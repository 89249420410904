import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DatedEvidence,
  FormEditor,
  FormPanel,
  FormSelect,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';

export function Fields() {
  const { naspisLogEventTypes } = useContext(StaticDataContext);

  const naspisLogEventTypeSource = useStaticListSource(naspisLogEventTypes);

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__NASPIS_LOG_RECORDS__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Obecné"
        />
      }
      expandable={false}
    >
      <FormSelect
        name="eventType"
        label={
          <FormattedMessage
            id="ES__NASPIS_LOG_RECORDS__DETAIL__FIELD_LABEL__EVENT_TYPE"
            defaultMessage="Typ události"
          />
        }
        source={naspisLogEventTypeSource}
        valueIsId
      />
      <FormTextField
        name="currentDataboxId"
        label={
          <FormattedMessage
            id="ES__NASPIS_LOG_RECORDS__DETAIL__FIELD_LABEL__CURRENT_DATA_BOX_ID"
            defaultMessage="Id stávající datové schránky uživatele"
          />
        }
      />
      <FormTextField
        name="newDataboxId"
        label={
          <FormattedMessage
            id="ES__NASPIS_LOG_RECORDS__DETAIL__FIELD_LABEL__NEW_DATA_BOX_ID"
            defaultMessage="Id nové datové schránky uživatele"
          />
        }
      />
      <FormTextField
        name="email"
        label={
          <FormattedMessage
            id="ES__NASPIS_LOG_RECORDS__DETAIL__FIELD_LABEL__EMAIL"
            defaultMessage="Email uživatele"
          />
        }
      />
      <FormEditor
        name="jsonRequest"
        label={
          <FormattedMessage
            id="ES__NASPIS_LOG_RECORDS__DETAIL__FIELD_LABEL__JSON_REQUEST"
            defaultMessage="Request"
          />
        }
        language="json"
      />
      <FormEditor
        name="jsonResponse"
        label={
          <FormattedMessage
            id="ES__NASPIS_LOG_RECORDS__DETAIL__FIELD_LABEL__JSON_RESPONSE"
            defaultMessage="Response"
          />
        }
        language="json"
      />
      <DatedEvidence.FieldCreated />
    </FormPanel>
  );
}
