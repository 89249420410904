const NEW = {
  title: 'Nové spisy',
  actions: [
    {
      name: 'Vytvoření nového soudního spisu',
      description:
        'Vytvářet spisy lze pouze ve stavu <b>Nové spisy</b> pomocí tlačítka <b>Nový</b>. Požadované atributy pro vytvoření nového soudního spisu - <i>Typ soudního spisu, Datum doručení, Datum pro statistiku, Došlé číslo</i>.',
    },
    {
      name: 'Přidělit spisovou značku',
      description:
        'Uživatel v detailu konkrétního spisu klikne na tlačítko <b>Přidělit spisovou značku</b>. Následně systém provede kontrolu a přiřadí značku. Stav spisu je po přidělení spisové značky nastaven na <b>Rejstřík - nové spisy</b>.',
    },
    {
      name: 'Přidělit spisovou značku hromadně',
      description:
        'Uživatel htomadně označí více spisů a v evidenci klikne na tlačítko <b>Přidělit spisovou značku</b>. Následně systém provede kontroly a přiřadí značky. Stavy spisů jsou po přidělení spisových značek nastaveny na <b>Rejstřík - Nové spisy</b>.',
    },
    {
      name: 'Změnit agendu',
      description: 'Změna agendy.',
    },
    {
      name: 'Upravení spisu',
      description: 'Uživatel má možnost upravovat jednotlivé atributy spisu.',
    },
    {
      name: 'Smazání spisu',
      description:
        'Uživatel může odstranit spis pouze v případě, kdy mu ještě nebyla přidělena spisová značka. V případě kdy jsou ke spisu přiřazeny nějaké dokumenty, jsou dokumenty po potvrzení odebrány od spisu a je u nich nastaven stav <b>Nezpracovaný</b>.',
    },
  ],
};

const REGISTER_NEW = {
  title: 'Rejstřík - Nové spisy',
  description:
    'Do tohoto stavu přechází spisy po přidělení spisových značek. V tomto stavu probíhá doplnění atributů na základě šablony generovaného odchozího dokumentu Akceptační dopis.',
  actions: [
    {
      name: 'Vyvést',
      description:
        'Při vyvedení z rejstříku je změněn <b>Způsob vyřízení</b> na <i>Vyvedeno z rejstříku</i>, jsou stornovány všechny aktivní úlohy a běžící lhůty spisu, spis je uzavřen. Akce je dostupná u spisů, které nejsou uzavřené a mají přidělenu spisovou značku.',
    },
    {
      name: 'Kancelář soudce',
      description:
        'Změna stavu spisu z <b>Rejstřík - nové spisy</b> na <b>Kancelář soudce</b>.',
    },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
    {
      name: 'Upravení spisu',
      description: 'Uživatel může upravovat jednotlivé atributy spisu.',
    },
    { name: 'Spojení spisů', description: 'Procesní spojení soudních spisů.' },
  ],
};

const JUDGE_OFFICE = {
  title: 'Kancelář soudce',
  actions: [
    {
      name: 'Vyvést',
      description:
        'Při vyvedení z rejstříku je změněn <b>Způsob vyřízení</b> na <i>Vyvedeno z rejstříku</i>, jsou stornovány všechny aktivní úlohy a běžící lhůty spisu, spis je uzavřen. Akce je dostupná u spisů, které nejsou uzavřené a mají přidělenu spisovou značku.',
    },
    {
      name: 'Rejstřík - nové spisy',
      description: 'Vrácení do předchozího stavu.',
    },
    { name: 'Rejstřík', description: 'Posun do následujícího stavu.' },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
    {
      name: 'Upravení spisu',
      description: 'Uživatel může upravovat jednotlivé atributy spisu.',
    },
    { name: 'Spojení spisů', description: 'Procesní spojení soudních spisů.' },
  ],
};

const REGISTER = {
  title: 'Rejstřík',
  actions: [
    {
      name: 'Vyvést',
      description:
        'Při vyvedení z rejstříku je změněn <b>Způsob vyřízení</b> na <i>Vyvedeno z rejstříku</i>, jsou stornovány všechny aktivní úlohy a běžící lhůty spisu, spis je uzavřen. Akce je dostupná u spisů, které nejsou uzavřené a mají přidělenu spisovou značku.',
    },
    { name: 'Kancelář soudce', description: 'Vrácení do předchozího stavu.' },
    { name: 'Spisovna', description: 'Posun do následujícího stavu.' },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
    {
      name: 'Upravení spisu',
      description: 'Uživatel může upravovat jednotlivé atributy spisu.',
    },
    { name: 'Spojení spisů', description: 'Procesní spojení soudních spisů.' },
  ],
};

const REGISTRY = {
  title: 'Spisovna',
  actions: [
    { name: 'Znovu otevřít', description: 'Otevření spisu.' },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
  ],
};

export const COURT_FILES = {
  title: 'Soudní spisy',
  subsections: [NEW, REGISTER_NEW, JUDGE_OFFICE, REGISTER, REGISTRY],
};
