import React, { useContext } from 'react';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ApiFilterOperation,
  EvidenceContext,
  useEventCallback,
} from '@eas/common-web';
import { useStringOperations } from './filter-api';
import { FilterContext } from './filter-context';

const useStyles = makeStyles({
  list: {
    listStyle: 'none',
  },
  listItem: {
    display: 'inline',
  },
  listItemLabel: {
    marginLeft: 15,
    marginRight: 30,
  },
  panel: {
    backgroundColor: 'white',
    boxShadow: 'none',
    /** to disable moving down while expanding */
    margin: '0 0 5px 0!important',
    '&::before': {
      opacity: 0,
    },
  },
  summary: {
    minHeight: '32px !important',
    height: 32,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 0,
  },
  summaryContent: {
    alignItems: 'center',
  },
  button: {
    marginRight: 15,
  },
  chip: {
    margin: 5,
  },
});

export function FilterReducers() {
  /**
   * Styles.
   */
  const classes = useStyles();

  /**
   * Context stuff.
   */
  const { removeReducer, toggleReducer, reducers, extenders } = useContext(
    FilterContext
  );
  const { tableRef } = useContext(EvidenceContext);

  const operations = useStringOperations();

  /**
   *
   */
  const getField = useEventCallback((key: string) => {
    const field = tableRef.current?.columns.find(
      (col) => col.datakey === key || col.filterkey === key
    );

    return field?.name ?? '';
  });

  /**
   *
   */
  const getOperation = useEventCallback((key: ApiFilterOperation) => {
    const operation = operations.items.find((op) => op.id === key);

    return operation?.name ?? '';
  });

  return (
    <div>
      {reducers.length > 0 && (
        <>
          {extenders.length > 0 && (
            <Typography variant="body1">
              <b>Odstraněné položky:</b>
            </Typography>
          )}
          {reducers.map((filterGroup, index) => {
            return (
              <ExpansionPanel key={index} className={classes.panel}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.summary}
                  classes={{
                    root: classes.summary,
                    content: classes.summaryContent,
                  }}
                >
                  <Switch
                    checked={filterGroup.visible}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleReducer(index);
                    }}
                    className={classes.button}
                    color="primary"
                    size="small"
                  />
                  <Typography
                    variant="body1"
                    color={!filterGroup.visible ? 'primary' : 'initial'}
                    className={classes.listItemLabel}
                  >
                    FILTR {index + 1}
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeReducer(index);
                    }}
                    className={classes.button}
                    color="secondary"
                    size="small"
                  >
                    <DeleteForeverIcon fontSize="small" />
                  </IconButton>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ul className={classes.list}>
                    {(filterGroup.filters ?? []).map((data, i) => {
                      const field = getField(data.field!);
                      const operation = getOperation(data.operation!);

                      return (
                        <li className={classes.listItem} key={i}>
                          <Chip
                            label={`${field} ${operation} ${JSON.stringify(
                              data.values
                            )}`}
                            className={classes.chip}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </>
      )}
    </div>
  );
}
