import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictProceedingsSubject } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { Fields } from './dict-proceedings-subject-fields';

export function DictProceedingsSubjects() {
  const intl = useIntl();
  const { columnActive, columnName } =
    DictionaryEvidence.useDictionaryColumns<DictProceedingsSubject>();

  const evidence = useDictionaryEvidence<DictProceedingsSubject>({
    version: 2,
    identifier: 'PROCEEDINGS_SUBJECTS',
    apiProps: {
      url: EvidenceApiUrl.DICT_PROCEEDINGS_SUBJECT,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.PROCEEDINGS_SUBJECT,
      }),
    },
    tableProps: {
      columns: [columnName, columnActive],
      tableName: intl.formatMessage({
        id: 'ES__DICT_PROCEEDINGS_SUBJECTS__TABLE__TITLE',
        defaultMessage: 'Předměty řízení',
      }),
      reportTag: ExportTags.DICT_PROCEEDINGS_SUBJECTS,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.PROCEEDINGS_SUBJECT,
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_PROCEEDINGS_SUBJECTS__DETAIL__TITLE',
          defaultMessage: 'Předmět řízení',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
