import {
  DictionaryAutocomplete,
  EsFileUnion,
  Participant,
  Proposer,
  UserRole,
  Writer,
} from '@models';

export function autocompleteLabelMapper<T extends DictionaryAutocomplete>({
  label,
  name,
}: T) {
  if (label) {
    return label;
  } else if (name) {
    return name;
  }

  return '-';
}

autocompleteLabelMapper.displayName = 'autocompleteLabelMapper';

export const dictionaryFieldLabelMapper = (
  props?: DictionaryAutocomplete | DictionaryAutocomplete[] | null
) => {
  if (Array.isArray(props)) {
    return props.map((item) => autocompleteLabelMapper(item)).join('; ');
  }

  if (props) {
    return autocompleteLabelMapper(props);
  }

  return '-';
};

dictionaryFieldLabelMapper.displayName = 'dictionaryFieldLabelMapper';

export function multipleAutocompleteColumnMapper<
  T extends DictionaryAutocomplete
>({ value }: { value: T[] }) {
  return (value || []).map((v) => v.label ?? v.name ?? '-').join(', ');
}

multipleAutocompleteColumnMapper.displayName =
  'multipleAutocompleteColumnMapper';

export function autocompleteColumnMapper<T extends DictionaryAutocomplete>({
  value,
}: {
  value: T;
}) {
  return value?.label ?? value?.name ?? '-';
}

autocompleteColumnMapper.displayName = 'autocompleteColumnMapper';

export function subjectContactColumnMapper({ value }: { value: Proposer[] }) {
  return value
    ?.map((participant: Participant) => participant?.contact?.label)
    .join(',');
}

subjectContactColumnMapper.displayName = 'subjectContactColumnMapper';

export function judgeSolversMergedColumnMapper({
  rowValue,
}: {
  rowValue: EsFileUnion;
}) {
  if ('judge' in rowValue) {
    return rowValue.judge?.label;
  }
  if ('solverUserRoles' in rowValue) {
    return rowValue.solverUserRoles
      ?.map((solver: UserRole) => solver.label)
      .join(', ');
  }
}

judgeSolversMergedColumnMapper.displayName = 'judgeSolversMergedColumnMapper';

export function participantsWritersMergedColumMapper({
  rowValue,
}: {
  rowValue: EsFileUnion;
}) {
  if ('proposers' in rowValue) {
    return rowValue.proposers
      ?.map((proposer: Proposer) => proposer.label)
      .join(',');
  }
  if ('writers' in rowValue) {
    return rowValue.writers?.map((writer: Writer) => writer.label).join(',');
  }
}

participantsWritersMergedColumMapper.displayName =
  'participantsWritersMergedColumMapper';
