import React, { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { useAutoCollapse } from '@components/form/misc/auto-collapse-hook';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { AccessoryParticipant } from '@models';
import { useAccessoryParticipantColumns } from './accessory-participant-columns';
import { AccessoryParticipantToolbar } from './accessory-participant-toolbar';

export interface AccessoryParticipantFieldProps {
  disabled: boolean;
  source?: ScrollableSource<AccessoryParticipant>;
}

export function AccessoryParticipantField({
  disabled,
  source,
}: AccessoryParticipantFieldProps) {
  const { panelRef } = useAutoCollapse(source?.items ?? []);

  const columns = useAccessoryParticipantColumns();

  const tableRef = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <AccessoryParticipantToolbar
            {...props}
            ref={editRef}
            disabled={disabled}
          />
        );
      },
    [disabled]
  );

  if (!source) {
    return <> Missing ACCESSORY_PARTICIPANT_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__ACCESSORY_PARTICIPANTS__PANEL_TITLE"
          defaultMessage="Vedlejší účastníci"
        />
      }
      expandable={true}
      ref={panelRef}
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        ref={tableRef}
        source={source}
        showDetailBtnCond={() => false}
        columns={columns}
        onRowClick={(_, index) => {
          if (!disabled) {
            tableRef.current?.setSelectedIndex(index);
            editRef.current?.executeAction();
          }
        }}
        maxRows={10}
        ToolbarComponent={ToolbarComponent}
        disabled={disabled}
      />
    </FormPanel>
  );
}
