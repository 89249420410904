import React from 'react';
import { DictionaryEvidence } from '@eas/common-web';

export function Fields() {
  return (
    <>
      <DictionaryEvidence.FieldName />
      <DictionaryEvidence.FieldActive />
    </>
  );
}
