/* eslint-disable prettier/prettier */
import { compact } from 'lodash';
import React, {
  Ref,
  RefObject,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  MenubarContext,
  NavigationContext,
  SnackbarContext,
  UserBtnAction,
  UserContext,
  abortableFetch,
  useEventCallback,
  useWebsocketEffect,
} from '@eas/common-web';
import { DECISIONS_ACCESS } from '@modules/decision/decision-permission';
import {
  DOCUMENTS_ACCESS,
  OUTGOING_DOCUMENTS_ACCESS,
  PROCESSED_DOCUMENTS_ACCESS,
  UNPROCESSED_DELIVERED_DOCUMENTS_ACCESS,
} from '@modules/document/document-permission';
import {
  ALL_FILES_ACCESS,
  COURT_FILES_ACCESS,
  DISCIPLINARY_PROCEEDINGS_FILE_ACCESS,
  INF_FILES_ACCESS,
  PLENARY_OPINION_FILES_ACCESS,
  SPR_FILES_ACCESS,
} from '@modules/es-file/es-file-permission';
import { Me } from '@models';
import {
  Agenda,
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  Messages,
  Role,
} from '@enums';

export type ESMenuItem = MenuItem & {
  roles?: string[];
  agenda?: string;
  viewing?: boolean;
  items?: ESMenuItem[];
};

const useStyles = makeStyles((theme) => ({
  loggedUser: {
    color: theme.palette.primary.main,
  },
}));

export function useMenuItems(menuRef: Ref<MenubarContext>) {
  const { navigate } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { user, hasPermission, reload } =
    useContext<UserContext<Me>>(UserContext);

  const classes = useStyles();

  useWebsocketEffect(() => {
    reload();
    console.log('message SESSION_REFRESHED');
  }, ['SESSION_REFRESHED']);

  const handleChangeContext = useEventCallback(async (id?: string) => {
    const response = abortableFetch(
      `${EvidenceApiUrl.USERS}/context/switch${
        id ? `?substitution=${id}` : ''
      }`,
      { method: 'POST' }
    );

    const message = id
      ? Messages.User.ACTIVE_USER_SUBSTITUTION
      : Messages.User.DEACTIVE_USER_SUBSTITUTION;

    try {
      await response.raw();

      showSnackbar(...message.SUCCESS);
    } catch (e) {
      showSnackbar(...message.ERROR);
    }
  });

  const {
    additionalDetails: {
      activeSubstitution = undefined,
      availableSubstitutions = [],
    } = {},
  } = user ?? {};

  const hasAvailableSubstitutions = availableSubstitutions.length > 0;

  const items: ESMenuItem[] = compact([
    {
      label: (
        <FormattedMessage id="ES__MENU__LUSTRUM" defaultMessage="Lustrum" />
      ),
      onClick: () => navigate(EvidenceBrowserUrl.SCREENING),
      href: EvidenceBrowserUrl.SCREENING,
      roles: [Role.LUSTRUM, Role.SOLVER, Role.HEAD_OSV, Role.INDEX],
      isActive: (pathname) => {
        return [EvidenceBrowserUrl.SCREENING].includes(
          pathname as EvidenceBrowserUrl
        );
      },
    },
    {
      label: (
        <FormattedMessage id="ES__MENU__DOCUMENTS" defaultMessage="Dokumenty" />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.DOCUMENTS_DELIVERED_UNPROCESSED,
          EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED,
          EvidenceBrowserUrl.DOCUMENTS_INTERNAL,
          EvidenceBrowserUrl.DOCUMENTS_OUTGOING,
          EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ALL,
          EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ELECTRONIC,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DOCUMENTS_DELIVERED"
              defaultMessage="Doručené"
            />
          ),
          roles: [...PROCESSED_DOCUMENTS_ACCESS, ...UNPROCESSED_DELIVERED_DOCUMENTS_ACCESS ],
          subHeader: true,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DOCUMENTS_DELIVERED_UNPROCESSED"
              defaultMessage="Nezpracované dokumenty"
            />
          ),
          onClick: () =>
            navigate(EvidenceBrowserUrl.DOCUMENTS_DELIVERED_UNPROCESSED),
          href: EvidenceBrowserUrl.DOCUMENTS_DELIVERED_UNPROCESSED,
          roles: UNPROCESSED_DELIVERED_DOCUMENTS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DOCUMENTS_DELIVERED_PROCESSED"
              defaultMessage="Zpracované dokumenty"
            />
          ),
          onClick: () =>
            navigate(EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED),
          href: EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED,
          roles: PROCESSED_DOCUMENTS_ACCESS,
        },
        {
          separator: true,
          roles: DOCUMENTS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DOCUMENTS_INTERNAL"
              defaultMessage="Interní"
            />
          ),
          roles: DOCUMENTS_ACCESS,
          subHeader: true,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DOCUMENTS_INTERNAL"
              defaultMessage="Interní dokumenty"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DOCUMENTS_INTERNAL),
          href: EvidenceBrowserUrl.DOCUMENTS_INTERNAL,
          roles: DOCUMENTS_ACCESS,
        },
        {
          separator: true,
          roles: OUTGOING_DOCUMENTS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DOCUMENTS_OUTGOING"
              defaultMessage="Odchozí"
            />
          ),
          roles: OUTGOING_DOCUMENTS_ACCESS,
          subHeader: true,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DOCUMENTS_OUTGOING"
              defaultMessage="Odchozí dokumenty"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DOCUMENTS_OUTGOING),
          href: EvidenceBrowserUrl.DOCUMENTS_OUTGOING,
          roles: OUTGOING_DOCUMENTS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DOCUMENTS_DISPATCHES_ALL"
              defaultMessage="Vypravení"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ALL),
          href: EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ALL,
          roles: DOCUMENTS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__ELECTRONIC_DOCUMENTS_DISPATCHES"
              defaultMessage="Vypravení - elektronické dokumenty"
            />
          ),
          onClick: () =>
            navigate(EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ELECTRONIC),
          href: EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ELECTRONIC,
          roles: DOCUMENTS_ACCESS,
        },
      ],
    },
    {
      label: (
        <FormattedMessage id="ES__MENU__ES_FILES" defaultMessage="Spisy" />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.ES_FILES_ALL,
          EvidenceBrowserUrl.ES_FILES_SEARCH,
          EvidenceBrowserUrl.COURT_FILES_NEW,
          EvidenceBrowserUrl.COURT_FILES_REGISTER_NEW,
          EvidenceBrowserUrl.COURT_FILES_JUDGE_OFFICE,
          EvidenceBrowserUrl.COURT_FILES_REGISTER,
          EvidenceBrowserUrl.COURT_FILES_REGISTRY,
          EvidenceBrowserUrl.COURT_FILES_CLOSED,
          EvidenceBrowserUrl.INF_FILES_NEW,
          EvidenceBrowserUrl.INF_FILES_IN_PROGRESS,
          EvidenceBrowserUrl.INF_FILES_SHREDDED,
          EvidenceBrowserUrl.SPR_FILES_NEW,
          EvidenceBrowserUrl.SPR_FILES_IN_PROGRESS,
          EvidenceBrowserUrl.SPR_FILES_CLOSED,
          EvidenceBrowserUrl.SPR_FILES_REGISTRY,
          EvidenceBrowserUrl.SPR_FILES_SHREDDED,
          EvidenceBrowserUrl.DISCIPLINARY_PROCEEDINGS_FILES,
          EvidenceBrowserUrl.PLENARY_OPINION_FILES,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__ES_FILES_ALL"
              defaultMessage="Všechny spisy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ES_FILES_ALL),
          href: EvidenceBrowserUrl.ES_FILES_ALL,
          roles: ALL_FILES_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__ES_FILES_SEARCH"
              defaultMessage="Spisy - hledání"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ES_FILES_SEARCH),
          href: EvidenceBrowserUrl.ES_FILES_SEARCH,
          roles: ALL_FILES_ACCESS,
        },
        {
          separator: true,
          agenda: Agenda.COURT,
          roles: COURT_FILES_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES"
              defaultMessage="Soudní"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_ALL),
          href: EvidenceBrowserUrl.COURT_FILES_ALL,
          roles: COURT_FILES_ACCESS,
          agenda: Agenda.COURT,
          subHeader: true,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES_NEW"
              defaultMessage="Nové spisy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_NEW),
          href: EvidenceBrowserUrl.COURT_FILES_NEW,
          roles: [
            Role.LUSTRUM,
            Role.HEAD_OSV,
            Role.INDEX,
            Role.READER,
            Role.VIEWING_REQUEST_MANAGER,
          ],
          agenda: Agenda.COURT,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES_REGISTER_NEW"
              defaultMessage="Rejstřík - nové spisy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_REGISTER_NEW),
          href: EvidenceBrowserUrl.COURT_FILES_REGISTER_NEW,
          roles: COURT_FILES_ACCESS,
          agenda: Agenda.COURT,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES_INDEX"
              defaultMessage="Indexace"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_INDEX),
          href: EvidenceBrowserUrl.COURT_FILES_INDEX,
          roles: COURT_FILES_ACCESS,
          agenda: Agenda.COURT,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES_JUDGE_OFFICE"
              defaultMessage="Kancelář soudce"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_JUDGE_OFFICE),
          href: EvidenceBrowserUrl.COURT_FILES_JUDGE_OFFICE,
          roles: COURT_FILES_ACCESS,
          agenda: Agenda.COURT,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES_REGISTER"
              defaultMessage="Rejstřík"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_REGISTER),
          href: EvidenceBrowserUrl.COURT_FILES_REGISTER,
          roles: COURT_FILES_ACCESS,
          agenda: Agenda.COURT,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES_CLOSED"
              defaultMessage="Uzavřené"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_CLOSED),
          href: EvidenceBrowserUrl.COURT_FILES_CLOSED,
          roles: COURT_FILES_ACCESS,
          agenda: Agenda.COURT,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES_REGISTRY"
              defaultMessage="Spisovna"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_REGISTRY),
          href: EvidenceBrowserUrl.COURT_FILES_REGISTRY,
          roles: COURT_FILES_ACCESS,
          agenda: Agenda.COURT,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__COURT_FILES_SHREDDED"
              defaultMessage="Skartované"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.COURT_FILES_SHREDDED),
          href: EvidenceBrowserUrl.COURT_FILES_SHREDDED,
          roles: COURT_FILES_ACCESS,
          agenda: Agenda.COURT,
        },
        {
          separator: true,
          agenda: Agenda.INF,
          roles: INF_FILES_ACCESS,
        },
        {
          label: (
            <FormattedMessage id="ES__MENU__INF_FILES" defaultMessage="INF" />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.INF_FILES_ALL),
          href: EvidenceBrowserUrl.INF_FILES_ALL,
          roles: INF_FILES_ACCESS,
          subHeader: true,
          agenda: Agenda.INF,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__INF_FILES_NEW"
              defaultMessage="Nové spisy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.INF_FILES_NEW),
          href: EvidenceBrowserUrl.INF_FILES_NEW,
          roles: [Role.LUSTRUM, Role.HEAD_OSV, Role.INDEX, Role.READER],
          agenda: Agenda.INF,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__INF_FILES_IN_PROGRESS"
              defaultMessage="V řešení"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.INF_FILES_IN_PROGRESS),
          href: EvidenceBrowserUrl.INF_FILES_IN_PROGRESS,
          roles: INF_FILES_ACCESS,
          agenda: Agenda.INF,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__INF_FILES_CLOSED"
              defaultMessage="Uzavřené"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.INF_FILES_CLOSED),
          href: EvidenceBrowserUrl.INF_FILES_CLOSED,
          roles: INF_FILES_ACCESS,
          agenda: Agenda.INF,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__INF_FILES_REGISTRY"
              defaultMessage="Spisovna"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.INF_FILES_REGISTRY),
          href: EvidenceBrowserUrl.INF_FILES_REGISTRY,
          roles: INF_FILES_ACCESS,
          agenda: Agenda.INF,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__INF_FILES_SHREDDED"
              defaultMessage="Skartované"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.INF_FILES_SHREDDED),
          href: EvidenceBrowserUrl.INF_FILES_SHREDDED,
          roles: INF_FILES_ACCESS,
          agenda: Agenda.INF,
        },
        {
          separator: true,
          agenda: Agenda.SPR,
          roles: INF_FILES_ACCESS,
        },
        {
          label: (
            <FormattedMessage id="ES__MENU__SPR_FILES" defaultMessage="SPR" />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SPR_FILES_ALL),
          href: EvidenceBrowserUrl.SPR_FILES_ALL,
          roles: SPR_FILES_ACCESS,
          subHeader: true,
          agenda: Agenda.SPR,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__SPR_FILES_NEW"
              defaultMessage="Nové spisy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SPR_FILES_NEW),
          href: EvidenceBrowserUrl.SPR_FILES_NEW,
          roles: [Role.LUSTRUM, Role.HEAD_OSV, Role.INDEX, Role.READER],
          agenda: Agenda.SPR,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__SPR_FILES_IN_PROGRESS"
              defaultMessage="V řešení"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SPR_FILES_IN_PROGRESS),
          href: EvidenceBrowserUrl.SPR_FILES_IN_PROGRESS,
          roles: SPR_FILES_ACCESS,
          agenda: Agenda.SPR,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__SPR_FILES_CLOSED"
              defaultMessage="Uzavřené"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SPR_FILES_CLOSED),
          href: EvidenceBrowserUrl.SPR_FILES_CLOSED,
          roles: SPR_FILES_ACCESS,
          agenda: Agenda.SPR,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__SPR_FILES_REGISTRY"
              defaultMessage="Spisovna"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SPR_FILES_REGISTRY),
          href: EvidenceBrowserUrl.SPR_FILES_REGISTRY,
          roles: SPR_FILES_ACCESS,
          agenda: Agenda.SPR,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__SPR_FILES_SHREDDED"
              defaultMessage="Skartované"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SPR_FILES_SHREDDED),
          href: EvidenceBrowserUrl.SPR_FILES_SHREDDED,
          roles: SPR_FILES_ACCESS,
          agenda: Agenda.SPR,
        },
        {
          separator: true,
          agenda: Agenda.DISCIPLINARY_PROCEEDINGS,
          roles: DISCIPLINARY_PROCEEDINGS_FILE_ACCESS,
          viewing: true,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DISCIPLINARY_PROCEEDINGS_FILES"
              defaultMessage="Kárné řízení"
            />
          ),
          onClick: () =>
            navigate(EvidenceBrowserUrl.DISCIPLINARY_PROCEEDINGS_FILES),
          href: EvidenceBrowserUrl.DISCIPLINARY_PROCEEDINGS_FILES,
          roles: DISCIPLINARY_PROCEEDINGS_FILE_ACCESS,
          subHeader: true,
          agenda: Agenda.DISCIPLINARY_PROCEEDINGS,
          viewing: true,
        },
        {
          separator: true,
          agenda: Agenda.PLENARY_OPINION,
          roles: PLENARY_OPINION_FILES_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__PLENARY_OPINION_FILES"
              defaultMessage="Stanovisko pléna"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.PLENARY_OPINION_FILES),
          href: EvidenceBrowserUrl.PLENARY_OPINION_FILES,
          roles: PLENARY_OPINION_FILES_ACCESS,
          subHeader: true,
          agenda: Agenda.PLENARY_OPINION,
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ES__MENU__DECISIONS"
          defaultMessage="Rozhodnutí"
        />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.DECISIONS,
          EvidenceBrowserUrl.DECISIONS_INDEXING,
          EvidenceBrowserUrl.DECISIONS_IN_PROGRESS,
          EvidenceBrowserUrl.DECISIONS_TO_ANONYMIZE,
          EvidenceBrowserUrl.DECISIONS_TO_MAKE_ACCESSIBLE,
          EvidenceBrowserUrl.DECISIONS_ACCESSIBLE,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DECISIONS_SEARCH"
              defaultMessage="Rozhodnutí - hledání"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DECISIONS_SEARCH),
          href: EvidenceBrowserUrl.DECISIONS_SEARCH,
          roles: DECISIONS_ACCESS,
        },
        {
          separator: true,
          roles: DECISIONS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DECISIONS_ALL"
              defaultMessage="Rozhodnutí"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DECISIONS_ALL),
          href: EvidenceBrowserUrl.DECISIONS_ALL,
          subHeader: true,
          roles: DECISIONS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DECISION_IN_PROGRESS"
              defaultMessage="Rozpracovaná"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DECISIONS_IN_PROGRESS),
          href: EvidenceBrowserUrl.DECISIONS_IN_PROGRESS,
          roles: DECISIONS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DECISION_INDEXING"
              defaultMessage="Indexace"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DECISIONS_INDEXING),
          href: EvidenceBrowserUrl.DECISIONS_INDEXING,
          roles: DECISIONS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DECISION_TO_ANONYMIZE"
              defaultMessage="K anonymizaci"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DECISIONS_TO_ANONYMIZE),
          href: EvidenceBrowserUrl.DECISIONS_TO_ANONYMIZE,
          roles: DECISIONS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DECISION_TO_MAKE_ACCESSIBLE"
              defaultMessage="K zpřístupnění"
            />
          ),
          onClick: () =>
            navigate(EvidenceBrowserUrl.DECISIONS_TO_MAKE_ACCESSIBLE),
          href: EvidenceBrowserUrl.DECISIONS_TO_MAKE_ACCESSIBLE,
          roles: DECISIONS_ACCESS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DECISION_ACCESSIBLE"
              defaultMessage="Zpřístupněná"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DECISIONS_ACCESSIBLE),
          href: EvidenceBrowserUrl.DECISIONS_ACCESSIBLE,
          roles: DECISIONS_ACCESS,
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ES__MENU__VIEWING_REQUESTS"
          defaultMessage="Žádosti o nahlížení"
        />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.VIEWING_REQUEST_ALL,
          EvidenceBrowserUrl.VIEWING_REQUEST_NEW,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__VIEWING_REQUESTS_ALL"
              defaultMessage="Všechny žádosti"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.VIEWING_REQUEST_ALL),
          href: EvidenceBrowserUrl.VIEWING_REQUEST_ALL,
          roles: [Role.ADMIN, Role.VIEWING_REQUEST_MANAGER],
        },
        {
          separator: true,
          roles: [Role.ADMIN, Role.VIEWING_REQUEST_MANAGER],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__VIEWING_REQUESTS_NEW"
              defaultMessage="Nové žádosti"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.VIEWING_REQUEST_NEW),
          href: EvidenceBrowserUrl.VIEWING_REQUEST_NEW,
          roles: [Role.ADMIN, Role.VIEWING_REQUEST_MANAGER],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__VIEWING_REQUESTS_PROCESSING"
              defaultMessage="Zpracovávané žádosti"
            />
          ),
          onClick: () =>
            navigate(EvidenceBrowserUrl.VIEWING_REQUEST_PROCESSING),
          href: EvidenceBrowserUrl.VIEWING_REQUEST_PROCESSING,
          roles: [Role.ADMIN, Role.VIEWING_REQUEST_MANAGER],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__VIEWING_REQUESTS_RESOLVED"
              defaultMessage="Vyřízené žádosti"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.VIEWING_REQUEST_RESOLVED),
          href: EvidenceBrowserUrl.VIEWING_REQUEST_RESOLVED,
          roles: [Role.ADMIN, Role.VIEWING_REQUEST_MANAGER],
        },
      ],
    },
    {
      label: <FormattedMessage id="ES__MENU__TASKS" defaultMessage="Úlohy" />,
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.TASKS].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage id="ES__MENU__TASKS" defaultMessage="Úlohy" />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.TASKS),
          href: EvidenceBrowserUrl.TASKS,
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_TASK_NAMES"
              defaultMessage="Názvy úloh"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_TASK_NAME),
          href: EvidenceBrowserUrl.DICT_TASK_NAME,
          roles: [Role.ADMIN],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ES__MENU__NOTIFICATIONS"
          defaultMessage="Notifikace"
        />
      ),
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.NOTIFICATION_TEMPLATES].find((url) =>
          pathname.match(url)
        );
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__NOTIFICATION_TEMPLATES"
              defaultMessage="Šablony notifikací"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.NOTIFICATION_TEMPLATES),
          href: EvidenceBrowserUrl.NOTIFICATION_TEMPLATES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__NOTIFICATIONS"
              defaultMessage="Všechny notifikace"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.NOTIFICATIONS),
          href: EvidenceBrowserUrl.NOTIFICATIONS,
          roles: [Role.ADMIN],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ES__MENU__STATISTICS_AND_REGISTERS"
          defaultMessage="Statistiky a rejstříky"
        />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.STATISTICS,
          EvidenceBrowserUrl.REGISTERS,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__REGISTERS"
              defaultMessage="Rejstříky - soudní spisy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.REGISTERS),
          href: EvidenceBrowserUrl.REGISTERS,
          roles: [Role.HEAD_OSV, Role.FILE_ROOM, Role.ADVISER],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__STATISTICS"
              defaultMessage="Statistiky"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.STATISTICS),
          href: EvidenceBrowserUrl.STATISTICS,
          roles: [Role.HEAD_OSV],
        },
      ],
    },
    {
      label: (
        <FormattedMessage id="ES__MENU__USERS" defaultMessage="Uživatelé" />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.USERS,
          EvidenceBrowserUrl.ROLES,
          EvidenceBrowserUrl.USER_SUBSTITUTIONS,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_USERS"
              defaultMessage="Uživatelé"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.USERS),
          href: EvidenceBrowserUrl.USERS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__ROLES"
              defaultMessage="Uživatelské role"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ROLES),
          href: EvidenceBrowserUrl.ROLES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__USER_SUBSTITUTIONS"
              defaultMessage="Zástupy uživatelů"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.USER_SUBSTITUTIONS),
          href: EvidenceBrowserUrl.USER_SUBSTITUTIONS,
          roles: [Role.ADMIN],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ES__MENU__DICTIONARIES"
          defaultMessage="Číselníky"
        />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.DICT_ACT_TYPE,
          EvidenceBrowserUrl.DICT_CONTACT_TYPES,
          EvidenceBrowserUrl.DICT_REGULATION_ORDER,
          EvidenceBrowserUrl.DICT_ENVELOPE_CONTENTS,
          EvidenceBrowserUrl.DICT_SUBMISSION_CONTENT,
          EvidenceBrowserUrl.DICT_OPPONENTS,
          EvidenceBrowserUrl.DICT_NOTES,
          EvidenceBrowserUrl.DICT_PROCEEDINGS_SUBJECT,
          EvidenceBrowserUrl.DICT_ZIP_CODES,
          EvidenceBrowserUrl.DICT_BRIEF_SUMMARY,
          EvidenceBrowserUrl.DICT_HOLIDAYS,
          EvidenceBrowserUrl.DICT_BARCODE_PRINTERS,
          EvidenceBrowserUrl.DICT_CONCERNED_AUTHORITY_TYPES,
          EvidenceBrowserUrl.DICT_DISPATCH_TYPES,
          EvidenceBrowserUrl.DICT_PROPOSER_DISTINCTION,
          EvidenceBrowserUrl.DICT_PROPOSER_TYPE,
          EvidenceBrowserUrl.DICT_PROCESSING_TYPE,
          EvidenceBrowserUrl.DICT_SUBJECT_INDEX,
          EvidenceBrowserUrl.DICT_COUNTRIES,
          EvidenceBrowserUrl.DICT_SETTLEMENT_METHODS,
          EvidenceBrowserUrl.SUBJECTS,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_ACT_TYPES"
              defaultMessage="Druhy aktu"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_ACT_TYPE),
          href: EvidenceBrowserUrl.DICT_ACT_TYPE,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_ENVELOPE_CONTENTS"
              defaultMessage="Obsah obálek"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_ENVELOPE_CONTENTS),
          href: EvidenceBrowserUrl.DICT_ENVELOPE_CONTENTS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_SUBMISSION_CONTENT_SPR"
              defaultMessage="Obsahy podání"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_SUBMISSION_CONTENT),
          href: EvidenceBrowserUrl.DICT_SUBMISSION_CONTENT,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_OPPONENTS"
              defaultMessage="Odpůrci"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_OPPONENTS),
          href: EvidenceBrowserUrl.DICT_OPPONENTS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_NOTES"
              defaultMessage="Poznámky"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_NOTES),
          href: EvidenceBrowserUrl.DICT_NOTES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_REGULATION_ORDERS"
              defaultMessage="Právní předpisy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_REGULATION_ORDER),
          href: EvidenceBrowserUrl.DICT_REGULATION_ORDER,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_PROCEEDINGS_SUBJECTS"
              defaultMessage="Předměty řízení"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_PROCEEDINGS_SUBJECT),
          href: EvidenceBrowserUrl.DICT_PROCEEDINGS_SUBJECT,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_ZIP_CODES"
              defaultMessage="PSČ"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_ZIP_CODES),
          href: EvidenceBrowserUrl.DICT_ZIP_CODES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_PROPOSER_DISTINCTIONS"
              defaultMessage="Rozlišení navrhovatelů"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_PROPOSER_DISTINCTION),
          href: EvidenceBrowserUrl.DICT_PROPOSER_DISTINCTION,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_BRIEF_SUMMARIES"
              defaultMessage="Stručné obsahy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_BRIEF_SUMMARY),
          href: EvidenceBrowserUrl.DICT_BRIEF_SUMMARY,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_SUBJECTS"
              defaultMessage="Subjekty"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SUBJECTS),
          href: EvidenceBrowserUrl.SUBJECTS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_HOLIDAYS"
              defaultMessage="Svátky"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_HOLIDAYS),
          href: EvidenceBrowserUrl.DICT_HOLIDAYS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_BARCODE_PRINTERS"
              defaultMessage="Tiskárny čárových kódů"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_BARCODE_PRINTERS),
          href: EvidenceBrowserUrl.DICT_BARCODE_PRINTERS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_CONCERNED_AUTHORITY_TYPES"
              defaultMessage="Typy dotčeného orgánu"
            />
          ),
          onClick: () =>
            navigate(EvidenceBrowserUrl.DICT_CONCERNED_AUTHORITY_TYPES),
          href: EvidenceBrowserUrl.DICT_CONCERNED_AUTHORITY_TYPES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_CONTACT_TYPES"
              defaultMessage="Typy kontaktů"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_CONTACT_TYPES),
          href: EvidenceBrowserUrl.DICT_CONTACT_TYPES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_PROPOSER_TYPES"
              defaultMessage="Typy navrhovatelů"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_PROPOSER_TYPE),
          href: EvidenceBrowserUrl.DICT_PROPOSER_TYPE,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_DISPATCH_TYPES"
              defaultMessage="Typy odeslání"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_DISPATCH_TYPES),
          href: EvidenceBrowserUrl.DICT_DISPATCH_TYPES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_PROCESSING_TYPES"
              defaultMessage="Typy řízení"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_PROCESSING_TYPE),
          href: EvidenceBrowserUrl.DICT_PROCESSING_TYPE,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_SUBJECT_INDEX"
              defaultMessage="Věcný rejstřík"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_SUBJECT_INDEX),
          href: EvidenceBrowserUrl.DICT_SUBJECT_INDEX,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_COUNTRIES"
              defaultMessage="Země"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_COUNTRIES),
          href: EvidenceBrowserUrl.DICT_COUNTRIES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__DICT_SETTLEMENT_METHODS"
              defaultMessage="Způsoby vyřízení"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_SETTLEMENT_METHODS),
          href: EvidenceBrowserUrl.DICT_SETTLEMENT_METHODS,
          roles: [Role.ADMIN],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ES__MENU__ADMINISTRATION"
          defaultMessage="Administrace"
        />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.APP_SETTINGS,
          EvidenceBrowserUrl.JOBS,
          EvidenceBrowserUrl.ESS_INCOMING_BATCHES,
          EvidenceBrowserUrl.ESS_OUTGOING_BATCHES,
          EvidenceBrowserUrl.SEQUENCES,
          EvidenceBrowserUrl.ALOG,
          EvidenceBrowserUrl.SCHEDULE_JOBS,
          EvidenceBrowserUrl.SCHEDULE_RUNS,
          EvidenceBrowserUrl.NASPIS_LOG_RECORDS,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__SEQUENCES"
              defaultMessage="Číselné řady"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SEQUENCES),
          href: EvidenceBrowserUrl.SEQUENCES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__GLOBAL_SETTINGS"
              defaultMessage="Globální nastavení"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.GLOBAL_SETTINGS),
          href: EvidenceBrowserUrl.GLOBAL_SETTINGS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__NASPIS_LOG_RECORDS"
              defaultMessage="Logování NaSpis"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.NASPIS_LOG_RECORDS),
          href: EvidenceBrowserUrl.NASPIS_LOG_RECORDS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__ALOG"
              defaultMessage="Logování VIS"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ALOG),
          href: EvidenceBrowserUrl.ALOG,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__APP_SETTINGS"
              defaultMessage="Nastavení aplikace"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.APP_SETTINGS),
          href: EvidenceBrowserUrl.APP_SETTINGS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__OUTGOING_BATCHES"
              defaultMessage="Odchozí dávky"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ESS_OUTGOING_BATCHES),
          href: EvidenceBrowserUrl.ESS_OUTGOING_BATCHES,
          roles: [Role.SUPERADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__INCOMING_BATCHES"
              defaultMessage="Příchozí dávky"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ESS_INCOMING_BATCHES),
          href: EvidenceBrowserUrl.ESS_INCOMING_BATCHES,
          roles: [Role.SUPERADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__JOBS"
              defaultMessage="Úlohy na spisové službě"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.JOBS),
          href: EvidenceBrowserUrl.JOBS,
          roles: [Role.SUPERADMIN],
        },
        {
          separator: true,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__SCHEDULE_JOBS"
              defaultMessage="Časové úlohy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SCHEDULE_JOBS),
          href: EvidenceBrowserUrl.SCHEDULE_JOBS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__SCHEDULE_RUNS"
              defaultMessage="Běhy časových úloh"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SCHEDULE_RUNS),
          href: EvidenceBrowserUrl.SCHEDULE_RUNS,
          roles: [Role.ADMIN],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ES__MENU__ES_FILE_NUMBER"
          defaultMessage="Spisové značky"
        />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.ASSIGNED_FILE_NUMBERS,
          EvidenceBrowserUrl.ASSIGNING_RULES,
          EvidenceBrowserUrl.DICT_SENATES,
          EvidenceBrowserUrl.JUDGES,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__ASSIGNED_FILE_NUMBERS"
              defaultMessage="Historie přidělení spisových značek"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ASSIGNED_FILE_NUMBERS),
          href: EvidenceBrowserUrl.ASSIGNED_FILE_NUMBERS,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__ASSIGNING_RULES"
              defaultMessage="Pravidla přidělování"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ASSIGNING_RULES),
          href: EvidenceBrowserUrl.ASSIGNING_RULES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage id="ES__MENU__SENATES" defaultMessage="Senáty" />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.DICT_SENATES),
          href: EvidenceBrowserUrl.DICT_SENATES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage id="ES__MENU__JUDGES" defaultMessage="Soudci" />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.JUDGES),
          href: EvidenceBrowserUrl.JUDGES,
          roles: [Role.ADMIN],
        },
      ],
    },
    {
      label: (
        <FormattedMessage id="ES__MENU__EXPORTS" defaultMessage="Exporty" />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.EXPORT_TEMPLATES,
          EvidenceBrowserUrl.EXPORT_REQUESTS,
        ].find((url) => pathname.match(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ES__MENU__EXPORT_TEMPLATES"
              defaultMessage="Šablony exportu"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.EXPORT_TEMPLATES),
          href: EvidenceBrowserUrl.EXPORT_TEMPLATES,
          roles: [Role.ADMIN],
        },
        {
          label: (
            <FormattedMessage
              id="ES__MENU__EXPORT_QUEUE"
              defaultMessage="Fronta exportů"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.EXPORT_REQUESTS),
          href: EvidenceBrowserUrl.EXPORT_REQUESTS,
          roles: [Role.ADMIN],
        },
      ],
    },
    {
      label: (
        <FormattedMessage id="ES__MENU__ES_HELP" defaultMessage="Nápověda" />
      ),
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.HELP].find((url) => pathname.match(url));
      },
      href: EvidenceBrowserUrl.HELP,
      onClick: () => navigate(EvidenceBrowserUrl.HELP),
    },
  ]);

  const substitutions = useMemo(
    () => [
      {
        separator: true,
      },
      {
        label: (
          <FormattedMessage
            id="ES__MENU__USER_SUBSTITUTIONS"
            defaultMessage="Zástupy"
          />
        ),
        subHeader: true,
      },
      ...(activeSubstitution
        ? [
            {
              label: (
                <span className={classes.loggedUser}>
                  <FormattedMessage
                    id="ES__MENU__USER_CANCEL"
                    defaultMessage="Ukončit zástup"
                  />
                </span>
              ),
              action: handleChangeContext,
            },
          ]
        : []),
      ...(availableSubstitutions
        ?.filter((sub) => sub.id !== activeSubstitution?.id)
        .map((sub, idx) => ({
          label: (
            <FormattedMessage
              id={`ES__MENU__USER_${idx}`}
              defaultMessage={`${sub?.substitutedUser?.name} (${sub.substitutedUser?.username})`}
            />
          ),
          action: () => {
            handleChangeContext(sub.id);
          },
        })) ?? []),
      {
        separator: true,
      },
    ],
    [availableSubstitutions]
  );

  const userBtnActions: UserBtnAction[] = useMemo(
    () => [
      {
        label: (
          <FormattedMessage id="ES__MENU__PROFILE" defaultMessage="Profil" />
        ),
        action: () => {
          navigate(EvidenceBrowserUrl.PROFILE);
        },
      },
      ...(hasAvailableSubstitutions ? substitutions : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [substitutions]
  );

  // Layout effect is used to ensure that it is called before useEffect in admin-provider.tsx
  useLayoutEffect(() => {
    if (menuRef) {
      const filteredItems = filterItems(items);

      (menuRef as RefObject<MenubarContext>)?.current?.modifyItems(
        () => filteredItems
      );
    }
  }, [hasPermission]);

  const filterItems = (items: ESMenuItem[]) => {
    if (!user) {
      return [];
    }

    const getNodes = (result: ESMenuItem[], item: ESMenuItem) => {
      if (!item.items && !item.roles && !item.agenda) {
        result.push(item);
        return result;
      }

      const permitted = (item?.roles ?? []).some((role) => {
        if (user?.additionalDetails?.hasActiveViewings && item?.viewing) {
          return true;
        }

        return hasPermission(
          role,
          item.agenda ? { agenda: item.agenda } : null
        );
      });

      if (permitted) {
        result.push(item);
        return result;
      }

      if (Array.isArray(item.items)) {
        const items = item.items.reduce(getNodes, []);
        if (items.length) result.push({ ...item, items });
      }

      return result;
    };

    return items.reduce(getNodes, []);
  };

  const filteredItems = filterItems(items);

  const devTools = useMemo(
    () => [
      {
        label: (
          <FormattedMessage
            id="ES__MENU__REST_MESSAGES"
            defaultMessage="Integrační REST volání"
          />
        ),
        onClick: () => navigate(EvidenceBrowserUrl.REST_MESSAGES),
        href: EvidenceBrowserUrl.REST_MESSAGES,
        roles: [Role.SUPERADMIN],
      },
      {
        label: (
          <FormattedMessage id="ES__MENU__SCRIPTS" defaultMessage="Skripty" />
        ),
        onClick: () => navigate(EvidenceBrowserUrl.ACTIONS),
        href: EvidenceBrowserUrl.ACTIONS,
        permission: [Role.SUPERADMIN],
      },
    ],
    [navigate]
  );

  return { items, userBtnActions, filteredItems, devTools };
}
