import clsx from 'clsx';
import React from 'react';
import { FilterComponentProps, Select, TextField } from '@eas/common-web';
import { useCompareOperations } from '../filter-api';
import { useStyles } from '../filter-styles';
import { useFilterOperation } from '../hooks/filter-operation-hook';

export function DisabledFilterNumberCell(props: FilterComponentProps) {
  return <FilterNumberCell {...props} disabled />;
}

export function FilterNumberCell({
  disabled,
  value,
  onChange,
  state,
  onChangeState,
}: FilterComponentProps) {
  const classes = useStyles();
  const operations = useCompareOperations();
  const { handleChangeOperation } = useFilterOperation(state, onChangeState);

  return (
    <div className={clsx(classes.root, { [classes.disabled]: disabled })}>
      <div className={classes.selectWrapper}>
        <Select
          source={operations}
          value={state.operation}
          onChange={handleChangeOperation}
          valueIsId={true}
          clearable={false}
          disabled={disabled}
        />
      </div>
      <div className={classes.textWrapper}>
        <TextField
          value={value}
          onChange={onChange}
          disabled={disabled}
          type="number"
        />
      </div>
    </div>
  );
}
