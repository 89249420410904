import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  PrimaryDetailActionbarButton,
  abortableFetch,
} from '@eas/common-web';
import { Document } from '@models';
import { EssDocumentState, EvidenceApiUrl } from '@enums';

export function useReopenDialog() {
  const { source } = useContext<DetailHandle<Document>>(DetailContext);
  const intl = useIntl();

  const callApi = () =>
    abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${source?.data?.id}/open`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });

  const showButton =
    source.data?.essDocument?.state === EssDocumentState.CLOSED;

  const ReopenButton = (props: { buttonDisabled?: boolean }) => (
    <DetailActionButton
      promptKey="REOPEN"
      apiCall={callApi}
      isLocked={false}
      buttonLabel={intl.formatMessage({
        id: 'ES__DOCUMENTS__ACTIONBAR__REOPEN',
        defaultMessage: 'Znovu otevřít',
      })}
      dialogTitle={intl.formatMessage({
        id: 'ES__DOCUMENTS__DIALOG_REOPEN__TITLE',
        defaultMessage: 'Opětovné otevření dokumentu',
      })}
      dialogText={intl.formatMessage({
        id: 'ES__DOCUMENTS__DIALOG_REOPEN__TEXT',
        defaultMessage: 'Opravdu chcete znovu otevřít vybraný dokument?',
      })}
      ButtonComponent={PrimaryDetailActionbarButton}
      {...props}
    />
  );

  return {
    showButton,
    ReopenButton,
  };
}
