import { abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl, NotificationEventType } from '@enums';

export const unsubscribeNotificationApiCall = (event: NotificationEventType) =>
  abortableFetch(
    `${EvidenceApiUrl.NOTIFICATION_PREFERENCES}/unsubscribe/${event}`,
    {
      method: 'PUT',
    }
  );
