import React, { useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ActionButtonHandle,
  ApiFilterOperation,
  DetailContext,
  FieldFilter,
  FormContext,
  FormDictionaryField,
  FormSelect,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import { useParticipants } from '@modules/es-file/es-file-api';
import { CONTACTS_SORT, useContacts } from '@modules/subject/subject-api';
import {
  useContactColumns,
  useContactFiltersFields,
} from '@modules/subject/subject-columns';
import { SubjectToolbar } from '@modules/subject/subject-toolbar';
import {
  ContactAutocompleteItem,
  createRowStyle,
} from '@composite/contact/conact-utils';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { dictionaryFieldLabelMapper } from '@components/form/misc/label-mappers';
import { Subject } from '@models';
import { EvidenceApiUrl, LegalAdviserType } from '@enums';

export function LegalAdviserDialog() {
  const intl = useIntl();
  const { setFieldValue } = useContext(FormContext);

  const { contactColumns } = useContactColumns({
    labelPrefix: 'Adresát',
  });
  const { contactFiltersFields } = useContactFiltersFields({
    labelPrefix: 'Adresát',
  });

  const createRef = useRef<ActionButtonHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const { source } = useContext(DetailContext);
  const { legalAdviserTypes } = useContext(StaticDataContext);

  const clientsFilters = [
    {
      operation: ApiFilterOperation.OR,
      filters: [
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: LegalAdviserType.ACCESSORY_PARTICIPANT,
        },
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: LegalAdviserType.PROPOSER,
        },
      ],
    },
  ];

  const legalAdviserTypesSource = useStaticListSource(legalAdviserTypes);
  const contacts = useContacts();
  const contactSource = useScrollableSource({
    url: `${EvidenceApiUrl.SUBJECT_CONTACTS}/list`,
  });

  const participantSource = useScrollableSource({
    url: `${EvidenceApiUrl.ES_FILES}/${source?.data?.id}/participant/list`,
    params: {
      filters: [
        {
          operation: ApiFilterOperation.OR,
          filters: [
            {
              field: 'type.id',
              operation: ApiFilterOperation.EQ,
              value: LegalAdviserType.ACCESSORY_PARTICIPANT,
            },
            {
              field: 'type.id',
              operation: ApiFilterOperation.EQ,
              value: LegalAdviserType.PROPOSER,
            },
          ],
        },
      ],
    },
  });
  const participants = useParticipants({ params: { filters: clientsFilters } });

  return (
    <>
      <SubjectToolbar
        createRef={createRef}
        editRef={editRef}
        fieldName="contact.subject"
        onResultValue={(subject: Subject) => {
          setFieldValue('contact', subject.selectedContact);
        }}
      />
      <FormDictionaryField
        name="contact"
        required={true}
        label={
          <FormattedMessage
            id="ES__LEGAL_ADVISERS__FIELD_LABEL__SUBJECT"
            defaultMessage="Adresát"
          />
        }
        filters={contactFiltersFields}
        columns={contactColumns}
        autoFocus={true}
        autocompleteSource={contacts}
        dialogSource={contactSource}
        labelMapper={dictionaryFieldLabelMapper}
        maxRows={9}
        dialogWidth={1000}
        onCreate={() => createRef.current?.executeAction()}
        onEdit={() => editRef.current?.executeAction()}
        dialogSorts={CONTACTS_SORT}
        createRowStyle={createRowStyle}
        AutocompleteItemComponent={ContactAutocompleteItem}
      />
      <FormSelect
        name="legalAdviserType"
        label={
          <FormattedMessage
            id="ES__LEGAL_ADVISERS__FIELD_LABEL__LEGAL_ADVISER_TYPE"
            defaultMessage="Typ právního zástupce"
          />
        }
        source={legalAdviserTypesSource}
        valueIsId={true}
      />
      <FormDictionaryField
        name="clients"
        label={
          <FormattedMessage
            id="ES__LEGAL_ADVISERS__FIELD_LABEL__CLIENTS"
            defaultMessage="Klienti"
          />
        }
        filters={[
          {
            label: intl.formatMessage({
              id: 'ES__LEGAL_ADVISER_TOOLBAR__FILTER__TEXT',
              defaultMessage: 'Název',
            }),
            datakey: 'name',
            filterkey: 'name',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterTextCell,
          },
        ]}
        columns={[
          {
            name: intl.formatMessage({
              id: 'ES__LEGAL_ADVISER_TOOLBAR__FILTER__TEXT',
              defaultMessage: 'Název',
            }),
            datakey: 'name',
            width: 300,
          },
        ]}
        multiple={true}
        required={true}
        autocompleteSource={participants}
        dialogSource={participantSource}
        labelMapper={dictionaryFieldLabelMapper}
      />
    </>
  );
}
