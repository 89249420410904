export const DICTIONARIES = {
  title: 'Číselníky',
  description:
    'Přehledy všech číselníků evidovaných v systému. Hodnoty jednotlivých číselníků je možné používat v definovaných částech aplikace.',
  actions: [
    {
      name: 'Vytvoření záznamu',
      description: 'Vytvoření záznamu číselníku.',
    },
    {
      name: 'Upravení záznamu',
      description:
        'Uživatel může upravovat jednotlivé atributy záznamu číselníku.',
    },
    {
      name: 'Smazání záznamu',
      description: 'Uživatel může smazat záznam číselníku.',
    },
    {
      name: 'Zneplatnit',
      description:
        'Zneplatnění záznamu číselníku. Záznam následně nebude možné v aplikaci používat.',
    },
    {
      name: 'Obnovit platnost',
      description: 'Obnovení platnosti záznamu číselníku.',
    },
  ],
};
