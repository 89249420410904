export function useAvgProceedingDurationColumns() {
  return [
    {
      name: 'Spisy',
      datakey: 'proceeding',
      width: 400,
    },

    {
      name: 'Průměrná délka řízení',
      datakey: 'count',
      width: 200,
    },
  ];
}
