import { useContext } from 'react';
import { DetailContext, DetailHandle, abortableFetch } from '@eas/common-web';
import { Dispatch } from '@models';
import { DispatchState, EvidenceApiUrl } from '@enums';

export const dispatchDocumentsApiCall = (ids?: string[]) => {
  return abortableFetch(`${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/dispatch`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(ids),
  });
};

export function useDispatchSingleDocumentDialog() {
  const { source } = useContext<DetailHandle<Dispatch>>(DetailContext);

  const showButton = source?.data?.state === DispatchState.SENT; // TODO rework

  const callApi = (id: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/${id}/dispatch`,
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      }
    );
  };

  return { callApi, showButton };
}
