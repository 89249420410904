import { merge } from 'lodash';
import { useContext } from 'react';
import {
  ApiFilterOperation,
  DetailContext,
  DetailToolbarBtnName,
  EvidenceProps,
  Filter,
  createItemFactory,
} from '@eas/common-web';
import { DeliveredDocument } from '@models';
import { DocumentType, EsFileState, EvidenceApiUrl, UIElement } from '@enums';
import { useDeliveredDocumentPermission } from '../document/document-permission';
import { useDocuments } from '../document/documents';
import { Fields } from './delivered-document-fields';
import { useValidationSchema } from './delivered-document-schema';

export function useDeliveredDocuments({
  evidenceProps,
}: {
  evidenceProps?: Partial<EvidenceProps<DeliveredDocument>>;
}) {
  const validationSchema = useValidationSchema();
  const defaultPreFilters = [
    ...(evidenceProps?.tableProps?.defaultPreFilters as Filter[]),
    {
      field: 'type.id',
      operation: ApiFilterOperation.EQ,
      value: DocumentType.DELIVERED,
    },
  ];

  const props = merge(
    {
      evidenceProps: {
        apiProps: {
          url: EvidenceApiUrl.DOCUMENTS,
          createItem: createItemFactory(),
        },
        tableProps: {
          defaultPreFilters,
        },
        detailProps: {
          FieldsComponent: Fields,
          validationSchema,
          toolbarProps: {
            subtitle: (value: DeliveredDocument) =>
              value?.referenceNumber ?? value?.incomingNumber ?? '',
            showBtn: (btn: DetailToolbarBtnName) =>
              !['NEW', 'REMOVE'].includes(btn),
            disableBtn: function useDisable(button: DetailToolbarBtnName) {
              const { source } = useContext(DetailContext);
              const { hasPermission } = useDeliveredDocumentPermission(
                source.data
              );

              const isClosed = [
                EsFileState.REGISTRY,
                EsFileState.CLOSED,
              ].includes(source?.data?.esFile?.state);

              const updatePermission = hasPermission(
                UIElement.DeliveredDocument.EDIT_DOCUMENT_BUTTON
              );

              if (button === 'EDIT' && (!updatePermission || isClosed)) {
                return true;
              }
            },
          },
        },
      },
    },
    { evidenceProps }
  );

  return useDocuments(props);
}
