/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Court file messages
 */
const ASSIGN_FILE_NUMBER: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'ATTRIBUTE_PROPOSER_IS_NULL'
  | 'ATTRIBUTE_STATS_DATE_IS_NULL'
> = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__ES_FILE__ASSIGN_FILE_NUMBER__SUCCESS"
      defaultMessage="Spisová značka byla úspěšně přidělena."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__ES_FILE__ASSIGN_FILE_NUMBER__ERROR"
      defaultMessage="Neznámá chyba při přidělování spisové značky."
    />,
    SnackbarVariant.ERROR,
  ],
  ATTRIBUTE_PROPOSER_IS_NULL: [
    <FormattedMessage
      id="ES__MESSAGE__ES_FILE__ASSIGN_FILE_NUMBER__ATTRIBUTE_PROPOSER_IS_NULL"
      defaultMessage="Pro úspěšné přidělení spisové značky doplňte aspoň jedného navrhovatele."
    />,
    SnackbarVariant.ERROR,
  ],
  ATTRIBUTE_STATS_DATE_IS_NULL: [
    <FormattedMessage
      id="ES__MESSAGE__ES_FILE__ASSIGN_FILE_NUMBER__ATTRIBUTE_STATS_NUMBER_IS_NULL"
      defaultMessage="Pro úspěšné přidělení spisové značky doplňte datum pro statistiku."
    />,
    SnackbarVariant.ERROR,
  ],
};

const COPY_CHALLENGED_ACT: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__ES_FILE__COPY_CHALLENGED_ACT__SUCCESS"
      defaultMessage="Napadený akt byl úspěšně vytvořen."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__ES_FILE__COPY_CHALLENGED_ACT__ERROR"
      defaultMessage="Chyba při vytváření napadeného aktu."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default { ASSIGN_FILE_NUMBER, COPY_CHALLENGED_ACT };
