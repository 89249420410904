import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useSenates } from '@modules/dict-senate/dict-senate-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DictSenate, Judge } from '@models';

export function useColumns(): TableColumn<Judge>[] {
  const intl = useIntl();
  const { judgeFunctions } = useContext(StaticDataContext);

  const { columnActive } = DictionaryEvidence.useDictionaryColumns<Judge>();
  const useFunctions = () => useStaticListSource(judgeFunctions);

  return [
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ES__JUDGES__TABLE__COLUMN__NAME',
        defaultMessage: 'Soudce',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    columnActive,
    {
      datakey: 'showInNalus',
      name: intl.formatMessage({
        id: 'ES__JUDGES__TABLE__COLUMN__SHOW_IN_NALUS',
        defaultMessage: 'Zobrazit v NALUSu',
      }),
      width: 200,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'function',
      sortkey: 'function.id',
      filterkey: 'function.name',
      name: intl.formatMessage({
        id: 'ES__JUDGES__TABLE__COLUMN__FUNCTION',
        defaultMessage: 'Funkce',
      }),
      width: 200,
      valueMapper: TableCells.useSelectCellFactory(useFunctions),
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useFunctions),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'senates',
      filterkey: 'senates.id',
      sortkey: 'senates.number',
      name: intl.formatMessage({
        id: 'ES__JUDGES__TABLE__COLUMN__SENATES',
        defaultMessage: 'Senáty',
      }),
      width: 200,
      valueMapper: ({ value }) =>
        value.map((senate: DictSenate) => senate.label).join(', '),
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useSenates),
      sortable: true,
      filterable: false,
    },
  ];
}
