import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictNote } from '@models';
import { EntityName, EvidenceApiUrl, HistoryEntityType } from '@enums';
import { useColumns } from './dict-note-columns';
import { Fields } from './dict-note-fields';
import { useValidationSchema } from './dict-note-schema';

export function DictNotes() {
  const intl = useIntl();
  const columns = useColumns();
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictNote>({
    version: 2,
    identifier: 'NOTES',
    apiProps: {
      url: EvidenceApiUrl.DICT_NOTES,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.NOTE,
      }),
    },
    tableProps: {
      columns: columns,
      tableName: intl.formatMessage({
        id: 'ES__DICT_NOTES__TABLE__TITLE',
        defaultMessage: 'Poznámky',
      }),
      showReportButton: false,
      defaultSorts: [
        {
          field: 'description',
          datakey: 'description',
          order: 'ASC',
          type: 'FIELD',
        },
      ],
    },
    detailProps: {
      entityName: EntityName.NOTE,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_NOTES__DETAIL__TITLE',
          defaultMessage: 'Poznámka',
        }),
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
