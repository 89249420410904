import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DecisionText } from '@composite/decision-text/decision-text';
import { AnonymizedDecisionActions } from '../anonymized-decision-actions';

export function LegalSentenceFields() {
  return (
    <>
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__TEXT_LEGAL_SENTENCE"
            defaultMessage="Právní věta - plné znění"
          />
        }
        name="text.legalSentence"
        enableHighlighting={true}
      />
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__ANONYMIZED_TEXT_LEGAL_SENTENCE"
            defaultMessage="Právní věta - anonymizováno"
          />
        }
        name="anonymizedText.legalSentence"
        Before={<AnonymizedDecisionActions fieldName="legalSentence" />}
        enableHighlighting={true}
      />
    </>
  );
}
