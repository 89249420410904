import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictSubmissionContent } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { Fields } from './dict-submission-content-fields';

export function DictSubmissionContents() {
  const intl = useIntl();
  const { columnName, columnActive } =
    DictionaryEvidence.useDictionaryColumns<DictSubmissionContent>();

  const evidence = useDictionaryEvidence<DictSubmissionContent>({
    version: 2,
    identifier: 'SUBMISSION_CONTENTS',
    apiProps: {
      url: EvidenceApiUrl.DICT_SUBMISSION_CONTENTS,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.SUBMISSION_CONTENT,
      }),
    },
    tableProps: {
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
      columns: [columnName, columnActive],
      tableName: intl.formatMessage({
        id: 'ES__DICT_SUBMISSION_CONTENT__TABLE__TITLE',
        defaultMessage: 'Obsahy podání',
      }),
      reportTag: ExportTags.DICT_SUBMISSION_CONTENT_SPR,
    },
    detailProps: {
      entityName: EntityName.SUBMISSION_CONTENT,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_SUBMISSION_CONTENT__DETAIL__TITLE',
          defaultMessage: 'Obsah podání',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
