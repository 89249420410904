import React from 'react';
import {
  DetailToolbarButton2,
  DetailToolbarButtonProps,
} from '@eas/common-web';
import { useRemoveDialog } from './dialog-actions/remove/remove-hook';

export function RemoveButton(props: DetailToolbarButtonProps) {
  const removeButton = useRemoveDialog();

  return (
    <>
      {removeButton?.onClick && (
        <DetailToolbarButton2 {...props} {...removeButton} />
      )}
    </>
  );
}
