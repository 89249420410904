export interface AuthContextType {
  type?: AuthType;
  loginUrl?: string;
}

export enum AuthType {
  LDAP = 'LDAP',
  FORM = 'FORM',
  KERBEROS = 'KERBEROS',
}
