import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Deadline } from '@models';
import { DocumentType, EvidenceBrowserUrl } from '@enums';
import { Redirect } from './../redirect/redirect';

function getDocumentUrl(type: DocumentType) {
  switch (type) {
    case DocumentType.DELIVERED:
      return EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED;
      break;
    case DocumentType.INTERNAL:
      return EvidenceBrowserUrl.DOCUMENTS_INTERNAL;
      break;
    case DocumentType.OUTGOING:
      return EvidenceBrowserUrl.DOCUMENTS_OUTGOING;
      break;

    default:
      return EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED;
      break;
  }
}

export function useDocumentColumns(): TableFieldColumn<Deadline>[] {
  const intl = useIntl();

  const { deadlineStates } = useContext(StaticDataContext);
  const useDeadlineStates = () => useStaticListSource(deadlineStates ?? []);

  return [
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      datakey: 'state',
      sortkey: 'state.name',
      width: 100,
      CellComponent: TableFieldCells.useSelectCellFactory(useDeadlineStates),
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__STARTING_FROM',
        defaultMessage: 'Počáteční datum',
      }),
      datakey: 'startingFrom',
      sortkey: 'startingFrom',
      width: 120,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__END_DATE',
        defaultMessage: 'Konečné datum',
      }),
      datakey: 'endDate',
      sortkey: 'endDate',
      width: 120,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__DAYS_REMAINING',
        defaultMessage: 'Zbývá',
      }),
      datakey: 'daysRemaining',
      sortkey: 'daysRemaining',
      width: 60,
    },
  ];
}

export function useEsFileColumns(): TableFieldColumn<Deadline>[] {
  const intl = useIntl();

  const { deadlineStates } = useContext(StaticDataContext);
  const useDeadlineStates = () => useStaticListSource(deadlineStates ?? []);

  return [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: function Cell(props: TableFieldCellProps<Deadline>) {
        const url = getDocumentUrl(props.rowValue.document!.type!);

        return (
          <>
            <Redirect url={url} id={props.rowValue.document!.id} />
          </>
        );
      },
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      datakey: 'document.referenceNumber',
      sortkey: 'document.referenceNumber',
      width: 120,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      datakey: 'description',
      sortkey: 'description',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__END_DATE',
        defaultMessage: 'Konečné datum',
      }),
      datakey: 'endDate',
      sortkey: 'endDate',
      width: 120,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__DAYS_REMAINING',
        defaultMessage: 'Zbývá',
      }),
      datakey: 'daysRemaining',
      sortkey: 'daysRemaining',
      width: 60,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      datakey: 'state',
      sortkey: 'state.name',
      width: 100,
      CellComponent: TableFieldCells.useSelectCellFactory(useDeadlineStates),
    },
  ];
}
