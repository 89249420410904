import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {
  ActionButtonHandle,
  ApiFilterOperation,
  DetailContext,
  DetailMode,
  FieldFilter,
  Filter,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarButtons,
  TableFieldToolbarHandle,
  TableFieldToolbarProps,
  useStaticListSource,
} from '@eas/common-web';
import { useUserRoles } from '@modules/role/role-api';
import {
  CancelTaskButton,
  ProcessTaskButton,
} from '@modules/task/dialog-actions/change-task-state-hook';
import { useTasksFields } from '@modules/task/task-fields';
import { useValidationSchema } from '@modules/task/task-schema';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { DictionaryObject, Task } from '@models';
import { Role, TaskState, TaskType } from '@enums';
import { useAddTaskDialog } from './dialog-actions/add-task-hook';
import { useEditTaskDialog } from './dialog-actions/edit-task-hook';

export const TaskToolbar = forwardRef(function TasksToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    preFilters,
    disabled,
  }: TableFieldToolbarProps & {
    disabled: boolean;
    preFilters: Filter[];
  },
  ref: Ref<TableFieldToolbarHandle>
) {
  const classes = useStyles();
  const intl = useIntl();

  const detailCtx = useContext(DetailContext);

  const { taskTypes, taskStates } = useContext(StaticDataContext);

  const { source: tasks } = useContext(RemoteTableFieldContext);
  const { AddTaskButton } = useAddTaskDialog();
  const { callApi: editTaskApiCall } = useEditTaskDialog();

  const validationSchema = useValidationSchema();
  const FormFields = useTasksFields();

  useEffect(
    () => {
      setSelectedIndex(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailCtx?.source]
  );

  const viewRef = useRef<ActionButtonHandle>(null);
  const processRef = useRef<ActionButtonHandle>(null);
  const cancelRef = useRef<ActionButtonHandle>(null);

  useImperativeHandle(
    ref,
    () => ({
      executeAction: (action) => {
        switch (action) {
          case 'VIEW':
            viewRef.current?.executeAction();
            break;
          case 'PROCESS':
            processRef.current?.executeAction();
            break;
          case 'CANCEL':
            cancelRef.current?.executeAction();
            break;

          default:
            break;
        }
      },
    }),
    []
  );

  const filtersFields = useFiltersFields(taskTypes, taskStates);

  const selectedTask =
    selectedIndex !== undefined ? tasks.items[selectedIndex] : {};

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <AddTaskButton
          buttonLabel={intl.formatMessage({
            id: 'ES__ES_FILES__TASKS_TOOLBAR__ADD_TASK',
            defaultMessage: 'Přidat úlohu',
          })}
          ButtonComponent={({ label, onClick }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled ?? false}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__TASKS_TOOLBAR__ADD_TASK__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formInitialValues={
            {
              esFile: detailCtx?.source.data,
              type: TaskType.CUSTOM,
              state: TaskState.SOLVING,
            } as Task
          }
        />

        <DetailActionButton
          ref={viewRef}
          promptKey="VIEW_TASK"
          apiCall={(_, formData) => editTaskApiCall(selectedTask.id, formData)}
          buttonLabel={intl.formatMessage({
            id: 'ES__TASKS_TOOLBAR__EDIT_TASK',
            defaultMessage: 'Zobrazit úlohu',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__TASKS_TOOLBAR__VIEW_TASK__TITLE',
            defaultMessage: 'Úloha',
          })}
          dialogText={null}
          dialogWidth={700}
          FormFields={() => (
            <DetailContext.Provider
              value={{
                ...detailCtx,
                mode: DetailMode.VIEW,
              }}
            >
              <FormFields />
            </DetailContext.Provider>
          )}
          modes={[]}
          formInitialValues={selectedTask}
          formValidationSchema={validationSchema}
        />

        <ProcessTaskButton ref={processRef} task={selectedTask} modes={[]} />
        <CancelTaskButton ref={cancelRef} task={selectedTask} modes={[]} />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={tasks}
          preFilters={preFilters}
          filtersFields={filtersFields}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    ref: Ref<TableFieldToolbarHandle>;
    disabled: boolean;
    preFilters: Filter[];
  }
) => ReactElement;

function useFiltersFields(
  types: DictionaryObject[],
  states: DictionaryObject[]
) {
  const { source } = useContext(DetailContext);
  const intl = useIntl();

  const useTypes = () => useStaticListSource(types);
  const useStates = () => useStaticListSource(states);

  const useSolverUsers = () =>
    useUserRoles({
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'agendas.id',
          value: source?.data?.agenda,
        },
        {
          operation: ApiFilterOperation.EQ,
          field: 'role.id',
          value: Role.SOLVER,
        },
      ],
    });

  const filtersFields = [
    {
      label: intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__FILTER__NAME',
        defaultMessage: 'Název',
      }),
      datakey: 'name',
      filterkey: 'name',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__FILTER__TYPE',
        defaultMessage: 'Typ úlohy',
      }),
      datakey: 'type',
      filterkey: 'type.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useTypes),
    },
    {
      label: intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__FILTER__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      datakey: 'document.referenceNumber',
      filterkey: 'document.referenceNumber',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__FILTER__CREATED_BY',
        defaultMessage: 'Vytvořil',
      }),
      datakey: 'createdBy',
      filterkey: 'createdBy.name',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__FILTER__SOLVER',
        defaultMessage: 'Pro uživatele',
      }),
      datakey: 'solver',
      filterkey: 'solver.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterAutocompleteCell(
        useSolverUsers,
        (value) => value.label ?? ''
      ),
    },
    {
      label: intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__FILTER__CREATED',
        defaultMessage: 'Datum vytvoření',
      }),
      datakey: 'created',
      filterkey: 'created',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterDateCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__FILTER__STATE',
        defaultMessage: 'Stav úlohy',
      }),
      datakey: 'state',
      filterkey: 'state.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useStates),
    },
  ];

  return filtersFields;
}
