import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';
import { ExportTags, RoleAuthority } from '@enums';

export function useExportTags() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: ExportTags.DICT_ACT_TYPES, name: 'Druhy aktu' },
    { id: ExportTags.DOCUMENTS, name: 'Doručené dokumenty' },
    {
      id: ExportTags.EXPORT_REQUESTS,
      name: 'Fronta exportu',
    },
    { id: ExportTags.DICT_ENVELOPE_CONTENTS, name: 'Obsahy obálek' },
    { id: ExportTags.DICT_SUBMISSION_CONTENT_SPR, name: 'Obsahy podání SPR' },
    { id: ExportTags.DICT_OPPONENTS, name: 'Odpůrci' },
    {
      id: ExportTags.DICT_PROCEEDINGS_SUBJECTS,
      name: 'Předměty řízení',
    },
    {
      id: ExportTags.DICT_ZIP_CODES,
      name: 'PSČ',
    },
    { id: ExportTags.DECISIONS, name: 'Rozhodnutí' },
    { id: ExportTags.DICT_SENATES, name: 'Senáty' },
    { id: ExportTags.DICT_JUDGES, name: 'Soudci' },
    {
      id: ExportTags.DICT_HOLIDAYS,
      name: 'Svátky',
    },
    {
      id: ExportTags.EXPORT_TEMPLATES,
      name: 'Šablony exportu',
    },
    { id: ExportTags.DICT_BARCODE_PRINTERS, name: 'Tiskárny čárových kódů' },
    {
      id: ExportTags.DICT_CONCERNED_AUTHORITY_TYPES,
      name: 'Typy dotčeného orgánu',
    },
    { id: ExportTags.DICT_DISPATCH_TYPES, name: 'Typy odeslání' },
    { id: ExportTags.DICT_PROCESSING_TYPES, name: 'Typy řízení' },
    {
      id: ExportTags.USERS,
      name: 'Uživatelé',
    },
    { id: ExportTags.USER_ROLES, name: 'Uživatelské role' },
    { id: ExportTags.DICT_SUBJECT_INDEX, name: 'Věcný rejstřík' },
    { id: ExportTags.DICT_COUNTRIES, name: 'Země' },
    { id: ExportTags.DICT_SETTLEMENT_METHODS, name: 'Způsoby vyřízení' },
  ]);
}

export function useReportPermissions() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: RoleAuthority.SUPERADMIN, name: 'Superadmin' },
  ]);
}
