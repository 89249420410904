import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useDeleteRegulationDialog() {
  const callApi = useEventCallback((esFileId: string, regulationId: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/${esFileId}/regulation/${regulationId}`,
      {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      }
    );
  });

  return {
    callApi,
  };
}
