import React from 'react';
import { AuthContext } from './auth-context';
import { useAuth } from './auth-hook';

export function AuthProvider({ children }: { children?: React.ReactNode }) {
  const contextData = useAuth();

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
}
