import { abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export const updateNotificationPreferences = (body: any) =>
  abortableFetch(`${EvidenceApiUrl.NOTIFICATION_PREFERENCES}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(body),
  });
