import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictBriefSummary } from '@models';
import { EntityName, EvidenceApiUrl, HistoryEntityType } from '@enums';
import { useColumns } from './dict-brief-summary-columns';
import { Fields } from './dict-brief-summary-fields';
import { useValidationSchema } from './dict-brief-summary-schema';

export function DictBriefSummaries() {
  const validationSchema = useValidationSchema();
  const intl = useIntl();

  const evidence = useDictionaryEvidence<DictBriefSummary>({
    version: 3,
    identifier: 'BRIEF_SUMMARIES',
    apiProps: {
      url: EvidenceApiUrl.DICT_BRIEF_SUMMARIES,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.BRIEF_SUMMARY,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_BRIEF_SUMMARIES__TABLE__TITLE',
        defaultMessage: 'Stručné obsahy',
      }),
      showReportButton: false,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.BRIEF_SUMMARY,
      validationSchema,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_BRIEF_SUMMARIES__DETAIL__TITLE',
          defaultMessage: 'Stručný obsah',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
