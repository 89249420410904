import * as Yup from 'yup';
import { DictSenate, UserRole } from '@models';

export function useValidationSchema() {
  return Yup.object<DictSenate>().shape({
    name: Yup.string().nullable().required(),
    number: Yup.number().nullable().required(),
    analystUserRole: Yup.mixed<UserRole>().nullable().required(),
  });
}
