import {
  DictionaryEvidence,
  DictionaryObject,
  EvidenceProps,
} from '@eas/common-web';
import { useEvidence } from './evidence';

export function useDictionaryEvidence<OBJECT extends DictionaryObject>(
  options: EvidenceProps<OBJECT>
): EvidenceProps<OBJECT> {
  const props = useEvidence(options);

  return DictionaryEvidence.useDictionaryEvidence(props);
}
