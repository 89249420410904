import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  DetailContext,
  DetailHandle,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DisciplinaryProceedingsFile } from '@models';
import { Agenda, EsFileState, EssRecordState } from '@enums';

const useStyles = makeStyles(() => ({
  divider: {
    width: '100%',
    margin: '10px 0',
  },
}));

export function useFormFields() {
  const { states } = useContext(StaticDataContext);
  const { source } =
    useContext<DetailHandle<DisciplinaryProceedingsFile>>(DetailContext);

  return function FormFields() {
    const classes = useStyles();

    const filtered = states
      .filter(
        (state) =>
          state?.forAgendas?.includes(Agenda.DISCIPLINARY_PROCEEDINGS) &&
          state.id !== EsFileState.REGISTRY &&
          state.id !== EsFileState.SHREDDED
      )
      .filter((state) => {
        if (state.id === EsFileState.CLOSED) {
          return source?.data?.essRecord?.state === EssRecordState.RESOLVED;
        }

        if (source.data?.state === EsFileState.NEW) {
          return state.id !== EsFileState.NEW;
        }
        if (source.data?.state === EsFileState.IN_PROGRESS) {
          return state.id !== EsFileState.IN_PROGRESS;
        }
      });

    const statesSource = useStaticListSource(filtered);

    return (
      <>
        <FormSelect
          source={statesSource}
          name="state"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILE__CHANGE_SATE_HOOK__FIELD_LABEL__STATE"
              defaultMessage="Stav"
            />
          }
          labelOptions={{
            hide: true,
          }}
          valueIsId={true}
        />
        <div className={classes.divider} />
      </>
    );
  };
}
