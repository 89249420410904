import { stubTrue } from 'lodash';
import { HookedSource, useScrollableSource } from '@eas/common-web';
import { useHistorySource } from '@composite/history/history-api';
import { Decision } from '@models';
import { EvidenceApiUrl, HistoryEntityType } from '@enums';

export const NOTE_SOURCE = 'NOTE_SOURCE';

function useNoteSource(): HookedSource<Decision> {
  const source = useScrollableSource();

  return {
    [NOTE_SOURCE]: {
      source,
      shouldLoad: () => true,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.DECISIONS}/${id}/note/list`);
      },
    },
  };
}

export function useDecisionsHookedSources(): HookedSource<Decision> {
  const notes = useNoteSource();
  const history = useHistorySource({
    shouldLoad: stubTrue,
    entityType: HistoryEntityType.DECISION,
  });

  return { ...notes, ...history };
}
