import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  FilesContext,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { CreatedByCell } from '@components/evidence/authored-evidence/created-by-cell';
import { DocumentAttachment } from '@models';
import { AttachmentType, EssDocumentState } from '@enums';

const useStyles = makeStyles(() => ({
  downloadButton: {
    minWidth: '0',
    marginLeft: '2pt',
    boxShadow: '0 0 0',
    padding: '2pt',
    height: 23,
    '& p': {
      // contradict the default 'none'
      display: 'block',
    },
  },
}));

export function useAttachmentColumns(
  attachmentType?: AttachmentType
): TableFieldColumn<DocumentAttachment>[] {
  const intl = useIntl();
  const classes = useStyles();

  const { getFileUrl } = useContext(FilesContext);

  const columns = useMemo(
    () => [
      {
        datakey: 'download',
        name: '',
        width: 42,
        CellComponent: function Cell(
          props: TableFieldCellProps<DocumentAttachment>
        ) {
          return (
            <Button
              download={true}
              size="small"
              classes={{ root: classes.downloadButton }}
              variant="text"
              onClick={(e: any) => e.stopPropagation()}
              disabled={
                !props.rowValue?.file?.id ||
                props.rowValue.document.essDocument?.state ===
                  EssDocumentState.SHREDDED
              }
              href={getFileUrl(props.rowValue?.file?.id)}
            >
              <GetAppIcon />
            </Button>
          );
        },
      },
      {
        datakey: 'label',
        name: intl.formatMessage({
          id: 'ES__DOCUMENT_DELIVERED__DETAIL__ATTACHMENT_COLUMN__LABEL',
          defaultMessage: 'Označení',
        }),
        width: 100,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        datakey: 'name',
        name: intl.formatMessage({
          id: 'ES__DOCUMENT_DELIVERED__DETAIL__ATTACHMENT_COLUMN__TYPE',
          defaultMessage: 'Název přílohy',
        }),
        width: 250,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        datakey: 'file.name',
        name: intl.formatMessage({
          id: 'ES__DOCUMENT_DELIVERED__DETAIL__ATTACHMENT_COLUMN__FILE',
          defaultMessage: 'Soubor',
        }),
        width: 250,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        datakey: 'created',
        name: intl.formatMessage({
          id: 'ES__DOCUMENT_DELIVERED__DETAIL__ATTACHMENT_COLUMN__CREATED',
          defaultMessage: 'Datum vytvoření',
        }),
        width: 250,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        datakey: 'createdBy.name',
        name: intl.formatMessage({
          id: 'ES__DOCUMENT_DELIVERED__DETAIL__ATTACHMENT_COLUMN__CREATED_BY',
          defaultMessage: 'Tvůrce',
        }),
        width: 250,
        CellComponent: CreatedByCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  if (
    attachmentType === AttachmentType.INTERNAL ||
    attachmentType === AttachmentType.OUTGOING ||
    attachmentType === AttachmentType.ENVELOPE ||
    attachmentType === AttachmentType.DELIVERY_NOTE
  ) {
    return columns.filter((c) => c.datakey !== 'label');
  }

  return columns;
}
