import { get, noop, stubFalse } from 'lodash';
import React, { Ref, forwardRef, useContext, useState } from 'react';
import { green, orange } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  TableField,
  TableFieldCells,
  TableFieldContext,
  TableFieldRowProps,
  useEventCallback,
} from '@eas/common-web';
import { useTheme } from '@components/theme/theme-hook';
import { HistoryFieldUnion } from '@models';
import { useDetailColumns } from './history-columns';

const useStyles = (color: any) =>
  makeStyles((theme) => ({
    rowWrapper: {
      cursor: 'pointer',
      minHeight: 30,
      borderBottom: '1px solid #cdcdcd',
    },
    row: {
      boxSizing: 'border-box',
      minWidth: '100%',
      padding: '0px 16px 1px',
      '&:hover': {
        backgroundColor: `${theme.palette.highlight} !important`,
      },
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
    },
    cellWrapper: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      paddingRight: 10,
      boxSizing: 'border-box',
    },
    tableWrapper: {
      backgroundColor: color[50],
    },
  }))();

export const HistoryTableFieldRow = forwardRef(function HistoryTableFieldRow(
  { index, value }: TableFieldRowProps<any>,
  ref: Ref<HTMLDivElement>
) {
  const color = value.operation === 'ADD' ? green : orange;
  const theme = useTheme({ primaryColor: color[500] });
  const classes = useStyles(color);

  const { filteredColumns, createRowStyle } = useContext(TableFieldContext);
  const columns = useDetailColumns();
  const rowStyle = createRowStyle(value);

  const [openState, setOpenState] = useState<{ [key: number]: boolean }>({});
  const open = openState[index];

  const changes = value.changes ?? [];

  const handleOpen = useEventCallback(() => {
    setOpenState({
      ...openState,
      [index]: !open,
    });
  });

  return (
    <div className={classes.rowWrapper} ref={ref}>
      <div className={classes.row} style={rowStyle} onClick={handleOpen}>
        {filteredColumns.map((column, i) => {
          const { CellComponent = TableFieldCells.TextCell } = column;
          return (
            <div
              key={i}
              className={classes.cellWrapper}
              style={{
                width: column.width,
              }}
            >
              <CellComponent
                index={index}
                value={get(value, column.datakey, '')}
                rowValue={value}
                column={column}
              />
              {column.datakey === 'id' &&
                changes?.length > 0 &&
                (open ? (
                  <KeyboardArrowUpIcon onClick={handleOpen} style={rowStyle} />
                ) : (
                  <KeyboardArrowDownIcon
                    onClick={handleOpen}
                    style={rowStyle}
                  />
                ))}
            </div>
          );
        })}
      </div>
      <div>
        {!!changes?.length && open && (
          <div className={classes.tableWrapper}>
            <ThemeProvider theme={theme}>
              <TableField<HistoryFieldUnion>
                value={changes}
                onChange={noop}
                columns={columns}
                showRadioCond={stubFalse}
                maxRows={5}
                showToolbar={false}
              />
            </ThemeProvider>
          </div>
        )}
      </div>
    </div>
  );
});
