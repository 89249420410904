import { useIntl } from 'react-intl';
import { abortableFetch, useEventCallback } from '@eas/common-web';
import {
  RedirectAction,
  useRedirect,
} from '@composite/redirect/dialog-redirect';
import { EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';

export function useCopyDialog() {
  const intl = useIntl();

  const callApi = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/copy`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  const redirectBag = useRedirect({
    options: [
      {
        id: RedirectAction.REDIRECT_TO_RESULT,
        toResult: true,
        name: intl.formatMessage({
          id: 'REDIRECT__OPTION__COPY',
          defaultMessage: 'Pokračovat do kopie dokumentu',
        }),
      },
      {
        id: RedirectAction.STAY_IN_EVIDENCE,
        toResult: true,
        name: intl.formatMessage({
          id: 'REDIRECT__OPTION__ORIGINAL',
          defaultMessage: 'Pokračovat v originále',
        }),
      },
    ],
    redirectTo: EvidenceBrowserUrl.DOCUMENTS_DELIVERED_UNPROCESSED,
  });

  return {
    callApi,
    redirectBag,
  };
}
