import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonComponentProps,
  ActionButtonHandle,
  DetailContext,
  DetailHandle,
  DetailMode,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { EsFile } from '@models';
import { ChallengedActDialog } from './challenged-act-dialog';
import { useValidationSchema } from './challenged-act-schema';
import { useAddChallengedActDialog } from './dialog-actions/add-challenged-act-hook';
import { CopyButton } from './dialog-actions/copy-challenged-act-hook';
import { useDeleteChallengedActDialog } from './dialog-actions/delete-challenged-act-hook';
import { useEditChallengedAactDialog } from './dialog-actions/edit-challenged-act-hook';

export const ChallengedActToolbar = forwardRef(function ChallengedActToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
  }: TableFieldToolbarProps & { disabled: boolean },
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();

  const intl = useIntl();

  const { source: detailSource } =
    useContext<DetailHandle<EsFile>>(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const challengedActs = tableSource.items;

  const validationSchema = useValidationSchema();

  const { callApi: addChallengedActApiCall } = useAddChallengedActDialog();
  const { callApi: editChallengedActApiCall } = useEditChallengedAactDialog();
  const { callApi: deleteChallengedActApiCall } =
    useDeleteChallengedActDialog();

  const selectedChallengedAct =
    selectedIndex !== undefined ? challengedActs[selectedIndex] : {};

  const isSelected = selectedIndex !== undefined;

  useEffect(() => {
    setSelectedIndex(undefined);
  }, [detailSource]);

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_CHALLENGED_ACT"
          closeCallback={detailSource.refresh}
          apiCall={(id, formData) => addChallengedActApiCall(formData)}
          buttonLabel={intl.formatMessage({
            id: 'ES__CHALLENGED_ACTS_TOOLBAR__ADD_CHALLENGED_ACT',
            defaultMessage: 'Přidat napadený akt',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DIALOG_ADD_CHALLENGED_ACT__TITLE',
            defaultMessage: 'Napadený akt',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DIALOG_ADD_CHALLENGED_ACT__TEXT',
            defaultMessage: 'Vyplňte formulář.',
          })}
          dialogActions={(loading) => [
            <CopyButton key="copy_button" loading={loading} />,
          ]}
          ButtonComponent={({ label, onClick }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__CHALLENGED_ACTS_TOOLBAR__ADD_CHALLENGED_ACT__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={ChallengedActDialog}
          formValidationSchema={validationSchema}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          dialogWidth={700}
        />
        <DetailActionButton
          ref={ref}
          promptKey="EDIT_CHALLENGED_ACT"
          apiCall={(id, formData) =>
            editChallengedActApiCall(
              challengedActs![selectedIndex!].id,
              formData
            )
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__CHALLENGED_ACTS_TOOLBAR__EDIT_CHALLENGED_ACT',
            defaultMessage: 'Editovat napadený akt',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__CHALLENGED_ACTS_TOOLBAR__EDIT_CHALLENGED_ACT__TITLE',
            defaultMessage: 'Napadený akt',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__CHALLENGED_ACTS_TOOLBAR__EDIT_CHALLENGED_ACT__TEXT',
            defaultMessage: 'Vyplňte formulář.',
          })}
          dialogWidth={700}
          FormFields={ChallengedActDialog}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__CHALLENGED_ACTS_TOOLBAR__EDIT_CHALLENGED_ACT__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formInitialValues={selectedChallengedAct}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formValidationSchema={validationSchema}
        />
        <DetailActionButton
          promptKey="DELETE_CHALLENGED_ACT"
          apiCall={() =>
            deleteChallengedActApiCall(challengedActs![selectedIndex!].id)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__CHALLENGED_ACTS_TOOLBAR__DELETE_CHALLENGED_ACT',
            defaultMessage: 'Odstranit napadený akt',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__CHALLENGED_ACTS_TOOLBAR__DELETE_CHALLENGED_ACT__TITLE',
            defaultMessage: 'Napadený akt',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__CHALLENGED_ACTS_TOOLBAR__DELETE_CHALLENGED_ACT__TEXT',
            defaultMessage: 'Skutečně chcete odstranit napadený akt?',
          })}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">Smazat</Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    ref: Ref<ActionButtonHandle>;
    disabled: boolean;
  }
) => ReactElement;
