import { DomainObject, HookedSource } from '@eas/common-web';
import { useHistorySource } from '@composite/history/history-api';
import { HistoryEntityType } from '@enums';

export function useDictionaryHookedSources<TYPE extends DomainObject>(props: {
  shouldLoad?: (item: TYPE) => boolean;
  entityType: HistoryEntityType;
}) {
  return function useHistory(): HookedSource<TYPE> {
    const history = useHistorySource(props);

    return history;
  };
}
