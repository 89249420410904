/**
 * Stav rozhodnutí
 */
export enum DecisionState {
  /**
   * Rozpracováno
   */
  IN_PROGRESS = 'IN_PROGRESS',

  /**
   * Indexace
   */
  INDEXING = 'INDEXING',

  /**
   * K anonymizaci
   */
  TO_ANONYMIZE = 'TO_ANONYMIZE',

  /**
   * K zpřístupnění
   */
  TO_MAKE_ACCESSIBLE = 'TO_MAKE_ACCESSIBLE',

  /**
   * Zpřístupněno
   */
  ACCESSIBLE = 'ACCESSIBLE',

  /**
   * Stanovisko pléna
   */
  PLENARY_OPINION = 'PLENARY_OPINION',
}

/**
 * Publikovat ve sbírce
 */
export enum PublishInCollection {
  /**
   * Nepublikovat
   */
  DO_NOT_PUBLISH = 'DO_NOT_PUBLISH',

  /**
   * Ke korektuře
   */
  FOR_CORRECTION = 'FOR_CORRECTION',

  /**
   * K publikaci
   */
  FOR_PUBLICATION = 'FOR_PUBLICATION',
}
