import { getErrorMessage } from '@utils/error-message';
import React, { useContext, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  AbortableFetch,
  ApiFilterOperation,
  DetailContext,
  DetailMode,
  FieldFilter,
  SnackbarContext,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  abortableFetch,
  useEventCallback,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  ChallengedAct,
  DictSettlementMethodAutocomplete,
  ErrorObject,
  EsFile,
  EsFileUnion,
  Proposer,
  UserRole,
  Writer,
} from '@models';
import { EvidenceApiUrl } from '@enums';
import { useSettlementMethods } from '../../dict-settlement-method/dict-settlement-method-api';

function callApi(id: string, esFile: EsFile) {
  return abortableFetch(
    `${EvidenceApiUrl.ES_FILES}/${esFile.id}/document/assign`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify([id]),
    }
  );
}

export function useToEsFileDialog() {
  const { source, onPersisted, setMode } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { states, agendas } = useContext(StaticDataContext);

  const useEsFileStates = () => useStaticListSource(states);
  const useEsFileAgendas = () => useStaticListSource(agendas);

  const fetch = useRef<AbortableFetch | null>(null);

  const wrappedCallApi = useEventCallback(async (formData: EsFile) => {
    try {
      source.setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(source.data.id, formData);
      await fetch.current.none();

      unstable_batchedUpdates(() => {
        source.setLoading(false);
        onPersisted(null);
        setMode(DetailMode.NONE);
      });
    } catch (err) {
      source.setLoading(false);

      const error = err as ErrorObject<string>;
      const message = getErrorMessage(error);

      if (error?.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  // TODO
  const showButton = source.data?.id && !source.data?.processed;

  const columns: TableFieldColumn<EsFile>[] = [
    {
      name: 'Datum doručení',
      datakey: 'deliveryDate',
      sortkey: 'deliveryDate',
      CellComponent: TableFieldCells.DateCell,
      width: 200,
    },
    {
      name: 'Spisová značka',
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      width: 200,
    },
    {
      name: 'Napadený akt - spisová značka / číslo aktu',
      datakey: 'challengedActs',
      sortkey: 'challengedActs.externalFileNumber',
      width: 200,
      CellComponent: function Cell({ value, ...props }) {
        return (
          <TableFieldCells.TextCell
            {...props}
            value={value
              ?.map((act: ChallengedAct) => act.externalFileNumber)
              .join(',')}
          />
        );
      },
    },
    {
      name: 'Soudce zpravodaj / Řešitel',
      datakey: 'judgeSolversMerged',
      sortkey: 'judgeSolversMerged.concatenated',
      CellComponent: function Cell({
        rowValue,
        ...props
      }: TableFieldCellProps<EsFileUnion>) {
        let value = '';
        if ('judge' in rowValue) {
          value = rowValue.judge?.label ?? '';
        } else if ('solverUserRoles' in rowValue) {
          value =
            rowValue.solverUserRoles
              ?.map((solver: UserRole) => solver.label)
              .join(', ') ?? '';
        }

        return (
          <TableFieldCells.TextCell
            {...props}
            rowValue={rowValue}
            value={value}
          />
        );
      },
      width: 200,
    },
    {
      name: 'Navrhovatel / Pisatel',
      datakey: 'participants',
      sortkey: 'participants.name',
      CellComponent: function Cell({
        rowValue,
        ...props
      }: TableFieldCellProps<EsFileUnion>) {
        let value = '';
        if ('proposers' in rowValue) {
          value =
            rowValue.proposers
              ?.map((proposer: Proposer) => proposer.label)
              .join(',') ?? '';
        } else if ('writers' in rowValue) {
          value =
            rowValue.writers?.map((writer: Writer) => writer.label).join(',') ??
            '';
        }

        return (
          <TableFieldCells.TextCell
            {...props}
            rowValue={rowValue}
            value={value}
          />
        );
      },
      width: 400,
    },
    {
      name: 'Agenda',
      datakey: 'agenda',
      sortkey: 'agenda.name',
      CellComponent: TableFieldCells.useSelectCellFactory(useEsFileAgendas),
      width: 200,
    },
    {
      name: 'Stav',
      datakey: 'state',
      sortkey: 'state.name',
      CellComponent: TableFieldCells.useSelectCellFactory(useEsFileStates),
      width: 200,
    },
    {
      name: 'Způsob vyřízení',
      datakey: 'settlementMethods',
      sortkey: 'settlementMethods.concatenated',
      CellComponent: function Cell({
        value,
        ...props
      }: TableFieldCellProps<EsFileUnion>) {
        return (
          <TableFieldCells.TextCell
            {...props}
            value={value
              .map((method: DictSettlementMethodAutocomplete) => method?.name)
              .join(', ')}
          />
        );
      },
      width: 200,
    },
  ];

  const filterColumns = [
    {
      label: 'Datum doručení',
      datakey: 'deliveryDate',
      filterkey: 'deliveryDate',
      defaultOperation: ApiFilterOperation.LTE,
      FilterComponent: FieldFilter.FilterDateCell,
    },
    {
      label: 'Napadený akt - spisová značka / číslo aktu',
      datakey: 'challengedActs.externalFileNumber',
      filterkey: 'challengedActs.externalFileNumber',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: 'Spisová značka',
      datakey: 'number.value',
      filterkey: 'number.value',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: 'Soudce zpravodaj / Řešitel',
      datakey: 'judgeSolversMerged',
      filterkey: 'judgeSolversMerged.concatenated',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: 'Navrhovatel / Pisatel',
      datakey: 'participants',
      filterkey: 'participants.contact.subject.name',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: 'Stav',
      datakey: 'state.id',
      filterkey: 'state.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useEsFileStates),
    },
    {
      label: 'Agenda',
      datakey: 'agenda.id',
      filterkey: 'agenda.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useEsFileAgendas),
    },
    {
      label: 'Způsob vyřízení',
      datakey: 'settlementMethods',
      filterkey: 'settlementMethods.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterAutocompleteCell(
        useSettlementMethods,
        autocompleteLabelMapper
      ),
    },
  ];

  return {
    callApi: wrappedCallApi,
    showButton,
    columns,
    filterColumns,
  };
}
