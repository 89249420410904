import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

/**
 * List all available REST services
 */
export function useRestServices() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.REST_MESSAGES}/service/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
