import * as monaco from 'monaco-editor';
import React, { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  FormContext,
  PromptContext,
  useEventCallback,
  usePrompts,
} from '@eas/common-web';
import { DifferenceEditor } from '@components/form/difference-editor/difference-editor';
import { DecisionText } from '@models';

export function useShowDifferenceDialog(fieldName: keyof DecisionText) {
  const intl = useIntl();
  const PROMPT_KEY = 'SHOW_DIFFERENCE';

  const { setFieldValue, editing } = useContext(FormContext);
  const { testPrompt } = useContext(PromptContext);

  const editorRef = useRef<monaco.editor.IStandaloneDiffEditor | null>(null);

  const handleConfirmDialog = useEventCallback(() => {
    const modifiedValue = editorRef.current?.getModifiedEditor().getValue();
    setFieldValue(`anonymizedText.${fieldName}`, modifiedValue);
  });

  const handleEditorDidMount = useEventCallback(
    (editor: monaco.editor.IStandaloneDiffEditor) => {
      editorRef.current = editor;

      const modifiedEditor = editor.getModifiedEditor();
      const originalEditor = editor.getOriginalEditor();

      // remove popup messages "Cannot edit in read-only editor" to prevent visual glitches
      modifiedEditor.onDidAttemptReadOnlyEdit(() => {
        modifiedEditor
          .getContribution('editor.contrib.messageController')
          ?.dispose();
      });

      originalEditor.onDidAttemptReadOnlyEdit(() => {
        originalEditor
          .getContribution('editor.contrib.messageController')
          ?.dispose();
      });
    }
  );

  usePrompts(
    [
      {
        key: `${PROMPT_KEY}_${fieldName.toLocaleUpperCase()}`,
        dialogTitle: intl.formatMessage({
          id: 'ES__DECISIONS__ANONYMIZED_TEXT__SHOW_DIFFERECE__TITLE',
          defaultMessage: 'Rozdíl textů',
        }),
        dialogText: intl.formatMessage({
          id: 'ES__DECISIONS__ANONYMIZED_TEXT__SHOW_DIFFERECE__TEXT',
          defaultMessage:
            'Rozdíl mezi originální a anonymizovanou verzí textu.',
        }),
        dialogWidth: 1000,
        FormFields: function FormFields() {
          return (
            <DifferenceEditor
              fieldName={fieldName}
              onMount={handleEditorDidMount}
              disabled={!editing}
            />
          );
        },
      },
    ],
    [editing]
  );

  const handleShowDifference = useEventCallback(() => {
    testPrompt({
      key: `${PROMPT_KEY}_${fieldName.toLocaleUpperCase()}`,
      submitCallback: handleConfirmDialog,
    });
  });

  return { handleShowDifference };
}
