import React, { ComponentType, ReactElement, Ref, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import {
  ActionButtonComponentProps,
  ActionButtonHandle,
  DetailMode,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { ViewingRequest } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useChangeTaskStateDialog() {
  const callApi = useEventCallback(
    (id: string, newState: 'approve' | 'reject') => {
      return abortableFetch(
        `${EvidenceApiUrl.VIEWING_REQUEST}/${id}/${newState}`,
        {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        }
      );
    }
  );

  return {
    callApi,
  };
}

export const ApproveViewingRequestButton = forwardRef(
  function ApproveViewingRequestButton(
    {
      ButtonComponent,
      modes,
      viewingRequest,
    }: {
      ButtonComponent?: ComponentType<ActionButtonComponentProps>;
      modes?: DetailMode[];
      viewingRequest: ViewingRequest;
    },
    ref: Ref<ActionButtonHandle>
  ) {
    const intl = useIntl();
    const { callApi } = useChangeTaskStateDialog();

    return (
      <DetailActionButton
        ref={ref}
        promptKey="PROCESS_VIEWING_REQUEST"
        apiCall={() => callApi(viewingRequest.id, 'approve')}
        buttonLabel={intl.formatMessage({
          id: 'ES__VIEWING_REQUESTS_TOOLBAR__APPROVE_VIEWING_REQUEST',
          defaultMessage: 'Schválit',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__VIEWING_REQUESTS_TOOLBAR__PROCESS_VIEWING_REQUEST__TITLE',
          defaultMessage: 'Žádost o nahlížení',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__VIEWING_REQUESTS_TOOLBAR__APPROVE_VIEWING_REQUEST__TEXT',
          defaultMessage: 'Skutečně chcete schválit tuto žádost o nahlížení?',
        })}
        ButtonComponent={ButtonComponent}
        modes={modes}
      />
    );
  }
) as (
  p: {
    ButtonComponent?: ComponentType<ActionButtonComponentProps>;
    modes?: DetailMode[];
    viewingRequest: ViewingRequest;
  } & {
    ref?: Ref<ActionButtonHandle>;
  }
) => ReactElement;

export const RejectViewingRequestButton = forwardRef(
  function RejectViewingRequestButton(
    {
      ButtonComponent,
      modes,
      viewingRequest,
    }: {
      modes?: DetailMode[];
      viewingRequest: ViewingRequest;
      ButtonComponent?: ComponentType<ActionButtonComponentProps>;
    },
    ref: Ref<ActionButtonHandle>
  ) {
    const intl = useIntl();
    const { callApi } = useChangeTaskStateDialog();

    return (
      <DetailActionButton
        ref={ref}
        promptKey="REJECT_VIEWING_REQUEST"
        apiCall={() => callApi(viewingRequest.id, 'reject')}
        buttonLabel={intl.formatMessage({
          id: 'ES__ES_FILES__VIEWING_REQUESTS_TOOLBAR__REJECT_VIEWING_REQUEST',
          defaultMessage: 'Zamítnout',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__ES_FILES__DIALOG_REJECT_VIEWING_REQUEST__TITLE',
          defaultMessage: 'Žádost o nahlížení',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__ES_FILES__DIALOG_REJECT_VIEWING_REQUEST__TEXT',
          defaultMessage: 'Skutečně chcete zamítnout tuto žádost o nahlížení?',
        })}
        ButtonComponent={ButtonComponent}
        modes={modes}
      />
    );
  }
) as (
  p: {
    ButtonComponent?: ComponentType<ActionButtonComponentProps>;
    modes?: DetailMode[];
    viewingRequest: ViewingRequest;
  } & {
    ref?: Ref<ActionButtonHandle>;
  }
) => ReactElement;
