import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DetailToolbarButton2 } from '@eas/common-web';
import { useDownloadDocxDialog } from './dialog-actions/download-docx-hook';
import { usePrintDialog } from './dialog-actions/print-hook';

export function Toolbar() {
  const { showButton: showPrintButton, PrintButton } = usePrintDialog();
  const { showButton: showDownloadButton, handleDownload } =
    useDownloadDocxDialog();

  return (
    <>
      {showPrintButton && <PrintButton />}
      {showDownloadButton && (
        <DetailToolbarButton2
          label="Docx"
          startIcon={<GetAppIcon />}
          onClick={handleDownload}
        />
      )}
    </>
  );
}
