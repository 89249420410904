import {
  AuthoredEvidence,
  AuthoredObject,
  EvidenceProps,
} from '@eas/common-web';
import { useEvidence } from './evidence';

export function useAuthoredEvidence<OBJECT extends AuthoredObject>(
  options: EvidenceProps<OBJECT>
): EvidenceProps<OBJECT> {
  const props = useEvidence(options);

  return AuthoredEvidence.useAuthoredEvidence(props);
}
