import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  ActionButton,
  FormContext,
  FormLocalDateField,
  FormSelect,
  FormTextField,
  LocaleContext,
  PrimaryDetailActionbarButton,
  useFormSelector,
} from '@eas/common-web';
import { IntervalInput, SubmissionCountDataInput } from '@models';
import { IntervalType } from '@enums';
import { useStyles } from '../card/dated-header';
import { useIntervalTypes } from '../statistics-api';

export const IntervalHeader = ({
  params,
  setParams,
}: {
  params: IntervalInput;
  setParams: (parameters: IntervalInput) => void;
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { locale } = useContext(LocaleContext);

  const validationSchema = Yup.object().shape<IntervalInput>({
    intervalType: Yup.mixed<IntervalType>().nullable().required(),
    year: Yup.string().when('intervalType', {
      is: IntervalType.YEAR,
      then: Yup.number().nullable().required(),
    }),
    from: Yup.string().when('intervalType', {
      is: IntervalType.RANGE,
      then: Yup.string().nullable().required(),
    }),
    to: Yup.string().when('intervalType', {
      is: IntervalType.RANGE,
      then: Yup.string().nullable().required(),
    }),
  });

  return (
    <div className={classes.wrapper}>
      {!!params.from && !!params.to && (
        <div className={classes.dateWrapper}>
          <div className={classes.labelWrapper}>
            <Typography className={classes.dateLabel}>OD</Typography>
            <Typography>
              {format(new Date(params?.from), locale.dateFormat)}
            </Typography>
          </div>
          <div className={classes.labelWrapper}>
            <Typography className={classes.dateLabel}>DO</Typography>
            <Typography>
              {format(new Date(params?.to), locale.dateFormat)}
            </Typography>
          </div>
        </div>
      )}
      {params.intervalType === IntervalType.ALL && (
        <Typography className={classes.dateLabel}>
          VŠE OD PRVNÍHO PODÁNÍ
        </Typography>
      )}
      {params.year && (
        <div className={classes.labelWrapper}>
          <Typography className={classes.dateLabel}>ROK</Typography>
          <Typography>{params.year}</Typography>
        </div>
      )}
      <ActionButton
        promptKey="CHANGE_INTERVAL_INPUT_PARAMETERS"
        buttonLabel={intl.formatMessage({
          id: 'ES__STATISTICS__CHANGE_INTERVAL_INPUT_PARAMETERS',
          defaultMessage: 'Upravit parametry',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__STATISTICS__CHANGE_INTERVAL_INPUT_PARAMETERS__TITLE',
          defaultMessage: 'Úprava parametrů',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__STATISTICS__CHANGE_INTERVAL_INPUT_PARAMETERS__TEXT',
          defaultMessage: 'Vyplňte parametry.',
        })}
        ButtonComponent={(props) => (
          <PrimaryDetailActionbarButton {...props} endIcon={<SettingsIcon />} />
        )}
        formInitialValues={params}
        FormFields={IntervalFields}
        formValidationSchema={validationSchema}
        submitCallback={setParams}
        dialogWidth={700}
        dialogDisableBackdrop={true}
      />
    </div>
  );
};

export function IntervalFields() {
  const { setFieldValue } = useContext(FormContext);

  const intervalTypes = useIntervalTypes();

  const { intervalType } = useFormSelector(
    (data: SubmissionCountDataInput) => ({
      intervalType: data.intervalType,
    })
  );

  return (
    <>
      <FormSelect
        name="intervalType"
        source={intervalTypes}
        label="Typ intervalu"
        valueIsId
        required
        notifyChange={(value) => {
          if (value === IntervalType.YEAR || value === IntervalType.ALL) {
            setFieldValue('from', undefined);
            setFieldValue('to', undefined);
          }
          if (value === IntervalType.ALL || value === IntervalType.RANGE) {
            setFieldValue('year', undefined);
          }
        }}
      />
      {intervalType === IntervalType.YEAR && (
        <FormTextField name="year" label="Rok" required />
      )}
      {intervalType === IntervalType.RANGE && (
        <>
          <FormLocalDateField name="from" label="Od" required />
          <FormLocalDateField name="to" label="Do" required />
        </>
      )}
    </>
  );
}
