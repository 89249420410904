import { merge } from 'lodash';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  ApiFilterOperation,
  EvidenceProps,
  Filter,
  getItemFactory,
} from '@eas/common-web';
import { SaveButton } from '@composite/settlement-method/save-button';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { CourtFile } from '@models';
import { Agenda, CourtFileType, EsFileState, EvidenceApiUrl } from '@enums';
import { disableBtnFactory } from '../es-file/es-file-utils';
import { useEsFiles } from '../es-file/es-files';

export function useCourtFiles({
  evidenceProps,
}: {
  evidenceProps?: Partial<EvidenceProps<CourtFile>>;
}) {
  const intl = useIntl();

  const { defaultCourtProcessingType, defaultSettlementMethods } =
    useContext(StaticDataContext);
  const defaultSettlementMethod = defaultSettlementMethods.find(
    (method) => method.agenda === Agenda.COURT
  );

  const defaultPreFilters = [
    ...((evidenceProps?.tableProps?.defaultPreFilters ?? []) as Filter[]),
    {
      field: 'agenda.id',
      operation: ApiFilterOperation.EQ,
      value: Agenda.COURT,
    },
  ];

  const defaultCourtFile = {
    id: uuidv4(),
    created: new Date().toISOString(),
    agenda: Agenda.COURT,
    state: EsFileState.NEW,
    type: CourtFileType.SENATE,
    processingType: defaultCourtProcessingType!,
    settlementMethods: defaultSettlementMethod ? [defaultSettlementMethod] : [],
  };

  const props = merge(
    {
      evidenceProps: {
        version: 8,
        apiProps: {
          getItem: getItemFactory({ postProcess: postprocessGetCall }),
        },
        tableProps: {
          tableName: intl.formatMessage({
            id: 'ES__COURT_FILES__TABLE__TITLE',
            defaultMessage: 'Soudní spisy',
          }),
          defaultPreFilters,
        },
        detailProps: {
          toolbarProps: {
            title: intl.formatMessage({
              id: 'ES__COURT_FILES__DETAIL__TITLE',
              defaultMessage: 'Soudní spis',
            }),
            disableBtn: disableBtnFactory(defaultCourtFile),
            SaveButton,
          },
          initNewItem: () => defaultCourtFile,
        },
      },
    },
    { evidenceProps }
  );

  return useEsFiles(props);
}

const postprocessGetCall = async (esFile: CourtFile) => {
  const challengedActResponse = await fetch(
    `${EvidenceApiUrl.ES_FILES}/${esFile.id}/challenged-act/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        size: -1,
      }),
    }
  );

  const challengedActs = await challengedActResponse.json();

  return {
    ...esFile,
    challengedActs: challengedActs.items ?? [],
  };
};
