import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailHandle,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useCancelDialog } from '@modules/document/dialog-actions/cancel-hook';
import { useReopenDialog } from '@modules/document/dialog-actions/reopen-hook';
import { useOutgoingDocumentPermission } from '@modules/document/document-permission';
import { useAddTaskDialog } from '@composite/tasks/dialog-actions/add-task-hook';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { InternalDocument, Task } from '@models';
import { TaskState, TaskType, UIElement } from '@enums';

const useStyles = makeStyles(() => ({
  wrapper: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 25,
  },
}));

export function ActionBar() {
  const classes = useStyles();
  const { source } = useContext<DetailHandle<InternalDocument>>(DetailContext);

  const intl = useIntl();

  const { callApi: cancelApiCall, showButton: showCancelButton } =
    useCancelDialog();

  const { hasPermission } = useOutgoingDocumentPermission();

  const { AddTaskButton, disabledBtn: disabledAddTaskBtn } = useAddTaskDialog();
  const { showButton: showReopenButton, ReopenButton } = useReopenDialog();

  return (
    <div className={classes.wrapper}>
      <div>
        <AddTaskButton
          buttonLabel={intl.formatMessage({
            id: 'ES__INTERNAL_DOCUMENTS__ACTIONBAR__ADD_TASK',
            defaultMessage: 'Nová úloha',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          formInitialValues={
            {
              esFile: source.data?.esFile,
              type: TaskType.CUSTOM,
              state: TaskState.SOLVING,
              document: source.data,
            } as Task
          }
          disabled={disabledAddTaskBtn}
        />
        {showReopenButton && (
          <ReopenButton
            buttonDisabled={
              !hasPermission(
                UIElement.InternalDocument.REOPEN_INTERNAL_DOCUMENT_BUTTON
              )
            }
          />
        )}
      </div>
      <div>
        {showCancelButton && (
          <DetailActionButton
            promptKey="CANCEL"
            apiCall={cancelApiCall}
            buttonLabel={intl.formatMessage({
              id: 'ES__OUTGOING_DOCUMENTS__ACTIONBAR__CANCEL',
              defaultMessage: 'Stornovat',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__OUTGOING_DOCUMENTS__DIALOG_CANCEL__TITLE',
              defaultMessage: 'Storno',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__OUTGOING_DOCUMENT__DIALOG_CANCEL__TEXT',
              defaultMessage: 'Skutečně chcete stornovat dokument?',
            })}
            ButtonComponent={SecondaryDetailActionbarButton}
            buttonDisabled={
              !hasPermission(UIElement.InternalDocument.CANCEL_BUTTON)
            }
          />
        )}
      </div>
    </div>
  );
}
