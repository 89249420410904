import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { DetailContext, DetailHandle, abortableFetch } from '@eas/common-web';
import {
  RedirectAction,
  useRedirect,
} from '@composite/redirect/dialog-redirect';
import { CourtFile } from '@models';
import {
  Agenda,
  EsFileState,
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  UIElement,
} from '@enums';
import { useCourtFilePermission } from '../../es-file/es-file-permission';

export const callApi = (ids?: string[]) => {
  return abortableFetch(`${EvidenceApiUrl.ES_FILES}/number`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(ids),
  });
};

export function useAssignFileNumberDialog() {
  const intl = useIntl();
  const match = useRouteMatch();

  const { source } = useContext<DetailHandle<CourtFile>>(DetailContext);
  const { hasPermission } = useCourtFilePermission(source.data);

  const inAllFiles = [
    EvidenceBrowserUrl.ES_FILES_ALL + '/:id?',
    EvidenceBrowserUrl.ES_FILES_SEARCH + '/:id?',
  ].includes(match.path);

  const showButton =
    source?.data?.agenda === Agenda.COURT &&
    source?.data?.state === EsFileState.NEW;

  const disableButton = !hasPermission(
    UIElement.CourtFile.CREATE_FILE_NUMBER_BUTTON
  );

  const redirectBag = useRedirect({
    options: [
      {
        id: RedirectAction.REDIRECT_TO_RESULT,
        name: intl.formatMessage({
          id: 'REDIRECT__OPTION__REGISTER_NEW_COURT_FILE',
          defaultMessage:
            'Pokračovat do spisech ve stavu Rejstřík - nové spisy',
        }),
      },
      {
        id: RedirectAction.STAY_IN_EVIDENCE,
        name: intl.formatMessage({
          id: 'REDIRECT__OPTION__NEW_COURT_FILE',
          defaultMessage: 'Pokračovat v nových spisech',
        }),
      },
    ],
    redirectTo: EvidenceBrowserUrl.COURT_FILES_REGISTER_NEW,
  });

  return {
    callApi,
    showButton,
    disableButton,
    redirectBag: inAllFiles ? {} : { ...redirectBag },
  };
}
