import { format } from 'date-fns';
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  ApiFilterOperation,
  LocaleContext,
  NavigationContext,
  TableFilterState,
  UserContext,
  UserSettingsContext,
} from '@eas/common-web';
import { ALL_ES_FILES_VERSION } from '@modules/es-file-all/all-es-files';
import { useColumns as useAllEsFilesColumns } from '@modules/es-file-all/all-es-files-columns';
import { ES_FILES_IDENTIFIER } from '@modules/es-file/es-files';
import { useColumns as useTaskColumns } from '@modules/task/task-columns';
import { TASK_IDENTIFIER, TASK_VERSION } from '@modules/task/tasks';
import { Me } from '@models';
import { DeadlineState, EvidenceBrowserUrl, TaskState } from '@enums';
import { DashboardButton } from '../button';
import { DashboardCard } from '../card';
import { Clock } from '../clock';
import { useStyles } from '../dashboard';
import { useUserOverviewStatistics } from '../dashboard-hook';

export function UserOverviewStatistics() {
  const classes = useStyles();

  const { user } = useContext<UserContext<Me>>(UserContext);
  const { locale } = useContext(LocaleContext);
  const { navigate } = useContext(NavigationContext);
  const { getTableSettings, setTableSettings } =
    useContext(UserSettingsContext);

  const {
    courtFilesWithRunningDeadline,
    unfinishedTasksForUser,
    unprocessedDeliveredDocuments,
  } = useUserOverviewStatistics();

  const taskColumns = useTaskColumns();
  const allEsFilesColumns = useAllEsFilesColumns();

  return (
    <Grid item xs={3}>
      <DashboardCard>
        <Typography variant="body1" align="center" className={classes.padded}>
          {format(new Date(), 'EEEE, do Lo yyyy', {
            locale: locale.dateFnsLocale,
          })}
        </Typography>
        <Typography align="center">
          <Clock />
        </Typography>
        <DashboardButton
          label="Přihlášen"
          value={user?.name}
          actionNode={null}
          dividerText="Pro mě"
        />
        <DashboardButton
          label="Nezpracované dokumenty"
          value={unprocessedDeliveredDocuments?.value}
          onClick={() =>
            navigate(EvidenceBrowserUrl.DOCUMENTS_DELIVERED_UNPROCESSED)
          }
          showAction={true}
        />
        <DashboardButton
          label="Nedokončené úlohy"
          value={unfinishedTasksForUser?.value}
          onClick={() => {
            const settings = getTableSettings(
              TASK_IDENTIFIER + '_TABLE',
              TASK_VERSION
            );

            const filtersState = taskColumns.map((column) => ({
              enabled: false,
              value: null,
              operation: column.filterOperation ?? ApiFilterOperation.EQ,
            })) as TableFilterState[];

            filtersState[2] = {
              enabled: true,
              value: TaskState.SOLVING,
              operation: ApiFilterOperation.EQ,
            };

            setTableSettings(TASK_IDENTIFIER + '_TABLE', {
              ...settings,
              version: TASK_VERSION,
              filtersState,
            });

            navigate(EvidenceBrowserUrl.TASKS);
          }}
          showAction={true}
        />
        <DashboardButton
          label="Spisy s běžící lhůtou"
          value={courtFilesWithRunningDeadline?.value}
          showAction={true}
          onClick={() => {
            const settings = getTableSettings(
              ES_FILES_IDENTIFIER + '_TABLE',
              ALL_ES_FILES_VERSION
            );

            const filtersState = allEsFilesColumns.map((column) => ({
              enabled: false,
              value: null,
              operation: column.filterOperation ?? ApiFilterOperation.EQ,
            })) as TableFilterState[];

            filtersState[8] = {
              enabled: true,
              value: DeadlineState.RUNNING,
              operation: ApiFilterOperation.EQ,
            };

            setTableSettings(ES_FILES_IDENTIFIER + '_TABLE', {
              ...settings,
              version: ALL_ES_FILES_VERSION,
              filtersState,
            });

            navigate(EvidenceBrowserUrl.ES_FILES_ALL);
          }}
        />
      </DashboardCard>
    </Grid>
  );
}
