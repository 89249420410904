import React, { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { red } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  DialogHandle,
  DictionaryFieldDialog,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
  useScrollableSource,
} from '@eas/common-web';
import { useReopenDialog } from '@modules/document/dialog-actions/reopen-hook';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { DeliveredDocument, EsFile } from '@models';
import { EsFileState, EvidenceApiUrl, UIElement } from '@enums';
import { useDeliveredDocumentPermission } from '../document/document-permission';
import { useCopyDialog } from './dialog-actions/copy-hook';
import { useRemoveDialog } from './dialog-actions/remove-hook';
import { useToEsFileDialog } from './dialog-actions/to-es-file-hook';

const useStyles = makeStyles(() => ({
  wrapper: {
    flexWrap: 'wrap',
  },
}));

export function ActionBar() {
  const classes = useStyles();
  const { mode, source } =
    useContext<DetailHandle<DeliveredDocument>>(DetailContext);

  const dialogRef = useRef<DialogHandle>(null);

  const esFiles = useScrollableSource<EsFile>({
    url: `${EvidenceApiUrl.ES_FILES}/list`,
  });

  const intl = useIntl();

  const { callApi: copyApiCall, redirectBag: copyRedirectBag } =
    useCopyDialog();
  const { callApi: removeApiCall, showButton: showRemoveButton } =
    useRemoveDialog();

  const {
    callApi: toEsFileApiCall,
    showButton: showToEsFileButton,
    columns: toEsFileColumns,
    filterColumns: toEsFileFilterColumns,
  } = useToEsFileDialog();

  const { showButton: showReopenButton, ReopenButton } = useReopenDialog();

  const copyDetails = source?.data?.copyDetails;
  const copyWarning = copyDetails
    ? `Upozornění: Kopie bude vytvořena z originálního dokumentu s došlým číslem ${copyDetails.original?.incomingNumber}.`
    : '';

  const { hasPermission } = useDeliveredDocumentPermission(source.data);

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div className={classes.wrapper}>
          {showToEsFileButton && (
            <>
              <PrimaryDetailActionbarButton
                label={intl.formatMessage({
                  id: 'ES__DOCUMENT_DELIVERED__ACTIONBAR__TO_ES_FILE',
                  defaultMessage: 'Do spisu',
                })}
                onClick={() => dialogRef.current?.open()}
                disabled={
                  !hasPermission(UIElement.DeliveredDocument.TO_ES_FILE)
                }
              />
              <DictionaryFieldDialog
                ref={dialogRef}
                columns={toEsFileColumns}
                filtersFields={toEsFileFilterColumns}
                preFilters={[
                  {
                    operation: ApiFilterOperation.NOT,
                    filters: [
                      {
                        field: 'state.id',
                        operation: ApiFilterOperation.EQ,
                        value: EsFileState.REGISTRY,
                      },
                    ],
                  },
                ]}
                onChange={
                  toEsFileApiCall as (
                    formData: EsFile | EsFile[]
                  ) => Promise<undefined>
                }
                source={esFiles}
                maxRows={9}
                width={1200}
              />
            </>
          )}
          <DetailActionButton
            promptKey="COPY"
            apiCall={copyApiCall}
            buttonLabel={intl.formatMessage({
              id: 'ES__DOCUMENT_DELIVERED__ACTIONBAR__COPY',
              defaultMessage: 'Kopie',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__DOCUMENT_DELIVERED__DIALOG_COPY__TITLE',
              defaultMessage: 'Varování',
            })}
            dialogText={intl.formatMessage(
              {
                id: 'ES__DOCUMENT_DELIVERED__DIALOG_COPY__TEXT',
                defaultMessage:
                  'Opravdu chcete vytvořit kopii dokumentu?{break}{warning}',
              },
              {
                break: <br />,
                warning: (
                  <div style={{ color: red['A700'] }}>{copyWarning}</div>
                ),
              }
            )}
            ButtonComponent={PrimaryDetailActionbarButton}
            dialogWidth={450}
            isLocked={false}
            buttonDisabled={!hasPermission(UIElement.DeliveredDocument.COPY)}
            {...copyRedirectBag}
          />
          {showRemoveButton && (
            <DetailActionButton
              promptKey="REMOVE"
              apiCall={removeApiCall}
              buttonLabel={intl.formatMessage({
                id: 'ES__DOCUMENT_DELIVERED__ACTIONBAR__REMOVE',
                defaultMessage: 'Vyjmout ze spisu',
              })}
              dialogTitle={intl.formatMessage({
                id: 'ES__DOCUMENT_DELIVERED__DIALOG_REMOVE__TITLE',
                defaultMessage: 'Varování',
              })}
              dialogText={intl.formatMessage({
                id: 'ES__DOCUMENT_DELIVERED__DIALOG_REMOVE__TEXT',
                defaultMessage: 'Opravdu chcete vyjmout dokument ze spisu?',
              })}
              ButtonComponent={SecondaryDetailActionbarButton}
              dialogWidth={450}
              buttonDisabled={
                !hasPermission(UIElement.DeliveredDocument.REMOVE_BUTTON)
              }
            />
          )}
          {showReopenButton && (
            <ReopenButton
              buttonDisabled={
                !hasPermission(
                  UIElement.DeliveredDocument.REOPEN_DELIVERED_DOCUMENT_BUTTON
                )
              }
            />
          )}
        </div>
      )}
    </>
  );
}
