import clsx from 'clsx';
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { UserContext } from '@eas/common-web';
import { Me } from '@models';
import { Agenda, Role } from '@enums';
import { DashboardBarChart } from '../bar-chart';
import { DashboardButton } from '../button';
import { DashboardCard } from '../card';
import { useStyles } from '../dashboard';
import { useFilesStatistics } from '../dashboard-hook';
import { DashboardDivider } from '../divider';

export function EsFilesStatistics() {
  const classes = useStyles();

  const { user } = useContext<UserContext<Me>>(UserContext);

  const isJudge = user?.userRoles.map((role) => role.role).includes(Role.JUDGE);

  const {
    agenda,
    setAgenda,
    assistantFiles,
    assistantFilesSummary,
    statesAccordingToAgendas,
    allFilesAccordingToAgendas,
    assistantFilesView,
    setAsistantFilesView,
  } = useFilesStatistics();

  return (
    <>
      <Typography
        variant="subtitle1"
        className={clsx(classes.padded, classes.subtitle)}
      >
        Statistiky spisů
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <DashboardCard>
            <Grid container spacing={3}>
              <Grid item xs={5} style={{ placeSelf: 'center' }}>
                {allFilesAccordingToAgendas?.map((item) => (
                  <DashboardButton
                    key={item.id}
                    label={item.label}
                    value={item.value}
                    active={item.id === agenda}
                    showAction={true}
                    onClick={() => setAgenda(item.id as Agenda)}
                  />
                ))}
              </Grid>
              <Grid item xs={7}>
                <DashboardDivider text="Spisy dle stavů" />
                <DashboardBarChart data={statesAccordingToAgendas[agenda]} />
              </Grid>
            </Grid>
          </DashboardCard>
        </Grid>

        {isJudge && (
          <Grid item xs={6}>
            <DashboardCard>
              <Grid container spacing={3}>
                <Grid item xs={5} style={{ placeSelf: 'center' }}>
                  {assistantFilesSummary?.map((item) => (
                    <DashboardButton
                      key={item.id}
                      label={item.label}
                      value={item.value}
                      active={item.id === assistantFilesView}
                      showAction={true}
                      onClick={() => setAsistantFilesView(item.id)}
                    />
                  ))}
                </Grid>
                <Grid item xs={7}>
                  <DashboardDivider text="Spisy dle asistentů" />
                  <DashboardBarChart
                    data={assistantFiles[assistantFilesView]}
                  />
                </Grid>
              </Grid>
            </DashboardCard>
          </Grid>
        )}
      </Grid>
    </>
  );
}
