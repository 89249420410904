import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RedirectAction } from '@composite/redirect/dialog-redirect';
import { EsFileState } from '@enums';

const OPTION_NEW = (
  <FormattedMessage
    id="REDIRECT__OPTION__NEW"
    defaultMessage="Pokračovat v nových INF spisech"
  />
);

const SOURCE_NEW = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_NEW,
};

const DEST_NEW = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_NEW,
};

const OPTION_IN_PROGRESS = (
  <FormattedMessage
    id="REDIRECT__OPTION__IN_PROGRESS"
    defaultMessage="Pokračovat v evidenci: INF spisy - V řešení"
  />
);

const SOURCE_IN_PROGRESS = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_IN_PROGRESS,
};

const DEST_IN_PROGRESS = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_IN_PROGRESS,
};

const OPTION_CLOSED = (
  <FormattedMessage
    id="REDIRECT__OPTION__CLOSED"
    defaultMessage="Pokračovat v evidenci: Uzavřené INF"
  />
);

const SOURCE_CLOSED = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_CLOSED,
};

const DEST_CLOSED = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_CLOSED,
};

export function getOptions(source?: EsFileState, dest?: EsFileState) {
  const options = [];

  switch (source) {
    case EsFileState.NEW:
      options.push(SOURCE_NEW);
      break;
    case EsFileState.IN_PROGRESS:
      options.push(SOURCE_IN_PROGRESS);
      break;
    case EsFileState.CLOSED:
      options.push(SOURCE_CLOSED);
      break;
  }

  switch (dest) {
    case EsFileState.NEW:
      options.push(DEST_NEW);
      break;
    case EsFileState.IN_PROGRESS:
      options.push(DEST_IN_PROGRESS);
      break;
    case EsFileState.CLOSED:
      options.push(DEST_CLOSED);
      break;
  }

  return options;
}
