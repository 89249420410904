import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  EvidenceScreenMode,
  FormSelect,
  FormSubmitButton,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { DictionaryAutocomplete } from '@models';

const useStyles = makeStyles({
  header: {
    textAlign: 'left',
    marginBottom: 30,
    fontSize: 16,
    fontWeight: 500,
  },
});

export function useEvidenceModes() {
  return useStaticListSource<DictionaryAutocomplete>([
    {
      id: EvidenceScreenMode.TABLE.toString(),
      name: 'Tabulka / Detail (při přesměrování na entitu)',
    },
    { id: EvidenceScreenMode.SPLIT.toString(), name: 'Dělené zobrazení' },
  ]);
}

export function AppSettingsFields({
  editing,
  loading,
  handleStartEditing,
  handleCancelEditing,
}: {
  editing: boolean;
  loading: boolean;
  handleStartEditing: () => void;
  handleCancelEditing: () => void;
}) {
  const evidenceModes = useEvidenceModes();

  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" className={classes.header}>
        <FormattedMessage
          id="ES__APP_SETTINGS__PANEL_TITLE"
          defaultMessage="Obecné"
        />
      </Typography>

      <FormSelect
        name="evidenceMode"
        label={
          <FormattedMessage
            id="ES__APP_SETTINGS__EVIDENCE_MODE"
            defaultMessage="Zobrazení evidencí"
          />
        }
        source={evidenceModes}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
      />

      <FormTextField
        name="primaryColor"
        label={
          <FormattedMessage
            id="ES__APP_SETTINGS__PRIMARY_COLOR"
            defaultMessage="Barevné schéma"
          />
        }
      />

      <FormTextField
        name="substitutionPrimaryColor"
        label={
          <FormattedMessage
            id="ES__APP_SETTINGS__SUBSTITUTION_PRIMARY_COLOR"
            defaultMessage="Barevné schéma zástupu"
          />
        }
      />

      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box height={50} />
          <Box display="flex" flexDirection="row-reverse">
            {!editing && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleStartEditing}
                >
                  <FormattedMessage
                    id="ES__APP_SETTINGS__BTN_EDIT"
                    defaultMessage="Upravit"
                  />
                </Button>
                <Box width={10} />
              </>
            )}
            {editing && (
              <>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={handleCancelEditing}
                >
                  <FormattedMessage
                    id="ES__APP_SETTINGS__BTN_CANCEL"
                    defaultMessage="Zrušit"
                  />
                </Button>
                <Box width={10} />
              </>
            )}
            {editing && (
              <>
                <FormSubmitButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  startIcon={
                    loading && <CircularProgress size="20px" color="inherit" />
                  }
                >
                  <FormattedMessage
                    id="ES__APP_SETTINGS__BTN_SAVE"
                    defaultMessage="Uložit"
                  />
                </FormSubmitButton>
                <Box width={10} />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
