import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DictRegulationOrder } from '@models';

export function useColumns(): TableColumn<DictRegulationOrder>[] {
  const intl = useIntl();
  const { columnActive, columnCode } =
    DictionaryEvidence.useDictionaryColumns<DictRegulationOrder>();

  return [
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ES__DICT_SENATES__TABLE__COLUMN__NAME',
        defaultMessage: 'Název',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'regulationOrder',
      name: intl.formatMessage({
        id: 'ES__DICT_REGULATION_ORDERS__TABLE__COLUMN__ORDER',
        defaultMessage: 'Pořadí indexu',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'number',
      name: intl.formatMessage({
        id: 'ES__DICT_REGULATION_ORDERS__TABLE__COLUMN__NUMBER',
        defaultMessage: 'Číslo',
      }),
      width: 100,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'year',
      name: intl.formatMessage({
        id: 'ES__DICT_REGULATION_ORDERS__TABLE__COLUMN__YEAR',
        defaultMessage: 'Rok',
      }),
      width: 100,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
    },
    columnCode,
    columnActive,
  ];
}
