import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  DetailContext,
  DictionaryObject,
  FilesContext,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { DeliveredDocument, Document, DocumentAttachment } from '@models';
import { Agenda, DocumentType, EvidenceBrowserUrl } from '@enums';
import { useStaticAttachmentTypes } from './../attachments/attachment-api';
import { Redirect } from './../redirect/redirect';
import { Remove } from './../remove/remove';
import { useUnassignDocumentDialog } from './dialog-actions/unassing-document-hook';

export function getDocumentUrl(type: DocumentType) {
  switch (type) {
    case DocumentType.DELIVERED:
      return EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED;
    case DocumentType.INTERNAL:
      return EvidenceBrowserUrl.DOCUMENTS_INTERNAL;
    case DocumentType.OUTGOING:
      return EvidenceBrowserUrl.DOCUMENTS_OUTGOING;

    default:
      return EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED;
  }
}

export function useColumns(props: {
  documentTypes: DictionaryObject[];
  documentDeliveryMethods: DictionaryObject[];
  documentDispatchStates: DictionaryObject[];
}): TableFieldColumn<Document>[] {
  const intl = useIntl();
  const { documentTypes, documentDispatchStates, documentDeliveryMethods } =
    props;
  const { source } = useContext(DetailContext);

  const { callApi: unassignDocument } = useUnassignDocumentDialog();

  const useDocumentTypes = () => useStaticListSource(documentTypes);
  const useDocumentDeliveryMethods = () =>
    useStaticListSource(documentDeliveryMethods);
  const useDocumentDispatchStates = () =>
    useStaticListSource(documentDispatchStates);

  const columns: TableFieldColumn<Document>[] = [
    {
      datakey: 'id',
      name: '',
      width: 140,
      CellComponent: function Cell(props: TableFieldCellProps<Document>) {
        const url = getDocumentUrl(props.rowValue.type!);

        return (
          <>
            {(props.rowValue.type !== DocumentType.DELIVERED ||
              (props.rowValue.type === DocumentType.DELIVERED &&
                !(props.rowValue as DeliveredDocument).removedFromEsFile)) && (
              <Redirect url={url} id={props.value} />
            )}
            {props.rowValue.type === DocumentType.DELIVERED &&
              !(props.rowValue as DeliveredDocument).removedFromEsFile && (
                <Remove
                  id={props.value}
                  removeDialogTitle={intl.formatMessage({
                    id: 'ES__DOCUMENTS__UNASSIGN__TITLE',
                    defaultMessage: 'Varování',
                  })}
                  removeDialogText={intl.formatMessage({
                    id: 'ES__DOCUMENTS__UNASSIGN__TEXT',
                    defaultMessage: 'Skutečně chcete odebrat dokument?',
                  })}
                  removeOnSuccess={source.refresh}
                  removeApiCall={unassignDocument}
                />
              )}
          </>
        );
      },
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      datakey: 'referenceNumber',
      sortkey: 'order',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__DELIVERY_DATE',
        defaultMessage: 'Datum doručení',
      }),
      datakey: 'deliveryDate',
      sortkey: 'deliveryDate',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__CREATED_DATE',
        defaultMessage: 'Datum vytvoření',
      }),
      datakey: 'createdDate',
      sortkey: 'createdDate',
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__INCOMING_NUMBER',
        defaultMessage: 'Došlé číslo',
      }),
      datakey: 'incomingNumber',
      sortkey: 'incomingNumber',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__SUMMARY',
        defaultMessage: 'Stručný obsah',
      }),
      datakey: 'summary',
      sortkey: 'summary',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__TYPE',
        defaultMessage: 'Typ',
      }),
      datakey: 'type',
      sortkey: 'type.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useDocumentTypes),
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__DELIVERY_METHOD',
        defaultMessage: 'Způsob doručení',
      }),
      datakey: 'deliveryMethod',
      sortkey: 'deliveryMethod.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useDocumentDeliveryMethods
      ),
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__DISPATCH_STATE',
        defaultMessage: 'Stav vypravení',
      }),
      datakey: 'dispatchState',
      sortkey: 'dispatchState.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useDocumentDispatchStates
      ),
    },
  ];

  if (source?.data?.agenda === Agenda.COURT) {
    columns.push({
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__ATTACHMENTS',
        defaultMessage: 'Přílohy',
      }),
      datakey: 'attachmentsLabelRange',
      width: 100,
    });
  }

  if (source?.data?.agenda === Agenda.INF) {
    columns.push(
      {
        name: intl.formatMessage({
          id: 'ES__DOCUMENTS__COLUMN__DECISION',
          defaultMessage: 'Správní rozhodnutí',
        }),
        datakey: 'decision',
        width: 100,
        CellComponent: TableFieldCells.BooleanCell,
      },
      {
        name: intl.formatMessage({
          id: 'ES__DOCUMENTS__COLUMN__COMPLIANT',
          defaultMessage: 'Stížnost',
        }),
        datakey: 'complaint',
        width: 100,
        CellComponent: TableFieldCells.BooleanCell,
      }
    );
  }

  return columns;
}

export function useAttachmentColumns(
  agenda?: Agenda
): TableFieldColumn<DocumentAttachment>[] {
  const intl = useIntl();

  const commonColumns: TableFieldColumn<DocumentAttachment>[] = [
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ES__ATTACHMENTS__COLUMN__NAME',
        defaultMessage: 'Název přílohy',
      }),
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'type',
      name: intl.formatMessage({
        id: 'ES__ATTACHMENTS__COLUMN__TYPE',
        defaultMessage: 'Typ',
      }),
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useStaticAttachmentTypes
      ),
    },
    {
      datakey: 'file.name',
      name: intl.formatMessage({
        id: 'ES__ATTACHMENTS__COLUMN__FILE_NAME',
        defaultMessage: 'Soubor',
      }),
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
  ];

  const labelColumn: TableFieldColumn<DocumentAttachment> = {
    datakey: 'label',
    name: intl.formatMessage({
      id: 'ES__ATTACHMENTS__COLUMN__LABEL',
      defaultMessage: 'Označení',
    }),
    width: 100,
    CellComponent: TableFieldCells.TextCell,
  };

  const downloadColumn: TableFieldColumn<DocumentAttachment> = {
    datakey: 'id',
    name: '',
    width: 140,
    CellComponent: function Cell({ rowValue }) {
      const { getFileUrl } = useContext(FilesContext);

      return (
        <>
          {rowValue?.file !== undefined && (
            <GetAppIcon
              fontSize="small"
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() => {
                console.log(getFileUrl(rowValue.id));
                const link = document.createElement('a');
                link.download = rowValue.file.name;
                link.href = getFileUrl(rowValue.file.id);
                link.click();
              }}
            />
          )}
        </>
      );
    },
  };

  return useMemo(
    () =>
      agenda === Agenda.COURT
        ? [downloadColumn, labelColumn, ...commonColumns]
        : [downloadColumn, ...commonColumns],
    [intl]
  );
}
