import { noop } from 'lodash';
import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import {
  ActionButtonHandle,
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  FieldFilter,
  Filter,
  PagedTableFieldContext,
  TableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarButtons,
  TableFieldToolbarHandle,
  TableFieldToolbarProps,
  useStaticListSource,
  useUpdateEffect,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { DeliveredDocument, DictionaryObject, EsFile } from '@models';
import { Agenda, DocumentType, UIElement } from '@enums';
import { useExportDocuments } from './actions/export-documents-hook';
import { AddInternalDocumentButton } from './dialog-actions/add-internal-document-hook';
import { AddOutgoingDocumentButton } from './dialog-actions/add-outgoing-document-hook';
import { AssignDeliveredDocumentButton } from './dialog-actions/assign-delivered-document-hook';
import { useCreateAcceptanceLetterDialog } from './dialog-actions/create-acceptance-letter-hook';
import { useCreateVotingProtocolDialog } from './dialog-actions/create-voting-protocol-hook';
import {
  useRemoveComplaintDialog,
  useSetComplaintDialog,
} from './dialog-actions/set-complaint-hook';
import {
  useRemoveDecisionDialog,
  useSetDecisionDialog,
} from './dialog-actions/set-decision-hook';
import { DocumentContext } from './document-context';
import { getInitialData } from './document-utils';

type DocumentFieldToolbarProps = TableFieldToolbarProps & {
  documentTypes: DictionaryObject[];
  documentDeliveryMethods: DictionaryObject[];
  documentDispatchStates: DictionaryObject[];
  preFilters: Filter[];
  disabled: boolean;
  hasPermission: (element: string) => boolean;
};

export const DocumentToolbar = forwardRef(function DocumentsToolbar(
  {
    selectedIndex,
    preFilters,
    documentTypes,
    documentDeliveryMethods,
    documentDispatchStates,
    disabled,
    hasPermission,
  }: DocumentFieldToolbarProps,
  ref
) {
  const classes = useStyles();

  const intl = useIntl();

  const { source } = useContext<DetailHandle<EsFile>>(DetailContext);

  const { sorts } = useContext(TableFieldContext);
  const { setOpenState } = useContext(DocumentContext);
  const { source: documentSource } = useContext(PagedTableFieldContext);

  useUpdateEffect(() => {
    setOpenState({});
  }, [JSON.stringify(sorts)]);

  const useDocumentTypes = () => useStaticListSource(documentTypes);
  const useDocumentDeliveryMethods = () =>
    useStaticListSource(documentDeliveryMethods);
  const useDocumentDispatchStates = () =>
    useStaticListSource(documentDispatchStates);

  const { callApi: exportDocumentsApiCall } = useExportDocuments();
  const { callApi: setComplaintApiCall } = useSetComplaintDialog();
  const { callApi: removeComplaintApiCall } = useRemoveComplaintDialog();
  const { callApi: setDecisionApiCall } = useSetDecisionDialog();
  const { callApi: removeDecisionApiCall } = useRemoveDecisionDialog();
  const {
    callApi: createAcceptanceLetterApiCall,
    ResultFields: AcceptaceLetterResultFields,
  } = useCreateAcceptanceLetterDialog();
  const { callApi: createVotingProtocoloApiCall } =
    useCreateVotingProtocolDialog();

  const assignDeliveredDocumentButton = {
    show: source?.data?.agenda !== Agenda.PLENARY_OPINION,
    disabled:
      disabled ||
      !hasPermission(UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON),
  };

  const addInternalDocumentButton = {
    show: true,
    disabled:
      disabled ||
      !hasPermission(UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON),
  };

  const addOutgoingDocumentButton = {
    show: source?.data?.agenda !== Agenda.PLENARY_OPINION,
    disabled:
      disabled ||
      !hasPermission(UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON),
  };

  const createAcceptanceLetterButton = {
    show: source.data?.agenda === Agenda.COURT,
    disabled:
      disabled ||
      !hasPermission(UIElement.Document.CREATE_ACCEPTANCE_LETTER_BUTTON),
  };

  const createVotingProtocolButton = {
    show:
      source.data?.agenda === Agenda.COURT ||
      source.data?.agenda === Agenda.DISCIPLINARY_PROCEEDINGS ||
      source.data?.agenda === Agenda.PLENARY_OPINION,
    disabled:
      disabled ||
      !hasPermission(UIElement.Document.CREATE_VOTING_PROTOCOL_BUTTON),
  };

  const setComplaintRef = useRef<ActionButtonHandle>(null);
  const removeComplaintRef = useRef<ActionButtonHandle>(null);
  const setDecisionRef = useRef<ActionButtonHandle>(null);
  const removeDecisionRef = useRef<ActionButtonHandle>(null);

  useImperativeHandle(
    ref,
    () => ({
      executeAction: (action) => {
        switch (action) {
          case 'SET_COMPLAINT':
            setComplaintRef.current?.executeAction();
            break;

          case 'REMOVE_COMPLAINT':
            removeComplaintRef.current?.executeAction();
            break;

          case 'SET_DECISION':
            setDecisionRef.current?.executeAction();
            break;

          case 'REMOVE_DECISION':
            removeDecisionRef.current?.executeAction();
            break;

          default:
            break;
        }
      },
    }),
    []
  );

  const selectedDocument =
    selectedIndex !== undefined ? documentSource?.items[selectedIndex] : {};

  // Refresh when the first document or the first uncancelled document is added
  const refreshAfterAddingDocument =
    (source?.data?.documents ?? []).length === 0 ||
    source?.data?.documents?.filter(
      (d) => !!d.canceledDate || (d as DeliveredDocument).removedFromEsFile
    ).length === (source?.data?.documents ?? []).length;

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        {assignDeliveredDocumentButton.show && (
          <AssignDeliveredDocumentButton
            disabled={assignDeliveredDocumentButton.disabled}
          />
        )}
        {addInternalDocumentButton.show && (
          <AddInternalDocumentButton
            disabled={addInternalDocumentButton.disabled}
            initialValues={getInitialData(source.data!, DocumentType.INTERNAL)}
            refreshAfterConfirm={refreshAfterAddingDocument}
          />
        )}
        {addOutgoingDocumentButton.show && (
          <AddOutgoingDocumentButton
            disabled={addOutgoingDocumentButton.disabled}
            initialValues={getInitialData(source.data!, DocumentType.OUTGOING)}
            refreshAfterConfirm={refreshAfterAddingDocument}
          />
        )}

        {createAcceptanceLetterButton.show && (
          <DetailActionButton
            promptKey="CREATE_ACCEPTANCE_LETTER"
            apiCall={createAcceptanceLetterApiCall}
            buttonLabel={intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__CREATE_ACCEPTANCE_LETTER',
              defaultMessage: 'Vytvořit akceptační dopis',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__CREATE_ACCEPTANCE_LETTER__TITLE',
              defaultMessage: 'Vytvoření akceptačního dopisu',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__CREATE_ACCEPTANCE_LETTER__TEXT',
              defaultMessage:
                'Opravdu chcete k vybranému spisu vytvořit akceptační dopis?',
            })}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            ButtonComponent={function Button({ label, onClick }) {
              return (
                <TableFieldToolbarButton
                  IconComponent={NoteAddIcon}
                  show={true}
                  disabled={createAcceptanceLetterButton.disabled ?? false}
                  title={label}
                  onClick={onClick}
                  color="default"
                >
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'ES__DOCUMENTS_TOOLBAR__CREATE_ACCEPTANCE_LETTER__BUTTON',
                      defaultMessage: 'Akceptační dopis',
                    })}
                  </Typography>
                </TableFieldToolbarButton>
              );
            }}
            resultDialog={{
              title: intl.formatMessage({
                id: 'ES__DOCUMENTS_TOOLBAR__CREATE_ACCEPTANCE_LETTER_RESULT__TITLE',
                defaultMessage: 'Akceptační dopis',
              }),
              text: intl.formatMessage({
                id: 'ES__DOCUMENTS_TOOLBAR__CREATE_ACCEPTANCE_LETTER_RESULT__TEXT',
                defaultMessage: 'Vytvořené dokumenty',
              }),
              FormFields: AcceptaceLetterResultFields,
              width: 1000,
            }}
            dialogWidth={500}
          />
        )}

        {createVotingProtocolButton.show && (
          <DetailActionButton
            promptKey="CREATE_VOTING_PROTOCOL"
            apiCall={noop as any}
            callback={createVotingProtocoloApiCall}
            buttonLabel={intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__CREATE_VOTING_PROTOCOL',
              defaultMessage: 'Vytvořit protokol o hlasování',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__CREATE_VOTING_PROTOCOL__TITLE',
              defaultMessage: 'Vytvoření protokolu o hlasování',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__CREATE_VOTING_PROTOCOL__TEXT',
              defaultMessage:
                'Opravdu chcete k vybranému spisu vytvořit protokol o hlasování?',
            })}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            ButtonComponent={function Button({ label, onClick }) {
              return (
                <TableFieldToolbarButton
                  IconComponent={NoteAddIcon}
                  show={true}
                  disabled={createVotingProtocolButton.disabled ?? false}
                  title={label}
                  onClick={onClick}
                  color="default"
                >
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'ES__DOCUMENTS_TOOLBAR__CREATE_VOTING_PROTOCOL__BUTTON',
                      defaultMessage: 'Protokol o hlasování',
                    })}
                  </Typography>
                </TableFieldToolbarButton>
              );
            }}
          />
        )}

        <DetailActionButton
          ref={setComplaintRef}
          promptKey="SET_COMPLAINT"
          apiCall={(id) => setComplaintApiCall(id, selectedDocument.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__SET_COMPLAINT',
            defaultMessage: 'Nastavit stížnost',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__SET_COMPLAINT__TITLE',
            defaultMessage: 'Stížnost',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__SET_COMPLAINT__TEXT',
            defaultMessage:
              'Opravdu si přejete nastavit stížnost vybranému dokumentu?',
          })}
          modes={[]}
        />
        <DetailActionButton
          ref={removeComplaintRef}
          promptKey="REMOVE_COMPLAINT"
          apiCall={(id) => removeComplaintApiCall(id, selectedDocument.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__REMOVE_COMPLAINT',
            defaultMessage: 'Odebrat stížnost',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__REMOVE_COMPLAINT__TITLE',
            defaultMessage: 'Stížnost',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__REMOVE_COMPLAINT__TEXT',
            defaultMessage:
              'Opravdu si přejede odebrat stížnost vybranému dokumentu?',
          })}
          modes={[]}
        />
        <DetailActionButton
          ref={setDecisionRef}
          promptKey="SET_DECISION"
          apiCall={(id) => setDecisionApiCall(id, selectedDocument.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__SET_DECISION',
            defaultMessage: 'Nastavit správní rozhodnutí',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__SET_DECISION__TITLE',
            defaultMessage: 'Správní rozhodnutí',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__SET_DECISION__TEXT',
            defaultMessage:
              'Opravdu si přejete nastavit správní rozhodnutí vybranému dokumentu?',
          })}
          modes={[]}
        />
        <DetailActionButton
          ref={removeDecisionRef}
          promptKey="REMOVE_DECISION"
          apiCall={(id) => removeDecisionApiCall(id, selectedDocument.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__REMOVE_DECISION',
            defaultMessage: 'Odebrat správní rozhodnutí',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__REMOVE_DECISION__TITLE',
            defaultMessage: 'Správní rozhodnutí',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__REMOVE_DECISION__TEXT',
            defaultMessage:
              'Opravdu si přejete odebrat správní rozhodnutí vybranému dokumentu?',
          })}
          modes={[]}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButton
          title={intl.formatMessage({
            id: 'ES__DOCUMENTS_TOOLBAR__DOWNLOAD',
            defaultMessage: 'Tisková sestava dokumentů',
          })}
          variant="text"
          color="default"
          onClick={exportDocumentsApiCall}
          IconComponent={GetAppIcon}
          disabled={!documentSource?.items?.length}
          show={true}
        >
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__DOWNLOAD__BUTTON',
              defaultMessage: 'Tisk dokumentů',
            })}
          </Typography>
        </TableFieldToolbarButton>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={documentSource}
          preFilters={preFilters}
          filtersFields={[
            {
              label: intl.formatMessage({
                id: 'ES__DEADLINES_TOOLBAR__FILTER__DELIVERY_DATE',
                defaultMessage: 'Datum doručení',
              }),
              datakey: 'deliveryDate',
              filterkey: 'deliveryDate',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterDateCell,
            },
            {
              label: intl.formatMessage({
                id: 'ES__DEADLINES_TOOLBAR__FILTER__CREATED_DATE',
                defaultMessage: 'Datum vytvoření',
              }),
              datakey: 'createdDate',
              filterkey: 'createdDate',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterLocalDateTimeCell,
            },
            {
              label: intl.formatMessage({
                id: 'ES__DEADLINES_TOOLBAR__FILTER__REFERENCE_NUMBER',
                defaultMessage: 'Číslo jednací',
              }),
              datakey: 'referenceNumber',
              filterkey: 'referenceNumber',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: intl.formatMessage({
                id: 'ES__DEADLINES_TOOLBAR__FILTER__INCOMING_NUMBER',
                defaultMessage: 'Došlé číslo',
              }),
              datakey: 'incomingNumber',
              filterkey: 'incomingNumber',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: intl.formatMessage({
                id: 'ES__DEADLINES_TOOLBAR__FILTER__TYPE',
                defaultMessage: 'Typ',
              }),
              datakey: 'type',
              filterkey: 'type.id',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterSelectCell(useDocumentTypes),
            },
            {
              label: intl.formatMessage({
                id: 'ES__DEADLINES_TOOLBAR__FILTER__SUMMARY',
                defaultMessage: 'Stručný obsah',
              }),
              datakey: 'summary',
              filterkey: 'summary',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: intl.formatMessage({
                id: 'ES__DEADLINES_TOOLBAR__FILTER__DELIVERY_METHOD',
                defaultMessage: 'Způsob doručení',
              }),
              datakey: 'deliveryMethod',
              filterkey: 'deliveryMethod.id',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterSelectCell(
                useDocumentDeliveryMethods
              ),
            },
            {
              label: intl.formatMessage({
                id: 'ES__DEADLINES_TOOLBAR__FILTER__DISPATCH_STATE',
                defaultMessage: 'Stav vypravení',
              }),
              datakey: 'dispatchState',
              filterkey: 'dispatchState.id',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterSelectCell(
                useDocumentDispatchStates
              ),
            },
          ]}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    ref: Ref<TableFieldToolbarHandle>;
    documentTypes: DictionaryObject[];
    documentDeliveryMethods: DictionaryObject[];
    documentDispatchStates: DictionaryObject[];
    hasPermission: (element: string) => boolean;
    preFilters: Filter[];
    disabled: boolean;
  }
) => ReactElement;
