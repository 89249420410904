import React, { useContext } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  TableContext,
  TableSelectedContext,
  TableToolbarButton,
  useEventCallback,
} from '@eas/common-web';
import { FilterContext } from './filter-context';

export function FilterOutButton() {
  const { selected } = useContext(TableSelectedContext);
  const { resetSelection } = useContext(TableContext);
  const { appendReducer } = useContext(FilterContext);

  const handleRemove = useEventCallback(() => {
    appendReducer(selected);

    resetSelection();
  });

  return (
    <TableToolbarButton
      label={<DeleteForeverIcon />}
      onClick={handleRemove}
      secondary={true}
      disabled={selected.length === 0}
    />
  );
}
