import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormCheckbox,
  FormFileField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';

export function LetterFields({ disabled = false }: { disabled?: boolean }) {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__PANEL_TITLE__LETTER_ATTRIBUTES"
            defaultMessage="Rozšiřující atributy ČP"
          />
        }
        expandable={false}
      >
        <FormTextField
          name="envelopeContent"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__ENVELOPE_CONTENT"
              defaultMessage="Obsah obálky"
            />
          }
          disabled={disabled}
        />
        <FormTextField
          name="shipmentType"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__SHIPMENT_TYPE"
              defaultMessage="Typ zásilky"
            />
          }
          disabled={disabled}
        />
        <FormTextField
          name="shipmentNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__SHIPMENT_NUMBER"
              defaultMessage="Číslo zásilky"
            />
          }
          disabled={true}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__PANEL_TITLE__ADDITIONAL_SERVICES"
            defaultMessage="ČP - Doplňkové služby"
          />
        }
      >
        <FormTextField
          name="additionalServices.deliveryNote"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DELIVERY_NOTE"
              defaultMessage="Dodejka"
            />
          }
          disabled={disabled}
        />
        <FormCheckbox
          name="additionalServices.intoOwnHands"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__INTO_OWN_HANDS"
              defaultMessage="Dodání do vlastních rukou"
            />
          }
          disabled={disabled}
        />
        <FormCheckbox
          name="additionalServices.intoOwnHandsToAddressee"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__INTO_OWN_HANDS_TO_ADDRESSEE"
              defaultMessage="Dodání do vlastních rukou výhradně jen adresáta"
            />
          }
          disabled={disabled}
        />
        <FormFileField
          name="deliveryNote"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DELIVERY_NOTE"
              defaultMessage="Doručenka"
            />
          }
          disabled={true}
        />
      </FormPanel>
    </>
  );
}
