import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { DictTaskName } from '@models';
import { EntityName, EvidenceApiUrl } from '@enums';
import { Fields } from './dict-task-name-fields';

export function DictTaskNames() {
  const intl = useIntl();
  const { columnName, columnCode, columnOrder, columnActive } =
    DictionaryEvidence.useDictionaryColumns<DictTaskName>();

  const evidence = useDictionaryEvidence<DictTaskName>({
    version: 2,
    identifier: 'TASK_NAMES',
    apiProps: {
      url: EvidenceApiUrl.DICT_TASK_NAME,
    },
    tableProps: {
      columns: [columnName, columnCode, columnOrder, columnActive],
      tableName: intl.formatMessage({
        id: 'ES__DICT_TASK_NAMES__TABLE__TITLE',
        defaultMessage: 'Názvy úloh',
      }),
      showReportButton: false,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.TASK_NAME,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_TASK_NAMES__DETAIL__TITLE',
          defaultMessage: 'Název úlohy',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
