import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  FormCheckboxGroup,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';

export function Fields() {
  const historyDescriptors = useCommonHistoryDescriptors();

  const { agendas, settlementMethodTags } = useContext(StaticDataContext);

  const agendaSource = useStaticListSource(agendas);
  const settlementMethodTagSource = useStaticListSource(settlementMethodTags);

  return (
    <>
      <DictionaryEvidence.FieldName />
      <DictionaryEvidence.FieldActive />
      <FormSelect
        name="agenda"
        label={
          <FormattedMessage
            id="ES__ES_FILES__DETAIL__FIELD_LABEL__AGENDA"
            defaultMessage="Agenda"
          />
        }
        required
        source={agendaSource}
        valueIsId={true}
      />
      <FormCheckboxGroup
        name="tags"
        source={settlementMethodTagSource}
        label={
          <FormattedMessage
            id="ES__ES_FILES__DETAIL__FIELD_LABEL__TAGS"
            defaultMessage="Vlastnosti"
          />
        }
      />
      <HistoryField
        descriptors={[
          ...historyDescriptors,
          { key: 'agenda', label: 'Agenda' },
          { key: 'defaultValue', label: 'Výchozí hodnota číselníku' },
          {
            key: 'forRegisterRemoval',
            label: 'Hodnota pro spisy vyvedeny z rejstříku',
          },
          {
            key: 'enabledForSubmissionEvidence',
            label: 'Povoleno pro evidenci podání',
          },
        ]}
      />
    </>
  );
}
