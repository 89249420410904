import { sum } from 'lodash';
import React, { ReactNode, forwardRef, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography/Typography';
import {
  Dialog,
  DialogHandle,
  FormNumberField,
  FormPanel,
  useFormSelector,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';

export interface ResultDialogProps {
  title: ReactNode;
  content: ReactNode;
}

export function useResultDialog(handleConfirm: () => void) {
  return forwardRef<DialogHandle, ResultDialogProps>(function ResultDialog(
    { title, content },
    ref
  ) {
    return (
      <Dialog
        ref={ref}
        title={title}
        onConfirm={handleConfirm}
        showClose={true}
      >
        {() => <>{content}</>}
      </Dialog>
    );
  });
}

export function ResultFields() {
  const intl = useIntl();

  const { agendas, roles } = useContext(StaticDataContext);
  const {
    esFileModifiedCount,
    userRoleAttributeModifiedCount,
  } = useFormSelector((data: any) => ({
    esFileModifiedCount: data.esFileModifiedCount,
    userRoleAttributeModifiedCount: data.userRoleAttributeModifiedCount,
  }));

  const count =
    (esFileModifiedCount && sum(Object.values(esFileModifiedCount))) ?? 0;

  return (
    <div style={{ width: 'fit-content' }}>
      <Typography variant="body1">
        {count === 0
          ? 'Vybraný uživatel není zpracovatelem žádného spisu.'
          : `Zpracovatel bude nahrazen na ${count} ${
              count > 1 ? 'spisech' : 'spisu'
            }. Pro dokončení akce klikněte na POTVRDIT.`}
      </Typography>
      {esFileModifiedCount && Object.entries(esFileModifiedCount).length > 0 && (
        <FormPanel
          label={
            <FormattedMessage
              id="ES__USER__REPLACE_USER__RESULT_DIALOG__PANEL_TITLE__REPLACED_ES_FILES"
              defaultMessage="Výskyty k nahrazení dle agendy"
            />
          }
        >
          {Object.keys(esFileModifiedCount)?.map((key) => {
            const label = agendas.find((agenda) => agenda.id === key)?.name;

            return (
              <FormNumberField
                key={key}
                name={`esFileModifiedCount.${key}`}
                label={intl.formatMessage(
                  {
                    id: `ES__USER__REPLACE_USER__RESULT_DIALOG__FIELD_LABEL__AGENDA_${key}`,
                    defaultMessage: '{label}',
                  },
                  {
                    label: label,
                  }
                )}
              />
            );
          })}
        </FormPanel>
      )}
      {userRoleAttributeModifiedCount &&
        Object.entries(userRoleAttributeModifiedCount).length > 0 && (
          <FormPanel
            label={
              <FormattedMessage
                id="ES__USER__REPLACE_USER__RESULT_DIALOG__PANEL_TITLE__REPLACED_ROLES"
                defaultMessage="Výskyty k nahrazení dle role"
              />
            }
          >
            {Object.keys(userRoleAttributeModifiedCount)?.map((key) => {
              const label = roles.find((role) => role.id === key)?.name;

              return (
                <FormNumberField
                  key={key}
                  name={`userRoleAttributeModifiedCount.${key}`}
                  label={intl.formatMessage(
                    {
                      id: `ES__USER__REPLACE_USER__RESULT_DIALOG__FIELD_LABEL__ROLE_${key}`,
                      defaultMessage: '{label}',
                    },
                    {
                      label: label,
                    }
                  )}
                />
              );
            })}
          </FormPanel>
        )}
    </div>
  );
}
