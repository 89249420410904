import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { Dispatch } from '@models';
import {
  DispatchMethod,
  DispatchState,
  EntityName,
  EvidenceApiUrl,
} from '@enums';
import { ActionBar } from './electronic-document-dispatch-actionbar';
import { useColumns } from './electronic-document-dispatch-columns';
import { Fields } from './electronic-document-dispatch-fields';
import { ElectronicDocumentDispatchToolbar } from './electronic-document-dispatch-toolbar';

export function ElectronicDocumentDispatches() {
  const intl = useIntl();

  const columns = useColumns();

  const evidence = useAuthoredEvidence<Dispatch>({
    version: 6,
    identifier: 'DOCUMENT_DISPATCHES_ELECTRONIC',
    apiProps: {
      url: EvidenceApiUrl.DOCUMENTS_DISPATCHES,
    },
    tableProps: {
      defaultPreFilters: [
        {
          field: 'state.id',
          operation: ApiFilterOperation.EQ,
          value: DispatchState.SENT,
        },
        {
          operation: ApiFilterOperation.OR,
          filters: [
            {
              field: 'method.id',
              operation: ApiFilterOperation.EQ,
              value: DispatchMethod.EMAIL,
            },
            {
              field: 'method.id',
              operation: ApiFilterOperation.EQ,
              value: DispatchMethod.DATA_BOX,
            },
          ],
        },
      ],
      columns,
      tableName: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TABLE__TITLE',
        defaultMessage: 'Vypravení - elektronické dokumenty',
      }),
      showReportButton: false,
      defaultSorts: [],
      toolbarProps: {
        after: <ElectronicDocumentDispatchToolbar />,
      },
    },
    detailProps: {
      entityName: EntityName.DISPATCH,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__TITLE',
          defaultMessage: 'Vypravení',
        }),
        showBtn: (btn) => ['REFRESH', 'SHARE'].includes(btn),
        ActionBar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
