const ALL_USERS = {
  title: 'Uživatelé',
  description: 'Přehled všech uživatelů evidovaných v systému.',
  actions: [
    {
      name: 'Nahradit zpracovatele',
      description:
        'Tato funkcionalita slouží k hromadnému nahrazení uživatele, který je veden u spisů v některé roli zpracovatele (vedoucí rejstříku, tajemnice, soudce zpravodaj, rozhodující orgán, asistent, řešitel). Nahrazení probíhá pro všechny otevřené spisy.',
    },
    {
      name: 'Upravení uživatele',
      description: 'Uživatel může upravovat jednotlivé atributy uživatele.',
    },
    {
      name: 'Zneplatnit',
      description: 'Zneplatnění uživatele.',
    },

    {
      name: 'Obnovit platnost',
      description: 'Obnovení platnosti uživatele.',
    },
  ],
};

const USER_ROLES = {
  title: 'Uživatelské role',
  description: 'Přehled všech uživatelských rolí evidovaných v systému.',
};

const USER_SUBSTITUTIONS = {
  title: 'Zástupy uživatelů',
  description:
    'Přehled všech zástupů uživatelů evidovaných v systému. Zastupující uživatel dostává přístup ke všem neuzavřeným spisům, které má přístupné zastupovaný uživatel.',
  actions: [
    {
      name: 'Vytvoření zástupu',
      description: 'Vytvoření zástupu uživatele.',
    },
    {
      name: 'Upravení zástupu',
      description:
        'Uživatel může upravovat jednotlivé atributy zástupu uživatele.',
    },
  ],
};

export const USERS = {
  title: 'Uživatelé',
  sections: [ALL_USERS, USER_ROLES, USER_SUBSTITUTIONS],
};
