import React, { ReactNode, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { AppSettingsContext, UserContext } from '@eas/common-web';
import { EsAppSettings } from '@models';
import { useTheme } from './theme-hook';

export function ThemeProvider({ children }: { children: ReactNode }) {
  const { settings } = useContext<AppSettingsContext<EsAppSettings>>(
    AppSettingsContext as any
  );
  const userCtx = useContext(UserContext);

  const theme = useTheme({
    primaryColor: userCtx?.user?.additionalDetails.activeSubstitution
      ? settings.substitutionPrimaryColor ?? '#43a047'
      : settings.primaryColor ?? '#0d98ba',
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
