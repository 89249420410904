import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { ParticipantCell } from '@modules/es-file/es-file-columns';
import {
  autocompleteLabelMapper,
  subjectContactColumnMapper,
} from '@components/form/misc/label-mappers';
import { InfFile } from '@models';
import { useContacts } from '../subject/subject-api';

export function useInfColumns() {
  const intl = useIntl();

  const columnDeliveryDate: TableColumn<InfFile> = {
    datakey: 'deliveryDate',
    name: intl.formatMessage({
      id: 'ES__INF_FILES__TABLE__COLUMN__DELIVERY_DATE',
      defaultMessage: 'Datum doručení',
    }),
    width: 150,
    CellComponent: TableCells.DateCell,
    FilterComponent: TableFilterCells.FilterDateCell,
    sortable: true,
    filterable: true,
  };

  const columnIncomingNumber: TableColumn<InfFile> = {
    datakey: 'incomingNumber',
    name: intl.formatMessage({
      id: 'ES__INF_FILES__TABLE__COLUMN__INCOMING_NUMBER',
      defaultMessage: 'Došlé číslo',
    }),
    width: 150,
    CellComponent: TableCells.TextCell,
    FilterComponent: TableFilterCells.FilterTextCell,
    sortable: true,
    filterable: true,
  };

  const columnWriters: TableColumn<InfFile> = {
    datakey: 'writers',
    filterkey: 'writers.contact.id',
    sortkey: 'writers.contact.name',
    name: intl.formatMessage({
      id: 'ES__INF_FILES__TABLE__COLUMN__WRITERS',
      defaultMessage: 'Pisatelé',
    }),
    width: 300,
    CellComponent: ParticipantCell('writers'),
    valueMapper: subjectContactColumnMapper,
    FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
      useContacts,
      autocompleteLabelMapper
    ),
    filterOperation: ApiFilterOperation.EQ,
    filterable: true,
    sortable: true,
  };

  return { columnDeliveryDate, columnIncomingNumber, columnWriters };
}
