import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { RestMessage } from '@models';

export function useColumns(): TableColumn<RestMessage>[] {
  const intl = useIntl();

  const { restServices } = useContext(StaticDataContext);
  const useRestServices = () => useStaticListSource(restServices);

  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<RestMessage>();

  return [
    {
      datakey: 'service',
      filterkey: 'service.id',
      sortkey: 'service.name',
      name: intl.formatMessage({
        id: 'ES__REST_MESSAGE__TABLE__COLUMN__SERVICE',
        defaultMessage: 'Název služby',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useRestServices),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useRestServices),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'method',
      name: intl.formatMessage({
        id: 'ES__REST_MESSAGE__TABLE__COLUMN__METHOD',
        defaultMessage: 'HTTP metoda',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'path',
      name: intl.formatMessage({
        id: 'ES__REST_MESSAGE__TABLE__COLUMN__PATH',
        defaultMessage: 'URL',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'httpStatus',
      name: intl.formatMessage({
        id: 'ES__REST_MESSAGE__TABLE__COLUMN__HTTP_STATUS',
        defaultMessage: 'HTTP status',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'duration',
      name: intl.formatMessage({
        id: 'ES__REST_MESSAGE__TABLE__COLUMN__DURATION',
        defaultMessage: 'Délka trvání operace',
      }),
      width: 200,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
    },
    ...authoredColumns,
  ];
}
