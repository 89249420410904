import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { RestMessage } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './rest-message-columns';
import { Fields } from './rest-message-fields';

export function RestMessages() {
  const intl = useIntl();

  const columns = useColumns();

  const evidence = useAuthoredEvidence<RestMessage>({
    version: 2,
    identifier: 'REST_MESSAGES',
    apiProps: {
      url: EvidenceApiUrl.REST_MESSAGES,
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'ES__REST_MESSAGES__TABLE__TITLE',
        defaultMessage: 'Integrační REST volání',
      }),
      showReportButton: false,
      defaultSorts: [
        { field: 'created', datakey: 'created', type: 'FIELD', order: 'DESC' },
      ],
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__REST_MESSAGES__DETAIL__TITLE',
          defaultMessage: 'REST zpráva',
        }),
        showBtn: function showBtn(btn) {
          return ['SHARE', 'REFRESH'].includes(btn);
        },
      },
    },
  });

  return <Evidence {...evidence} />;
}
