import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    { key: 'actName', label: 'Název aktu' },
    { key: 'actType', label: 'Druh aktu' },
    { key: 'challengedProvisions', label: 'Napadená ustanovení' },
    { key: 'concernedAuthority', label: 'Dotčený orgán' },
    { key: 'concernedAuthorityType', label: 'Typ dotčeného orgánu' },
    { key: 'esFile', label: 'Spis' },
    { key: 'externalFileNumber', label: 'Spisová značka' },
    { key: 'firstInstance', label: 'Prvoinstanční' },
  ];
}
