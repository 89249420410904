export enum Agenda {
  /**
   * Kárné řízení
   */
  DISCIPLINARY_PROCEEDINGS = 'DISCIPLINARY_PROCEEDINGS',

  /**
   * INF
   */
  INF = 'INF',

  /**
   * Soudní spisy
   */
  COURT = 'COURT',

  /**
   * SPR
   */
  SPR = 'SPR',

  /**
   * Stanovisko pléna
   */
  PLENARY_OPINION = 'PLENARY_OPINION',
}
