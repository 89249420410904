import React from 'react';
import { HistoryFieldProps } from '../history-types';
import { StringHistoryField } from './string-history-field';

export function LabeledHistoryField({
  value,
  position,
}: HistoryFieldProps<{ id: string; label: string }>) {
  return <StringHistoryField value={value?.label} position={position} />;
}
