import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const commonDescriptors = useCommonHistoryDescriptors();

  return [
    ...commonDescriptors,
    {
      key: 'esFile',
      label: 'Spis',
    },
    {
      key: 'agenda',
      label: 'Agenda',
    },
    {
      key: 'date',
      label: 'Datum',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'summary',
      label: 'Stručný obsah',
    },
    {
      key: 'pageCount',
      label: 'Počet listů',
    },
    { key: 'attachments', label: 'Přílohy' },
    {
      key: 'order',
      label: 'Pořadí',
    },
    {
      key: 'barCode',
      label: 'Čárový kód',
    },
    {
      key: 'type',
      label: 'Typ dokumentu',
    },
    {
      key: 'referenceNumber',
      label: 'Číslo jednací',
    },
    {
      key: 'essDocument',
      label: 'Dokument v ESS',
    },
  ];
}
