import { noop, stubFalse, sumBy } from 'lodash';
import React from 'react';
import { Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { lighten } from '@material-ui/core/styles';
import { TableField } from '@eas/common-web';
import { useStyles } from '@modules/dashboard/search/result-table';
import { SubmissionCount } from '@models';
import { Agenda, ChartView } from '@enums';
import { Chart } from '../card/chart';
import { useSubmissionCountsColumns } from './submission-counts-columns';

export function useSubmissionCountsVisualisation({
  data = [],
  agenda,
  chartHeight,
  maxTableRows = 7,
}: {
  data?: SubmissionCount[];
  agenda?: Agenda;
  chartHeight?: number;
  maxTableRows?: number;
}) {
  const Table = () => {
    const classes = useStyles();

    const columns = useSubmissionCountsColumns(agenda);
    const parsedData = parseData(data);

    return (
      <TableField
        value={parsedData}
        onChange={noop}
        columns={columns ?? []}
        showRadioCond={stubFalse}
        classes={classes}
        maxRows={maxTableRows}
        showToolbar={false}
        createRowStyle={(item) =>
          item?.year === 'Celkem'
            ? { backgroundColor: lighten('#0d98ba', 0.8) }
            : {}
        }
      />
    );
  };

  const PlenaryFilesChart = () => {
    return (
      <Chart data={data} ChartComponent={LineChart} height={chartHeight}>
        <YAxis />
        <Tooltip
          formatter={(value: string, name: string) => [
            value,
            name === 'plenaryCount' ? 'Plenární spisy' : 'Senátní spisy',
          ]}
        />
        <Legend
          formatter={(value) =>
            value === 'plenaryCount' ? 'Plenární spisy' : 'Senátní spisy'
          }
        />
        <XAxis dataKey="year" />
        <Line dataKey="plenaryCount" stroke="#ff5555" />
      </Chart>
    );
  };

  const SenateFilesChart = () => {
    return (
      <Chart data={data} ChartComponent={LineChart} height={chartHeight}>
        <YAxis />
        <Tooltip
          formatter={(value: string, name: string) => [
            value,
            name === 'plenaryCount' ? 'Plenární spisy' : 'Senátní spisy',
          ]}
        />
        <Legend
          formatter={(value) =>
            value === 'plenaryCount' ? 'Plenární spisy' : 'Senátní spisy'
          }
        />
        <XAxis dataKey="year" />
        <Line dataKey="senateCount" stroke="#5555ff" />
      </Chart>
    );
  };

  const availableViews = [
    { view: ChartView.TABLE, tooltip: 'Tabulka - počty podání' },
    {
      view: ChartView.LINE_CHART,
      tooltip: 'Spojnicový graf - počty podání plenárních spisů',
    },
    {
      view: ChartView.LINE_CHART_2,
      tooltip: 'Spojnicový graf - počty podání senátních spisů',
    },
  ];

  return {
    Visualisations: {
      [ChartView.TABLE]: Table,
      [ChartView.LINE_CHART]: PlenaryFilesChart,
      [ChartView.LINE_CHART_2]: SenateFilesChart,
    },
    availableViews,
  };
}

const parseData = (data: SubmissionCount[]) => {
  const parsedData: SubmissionCount[] = [...data];

  if (parsedData?.length > 0) {
    parsedData.push({
      year: 'Celkem',
      totalCount: sumBy(data, 'totalCount'),
      plenaryCount: sumBy(data, 'plenaryCount'),
      senateCount: sumBy(data, 'senateCount'),
    });
  }

  return parsedData;
};
