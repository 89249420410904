import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  FormCheckbox,
  FormTextArea,
  FormTextField,
} from '@eas/common-web';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';

export function Fields() {
  const historyDescriptors = useCommonHistoryDescriptors();

  return (
    <>
      <FormTextArea
        name="description"
        required
        label={
          <FormattedMessage
            id="ES__DICT_BARCODE_PRINTERS__DETAIL__FIELD_LABEL__DESCRIPTION"
            defaultMessage="Popis"
          />
        }
      />
      <FormTextField
        name="sharedDirectory"
        required
        label={
          <FormattedMessage
            id="ES__DICT_BARCODE_PRINTERS__DETAIL__FIELD_LABEL__SHARED_DICTIONARY"
            defaultMessage="Sdílený adresář"
          />
        }
      />
      <FormTextField
        name="zplCommand"
        required
        label={
          <FormattedMessage
            id="ES__DICT_BARCODE_PRINTERS__DETAIL__FIELD_LABEL__ZPL_COMMAND"
            defaultMessage="ZPL příkaz"
          />
        }
      />
      <DictionaryEvidence.FieldName />
      <FormTextField
        name="commandLine"
        label={
          <FormattedMessage
            id="ES__DICT_BARCODE_PRINTERS__DETAIL__FIELD_LABEL__COMMAND_LINE"
            defaultMessage="Příkazová řádka"
          />
        }
      />
      <FormCheckbox
        name="hasDefaultValue"
        label={
          <FormattedMessage
            id="ES__DICT_BARCODE_PRINTERS__DETAIL__FIELD_LABEL__HAS_DEFAULT_VALUE"
            defaultMessage="Výchozí hodnota"
          />
        }
      />
      <HistoryField
        descriptors={[
          ...historyDescriptors,
          { key: 'commandLine', label: 'Příkazová řádka' },
          { key: 'hasDefaultValue', label: 'Výchozí adresa' },
          { key: 'sharedDirectory', label: 'Sdílený adresář' },
          { key: 'zplCommand', label: 'ZPL příkaz' },
        ]}
      />
    </>
  );
}
