import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const commonDescriptors = useCommonHistoryDescriptors();

  return [
    ...commonDescriptors,
    {
      key: 'allowPublishing',
      label: 'Umožnit zveřejnění',
    },
    {
      key: 'anonymizedText.abstractText',
      label: 'Anonymizovaný text - abstrakt',
    },
    {
      key: 'anonymizedText.argumentation',
      label: 'Anonymizovaný text - argumentace',
    },
    {
      key: 'anonymizedText.differentOpinion',
      label: 'Anonymizovaný text - odlišné stanovisko',
    },
    {
      key: 'anonymizedText.introduction',
      label: 'Anonymizovaný text - návětí',
    },
    {
      key: 'anonymizedText.legalSentence',
      label: 'Anonymizovaný text - právní věta',
    },
    {
      key: 'anonymizedText.narration',
      label: 'Anonymizovaný text - narace',
    },
    {
      key: 'anonymizedText.statement',
      label: 'Anonymizovaný text - výrok',
    },
    {
      key: 'decisionDate',
      label: 'Datum rozhodnutí',
    },
    {
      key: 'differentOpinion',
      label: 'Odlišné stanovisko',
    },
    {
      key: 'document',
      label: 'Odchozí dokument',
    },
    {
      key: 'ecli',
      label: 'Identifikátor evropské judikatury',
    },
    {
      key: 'esFile',
      label: 'Spis',
    },
    {
      key: 'excludedFromNalus',
      label: 'Vyloučeno z NALUSu',
    },
    {
      key: 'form',
      label: 'Forma rozhodnutí',
    },
    {
      key: 'importance',
      label: 'Význam',
    },
    {
      key: 'language',
      label: 'Jazyk',
    },
    {
      key: 'note',
      label: 'Poznámka',
    },
    {
      key: 'number',
      label: 'Číslo rozhodnutí',
    },
    {
      key: 'parallelCitation.sbNumber',
      label: 'Sbírka: číslo',
    },
    {
      key: 'parallelCitation.sbYear',
      label: 'Sbírka: rok',
    },
    {
      key: 'parallelCitation.sbnuNumber',
      label: 'Sbírka nálezů a usnesení: číslo',
    },
    {
      key: 'parallelCitation.sbnuPage',
      label: 'Sbírka nálezů a usnesení: strana',
    },
    {
      key: 'parallelCitation.sbnuVolume',
      label: 'Sbírka nálezů a usnesení: svazek',
    },
    {
      key: 'popularName',
      label: 'Populární název',
    },
    {
      key: 'publicationDate',
      label: 'Datum vyhlášení',
    },
    {
      key: 'settlementMethods',
      label: 'Způsoby vyřízení',
    },
    {
      key: 'state',
      label: 'Stav rozhodnutí',
    },
    {
      key: 'takeOverIndexes',
      label: 'Převzít indexy',
    },
    {
      key: 'text.abstractText',
      label: 'Text - abstrakt',
    },
    {
      key: 'text.argumentation',
      label: 'Text - argumentace',
    },
    {
      key: 'text.differentOpinion',
      label: 'Text - odlišné stanovisko',
    },
    {
      key: 'text.introduction',
      label: 'Text - návětí',
    },
    {
      key: 'text.legalSentence',
      label: 'Text - právní věta',
    },
    {
      key: 'text.narration',
      label: 'Text - narace',
    },
    {
      key: 'text.statement',
      label: 'Text - výrok',
    },
  ];
}
