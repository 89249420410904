import { stubFalse } from 'lodash';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { UserSubstitution } from '@models';
import { useUserSubstitutionLogColumns } from './user-substitution-log-columns';
import { UserSubstitutionLogToolbar } from './user-substitution-log-toolbar';

export interface UserSubstitutionLogFieldProps {
  disabled: boolean;
  source?: ScrollableSource<UserSubstitution>;
}

export function UserSubstitutionLogField({
  disabled,
  source,
}: UserSubstitutionLogFieldProps) {
  const tableRef = useRef<TableFieldHandle>(null);

  const columns = useUserSubstitutionLogColumns();

  if (!source) {
    return <> Missing USER_ROLE_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__USER_SUBSTITUTION_LOGS__PANEL_TITLE"
          defaultMessage="Zástup využit"
        />
      }
      expandable={true}
      defaultExpanded={true}
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        ref={tableRef}
        source={source}
        showDetailBtnCond={() => false}
        columns={columns}
        maxRows={10}
        ToolbarComponent={UserSubstitutionLogToolbar}
        showRadioCond={stubFalse}
        disabled={disabled}
      />
    </FormPanel>
  );
}
