import React, { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  FormContext,
  FormSelect,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { searchDataBoxApiCall } from '@composite/contact/dialog-actions/search-data-box/search-data-box-hook';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';

export function VerifyDataBoxResultDialog({
  dataBoxId,
}: {
  dataBoxId?: string;
}) {
  const { setFieldValues } = useContext(FormContext);
  const { isdsSubjectTypes } = useContext(StaticDataContext);
  const [wasFound, setWasFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const subjectTypeSource = useStaticListSource(isdsSubjectTypes);

  useEffect(() => {
    const searchDataBox = async () => {
      try {
        setIsLoading(true);
        setIsError(false);
        setWasFound(false);
        const json = await searchDataBoxApiCall({ dataBoxId }).json();
        if (json?.subjects?.length > 0) {
          setWasFound(true);
          setFieldValues(json?.subjects[0]);
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    searchDataBox();
  }, [dataBoxId]);

  return isLoading ? (
    <Typography>Ověřování...</Typography>
  ) : isError ? (
    <Typography>Při ověřování datové schránky se vyskytla chyba.</Typography>
  ) : wasFound ? (
    <>
      <FormTextField name="dataBoxId" label="Id datové schránky" disabled />
      <FormTextField name="firmName" label="Název firmy subjektu" disabled />
      <FormTextField name="ico" label="IČO" disabled />
      <FormTextField name="givenName" label="Jméno subjektu" disabled />
      <FormTextField name="lastName" label="Příjmení subjektu" disabled />
      <FormSelect
        source={subjectTypeSource}
        valueIsId
        name="subjectType"
        label="Typ subjektu"
        disabled
      />
      <FormTextField name="address.label" label="Adresa" disabled />
    </>
  ) : (
    <Typography>
      K datové schránce nebyl nalezen žádný odpovídající subjekt.
    </Typography>
  );
}
