import { merge } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  PromptDialogContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { EsFileRegulation } from '@models';
import { EvidenceApiUrl, RegulationEntityType } from '@enums';

export function useAddRegulationDialog() {
  const callApi = useEventCallback(
    (id: string, formData: { regulations: EsFileRegulation[] }) => {
      return abortableFetch(`${EvidenceApiUrl.ES_FILES}/${id}/regulation`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(
          formData.regulations.map((regulation) => {
            const { sectionOrArticlePart, ...rest } = regulation;

            return regulation.type === RegulationEntityType.ARTICLE
              ? { ...rest, article: sectionOrArticlePart }
              : { ...rest, sectionSign: sectionOrArticlePart };
          })
        ),
      });
    }
  );

  return {
    callApi,
  };
}

function isColumnChanged(
  curr: EsFileRegulation,
  prev: EsFileRegulation,
  column: keyof EsFileRegulation
) {
  return prev[column] !== curr[column] && curr[column];
}

function composeRow(current: EsFileRegulation, previous: EsFileRegulation) {
  let defaults: Partial<EsFileRegulation> = previous;

  if (isColumnChanged(current, previous, 'regulationType')) {
    defaults = {};
  } else if (isColumnChanged(current, previous, 'number')) {
    defaults = {
      regulationType: previous.regulationType,
    };
  } else if (isColumnChanged(current, previous, 'year')) {
    defaults = {
      regulationType: previous.regulationType,
      number: previous.number,
    };
  } else if (isColumnChanged(current, previous, 'type')) {
    defaults = {
      year: previous.year,
      regulationType: previous.regulationType,
      number: previous.number,
    };
  } else if (isColumnChanged(current, previous, 'sectionOrArticlePart')) {
    defaults = {
      type: previous.type,
      year: previous.year,
      regulationType: previous.regulationType,
      number: previous.number,
    };
  } else if (isColumnChanged(current, previous, 'letter')) {
    defaults = {
      paragraph: previous.paragraph,
      sectionOrArticlePart: previous.sectionOrArticlePart,
      type: previous.type,
      year: previous.year,
      regulationType: previous.regulationType,
      number: previous.number,
    };
  } else if (isColumnChanged(current, previous, 'order')) {
    defaults = {
      letter: previous.letter,
      paragraph: previous.paragraph,
      sectionOrArticlePart: previous.sectionOrArticlePart,
      type: previous.type,
      year: previous.year,
      regulationType: previous.regulationType,
      number: previous.number,
    };
  }

  return merge(current, defaults);
}

function completeData(regulations: EsFileRegulation[]) {
  regulations.forEach((_, index) => {
    const composed = composeRow(
      regulations[index],
      regulations[index - 1] ?? {}
    );

    regulations[index] = composed;
  });

  return regulations;
}

export function CompleteButton({ loading }: { loading: boolean }) {
  const classes = useStyles();

  const { formRef } = useContext(PromptDialogContext);

  const handleComplete = useEventCallback(() => {
    const { regulations } = formRef.current?.getFieldValues();

    const values = completeData(regulations);
    formRef.current?.setFieldValues({ regulations: values });
  });

  return (
    <Button
      type="submit"
      onClick={handleComplete}
      variant="outlined"
      color="primary"
      disabled={loading}
    >
      <Typography classes={{ root: classes.buttonLabel }}>
        <FormattedMessage
          id="ES__REGULATIONS__COMPLETE"
          defaultMessage="Doplnit"
        />
      </Typography>
    </Button>
  );
}
