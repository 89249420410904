import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  FileRef,
  FilesContext,
  FormContext,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';

function callUploadFile(fileUrl: string, file: File) {
  const formData = new FormData();
  formData.append('file', file, file.name);

  return abortableFetch(`${fileUrl}`, {
    method: 'POST',
    body: formData,
  });
}

export function useFiles(url: string, maxUploadSize?: number) {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useContext(FormContext);

  const [loading, setLoading] = useState(false);

  const getFileUrl = useEventCallback((id: string) => `${url}/${id}`);

  const uploadFile = useEventCallback(async (file: File) => {
    if (maxUploadSize !== undefined) {
      if (file.size > maxUploadSize) {
        const message = intl.formatMessage({
          id: 'EAS_FILES_MSG_ERROR_UPLOAD_SIZE',
          defaultMessage: 'Byla překročena maximální povolena velikost souboru',
        });

        showSnackbar(message, SnackbarVariant.ERROR);

        throw new Error('Max file size error');
      }
    }

    try {
      setFieldValue('fileLoading', true);
      setLoading(true);
      const fileRef: FileRef = await callUploadFile(url, file).json();

      const message = intl.formatMessage({
        id: 'EAS_FILES_MSG_SUCCESS',
        defaultMessage: 'Nahrávaní souboru dokončeno',
      });
      showSnackbar(message, SnackbarVariant.SUCCESS);

      setFieldValue('fileLoading', false);
      setLoading(false);

      return fileRef;
    } catch (err) {
      setFieldValue('fileLoading', false);
      setLoading(false);

      const error = err as any;
      if (error.name !== 'AbortError') {
        let message = '';
        if (
          error.exception ===
          'class org.springframework.web.multipart.MaxUploadSizeExceededException'
        ) {
          message = intl.formatMessage({
            id: 'EAS_FILES_MSG_ERROR_UPLOAD_SIZE',
            defaultMessage:
              'Byla překročena maximální povolena velikost souboru',
          });
        } else if (
          error.exception ===
          'class cz.inqool.eas.common.exception.VirusFoundException'
        ) {
          message = intl.formatMessage({
            id: 'EAS_FILES_MSG_ERROR_VIRUS',
            defaultMessage: 'Soubor nebyl nahrán protože obsahuje virus',
          });
        } else if (
          error.exception ===
          'class cz.inqool.eas.common.exception.ExtensionNotAllowedException'
        ) {
          message = intl.formatMessage({
            id: 'EAS_FILES_MSG_ERROR_NOT_ALLOWED',
            defaultMessage:
              'Soubor nebyl nahrán protože má nepovolenou příponu ',
          });
        } else {
          message = intl.formatMessage(
            {
              id: 'EAS_FILES_MSG_ERROR',
              defaultMessage: 'Chyba nahrávaní souboru: {detail}',
            },
            { detail: error.message }
          );
        }

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
    }
  });

  const context: FilesContext = useMemo(
    () => ({
      loading,
      url,
      getFileUrl,
      uploadFile,
    }),
    [loading, url, getFileUrl, uploadFile]
  );

  return { context };
}
