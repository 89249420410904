import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DictionaryEvidence, FormTextField } from '@eas/common-web';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';

export function Fields({
  showHistoryField = true,
}: {
  showHistoryField?: boolean;
}) {
  const historyDescriptors = useCommonHistoryDescriptors();

  return (
    <>
      <FormTextField
        name="code"
        label={
          <FormattedMessage
            id="ES__ZIP_CODES__DETAIL__FIELD_LABEL__CODE"
            defaultMessage="Kód"
          />
        }
        required
      />
      <DictionaryEvidence.FieldName />
      <FormTextField
        name="city"
        label={
          <FormattedMessage
            id="ES__ZIP_CODES__DETAIL__FIELD_LABEL__CITY"
            defaultMessage="Město"
          />
        }
        disabled
      />
      <DictionaryEvidence.FieldActive />
      {showHistoryField && (
        <HistoryField
          descriptors={[
            ...historyDescriptors,
            { key: 'district', label: 'Okres' },
            { key: 'post', label: 'Pošta' },
          ]}
        />
      )}
    </>
  );
}
