import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RedirectAction } from '@composite/redirect/dialog-redirect';
import { EsFileState } from '@enums';

const OPTION_JUDGE_OFFICE = (
  <FormattedMessage
    id="REDIRECT__OPTION__JUDGE_OFFICE"
    defaultMessage="Pokračovat v evidenci: Kancelář soudce"
  />
);

const SOURCE_JUDGE_OFFICE = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_JUDGE_OFFICE,
};

const DEST_JUDGE_OFFICE = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_JUDGE_OFFICE,
};

const OPTION_REGISTER_NEW = (
  <FormattedMessage
    id="REDIRECT__OPTION__REGISTER_NEW"
    defaultMessage="Pokračovat v evidenci: Rejstřík - nové spisy"
  />
);

const SOURCE_REGISTER_NEW = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_REGISTER_NEW,
};

const DEST_REGISTER_NEW = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_REGISTER_NEW,
};

const OPTION_REGISTER = (
  <FormattedMessage
    id="REDIRECT__OPTION__REGISTER"
    defaultMessage="Pokračovat v evidenci: Rejstřík"
  />
);

const SOURCE_REGISTER = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_REGISTER,
};

const DEST_REGISTER = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_REGISTER,
};

const OPTION_CLOSED = (
  <FormattedMessage
    id="REDIRECT__OPTION__CLOSED"
    defaultMessage="Pokračovat v evidenci: Uzavřené"
  />
);

const SOURCE_CLOSED = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_CLOSED,
};

const DEST_CLOSED = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_CLOSED,
};

const OPTION_REGISTRY = (
  <FormattedMessage
    id="REDIRECT__OPTION__REGISTRY"
    defaultMessage="Pokračovat v evidenci: Spisovna"
  />
);

const SOURCE_REGISTRY = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_REGISTRY,
};

const DEST_REGISTRY = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_REGISTRY,
};

const OPTION_INDEX = (
  <FormattedMessage
    id="REDIRECT__OPTION__INDEX"
    defaultMessage="Pokračovat v evidenci: Indexace"
  />
);

const SOURCE_INDEX = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_INDEX,
};

const DEST_INDEX = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_INDEX,
};

export function getOptions(source?: EsFileState, dest?: EsFileState) {
  const options = [];

  switch (source) {
    case EsFileState.JUDGE_OFFICE:
      options.push(SOURCE_JUDGE_OFFICE);
      break;
    case EsFileState.REGISTER_NEW:
      options.push(SOURCE_REGISTER_NEW);
      break;
    case EsFileState.REGISTER:
      options.push(SOURCE_REGISTER);
      break;
    case EsFileState.CLOSED:
      options.push(SOURCE_CLOSED);
      break;
    case EsFileState.REGISTRY:
      options.push(SOURCE_REGISTRY);
      break;
    case EsFileState.INDEX:
      options.push(SOURCE_INDEX);
      break;
  }

  switch (dest) {
    case EsFileState.JUDGE_OFFICE:
      options.push(DEST_JUDGE_OFFICE);
      break;
    case EsFileState.REGISTER_NEW:
      options.push(DEST_REGISTER_NEW);
      break;
    case EsFileState.REGISTER:
      options.push(DEST_REGISTER);
      break;
    case EsFileState.CLOSED:
      options.push(DEST_CLOSED);
      break;
    case EsFileState.REGISTRY:
      options.push(DEST_REGISTRY);
      break;
    case EsFileState.INDEX:
      options.push(DEST_INDEX);
      break;
  }

  return options;
}
