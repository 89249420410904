import React, { useContext } from 'react';
import {
  DetailContext,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import {
  CancelTaskButton,
  ProcessTaskButton,
} from './dialog-actions/change-task-state-hook';

export function ActionBar() {
  const { source } = useContext(DetailContext);

  return (
    <>
      <ProcessTaskButton
        task={source?.data}
        ButtonComponent={PrimaryDetailActionbarButton}
      />
      <CancelTaskButton
        task={source?.data}
        ButtonComponent={SecondaryDetailActionbarButton}
      />
    </>
  );
}
