/**
 * Typ dokumentu
 */
export enum DocumentType {
  /**
   * Doručený
   */
  DELIVERED = 'DELIVERED',

  /**
   * Interní
   */
  INTERNAL = 'INTERNAL',

  /**
   * Odchozí
   */
  OUTGOING = 'OUTGOING',
}

/**
 * Původce
 */
export enum DocumentOriginator {
  /**
   * Odpůrce
   */
  OPPONENT = 'OPPONENT',

  /**
   * Ostatní
   */
  OTHER = 'OTHER',

  /**
   * Navrhovatel
   */
  PROPONENT = 'PROPONENT',
}

/**
 * Stav vypravení odchozího dokumentu
 */
export enum OutgoingDocumentDispatchState {
  /**
   * Neodeslán
   */
  NOT_SENT = 'NOT_SENT',

  /**
   * K odeslání
   */
  FOR_SENDING = 'FOR_SENDING',

  /**
   * K vypravení
   */
  FOR_DISPATCHING = 'FOR_DISPATCHING',

  /**
   * Vypraven
   */
  DISPATCHED = 'DISPATCHED',

  /**
   * Doručen
   */
  DELIVERED = 'DELIVERED',

  /**
   * Nedoručen
   */
  NOT_DELIVERED = 'NOT_DELIVERED',
}

/**
 * Druhy příloh
 */
export enum AttachmentType {
  /**
   * Hlavní příloha
   */
  MAIN = 'MAIN',

  /**
   * Plná moc
   */
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',

  /**
   * Napadená rozhodnutí
   */
  CHALLENGED_DECISION = 'CHALLENGED_DECISION',

  /**
   * Další přílohy
   */
  OTHER = 'OTHER',

  /**
   * Přílohy interního dokumentu
   */
  INTERNAL = 'INTERNAL',

  /**
   * Přílohy odchozího dokumentu
   */
  OUTGOING = 'OUTGOING',

  /**
   * Obálky
   */
  ENVELOPE = 'ENVELOPE',

  /**
   * Doručenky
   */
  DELIVERY_NOTE = 'DELIVERY_NOTE',

  /**
   * Lustrum
   */
  LUSTRUM = 'LUSTRUM',
}

/**
 * Typ doručeného dokumentu
 */
export enum DeliveredDocumentType {
  /**
   * E-mail
   */
  EMAIL = 'EMAIL',

  /**
   * Datová schránka
   */
  DATA_BOX = 'DATA_BOX',

  /**
   * Listová zásilka
   */
  LETTER = 'LETTER',
}

/**
 * Stav doručeného dokumentu
 */
export enum DeliveredDocumentState {
  /**
   * Zpracovaný
   */
  PROCESSED = 'PROCESSED',

  /**
   * Nezpracovaný
   */
  UNPROCESSED = 'UNPROCESSED',
}

export enum EssDocumentState {
  /**
   * Nevyřízen
   */
  UNRESOLVED = 'UNRESOLVED',

  /**
   * Vyřízen
   */
  RESOLVED = 'RESOLVED',

  /**
   * Uzavřen
   */
  CLOSED = 'CLOSED',

  /**
   * Předán do spisovny
   */
  FORWARDED_TO_REGISTRY = 'FORWARDED_TO_REGISTRY',

  /**
   * Předán do archivu
   */
  FORWARDED_TO_ARCHIVE = 'FORWARDED_TO_ARCHIVE',

  /**
   * Stornován
   */
  CANCELED = 'CANCELED',

  /**
   * Skartován
   */
  SHREDDED = 'SHREDDED',
}

/**
 * Výsledek validace
 */
export enum SignatureInfoStatus {
  /**
   * Platný
   */
  VALID = 'VALID',
}

/**
 * Electronic IDentification, Authentication and trust Services (eIDAS)
 */
export enum SignatureInfoEidasType {
  /**
   * Zaručený elektronický podpis
   */
  ADVANCED_SIGNATURE = 'ADVANCED_SIGNATURE',

  /**
   * Zaručená elektronická pečeť
   */
  ADVANCED_SEAL = 'ADVANCED_SEAL',

  /**
   * Uznávaný elektronický podpis (zaručený elektronický podpis založený na kvalifikovaném certifikátu)
   */
  ACREDITED_SIGNATURE = 'ACREDITED_SIGNATURE',

  /**
   * Uznávaná elektronická pečeť (zaručená elektronická pečeť založená na kvalifikovaném certifikátu pro elektronické pečeti)
   */
  ACREDITED_SEAL = 'ACREDITED_SEAL',

  /**
   * Zaručená elektronická pečeť, založená na certifikátu pro elektronické pečeti od kvalifikovaného poskytovatele služeb vytvářejících důvěru
   */
  TRUSTED_SEAL = 'TRUSTED_SEAL',

  /**
   * Uznávaný elektronický podpis (kvalifikovaný elektronický podpis založený na kvalifikovaném certifikátu a vytvořený na kvalifikovaném prostředku)
   */
  QUALIFIED_SIGNATURE = 'QUALIFIED_SIGNATURE',

  /**
   * Kvalifikovaná eletronická pečeť založená na kvalifikovaném certifikátu od kvalifikovaného poskytovatele služeb vytvářejících důvěru
   */
  QUALIFIED_SEAL = 'QUALIFIED_SEAL',
}

/**
 * Typ certifikátu
 */
export enum SignatureInfoCertificateType {
  /**
   * Neznámý
   */
  UNKNOWN = 'UNKNOWN',

  /**
   * Interní úložiště
   */
  INTERNAL_STORAGE = 'INTERNAL_STORAGE',

  /**
   * Komerční
   */
  COMMERCIAL = 'COMMERCIAL',

  /**
   * Kvalifikovaný
   */
  QUALIFIED = 'QUALIFIED',
}
