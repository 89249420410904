import React, { ComponentType, useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ActionButtonComponentProps,
  DetailContext,
  DetailMode,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { AssigningRulesFields } from '@modules/assigning-rule/assigning-rule-fields';
import { useValidationSchema } from '@modules/assigning-rule/assigning-rule-schema';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { AssigningRule } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useAddAssigningRulesDialog() {
  const callApi = useEventCallback((id: string, formData: any) =>
    abortableFetch(EvidenceApiUrl.ASSIGNING_RULES, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    })
  );

  return {
    callApi,
  };
}

export function AddAssigningRulesButton({
  ButtonComponent,
}: {
  ButtonComponent: ComponentType<ActionButtonComponentProps>;
}) {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const { callApi } = useAddAssigningRulesDialog();
  const { source } = useContext(DetailContext);

  return (
    <DetailActionButton
      promptKey="ADD_ASSIGNING_RULES"
      apiCall={callApi}
      buttonLabel={intl.formatMessage({
        id: 'ES__JUDGES__ACTIONBAR__ADD_ASSIGNING_RULES',
        defaultMessage: 'Přidat pravidla přidělování',
      })}
      dialogTitle={intl.formatMessage({
        id: 'ES__JUDGES__DIALOG_ADD_ASSIGNING_RULES__TITLE',
        defaultMessage: 'Přidání pravidel přidělování',
      })}
      dialogText={intl.formatMessage({
        id: 'ES__JUDGES__DIALOG_ADD_ASSIGNING_RULES__TEXT',
        defaultMessage: 'Vyplňte následující pole:',
      })}
      dialogWidth={1200}
      FormFields={AssigningRulesFields}
      ButtonComponent={(props: ActionButtonComponentProps) => (
        <ButtonComponent {...props} />
      )}
      formInitialValues={{ judge: source.data } as AssigningRule}
      formValidationSchema={validationSchema}
      modes={[DetailMode.VIEW, DetailMode.EDIT]}
    />
  );
}
