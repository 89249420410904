import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hourMarker: {
    fill: 'transparent',
    stroke: '#808080',
    strokeWidth: 9,
    strokeDasharray: '0.4, 4.6',
    strokeDashoffset: '0.2',
  },
  minuteMarker: {
    fill: 'transparent',
    stroke: '#e7e7e7',
    strokeWidth: 7,
    strokeDasharray: '0.2, 0.8',
    strokeDashoffset: '0.1',
  },
  hand: {
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    strokeLinecap: 'round',
  },
  handThick: {
    strokeWidth: 6,
  },
  handSecond: {
    stroke: '#808080',
  },
  text: {
    fill: '#808080',
    textAnchor: 'middle',
    cursor: 'pointer',
    userSelect: 'none',
  },
  center: {
    fill: '#000',
    stroke: 'white',
  },
}));

export function Clock() {
  const classes = useStyles();

  const text = useRef<SVGTextElement>(null);
  const hourHand = useRef<SVGGElement>(null);
  const minuteHand = useRef<SVGGElement>(null);
  const secondHand = useRef<SVGGElement>(null);

  const [showDate, setShowDate] = useState(false);

  function animate() {
    const date = new Date();

    const day = date.getDate();
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    if (text.current) {
      text.current.textContent = showDate ? day.toString() : ampm;
    }

    hourHand.current?.setAttribute('transform', `rotate(${(360 / 12) * hour})`);
    minuteHand.current?.setAttribute(
      'transform',
      `rotate(${(360 / 60) * minute})`
    );
    secondHand.current?.setAttribute(
      'transform',
      `rotate(${(360 / 60) * second})`
    );

    requestAnimationFrame(animate);
  }

  useEffect(() => {
    const id = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(id);
  }, [showDate]);

  const handleShowDate = useCallback(() => {
    setShowDate((show) => !show);
  }, []);

  return (
    <svg width="50%" viewBox="-100 -100 200 200">
      <circle
        className={classes.minuteMarker}
        cx={0}
        cy={0}
        r={90}
        pathLength={60}
      />
      <circle
        className={classes.hourMarker}
        cx={0}
        cy={0}
        r={91}
        pathLength={60}
      />

      <text
        ref={text}
        onClick={handleShowDate}
        className={classes.text}
        x={45}
        y={5}
      >
        00
      </text>

      <g ref={hourHand}>
        <line className={classes.hand} x1={0} y1={0} x2={0} y2={-50} />
        <line
          className={clsx(classes.hand, classes.handThick)}
          x1={0}
          y1={-12}
          x2={0}
          y2={-50}
        />
      </g>

      <g ref={minuteHand}>
        <line className={classes.hand} x1={0} y1={0} x2={0} y2={-80} />
        <line
          className={clsx(classes.hand, classes.handThick)}
          x1={0}
          y1={-12}
          x2={0}
          y2={-80}
        />
      </g>

      <g ref={secondHand}>
        <line
          className={clsx(classes.hand, classes.handSecond)}
          x1={0}
          y1={12}
          x2={0}
          y2={-80}
        />
      </g>

      <circle className={classes.center} r={3} />
    </svg>
  );
}
