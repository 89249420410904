import { useContext } from 'react';
import {
  DetailContext,
  ScheduleContext,
  abortableFetch,
} from '@eas/common-web';

export function useCancelDialog() {
  const { source } = useContext(DetailContext);

  const { jobUrl } = useContext(ScheduleContext);

  const callApi = (id: string) => {
    return abortableFetch(`${jobUrl}/${id}/cancel`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

  const showCancelButton = source.data?.running;

  return { callApi, showCancelButton };
}
