import { format, parseISO } from 'date-fns';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  LocaleContext,
  SnackbarContext,
  SnackbarVariant,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  Tooltip,
  useEventCallback,
  useFieldTooltip,
  useStaticListSource,
} from '@eas/common-web';
import { CreatedByCell } from '@components/evidence/authored-evidence/created-by-cell';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { HistoryFieldUnion, RawSimpleLog } from '@models';
import { HistoryFieldType } from '@enums';
import { SimpleLogValue } from './fields/simple-log-value';
import { HistoryContext } from './history-context';
import { useStyles } from './history-styles';

export function useTableColumns(): TableFieldColumn<RawSimpleLog>[] {
  const { historyEventTypes } = useContext(StaticDataContext);
  const useEventTypes = () => useStaticListSource(historyEventTypes);

  return [
    {
      name: 'Datum',
      datakey: 'created',
      sortkey: 'created',
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: 'Autor',
      datakey: 'createdByDelegate',
      sortkey: 'createdByDelegate.substitutedBy.name',
      width: 200,
      CellComponent: CreatedByCell,
    },
    {
      name: 'Typ události',
      datakey: 'type',
      sortkey: 'type',
      width: 250,
      CellComponent: TableFieldCells.useSelectCellFactory(useEventTypes),
    },
    {
      name: 'Popis',
      datakey: 'note',
      width: 500,
    },
  ];
}

export function useDetailColumns(): TableFieldColumn<HistoryFieldUnion>[] {
  const { descriptors } = useContext(HistoryContext);
  const classes = useStyles();

  return [
    {
      name: '',
      datakey: 'copy',
      width: 50,
      CellComponent: CopyCell,
    },
    {
      name: 'Název',
      datakey: 'key',
      width: 250,
      CellComponent: function Cell({ value }) {
        const { withTooltip, tooltipRef } = useFieldTooltip(value);
        const label =
          descriptors.find((descriptor) => descriptor.key === value)?.label ??
          value;

        const content = (
          <Typography className={classes.text} ref={tooltipRef}>
            {label}
          </Typography>
        );

        return withTooltip ? (
          <Tooltip title={label} placement="top-start" type="HOVER">
            {content}
          </Tooltip>
        ) : (
          <>{content}</>
        );
      },
    },
    {
      name: 'Předchozí hodnota',
      datakey: 'valueBefore',
      width: 450,
      CellComponent: function Cell({ value, rowValue }) {
        if (rowValue.type === HistoryFieldType.COLLECTION) {
          value = rowValue.changes
            .filter((change) => change.operation === 'REMOVE')
            .map((change) => change.label)
            .join('; ');
        }

        return (
          value && (
            <SimpleLogValue
              value={value}
              type={rowValue.type}
              position="before"
            />
          )
        );
      },
    },
    {
      name: 'Nová hodnota',
      datakey: 'valueAfter',
      width: 450,
      CellComponent: function Cell({ value, rowValue }) {
        if (rowValue.type === HistoryFieldType.COLLECTION) {
          value = rowValue.changes
            .filter((change) => change.operation === 'ADD')
            .map((change) => change.label)
            .join('; ');
        }

        return (
          <SimpleLogValue value={value} type={rowValue.type} position="after" />
        );
      },
    },
  ];
}

const getTextToCopy = (
  rowValue: HistoryFieldUnion,
  dateFormat: string,
  dateTimeFormat: string
) => {
  let text = '';

  if ('valueAfter' in rowValue && rowValue.valueAfter) {
    switch (rowValue.type) {
      case HistoryFieldType.STRING: {
        text = rowValue.valueAfter;
        break;
      }
      case HistoryFieldType.LABELED: {
        text = rowValue.valueAfter?.label;
        break;
      }
      case HistoryFieldType.BOOLEAN: {
        text = rowValue.valueAfter ? 'Ano' : 'Ne';
        break;
      }
      case HistoryFieldType.LOCAL_DATE: {
        text = format(parseISO(rowValue.valueAfter), dateFormat);
        break;
      }
      case HistoryFieldType.LOCAL_DATE_TIME: {
        text = format(parseISO(rowValue.valueAfter), dateTimeFormat);
        break;
      }
    }
  }

  return text;
};

function CopyCell({ rowValue }: TableFieldCellProps<HistoryFieldUnion>) {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const { locale } = useContext(LocaleContext);

  const handleCopy = useEventCallback(() => {
    const text = getTextToCopy(
      rowValue,
      locale.dateFormat,
      locale.dateTimeFormat
    );

    if (text) {
      navigator.clipboard.writeText(text);

      const message = intl.formatMessage({
        id: 'EAS_DETAIL_SHARE_DIALOG_MSG_SUCCESS',
        defaultMessage: 'Úspěšně jste zkopírovali novou hodnotu.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
    }
  });

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="ES__HISTORY__COPY"
          defaultMessage="Kopírovat novou hodnotu"
        />
      }
    >
      <FileCopyIcon
        fontSize="small"
        color="primary"
        style={{ cursor: 'pointer', marginRight: 10 }}
        onClick={handleCopy}
      />
    </Tooltip>
  );
}
