import { v4 as uuidv4 } from 'uuid';
import { EsFile, InternalDocument, OutgoingDocument } from '@models';
import { Agenda, DocumentType } from '@enums';

export const getInitialData = (
  esFile: EsFile,
  documentType: DocumentType.INTERNAL | DocumentType.OUTGOING
) => {
  let data: InternalDocument | OutgoingDocument = {
    id: uuidv4(),
    type: documentType,
    esFile: {
      id: esFile.id,
      number: esFile.number,
      agenda: esFile.agenda,
    },
    agenda: esFile.agenda,
  };

  if (data.type === DocumentType.INTERNAL) {
    data.createdDate = new Date().toISOString();

    if (esFile.agenda === Agenda.PLENARY_OPINION) {
      data = {
        ...data,
        summary: 'Stanovisko pléna',
        decision: true,
      };
    }
  }

  if (data.type === DocumentType.OUTGOING) {
    data = {
      ...data,
      deliveryDate: new Date().toISOString(),
    };

    if (esFile.agenda === Agenda.COURT) {
      data.decision = false;
    }
  }

  return data;
};
