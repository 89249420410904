import React, { useContext } from 'react';
import { DocumentField } from '@composite/documents/document-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';

export function DocumentFields({
  hasPermission,
}: {
  hasPermission: (element: string) => boolean;
}) {
  const {
    documentTypes,
    documentDeliveryMethods,
    documentOutgoingDispatchStates,
  } = useContext(StaticDataContext);

  return (
    <>
      <DocumentField
        documentTypes={documentTypes}
        documentDeliveryMethods={documentDeliveryMethods}
        documentDispatchStates={documentOutgoingDispatchStates}
        hasPermission={hasPermission}
      />
    </>
  );
}
