import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { OutgoingDocument } from '@models';
import { EvidenceApiUrl, OutgoingDocumentDispatchState } from '@enums';

export function useReturnSend() {
  const { source } = useContext<DetailHandle<OutgoingDocument>>(DetailContext);

  const callApi = useEventCallback(() =>
    abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${source.data?.id}/dispatch/return/all`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  const showButton =
    source.data?.id &&
    source?.data.dispatchState === OutgoingDocumentDispatchState.FOR_SENDING;

  return {
    callApi,
    showButton,
  };
}
