import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  TableCells,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';

export function useColumns() {
  const intl = useIntl();
  const { viewingMethods, viewingRequestStates } =
    useContext(StaticDataContext);

  const useViewingMethods = () => useStaticListSource(viewingMethods);
  const useViewingRequestStates = () =>
    useStaticListSource(viewingRequestStates);

  const columnCreated = {
    datakey: 'created',
    name: intl.formatMessage({
      id: 'ES__VIEWING_REQUESTS_ALL__TABLE__COLUMN__DATA_BOX_ID',
      defaultMessage: 'Datum vytvoření',
    }),
    width: 200,
    CellComponent: TableCells.DateTimeCell,
    FilterComponent: TableFilterCells.FilterDateTimeCell,
    sortable: true,
    filterable: true,
  };

  const columnEsFile = {
    datakey: 'esFile.number.value',
    sortkey: 'esFile.number.valueSort',
    filterkey: 'esFile.number.value',
    name: intl.formatMessage({
      id: 'ES__VIEWING_REQUESTS_ALL__TABLE__COLUMN__ES_FILE',
      defaultMessage: 'Spisová značka',
    }),
    width: 150,
    CellComponent: TableCells.TextCell,
    sortable: true,
    filterable: true,
  };

  const columnViewingMethod = {
    datakey: 'viewingMethod',
    filterkey: 'viewingMethod.id',
    sortkey: 'viewingMethod.name',
    name: intl.formatMessage({
      id: 'ES__VIEWING_REQUESTS_ALL__TABLE__COLUMN__VIEWING_METHOD',
      defaultMessage: 'Způsob nahlížení',
    }),
    width: 150,
    CellComponent: TableCells.TextCell,
    FilterComponent:
      TableFilterCells.useFilterSelectCellFactory(useViewingMethods),
    valueMapper: TableCells.useSelectCellFactory(useViewingMethods),
    sortable: true,
    filterable: true,
  };

  const columnRequester = {
    datakey: 'requester.label',
    name: intl.formatMessage({
      id: 'ES__VIEWING_REQUESTS_ALL__TABLE__COLUMN__REQUESTER',
      defaultMessage: 'Žadatel o nahlížení',
    }),
    width: 250,
    CellComponent: TableCells.TextCell,
    sortable: true,
    filterable: true,
  };

  const columnElaborator = {
    datakey: 'elaborator.user.label',
    sortkey: 'elaborator.name',
    filterkey: 'elaborator.name',
    name: intl.formatMessage({
      id: 'ES__VIEWING_REQUESTS_ALL__TABLE__COLUMN__ELABORATOR',
      defaultMessage: 'Zpracovatel žádosti',
    }),
    width: 250,
    CellComponent: TableCells.TextCell,
    sortable: true,
    filterable: true,
  };

  const columnState = {
    datakey: 'state',
    filterkey: 'state.id',
    sortkey: 'state.name',
    name: intl.formatMessage({
      id: 'ES__VIEWING_REQUESTS_ALL__TABLE__COLUMN__STATE',
      defaultMessage: 'Stav žádosti',
    }),
    width: 200,
    CellComponent: TableCells.TextCell,
    FilterComponent: TableFilterCells.useFilterSelectCellFactory(
      useViewingRequestStates
    ),
    valueMapper: TableCells.useSelectCellFactory(useViewingRequestStates),
    sortable: true,
    filterable: true,
  };

  return {
    columnCreated,
    columnEsFile,
    columnRequester,
    columnElaborator,
    columnState,
    columnViewingMethod,
  };
}
