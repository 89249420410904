/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Dispatch messages
 */
const DISPATCH: MessageType<'SUCCESS' | 'WRONG_DISPATCH_STATE' | 'ERROR'> = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__DISPATCH__SUCCESS"
      defaultMessage="Dokumenty byly úspěšně vypraveny."
    />,
    SnackbarVariant.SUCCESS,
  ],
  WRONG_DISPATCH_STATE: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__DISPATCH__WRONG_DISPATCH_STATE"
      defaultMessage="Vypravit lze pouze dokumenty ve stavu Odesláno."
    />,
    SnackbarVariant.ERROR,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__DISPATCH__ERROR"
      defaultMessage="Chyba při vypravení dokumentů."
    />,
    SnackbarVariant.ERROR,
  ],
};

const RETURN: MessageType<'SUCCESS' | 'WRONG_DISPATCH_STATE' | 'ERROR'> = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__RETURN__SUCCESS"
      defaultMessage="Dokumenty byly úspěšně vráceny."
    />,
    SnackbarVariant.SUCCESS,
  ],
  WRONG_DISPATCH_STATE: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__RETURN__WRONG_DISPATCH_STATE"
      defaultMessage="Vrátit lze pouze dokumenty ve stavu Odesláno."
    />,
    SnackbarVariant.ERROR,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__RETURN__ERROR"
      defaultMessage="Chyba při vracení dokumentů."
    />,
    SnackbarVariant.ERROR,
  ],
};

const CREATE: MessageType<'SUCCESS' | 'ERROR' | 'CONTACT_CREATED'> = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__CREATE__SUCCESS"
      defaultMessage="Vypravení bylo úspěšně vytvořeno."
    />,
    SnackbarVariant.SUCCESS,
  ],
  CONTACT_CREATED: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__CREATE__CONTACT_CREATED"
      defaultMessage="Vypravení bylo úspěšně vytvořeno. K vybranému subjektu byl vytvořen nový kontakt."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__CREATE__ERROR"
      defaultMessage="Chyba při vytváření vypravení."
    />,
    SnackbarVariant.ERROR,
  ],
};

const UPDATE: MessageType<'SUCCESS' | 'ERROR' | 'CONTACT_CREATED'> = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__UPDATE__SUCCESS"
      defaultMessage="Vypravení bylo úspěšně upraveno."
    />,
    SnackbarVariant.SUCCESS,
  ],
  CONTACT_CREATED: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__UPDATE__CONTACT_CREATED"
      defaultMessage="Vypravení bylo úspěšně upraveno. K vybranému subjektu byl vytvořen nový kontakt."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__DISPATCH__UPDATE__ERROR"
      defaultMessage="Chyba při vytváření vypravení."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default { DISPATCH, RETURN, CREATE, UPDATE };
