import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { EvidenceContext, FormPanel, RemoteTableField } from '@eas/common-web';
import { SAME_PROPOSER_FILE_SOURCE } from '@modules/es-file/es-file-hooked-sources';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { useSimilarFileColumns } from './similar-file-columns';

export function SimilarProposerFileField() {
  const columns = useSimilarFileColumns();

  const { hookedSources } = useContext(EvidenceContext);
  const source = hookedSources[SAME_PROPOSER_FILE_SOURCE].source;

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__SIMILAR_PROPOSER_FILES__PANEL_TITLE"
          defaultMessage="Obdobné spisy dle navrhovatele / pisatele"
        />
      }
      expandable={true}
      defaultExpanded={true}
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        columns={columns}
        source={source}
        maxRows={7}
        showToolbar={false}
        highlightSelectedRow={true}
        showDetailBtnCond={stubFalse}
        showRadioCond={stubFalse}
      />
    </FormPanel>
  );
}
