import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonComponentProps,
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  FieldFilter,
  Filter,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarButtons,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { AssigningRulesFields } from '@modules/assigning-rule/assigning-rule-fields';
import { useValidationSchema } from '@modules/assigning-rule/assigning-rule-schema';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { AssigningRule, Judge } from '@models';
import { AddAssigningRulesButton } from './dialog-actions/add-assigning-rules-hook';
import { useEditAssigningRulesDialog } from './dialog-actions/edit-assigning-rules-hook';

type AssigningRuleFieldToolbarProps = TableFieldToolbarProps & {
  preFilters: Filter[];
};

export function AssigningRuleToolbar({
  selectedIndex,
  setSelectedIndex,
  preFilters,
}: AssigningRuleFieldToolbarProps) {
  const classes = useStyles();
  const intl = useIntl();

  const { source } = useContext<DetailHandle<Judge>>(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);

  const { callApi: callApiEditAssigningRules } = useEditAssigningRulesDialog();
  const validationSchema = useValidationSchema();

  useEffect(() => {
    setSelectedIndex(undefined);
  }, [source]);

  const selectedRule =
    selectedIndex !== undefined ? tableSource.items?.[selectedIndex] : {};

  const isEditationDisabled =
    selectedIndex !== undefined
      ? !(
          selectedRule?.valid ||
          new Date(selectedRule?.validFrom as string) > new Date()
        )
      : true;

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <AddAssigningRulesButton
          ButtonComponent={({ label, onClick }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={false}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__JUDGES__ACTIONBAR__ADD_ASSIGNING_RULES',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
        />
        <DetailActionButton
          promptKey="EDIT_ASSIGNING_RULES"
          apiCall={callApiEditAssigningRules}
          buttonLabel={intl.formatMessage({
            id: 'ES__JUDGES__ACTIONBAR__EDIT_ASSIGNING_RULES',
            defaultMessage: 'Editovat pravidla přidělování',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__JUDGES__DIALOG_EDIT_ASSIGNING_RULES__TITLE',
            defaultMessage: 'Editace pravidel přidělování',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__JUDGES__DIALOG_EDIT_ASSIGNING_RULES__TEXT',
            defaultMessage: 'Skutečně chcete nastavit tuto hodnotu?',
          })}
          formInitialValues={
            (selectedIndex !== undefined ? selectedRule : {}) as AssigningRule
          }
          dialogWidth={1200}
          FormFields={AssigningRulesFields}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={isEditationDisabled}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__JUDGES__ACTIONBAR__EDIT_ASSIGNING_RULES',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formValidationSchema={validationSchema}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={tableSource}
          preFilters={preFilters}
          filtersFields={[
            {
              label: 'Platnost od',
              datakey: 'validFrom',
              filterkey: 'validFrom',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterLocalDateTimeCell,
            },
            {
              label: 'Platnost do',
              datakey: 'validTo',
              filterkey: 'validTo',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterLocalDateTimeCell,
            },
            {
              label: 'Pozastavit senátní',
              datakey: 'stopSenate',
              filterkey: 'stopSenate',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterBooleanCell,
            },
            {
              label: 'Pozastavit plenární',
              datakey: 'stopPlenary',
              filterkey: 'stopPlenary',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterBooleanCell,
            },
          ]}
        />
      </ButtonGroup>
    </div>
  );
}
