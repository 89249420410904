import React from 'react';
import Grid from '@material-ui/core/Grid';
import { SubmissionCountData, SubmissionCountDataInput } from '@models';
import { Agenda, EvidenceApiUrl, IntervalType } from '@enums';
import { StatisticCard } from '../card/card';
import { useStatistic } from '../statistics-hook';
import { Header } from './submission-counts-header';
import { useSubmissionCountsVisualisation } from './submission-counts-hook';

export function SubmissionCounts() {
  const url = EvidenceApiUrl.STATISTICS_SUBMISSION_COUNT;

  const { data, params, setParams } = useStatistic<
    SubmissionCountDataInput,
    SubmissionCountData
  >({
    url,
    initialParams: {
      intervalType: IntervalType.ALL,
      agenda: Agenda.COURT,
    },
  });

  const { Visualisations } = useSubmissionCountsVisualisation({
    data: data?.submissionCounts,
    agenda: params.agenda,
  });

  return (
    <Grid item xs={6}>
      <StatisticCard
        title="Počty podání"
        url={url}
        params={params}
        Header={() => <Header params={params} setParams={setParams} />}
        Visualisations={Visualisations}
      />
    </Grid>
  );
}
