import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { LegalAdviser } from '@models';
import { EvidenceApiUrl, ParticipantType } from '@enums';

export function useAddLegalAdviserDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback((id: string, formData: LegalAdviser) => {
    return abortableFetch(`${EvidenceApiUrl.ES_FILES}/${id}/participant`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        ...formData,
        type: ParticipantType.LEGAL_ADVISER,
        esFile: source.data,
      }),
    });
  });

  return {
    callApi,
  };
}
