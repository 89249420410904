import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { ViewingRequestState } from '@enums';
import { useColumns } from '../viewing-request-columns';
import { useViewingRequests } from '../viewing-requests';

export function ViewingRequestsResolved() {
  const intl = useIntl();

  const {
    columnCreated,
    columnEsFile,
    columnViewingMethod,
    columnRequester,
    columnElaborator,
    columnState,
  } = useColumns();

  const { evidenceBag } = useViewingRequests({
    evidenceProps: {
      tableProps: {
        columns: [
          columnCreated,
          columnEsFile,
          columnViewingMethod,
          columnRequester,
          columnElaborator,
          columnState,
        ],
        defaultPreFilters: [
          {
            field: 'state.id',
            operation: ApiFilterOperation.EQ,
            value: ViewingRequestState.RESOLVED,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__VIEWING_REQUESTS__RESOLVED__TABLE__TITLE',
          defaultMessage: 'Vyřízené žádosti o nahlížení',
        }),
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
