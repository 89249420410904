import { format } from 'date-fns';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormAutocomplete,
  FormCheckbox,
  FormDateTimeField,
  FormPanel,
  TextField,
  TextFieldProps,
  formFieldFactory,
} from '@eas/common-web';
import { HistoryField } from '@composite/history/history-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { useJudges } from '../judge/judge-api';
import { useHistoryDescriptors } from './assigning-rule-history-descriptors';

const useStyles = makeStyles({
  smallTextField: {
    display: 'flex',
    width: '100%',
  },
  smallTextInput: { lineHeight: '22px', fontSize: 12 },
});

export function Fields() {
  const judges = useJudges();
  const historyDescriptors = useHistoryDescriptors();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__ASSIGNING_RULES__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormAutocomplete
          name="judge"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__JUDGE"
              defaultMessage="Soudce"
            />
          }
          source={judges}
          labelMapper={autocompleteLabelMapper}
        />
      </FormPanel>
      <AssigningRulesFields />
      <HistoryField descriptors={historyDescriptors} />
    </>
  );
}

const MiniTextField = ({
  help,
  ...props
}: TextFieldProps & { help: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <div className={classes.smallTextField}>
      <TextField
        {...props}
        style={{ width: 100, flexShrink: 0, marginRight: 15 }}
      />
      <Typography variant="body1" className={classes.smallTextInput}>
        {help}
      </Typography>
    </div>
  );
};

const FormMiniTextField = formFieldFactory(MiniTextField);

export function AssigningRulesFields() {
  const validateNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/[^0-9]/gi, '');
  };

  const currentDate = format(new Date(), 'yyyy-MM-dd');

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__ASSIGNING_RULES__DETAIL__PANEL_TITLE__SENATE"
            defaultMessage="Senátní"
          />
        }
        expandable={false}
      >
        <FormCheckbox
          name="stopSenate"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__STOP_SENATE"
              defaultMessage="Pozastavit"
            />
          }
        />
        <FormMiniTextField
          name="assignXSenate"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__ASSIGN_X_SENATE"
              defaultMessage="Přidělit x"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__STOP_SENATE"
              defaultMessage="Přidělení x senátních věcí a pozastavení procesu přidělování"
            />
          }
          onChangeEvent={validateNumber}
          inputProps={{ maxLength: 5 }}
        />
        <FormMiniTextField
          name="skipEveryXSenate"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_EVERY_X_SENATE"
              defaultMessage="Vynechat každé x - té"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_EVERY_X_SENATE"
              defaultMessage="Vynechání každého xtého kola přidělování senátních věcí"
            />
          }
          inputProps={{ maxLength: 5 }}
          onChangeEvent={validateNumber}
        />
        <FormMiniTextField
          name="skipXSenate"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_X_SENATE"
              defaultMessage="Přeskočit x"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_X_SENATE"
              defaultMessage="Kolik následujících kol přidělování senátních věcí má být přeskočeno. Po přeskočení se sníží o 1"
            />
          }
          inputProps={{ maxLength: 5 }}
          onChangeEvent={validateNumber}
        />
        <FormMiniTextField
          name="assignXSenateCounter"
          type="number"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__ASSIGN_X_SENATE_COUNTER"
              defaultMessage="Přiděleno celkově"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__ASSIGN_X_SENATE_COUNTER"
              defaultMessage="Počítadlo v kolika kolech již byla přidělena senátní věc (po přidělení se zvýší o 1)"
            />
          }
          disabled
        />
        <FormMiniTextField
          name="skipEveryXSenateCounter"
          type="number"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_EVERY_X_SENATE_COUNTER"
              defaultMessage="Přiděleno v sekvenci"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_EVERY_X_SENATE_COUNTER"
              defaultMessage="Počítadlo v kolika kolech již byla přidělena senátní věc (po přidělení se zvýší o 1, při přeskočení se nastaví na 0)"
            />
          }
          disabled
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ES__ASSIGNING_RULES__DETAIL__PANEL_TITLE__PLENARY"
            defaultMessage="Plenární"
          />
        }
        expandable={false}
      >
        <FormCheckbox
          name="stopPlenary"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__STOP_PLENARY"
              defaultMessage="Pozastavit"
            />
          }
        />

        <FormMiniTextField
          name="assignXPlenary"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__ASSIGN_X_PLENARY"
              defaultMessage="Přidělit x"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__ASSIGN_X_PLENARY"
              defaultMessage="Přidělení x plenárních věcí a pozastavení procesu přidělování"
            />
          }
          inputProps={{ max: 5 }}
          onChangeEvent={validateNumber}
        />
        <FormMiniTextField
          name="skipEveryXPlenary"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_EVERY_X_PLENARY"
              defaultMessage="Vynechat každé x - té"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_EVERY_X_PLENARY"
              defaultMessage="Vynechání každého xtého kola přidělování plenárních věcí"
            />
          }
          inputProps={{ maxLength: 5 }}
          onChangeEvent={validateNumber}
        />
        <FormMiniTextField
          name="skipXPlenary"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_X_PLENARY"
              defaultMessage="Přeskočit x"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_X_PLENARY"
              defaultMessage="Kolik následujících kol přidělování plernárních věcí má být přeskočeno. Po přeskočení se sníží o 1"
            />
          }
          inputProps={{ maxLength: 5 }}
          onChangeEvent={validateNumber}
        />
        <FormMiniTextField
          name="assignXPlenaryCounter"
          type="number"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__ASSIGN_X_PLENARY_COUNTER"
              defaultMessage="Přiděleno celkově"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__ASSIGN_X_PLENARY_COUNTER"
              defaultMessage="Počítadlo v kolika kolech již byla přidělena plenární věc (po přidělení se zvýší o 1)"
            />
          }
          disabled
        />
        <FormMiniTextField
          name="skipEveryXPlenaryCounter"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_EVERY_X_PLENARY_COUNTER"
              defaultMessage="Přiděleno v sekvenci"
            />
          }
          help={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_EVERY_X_PLENARY_COUNTER"
              defaultMessage="Počítadlo v kolika kolech již byla přidělena plenární věc (po přidělení se zvýší o 1, při přeskočení se nastaví na 0)"
            />
          }
          disabled
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ES__ASSIGNING_RULES__DETAIL__PANEL_TITLE__VALID"
            defaultMessage="Platnost"
          />
        }
        expandable={false}
      >
        <FormDateTimeField
          name="validFrom"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__VALID_FROM"
              defaultMessage="Platný od"
            />
          }
          required
          representation="local-date-time"
          minDatePicker={currentDate}
        />
        <FormDateTimeField
          name="validTo"
          label={
            <FormattedMessage
              id="ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__VALID_TO"
              defaultMessage="Platný do"
            />
          }
          representation="local-date-time"
        />
      </FormPanel>
    </>
  );
}
