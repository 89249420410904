import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { MakeDefaultButton } from '@components/evidence/dictionary-evidence/dialog-actions/make-default-hook';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictSettlementMethod } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { useColumns } from './dict-settlement-method-columns';
import { Fields } from './dict-settlement-method-fields';

export function DictSettlementMethods() {
  const intl = useIntl();

  const evidence = useDictionaryEvidence<DictSettlementMethod>({
    version: 5,
    identifier: 'SETTLEMENT_METHODS',
    apiProps: {
      url: EvidenceApiUrl.DICT_SETTLEMENT_METHODS,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.SETTLEMENT_METHOD,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_SETTLEMENT_METHODS__TABLE__TITLE',
        defaultMessage: 'Způsoby vyřízení',
      }),
      reportTag: ExportTags.DICT_SETTLEMENT_METHODS,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.SETTLEMENT_METHOD,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_SETTLEMENT_METHODS__DETAIL__TITLE',
          defaultMessage: 'Způsob vyřízení',
        }),
        ActionBar: DictionaryEvidence.dictionaryActionbarFactory({
          After: function AfterButton() {
            return <MakeDefaultButton />;
          },
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
