enum Element {
  /**
   * AKCE: Přidat vypravení
   */
  ADD_DISPATCH = 'ADD_DISPATCH',

  /**
   * AKCE: Editovat vypravení
   */
  EDIT_DISPATCH = 'EDIT_DISPATCH',

  /**
   * AKCE: Smazat vypravení
   */
  DELETE_DISPATCH = 'DELETE_DISPATCH',

  /**
   * AKCE: Generovat obálky
   */
  GENERATE_ENVELOPES = 'GENERATE_ENVELOPES',
}

export default Element;
