import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictProposerDistinction } from '@models';
import { EntityName, EvidenceApiUrl, HistoryEntityType } from '@enums';
import { Fields } from './dict-proposer-distinction-fields';

export function DictProposerDistinctions() {
  const intl = useIntl();
  const { columnName, columnCode, columnOrder, columnActive } =
    DictionaryEvidence.useDictionaryColumns<DictProposerDistinction>();

  const evidence = useDictionaryEvidence<DictProposerDistinction>({
    version: 3,
    identifier: 'PROPOSER_DISTINCTIONS',
    apiProps: {
      url: EvidenceApiUrl.DICT_PROPOSER_DISTINCTION,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.PROPOSER_DISTINCTION,
      }),
    },
    tableProps: {
      columns: [columnName, columnCode, columnOrder, columnActive],
      tableName: intl.formatMessage({
        id: 'ES__DICT_PROPOSER_DISTINCTIONS__TABLE__TITLE',
        defaultMessage: 'Rozlišení navrhovatelů',
      }),
      showReportButton: false,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.PROPOSER_DISTINCITON,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_PROPOSER_DISTINCTIONS__DETAIL__TITLE',
          defaultMessage: 'Rozlišení navrhovatele',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
