enum Element {
  /**
   * Číslo jednací
   */
  REFERENCE_NUMBER = 'REFERENCE_NUMBER',

  /**
   * Datum vytvoření
   */
  CREATED_DATE = 'CREATED_DATE',

  /**
   * Datum doručení
   */
  DELIVERY_DATE = 'DELIVERY_DATE',

  /**
   * Došlé číslo
   */
  INCOMING_NUMBER = 'INCOMING_NUMBER',

  /**
   * Stav
   */
  PROCESSED = 'PROCESSED',

  /**
   * Dokument předchází
   */
  PRECENDING = 'PRECENDING',

  /**
   * Agenda
   */
  AGENDA = 'AGENDA',

  /**
   * Stručný obsah
   */
  SUMMARY = 'SUMMARY',

  /**
   * Způsob doručení
   */
  DELIVERY_METHOD = 'DELIVERY_METHOD',

  /**
   * Poznámka
   */
  NOTE = 'NOTE',

  /**
   * Původce
   */
  ORIGINATOR = 'ORIGINATOR',

  /**
   * Stížnost
   */
  COMPLAINT = 'COMPLAINT',

  /**
   * Počet listů
   */
  PAGE_COUNT = 'PAGE_COUNT',

  /**
   * Počet příloh
   */
  ATTACHMENT_COUNT = 'ATTACHMENT_COUNT',

  /**
   * Druh příloh
   */
  ATTACHMENTS_LABEL_RANGE = 'ATTACHMENTS_LABEL_RANGE',

  /**
   * Pořadí
   */
  ORDER = 'ORDER',

  /**
   * Čárový kód
   */
  BAR_CODE = 'BAR_CODE',

  /**
   * Lhůty
   */
  DEADLINES = 'DEADLINES',

  /**
   * AKCE: Nový soudní spis
   */
  NEW_COURT_FILE = 'NEW_COURT_FILE',

  /**
   * AKCE: Nové SPR
   */
  NEW_SPR = 'NEW_SPR',

  /**
   * AKCE: Nové INF
   */
  NEW_INF = 'NEW_INF',

  /**
   * AKCE: Do spisu
   */
  TO_ES_FILE = 'TO_ES_FILE',

  /**
   * AKCE: Kopie
   */
  COPY = 'COPY',

  /**
   * AKCE: Vrátit
   */
  RETURN = 'RETURN',

  /**
   * AKCE: Lustrovat
   */
  SCREENING_FOR_DOCUMENT = 'SCREENING_FOR_DOCUMENT',

  /**
   * AKCE: Upravit dokument
   */
  EDIT_DOCUMENT_BUTTON = 'EDIT_DOCUMENT_BUTTON',

  /**
   * AKCE: Znovu otevřít
   */
  REOPEN_DELIVERED_DOCUMENT_BUTTON = 'REOPEN_DELIVERED_DOCUMENT_BUTTON',

  /**
   * AKCE: Vyjmout ze spisu
   */
  REMOVE_BUTTON = 'REMOVE_BUTTON',
}

export default Element;
