import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  DictionaryEvidence,
  EvidenceContext,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { UserRoleField } from '@composite/user-roles/user-role-field';
import { User } from '@models';
import { USER_ROLE_SOURCE } from './user-hooked-sources';

export function Fields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode } = useContext(DetailContext);

  const { active } = useFormSelector((data: User) => ({
    active: data.active,
  }));

  const disabledUserRoles = isLocked || mode !== DetailMode.VIEW || !active;
  const userRoleSource = hookedSources[USER_ROLE_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DICT_USERS__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormTextField
          name="username"
          label={
            <FormattedMessage
              id="ES__DICT_USERS__DETAIL__FIELD_LABEL__USERNAME"
              defaultMessage="Uživatelské jméno"
            />
          }
          disabled
        />
        <DictionaryEvidence.FieldActive />
        <FormTextField
          name="titlesBefore"
          label={
            <FormattedMessage
              id="ES__DICT_USERS__DETAIL__FIELD_LABEL__DEGREE_BEFORE"
              defaultMessage="Tituly před"
            />
          }
        />
        <FormTextField
          name="firstName"
          label={
            <FormattedMessage
              id="ES__DICT_USERS__DETAIL__FIELD_LABEL__FIRST_NAME"
              defaultMessage="Jméno"
            />
          }
          disabled
        />
        <FormTextField
          name="lastName"
          label={
            <FormattedMessage
              id="ES__DICT_USERS__DETAIL__FIELD_LABEL__LAST_NAME"
              defaultMessage="Příjmení"
            />
          }
          disabled
        />
        <FormTextField
          name="titlesAfter"
          label={
            <FormattedMessage
              id="ES__DICT_USERS__DETAIL__FIELD_LABEL__DEGREE_AFTER"
              defaultMessage="Tituly za"
            />
          }
        />
        <FormTextField
          name="email"
          label={
            <FormattedMessage
              id="ES__DICT_USERS__DETAIL__FIELD_LABEL__EMAIL"
              defaultMessage="Email"
            />
          }
          disabled
        />
        <FormTextField
          name="phone"
          label={
            <FormattedMessage
              id="ES__DICT_USERS__DETAIL__FIELD_LABEL__PHONE"
              defaultMessage="Telefon"
            />
          }
          disabled
        />
      </FormPanel>

      <UserRoleField disabled={disabledUserRoles} source={userRoleSource} />
    </>
  );
}
