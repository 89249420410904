import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  FormAutocomplete,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { useBarcodePrinters } from '@modules/dict-barcode-printer/dict-barcode-printer-api';
import { useUsers } from '@modules/user/user-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DictionaryObject } from '@models';
import { Role } from '@enums';

export function useUserRoleDialog({
  agendas,
}: {
  agendas: DictionaryObject[];
}) {
  const { source } = useContext(DetailContext);

  return function UserDialog() {
    const agendaSource = useStaticListSource(agendas);

    const users = useUsers();
    const barcodePrinters = useBarcodePrinters();

    const showBarcodePrinter = [Role.LUSTRUM, Role.MAILROOM].includes(
      source.data?.id as Role
    );
    const showAgenda = [Role.INDEX, Role.SOLVER].includes(
      source.data?.id as Role
    );

    return (
      <>
        <FormAutocomplete
          name="user"
          label="Uživatel"
          source={users}
          labelMapper={autocompleteLabelMapper}
        />
        {showBarcodePrinter && (
          <FormAutocomplete
            name="barcodePrinter"
            label={
              <FormattedMessage
                id="ES__ROLE__ASSIGN_USERS_HOOK__FIELD_LABEL__BARCODEPRINTER"
                defaultMessage="Tiskárna čárových kódů"
              />
            }
            labelMapper={autocompleteLabelMapper}
            source={barcodePrinters}
          />
        )}
        {showAgenda && (
          <FormSelect
            name="agendas"
            label={
              <FormattedMessage
                id="ES__ROLE__ASSIGN_USERS_HOOK__FIELD_LABEL__AGENDA"
                defaultMessage="Agenda"
              />
            }
            source={agendaSource}
            multiple={true}
            valueIsId={true}
          />
        )}
      </>
    );
  };
}
