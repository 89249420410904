import * as Yup from 'yup';
import { DictRegulationOrder } from '@models';

export function useValidationSchema() {
  return Yup.object<DictRegulationOrder>().shape({
    name: Yup.string().nullable().required(),
    number: Yup.number().nullable().required(),
    year: Yup.number().nullable().required(),
  });
}
