import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  FilesContext,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { CreatedByCell } from '@components/evidence/authored-evidence/created-by-cell';
import { Draft } from '@models';

const useStyles = makeStyles(() => ({
  downloadButton: {
    minWidth: '0',
    marginLeft: '2pt',
    boxShadow: '0 0 0',
    padding: '2pt',
    height: 23,
    '& p': {
      // contradict the default 'none'
      display: 'block',
    },
  },
}));

export function useDraftColumns(): TableFieldColumn<Draft>[] {
  const intl = useIntl();
  const classes = useStyles();

  const { getFileUrl } = useContext(FilesContext);

  const columns = useMemo(
    () => [
      {
        datakey: 'download',
        name: '',
        width: 42,
        CellComponent: function Cell(props: TableFieldCellProps<Draft>) {
          return (
            <Button
              download={true}
              size="small"
              classes={{ root: classes.downloadButton }}
              variant="text"
              onClick={(e) => e.stopPropagation()}
              disabled={!props.rowValue?.file?.id}
              href={getFileUrl(props.rowValue?.file?.id)}
            >
              <GetAppIcon />
            </Button>
          );
        },
      },
      {
        datakey: 'created',
        name: intl.formatMessage({
          id: 'ES__DRAFTS__COLUMN__CREATED',
          defaultMessage: 'Datum vzniku',
        }),
        width: 250,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        datakey: 'updated',
        name: intl.formatMessage({
          id: 'ES__DRAFTS__COLUMN__UPDATED',
          defaultMessage: 'Datum editace',
        }),
        width: 250,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        datakey: 'name',
        name: intl.formatMessage({
          id: 'ES__DRAFTS__COLUMN__NAME',
          defaultMessage: 'Název dokumentu',
        }),
        width: 250,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        datakey: 'createdBy.name',
        name: intl.formatMessage({
          id: 'ES__DRAFTS__COLUMN__CREATED_BY',
          defaultMessage: 'Tvůrce',
        }),
        width: 250,
        CellComponent: CreatedByCell,
      },
      {
        datakey: 'file.name',
        name: intl.formatMessage({
          id: 'ES__DRAFTS__COLUMN__FILE',
          defaultMessage: 'Soubor',
        }),
        width: 250,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        datakey: 'note',
        name: intl.formatMessage({
          id: 'ES__DRAFTS__COLUMN__NOTE',
          defaultMessage: 'Poznámka',
        }),
        width: 250,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        datakey: 'finalVersion',
        name: intl.formatMessage({
          id: 'ES__DRAFTS__COLUMN__FINAL_VERSION',
          defaultMessage: 'Konečná verze',
        }),
        width: 250,
        CellComponent: TableFieldCells.BooleanCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  return columns;
}
