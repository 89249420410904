import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  NavigationContext,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useDeliveredDocumentPermission } from '@modules/document/document-permission';
import { useAddTaskDialog } from '@composite/tasks/dialog-actions/add-task-hook';
import { DeliveredDocument, Task } from '@models';
import { EvidenceBrowserUrl, TaskState, TaskType, UIElement } from '@enums';
import { ActionBar as DeliveredDocumentActionbar } from '../document-delivered/delivered-document-actionbar';

export function ActionBar() {
  const { navigate } = useContext(NavigationContext);
  const { mode, source } =
    useContext<DetailHandle<DeliveredDocument>>(DetailContext);

  const intl = useIntl();

  const { AddTaskButton, disabledBtn: disabledAddTaskBtn } = useAddTaskDialog();

  const { hasPermission } = useDeliveredDocumentPermission(source.data);

  return (
    <>
      <DeliveredDocumentActionbar />
      <AddTaskButton
        buttonLabel={intl.formatMessage({
          id: 'ES__DELIVERED_DOCUMENTS__ACTIONBAR__ADD_TASK',
          defaultMessage: 'Nová úloha',
        })}
        ButtonComponent={PrimaryDetailActionbarButton}
        formInitialValues={
          {
            esFile: source.data?.esFile,
            type: TaskType.CUSTOM,
            state: TaskState.SOLVING,
            document: source.data,
          } as Task
        }
        disabled={disabledAddTaskBtn}
      />
      {mode === DetailMode.VIEW && source.data?.id && (
        <PrimaryDetailActionbarButton
          label={intl.formatMessage({
            id: 'ES__DOCUMENTS_DELIVERED__ACTIONBAR__SCREENING',
            defaultMessage: 'Lustrovat',
          })}
          onClick={() =>
            navigate(EvidenceBrowserUrl.SCREENING, true, {
              action: 'SCREENING_FOR_DOCUMENT',
              data: source.data,
            })
          }
          disabled={
            !hasPermission(UIElement.DeliveredDocument.SCREENING_FOR_DOCUMENT)
          }
        />
      )}
    </>
  );
}
