import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  TableCells,
  TableColumn,
  useStaticListSource,
} from '@eas/common-web';
import { useActTypes } from '@modules/dict-act-type/dict-act-type-api';
import { useConcernedAuthorityTypes } from '@modules/dict-concerned-authority-type/dict-concerned-authority-type-api';
import { useOpponents } from '@modules/dict-opponent/dict-opponent-api';
import { useProceedingsSubjects } from '@modules/dict-proceedings-subject/dict-proceedings-subject-api';
import { useProcessingTypes } from '@modules/dict-processing-type/dict-processing-type-api';
import { useProposerDistinctions } from '@modules/dict-proposer-distinction/dict-proposer-distinction-api';
import { useProposerTypes } from '@modules/dict-proposer-type/dict-proposer-type-api';
import { useSettlementMethods } from '@modules/dict-settlement-method/dict-settlement-method-api';
import { useSubjectIndexes } from '@modules/dict-subject-index/dict-subject-index-api';
import { useJudges } from '@modules/judge/judge-api';
import { FilterAutocompleteCell } from '@composite/filter/filter-cells/filter-autocomplete-cell';
import { FilterCheckboxCell } from '@composite/filter/filter-cells/filter-checkbox-cell';
import { FilterDateCell } from '@composite/filter/filter-cells/filter-date-cell';
import { FilterSelectCell } from '@composite/filter/filter-cells/filter-select-cell';
import { FilterTextCell } from '@composite/filter/filter-cells/filter-text-cell';
import { NullableAutocompleteFilterCell } from '@composite/filter/filter-cells/nullable-filter-autocomplete-cell';
import { NullableFilterTextCell } from '@composite/filter/filter-cells/nullable-filter-text-cell';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Decision } from '@models';

export function useColumns(): TableColumn<Decision>[] {
  const intl = useIntl();
  const { decisionStates, decisionForms, anonymizationRequests } =
    useContext(StaticDataContext);

  const useAnonymizationRequests = () =>
    useStaticListSource(anonymizationRequests);
  const useForms = () => useStaticListSource(decisionForms);
  const useStates = () => useStaticListSource(decisionStates);

  return [
    {
      datakey: 'parallelCitation.sbValue',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PARALLEL_CITATION_SB_VALUE',
        defaultMessage: 'Paralelní citace (Sb.)',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 1,
    },
    {
      datakey: 'parallelCitation.sbnuValue',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PARALLEL_CITATION_SBNU_VALUE',
        defaultMessage: 'Paralelní citace (SbNU)',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 2,
    },
    {
      datakey: 'excludedFromNalus',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__EXCLUDED_FROM_NALUS',
        defaultMessage: 'Vyloučeno z NALUSu',
      }),
      width: 0,
      CellComponent: TableCells.BooleanCell,
      FilterComponent: FilterCheckboxCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 3,
    },
    {
      datakey: 'esFile.anonymize',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ANONYMIZE',
        defaultMessage: 'Anonymizovat',
      }),
      width: 0,
      CellComponent: TableCells.BooleanCell,
      FilterComponent: FilterCheckboxCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 4,
    },
    {
      datakey: 'esFile.anonymizationRequest.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ANONYMIZATION_REQUEST',
        defaultMessage: 'Žádost o anonymizaci',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useAnonymizationRequests),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 5,
    },
    {
      datakey: 'state.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useStates),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 6,
    },
    {
      datakey: 'updated.from',
      filterkey: 'updated',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__UPDATED_FROM',
        defaultMessage: 'Datum změny od',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.GTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 7,
    },
    {
      datakey: 'updated.to',
      filterkey: 'updated',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__UPDATED_TO',
        defaultMessage: 'Datum změny do',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.LTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 8,
    },
    {
      datakey: 'popularName',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__POPULAR_NAME',
        defaultMessage: 'Populární název',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 9,
    },
    {
      datakey: 'form.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__FORM',
        defaultMessage: 'Forma rozhodnutí',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useForms),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 10,
    },
    {
      datakey: 'decisionDate.from',
      filterkey: 'decisionDate',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__DECISION_DATE_FROM',
        defaultMessage: 'Datum rozhodnutí od',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.GTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 11,
    },
    {
      datakey: 'decisionDate.to',
      filterkey: 'decisionDate',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__DECISION_DATE_FROM',
        defaultMessage: 'Datum rozhodnutí do',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.LTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 12,
    },
    {
      datakey: 'settlementMethods.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__SETTLEMENT_METHODS',
        defaultMessage: 'Způsob vyřízení',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableAutocompleteFilterCell(
        useSettlementMethods,
        autocompleteLabelMapper,
        true
      ),
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 13,
    },
    {
      datakey: 'number',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__NUMBER',
        defaultMessage: 'Číslo rozhodnutí',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 14,
    },
    {
      datakey: 'text.introduction',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__INTRODUCTION',
        defaultMessage: 'Návětí',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 14,
    },
    {
      datakey: 'text.statement',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__STATEMENT',
        defaultMessage: 'Výrok',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 15,
    },
    {
      datakey: 'text.narration',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__NARRATION',
        defaultMessage: 'Narace',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 16,
    },
    {
      datakey: 'text.argumentation',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ARGUMENTATION',
        defaultMessage: 'Argumentace',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 17,
    },
    {
      datakey: 'text.legalSentence',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__LEGAL_SENTENCE',
        defaultMessage: 'Právní věta',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 18,
    },
    {
      datakey: 'text.abstractText',
      filterkey: 'text.abstract',
      sortkey: 'text.abstract',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ABSTRACT',
        defaultMessage: 'Abstrakt',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 19,
    },
    {
      datakey: 'text.differentOpinion',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__DIFFERENT_OPINION',
        defaultMessage: 'Odlišné stanovisko',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableFilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 20,
    },
    {
      datakey: 'esFile.decidingAuthorityMembers.concatenated',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__DECIDING_AUTHORITY_MEMBERS',
        defaultMessage: 'Rozhodující orgán',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 21,
    },

    {
      datakey: 'esFile.proposers.contact.subject.name',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PROPOSERS_SUBJECT',
        defaultMessage: 'Navrhovatel',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 22,
    },
    {
      datakey: 'esFile.proposers.proposerType.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PROPOSER_TYPE',
        defaultMessage: 'Typ navrhovatele',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useProposerTypes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 23,
    },
    {
      datakey: 'esFile.proposers.proposerDistinction.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PROPOSER_DISTINCTION',
        defaultMessage: 'Rozlišení navrhovatele',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useProposerDistinctions,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 24,
    },
    {
      datakey: 'esFile.challengedActs.actType.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ACT_TYPE',
        defaultMessage: 'Napadený akt - Druh aktu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useActTypes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 25,
    },
    {
      datakey: 'esFile.challengedActs.concernedAuthorityType.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__CONCERNED_AUTHORITY_TYPE',
        defaultMessage: 'Napadený akt - Typ dotčeného orgánu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useConcernedAuthorityTypes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 26,
    },
    {
      datakey: 'esFile.challengedActs.concernedAuthority.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__CONCERNED_AUTHORITY',
        defaultMessage: 'Napadený akt - Název dotčeného orgánu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useOpponents,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 27,
    },
    {
      datakey: 'esFile.challengedActs.externalFileNumber',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__EXTERNAL_FILE_NUMBER',
        defaultMessage: 'Napadený akt - Spisová značka',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 28,
    },
    {
      datakey: 'esFile.challengedActs.actName',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ACT_NAME',
        defaultMessage: 'Napadený akt - Název aktu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 29,
    },
    {
      datakey: 'esFile.judge.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useJudges,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 30,
    },

    {
      datakey: 'esFile.processingType.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PROCESSING_TYPE',
        defaultMessage: 'Typ řízení',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useProcessingTypes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 31,
    },
    {
      datakey: 'esFile.proceedingsSubjects.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PROCEEDINGS_SUBJECT',
        defaultMessage: 'Předmět řízení',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableAutocompleteFilterCell(
        useProceedingsSubjects,
        autocompleteLabelMapper,
        true
      ),
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 32,
    },
    {
      datakey: 'esFile.subjectIndexes.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__SUBJECT_INDEXES',
        defaultMessage: 'Věcný rejstřík',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: NullableAutocompleteFilterCell(
        useSubjectIndexes,
        autocompleteLabelMapper,
        true
      ),
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 33,
    },
    {
      datakey: 'esFile.number.value',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: true,
      filterGroup: 3,
      filterOrder: 34,
    },
  ];
}
