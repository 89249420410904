import React, { ComponentType, ReactElement, Ref, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import {
  ActionButtonComponentProps,
  ActionButtonHandle,
  DetailMode,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { Task } from '@models';
import { EvidenceApiUrl, TaskState } from '@enums';

export function useChangeTaskStateDialog() {
  const callApi = useEventCallback((id: string, newState: TaskState) => {
    return abortableFetch(`${EvidenceApiUrl.TASKS}/${id}/change-state`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ newState }),
    });
  });

  return {
    callApi,
  };
}

export const ProcessTaskButton = forwardRef(function ProcessTaskButton(
  {
    ButtonComponent,
    modes,
    task,
  }: {
    ButtonComponent?: ComponentType<ActionButtonComponentProps>;
    modes?: DetailMode[];
    task: Task;
  },
  ref: Ref<ActionButtonHandle>
) {
  const intl = useIntl();
  const { callApi } = useChangeTaskStateDialog();

  return (
    <DetailActionButton
      ref={ref}
      promptKey="PROCESS_TASK"
      apiCall={() => callApi(task.id, TaskState.PROCESSED)}
      buttonLabel={intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__PROCESS_TASK',
        defaultMessage: 'Hotovo',
      })}
      dialogTitle={intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__PROCESS_TASK__TITLE',
        defaultMessage: 'Úloha',
      })}
      dialogText={intl.formatMessage({
        id: 'ES__TASKS_TOOLBAR__PROCESS_TASK__TEXT',
        defaultMessage: 'Skutečně chcete změnit stav lhůty na HOTOVO?',
      })}
      ButtonComponent={ButtonComponent}
      modes={modes}
    />
  );
}) as (
  p: {
    ButtonComponent?: ComponentType<ActionButtonComponentProps>;
    modes?: DetailMode[];
    task: Task;
  } & {
    ref?: Ref<ActionButtonHandle>;
  }
) => ReactElement;

export const CancelTaskButton = forwardRef(function CancelTaskButton(
  {
    ButtonComponent,
    modes,
    task,
  }: {
    modes?: DetailMode[];
    task: Task;
    ButtonComponent?: ComponentType<ActionButtonComponentProps>;
  },
  ref: Ref<ActionButtonHandle>
) {
  const intl = useIntl();
  const { callApi } = useChangeTaskStateDialog();

  return (
    <DetailActionButton
      ref={ref}
      promptKey="CANCEL_TASK"
      apiCall={() => callApi(task.id, TaskState.CANCELED)}
      buttonLabel={intl.formatMessage({
        id: 'ES__ES_FILES__TASKS_TOOLBAR__CANCEL_TASK',
        defaultMessage: 'Storno',
      })}
      dialogTitle={intl.formatMessage({
        id: 'ES__ES_FILES__DIALOG_CANCEL_TASK__TITLE',
        defaultMessage: 'Úloha',
      })}
      dialogText={intl.formatMessage({
        id: 'ES__ES_FILES__DIALOG_CANCEL_TASK__TEXT',
        defaultMessage: 'Skutečně chcete změnit stav lhůty na STORNO?',
      })}
      ButtonComponent={ButtonComponent}
      modes={modes}
    />
  );
}) as (
  p: {
    ButtonComponent?: ComponentType<ActionButtonComponentProps>;
    modes?: DetailMode[];
    task: Task;
  } & {
    ref?: Ref<ActionButtonHandle>;
  }
) => ReactElement;
