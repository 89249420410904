import { useContext } from 'react';
import { UserContext, abortableFetch, useEventCallback } from '@eas/common-web';
import { Deadline } from '@models';
import { EvidenceApiUrl, Role } from '@enums';

export function useEditDeadlineDialog() {
  const { hasPermission } = useContext(UserContext);

  const callApi = useEventCallback((id: string, values: Deadline) => {
    return abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${values?.document?.id}/deadline/${values.id}`,
      {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(values),
      }
    );
  });

  const show = hasPermission(Role.INDEX) || hasPermission(Role.SECRETARY);

  return {
    callApi,
    show,
  };
}
