export const VIEWING_REQUESTS = {
  title: 'Žádosti o nahlížení',
  description: 'Evidence žádostí o nahlížení do spisů.',
  actions: [
    {
      name: 'Vytvoření žádosti',
      description:
        'Vytvoření žádosti je možné z detailu soudního spisu - záložka <b>Nahlížení a zápůjčky</b> po kliknutí na tlačítko <b>Žádost o fyzické nahlížení</b>.',
    },
    {
      name: 'Upravení žádosti',
      description:
        'Uživatel může upravovat jednotlivé atributy žádosti o rozhodnutí.',
    },
    {
      name: 'Smazání žádosti',
      description: 'Uživatel může smazat žádost o nahlížení. ',
    },
  ],
};
