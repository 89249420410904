import React, { ReactElement, Ref, forwardRef } from 'react';
import {
  DetailActionButton as ActionButton,
  ActionButtonHandle,
  DetailActionButtonProps,
} from '@eas/common-web';

export const DetailActionButton = forwardRef(function DetailActionButton<
  T = unknown
>(props: DetailActionButtonProps<T>, ref: Ref<ActionButtonHandle>) {
  return (
    <ActionButton
      {...props}
      ref={ref}
      dialogDisableBackdrop={props.dialogDisableBackdrop ?? true}
    />
  );
}) as <T = unknown>(
  p: DetailActionButtonProps<T> & { ref?: Ref<ActionButtonHandle> }
) => ReactElement;
