import { useIntl } from 'react-intl';
import { TableCells, TableColumn } from '@eas/common-web';
import { DictHoliday } from '@models';

export function useColumns(): TableColumn<DictHoliday>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'date',
      name: intl.formatMessage({
        id: 'ES__DICT_HOLIDAYS__TABLE__COLUMN__DATE',
        defaultMessage: 'Datum',
      }),
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'description',
      name: intl.formatMessage({
        id: 'ES__DICT_HOLIDAYS__TABLE__COLUMN__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
    },
  ];
}
