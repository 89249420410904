export const DISCIPLINARY_PROCEEDINGS = {
  title: 'Kárné řízení',
  description:
    'Agenda Kárné řízení eviduje spisy týkající se kárného provinění soudců Ústavního soudu. Kárným proviněním je jednání, jímž soudce snižuje vážnost a důstojnost své funkce nebo ohrožuje důvěru v nezávislé a nestranné rozhodování Ústavního soudu, jakož i jiné zaviněné porušení povinností soudce. Kárným proviněním je též jednání, které má znaky přestupku podle zvláštních předpisů. O zahájení kárného řízení rozhoduje předseda Ústavního soudu usnesením. Usnesení musí být odůvodněno a doručuje se soudci, proti němuž se kárné řízení zahajuje. O zahájení kárného řízení s předsedou Ústavního soudu se může usnést plénum na společný návrh nejméně tří soudců.',
  actions: [
    {
      name: 'Vytvoření nového kárného spisu',
      description: 'Vytvoření nového spisu s agendou <b>Kárné řízení</b>.',
    },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
    {
      name: 'Změnit agendu',
      description: 'Změna agendy. Dostupné pro spisy ve stavu <b>Nový</b>.',
    },
    {
      name: 'Změnit stav',
      description:
        'U spisů, které nejsou ve stavu <b>Uzavřený</b> je možné změnit stav.',
    },
    {
      name: 'Smazání spisu',
      description: 'Dostupné pro spisy ve stavu <b>Nový</b>.',
    },
    {
      name: 'Upravení spisu',
      description:
        'U spisů, které nejsou ve stavu <b>Uzavřený</b> má uživatel možnost upravovat jednotlivé atributy spisu.',
    },
    {
      name: 'Vyvést',
      description:
        'Při vyvedení z rejstříku je změněn <b>Způsob vyřízení</b> na <i>Vyvedeno z rejstříku</i>, jsou stornovány všechny aktivní úlohy a běžící lhůty spisu, spis je uzavřen. Akce je dostupná u spisů, které nejsou uzavřené a mají přidělenu spisovou značku.',
    },
    {
      name: 'Znovu otevřít',
      description: 'Otevření spisu, který je ve stavu <b>Uzavřený</b>.',
    },
  ],
};
