import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailMode,
  FieldFilter,
  FormAutocomplete,
  FormDateTimeField,
  FormDictionaryField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  TableFieldCells,
  eqFilterParams,
  useFormSelector,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { AuthoredFields } from '@components/evidence/authored-evidence/authored-fields';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteLabelMapper,
  dictionaryFieldLabelMapper,
} from '@components/form/misc/label-mappers';
import { Document, Task } from '@models';
import { EvidenceApiUrl, EvidenceBrowserUrl, Role } from '@enums';
import { useTaskNames } from '../dict-task-name/dict-task-name-api';
import { useDocuments } from '../document/document-api';
import { useUserRoles } from '../role/role-api';

export function Fields() {
  const TasksFields = useTasksFields();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__TASKS___DETAIL__TASK_PANEL_TITLE"
            defaultMessage="Úloha"
          />
        }
      >
        <TasksFields />
      </FormPanel>
    </>
  );
}

export function useTasksFields() {
  const { taskTypes, taskStates, documentTypes } =
    useContext(StaticDataContext);

  return function TasksFields() {
    const { mode, source } = useContext(DetailContext);
    const { esFile } = useFormSelector((data: Task) => ({
      esFile: data?.esFile,
    }));

    const typesSource = useStaticListSource(taskTypes);
    const stateSource = useStaticListSource(taskStates);
    const useDocumentTypes = () => useStaticListSource(documentTypes);

    const taskNames = useTaskNames();
    const documents = useDocuments(
      eqFilterParams({ field: 'esFile.id', value: esFile?.id })
    );
    const documentsSource = useScrollableSource<Document>({
      url: `${EvidenceApiUrl.DOCUMENTS}/list`,
    });
    const solverUsers = useUserRoles({
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'role.id',
          value: Role.SOLVER,
        },
      ],
    });

    return (
      <>
        <FormAutocomplete
          name="name"
          source={taskNames}
          label={
            <FormattedMessage
              id="ES__TASKS__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Název"
            />
          }
          labelMapper={autocompleteLabelMapper}
          disabled={mode === DetailMode.VIEW}
          required={true}
          freeSolo={true}
          autoFocus={true}
        />
        <FormTextArea
          name="description"
          label={
            <FormattedMessage
              id="ES__TASKS__DETAIL__FIELD_LABEL__DESCRIPTION"
              defaultMessage="Popis"
            />
          }
          disabled={mode === DetailMode.VIEW}
        />
        <FormSelect
          name="type"
          source={typesSource}
          label={
            <FormattedMessage
              id="ES__TASKS__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ úlohy"
            />
          }
          disabled={true}
          valueIsId={true}
          required={true}
        />
        <FormSelect
          name="state"
          source={stateSource}
          label={
            <FormattedMessage
              id="ES__TASKS__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav úlohy"
            />
          }
          disabled={true}
          valueIsId={true}
          required={true}
        />
        {esFile?.number && (
          <FormTextField
            name="esFile.number.value"
            label={
              <FormattedMessage
                id="ES__TASKS__DETAIL__FIELD_LABEL__FILE_NUMBER"
                defaultMessage="Spisová značka"
              />
            }
            after={
              esFile?.id && (
                <Redirect
                  url={EvidenceBrowserUrl.ES_FILES_ALL}
                  id={esFile.id}
                />
              )
            }
            disabled={true}
            required={true}
          />
        )}

        <FormDictionaryField<Document>
          name="document"
          label={
            <FormattedMessage
              id="ES__TASKS__DETAIL__FIELD_LABEL__REFERENCE_NUMBER"
              defaultMessage="Číslo jednací"
            />
          }
          dialogSource={documentsSource}
          autocompleteSource={documents}
          columns={[
            {
              name: 'Číslo jednací',
              datakey: 'referenceNumber',
              width: 300,
            },
            { name: 'Stručný obsah', datakey: 'summary', width: 300 },
            {
              name: 'Typ dokumentu',
              datakey: 'type',
              width: 300,
              CellComponent:
                TableFieldCells.useSelectCellFactory(useDocumentTypes),
            },
            {
              name: 'Datum',
              datakey: 'date',
              width: 300,
              CellComponent: TableFieldCells.DateCell,
            },
          ]}
          filters={[
            {
              label: 'Číslo jednací',
              datakey: 'name',
              filterkey: 'referenceNumber',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Stručný obsah',
              datakey: 'summary',
              filterkey: 'summary',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Typ dokumentu',
              datakey: 'type',
              filterkey: 'type.id',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterSelectCell(useDocumentTypes),
            },
            {
              label: 'Datum',
              datakey: 'date',
              filterkey: 'date',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterDateCell,
            },
          ]}
          labelMapper={dictionaryFieldLabelMapper}
          dialogPreFilters={[
            {
              field: 'esFile.id',
              operation: ApiFilterOperation.EQ,
              value: esFile?.id,
            },
          ]}
          disabled={
            mode !== DetailMode.NEW || source.url === EvidenceApiUrl.DOCUMENTS
          }
        />
        <FormAutocomplete
          name="solver"
          source={solverUsers}
          label={
            <FormattedMessage
              id="ES__TASKS__DETAIL__FIELD_LABEL__SOLVER"
              defaultMessage="Pro uživatele"
            />
          }
          disabled={!esFile || mode === DetailMode.VIEW}
          required={true}
          labelMapper={autocompleteLabelMapper}
        />
        <FormDateTimeField
          name="created"
          label={
            <FormattedMessage
              id="ES__TASKS__DETAIL__FIELD_LABEL__CREATED"
              defaultMessage="Datum vytvoření"
            />
          }
          disabled={true}
        />
        <AuthoredFields.CreatedBy />
      </>
    );
  };
}
