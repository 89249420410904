import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailToolbarButton2,
  DetailToolbarButtonProps,
  PromptContext,
  useEventCallback,
  usePrompts,
} from '@eas/common-web';
import { CourtFile, DictSettlementMethod, PlenaryOpinionFile } from '@models';

export function SaveButton<T extends CourtFile | PlenaryOpinionFile>(
  props: DetailToolbarButtonProps
) {
  const { testPrompt } = useContext(PromptContext);
  const { source, formRef } = useContext<DetailHandle<T>>(DetailContext);

  const [deletedMethods, setDeletedMethods] = useState<DictSettlementMethod[]>(
    []
  );

  const label = deletedMethods.map((d) => d.label).join(', ');

  usePrompts(
    [
      {
        key: 'WARNING',
        dialogTitle: (
          <FormattedMessage id="ES__WARNING__TITLE" defaultMessage="Varování" />
        ),
        dialogText: (
          <FormattedMessage
            id="ES__WARNING__TEXT"
            defaultMessage="Smazali jste způsoby vyřízení: {label} Po uložení budou tyto způsoby vyřízení smazány ze všech rozhodnutí."
            values={{
              label: (
                <>
                  <br />
                  {deletedMethods.map((method) => (
                    <React.Fragment key={method.id}>
                      <b>{method?.label}</b>
                      <br />
                    </React.Fragment>
                  ))}

                  <br />
                </>
              ),
            }}
          />
        ),
      },
    ],
    [label]
  );

  const handleSave = useEventCallback(() => {
    const decisionSettlementMethods =
      source.data?.decisions
        ?.map((d) => d.settlementMethods)
        .flat()
        .map((d) => d!.id) ?? [];

    const originalMethods = source.data?.settlementMethods ?? [];
    const newMethods = formRef?.getFieldValues().settlementMethods ?? [];

    const deletedMethods = originalMethods.filter(
      (d) => !newMethods.map((d) => d.id)?.includes(d.id)
    );

    const deletedImportantMethods = deletedMethods?.filter((d) =>
      decisionSettlementMethods.includes(d.id)
    );

    setDeletedMethods(deletedImportantMethods);

    if (deletedImportantMethods.length) {
      setTimeout(() => {
        testPrompt({
          key: 'WARNING',
          submitCallback: () => props.onClick(),
        });
      }, 0);
    } else {
      props.onClick();
    }
  });

  return <DetailToolbarButton2 {...props} onClick={handleSave} />;
}
