import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    { key: 'esFile', label: 'Spis' },
    { key: 'file', label: 'Soubor' },
    { key: 'finalVersion', label: 'Konečná verze' },
    { key: 'note', label: 'Poznámka' },
  ];
}
