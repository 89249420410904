import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useSetComplaintDialog() {
  const callApi = useEventCallback((esFileId: string, documentId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/inf/${esFileId}/document/delivered/${documentId}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
        body: JSON.stringify({ complaint: true }),
      }
    )
  );

  return {
    callApi,
  };
}

export function useRemoveComplaintDialog() {
  const callApi = useEventCallback((esFileId: string, documentId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/inf/${esFileId}/document/delivered/${documentId}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
        body: JSON.stringify({ complaint: false }),
      }
    )
  );

  return {
    callApi,
  };
}
