import {
  Params,
  useAutocompleteSource,
  useFetch,
  useScrollableSource,
} from '@eas/common-web';
import { DictionaryObject, Judge, JudgeAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useJudges(params?: Params, apiUrl?: EvidenceApiUrl) {
  return useAutocompleteSource<JudgeAutocomplete>({
    url: `${EvidenceApiUrl.JUDGES}/autocomplete`,
    params,
    apiUrl,
  });
}

export function useJudgeSource() {
  return useScrollableSource<Judge>({
    url: `${EvidenceApiUrl.JUDGES}/list`,
  });
}

export function useJudgeFunctions() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.JUDGES}/function/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
