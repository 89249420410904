import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DictOpponent } from '@models';

export function useColumns(): TableColumn<DictOpponent>[] {
  const intl = useIntl();
  const { columnName, columnActive } = DictionaryEvidence.useDictionaryColumns<
    DictOpponent
  >();

  return [
    columnName,
    {
      datakey: 'generateNotifications',
      name: intl.formatMessage({
        id: 'ES__DICT_OPPONENTS__TABLE__COLUMN__GENERATE_NOTIFICATIONS',
        defaultMessage: 'Generovat notifikace',
      }),
      width: 150,
      CellComponent: TableCells.BooleanCell,
    },
    columnActive,
  ];
}
