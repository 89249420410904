import React from 'react';
import { SimilarChallengedActFileField } from '@composite/similar-files/similar-challenged-act-file-field';
import { SimilarProposerFileField } from '@composite/similar-files/similar-proposer-file-field';

export function SimilarFilesFields() {
  return (
    <>
      <SimilarProposerFileField />
      <SimilarChallengedActFileField />
    </>
  );
}
