import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HistoryField } from '@composite/history/history-field';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { Content } from './fields/content/content';
import { General } from './fields/general/general';
import { useHistoryDescriptors } from './internal-document-history-descriptors';

export function Fields() {
  const historyDescriptors = useHistoryDescriptors();

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ES__DOCUMENT_INTERNAL__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Obecné"
        />
      ),
      key: 'GENERAL',
      content: <General />,
      validationKeys: ['summary'],
    },
    {
      label: (
        <FormattedMessage
          id="ES__DOCUMENT_INTERNAL__DETAIL__PANEL_TITLE__CONTENT"
          defaultMessage="Obsah"
        />
      ),
      key: 'CONTENT',
      content: <Content />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DOCUMENT_INTERNAL__DETAIL__PANEL_TITLE__HISTORY"
          defaultMessage="Historie"
        />
      ),
      key: 'HISTORY',
      content: <HistoryField descriptors={historyDescriptors} />,
    },
  ];

  return <Tabs config={config} />;
}
