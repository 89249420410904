import { Context, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  AppSettingsContext,
  FormHandle,
  SnackbarContext,
  SnackbarVariant,
  useEventCallback,
} from '@eas/common-web';
import { EsAppSettings } from '@models';

export function useAppSettings() {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const { settings, update } = useContext(
    AppSettingsContext as unknown as Context<AppSettingsContext<EsAppSettings>>
  );

  const ref = useRef<FormHandle<EsAppSettings>>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);

  const validationSchema = Yup.object<EsAppSettings>().shape({
    primaryColor: Yup.string()
      .test('', '', function (value) {
        const { createError, path } = this;

        if (value && !value?.match(/^#(?:[0-9a-f]{3}){1,2}$/i)) {
          return createError({
            path,
            message:
              'Barva musí být zadána ve validním hexa kódu - např. #0d98ba.',
          });
        }
        return true;
      })
      .nullable(),
    substitutionPrimaryColor: Yup.string()
      .test('', '', function (value) {
        const { createError, path } = this;

        if (value && !value?.match(/^#(?:[0-9a-f]{3}){1,2}$/i)) {
          return createError({
            path,
            message:
              'Barva musí být zadána ve validním hexa kódu - např. #0d98ba.',
          });
        }
        return true;
      })
      .nullable(),
  });

  const handleStartEditing = useEventCallback(() => {
    setEditing(true);
  });

  const handleCancelEditing = useEventCallback(() => {
    setEditing(false);
    ref.current?.setFieldValues(settings);
  });

  useEffect(() => {
    ref.current?.setFieldValues(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useEventCallback(async () => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'ES__APP_SETTTINGS__MSG__VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });
        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        setLoading(false);
        return;
      }

      await update(ref.current.getFieldValues());

      setEditing(false);
    }
  });

  return {
    loading,
    editing,
    handleSubmit,
    handleStartEditing,
    handleCancelEditing,
    ref,
    validationSchema,
  };
}
