import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  DetailContext,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Agenda } from '@enums';

const useStyles = makeStyles(() => ({
  divider: {
    width: '100%',
    margin: '10px 0',
  },
}));

export function useFormFields() {
  const { source } = useContext(DetailContext);
  const { agendas } = useContext(StaticDataContext);

  return function FormFields() {
    const classes = useStyles();

    const agendasSource = useStaticListSource(
      agendas.filter(
        (agenda) =>
          ![source.data?.agenda, Agenda.PLENARY_OPINION].includes(agenda.id)
      )
    );

    return (
      <>
        <FormSelect
          source={agendasSource}
          name="agenda"
          label={
            <FormattedMessage
              id="ES__ES_FILE__CHANGE_AGENDA_HOOK__FIELD_LABEL__AGENDA"
              defaultMessage="Agenda"
            />
          }
          labelOptions={{
            hide: true,
          }}
          valueIsId={true}
        />
        <div className={classes.divider} />
      </>
    );
  };
}
