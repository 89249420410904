import {
  ChallengedAct,
  CourtFile,
  Decision,
  DictProceedingsSubject,
  DictSubjectIndex,
  DictionaryAutocomplete,
  Proposer,
} from '@models';
import { DecisionForm } from '@enums';

export function createContent(decision?: Decision | null) {
  let content = '';
  if (decision) {
    content = addToText(content, decision.anonymizedText?.introduction ?? '');
    content = addToText(content, decision.anonymizedText?.statement ?? '');
    content = addToText(content, decision.anonymizedText?.narration ?? '');
    content = addToText(content, decision.anonymizedText?.argumentation ?? '');
    content = addToText(
      content,
      decision.anonymizedText?.differentOpinion ?? ''
    );
  }

  return content;
}

export function createQuote(
  decisionForms: DictionaryAutocomplete[],
  decision?: Decision | null
) {
  const decisionForm = showDecisionForm(decisionForms, decision?.form);
  let quote = '';

  if (decision) {
    quote = `${decisionForm} sp. zn. ${
      decision.esFile?.number?.value
    } ze dne ${showDate(decision.decisionDate)}`;
  }

  return quote;
}

function addToText(text: string, content: string) {
  if (content) {
    return `${text}
  
${content}
  `;
  }
  return text;
}
function showDate(date?: string) {
  if (date) {
    const label = new Date(date).toLocaleDateString();
    if (label === 'Invalid Date') {
      return '-';
    }
    return label;
  }
  return '-';
}

function showDecisionForm(
  decisionForms: DictionaryAutocomplete[],
  value?: DecisionForm
) {
  return decisionForms.find((form) => form.id === value)?.name || '-';
}

function showProposers(proposers?: Proposer[]) {
  return proposers?.length ? proposers?.map((p) => p.name!) : '-';
}

function showConcernedAuthorities(challengedActs?: ChallengedAct[]) {
  return challengedActs?.length
    ? challengedActs?.map((p) => p.concernedAuthority.name!)
    : '-';
}

function showChallengedActs(challengedActs?: ChallengedAct[]) {
  return challengedActs?.length ? challengedActs?.map((p) => p.actName!) : '-';
}

function showProceedingsSubjects(
  proceedingsSubjects?: DictProceedingsSubject[]
) {
  return proceedingsSubjects?.length
    ? proceedingsSubjects?.map((p) => p.name)
    : '-';
}

function showSubjectIndexes(subjectIndexes?: DictSubjectIndex[]) {
  return subjectIndexes?.length ? subjectIndexes?.map((p) => p.name) : '-';
}

export function createDecisionData(
  decisionForms: DictionaryAutocomplete[],
  decision?: Decision
) {
  const data = new Map<string, string | string[]>([
    ['Karta záznamu', decision?.ecli || '-'],
    ['Název soudu', 'Ústavní soud' || '-'],
    ['Spisová značka', decision?.esFile?.number?.value || '-'],
    ['Populární název', decision?.popularName || '-'],
    ['Datum rozhodnutí', showDate(decision?.decisionDate) || '-'],
    ['Datum vyhlášení', showDate(decision?.publicationDate) || '-'],
    ['Datum podání', showDate(decision?.esFile?.deliveryDate) || '-'],
    ['Datum zpřístupnění', showDate(decision?.madeAccessibleDate) || '-'],
    [
      'Forma rozhodnutí',
      showDecisionForm(decisionForms, decision?.form) || '-',
    ],
    ['Typ řízení', decision?.esFile?.processingType?.name || '-'],
    ['Význam', decision?.importance?.toString() || '-'],
    [
      'Navrhovatel',
      showProposers((decision?.esFile as CourtFile)?.proposers) || '-',
    ],
    [
      'Dotčený orgán',
      showConcernedAuthorities(
        (decision?.esFile as CourtFile)?.challengedActs
      ) || '-',
    ],
    ['Soudce zpravodaj', decision?.esFile?.judge?.name || '-'],
    [
      'Napadený akt',
      showChallengedActs((decision?.esFile as CourtFile)?.challengedActs) ||
        '-',
    ],
    ['Výrok', decision?.anonymizedText?.statement || '-'],
    ['Dotčené ústavní zákony a mezinárodní smlouvy', '-'],
    ['Ostatní dotčené předpisy', '-'],
    [
      'Předmět řízení',
      showProceedingsSubjects(decision?.esFile?.proceedingsSubjects) || '-',
    ],
    [
      'Věcný rejstřík',
      showSubjectIndexes(decision?.esFile?.subjectIndexes) || '-',
    ],
    ['Poznámka', decision?.note || '-'],
    ['URL adresa', '-'],
  ]);

  return data;
}
