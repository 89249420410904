import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { UserContext, useFirstRender } from '@eas/common-web';
import { EvidenceBrowserUrl } from './enums/evidence-url';
import { Login, Logout } from './modules';

const useStyles = makeStyles((theme) => ({
  menubar: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: 33,
  },
}));

export function AppPublic() {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { push } = useHistory();

  useFirstRender(() => {
    if (user) {
      push('/es');
    }
  });

  return (
    <div className={classes.menubar}>
      <Switch>
        <Route path={EvidenceBrowserUrl.LOGOUT} exact component={Logout} />
        <Route path="/" component={Login} />
      </Switch>
    </div>
  );
}
