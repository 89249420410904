import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteLabelMapper,
  judgeSolversMergedColumnMapper,
  multipleAutocompleteColumnMapper,
  participantsWritersMergedColumMapper,
} from '@components/form/misc/label-mappers';
import { EsFile } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { useSettlementMethods } from '../dict-settlement-method/dict-settlement-method-api';
import { useColumns as useDefaultColumns } from '../es-file/es-file-columns';

export function useColumns(): TableColumn<EsFile>[] {
  const intl = useIntl();
  const defaultColumns = useDefaultColumns();

  const { deadlineStates } = useContext(StaticDataContext);
  const useDeadlineStates = () => useStaticListSource(deadlineStates);

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.ES_FILES_ALL}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
      sortable: false,
    },
    {
      datakey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SUBMISSION_DATE',
        defaultMessage: 'Datum doručení',
      }),
      width: 150,
      CellComponent: TableCells.DateCell,
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'incomingNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__INCOMING_NUMBER',
        defaultMessage: 'Došlé číslo',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 120,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'judgeSolversMerged',
      filterkey: 'judgeSolversMerged.concatenated',
      sortkey: 'judgeSolversMerged.concatenated',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj / Řešitel',
      }),
      width: 300,
      valueMapper: judgeSolversMergedColumnMapper,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'participants',
      filterkey: 'participants.contact.subject.name',
      sortkey: 'participants.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROPOSERS',
        defaultMessage: 'Navrhovatel / Pisatel',
      }),
      width: 300,
      valueMapper: participantsWritersMergedColumMapper,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'settlementMethods',
      sortkey: 'settlementMethods.concatenated',
      filterkey: 'settlementMethods.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SETTLEMENT_METHODS',
        defaultMessage: 'Způsob vyřízení',
      }),
      width: 300,
      valueMapper: multipleAutocompleteColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSettlementMethods,
        autocompleteLabelMapper
      ),
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    ...defaultColumns,
    /**
     * warning: when changing this column, also fix user-overview.tsx#104 onClick funtion
     */
    {
      datakey: 'documents.deadlines',
      sortkey: 'documents.deadlines.state.name',
      filterkey: 'documents.deadlines.state.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DEADLINE_STATE',
        defaultMessage: 'Stav lhůty',
      }),
      width: 150,
      valueMapper: TableCells.useSelectCellFactory(useDeadlineStates),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useDeadlineStates),
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      visible: false,
    },
  ];
}
