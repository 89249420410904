import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormSelector } from '@eas/common-web';
import { AttachmentField } from '@composite/attachments/attachment-field';
import { Document } from '@models';
import { AttachmentType } from '@enums';
import { useInternalDocumentPermission } from '../../../document/document-permission';

export function Content() {
  const { attachments, summary } = useFormSelector((data: Document) => ({
    attachments: data.attachments ?? [],
    summary: data.summary ?? '',
  }));

  const internalAttachments = attachments.filter(
    (a) => a.type === AttachmentType.INTERNAL
  );

  const lustrumAttachments = attachments.filter(
    (a) => a.type === AttachmentType.LUSTRUM
  );

  const { hasPermission } = useInternalDocumentPermission();

  return (
    <>
      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__INTERNAL"
            defaultMessage="Přílohy interního dokumentu"
          />
        }
        attachments={internalAttachments}
        attachmentType={AttachmentType.INTERNAL}
        defaultName={summary}
        hasPermission={hasPermission}
      />
      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_INTERNAL__DETAIL__PANEL_TITLE__LUSTRUM"
            defaultMessage="Lustrum"
          />
        }
        attachments={lustrumAttachments}
        attachmentType={AttachmentType.LUSTRUM}
        defaultName={summary}
        showAddButton={false}
        showEditButton={false}
        hasPermission={hasPermission}
      />
    </>
  );
}
