import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AssignedAndResolvedCounts } from './assigned-and-resolved-counts/assigned-and-resolved-counts-card';
import { MonthlyStatisticOutput } from './monthly-statistic-output/monthly-statistic-output-card';
import { ProceedingDurationResolvedCounts } from './proceeding-duration-counts/proceeding-duration-resolved-counts-card';
import { ProceedingDurationUnresolvedCounts } from './proceeding-duration-counts/proceeding-duration-unresolved-counts-card';
import { SettlementMethodCounts } from './settlement-method-counts/settlement-method-counts-card';
import { SubmissionCounts } from './submission-counts/submission-counts-card';
import { UnresolvedByJudgeCounts } from './unresolved-by-judge-counts/unresolved-by-judge-counts-card';

export const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f1f3f4',
    overflow: 'auto',
  },
  content: {
    marginTop: 50,
    marginBottom: 50,
    flex: '1 0 auto',
  },
}));

export function Statistics() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <MonthlyStatisticOutput />
            <ProceedingDurationResolvedCounts />
            <ProceedingDurationUnresolvedCounts />
            <SubmissionCounts />
            <UnresolvedByJudgeCounts />
            <SettlementMethodCounts />
            <AssignedAndResolvedCounts />
          </Grid>
        </Container>
      </div>
    </div>
  );
}
