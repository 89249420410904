import { noop, stubFalse, sumBy } from 'lodash';
import React from 'react';
import { lighten } from '@material-ui/core/styles';
import { TableField } from '@eas/common-web';
import { useStyles } from '@modules/dashboard/search/result-table';
import {
  AssignedAndResolvedByJudgeCount,
  AssignedAndResolvedPlenaryCount,
  AssignedAndResolvedSenateCount,
} from '@models';
import { ChartView, CourtFileType } from '@enums';
import { useAssignedAndResolvedCountsColumns } from './assigned-and-resolved-counts-columns';

export function useAssignedAndResolvedCountsVisualisation({
  courtFileType,
  data = [],
  addSum = false,
}: {
  data?: AssignedAndResolvedPlenaryCount[] | AssignedAndResolvedSenateCount[];
  courtFileType?: CourtFileType;
  addSum?: boolean;
}) {
  const Table = () => {
    const classes = useStyles();

    const columns = useAssignedAndResolvedCountsColumns(courtFileType);
    const parsedData = addSum ? parseData(data) : data;

    return (
      <TableField
        value={parsedData}
        onChange={noop}
        columns={columns}
        showRadioCond={stubFalse}
        classes={classes}
        maxRows={10}
        showToolbar={false}
        createRowStyle={(item) =>
          addSum && item?.judgeName === 'Celkem'
            ? { backgroundColor: lighten('#0d98ba', 0.8) }
            : {}
        }
      />
    );
  };

  return {
    Visualisations: { [ChartView.TABLE]: Table },
  };
}

const parseData = (data: AssignedAndResolvedByJudgeCount[]) => {
  const parsedData: AssignedAndResolvedByJudgeCount[] = [...data];

  if (parsedData?.length > 0) {
    parsedData.push({
      judgeName: 'Celkem',
      countSenateAssigned: sumBy(data, 'countSenateAssigned'),
      countSenateResolved: sumBy(data, 'countSenateResolved'),
      countPlenaryAssigned: sumBy(data, 'countPlenaryAssigned'),
      countPlenaryResolved: sumBy(data, 'countPlenaryResolved'),
    });
  }

  return parsedData;
};
