import * as Yup from 'yup';
import { Task, UserRole } from '@models';
import { TaskState, TaskType } from '@enums';

export function useValidationSchema() {
  return Yup.object<Task>().shape({
    name: Yup.string().nullable().required(),
    type: Yup.mixed<TaskType>().nullable().required(),
    state: Yup.mixed<TaskState>().nullable().required(),
    solver: Yup.mixed<UserRole>().nullable().required(),
  });
}
