import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { FormDateField, abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export const callApi = (dispatchId: string, formData: any) => {
  return abortableFetch(
    `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/${dispatchId}/notice-board`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    }
  );
};

export function useSendByNoticeBoardValidationSchema() {
  return Yup.object().shape({
    noticeDate: Yup.string().nullable().required(),
    postingDate: Yup.string().nullable().required(),
    expirationDate: Yup.string().nullable().required(),
  });
}

export function SendByNoticeBoardDialog() {
  return (
    <>
      <FormDateField
        name="noticeDate"
        label={
          <FormattedMessage
            id="ES__DISPATCHES__SEND_BY_NOTICE_BOARD__FIELD_LABEL__NOTICE_DATE"
            defaultMessage="Datum oznámení"
          />
        }
        autoFocus={true}
      />
      <FormDateField
        name="postingDate"
        label={
          <FormattedMessage
            id="ES__DISPATCHES__SEND_BY_NOTICE_BOARD__FIELD_LABEL__POSTING_DATE"
            defaultMessage="Datum vyvěšení"
          />
        }
      />
      <FormDateField
        name="expirationDate"
        label={
          <FormattedMessage
            id="ES__DISPATCHES__SEND_BY_NOTICE_BOARD__FIELD_LABEL__EXPIRATION_DATE"
            defaultMessage="Datum snětí"
          />
        }
      />
    </>
  );
}
