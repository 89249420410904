import { getErrorMessage } from '@utils/error-message';
import React, { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {
  ApiFilterOperation,
  DetailContext,
  DialogHandle,
  DictionaryFieldDialog,
  FieldFilter,
  SnackbarContext,
  SnackbarVariant,
  TableFieldCells,
  TableFieldToolbarButton,
  abortableFetch,
  useEventCallback,
  useScrollableSource,
} from '@eas/common-web';
import { CreatedByCell } from '@components/evidence/authored-evidence/created-by-cell';
import { Draft, ErrorObject } from '@models';
import { EvidenceApiUrl } from '@enums';

function callCopyDraftApi(id: string, values: string[]) {
  return abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/draft/copy`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(values),
  });
}

export function CopyDraftButton({ disabled }: { disabled: boolean }) {
  const { source, isLocked } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const dialogRef = useRef<DialogHandle>(null);
  const drafts = useScrollableSource<Draft>({
    url: `${EvidenceApiUrl.ES_FILES}/${source.data?.esFile?.id}/draft/list`,
  });
  const intl = useIntl();

  const callCopyApi = useEventCallback(async (selectedDrafts: Draft[]) => {
    try {
      await callCopyDraftApi(
        source.data!.id,
        selectedDrafts.map((draft) => draft.id)
      ).raw();

      dialogRef.current?.close();
      source.refresh();

      const message = intl.formatMessage({
        id: 'EAS_ACTION_BUTTON_MSG_SUCCESS',
        defaultMessage: 'Akce byla úspěšně vykonána.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
    } catch (e) {
      const err = e as ErrorObject<string>;
      const message = getErrorMessage(err);

      if (err.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  return (
    <>
      <TableFieldToolbarButton
        IconComponent={ControlPointIcon}
        show={true}
        disabled={isLocked || disabled}
        title="Přidat koncept"
        onClick={() => dialogRef.current?.open()}
        color="primary"
        variant="contained"
      >
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'ES__DOCUMENTS__ACTIONBAR__SEARCH_DRAFT',
            defaultMessage: 'Přidat koncept',
          })}
        </Typography>
      </TableFieldToolbarButton>
      <DictionaryFieldDialog
        ref={dialogRef}
        columns={[
          {
            name: intl.formatMessage({
              id: 'ES__DRAFTS__COLUMN__NAME',
              defaultMessage: 'Název konceptu',
            }),
            datakey: 'name',
            width: 200,
          },
          {
            name: intl.formatMessage({
              id: 'ES__DRAFTS__COLUMN__FILE_NAME',
              defaultMessage: 'Název souboru',
            }),
            datakey: 'file.name',
            width: 200,
          },
          {
            name: intl.formatMessage({
              id: 'ES__DRAFTS__COLUMN__CREATED_BY',
              defaultMessage: 'Tvůrce',
            }),
            datakey: 'createdBy.name',
            width: 200,
            CellComponent: CreatedByCell,
          },
          {
            name: intl.formatMessage({
              id: 'ES__DRAFTS__COLUMN__UPDATED',
              defaultMessage: 'Datum editace',
            }),
            datakey: 'updated',
            width: 200,
            CellComponent: TableFieldCells.DateCell,
          },
        ]}
        filtersFields={[
          {
            label: intl.formatMessage({
              id: 'ES__DRAFTS__COLUMN__NAME',
              defaultMessage: 'Název',
            }),
            datakey: 'name',
            filterkey: 'name',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterTextCell,
          },
        ]}
        onChange={
          callCopyApi as (selected: Draft | Draft[]) => Promise<undefined>
        }
        width={1000}
        source={drafts}
        multiple={true}
      />
    </>
  );
}
