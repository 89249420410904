import React from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bar: {
    fill: `${theme.palette.primary.main}77`,
    '&:hover': {
      fill: `${theme.palette.primary.main}`,
    },
  },
}));

export function DashboardBarChart({
  data,
  height = 400,
}: {
  data: { label: string; value: number }[];
  height?: number;
}) {
  const classes = useStyles();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        width={150}
        height={40}
        margin={{
          top: 50,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        data={data}
      >
        <Bar dataKey="value" className={classes.bar}>
          <LabelList position="top" fill="#000" />
        </Bar>
        <XAxis dataKey="label" allowDataOverflow={true} />
      </BarChart>
    </ResponsiveContainer>
  );
}
