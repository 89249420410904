import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ProceedingDurationData, ProceedingDurationDataInput } from '@models';
import { CourtFileType, EvidenceApiUrl, IntervalType } from '@enums';
import { StatisticCard } from '../card/card';
import { useStatistic } from '../statistics-hook';
import { Header } from './proceeding-duration-counts-header';
import { useProceedingDurationCountsVisualisation } from './proceeding-duration-counts-hook';

export function ProceedingDurationResolvedCounts() {
  const url = EvidenceApiUrl.STATISTICS_PROCEEDING_DURATION;

  const { data, params, setParams } = useStatistic<
    ProceedingDurationDataInput,
    ProceedingDurationData
  >({
    url,
    initialParams: {
      courtFileType: CourtFileType.SENATE,
      intervalType: IntervalType.ALL,
      resolved: true,
    },
  });

  const content = useProceedingDurationCountsVisualisation({
    data,
  });

  return (
    <Grid item xs={12}>
      <StatisticCard
        title="Délka řízení ve vyřízených soudních spisech"
        url={url}
        params={params}
        Header={() => <Header params={params} setParams={setParams} />}
        {...content}
      />
    </Grid>
  );
}
