import React, { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { useAutoCollapse } from '@components/form/misc/auto-collapse-hook';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { ChallengedAct } from '@models';
import { useChallengedActColumns } from './challenged-act-columns';
import { ChallengedActToolbar } from './challenged-act-toolbar';

export interface ChallengedActFieldProps {
  disabled: boolean;
  source?: ScrollableSource<ChallengedAct>;
}

export function ChallengedActField({
  disabled,
  source,
}: ChallengedActFieldProps) {
  const { panelRef } = useAutoCollapse(source?.items ?? []);

  const columns = useChallengedActColumns();

  const tableRef = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <ChallengedActToolbar {...props} ref={editRef} disabled={disabled} />
        );
      },
    [disabled]
  );

  if (!source) {
    return <> Missing CHALLENGED_ACTS_SOURCE </>;
  }

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__CHALLENGED_ACTS__PANEL_TITLE"
            defaultMessage="Napadené akty"
          />
        }
        ref={panelRef}
        expandable={true}
        summary={<FormTableCount source={source} />}
      >
        <RemoteTableField
          ref={tableRef}
          disabled={disabled}
          columns={columns}
          source={source}
          maxRows={15}
          ToolbarComponent={ToolbarComponent}
          onRowClick={(_, index) => {
            if (!disabled) {
              tableRef.current?.setSelectedIndex(index);
              editRef.current?.executeAction();
            }
          }}
          highlightSelectedRow={true}
          showDetailBtnCond={() => false}
        />
      </FormPanel>
    </>
  );
}
