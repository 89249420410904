import clsx from 'clsx';
import React, { useState } from 'react';
import {
  HelpItem,
  Section as SectionType,
  Subsection as SubsectionType,
} from '@models';
import { DECISIONS } from './decisions';
import { DICTIONARIES } from './dictionaries';
import { DISPATCHES, ELECTRONIC_DOCUMENT_DISPATCHES } from './dispatches';
import { DELIVERED_DOCUMENTS } from './documents/delivered';
import { INTERNAL_DOCUMENTS } from './documents/internal';
import { OUTGOING_DOCUMENTS } from './documents/outgoing';
import { ES_FILE_NUMBERS } from './es-file-numbers';
import { COURT_FILES } from './es-files/court-files';
import { DISCIPLINARY_PROCEEDINGS } from './es-files/disciplinary-proceedings';
import { INF_FILES } from './es-files/inf-files';
import { PLENARY_OPINIONS } from './es-files/plenary-opinions';
import { SPR_FILES } from './es-files/spr-files';
import { useStyles } from './help-styles';
import { NOTIFICATIONS } from './notifications';
import { SCREENING } from './screening';
import { STATISTICS_AND_REGISTERS } from './statistics';
import { TASKS } from './tasks';
import { USERS } from './users';
import { VIEWING_REQUESTS } from './viewing-requests';

const SECTIONS = [
  SCREENING,
  {
    title: 'Dokumenty',
    sections: [
      DELIVERED_DOCUMENTS,
      INTERNAL_DOCUMENTS,
      OUTGOING_DOCUMENTS,
      DISPATCHES,
      ELECTRONIC_DOCUMENT_DISPATCHES,
    ],
  },
  {
    title: 'Spisy',
    sections: [
      COURT_FILES,
      INF_FILES,
      SPR_FILES,
      DISCIPLINARY_PROCEEDINGS,
      PLENARY_OPINIONS,
    ],
  },
  DECISIONS,
  VIEWING_REQUESTS,
  TASKS,
  NOTIFICATIONS,
  STATISTICS_AND_REGISTERS,
  USERS,
  DICTIONARIES,
  ES_FILE_NUMBERS,
];

export function Help() {
  const classes = useStyles();

  const [selectedSection, setSelectedSection] = useState<HelpItem>(SECTIONS[0]);

  return (
    <div className={classes.page}>
      <div className={classes.navigation}>
        {SECTIONS.map((section) => (
          <label
            className={classes.navigationItem}
            key={section.title}
            onClick={() => setSelectedSection(section)}
          >
            <span
              className={clsx({
                [classes.selectedItem]: section.title === selectedSection.title,
              })}
            >
              {section.title}
            </span>
          </label>
        ))}
      </div>
      <div className={classes.help}>
        {selectedSection &&
          (selectedSection?.sections ?? [selectedSection]).map((section) => (
            <Section {...section} key={section.title} />
          ))}
      </div>
    </div>
  );
}

const Section = (section: SectionType) => {
  const classes = useStyles();

  return (
    <div className={classes.section} id={section.title} key={section.title}>
      <h2 className={classes.title}>
        <span className={classes.underline}>{section.title}</span>
      </h2>
      {(section?.subsections ?? []).length > 0 && section?.description && (
        <p className={classes.description}>{section.description}</p>
      )}
      {(section?.subsections ?? []).length > 0 ? (
        section.subsections!.map((subsection) => (
          <Subsection {...subsection} key={section.title} />
        ))
      ) : (
        <Subsection
          description={section.description}
          actions={section.actions}
        />
      )}
    </div>
  );
};

const Subsection = ({ title, description, actions }: SubsectionType) => {
  const classes = useStyles();

  return (
    <div key={title} className={classes.subsection}>
      {title && <h3 className={classes.subtitle}>{title}</h3>}
      {description && <p className={classes.description}>{description}</p>}
      <div className={classes.actions}>
        {(actions ?? []).map(({ name, description }) => (
          <div key={name}>
            <h4>{name}</h4>
            <span
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
