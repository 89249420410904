import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useCancelDialog } from './dialog-actions/cancel-hook';
import { useStartDialog } from './dialog-actions/start-hook';

export function JobsActionbar() {
  const { callApi: startApiCall, showStartButton } = useStartDialog();
  const { callApi: cancelApiCall, showCancelButton } = useCancelDialog();

  return (
    <>
      {showStartButton && (
        <DetailActionButton
          promptKey="START"
          buttonLabel="Spustit"
          dialogText="Skutečně chcete spustit úlohu?"
          dialogTitle="Varování"
          apiCall={startApiCall}
          ButtonComponent={PrimaryDetailActionbarButton}
        />
      )}
      {showCancelButton && (
        <DetailActionButton
          promptKey="Zastavit"
          buttonLabel="Zastavit"
          dialogTitle="Varování"
          dialogText="Zastavení běžící úlohy může spůsobit inkonzistenci dat. Skutečně chcete zastavit běžící úlohu?"
          apiCall={cancelApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
        />
      )}
    </>
  );
}
