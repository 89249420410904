import { abortableFetch, useEventCallback } from '@eas/common-web';
import { DocumentAttachment } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useAddAttachmentBulkDialog() {
  const callApi = useEventCallback((id: string, values: DocumentAttachment) =>
    abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/attachment/bulk`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(values),
    })
  );

  return {
    callApi,
  };
}
