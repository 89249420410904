import React, { useContext, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Filter,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldSpeedDialAction,
  TableFieldToolbarHandle,
  TableFieldToolbarProps,
  UserContext,
} from '@eas/common-web';
import { createRowStyle } from '@modules/task/tasks';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { Task } from '@models';
import { TaskState, TaskType } from '@enums';
import { useColumns } from './task-columns';
import { TaskToolbar } from './task-toolbar';

export interface TaskFieldProps {
  disabled: boolean;
  source?: ScrollableSource<Task>;
  preFilters: Filter[];
}

export function TaskField({ disabled, source, preFilters }: TaskFieldProps) {
  const { user } = useContext(UserContext);

  const intl = useIntl();

  const columns = useColumns();

  const tableRef = useRef<TableFieldHandle>(null);
  const toolbarRef = useRef<TableFieldToolbarHandle>(null);

  const actions: TableFieldSpeedDialAction<Task>[] = useMemo(
    () => [
      {
        Icon: VisibilityIcon,
        name: intl.formatMessage({
          id: 'ES__TASKS__VIEW_TASK',
          defaultMessage: 'Zobrazit úlohu',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('VIEW');
        },
      },
      {
        Icon: SentimentSatisfiedAltIcon,
        name: intl.formatMessage({
          id: 'ES__TASKS__PROCESS_TASK',
          defaultMessage: 'Změnit stav úlohy na Hotovo',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('PROCESS');
        },
        disabled: (task) => {
          return (
            task?.state !== TaskState.SOLVING ||
            (task?.type === TaskType.SYSTEM &&
              user.id !== task?.solver?.user?.id) ||
            (task?.type === TaskType.CUSTOM &&
              ![task?.solver?.user?.id, task?.createdBy?.id].includes(user.id))
          );
        },
      },
      {
        Icon: SentimentVeryDissatisfiedIcon,
        name: intl.formatMessage({
          id: 'ES__TASKS__CANCEL_TASK',
          defaultMessage: 'Změnit stav úlohy na Storno',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('CANCEL');
        },
        disabled: (task) => {
          return (
            task?.state !== TaskState.SOLVING ||
            (task?.type === TaskType.SYSTEM &&
              user.id !== task?.solver?.user?.id) ||
            (task?.type === TaskType.CUSTOM &&
              ![task?.solver?.user?.id, task?.createdBy?.id].includes(user.id))
          );
        },
      },
    ],
    [intl, user.id]
  );

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <TaskToolbar
            selectedIndex={props.selectedIndex}
            setSelectedIndex={props.setSelectedIndex}
            preFilters={preFilters}
            ref={toolbarRef}
            disabled={disabled}
          />
        );
      },
    [disabled, preFilters]
  );

  if (!source) {
    return <> Missing TASK_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage id="ES__TASKS__PANEL_TITLE" defaultMessage="Úlohy" />
      }
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        ref={tableRef}
        columns={columns}
        ToolbarComponent={ToolbarComponent}
        speedDialActions={actions}
        maxRows={7}
        onRowClick={() => {}}
        showSpeedDialCond={() => true}
        showRadioCond={() => false}
        showDetailBtnCond={() => false}
        createRowStyle={createRowStyle}
        source={source}
        disabled={disabled}
      />
    </FormPanel>
  );
}
