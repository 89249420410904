import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import {
  ApiFilterOperation,
  EvidenceContext,
  useEventCallback,
} from '@eas/common-web';
import { FilterState } from '../filter-types';
import { normalizeFilterState } from '../filter-utils';

export function useExtender({
  filtersState,
  setFiltersState,
}: {
  filtersState: FilterState[];
  setFiltersState: Dispatch<SetStateAction<FilterState[]>>;
}) {
  const { tableRef } = useContext(EvidenceContext);

  /**
   * Extender filters are editable. Stores index of currently edited extender.
   */
  const [activeExtender, setActiveExtender] = useState<number | undefined>();

  /**
   * Iterate over all extenders and each set as visible.
   */
  const activateAllExtenders = useEventCallback(() => {
    const filters = cloneDeep(filtersState);

    const extenders = filters[0].filters![0].filters;

    extenders?.forEach((e) => {
      e.visible = true;
    });

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  /**
   * Iterate over all extenders and each set as invisible.
   *
   * @param except Optionaly can exlude item from flagging as invisible.
   */
  const deactivateAllExtenders = useEventCallback((except?: number) => {
    const filters = cloneDeep(filtersState);

    const extenders = filters[0].filters![0].filters;

    extenders?.forEach((e, i) => {
      if (i !== except) {
        e.visible = false;
      } else {
        e.visible = true;
      }
    });

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  /**
   * Append extender into filter state.
   * If there is `activeExtender` replace filterState of `activeExtender`.
   *
   * @returns index of added / edited item.
   */
  const appendExtender = useEventCallback((newExtenders: FilterState[]) => {
    const filters = cloneDeep(filtersState);

    let extenders = filters[0].filters![0].filters;

    if (activeExtender !== undefined && extenders) {
      extenders = [
        ...extenders.slice(0, activeExtender),
        {
          ...extenders[activeExtender],
          filters: newExtenders,
        },
        ...extenders.slice(activeExtender + 1),
      ];

      filters[0].filters![0].filters = extenders;
    } else {
      extenders?.forEach((e) => {
        e.visible = false;
      });

      extenders?.push({
        filters: newExtenders,
        operation: ApiFilterOperation.AND,
        visible: true,
        confirmed: false,
      });
    }

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));

    return activeExtender ?? extenders!.length - 1;
  });

  /**
   * Flag extender as confirmed.
   */
  const confirmExtender = useEventCallback(() => {
    const filters = cloneDeep(filtersState);

    const extenders = filters[0].filters![0].filters;

    extenders![activeExtender!] = {
      ...extenders![activeExtender!],
      confirmed: true,
    };

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  /**
   * Toggle visibility of extender.
   */
  const toggleExtender = useEventCallback((index: number) => {
    const filters = cloneDeep(filtersState);

    const extenders = filters[0].filters![0].filters;

    extenders![index] = {
      ...extenders![index],
      visible: !extenders![index].visible,
    };

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  /**
   * Remove extender.
   */
  const removeExtender = useEventCallback((index: number) => {
    const filters = cloneDeep(filtersState);

    const extenders = filters[0].filters![0].filters;
    extenders?.splice(index, 1);

    if (index === activeExtender) {
      setActiveExtender(undefined);
    }

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  return {
    activeExtender,
    setActiveExtender,
    activateAllExtenders,
    deactivateAllExtenders,
    appendExtender,
    confirmExtender,
    toggleExtender,
    removeExtender,
  };
}
