import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  DetailToolbarBtnName,
} from '@eas/common-web';
import { EsFile } from '@models';
import { Agenda, UIElement } from '@enums';
import {
  useCourtFilePermission,
  useDisciplinaryProceedingsPermission,
  usePermission as useEsFilePermission,
  useInfFilePermission,
  usePlenaryOpinionPermission,
  useSprFilePermission,
} from './es-file-permission';

export function useEsFileMetadata<T extends EsFile>(esFile?: T) {
  const { source } = useContext<DetailHandle<EsFile>>(DetailContext);

  let usePermission = (esFile?: EsFile) => useEsFilePermission();
  let createPermission = '';
  let updatePermission = '';
  let deletePermission = '';
  let fileCoverPermission = '';
  let changeAgendaPermission = '';

  switch (source?.data?.agenda ?? esFile?.agenda) {
    case Agenda.COURT:
      usePermission = useCourtFilePermission;
      createPermission = UIElement.CourtFile.CREATE_COURT_FILE_BUTTON;
      updatePermission = UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON;
      deletePermission = UIElement.CourtFile.DELETE_COURT_FILE_BUTTON;
      fileCoverPermission = UIElement.CourtFile.CREATE_FILE_COVER_BUTTON;
      changeAgendaPermission =
        UIElement.CourtFile.UPDATE_COURT_FILE_AGENDA_BUTTON;
      break;
    case Agenda.SPR:
      usePermission = useSprFilePermission;
      createPermission = UIElement.SprFile.CREATE_SPR_FILE_BUTTON;
      updatePermission = UIElement.SprFile.UPDATE_SPR_FILE_BUTTON;
      deletePermission = UIElement.SprFile.DELETE_SPR_FILE_BUTTON;
      fileCoverPermission = UIElement.SprFile.CREATE_FILE_COVER_BUTTON;
      changeAgendaPermission = UIElement.SprFile.UPDATE_SPR_FILE_AGENDA_BUTTON;
      break;
    case Agenda.INF:
      usePermission = useInfFilePermission;
      createPermission = UIElement.InfFile.CREATE_INF_FILE_BUTTON;
      updatePermission = UIElement.InfFile.UPDATE_INF_FILE_BUTTON;
      deletePermission = UIElement.InfFile.DELETE_INF_FILE_BUTTON;
      fileCoverPermission = UIElement.InfFile.CREATE_FILE_COVER_BUTTON;
      changeAgendaPermission = UIElement.InfFile.UPDATE_INF_FILE_AGENDA_BUTTON;
      break;

    case Agenda.DISCIPLINARY_PROCEEDINGS:
      usePermission = useDisciplinaryProceedingsPermission;
      createPermission =
        UIElement.DisciplinaryProceedingsFile
          .CREATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON;
      updatePermission =
        UIElement.DisciplinaryProceedingsFile
          .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON;
      deletePermission =
        UIElement.DisciplinaryProceedingsFile
          .DELETE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON;
      fileCoverPermission =
        UIElement.DisciplinaryProceedingsFile.CREATE_FILE_COVER_BUTTON;
      changeAgendaPermission =
        UIElement.DisciplinaryProceedingsFile
          .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_AGENDA_BUTTON;
      break;
    case Agenda.PLENARY_OPINION:
      usePermission = usePlenaryOpinionPermission;
      createPermission =
        UIElement.PlenaryOpinionFile.CREATE_PLENARY_OPINION_FILE_BUTTON;
      updatePermission =
        UIElement.PlenaryOpinionFile.UPDATE_PLENARY_OPINION_FILE_BUTTON;
      fileCoverPermission =
        UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON;
      deletePermission =
        UIElement.PlenaryOpinionFile.DELETE_PLENARY_OPINION_FILE_BUTTON;
      break;

    default:
      break;
  }

  const { hasPermission } = usePermission(source?.data ?? esFile);

  // create permission is special, allowed only for predefined index role
  const { hasPermission: hasCreatePermission } = usePermission(esFile);

  return {
    hasPermission,
    hasChangeAgendaPermission: hasPermission(changeAgendaPermission),
    hasFileCoverPermission: hasPermission(fileCoverPermission, true),
    hasCreatePermission: hasCreatePermission(createPermission),
    hasUpdatePermission: hasPermission(updatePermission),
    hasDeletePermission: hasPermission(deletePermission),
  };
}

export function disableBtnFactory<T extends EsFile>(esFile?: T) {
  return function useDisableBtn(button: DetailToolbarBtnName) {
    const { isLocked } = useContext<DetailHandle<EsFile>>(DetailContext);

    const { hasCreatePermission, hasUpdatePermission } =
      useEsFileMetadata(esFile);

    if (isLocked) {
      return !['NEW', 'SHARE', 'REFRESH'].includes(button);
    }

    if (button === 'NEW' && !hasCreatePermission) {
      return true;
    }

    if (button === 'EDIT' && !hasUpdatePermission) {
      return true;
    }

    return false;
  };
}
