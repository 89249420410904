import { getErrorMessage } from '@utils/error-message';
import { noop } from 'lodash';
import React, { ComponentType, useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DetailActionButton,
  DetailToolbarButtonProps,
  SnackbarContext,
} from '@eas/common-web';
import { Messages } from '@enums';
import { VerifyDataBoxResultDialog } from './verify-databox-result-dialog';

interface VerifyDataBoxButtonProps {
  dataBoxId?: string;
  ButtonComponent: ComponentType<DetailToolbarButtonProps>;
}

export function VerifyDataBoxButton({
  dataBoxId,
  ButtonComponent,
}: VerifyDataBoxButtonProps) {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);

  return (
    <DetailActionButton
      promptKey="VERIFY_DATABOX"
      buttonLabel={intl.formatMessage({
        id: 'ES__VIEWING_REQUEST__ACTIONBAR__VERIFY_DATABOX',
        defaultMessage: 'Ověřit datovou schránku',
      })}
      dialogTitle={intl.formatMessage({
        id: 'ES__VIEWING_REQUEST__DIALOG_VERIFY_DATABOX__TITLE',
        defaultMessage: 'Ověření datové schránky',
      })}
      dialogText={undefined}
      FormFields={() => <VerifyDataBoxResultDialog dataBoxId={dataBoxId} />}
      ButtonComponent={ButtonComponent}
      dialogWidth={450}
      dialogShowConfirm={false}
      onSuccess={async () => noop()}
      refreshAfterClose={false}
      onError={async (err) => {
        const message = getErrorMessage(err, Messages.Subject.VERIFY_DATA_BOX);

        if (err.name !== 'AbortError') {
          showSnackbar(...message);
        }
      }}
    />
  );
}
