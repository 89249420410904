import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { grey } from '@material-ui/core/colors';
import {
  ApiFilterOperation,
  DetailContext,
  FormFieldContext,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldCells,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { AssigningRule } from '@models';
import { AssigningRuleToolbar } from './assigning-rule-toolbar';

export type AssigningRuleFieldProps = {
  source?: ScrollableSource<AssigningRule>;
};

export function AssigningRuleField({ source }: AssigningRuleFieldProps) {
  const detailCtx = useContext(DetailContext);

  const preFilters = useMemo(
    () => [
      {
        field: 'judge.id',
        operation: ApiFilterOperation.EQ,
        value: detailCtx?.source?.data?.id,
      },
    ],
    [detailCtx?.source?.data?.id]
  );

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return <AssigningRuleToolbar {...props} preFilters={preFilters} />;
      },
    [preFilters]
  );

  if (!source) {
    return <> Missing ASSIGNING_RULES_SOURCE </>;
  }

  return (
    <FormFieldContext.Provider value={{ disabled: false }}>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__JUDGES__DETAIL__PANEL_TITLE__ASSIGNING_RULES"
            defaultMessage="Pravidla přidělování"
          />
        }
        expandable={true}
      >
        <RemoteTableField
          source={source}
          columns={[
            {
              name: 'Platnost od',
              datakey: 'validFrom',
              sortkey: 'validFrom',
              width: 150,
              CellComponent: TableFieldCells.DateCell,
            },
            {
              name: 'Platnost do',
              datakey: 'validTo',
              sortkey: 'validTo',
              width: 150,
              CellComponent: TableFieldCells.DateCell,
            },
            {
              name: 'Pozastavit senátní',
              datakey: 'stopSenate',
              sortkey: 'stopSenate',
              width: 100,
              CellComponent: TableFieldCells.BooleanCell,
            },
            {
              name: 'Pozastavit plenární',
              datakey: 'stopPlenary',
              sortkey: 'stopPlenary',
              width: 100,
              CellComponent: TableFieldCells.BooleanCell,
            },
            {
              name: 'Ostatní pravidla',
              datakey: 'otherRules',
              width: 150,
              CellComponent: TableFieldCells.TextCell,
            },
          ]}
          ToolbarComponent={ToolbarComponent}
          maxRows={15}
          createRowStyle={(rule: AssigningRule) =>
            !rule.valid
              ? {
                  color: grey[400],
                }
              : {}
          }
        />
      </FormPanel>
    </FormFieldContext.Provider>
  );
}
