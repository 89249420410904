import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DictSettlementMethod } from '@models';

export function useColumns(): TableColumn<DictSettlementMethod>[] {
  const intl = useIntl();
  const { columnName, columnActive } =
    DictionaryEvidence.useDictionaryColumns<DictSettlementMethod>();

  const { agendas } = useContext(StaticDataContext);
  const useAgendas = () => useStaticListSource(agendas);

  return [
    columnName,
    columnActive,
    {
      datakey: 'agenda',
      sortkey: 'agenda.name',
      filterkey: 'agenda.id',
      name: intl.formatMessage({
        id: 'ES__DICT_SETTLEMENT_METHODS__TABLE__COLUMN__AGENDA',
        defaultMessage: 'Agenda',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useAgendas),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useAgendas),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}
