import { Params, useAutocompleteSource } from '@eas/common-web';
import { DictZipCodeAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useZipCodes(props?: {
  apiUrl?: EvidenceApiUrl;
  params?: Params;
}) {
  return useAutocompleteSource<DictZipCodeAutocomplete>({
    url: `${EvidenceApiUrl.DICT_ZIP_CODES}/autocomplete`,
    ...props,
  });
}
