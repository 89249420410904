const UNPROCESSED = {
  title: 'Nezpracované',
  actions: [
    { name: 'Kopie', description: 'Vytvoření kopie dokumentu.' },
    {
      name: 'Nový soudní spis',
      description:
        'Vytvoření nového Soudního spisu, jehož typ je ve výchozí hodnotě nastaven na <b>Senátní</b>. Dokument je přiřazen do spisu a přechází tak do stavu <b>Zpracovaný</b>.',
    },
    {
      name: 'Nové SPR',
      description:
        'Vytvoření nového SPR spisu. Dokument je přiřazen do spisu a přechází tak do stavu <b>Zpracovaný</b>.',
    },
    {
      name: 'Nové INF',
      description:
        'Vytvoření nového INF spisu. Dokument je přiřazen do spisu a přechází tak do stavu <b>Zpracovaný</b>.',
    },
    {
      name: 'Do spisu',
      description:
        'Zobrazení seznamu existujících spisů, do kterých je možné dokument přiřadit. Do vybraného spisu je následně dokument přiřazen a přechází tak do stavu <b>Zpracovaný</b>.',
    },
    {
      name: 'Vrátit',
      description:
        'Vrácení dokumentu zpět do eSSL a jeho smazání z aplikace ESS.',
    },
    {
      name: 'Lustrovat',
      description:
        'Provedení lustrace pro 1 konkrétní doručený dokument. Systém zobrazí modul lustraci, kde je zvolen vybraný dokument. Po provedení lustrace je vygenerován dokument , který se přiřadí k vybranému doručenému dokumentu jako přijatý dokument s přílohou.',
    },
    {
      name: 'Upravení dokumentu',
      description: 'Uživatel může upravovat jednotlivé atributy dokumenty.',
    },
  ],
};

const PROCESSED = {
  title: 'Zpracované',
  description:
    'Do tohoto stavu přechází spisy po přidělení spisových značek. V tomto stavu probíhá doplnění atributů na základě šablony generovaného odchozího dokumentu Akceptační dopis.',
  actions: [
    { name: 'Kopie', description: 'Vytvoření kopie dokumentu.' },
    {
      name: 'Upravení dokumentu',
      description:
        'Pouze u dokumentů, které jsou vázány na neuzavřený spis. Uživatel může upravovat jednotlivé atributy dokumenty.',
    },
    { name: 'Nová úloha', description: 'Vytvoření nové uživatelské úlohy.' },
  ],
};

export const DELIVERED_DOCUMENTS = {
  title: 'Doručené dokumenty',
  subsections: [UNPROCESSED, PROCESSED],
  description:
    'Doručené dokumenty představují dokumenty, které se do ESS dostaly ze spisové služby. Představují zprávy, které byly doručeny na Ústavní soud. Takovými zprávami mohou být datové správy (z ISDS - Informační systém datových schránek), mailové zprávy, či fyzické listinné zásilky. Doručené dokumenty mohou nabývat stavů: Nezpracovaný - Výchozí stav, doručený dokument není ještě zařazen do žádného spisu; Zpracovaný - Nastavuje se automaticky, vždy po zařazení dokumentu do spisu.',
};
