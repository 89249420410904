import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { useAddWriterDialog } from './dialog-actions/add-writer-hook';
import { useDeleteWriterDialog } from './dialog-actions/delete-writer-hook';
import { useEditWriterDialog } from './dialog-actions/edit-writer-hook';
import { FormFields } from './writer-dialog';
import { useValidationSchema } from './writer-schema';

export const WriterToolbar = forwardRef(function WritersToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
  }: TableFieldToolbarProps & { disabled: boolean },
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();

  const intl = useIntl();

  const { source } = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const writers = tableSource.items;

  const validationSchema = useValidationSchema();

  const { callApi: addWritersApiCall } = useAddWriterDialog();
  const { callApi: editWriterApiCall } = useEditWriterDialog();
  const { callApi: deleteWriterApiCall } = useDeleteWriterDialog();

  const selectedWriter =
    selectedIndex !== undefined ? writers?.[selectedIndex] : {};

  const isSelected = selectedIndex !== undefined;

  useEffect(
    () => {
      setSelectedIndex(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source]
  );

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_WRITER"
          apiCall={addWritersApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__ADD_WRITER',
            defaultMessage: 'Přidat pisatele',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__ADD_WRITER__TITLE',
            defaultMessage: 'Pisatel',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__ADD_WRITER__TEXT',
            defaultMessage: 'Vyplňte informace o pisateli.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__WRITERS_TOOLBAR__ADD_WRITER__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={FormFields}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          dialogWidth={700}
          formValidationSchema={validationSchema}
        />

        <DetailActionButton
          ref={ref}
          promptKey="EDIT_WRITER"
          apiCall={(id, values) =>
            editWriterApiCall(id, selectedWriter.id, values)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__EDIT_WRITER',
            defaultMessage: 'Editovat pisatele',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__EDIT_WRITER__TITLE',
            defaultMessage: 'Pisatel',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__EDIT_WRITER__TEXT',
            defaultMessage: 'Upravte informace o pisateli.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__WRITERS_TOOLBAR__EDIT_WRITER__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          dialogWidth={700}
          FormFields={FormFields}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formInitialValues={selectedWriter}
          formValidationSchema={validationSchema}
        />

        <DetailActionButton
          promptKey="DELETE_WRITER"
          apiCall={(id) => deleteWriterApiCall(id, selectedWriter.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__DELETE_WRITER',
            defaultMessage: 'Odstranit pisatele',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__DELETE_WRITER__TITLE',
            defaultMessage: 'Pisatel',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__WRITERS_TOOLBAR__DELETE_WRITER__TEXT',
            defaultMessage: 'Skutečně chcete odstranit pisatele?',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__WRITERS_TOOLBAR__DELETE_WRITER__BUTTON',
                  defaultMessage: 'Smazat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    ref: Ref<ActionButtonHandle>;
    disabled: boolean;
  }
) => ReactElement;
