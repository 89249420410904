import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  ActionButton,
  FormSelect,
  FormTextField,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useEventCallback } from '@eas/common-web';
import { useStyles as useTabsStyles } from '@composite/tabs/tabs-styles';
import { MonthlyStatisticOutputDataInput } from '@models';
import { Statistic } from '@enums';
import { useStyles } from '../card/dated-header';
import { useMonths } from '../statistics-api';

export const Header = ({
  params,
  setParams,
  selectedStatistic,
  setSelectedStatistic,
}: {
  params: MonthlyStatisticOutputDataInput;
  setParams: (params: MonthlyStatisticOutputDataInput) => void;
  selectedStatistic: Statistic;
  setSelectedStatistic: (statistic: Statistic) => void;
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const months = useMonths();

  const validationSchema = Yup.object().shape({
    month: Yup.string().nullable().required(),
    year: Yup.number().nullable().required(),
  });

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.dateWrapper}>
          <div className={classes.labelWrapper}>
            <Typography className={classes.dateLabel}>MĚSÍC</Typography>
            <Typography>
              {months.items.find((month) => month.id === params.month)?.name}
            </Typography>
          </div>
          <div className={classes.labelWrapper}>
            <Typography className={classes.dateLabel}>ROK</Typography>
            <Typography>{params.year}</Typography>
          </div>
        </div>
        <ActionButton
          promptKey="CHANGE_MONTH_YEAR"
          buttonLabel={intl.formatMessage({
            id: 'ES__STATISTICS__MONTH_YEAR',
            defaultMessage: 'Upravit parametry',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__STATISTICS__MONTH_YEAR__TITLE',
            defaultMessage: 'Úprava parametrů',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__STATISTICS__MONTH_YEAR__TEXT',
            defaultMessage: 'Vyplňte údaje.',
          })}
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton
              {...props}
              endIcon={<SettingsIcon />}
            />
          )}
          formInitialValues={params}
          FormFields={() => (
            <>
              <FormSelect
                source={months}
                valueIsId
                name="month"
                label="Měsíc"
                required
              />
              <FormTextField type="number" name="year" label="Rok" required />
            </>
          )}
          formValidationSchema={validationSchema}
          submitCallback={(values) => setParams(values)}
          dialogDisableBackdrop={true}
        />
      </div>
      <Tabs
        selectedStatistic={selectedStatistic}
        setSelectedStatistic={setSelectedStatistic}
      />
    </>
  );
};

const Tabs = ({
  selectedStatistic,
  setSelectedStatistic,
}: {
  selectedStatistic: Statistic;
  setSelectedStatistic: (statistic: Statistic) => void;
}) => {
  const classes = useTabsStyles('initial', 2)();

  const config = [
    {
      id: Statistic.SUBMISSION_COUNTS,
      label: 'Počty podání',
    },
    {
      id: Statistic.UNRESOLVED_BY_JUDGE_COUNTS,
      label: 'Nevyřízené soudní spisy',
    },
    {
      id: Statistic.SETTLEMENT_METHOD_COUNTS,
      label: 'Vyřízené spisy dle způsobu vyřízení',
    },
    {
      id: Statistic.ASSIGNED_AND_RESOLVED_SENATE_COUNTS,
      label: 'Přidělené a vyřízené senátní spisy',
    },
    {
      id: Statistic.ASSIGNED_AND_RESOLVED_PLENARY_COUNTS,
      label: 'Přidělené a vyřízené plenární spisy',
    },
    {
      id: Statistic.AVG_PROCEEDING_DURATION,
      label: 'Průměrná délka řízení',
    },
  ];

  const handleTabChange = useEventCallback((_, index: number) => {
    setSelectedStatistic(config[index].id);
  });

  return (
    <MuiTabs
      className={classes.tabs}
      variant="scrollable"
      value={config.findIndex((item) => item.id === selectedStatistic)}
      onChange={handleTabChange}
      classes={{
        indicator: classes.indicator,
        root: classes.tabsRoot,
        scrollButtons: classes.scrollButtons,
      }}
    >
      {config.map((tab, i) => {
        return (
          <MuiTab
            key={i}
            label={tab.label}
            classes={{
              root: classes.tabRoot,
            }}
          />
        );
      })}
    </MuiTabs>
  );
};
