import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { Job } from '@models';
import { useJobStates } from './job-api';

export function useColumns(): TableColumn<Job>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'ES__JOBS__TABLE__COLUMN__CREATED',
        defaultMessage: 'Datum vytvoření',
      }),
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: intl.formatMessage({
        id: 'ES__JOBS__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useJobStates
      ),
      valueMapper: TableCells.useSelectCellFactory(useJobStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}
