import { abortableFetch, useEventCallback } from '@eas/common-web';
import { Dispatch } from '@models';
import { EvidenceApiUrl, Messages } from '@enums';
import { useSubmitDispatchChange } from './add-dispatch-hook';

export function useEditDispatchDialog() {
  const callApi = useEventCallback((formData: Dispatch) => {
    return abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/${formData.id}`,
      {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          ...formData,
          recipient: formData.recipient,
        }),
      }
    );
  });

  const { handleSubmit } = useSubmitDispatchChange(
    callApi,
    Messages.Dispatch.UPDATE
  );

  return {
    handleSubmit,
  };
}
