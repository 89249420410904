import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useDeleteUserRoleDialog() {
  const callApi = useEventCallback((userRoleId: string) => {
    return abortableFetch(`${EvidenceApiUrl.USER_ROLE}/${userRoleId}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  });

  return {
    callApi,
  };
}
