import { useCreateDocx } from '@utils/docx';
import { Packer } from 'docx';
import saveAs from 'file-saver';
import { useContext } from 'react';
import { DetailContext, DetailMode } from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { createContent, createQuote } from '../decision-utils';

export function useDownloadDocxDialog() {
  const { source, mode } = useContext(DetailContext);
  const { decisionForms } = useContext(StaticDataContext);

  const content = createContent(source.data);
  const quote = createQuote(decisionForms, source.data);

  const doc = useCreateDocx({
    quote: quote,
    content: content,
    decision: source.data,
  });

  const handleDownload = () => {
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${slugify(source?.data?.popularName || 'Bez názvu')}.docx`);
    });
  };

  const showButton = mode === DetailMode.VIEW;

  return { showButton, handleDownload };
}

function slugify(
  str: string,
  options: {
    toLowerCase?: boolean;
    ignoreChars?: string[];
  } = {
    toLowerCase: true,
    ignoreChars: [],
  }
) {
  str = str.replace(/^\s+|\s+$/g, '');

  const ignoreChars = options.ignoreChars ?? [];

  // Make the string lowercase
  if (options.toLowerCase) {
    str = str.toLowerCase();
  }

  // Remove accents, swap ñ for n, etc
  let from =
    'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
  let to =
    'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';

  for (let i = 0, l = ignoreChars.length; i < l; i++) {
    const ignoreChar = ignoreChars[i];

    if (ignoreChar) {
      const index = from.indexOf(ignoreChar);
      from = from.substring(0, index) + from.substring(index + 1);
      to = to.substring(0, index) + to.substring(index + 1);
    }
  }

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  // Remove invalid chars
  const re = new RegExp(`[^a-zA-Z0-9 -${ignoreChars.join('')}]`, 'g');

  str = str
    .replace(re, '')
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-')
    // Collapse dashes
    .replace(/-+/g, '-');

  return str;
}
