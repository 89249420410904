import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    { key: 'esFile', label: 'Spis' },
    { key: 'clients', label: 'Klienti' },
    { key: 'legalAdviserType', label: 'Typ právního zástupce' },
    { key: 'subject', label: 'Subjekt' },
    { key: 'type', label: 'Typ účastníka' },
  ];
}
