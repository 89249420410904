import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonComponentProps,
  ApiFilterOperation,
  DetailContext,
  FormContext,
  FormInlineTableField,
  FormPanel,
  FormSelect,
  InlineTableFieldCells,
  TableFieldColumn,
  TableFieldToolbarButton,
  abortableFetch,
  useEventCallback,
  useFormSelector,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { Dispatch } from '@models';
import {
  DispatchEnvelopeType,
  DispatchMethod,
  DispatchState,
  EvidenceApiUrl,
} from '@enums';
import { useDispatchColumns } from '../dispatch-columns';

export const callApi = (
  id: string,
  values: { dispatches: (Dispatch & { envelopeType: DispatchEnvelopeType })[] }
) => {
  const envelopes = Object.fromEntries(
    values.dispatches.map((dispatch) => [dispatch.id, dispatch.envelopeType])
  );

  return abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/dispatch/envelope`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(envelopes),
  });
};

export function useChangeAllEnvelopeTypesValidationSchema() {
  return Yup.object().shape({
    type: Yup.string().nullable().required(),
  });
}

export function DispatchesToolbar() {
  const intl = useIntl();
  const classes = useStyles();

  const { setFieldValue } = useContext(FormContext);
  const { dispatchEnvelopeTypes } = useContext(StaticDataContext);

  const { dispatches } = useFormSelector(
    (values: {
      dispatches: (Dispatch & { envelopeType: DispatchEnvelopeType })[];
    }) => values
  );

  const useDispatchEnvelopeTypes = () =>
    useStaticListSource(dispatchEnvelopeTypes);
  const types = useDispatchEnvelopeTypes();

  const validationSchema = useChangeAllEnvelopeTypesValidationSchema();

  const handleChangeAll = useEventCallback((values) => {
    setFieldValue(
      'dispatches',
      dispatches.map((dispatch) => ({
        ...dispatch,
        envelopeType: values.type,
      }))
    );
  });

  return (
    <div className={classes.tableActions}>
      <ButtonGroup
        size="small"
        variant="outlined"
        className={classes.buttonGroup}
      >
        <DetailActionButton
          promptKey="CHANGE_ALL_ENVELOPE_TYPES"
          submitCallback={handleChangeAll}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCH__CHANGE_ALL_ENVELOPE_TYPES',
            defaultMessage: 'Nastavit typ hromadně',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCH__CHANGE_ALL_ENVELOPE_TYPES__TITLE',
            defaultMessage: 'Nastavit typ hromadně',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCH__CHANGE_ALL_ENVELOPE_TYPES__TEXT',
            defaultMessage: 'Vyberte typ obálky.',
          })}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={false}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__DOCUMENT_DISPATCH__CHANGE_ALL_ENVELOPE_TYPES__BUTTON',
                  defaultMessage: 'Nastavit typ hromadně',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={function Fields() {
            return (
              <FormSelect
                name="type"
                label={
                  <FormattedMessage
                    id="ES__DOCUMENT_DISPATCH__CHANGE_ALL_ENVELOPE_TYPES__FIELD_LABEL__TYPE"
                    defaultMessage="Typ obálky"
                  />
                }
                source={types}
                tooltipMapper={(o) => o.name}
                valueIsId={true}
              />
            );
          }}
          formValidationSchema={validationSchema}
          dialogWidth={700}
        />
      </ButtonGroup>
    </div>
  );
}

export function GenerateEnvelopesDialog() {
  const intl = useIntl();
  const { source } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);
  const { dispatchMethods, documentDispatchStates, dispatchEnvelopeTypes } =
    useContext(StaticDataContext);

  const useDispatchEnvelopeTypes = () =>
    useStaticListSource(dispatchEnvelopeTypes);

  const preColumns: TableFieldColumn<Dispatch>[] = [
    {
      name: intl.formatMessage({
        id: 'ES__DISPATCHES__COLUMN__ENVELOPE_TYPE',
        defaultMessage: 'Typ obálky',
      }),
      datakey: 'envelopeType',
      width: 200,
      CellComponent: InlineTableFieldCells.useInlineSelectCellFactory({
        dataHook: useDispatchEnvelopeTypes,
        collectionDatakey: 'dispatches',
        valueIsId: true,
      }),
    },
  ];

  const columns = useDispatchColumns({
    dispatchMethods,
    dispatchStates: documentDispatchStates,
    showRedirectInCurrent: false,
    preColumns,
  });

  const dispatchSource = useScrollableSource({
    url: `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/list`,
    params: {
      size: -1,
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          value: source.data.id,
          field: 'document.id',
        },
        {
          operation: ApiFilterOperation.EQ,
          value: DispatchMethod.LETTER,
          field: 'method.id',
        },
        {
          operation: ApiFilterOperation.EQ,
          value: DispatchState.PREPARED,
          field: 'state.id',
        },
      ],
    },
  });

  useEffect(() => {
    if (dispatchSource.items.length > 0) {
      setFieldValue('dispatches', dispatchSource.items);
    }
  }, [dispatchSource.items.length]);

  useEffect(() => {
    dispatchSource.loadMore();
  }, []);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DISPATCHES__PANEL_TITLE"
            defaultMessage="Vypravení"
          />
        }
        expandable={true}
        defaultExpanded={true}
        summary={<FormTableCount source={dispatchSource} />}
      >
        <FormInlineTableField
          name="dispatches"
          columns={columns}
          maxRows={7}
          ToolbarComponent={DispatchesToolbar}
          highlightSelectedRow={true}
          showDetailBtnCond={() => false}
          showRadioCond={() => false}
          labelOptions={{ hide: true }}
          withRemove={false}
          layoutOptions={{
            noSpacing: true,
          }}
        />
      </FormPanel>
    </>
  );
}
