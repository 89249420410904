import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { TextNote } from '@models';
import { EvidenceApiUrl } from '@enums';

const getResultObject = (url: EvidenceApiUrl, id: string, data: any) => {
  switch (url) {
    case EvidenceApiUrl.DECISIONS:
      return { decision: { id } };
    case EvidenceApiUrl.ES_FILES:
      return { esFile: { id, agenda: data.agenda } };
  }
};

export function useAddNoteDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback((id: string, formData: TextNote) => {
    const result = getResultObject(
      source.url as EvidenceApiUrl,
      id,
      source.data
    );

    return abortableFetch(`${source.url}/note`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        ...result,
        ...formData,
      }),
    });
  });

  return {
    callApi,
  };
}
