import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  FormDateTimeField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
} from '@eas/common-web';
import { AuthoredFields } from '@components/evidence/authored-evidence/authored-fields';
import { Job } from '@models';
import { JobState } from '@enums';
import { useEntityTypes, useJobStates, useJobTypes } from './job-api';

export function Fields() {
  const types = useJobTypes();
  const entityTypes = useEntityTypes();
  const jobStates = useJobStates();

  const { source } = useContext<DetailHandle<Job>>(DetailContext);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__JOBS__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormDateTimeField
          name="created"
          label={
            <FormattedMessage
              id="ES__JOBS__DETAIL__FIELD_LABEL__CREATED"
              defaultMessage="Datum vytvoření"
            />
          }
        />
        <AuthoredFields.CreatedBy />
        <FormDateTimeField
          name="startAfter"
          label={
            <FormattedMessage
              id="ES__JOBS__DETAIL__FIELD_LABEL__START_AFTER"
              defaultMessage="Začít akci po"
            />
          }
        />
        <FormDateTimeField
          name="executionStart"
          label={
            <FormattedMessage
              id="ES__JOBS__DETAIL__FIELD_LABEL__EXECUTION_START"
              defaultMessage="Začátek vykonávání úlohy"
            />
          }
        />
        <FormDateTimeField
          name="executionEnd"
          label={
            <FormattedMessage
              id="ES__JOBS__DETAIL__FIELD_LABEL__EXECUTION_END"
              defaultMessage="Konec vykonávání úlohy"
            />
          }
        />
        <FormSelect
          name="type"
          label={
            <FormattedMessage
              id="ES__JOBS__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ"
            />
          }
          source={types}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <FormSelect
          name="entity.type"
          label={
            <FormattedMessage
              id="ES__JOBS__DETAIL__FIELD_LABEL__ENTITY_TYPE"
              defaultMessage="Entita"
            />
          }
          source={entityTypes}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__JOBS__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav"
            />
          }
          source={jobStates}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
        />
      </FormPanel>
      {source.data?.state === JobState.FAILED ||
        (source.data?.state === JobState.FAILED_NO_RETRY && (
          <FormPanel
            label={
              <FormattedMessage
                id="ES__JOBS__DETAIL__PANEL_TITLE__ERROR"
                defaultMessage="Chyba"
              />
            }
            expandable={false}
          >
            <FormTextArea
              name="error.message"
              label={
                <FormattedMessage
                  id="ES__JOBS__DETAIL__FIELD_LABEL__MESSAGE"
                  defaultMessage="Chybová zpráva"
                />
              }
            />
            <FormTextField
              name="error.counter"
              type="number"
              label={
                <FormattedMessage
                  id="ES__JOBS__DETAIL__FIELD_LABEL__COUNTER"
                  defaultMessage="Počítadlo opakování"
                />
              }
            />
            <FormDateTimeField
              name="error.retryAfter"
              label={
                <FormattedMessage
                  id="ES__JOBS__DETAIL__FIELD_LABEL__RETRY_AFTER"
                  defaultMessage="Datum a čas příštího pokusu o zopakování akce"
                />
              }
            />
          </FormPanel>
        ))}
    </>
  );
}
