import { useFetch } from '@eas/common-web';
import { DictionaryObject } from '@models';
import { EvidenceApiUrl } from '@enums';

/**
 * List all available dispatch states.
 */
export function useStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/state/list`,
    {
      method: 'GET',
    }
  );
}

/**
 * List all available dispatch methods.
 */
export function useMethods() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/method/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available dispatch envelope types.
 */
export function useEnvelopeTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/envelope/type/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available dispatch delivery methods.
 */
export function useDispatchDeliveryMethods() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/method/delivery/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
