import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useUnassignDocumentDialog() {
  const callApi = useEventCallback((documentId: string) =>
    abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${documentId}/remove`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  return {
    callApi,
  };
}
