import React from 'react';
import { useIntl } from 'react-intl';
import { PrimaryDetailActionbarButton } from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useFormFields } from './dialog-actions/replace-user/replace-user-dialog';
import { useReplaceUserDialog } from './dialog-actions/replace-user/replace-user-hook';
import {
  ResultFields,
  useResultDialog,
} from './dialog-actions/replace-user/replace-user-result-dialog';
import { useValidationSchema } from './dialog-actions/replace-user/replace-user-schema';

export function ActionBar() {
  const intl = useIntl();

  const {
    callApi: replaceUserApiCall,
    handleConfirm,
    showButton: showReplaceUserButton,
  } = useReplaceUserDialog();

  const FormFields = useFormFields();
  const ResultDialog = useResultDialog(handleConfirm);
  const replaceUserValidationSchema = useValidationSchema();

  return (
    <>
      {showReplaceUserButton && (
        <DetailActionButton
          promptKey="REPLACE_USER"
          apiCall={replaceUserApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__USERS__ACTIONBAR__REPLACE_USER',
            defaultMessage: 'Nahradit zpracovatele',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__USERS__DIALOG_REPLACE_USER__TITLE',
            defaultMessage: 'Nahrazení zpracovatele',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__USERS__DIALOG_REPLACE_USER__TEXT',
            defaultMessage: 'Vyplňte doplňující informace.',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          FormFields={FormFields}
          dialogWidth={600}
          resultDialog={{
            title: intl.formatMessage({
              id: 'ES__USERS__ACTIONBAR__REPLACE_USER__RESULT__TITLE',
              defaultMessage: 'Nahrazení zpracovatele',
            }),
            FormFields: ResultFields,
            DialogComponent: ResultDialog,
          }}
          formValidationSchema={replaceUserValidationSchema}
        />
      )}
    </>
  );
}
