import React, { useContext, useMemo } from 'react';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { DeadlineField } from '@composite/deadlines/deadline-field';
import { TaskField } from '@composite/tasks/task-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { EsFile } from '@models';
import { EsFileState, UIElement } from '@enums';
import {
  DEADLINE_SOURCE,
  TASK_SOURCE,
} from '../../../es-file/es-file-hooked-sources';
import { useInfFilePermission } from '../../../es-file/es-file-permission';

export function TasksAndDeadlinesFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } =
    useContext<DetailHandle<EsFile>>(DetailContext);
  const { deadlineStates } = useContext(StaticDataContext);

  const { hasPermission } = useInfFilePermission(source.data);

  // eslint-disable-next-line prettier/prettier
    const disabledDeadlines =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.InfFile.TASKS_AND_DEADLINES);
  const deadlineSource = hookedSources[DEADLINE_SOURCE]?.source;

  // eslint-disable-next-line prettier/prettier
  const disabledTasks =
    isLocked ||
    mode !== DetailMode.VIEW ||
    source.data?.state === EsFileState.NEW ||
    !hasPermission(UIElement.InfFile.TASKS_AND_DEADLINES);
  const taskSource = hookedSources[TASK_SOURCE]?.source;

  const taskPreFilters = useMemo(
    () => [
      {
        operation: ApiFilterOperation.EQ,
        field: 'esFile.id',
        value: source.data?.id,
      },
    ],
    [source.data?.id]
  );

  return (
    <>
      <DeadlineField
        display="IN_ES_FILE"
        deadlineStates={deadlineStates}
        disabled={disabledDeadlines}
        source={deadlineSource}
      />
      <TaskField
        disabled={disabledTasks}
        source={taskSource}
        preFilters={taskPreFilters}
      />
    </>
  );
}
