import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  section: {
    margin: '10px 40px',
    paddingBottom: 30,

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${grey[300]}`,
    },
  },
  subsection: {
    alignItems: 'center',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    display: 'flex',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: 'white',
  },
  help: {
    fontSize: 14,
    fontWeight: 400,
    width: '100%',
    marginLeft: 300,
  },
  actions: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
  title: {
    marginTop: 20,
    width: '100%',
    fontWeight: 400,
    textAlign: 'center',
    display: 'inline-block',
  },
  underline: {
    display: 'inline-block',
    boxShadow: `0px 18px 0px -9.5px ${theme.palette.primary.main}`,
  },
  subtitle: {
    fontSize: 19,
    color: theme.palette.primary.main,
    marginTop: 30,
    fontWeight: 600,
  },
  navigation: {
    position: 'fixed',
    width: 300,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    gap: '15px',
    boxShadow: `1px 0 3px -1px ${grey[500]}`,
    backgroundColor: '#fafafa',
    zIndex: 1,
  },
  navigationItem: {
    fontSize: 14,
    cursor: 'pointer',
  },
  selectedItem: {
    fontWeight: 700,
    boxShadow: `0px 13px 0px -9px ${theme.palette.primary.main}`,
  },
  description: {
    marginTop: 20,
  },
}));
