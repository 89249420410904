import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistoryDescriptors } from '@modules/es-file/es-file-history-descriptors';
import { HistoryField } from '@composite/history/history-field';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { DecisionsFields } from './fields/decisions/decisions';
import { DocumentFields } from './fields/documents/documents';
import { DraftsFields } from './fields/drafts/drafts';
import { GeneralFields } from './fields/general/general';
import { IndexesFields } from './fields/indexes/indexes';
import { JudgesAndProcessorsFields } from './fields/judges-and-processors/judges-and-processors';
import { MemberFields } from './fields/members/members';
import { SimilarFilesFields } from './fields/similar-files/similar-files';
import { TasksAndDeadlinesFields } from './fields/tasks-and-deadlines/tasks-and-deadlines';
import { ViewingsAndLoansFields } from './fields/viewings-and-loans/viewings-and-loans';

export function Fields() {
  const historyDescriptors = useHistoryDescriptors();

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Obecné"
        />
      ),
      key: 'GENERAL',
      validationKeys: [
        'settlementMethods',
        'agenda',
        'deliveryDate',
        'proceedingDuration',
        'statsDate',
        'incomingNumber',
      ],
      content: <GeneralFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__MEMBERS"
          defaultMessage="Účastníci"
        />
      ),
      key: 'MEMBERS',
      content: <MemberFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__INDEXES"
          defaultMessage="Indexy"
        />
      ),
      key: 'INDEXES',
      content: <IndexesFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__DOCUMENTS"
          defaultMessage="Dokumenty"
        />
      ),
      key: 'DOCUMENTS',
      content: <DocumentFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__DECISIONS"
          defaultMessage="Rozhodnutí"
        />
      ),
      key: 'DECISIONS',
      content: <DecisionsFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__TASKS_AND_DEADLINES"
          defaultMessage="Úlohy a lhůty"
        />
      ),
      key: 'TASKS_AND_DEADLINES',
      content: <TasksAndDeadlinesFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__JUDGES_AND_PROCESSORS"
          defaultMessage="Soudci a zpracovatelé"
        />
      ),
      key: 'JUDGES_AND_PROCESSORS',
      content: <JudgesAndProcessorsFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__SIMILAR_FILES"
          defaultMessage="Obdobné spisy"
        />
      ),
      key: 'SIMILAR_FILES',
      content: <SimilarFilesFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__DRAFTS"
          defaultMessage="Koncepty"
        />
      ),
      key: 'DRAFTS',
      content: <DraftsFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__VIEWINGS_AND_LOANS"
          defaultMessage="Nahlížení a zápůjčky"
        />
      ),
      key: 'VIEWINGS_AND_LOANS',
      content: <ViewingsAndLoansFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__COURT_FILES__DETAIL__PANEL_TITLE__HISTORY"
          defaultMessage="Historie"
        />
      ),
      key: 'HISTORY',
      content: <HistoryField descriptors={historyDescriptors} />,
    },
  ];

  return <Tabs config={config} />;
}
