import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { AccessoryParticipant } from '@models';
import { EvidenceApiUrl, ParticipantType } from '@enums';

export function useAddAccessoryParticipantDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback(
    (id: string, formData: AccessoryParticipant) => {
      return abortableFetch(`${EvidenceApiUrl.ES_FILES}/${id}/participant`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          ...formData,
          type: ParticipantType.ACCESSORY_PARTICIPANT,
          esFile: source.data,
        }),
      });
    }
  );

  return {
    callApi,
  };
}
