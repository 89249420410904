import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
    {
      key: 'stopSenate',
      label: 'Pozastvení přidělování pro senátní',
    },
    {
      key: 'stopPlenary',
      label: 'Pozastavení přidělování pro plenární',
    },
    {
      key: 'assignXSenate',
      label: 'Přidělení x senátních a pozastavení procesu přidělování',
    },
    {
      key: 'skipEveryXSenate',
      label: 'Vynechání každého x tého kola přidělování senátních věcí',
    },
    {
      key: 'skipXSenate',
      label: 'Přeskočit x senátních',
    },
    {
      key: 'assignXPlenary',
      label: 'Přidělení x plenárních a pozastavení procesu přidělování',
    },
    {
      key: 'skipEveryXPlenary',
      label: 'Vynechání každého x tého kola přidělování plenárních věcí',
    },
    {
      key: 'skipXPlenary',
      label: 'Přeskočit x plenárních',
    },
  ];
}
