import { DictionaryObject, eqFilterParams, useFetch } from '@eas/common-web';
import { Participant, ViewingRequest } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useViewingMethods() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.VIEWING}/method/list`, {
    method: 'GET',
  });
}

export function useViewingRequestStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.VIEWING_REQUEST}/state/list`,
    {
      method: 'GET',
    }
  );
}

export async function postProcess(item: ViewingRequest) {
  const response = await fetch(
    `${EvidenceApiUrl.ES_FILES}/${item.esFile?.id}/participant/list`,
    {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        ...eqFilterParams({
          field: 'contact.dataBox.identifier',
          value: item.dataBoxId,
        }),
      }),
    }
  );

  const participantTypes = await response.json();

  return {
    ...item,
    participantTypes: (participantTypes.items ?? []).map(
      (participant: Participant) => participant.type
    ),
  };
}
