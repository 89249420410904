import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  EvidenceContext,
  FormCheckbox,
  FormContext,
  FormPanel,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { DecisionField } from '@composite/decisions/decision-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { AnonymizationRequest, UIElement } from '@enums';
import { DECISION_SOURCE } from '../../../es-file/es-file-hooked-sources';

export function DecisionFields({
  hasPermission,
}: {
  hasPermission: (element: string) => boolean;
}) {
  const { hookedSources } = useContext(EvidenceContext);
  const { getFieldValues, setFieldValue } = useContext(FormContext);
  const { anonymizationRequests, decisionForms, decisionStates } = useContext(
    StaticDataContext
  );

  const anonymizationRequestsSource = useStaticListSource(
    anonymizationRequests
  );

  const decisionSource = hookedSources[DECISION_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__ES_FILES___DETAIL__PANEL_TITLE_ANONYMIZATION"
            defaultMessage="Anonymizace"
          />
        }
      >
        <FormCheckbox
          name="anonymize"
          label={
            <FormattedMessage
              id="ES__ES_FILES__DETAIL__FIELD_LABEL__ANONYMIZE"
              defaultMessage="Anonymizovat"
            />
          }
          disabled={!hasPermission(UIElement.EsFileCommon.ANONYMIZE)}
        />
        <FormSelect
          name="anonymizationRequest"
          source={anonymizationRequestsSource}
          label={
            <FormattedMessage
              id="ES__ES_FILES__DETAIL__FIELD_LABEL__ANONYMIZATION_REQUEST"
              defaultMessage="Žádost o anonymizaci"
            />
          }
          notifyChange={() => {
            const anonymizationRequest = getFieldValues().anonymizationRequest;
            if (anonymizationRequest === AnonymizationRequest.GRANTED) {
              setFieldValue('anonymize', true);
            } else if (anonymizationRequest === AnonymizationRequest.REJECTED) {
              setFieldValue('anonymize', false);
            }
          }}
          valueIsId={true}
          disabled={
            !hasPermission(UIElement.EsFileCommon.ANONYMIZATION_REQUEST)
          }
        />
      </FormPanel>

      <DecisionField
        source={decisionSource}
        decisionForms={decisionForms}
        decisionStates={decisionStates}
      />
    </>
  );
}
