import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TreeObject } from '@models';

const useStyles = makeStyles({
  disabledComponent: {
    lineHeight: '22px',
    height: 22,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: 15,
  },
});

function buildPath(obj: TreeObject) {
  const parents: string[] = [];

  const prependParent = (obj: TreeObject) => {
    if (obj.parent) {
      prependParent(obj.parent);
    }

    parents.push(obj.label);
  };

  prependParent(obj);
  parents.pop();

  if (parents.length === 0) {
    return '';
  }

  return `(${parents.join(' / ')})`;
}

export function TreeDisabledComponent(props: {
  value: TreeObject[];
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}) {
  const classes = useStyles();

  return (
    <>
      {props.value?.map((v, i) => (
        <Typography
          key={v.id}
          variant="body1"
          className={classes.disabledComponent}
        >
          {i + 1}. {v.label} <i>{buildPath(v)}</i>
        </Typography>
      ))}
    </>
  );
}
