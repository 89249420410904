import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import {
  ApiFilterOperation,
  DetailContext,
  FieldFilter,
  Filter,
  RemoteTableFieldContext,
  SnackbarContext,
  TableFieldToolbarButton,
  TableFieldToolbarButtons,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { ErrorObject } from '@models';
import { Messages } from '@enums';
import { useAssignRoleDialog } from './dialog-actions/assign-role-hook';
import { useMakeRoleDefaultDialog } from './dialog-actions/make-role-default-hook';
import { useUnassignRoleDialog } from './dialog-actions/unassign-role-hook';
import { useUserDefaultRoleDialog } from './user-default-role-dialog';
import { UserFieldProps } from './user-field';
import { useUserRoleDialog } from './user-role-dialog';
import {
  useDefaultRoleValidationSchema,
  useRoleValidationSchema,
} from './user-schema';

type UserFieldToolbarProps = Omit<UserFieldProps, 'source'> &
  TableFieldToolbarProps & {
    preFilters: Filter[];
  };

export function UserToolbar({
  selectedIndex,
  setSelectedIndex,
  agendas,
  defaultableAgendas,
  preFilters,
  withAgendaFields,
  withDefaultUserFields,
}: UserFieldToolbarProps) {
  const classes = useStyles();

  const { showSnackbar } = useContext(SnackbarContext);
  const { source } = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const users = tableSource.items;

  const selectedUser =
    selectedIndex !== undefined ? users?.[selectedIndex] : {};

  useEffect(() => {
    setSelectedIndex(undefined);
  }, [source]);

  const intl = useIntl();

  const { callApi: assignRoleApiCall } = useAssignRoleDialog();
  const { callApi: unassignRoleApiCall } = useUnassignRoleDialog();
  const { callApi: makeRoleDefaultApiCall } = useMakeRoleDefaultDialog();

  const DefaultRoleFormFields = useUserDefaultRoleDialog({
    agendas: defaultableAgendas,
  });
  const RoleFormFields = useUserRoleDialog({ agendas });
  const roleValidationSchema = useRoleValidationSchema();
  const defaultRolevalidationSchema = useDefaultRoleValidationSchema();

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ASSIGN_ROLE"
          apiCall={assignRoleApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE',
            defaultMessage: 'Přidat uživatelskou roli',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE__TITLE',
            defaultMessage: 'Uživatelská role',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE__TEXT',
            defaultMessage: 'Vyplňte informace o uživatelské roli.',
          })}
          FormFields={RoleFormFields}
          formValidationSchema={roleValidationSchema}
          dialogWidth={600}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={false}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          onError={async (err: ErrorObject<'USER_ROLE_ALREADY_EXISTS'>) => {
            if (err.code) {
              const error =
                Messages.Role.ASSIGN_ROLE?.[err.code] ??
                Messages.Role.ASSIGN_ROLE.ERROR;
              showSnackbar(...error);
            }
          }}
        />
        {withAgendaFields && (
          <DetailActionButton
            promptKey="MAKE_ROLE_DEFAULT"
            apiCall={(_, formData) =>
              makeRoleDefaultApiCall(selectedUser.id, formData)
            }
            buttonLabel={intl.formatMessage({
              id: 'ES__USER_ROLES_TOOLBAR__MAKE_ROLE_DEFAULT',
              defaultMessage: 'Přednastavit uživatelskou roli',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__USER_ROLES_TOOLBAR__MAKE_ROLE_DEFAULT__TITLE',
              defaultMessage: 'Uživatelská role',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__USER_ROLES_TOOLBAR__MAKE_ROLE_DEFAULT__TEXT',
              defaultMessage: 'Vyberte agendu.',
            })}
            FormFields={DefaultRoleFormFields}
            formValidationSchema={defaultRolevalidationSchema}
            dialogWidth={600}
            ButtonComponent={({ onClick, label }) => (
              <TableFieldToolbarButton
                IconComponent={ControlPointIcon}
                show={true}
                disabled={selectedIndex === undefined}
                title={label}
                onClick={onClick}
                color="primary"
                variant="contained"
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE__BUTTON',
                    defaultMessage: 'Přednastavit',
                  })}
                </Typography>
              </TableFieldToolbarButton>
            )}
          />
        )}
        {withDefaultUserFields && (
          <DetailActionButton
            promptKey="MAKE_USER_DEFAULT"
            apiCall={() => makeRoleDefaultApiCall(selectedUser.id)}
            buttonLabel={intl.formatMessage({
              id: 'ES__USER_ROLES_TOOLBAR__MAKE_USER_DEFAULT',
              defaultMessage: 'Nastavit výchozího uživatele',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__USER_ROLES_TOOLBAR__MAKE_USER_DEFAULT__TITLE',
              defaultMessage: 'Výchozí uživatel',
            })}
            dialogText={intl.formatMessage(
              {
                id: 'ES__USER_ROLES_TOOLBAR__MAKE_USER_DEFAULT__TEXT',
                defaultMessage:
                  'Skutečně chcete pro tuto roli nastavit výchozího uživatele {user} ?',
              },
              {
                user: <b>{selectedUser.label}</b>,
              }
            )}
            dialogWidth={400}
            ButtonComponent={({ onClick, label }) => (
              <TableFieldToolbarButton
                IconComponent={ControlPointIcon}
                show={true}
                disabled={selectedIndex === undefined}
                title={label}
                onClick={onClick}
                color="primary"
                variant="contained"
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'ES__USER_ROLES_TOOLBAR__MAKE_USER_DEFAULT__BUTTON',
                    defaultMessage: 'Výchozí',
                  })}
                </Typography>
              </TableFieldToolbarButton>
            )}
          />
        )}
        <DetailActionButton
          promptKey="UNASSIGN_ROLE"
          apiCall={() => unassignRoleApiCall(selectedUser.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__UNASSIGN_ROLE',
            defaultMessage: 'Odstranit uživatelskou roli',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__UNASSIGN_ROLE__TITLE',
            defaultMessage: 'Uživatelská role',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__UNASSIGN_ROLE__TEXT',
            defaultMessage: 'Skutečně chcete odstranit uživatelskou roli?',
          })}
          dialogWidth={600}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={selectedIndex === undefined}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__USER_ROLES_TOOLBAR__DELETE_USER_ROLE__BUTTON',
                  defaultMessage: 'Smazat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={tableSource}
          preFilters={preFilters}
          filtersFields={[
            {
              label: 'Uživatelské jméno',
              datakey: 'user.username',
              filterkey: 'user.username',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Email',
              datakey: 'user.email',
              filterkey: 'user.email',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Jméno',
              datakey: 'user.firstName',
              filterkey: 'user.firstName',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Příjmení',
              datakey: 'user.lastName',
              filterkey: 'user.lastName',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Telefon',
              datakey: 'user.phone',
              filterkey: 'user.phone',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Tituly před',
              datakey: 'user.titlesBefore',
              filterkey: 'user.titlesBefore',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Tituly za',
              datakey: 'user.titlesAfter',
              filterkey: 'user.titlesAfter',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
          ]}
        />
      </ButtonGroup>
    </div>
  );
}
