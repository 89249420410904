import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormCustomField,
  FormPanel,
  FormTextField,
  TextArea,
  useFormSelector,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { CourtFile, Document, InfFile, SprFile } from '@models';
import { Agenda, EvidenceBrowserUrl } from '@enums';

export function EsFileFields() {
  const { esFile } = useFormSelector((data: Document) => ({
    esFile: data.esFile,
  }));

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__DOCUMENT__DETAIL__ES_FILE_PANEL_TITLE"
          defaultMessage="Spis"
        />
      }
    >
      <FormTextField
        name="esFile.number.value"
        label={
          <FormattedMessage
            id="ES__DOCUMENT__DETAIL__FIELD_LABEL__FILE_NUMBER"
            defaultMessage="Spisová značka"
          />
        }
        disabled={true}
        after={
          esFile?.id && (
            <Redirect url={EvidenceBrowserUrl.ES_FILES_ALL} id={esFile.id} />
          )
        }
      />
      {esFile?.agenda === Agenda.COURT && (
        <>
          <FormCustomField
            name="esFile.proposers"
            label={
              <FormattedMessage
                id="ES__DOCUMENT__DETAIL__FIELD_LABEL__PROPOSERS"
                defaultMessage="Navrhovatel"
              />
            }
            disabled={true}
          >
            <TextArea
              disabled={true}
              value={(esFile as CourtFile).proposers
                ?.map((proposer) => proposer.label)
                .join(', ')}
            />
          </FormCustomField>
          <FormCustomField
            label={
              <FormattedMessage
                id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__OPPONENT"
                defaultMessage="Odpůrce"
              />
            }
          >
            <TextArea
              disabled={true}
              value={(esFile as CourtFile).challengedActs
                ?.map((c) => c.label)
                .join(', ')}
            />
          </FormCustomField>
        </>
      )}
      {esFile?.agenda && [Agenda.INF, Agenda.SPR].includes(esFile?.agenda) && (
        <FormCustomField
          name="esFile.writers"
          label={
            <FormattedMessage
              id="ES__DOCUMENT__DETAIL__FIELD_LABEL__WRITERS"
              defaultMessage="Pisatel"
            />
          }
          disabled={true}
        >
          <TextArea
            disabled={true}
            value={(esFile as SprFile | InfFile).writers
              ?.map((writer) => writer.label)
              .join('\n')}
          />
        </FormCustomField>
      )}
    </FormPanel>
  );
}
