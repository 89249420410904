import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HistoryIcon from '@material-ui/icons/History';
import {
  TableCellProps,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableContext,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { TableActionButton } from '@components/action-button/table-action-button';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteColumnMapper } from '@components/form/misc/label-mappers';
import { AssignedFileNumber } from '@models';
import { useDeleteAssignedFileNumberDialog } from './dialog-actions/delete-assigned-file-numer-hook';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: 'black',
    },
  },
}));

function RevertColumn({ rowValue, index }: TableCellProps<AssignedFileNumber>) {
  const intl = useIntl();
  const { callApi } = useDeleteAssignedFileNumberDialog();
  const classes = useStyles();
  const { source, refresh } = useContext(TableContext);

  const count = source.items.filter(
    (item) => item.assignedTime >= rowValue.assignedTime!
  ).length;
  const date = new Date(rowValue.assignedTime!).toLocaleString();

  return (
    <TableActionButton
      promptKey={`DELETE_ASSIGNED_FILE_NUMBERS_${index}`}
      apiCall={() => callApi(rowValue.id)}
      buttonLabel=""
      dialogTitle={intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__DIALOG_DELETE_ASSIGNED_FILE_NUMBERS__TITLE',
        defaultMessage: 'Vrácení přidělení spisových značek',
      })}
      dialogText={intl.formatMessage(
        {
          id: 'ES__ASSIGNED_FILE_NUMBERS__DIALOG_DELETE_ASSIGNED_FILE_NUMBERS__TEXT',
          defaultMessage:
            'Opravdu chcete vrátit přidělení spisových značek?{break}Budou vráceny všechny značky přidělné od {date}.{break}Počet značek k vrácení: {count}',
        },
        { date: <b>{date}</b>, break: <br />, count: <b>{count}</b> }
      )}
      ButtonComponent={(props) => (
        <HistoryIcon
          fontSize="small"
          className={classes.icon}
          onClick={(e) => {
            props.onClick();
            e.stopPropagation();
          }}
        />
      )}
      onError={async () => refresh()}
      dialogWidth={500}
    />
  );
}

export function useColumns(): TableColumn<AssignedFileNumber>[] {
  const intl = useIntl();

  const { courtFileTypes } = useContext(StaticDataContext);
  const useCourtFileTypes = () => useStaticListSource(courtFileTypes);

  return [
    {
      datakey: 'revert',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__REVERT',
        defaultMessage: 'Vrátit',
      }),
      width: 100,
      CellComponent: RevertColumn,
      align: TableColumnAlign.LEFT,
    },
    {
      datakey: 'assignedTime',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__ASSIGNED_TIME',
        defaultMessage: 'Datum a čas přidělení',
      }),
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'number',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'esFile.incomingNumber',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__INCOMING_NUMBER',
        defaultMessage: 'Došlé číslo',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'judge',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: autocompleteColumnMapper,
    },
    {
      datakey: 'proposer',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__PROPOSER',
        defaultMessage: 'Navrhovatel',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: autocompleteColumnMapper,
    },
    {
      datakey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__SUBMISSION_DATE',
        defaultMessage: 'Datum doručení',
      }),
      width: 200,
      CellComponent: TableCells.DateCell,
    },
    {
      datakey: 'lastSenate',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__LAST_SENATE',
        defaultMessage: 'Poslední senát',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: autocompleteColumnMapper,
    },
    {
      datakey: 'type',
      filterkey: 'type.id',
      sortkey: 'type.name',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__TYPE',
        defaultMessage: 'Typ',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useCourtFileTypes),
      valueMapper: TableCells.useSelectCellFactory(useCourtFileTypes),
    },
    {
      datakey: 'order',
      name: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__COLUMN__ORDER',
        defaultMessage: 'Pořadí',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
    },
  ];
}
