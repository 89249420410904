import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

/**
 * List all available user substitution states.
 */
export function useStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.USER_SUBSTITUTIONS}/state/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
