import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictBarcodePrinter } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { useColumns } from './dict-barcode-printer-columns';
import { Fields } from './dict-barcode-printer-fields';
import { useValidationSchema } from './dict-barcode-printer-schema';

export function DictBarcodePrinters() {
  const validationSchema = useValidationSchema();
  const intl = useIntl();

  const evidence = useDictionaryEvidence<DictBarcodePrinter>({
    version: 2,
    identifier: 'BARCODE_PRINTERS',
    apiProps: {
      url: EvidenceApiUrl.DICT_BARCODE_PRINTERS,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.BARCODE_PRINTER,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_BARCODE_PRINTERS__TABLE__TITLE',
        defaultMessage: 'Tiskárny čárových kódů',
      }),
      reportTag: ExportTags.DICT_BARCODE_PRINTERS,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.BARCODE_PRINTER,
      validationSchema,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_BARCODE_PRINTERS__DETAIL__TITLE',
          defaultMessage: 'Tiskárna čárových kódů',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
