import React, { ComponentType, ReactNode, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  FormCheckbox,
  FormContext,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextField,
  useEventCallback,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import {
  ContactField,
  ContactFieldProps,
} from '@composite/contact/contact-field';
import { HISTORY_SOURCE } from '@composite/history/history-api';
import { HistoryField } from '@composite/history/history-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Subject } from '@models';
import { SubjectType } from '@enums';
import { SubjectHistoryContext } from './subject-history-context';
import { useHistoryDescriptors } from './subject-history-descriptors';

export function Fields({
  HistoryFields,
  display,
  contactsProps,
  panelLabel,
}: {
  HistoryFields?: ComponentType;
  display: 'IN_SUBJECT' | 'OUT_OF_SUBJECT';
  contactsProps?: Omit<ContactFieldProps, 'display' | 'disabled'>;
  panelLabel: ReactNode;
}) {
  const { getFieldValues, setFieldValue } = useContext(FormContext);
  const { subjectTypes } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);

  const { type } = useFormSelector((values: Subject) => ({
    type: values.type,
    contacts: values.contacts ?? [],
  }));

  const subjectTypeSource = useStaticListSource(subjectTypes);

  const disabledNaturalPersonFields =
    !type ||
    ![
      SubjectType.NATURAL_PERSON,
      SubjectType.UNSPECIFIED,
      SubjectType.BUSINESS_NATURAL_PERSON,
    ].includes(type);

  const handleTypeChange = useEventCallback(() => {
    const values = getFieldValues();

    if (values?.type === SubjectType.NATURAL_PERSON) {
      ['companyName', 'ico', 'function', 'dic', 'isPublicAuthority'].forEach(
        (field) => setFieldValue(field, undefined)
      );
    } else if (
      values?.type &&
      [SubjectType.LEGAL_ENTITY].includes(values?.type)
    ) {
      [
        'firstName',
        'lastName',
        'maidenName',
        'titleAfter',
        'titleBefore',
        'birthDate',
        'birthPlace',
        'birthNumber',
      ].forEach((field) => setFieldValue(field, undefined));
    }
  });

  return (
    <>
      <FormPanel label={panelLabel} expandable={false}>
        <FormSelect
          name="type"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ subjektu"
            />
          }
          source={subjectTypeSource}
          valueIsId={true}
          notifyChange={handleTypeChange}
          required
        />
        <FormTextField
          name="companyName"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Název"
            />
          }
          disabled={!type || type === SubjectType.NATURAL_PERSON}
          required={
            type &&
            [
              SubjectType.BUSINESS_NATURAL_PERSON,
              SubjectType.LEGAL_ENTITY,
              SubjectType.UNSPECIFIED,
            ].includes(type)
          }
        />
        <FormTextField
          name="ico"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ICO"
              defaultMessage="IČO"
            />
          }
          disabled={!type || type === SubjectType.NATURAL_PERSON}
        />
        <FormTextField
          name="dic"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__DIC"
              defaultMessage="DIČ"
            />
          }
          disabled={
            !type ||
            ![
              SubjectType.LEGAL_ENTITY,
              SubjectType.BUSINESS_NATURAL_PERSON,
              SubjectType.UNSPECIFIED,
            ].includes(type)
          }
        />
        <FormCheckbox
          name="isPublicAuthority"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__IS_PUBLIC_AUTHORITY"
              defaultMessage="Orgán veřejné moci"
            />
          }
          disabled={
            !type ||
            ![
              SubjectType.LEGAL_ENTITY,
              SubjectType.BUSINESS_NATURAL_PERSON,
              SubjectType.UNSPECIFIED,
            ].includes(type)
          }
        />
        <FormTextField
          name="function"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__FUNCTION"
              defaultMessage="Funkce"
            />
          }
          disabled={!type || type === SubjectType.NATURAL_PERSON}
        />
        <FormTextField
          name="otherIdentification"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__OTHER_IDENTIFICATION"
              defaultMessage="Jiná identifikace"
            />
          }
        />
        <FormTextField
          name="firstName"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__FIRST_NAME"
              defaultMessage="Jméno"
            />
          }
          disabled={disabledNaturalPersonFields}
          required={
            type === SubjectType.NATURAL_PERSON ||
            type === SubjectType.UNSPECIFIED
          }
        />
        <FormTextField
          name="lastName"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LAST_NAME"
              defaultMessage="Příjmení"
            />
          }
          disabled={disabledNaturalPersonFields}
          required={
            type === SubjectType.NATURAL_PERSON ||
            type === SubjectType.UNSPECIFIED
          }
        />
        <FormTextField
          name="maidenName"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__MAIDEN_NAME"
              defaultMessage="Rodné příjmení"
            />
          }
          disabled={disabledNaturalPersonFields}
        />
        <FormTextField
          name="titleBefore"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TITLE_BEFORE"
              defaultMessage="Titul před"
            />
          }
          disabled={disabledNaturalPersonFields}
        />
        <FormTextField
          name="titleAfter"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TITLE_AFTER"
              defaultMessage="Titul za"
            />
          }
          disabled={disabledNaturalPersonFields}
        />
        <FormLocalDateField
          name="birthDate"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_DATE"
              defaultMessage="Datum narození"
            />
          }
          disabled={disabledNaturalPersonFields}
          maxDatePicker={new Date().toISOString()}
        />
        <FormTextField
          name="birthNumber"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_NUMBER"
              defaultMessage="Rodné číslo"
            />
          }
          disabled={disabledNaturalPersonFields}
        />
        <FormTextField
          name="birthPlace"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_PLACE"
              defaultMessage="Místo narození"
            />
          }
          disabled={disabledNaturalPersonFields}
        />
        <FormTextField
          name="lawyerEvidenceNumber"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LAWYER_EVIDENCE_NUMBER"
              defaultMessage="Evidenční číslo České advokátní komory"
            />
          }
        />
        <FormCheckbox
          name="active"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ACTIVE"
              defaultMessage="Aktivní"
            />
          }
          disabled={true}
        />
      </FormPanel>
      <ContactField display={display} {...contactsProps} />
      {HistoryFields && mode !== DetailMode.NEW && <HistoryFields />}
    </>
  );
}

export function FieldsWithHistory() {
  const { hookedSources } = useContext(EvidenceContext);
  const historySource = hookedSources[HISTORY_SOURCE]?.source;

  const historyDescriptors = useHistoryDescriptors();

  const HistoryFields = () => <HistoryField descriptors={historyDescriptors} />;

  return (
    <SubjectHistoryContext.Provider value={{ historySource }}>
      <Fields
        HistoryFields={HistoryFields}
        display="IN_SUBJECT"
        panelLabel={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
      />
    </SubjectHistoryContext.Provider>
  );
}
