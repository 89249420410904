import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailMode,
  FormCustomField,
  FormDictionaryField,
  useFormSelector,
} from '@eas/common-web';
import { dictionaryFieldLabelMapper } from '@components/form/misc/label-mappers';
import { PlenaryOpinionFile } from '@models';
import { Agenda, UIElement } from '@enums';
import { useEsFileListSource, useEsFiles } from '../../../es-file/es-file-api';
import { usePlenaryOpinionPermission } from '../../../es-file/es-file-permission';
import {
  useCourtFileColumns,
  useCourtFileFilterColumns,
} from '../../plenary-opinion-file-columns';
import { CourtFileField } from './source-file-field';

export function ChallengedFileField() {
  const { mode, source } = useContext(DetailContext);

  const { challengedFiles } = useFormSelector((data: PlenaryOpinionFile) => ({
    challengedFiles: data.challengedFiles,
  }));

  const courtFileColumns = useCourtFileColumns();
  const filters = useCourtFileFilterColumns();

  const courtFileFilters = [
    {
      field: 'agenda.id',
      operation: ApiFilterOperation.EQ,
      value: Agenda.COURT,
    },
    {
      field: 'number',
      operation: ApiFilterOperation.NOT_NULL,
    },
    {
      operation: ApiFilterOperation.NOT,
      filters: [
        {
          field: 'state.id',
          operation: ApiFilterOperation.EQ,
          value: 'REGISTRY',
        },
      ],
    },
  ];
  const courtFileDialogSource = useEsFileListSource();
  const courtFileAutocompleteSource = useEsFiles({
    filters: courtFileFilters,
  });

  const { hasPermission } = usePlenaryOpinionPermission(source.data);

  return (
    <>
      {[DetailMode.EDIT, DetailMode.NEW].includes(mode) && (
        <FormDictionaryField
          name="challengedFiles"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__CHALLENGED_FILES"
              defaultMessage="Napadené spisové značky"
            />
          }
          dialogSource={courtFileDialogSource}
          autocompleteSource={courtFileAutocompleteSource}
          multiple={true}
          dialogPreFilters={courtFileFilters}
          columns={courtFileColumns}
          filters={filters}
          disabled={
            !hasPermission(UIElement.PlenaryOpinionFile.CHALLENGED_FILES)
          }
          labelMapper={dictionaryFieldLabelMapper}
          maxRows={9}
        />
      )}
      {mode === DetailMode.VIEW && (
        <FormCustomField
          name="challengedFiles"
          style={{ flexDirection: 'column' }}
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__CHALLENGED_FILES"
              defaultMessage="Napadené spisové značky"
            />
          }
        >
          <div>
            {challengedFiles?.map((challengedFile) => (
              <CourtFileField
                courtFile={challengedFile}
                key={challengedFile.id}
              />
            ))}
          </div>
        </FormCustomField>
      )}
    </>
  );
}
