/**
 * Typ notifikace
 */
export enum NotificationType {
  /**
   * Emailová notifikace
   */
  EMAIL = 'EMAIL',

  /**
   * Aplikační notifikace
   */
  APPLICATION = 'APPLICATION',
}

/**
 * Typ odběru notifikace
 */
export enum NotificationSubscriptionType {
  /**
   * Žádná
   */
  NONE = 'NONE',

  /**
   * Aplikační i mailová
   */
  BOTH = 'BOTH',

  /**
   * Emailová notifikace
   */
  EMAIL = 'EMAIL',

  /**
   * Aplikační notifikace
   */
  APPLICATION = 'APPLICATION',
}

/**
 * Událost
 */
export enum NotificationEventType {
  /**
   * Událost je vyvolána po vytvoření nové úlohy. Příjemcem je řešitel úlohy.
   */
  SYSTEM_TASK_CREATED = 'SYSTEM_TASK_CREATED',
}

/**
 * Typ notifikační šablony
 */
export enum NotificationTemplateType {
  /**
   * Notifikační šablona pro aplikační notifikace
   */
  APPLICATION = 'APPLICATION',

  /**
   * Notifikační šablona pro mailové notifikace
   */
  EMAIL = 'EMAIL',
}
