import { green } from '@material-ui/core/colors';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textAdded: {
      lineHeight: '24px',
      padding: '0 12px',
      backgroundColor: green[200],
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    textDeleted: {
      lineHeight: '24px',
      padding: '0 12px',
      color: theme.palette.grey[700],
      textDecoration: 'line-through',
      marginRight: 5,
      backgroundColor: `${theme.palette.error.main}70`,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    content: {
      display: 'flex',
      width: '100%',
    },
    text: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })
);
