import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  EmptyComponent,
  FormContext,
  FormPanel,
  PromptDialogContext,
  TableField,
  TableFieldCells,
  TableFieldHandle,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import {
  DictionaryObject,
  IsdsSearchSubjectResponseDto,
  IsdsSubjectRequestDto,
  IsdsSubjectResponseDto,
} from '@models';
import { useSearchDataBoxDialog } from './search-data-box-hook';

export const useStyles = makeStyles(() => ({
  actions: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '10px 24px',
    backgroundColor: '#ebebeb',
  },
  buttonLabel: {
    textTransform: 'uppercase',
  },
  delimiter: { width: '100%', marginBottom: 50 },
  warning: {
    color: red['A700'],
  },
}));

export function SearchDataBoxResultDialog() {
  const classes = useStyles();
  const tableRef = useRef<TableFieldHandle>(null);

  const { setFieldValue } = useContext(FormContext);
  const { subjectTypes } = useContext(StaticDataContext);

  const useSubjectTypes = () => useStaticListSource(subjectTypes);

  const { maxResultCountReached, subjects } = useFormSelector(
    (data: IsdsSearchSubjectResponseDto) => ({
      maxResultCountReached: data.maxResultCountReached,
      subjects: data.subjects,
    })
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__CONTACTS__SEARCH_DATA_BOX_DIALOG__PANEL_TITLE__RESULTS"
            defaultMessage="Nalezené výsledky"
          />
        }
        expandable={false}
        summary={<FormTableCount formKey="subjects" />}
      >
        <TableField
          ref={tableRef}
          value={subjects}
          onChange={(value) => setFieldValue('subjects', value)}
          ToolbarComponent={EmptyComponent}
          maxRows={5}
          onSelect={(row) => {
            const { ico, ...subject } = row || {};

            setFieldValue('selectedSubject', {
              ...subject,
              ico: (ico ?? '').trim().length > 0 ? ico?.trim() : undefined,
            });
          }}
          columns={[
            {
              datakey: 'dataBoxId',
              name: 'Id datové schránky',
              width: 150,
              CellComponent: TableFieldCells.TextCell,
            },
            {
              datakey: 'firmName',
              name: 'Název firmy subjektu',
              width: 200,
              CellComponent: TableFieldCells.TextCell,
            },
            {
              datakey: 'ico',
              name: 'IČO',
              width: 125,
              CellComponent: TableFieldCells.TextCell,
            },
            {
              datakey: 'givenName',
              name: 'Jméno subjektu',
              width: 200,
              CellComponent: TableFieldCells.TextCell,
            },
            {
              datakey: 'lastName',
              name: 'Příjmení subjektu',
              width: 200,
              CellComponent: TableFieldCells.TextCell,
            },
            {
              datakey: 'birthDate',
              name: 'Datum narození',
              width: 100,
              CellComponent: TableFieldCells.DateCell,
            },
            {
              datakey: 'subjectType',
              name: 'Typ subjektu',
              width: 100,
              CellComponent: TableFieldCells.useSelectCellFactory<
                IsdsSubjectResponseDto,
                DictionaryObject
              >(useSubjectTypes),
            },
            {
              datakey: 'publicAuthority',
              name: 'Orgán veřejné moci',
              width: 100,
              CellComponent: TableFieldCells.BooleanCell,
            },
            {
              datakey: 'address.label',
              name: 'Adresa',
              width: 200,
              CellComponent: TableFieldCells.TextCell,
            },
          ]}
        />
      </FormPanel>
      {maxResultCountReached && (
        <Typography className={classes.warning}>
          Pozor! Pro zadané parametry bylo nalezeno mnoho výsledků a je možné,
          že nejsou všechny zobrazeny. Pro přesnější vyhledávání zadejte
          podrobnější informace.
        </Typography>
      )}
      <div className={classes.delimiter} />
      <DialogActions
        setSelectedIndex={(index) => tableRef?.current?.setSelectedIndex(index)}
      />
    </>
  );
}

export const DialogActions = ({
  setSelectedIndex,
}: {
  setSelectedIndex: (index: number | undefined) => void;
}) => {
  const classes = useStyles();

  const { formRef, handleCancel, handleConfirm, loading } =
    useContext(PromptDialogContext);
  const { setFieldValue } = useContext(FormContext);

  const { handleSubmit } = useSearchDataBoxDialog();

  const [searching, setSearching] = useState(false);

  const { selectedSubject, subjects, data } = useFormSelector(
    ({
      selectedSubject,
      subjects,
      ...data
    }: {
      selectedSubject: IsdsSubjectResponseDto;
      subjects: IsdsSubjectResponseDto[];
    }) => ({
      selectedSubject: selectedSubject,
      subjects: subjects,
      data: data,
    })
  );

  return (
    <MuiDialogActions classes={{ root: classes.actions }}>
      <ButtonGroup size="small" variant="outlined">
        {subjects?.length > 0 && (
          <Button
            type="submit"
            onClick={handleConfirm}
            variant="outlined"
            color="primary"
            disabled={loading || !selectedSubject || searching}
            startIcon={
              loading && <CircularProgress size="20px" color="inherit" />
            }
          >
            <Typography classes={{ root: classes.buttonLabel }}>
              <FormattedMessage
                id="EAS_DIALOG_BTN_CONFIRM"
                defaultMessage="Potvrdit"
              />
            </Typography>
          </Button>
        )}
        <Button
          onClick={() => {
            setFieldValue('selectedSubject', undefined);
            setSelectedIndex(undefined);
            handleSubmit(data as IsdsSubjectRequestDto, formRef, setSearching);
          }}
          variant="outlined"
          color="default"
          disabled={loading || searching}
          startIcon={
            searching && <CircularProgress size="20px" color="inherit" />
          }
        >
          <Typography classes={{ root: classes.buttonLabel }}>
            <FormattedMessage
              id="EAS_DIALOG_BTN_SEARCH"
              defaultMessage="Vyhledat"
            />
          </Typography>
        </Button>

        <Button variant="outlined" onClick={handleCancel} disabled={loading}>
          <Typography classes={{ root: classes.buttonLabel }}>
            <FormattedMessage
              id="EAS_DIALOG_BTN_CANCEL"
              defaultMessage="Zrušit"
            />
          </Typography>
        </Button>
      </ButtonGroup>
    </MuiDialogActions>
  );
};
