import { sumBy } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryObject,
  FormTextField,
  PromptContext,
  Report,
  useEventCallback,
  useFetch,
  usePrompts,
} from '@eas/common-web';
import {
  Agenda,
  AssistantFilesView,
  EvidenceApiUrl,
  Report as ReportDefinition,
} from '@enums';
import { useAgendas } from '../es-file/es-file-api';
import { useValidationSchema } from './dashboard-schema';

export function useReport({
  url,
  labels = [],
  options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },
}: {
  url: string;
  labels?: DictionaryObject[];
  options?: RequestInit;
}): { id: string; label: string; value: number }[] {
  const [result] = useFetch<Report>(url, options);

  const items = useMemo(
    () =>
      (result?.data ?? []).map((item: { count: string; key: string }) => ({
        id: labels.find((label) => label.name === item.key)?.id ?? item.key,
        label: item.key,
        value: parseInt(item.count, 10),
      })),
    [result, labels]
  );

  return items;
}

export function useFilesStatistics() {
  const [agenda, setAgenda] = useState(Agenda.COURT);
  const [assistantFilesView, setAsistantFilesView] = useState(
    AssistantFilesView.ACTIVE
  );
  const [agendas] = useAgendas();

  const allFilesAccordingToAgendas = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.ALL_FILES_ACCORDING_TO_AGENDAS}`,
    labels: agendas,
  });

  const courtFilesAccordingToStates = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.COURT_FILES_ACCORDING_TO_STATES}`,
  });

  const allFilesAccordingToAssistants = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.ALL_FILES_ACCORDING_TO_ASSISTANTS}`,
  });

  const disciplinaryProceedingsAccordingToStates = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.DISCIPLINARY_PROCEEDINGS_ACCORDING_TO_STATES}`,
  });

  const infAccordingToStates = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.INF_ACCORDING_TO_STATES}`,
  });

  const plenaryOpinionAccordingToStates = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.PLENARY_OPINION_ACCORDING_TO_STATES}`,
  });

  const sprAccordingToStates = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.SPR_ACCORDING_TO_STATES}`,
  });

  const activeFilesAccordingToAssistants = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.ACTIVE_FILES_ACCORDING_TO_ASSISTANTS}`,
  });

  const statesAccordingToAgendas = {
    [Agenda.COURT]: courtFilesAccordingToStates,
    [Agenda.INF]: infAccordingToStates,
    [Agenda.SPR]: sprAccordingToStates,
    [Agenda.DISCIPLINARY_PROCEEDINGS]: disciplinaryProceedingsAccordingToStates,
    [Agenda.PLENARY_OPINION]: plenaryOpinionAccordingToStates,
  };

  const assistantFilesSummary = [
    {
      id: AssistantFilesView.ACTIVE,
      label: 'Aktivní',
      value: sumBy(activeFilesAccordingToAssistants, (file) => file.value),
    },
    {
      id: AssistantFilesView.ALL,
      label: 'Všechny',
      value: sumBy(allFilesAccordingToAssistants, (file) => file.value),
    },
  ];

  const assistantFiles = {
    [AssistantFilesView.ACTIVE]: activeFilesAccordingToAssistants,
    [AssistantFilesView.ALL]: allFilesAccordingToAssistants,
  };

  return {
    agenda,
    setAgenda,
    assistantFilesView,
    setAsistantFilesView,
    assistantFilesSummary,
    assistantFiles,
    allFilesAccordingToAgendas,
    statesAccordingToAgendas,
  };
}

export function useDocumentsStatistics(lastNDays: number) {
  const outgoingDocuments = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.OUTGOING_DOCUMENTS}/generate`,
    options: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ lastNDays }),
    },
  });

  return {
    outgoingDocuments,
  };
}

export function useUserOverviewStatistics() {
  const courtFilesWithRunningDeadline = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.COURT_FILES_WITH_RUNNING_DEADLINE}`,
  })?.[0];

  const unfinishedTasksForUser = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.UNFINISHED_TASKS_FOR_USER}`,
  })?.[0];

  const unprocessedDeliveredDocuments = useReport({
    url: `${EvidenceApiUrl.REPORTS}/${ReportDefinition.UNPROCESSED_DELIVERED_DOCUMENTS_COUNT}`,
  })?.[0];

  return {
    courtFilesWithRunningDeadline,
    unfinishedTasksForUser,
    unprocessedDeliveredDocuments,
  };
}

export function useSetCustomRange(setValue: (value: number) => void) {
  const intl = useIntl();
  const PROMPT_KEY = 'SET_CUSTOM_RANGE';

  const validationSchema = useValidationSchema();

  const { testPrompt } = useContext(PromptContext);

  const handleConfirmDialog = useEventCallback((value) => {
    setValue(parseInt(value.lastNDays));
  });

  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: intl.formatMessage({
        id: 'ES__DASHBOARD__DOCUMENTS__SET_CUSTOM_RANGE__TITLE',
        defaultMessage: 'Nastavení rozsahu',
      }),
      dialogText: '',
      FormFields: function FormFields() {
        return (
          <FormTextField label="Počet dní" type="number" name="lastNDays" />
        );
      },
      formValidationSchema: validationSchema,
    },
  ]);

  const handleSetCustomRange = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: handleConfirmDialog,
    });
  });

  return { handleSetRange: handleSetCustomRange };
}
