import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictConcernedAuthorityType } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { Fields } from './dict-concerned-authority-type-fields';

export function DictConcernedAuthorityTypes() {
  const intl = useIntl();
  const { columnName, columnOrder, columnActive } =
    DictionaryEvidence.useDictionaryColumns<DictConcernedAuthorityType>();

  const evidence = useDictionaryEvidence<DictConcernedAuthorityType>({
    version: 3,
    identifier: 'CONCERNED_AUTHORITY_TYPES',
    apiProps: {
      url: EvidenceApiUrl.DICT_CONCERNED_AUTHORITY_TYPES,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.CONCERNED_AUTHORITY_TYPE,
      }),
    },
    tableProps: {
      columns: [columnName, columnOrder, columnActive],
      tableName: intl.formatMessage({
        id: 'ES__DICT_CONCERNED_AUTHORITY_TYPES__TABLE__TITLE',
        defaultMessage: 'Typy dotčeného orgánu',
      }),
      reportTag: ExportTags.DICT_CONCERNED_AUTHORITY_TYPES,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.CONCERNED_AUTHORITY_TYPE,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_CONCERNED_AUTHORITY_TYPES__DETAIL__TITLE',
          defaultMessage: 'Typ dotčeného orgánu',
        }),
      },
      GeneralFieldsComponent: Fields,
    },
  });

  return <Evidence {...evidence} />;
}
