import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormDateField, FormPanel } from '@eas/common-web';

export function NoticeBoardFields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__PANEL_TITLE__NOTICE_BOARD_ATTRIBUTES"
            defaultMessage="Úřední deska"
          />
        }
        expandable={false}
      >
        <FormDateField
          name="noticeBoard.noticeDate"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__NOTICE_DATE"
              defaultMessage="Datum oznámení"
            />
          }
        />
        <FormDateField
          name="noticeBoard.postingDate"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__POSTING_DATE"
              defaultMessage="Datum vyvěšení"
            />
          }
        />
        <FormDateField
          name="noticeBoard.expirationDate"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__EXPIRATION_DATE"
              defaultMessage="Datum snětí"
            />
          }
        />
      </FormPanel>
    </>
  );
}
