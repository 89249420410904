import React, { PropsWithChildren } from 'react';
import { FilterContext } from './filter-context';
import { FilterState } from './filter-types';
import { useFilterState } from './hooks/filter-hook';

export function FilterProvider({
  children,
  settingsKey,
  defaults,
}: PropsWithChildren<{ settingsKey: string; defaults?: FilterState[] }>) {
  const { context } = useFilterState({ settingsKey, defaults });

  return (
    <FilterContext.Provider value={context}>{children}</FilterContext.Provider>
  );
}
