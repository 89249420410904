/**
 * Způsob nahlížení
 */
export enum ViewingMethod {
  /**
   * Fyzicky
   */
  PHYSICAL = 'PHYSICAL',

  /**
   * Elektronicky
   */
  ELECTRONIC = 'ELECTRONIC',
}

/**
 * Stav žádosti o nahlížení
 */
export enum ViewingRequestState {
  /**
   * Nová
   */
  NEW = 'NEW',

  /**
   * Zpracovává se
   */
  PROCESSING = 'PROCESSING',

  /**
   * Vyřízená
   */
  RESOLVED = 'RESOLVED',
}
