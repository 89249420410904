import React from 'react';
import { useIntl } from 'react-intl';
import {
  EmptyComponent,
  Evidence,
  createItemFactory,
  getItemFactory,
  updateItemFactory,
} from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { Contact, DictOpponent } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { useColumns } from './dict-opponent-columns';
import { Fields } from './dict-opponent-fields';

export function DictOpponents() {
  const intl = useIntl();

  const evidence = useDictionaryEvidence<DictOpponent>({
    version: 2,
    identifier: 'OPPONENTS',
    apiProps: {
      url: EvidenceApiUrl.DICT_OPPONENTS,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.OPPONENT,
      }),
      getItem: getItemFactory({
        postProcess,
      }),
      createItem: createItemFactory({
        preProcess,
        postProcess,
      }),
      updateItem: updateItemFactory({ preProcess, postProcess }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_OPPONENTS__TABLE__TITLE',
        defaultMessage: 'Odpůrci',
      }),
      reportTag: ExportTags.DICT_OPPONENTS,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.OPPONENT,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_OPPONENTS__DETAIL__TITLE',
          defaultMessage: 'Odpůrce',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}

async function postProcess(item: DictOpponent) {
  return {
    ...item,
    contacts: (item?.contacts ?? []).map((c) => ({ contact: c })),
  } as DictOpponent;
}

function preProcess(item: DictOpponent) {
  return {
    ...item,
    contacts: item?.contacts?.map((c) => (c as { contact: Contact }).contact),
  } as DictOpponent;
}
