import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import {
  RedirectAction,
  useRedirect,
} from '@composite/redirect/dialog-redirect';
import { Agenda, EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';

export function useNewCourtFileDialog() {
  const intl = useIntl();

  const callApi = useEventCallback((id: string) =>
    abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${id}/submission?agenda=${Agenda.COURT}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
      }
    )
  );

  const { source } = useContext(DetailContext);

  const redirectBag = useRedirect({
    options: [
      {
        id: RedirectAction.REDIRECT_TO_RESULT,
        toResult: true,
        name: intl.formatMessage({
          id: 'REDIRECT__OPTION__NEW_COURT_FILE',
          defaultMessage: 'Pokračovat v novém soudním spisu',
        }),
      },
      {
        id: RedirectAction.STAY_IN_EVIDENCE,
        name: intl.formatMessage({
          id: 'REDIRECT__OPTION__UNPROCESSED_DOCUMENTS',
          defaultMessage: 'Pokračovat v nezpracovaných dokumentech',
        }),
      },
    ],
    redirectTo: EvidenceBrowserUrl.COURT_FILES_NEW,
  });

  // TODO
  const showButton = source.data?.id;

  return {
    callApi,
    showButton,
    redirectBag,
  };
}
