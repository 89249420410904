import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictZipCode } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { useColumns } from './dict-zip-code-columns';
import { Fields } from './dict-zip-code-fields';
import { useValidationSchema } from './dict-zip-code-schema';

export function DictZipCodes() {
  const intl = useIntl();

  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictZipCode>({
    version: 3,
    identifier: 'ZIP_CODES',
    apiProps: {
      url: EvidenceApiUrl.DICT_ZIP_CODES,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.ZIP_CODE,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_ZIP_CODES__TABLE__TITLE',
        defaultMessage: 'PSČ',
      }),
      reportTag: ExportTags.DICT_ZIP_CODES,
      defaultSorts: [
        {
          field: 'name',
          datakey: 'name',
          order: 'ASC',
          type: 'FIELD',
        },
      ],
    },
    detailProps: {
      entityName: EntityName.ZIP_CODE,
      GeneralFieldsComponent: () => <Fields />,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_ZIP_CODES__DETAIL__TITLE',
          defaultMessage: 'PSČ',
        }),
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
