import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormCheckbox,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useActTypes } from '@modules/dict-act-type/dict-act-type-api';
import { useConcernedAuthorityTypes } from '@modules/dict-concerned-authority-type/dict-concerned-authority-type-api';
import { useOpponents } from '@modules/dict-opponent/dict-opponent-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';

export function ChallengedActDialog() {
  const actTypes = useActTypes();
  const concernedAuthorityTypes = useConcernedAuthorityTypes();
  const opponents = useOpponents();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__COURT_FILE__DETAIL__CHALLENGED_ACT_PANEL_TITLE"
            defaultMessage="Napadený akt"
          />
        }
      >
        <FormAutocomplete
          name="actType"
          label={
            <FormattedMessage
              id="ES__COURT_FILE__DETAIL__FIELD_LABEL__ACT_TYPE"
              defaultMessage="Druh aktu"
            />
          }
          autoFocus={true}
          source={actTypes}
          labelMapper={autocompleteLabelMapper}
        />
        <FormAutocomplete
          name="concernedAuthorityType"
          label={
            <FormattedMessage
              id="ES__COURT_FILE__DETAIL__FIELD_LABEL__CONCERNED_AUTHORITY_TYPE"
              defaultMessage="Typ dotčeného orgánu"
            />
          }
          source={concernedAuthorityTypes}
          labelMapper={autocompleteLabelMapper}
        />
        <FormAutocomplete
          name="concernedAuthority"
          label={
            <FormattedMessage
              id="ES__COURT_FILE__DETAIL__FIELD_LABEL__CONCERNED_AUTHORITY"
              defaultMessage="Název dotčeného orgánu"
            />
          }
          required
          source={opponents}
          labelMapper={autocompleteLabelMapper}
        />
        <FormTextField
          name="externalFileNumber"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__FILE_NUMBER"
              defaultMessage="Spisová značka / číslo aktu"
            />
          }
        />
        <FormTextField
          name="actName"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__ACT_NAME"
              defaultMessage="Název aktu"
            />
          }
        />
        <FormTextField
          name="challengedProvisions"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__CHALLENGED_PROVISIONS"
              defaultMessage="Napadená ustanovení"
            />
          }
        />
        <FormCheckbox
          name="firstInstance"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__FIRST_INSTANCE"
              defaultMessage="Prvoinstanční"
            />
          }
        />
      </FormPanel>
    </>
  );
}
