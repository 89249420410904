import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  FormContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
  eqFilterParams,
} from '@eas/common-web';
import { SearchDataBoxButton } from '@composite/contact/dialog-actions/search-data-box/search-data-box-button';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { Contact, DictContactType, DictCountry } from '@models';
import { EvidenceApiUrl } from '@enums';
import { ContactDialog } from './contact-dialog';
import { ContactFieldProps } from './contact-field';
import { useValidationSchema } from './contact-schema';
import { useAddContactDialog } from './dialog-actions/add-contact-hook';
import { useEditContactDialog } from './dialog-actions/edit-contact-hook';
import { IdentifyButton } from './dialog-actions/identify/identify-button';
import { useInvalidateContactDialog } from './dialog-actions/invalidate-contact-hook';
import { useRevalidateContactDialog } from './dialog-actions/revalidate-contact-hook';

interface ContactToolbarProps extends ContactFieldProps {
  contacts?: Contact[];
}

export const ContactToolbar = forwardRef(function ContactsToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled = false,
    contacts = [],
    display,
  }: TableFieldToolbarProps & ContactToolbarProps,
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();
  const [defaults, setDefaults] = useState<{
    contactType?: DictContactType;
    country?: DictCountry;
  }>({});

  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const { source, formRef, mode } = useContext(DetailContext);
  const formContext = useContext(FormContext);

  const derivedRef = display === 'IN_SUBJECT' ? formRef : formContext;

  useEffect(() => {
    setSelectedIndex(undefined);

    const loadDefaultType = async () => {
      const [contactTypeResponse, countryResponse] = await Promise.all([
        await fetch(`${EvidenceApiUrl.DICT_CONTACT_TYPES}/list`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(
            eqFilterParams({ field: 'isDefault', value: true })
          ),
        }),
        await fetch(`${EvidenceApiUrl.DICT_COUNTRIES}/list`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(eqFilterParams({ field: 'code', value: 'CZ' })),
        }),
      ]);

      const contactTypes = await contactTypeResponse.json();
      const countries = await countryResponse.json();

      setDefaults({
        contactType: contactTypes.items?.[0],
        country: countries.items?.[0],
      });
    };

    loadDefaultType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const isSelected = selectedIndex !== undefined;
  const selectedContact =
    selectedIndex !== undefined ? contacts?.[selectedIndex] : ({} as Contact);

  const { callApi: addContactApiCall } = useAddContactDialog();
  const { callApi: editContactApiCall } = useEditContactDialog();
  const { callApi: invalidateContactApiCall } = useInvalidateContactDialog();
  const { callApi: revalidateContactApiCall } = useRevalidateContactDialog();

  const handleContactsChange = (value: Contact[]) => {
    derivedRef?.setFieldValue('contacts', value);
  };

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_CONTACT"
          apiCall={addContactApiCall}
          submitCallback={
            mode === DetailMode.VIEW
              ? undefined
              : async (formData) => {
                  handleContactsChange([...contacts, formData]);
                }
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__ADD_CONTACT',
            defaultMessage: 'Přidat nový kontakt',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__ADD_CONTACT__TITLE',
            defaultMessage: 'Kontakt',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__ADD_CONTACT__TEXT',
            defaultMessage: 'Vyplňte údaje.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__CONTACTS_TOOLBAR__ADD_CONTACT__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formInitialValues={{
            id: uuidv4(),
            type: defaults.contactType,
            address: { country: defaults.country },
          }}
          formValidationSchema={validationSchema}
          FormFields={ContactDialog}
          modes={[DetailMode.VIEW, DetailMode.EDIT, DetailMode.NEW]}
          dialogWidth={700}
        />
        <DetailActionButton
          ref={ref}
          promptKey="EDIT_CONTACT"
          apiCall={(id, formData) => editContactApiCall(id, formData)}
          submitCallback={
            mode === DetailMode.VIEW
              ? undefined
              : async (formData) => {
                  handleContactsChange([
                    ...contacts.slice(undefined, selectedIndex),
                    formData,
                    ...contacts.slice(selectedIndex! + 1, undefined),
                  ]);
                }
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__EDIT_CONTACT',
            defaultMessage: 'Editovat kontakt',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__EDIT_CONTACT__TITLE',
            defaultMessage: 'Kontakt',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__EDIT_CONTACT__TEXT',
            defaultMessage: 'Vyplňte údaje.',
          })}
          FormFields={ContactDialog}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__CONTACTS_TOOLBAR__EDIT_CONTACT__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formValidationSchema={validationSchema}
          formInitialValues={selectedContact}
          modes={[DetailMode.VIEW, DetailMode.EDIT, DetailMode.NEW]}
          dialogWidth={700}
        />
        <SearchDataBoxButton formRef={derivedRef} disabled={disabled} />
        <IdentifyButton
          disabled={disabled || selectedIndex === undefined}
          selectedContact={selectedContact}
        />
        <DetailActionButton
          promptKey="DELETE_CONTACT"
          submitCallback={async () => {
            handleContactsChange(
              contacts.filter((_, i) => i !== selectedIndex)
            );

            formContext.setFieldValue('selectedContact', undefined);
            setSelectedIndex(undefined);
          }}
          buttonLabel={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__DELETE_CONTACT',
            defaultMessage: 'Odstranit kontakt',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__DELETE_CONTACT__TITLE',
            defaultMessage: 'Kontakt',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__DELETE_CONTACT__TEXT',
            defaultMessage: 'Skutečně chcete odstranit kontakt?',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__CONTACTS_TOOLBAR__DELETE_CONTACT__BUTTON',
                  defaultMessage: 'Smazat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.EDIT, DetailMode.NEW]}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="INVALIDATE_CONTACT"
          apiCall={(id) => invalidateContactApiCall(id, selectedContact.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__INACTIVATE_CONTACT',
            defaultMessage: 'Zneplatnit kontakt',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__INACTIVATE_CONTACT__TITLE',
            defaultMessage: 'Kontakt',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__INACTIVATE_CONTACT__TEXT',
            defaultMessage: 'Skutečně chcete zneplatnit kontakt?',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={CloseIcon}
              show={true}
              disabled={disabled || !isSelected || !selectedContact?.active}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__CONTACTS_TOOLBAR__INACTIVATE_CONTACT__BUTTON',
                  defaultMessage: 'Zneplatnit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW]}
        />
        <DetailActionButton
          promptKey="REVALIDATE_CONTACT"
          apiCall={(id) => revalidateContactApiCall(id, selectedContact.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__INACTIVATE_CONTACT',
            defaultMessage: 'Obnovit platnost kontaktu',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__INACTIVATE_CONTACT__TITLE',
            defaultMessage: 'Kontakt',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__CONTACTS_TOOLBAR__INACTIVATE_CONTACT__TEXT',
            defaultMessage: 'Skutečně chcete obnovit platnost kontaktu?',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={CheckIcon}
              show={true}
              disabled={disabled || !isSelected || !!selectedContact?.active}
              title={label}
              onClick={onClick}
              color="primary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__CONTACTS_TOOLBAR__INACTIVATE_CONTACT__BUTTON',
                  defaultMessage: 'Obnovit platnost',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW]}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps &
    ContactToolbarProps & {
      ref: Ref<ActionButtonHandle>;
    }
) => ReactElement;
