import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  ApiFilterOperation,
  FieldFilter,
  RemoteTableFieldContext,
  TableFieldToolbarButtons,
} from '@eas/common-web';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';

export function UserSubstitutionLogToolbar() {
  const intl = useIntl();
  const classes = useStyles();

  const { source } = useContext(RemoteTableFieldContext);

  return (
    <div className={classes.tableActions}>
      <div />
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={source}
          filtersFields={[
            {
              label: intl.formatMessage({
                id: 'ES__USER_SUBSTITUTION_LOG_TOOLBAR__FILTER__START',
                defaultMessage: 'Začátek',
              }),
              datakey: 'started',
              filterkey: 'started',
              defaultOperation: ApiFilterOperation.GTE,
              FilterComponent: FieldFilter.FilterLocalDateTimeCell,
            },
            {
              label: intl.formatMessage({
                id: 'ES__USER_SUBSTITUTION_LOG_TOOLBAR__FILTER__END',
                defaultMessage: 'Konec',
              }),
              datakey: 'ended',
              filterkey: 'ended',
              defaultOperation: ApiFilterOperation.LTE,
              FilterComponent: FieldFilter.FilterLocalDateTimeCell,
            },
          ]}
        />
      </ButtonGroup>
    </div>
  );
}
