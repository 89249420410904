import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { PublicationFiles } from './publication-files';
import { Registers } from './registers';
import { ToShredFiles } from './to-shred-files';

export const useStyles = makeStyles({
  root: {
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f1f3f4',
    overflow: 'auto',
  },
  content: {
    marginTop: 50,
    marginBottom: 50,
    flex: '1 0 auto',
  },
});

export function RegistersAndFiles() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Registers />
            <ToShredFiles />
            <PublicationFiles />
          </Grid>
        </Container>
      </div>
    </div>
  );
}
