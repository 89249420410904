export enum Report {
  /**
   * Aktivní spisy dle jednotlivých asistentů
   */
  ACTIVE_FILES_ACCORDING_TO_ASSISTANTS = 'ACTIVE_FILES_ACCORDING_TO_ASSISTANTS',

  /**
   * Všechny spisy dle jednotlivých agend
   */
  ALL_FILES_ACCORDING_TO_AGENDAS = 'ALL_FILES_ACCORDING_TO_AGENDAS',

  /**
   * Všechny spisy dle jednotlivých asistentů
   */
  ALL_FILES_ACCORDING_TO_ASSISTANTS = 'ALL_FILES_ACCORDING_TO_ASSISTANTS',

  /**
   * Soudní spisy dle jednotlivých stavů
   */
  COURT_FILES_ACCORDING_TO_STATES = 'COURT_FILES_ACCORDING_TO_STATES',

  /**
   * Soudní spisy s běžící lhůtou
   */
  COURT_FILES_WITH_RUNNING_DEADLINE = 'COURT_FILES_WITH_RUNNING_DEADLINE',

  /**
   * Kárná řízení dle jednotlivých stavů
   */
  DISCIPLINARY_PROCEEDINGS_ACCORDING_TO_STATES = 'DISCIPLINARY_PROCEEDINGS_ACCORDING_TO_STATES',

  /**
   * Inf spisy dle jednotlivých stavů
   */
  INF_ACCORDING_TO_STATES = 'INF_ACCORDING_TO_STATES',

  /**
   * Stanoviska pléna dle jednotlivých stavů
   */
  PLENARY_OPINION_ACCORDING_TO_STATES = 'PLENARY_OPINION_ACCORDING_TO_STATES',

  /**
   * Spr spisy dle jednotlivých stavů
   */
  SPR_ACCORDING_TO_STATES = 'SPR_ACCORDING_TO_STATES',

  /**
   * Počet nedokončených úloh uživatele
   */
  UNFINISHED_TASKS_FOR_USER = 'UNFINISHED_TASKS_FOR_USER',

  /**
   * Odchozí dokumenty
   */
  OUTGOING_DOCUMENTS = 'OUTGOING_DOCUMENTS',

  /**
   * Počet nezpracovaných doručených dokumentů
   */
  UNPROCESSED_DELIVERED_DOCUMENTS_COUNT = 'UNPROCESSED_DELIVERED_DOCUMENTS_COUNT',
}
