import { getErrorMessage } from '@utils/error-message';
import React, { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  AbortableFetch,
  DetailContext,
  DetailHandle,
  SnackbarContext,
  SnackbarVariant,
  TableFieldToolbarButton,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { Document } from '@models';
import { EvidenceApiUrl, Messages } from '@enums';

export function useDownloadValidationReportDialog() {
  const { source } = useContext<DetailHandle<Document>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useRef<AbortableFetch | null>(null);
  const intl = useIntl();

  const callApi = useEventCallback((attachmentId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${source?.data?.id}/attachment/${attachmentId}/report`,
      {
        headers: new Headers({
          'Content-Type': 'application/blob',
        }),
        method: 'GET',
      }
    )
  );

  const wrappedCallApi = useEventCallback(async (id: string) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(id);

      const message = intl.formatMessage({
        id: 'DOCUMENTS__REQUEST_SUCCESSFULLY_SENT',
        defaultMessage: 'Dokument se generuje, čekejte prosím.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);

      const response = await fetch.current.raw();

      const blob = await response.blob();

      const fileUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = 'report.pdf';

      document.body.appendChild(a); // Required for FF
      a.click();
      a.remove();

      showSnackbar(...Messages.Attachment.DOWNLOAD_VALIDATION_REPORT.SUCCESS);
    } catch (err) {
      const message = getErrorMessage(err as Error);

      if ((err as Error).name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  const DownloadValidationReportButton = ({
    disabled,
    apiCall,
  }: {
    disabled: boolean;
    apiCall: () => Promise<void>;
  }) => (
    <TableFieldToolbarButton
      title={intl.formatMessage({
        id: 'ES__ATTACHMENTS__DOWNLOAD_REPORT__TITLE',
        defaultMessage: 'Stáhnutí validačního PDF reportu',
      })}
      variant="text"
      color="default"
      onClick={apiCall}
      IconComponent={GetAppIcon}
      show={true}
      disabled={disabled}
    >
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'ES__ATTACHMENTS__DOWNLOAD_REPORT__BUTTON',
          defaultMessage: 'Validační PDF report',
        })}
      </Typography>
    </TableFieldToolbarButton>
  );

  return {
    callApi: wrappedCallApi,
    DownloadValidationReportButton,
  };
}
