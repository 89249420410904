import {
  ApiFilterOperation,
  HookedSource,
  useScrollableSource,
} from '@eas/common-web';
import { useHistorySource } from '@composite/history/history-api';
import { ChallengedAct, CourtFile, EsFile } from '@models';
import {
  Agenda,
  DeadlineState,
  EvidenceApiUrl,
  HistoryEntityType,
  ParticipantType,
  TaskState,
} from '@enums';

export const NOTE_SOURCE = 'NOTE_SOURCE';
export const DEADLINE_SOURCE = 'DEADLINE_SOURCE';
export const RUNNING_DEADLINE_SOURCE = 'RUNNING_DEADLINE_SOURCE';
export const DOCUMENT_SOURCE = 'DOCUMENT_SOURCE';
export const DECISION_SOURCE = 'DECISION_SOURCE';
export const PROPOSER_SOURCE = 'PROPOSER_SOURCE';
export const ACCESSORY_PROPOSER_SOURCE = 'ACCESSORY_PROPOSER_SOURCE';
export const LEGAL_ADVISER_SOURCE = 'LEGAL_ADVISER_SOURCE';
export const WRITER_SOURCE = 'WRITER_SOURCE_SOURCE';
export const CHALLENGED_ACT_SOURCE = 'CHALLENGED_ACT_SOURCE';
export const REGULATION_SOURCE = 'REGULATION_SOURCE';
export const TASK_SOURCE = 'TASK_SOURCE';
export const DRAFT_SOURCE = 'DRAFT_SOURCE';
export const VIEWING_SOURCE = 'VIEWING_SOURCE';
export const VIEWING_REQUEST_SOURCE = 'VIEWING_REQUEST_SOURCE';
export const SAME_PROPOSER_FILE_SOURCE = 'SAME_PROPOSER_FILE_SOURCE';
export const SAME_CHALLENGED_ACT_FILE_SOURCE =
  'SAME_CHALLENGED_ACT_FILE_SOURCE';

function useNoteSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [NOTE_SOURCE]: {
      source,
      shouldLoad: () => true,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/note/list`);
      },
    },
  };
}

function useDeadlineSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [DEADLINE_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [
          Agenda.COURT,
          Agenda.SPR,
          Agenda.INF,
          Agenda.DISCIPLINARY_PROCEEDINGS,
        ].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/deadline/list`);
        source.setParams({
          size: 10,
          sort: [
            {
              field: 'endDate',
              type: 'FIELD',
              order: 'ASC',
            },
          ],
        });
      },
    },
  };
}

function useRunningDeadlineSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [RUNNING_DEADLINE_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [
          Agenda.COURT,
          Agenda.SPR,
          Agenda.INF,
          Agenda.DISCIPLINARY_PROCEEDINGS,
        ].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/deadline/list`);
        source.setParams({
          size: 10,
          filters: [
            {
              field: 'state.id',
              operation: ApiFilterOperation.EQ,
              value: DeadlineState.RUNNING,
            },
          ],
          sort: [
            {
              field: 'endDate',
              type: 'FIELD',
              order: 'ASC',
            },
          ],
        });
      },
    },
  };
}

function useDocumentSource(): HookedSource<EsFile> {
  const source = useScrollableSource({
    url: `${EvidenceApiUrl.DOCUMENTS}/list`,
  });

  return {
    [DOCUMENT_SOURCE]: {
      source,
      shouldLoad: () => true,
      modifyBeforeLoad: ({ id }) => {
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'esFile.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
          sort: [
            {
              field: 'order',
              type: 'FIELD',
              order: 'DESC',
            },
          ],
        });
      },
    },
  };
}

function useDecisionSource(): HookedSource<EsFile> {
  const source = useScrollableSource({
    url: `${EvidenceApiUrl.DECISIONS}/list`,
  });

  return {
    [DECISION_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) =>
        [Agenda.COURT, Agenda.PLENARY_OPINION].includes(agenda!),
      modifyBeforeLoad: ({ id }) => {
        source.setParams({
          size: 10,
          filters: [
            {
              field: 'esFile.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
          sort: [
            {
              type: 'FIELD',
              field: 'number',
              order: 'ASC',
            },
          ],
        });
      },
    },
  };
}

function useProposerSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [PROPOSER_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.COURT].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/participant/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'type.id',
              operation: ApiFilterOperation.EQ,
              value: ParticipantType.PROPOSER,
            },
          ],
        });
      },
    },
  };
}

function useAccessoryProposerSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [ACCESSORY_PROPOSER_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.COURT].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/participant/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'type.id',
              operation: ApiFilterOperation.EQ,
              value: ParticipantType.ACCESSORY_PARTICIPANT,
            },
          ],
        });
      },
    },
  };
}

function useLegalAdviserSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [LEGAL_ADVISER_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.COURT].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/participant/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'type.id',
              operation: ApiFilterOperation.EQ,
              value: ParticipantType.LEGAL_ADVISER,
            },
          ],
        });
      },
    },
  };
}

function useWriterSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [WRITER_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.INF, Agenda.SPR].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/participant/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'type.id',
              operation: ApiFilterOperation.EQ,
              value: ParticipantType.WRITER,
            },
          ],
        });
      },
    },
  };
}

function useRegulationSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [REGULATION_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.COURT, Agenda.PLENARY_OPINION].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/regulation/list`);
      },
    },
  };
}

function useChallengedActSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [CHALLENGED_ACT_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.COURT].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/challenged-act/list`);
      },
    },
  };
}

function useTaskSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [TASK_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [
          Agenda.COURT,
          Agenda.INF,
          Agenda.SPR,
          Agenda.PLENARY_OPINION,
        ].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.TASKS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'esFile.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
            {
              field: 'state.id',
              operation: ApiFilterOperation.EQ,
              value: TaskState.SOLVING,
            },
          ],
        });
      },
    },
  };
}

function useDraftSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [DRAFT_SOURCE]: {
      source,
      shouldLoad: () => true,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/draft/list`);
      },
    },
  };
}

function useViewingSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [VIEWING_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.DISCIPLINARY_PROCEEDINGS].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/viewing/list`);
      },
    },
  };
}

function useViewingRequestSource(): HookedSource<EsFile> {
  const source = useScrollableSource();

  return {
    [VIEWING_REQUEST_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.COURT].includes(agenda!);
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ES_FILES}/${id}/viewing/request/list`);
        source.setParams({
          size: 10,
          sort: [
            {
              field: 'created',
              type: 'FIELD',
              order: 'DESC',
            },
          ],
        });
      },
    },
  };
}

function useSameProposerFileSource(): HookedSource<EsFile> {
  const source = useScrollableSource({
    url: `${EvidenceApiUrl.ES_FILES}/list`,
  });

  return {
    [SAME_PROPOSER_FILE_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.COURT, Agenda.INF, Agenda.SPR].includes(agenda!);
      },
      modifyBeforeLoad: ({ participants, id }) => {
        source.setParams({
          size: 10,
          filters: [
            {
              field: 'agenda.id',
              operation: ApiFilterOperation.IN,
              values: [Agenda.COURT, Agenda.SPR, Agenda.INF],
            },
            {
              operation: ApiFilterOperation.NOT,
              filters: [
                {
                  field: 'id',
                  operation: ApiFilterOperation.EQ,
                  value: id,
                },
              ],
            },
            {
              field: 'participants.contact.subject.id',
              operation: ApiFilterOperation.IN,
              values: participants
                ?.filter(
                  (p) =>
                    p.type === ParticipantType.PROPOSER ||
                    p.type === ParticipantType.WRITER
                )
                .map((p) => p.contact?.subject?.id),
            },
          ],
        });
      },
    },
  };
}

function useSameChallengedActFileSource(): HookedSource<CourtFile> {
  const source = useScrollableSource({
    url: `${EvidenceApiUrl.ES_FILES}/list`,
  });

  return {
    [SAME_CHALLENGED_ACT_FILE_SOURCE]: {
      source,
      shouldLoad: ({ agenda }) => {
        return [Agenda.COURT].includes(agenda!);
      },
      modifyBeforeLoad: ({ challengedActs, id }) => {
        const challengedActsWithExternalFileNumber = (
          challengedActs ?? []
        ).filter((c) => !!c.externalFileNumber);

        source.setParams({
          size: 10,
          filters: [
            {
              field: 'agenda.id',
              operation: ApiFilterOperation.EQ,
              value: Agenda.COURT,
            },
            {
              operation: ApiFilterOperation.NOT,
              filters: [
                {
                  field: 'id',
                  operation: ApiFilterOperation.EQ,
                  value: id,
                },
              ],
            },
            ...(challengedActsWithExternalFileNumber.length > 0
              ? [
                  {
                    field: 'challengedActs.externalFileNumber',
                    operation: ApiFilterOperation.IN,
                    values: challengedActsWithExternalFileNumber.map(
                      (c: ChallengedAct) => c.externalFileNumber
                    ),
                  },
                ]
              : []),
          ],
        });
      },
    },
  };
}

export function useEsFilesHookedSources(): HookedSource<EsFile> {
  const notes = useNoteSource();
  const deadlines = useDeadlineSource();
  const runningDeadlines = useRunningDeadlineSource();
  const documents = useDocumentSource();
  const decisions = useDecisionSource();
  const proposers = useProposerSource();
  const accessoryProposers = useAccessoryProposerSource();
  const legalAdvisers = useLegalAdviserSource();
  const writers = useWriterSource();
  const challengedActs = useChallengedActSource();
  const regulations = useRegulationSource();
  const tasks = useTaskSource();
  const drafts = useDraftSource();
  const viewing = useViewingSource();
  const viewingRequests = useViewingRequestSource();
  const sameProposerFiles = useSameProposerFileSource();
  const sameChallengedActFiles = useSameChallengedActFileSource();
  const history = useHistorySource({
    entityType: HistoryEntityType.ES_FILE,
  });

  return {
    ...notes,
    ...deadlines,
    ...runningDeadlines,
    ...documents,
    ...decisions,
    ...proposers,
    ...accessoryProposers,
    ...legalAdvisers,
    ...writers,
    ...challengedActs,
    ...regulations,
    ...tasks,
    ...drafts,
    ...viewing,
    ...viewingRequests,
    ...sameProposerFiles,
    ...sameChallengedActFiles,
    ...history,
  };
}
