import clsx from 'clsx';
import { noop } from 'lodash';
import React from 'react';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DashboardDivider } from './divider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'left',
    marginLeft: 15,
  },
  button: {
    width: '100%',
  },
  label: {
    // padding: '16px 0 0',
    margin: 0,
    whiteSpace: 'nowrap',
  },
  value: {
    padding: '0 0 16px',
    fontFamily: 'Montserrat',
    margin: 0,
    fontWeight: 400,
    fontSize: '1.3rem',
    color: theme.palette.grey[600],
  },
  actionText: {
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // transform: 'rotate(90deg)',
  },
  underline: {
    height: 4,
    background: theme.palette.primary.main,
  },
  primary: {
    color: theme.palette.primary.main,
  },
}));

export function DashboardButton({
  label,
  value,
  active,
  showAction = false,
  actionNode = <ArrowForwardIosIcon fontSize="small" />,
  dividerText,
  onClick = noop,
}: {
  label?: React.ReactNode;
  value?: React.ReactNode;
  active?: boolean;
  showAction?: boolean;
  actionNode?: React.ReactNode | null;
  dividerText?: React.ReactNode;
  onClick?: () => void;
}) {
  const classes = useStyles();

  const content = (
    <>
      <div className={classes.root}>
        <div style={{ width: '100%' }}>
          {label && (
            <Typography
              paragraph={true}
              variant="body1"
              className={clsx(classes.label, { [classes.primary]: active })}
            >
              {label}
            </Typography>
          )}
          <Typography
            paragraph={true}
            variant="button"
            className={clsx(classes.value, { [classes.primary]: active })}
          >
            {value}
          </Typography>
        </div>
        {actionNode && <div className={classes.actionText}>{actionNode}</div>}
      </div>
      {dividerText ? (
        <DashboardDivider text={dividerText} />
      ) : (
        <Divider className={clsx({ [classes.underline]: active })} />
      )}
    </>
  );

  return (
    <>
      {showAction && (
        <CardActionArea onClick={onClick}>{content}</CardActionArea>
      )}
      {!showAction && content}
    </>
  );
}
