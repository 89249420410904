import React from 'react';
import { TableFieldCells, TableFieldColumn } from '@eas/common-web';
import { Address, Contact } from '@models';

export function useContactColumns(): TableFieldColumn<Contact>[] {
  return [
    {
      name: 'Typ kontaktu',
      datakey: 'type.label',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Aktivní',
      datakey: 'active',
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      name: 'Titul před',
      datakey: 'titleBefore',
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Jméno',
      datakey: 'firstName',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Příjmení',
      datakey: 'lastName',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Titul za',
      datakey: 'titleAfter',
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Útvar',
      datakey: 'department',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Datová schránka',
      datakey: 'dataBox.identifier',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'E-mail',
      datakey: 'email',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Telefon',
      datakey: 'phone',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: 'Adresa',
      datakey: 'address',
      width: 300,
      CellComponent: function Cell(props) {
        const label = getAddressLabel(props.value);

        return <TableFieldCells.TextCell {...props} value={label} />;
      },
    },
  ];
}

const getAddressLabel = (address?: Address) => {
  const {
    street,
    city,
    descriptiveNumber,
    orientationNumber,
    zipCode,
    country,
    ...props
  } = address || {};

  if (props.label) {
    return props.label;
  }

  let label = '';

  if (street) {
    label = street;
  } else if (city) {
    label = city;
  }

  if (descriptiveNumber) {
    label += label.length > 0 ? ` ${descriptiveNumber}` : descriptiveNumber;

    if (orientationNumber) {
      label += `/${orientationNumber}`;
    }
  } else if (orientationNumber) {
    label += label.length > 0 ? ` ${orientationNumber}` : orientationNumber;
  }

  if (zipCode) {
    label += label.length > 0 ? `, ${zipCode.code}` : zipCode.code;
  }

  if (city) {
    label += label.length > 0 ? ` ${city}` : city;
  }

  if (country) {
    label += label.length > 0 ? `, ${country.label}` : country.label;
  }

  return label;
};
