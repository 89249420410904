import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { User } from '@models';

export function useColumns(): TableColumn<User>[] {
  const intl = useIntl();
  const { columnActive } = DictionaryEvidence.useDictionaryColumns<User>();

  return [
    columnActive,
    {
      datakey: 'username',
      name: intl.formatMessage({
        id: 'ES__DICT_USERS__TABLE__COLUMN__USERNAME',
        defaultMessage: 'Uživatelské jméno',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'lastName',
      name: intl.formatMessage({
        id: 'ES__DICT_USERS__TABLE__COLUMN__LAST_NAME',
        defaultMessage: 'Příjmení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'firstName',
      name: intl.formatMessage({
        id: 'ES__DICT_USERS__TABLE__COLUMN__FIRST_NAME',
        defaultMessage: 'Jméno',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'email',
      name: intl.formatMessage({
        id: 'ES__DICT_USERS__TABLE__COLUMN__EMAIL',
        defaultMessage: 'Email',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'phone',
      name: intl.formatMessage({
        id: 'ES__DICT_USERS__TABLE__COLUMN__PHONE',
        defaultMessage: 'Telefon',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'titlesBefore',
      name: intl.formatMessage({
        id: 'ES__DICT_USERS__TABLE__COLUMN__DEGREE_BEFORE',
        defaultMessage: 'Titul před',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'titlesAfter',
      name: intl.formatMessage({
        id: 'ES__DICT_USERS__TABLE__COLUMN__DEGREE_AFTER',
        defaultMessage: 'Titul za',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
  ];
}
