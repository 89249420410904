enum Element {
  /**
   * AKCE: Přidat přílohu
   */
  ADD_ATTACHMENT = 'ADD_ATTACHMENT',

  /**
   * AKCE: Přidat přílohy hromadně
   */
  ADD_ATTACHMENT_BULK = 'ADD_ATTACHMENT_BULK',

  /**
   * AKCE: Upravit přílohu
   */
  EDIT_ATTACHMENT = 'EDIT_ATTACHMENT',

  /**
   * AKCE: Revalidovat přílohu
   */
  REVALIDATE_ATTACHMENT = 'REVALIDATE_ATTACHMENT',

  /**
   * AKCE: Převést do PDF
   */
  CONVERT_TO_PDF = 'CONVERT_TO_PDF',

  /**
   * AKCE: Opatřit el. pečetí
   */
  SIGN_ATTACHMENT = 'SIGN_ATTACHMENT',

  /**
   * AKCE: Smazat přílohu
   */
  DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',

  /**
   * AKCE: Přidat koncept
   */
  COPY_DRAFT = 'COPY_DRAFT',

  /**
   * AKCE: Přidat kopii
   */
  COPY_ATTACHMENT = 'COPY_ATTACHMENT',

  /**
   * AKCE: Stáhnout validační PDF report
   */
  DOWNLOAD_VALIDATION_REPORT = 'DOWNLOAD_REPORT',
}

export default Element;
