import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    { key: 'esFile', label: 'Spis' },
    { key: 'proposerDistinction', label: 'Rozlišení navrhovatele' },
    { key: 'proposerType', label: 'Typ navrhovatele' },
    { key: 'subject', label: 'Subjekt' },
    { key: 'type', label: 'Typ účastníka' },
  ];
}
