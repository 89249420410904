import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  ListSource,
  Select,
  TableFieldCellProps,
  useSubscriptionTypes,
} from '@eas/common-web';
import { NotificationPreference } from '@models';
import { NotificationSubscriptionType, NotificationType } from '@enums';

const filterSubscriptionTypes = (
  subscriptionTypes: { id: NotificationSubscriptionType }[],
  allowedTypes: NotificationType[]
) => {
  if (!allowedTypes?.includes(NotificationType.APPLICATION)) {
    return subscriptionTypes.filter(
      (item) =>
        ![
          NotificationSubscriptionType.BOTH,
          NotificationSubscriptionType.APPLICATION,
        ].includes(item.id)
    );
  } else if (!allowedTypes.includes(NotificationType.EMAIL)) {
    return subscriptionTypes.filter(
      (item) =>
        ![
          NotificationSubscriptionType.BOTH,
          NotificationSubscriptionType.EMAIL,
        ].includes(item.id)
    );
  }

  return subscriptionTypes;
};

export function useNotificationColumns(
  editing: boolean,
  setNotificationPreferences: (notificationEvents: any) => void
) {
  const intl = useIntl();

  const SelectTypeCell = (dataHook: () => ListSource<any>) => {
    return useMemo(
      () =>
        function SelectCell({
          value,
          rowValue,
        }: TableFieldCellProps<NotificationPreference>) {
          const source = dataHook();
          source.items = filterSubscriptionTypes(
            source.items,
            rowValue.event.allowedTypes ?? []
          );

          return (
            <Select
              disabled={!editing}
              source={source}
              value={value}
              clearable={false}
              onChange={(value) =>
                setNotificationPreferences((prev: NotificationPreference[]) => {
                  const foundIndex = prev.findIndex(
                    (item) => item.event.id === rowValue.event.id
                  );

                  return [
                    ...prev.slice(0, foundIndex),
                    { ...prev[foundIndex], type: value },
                    ...prev.slice(foundIndex + 1),
                  ];
                })
              }
              valueIsId={true}
            />
          );
        },
      [dataHook, editing]
    );
  };

  return [
    {
      name: intl.formatMessage({
        id: 'ES__NOTIFICATIONS__COLUMN__EVENT',
        defaultMessage: 'Název události',
      }),
      datakey: 'event.name',
      width: 300,
    },
    {
      name: intl.formatMessage({
        id: 'ES__NOTIFICATIONS__COLUMN__TYPE',
        defaultMessage: 'Způsob odběru',
      }),
      datakey: 'type',
      width: 300,
      CellComponent: SelectTypeCell(useSubscriptionTypes),
    },
  ];
}
