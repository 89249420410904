import React, { useContext, useMemo } from 'react';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { TaskField } from '@composite/tasks/task-field';
import { EsFile } from '@models';
import { UIElement } from '@enums';
import { TASK_SOURCE } from '../../../es-file/es-file-hooked-sources';
import { usePlenaryOpinionPermission } from '../../../es-file/es-file-permission';

export function TasksFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } = useContext<DetailHandle<EsFile>>(
    DetailContext
  );

  const { hasPermission } = usePlenaryOpinionPermission(source.data);

  // eslint-disable-next-line prettier/prettier
  const disabledTasks =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.PlenaryOpinionFile.TASKS);
  const taskSource = hookedSources[TASK_SOURCE]?.source;

  const taskPreFilters = useMemo(
    () => [
      {
        operation: ApiFilterOperation.EQ,
        field: 'esFile.id',
        value: source.data?.id,
      },
    ],
    [source.data?.id]
  );
  return (
    <>
      <TaskField
        disabled={disabledTasks}
        source={taskSource}
        preFilters={taskPreFilters}
      />
    </>
  );
}
