import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  MonthlyStatisticOutputData,
  MonthlyStatisticOutputDataInput,
} from '@models';
import { CourtFileType, EvidenceApiUrl, Statistic } from '@enums';
import { useAssignedAndResolvedCountsVisualisation } from '../assigned-and-resolved-counts/assigned-and-resolved-counts-hook';
import { useAvgProceedingDurationVisualisation } from '../avg-proceeding-duration/avg-proceeding-duration-hook';
import { StatisticCard } from '../card/card';
import { useSettlementMethodCountsVisualisation } from '../settlement-method-counts/settlement-method-counts-hook';
import { useStatistic } from '../statistics-hook';
import { useSubmissionCountsVisualisation } from '../submission-counts/submission-counts-hook';
import { useUnresolvedByJudgeCountsVisualisation } from '../unresolved-by-judge-counts/unresolved-by-judge-counts-hook';
import { Header } from './monthly-statistic-output-header';

export function MonthlyStatisticOutput() {
  const url = EvidenceApiUrl.STATISTICS_MONTHLY_OUTPUT;

  const [selectedStatistic, setSelectedStatistic] = useState(
    Statistic.SUBMISSION_COUNTS
  );
  const { data, setParams, params } = useStatistic<
    MonthlyStatisticOutputDataInput,
    MonthlyStatisticOutputData
  >({
    url,
    initialParams: {
      month: new Date().getMonth().toString(),
      year: new Date().getFullYear(),
    },
  });
  const content = useStatisticVisualisation(selectedStatistic, data);

  return (
    <Grid item xs={12}>
      <StatisticCard
        url={url}
        params={params}
        title="Měsíční statistický report"
        Header={() => (
          <Header
            params={params}
            setParams={setParams}
            selectedStatistic={selectedStatistic}
            setSelectedStatistic={setSelectedStatistic}
          />
        )}
        deps={[selectedStatistic]}
        {...content}
      />
    </Grid>
  );
}

const useStatisticVisualisation = (
  statistic: Statistic,
  data?: MonthlyStatisticOutputData
) => {
  const chartHeight = 350;
  const maxTableRows = 10;

  const submissionCounts = useSubmissionCountsVisualisation({
    data: data?.submissionCounts,
    chartHeight,
    maxTableRows,
  });

  const unresolvedByJudgesCounts = useUnresolvedByJudgeCountsVisualisation({
    data: data?.unresolvedByJudgeCounts,
    chartHeight,
    maxTableRows,
  });

  const settlementMethodCounts = useSettlementMethodCountsVisualisation({
    data: data?.settlementMethodCounts,
    chartHeight,
    maxTableRows,
  });

  const assignedAndResolvedPlenaryCounts =
    useAssignedAndResolvedCountsVisualisation({
      data: data?.assignedAndResolvedPlenaryCounts,
      courtFileType: CourtFileType.PLENARY,
    });

  const assignedAndResolvedSenateCounts =
    useAssignedAndResolvedCountsVisualisation({
      data: data?.assignedAndResolvedSenateCounts,
      courtFileType: CourtFileType.SENATE,
    });

  const avgProceedingDuration = useAvgProceedingDurationVisualisation({
    data: data?.avgProceedingDuration,
  });

  switch (statistic) {
    case Statistic.SUBMISSION_COUNTS:
      return submissionCounts;
    case Statistic.UNRESOLVED_BY_JUDGE_COUNTS:
      return unresolvedByJudgesCounts;
    case Statistic.SETTLEMENT_METHOD_COUNTS:
      return settlementMethodCounts;
    case Statistic.ASSIGNED_AND_RESOLVED_PLENARY_COUNTS:
      return assignedAndResolvedPlenaryCounts;
    case Statistic.ASSIGNED_AND_RESOLVED_SENATE_COUNTS:
      return assignedAndResolvedSenateCounts;
    case Statistic.AVG_PROCEEDING_DURATION:
      return avgProceedingDuration;
  }
};
