import {
  TreeOptions,
  useAutocompleteSource,
  useTreeSource,
} from '@eas/common-web';
import { DictProceedingsSubject, TreeObject } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useProceedingsSubjects() {
  return useAutocompleteSource<DictProceedingsSubject>({
    url: `${EvidenceApiUrl.DICT_PROCEEDINGS_SUBJECT}/autocomplete`,
  });
}

export function useProceedingsSubjectsTree(
  options: Partial<TreeOptions<TreeObject>> = {}
) {
  return useTreeSource({
    ...options,
    url: EvidenceApiUrl.DICT_PROCEEDINGS_SUBJECT,
    getKey: (item) => item.id,
  });
}
