import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography/Typography';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import {
  DetailContext,
  DetailMode,
  TableFieldToolbarButton,
} from '@eas/common-web';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { DecisionText as DecisionTextType } from '@models';
import { UIElement } from '@enums';
import { useDecisionPermission } from '../decision-permission';
import { useConfirmCopyDialog } from '../dialog-actions/confirm-copy-hook';
import { useShowDifferenceDialog } from '../dialog-actions/show-difference-hook';

export function AnonymizedDecisionActions({
  fieldName,
}: {
  fieldName: keyof DecisionTextType;
}) {
  const { mode, source } = useContext(DetailContext);

  const intl = useIntl();
  const classes = useStyles();

  const { handleShowDifference } = useShowDifferenceDialog(fieldName);
  const { handleConfirmCopy } = useConfirmCopyDialog(fieldName);
  const { hasPermission } = useDecisionPermission(source.data);

  return (
    <>
      {mode !== DetailMode.VIEW &&
        hasPermission(UIElement.Decision.ANONYMIZED_TEXT) && (
          <>
            <TableFieldToolbarButton
              IconComponent={SystemUpdateAltIcon}
              show={true}
              disabled={false}
              title={intl.formatMessage({
                id: 'ES__DECISION_TEXT_TOOLBAR__SYNC__BUTTON',
                defaultMessage: 'Dotáhnout text',
              })}
              onClick={handleConfirmCopy}
              color="default"
              variant="text"
            >
              <Typography variant="body2" />
            </TableFieldToolbarButton>
            <div className={classes.divider} />
          </>
        )}
      <TableFieldToolbarButton
        IconComponent={CompareArrowsIcon}
        show={true}
        disabled={false}
        title={intl.formatMessage({
          id: 'ES__DECISION_TEXT_TOOLBAR__DIFF__BUTTON',
          defaultMessage:
            'Rozdíl mezi textem rozhodnutí a anonymizovaným textem',
        })}
        onClick={handleShowDifference}
        color="default"
        variant="text"
      >
        <Typography variant="body2" />
      </TableFieldToolbarButton>
      <div className={classes.divider} />
    </>
  );
}
