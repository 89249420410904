import { merge } from 'lodash';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { ApiFilterOperation, EvidenceProps, Filter } from '@eas/common-web';
import { useDefaultIndexRole } from '@modules/role/role-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { EsFile } from '@models';
import { Agenda, EsFileState } from '@enums';
import { disableBtnFactory } from '../es-file/es-file-utils';
import { useEsFiles } from '../es-file/es-files';

export function useInfFiles({
  evidenceProps,
}: {
  evidenceProps?: Partial<EvidenceProps<EsFile>>;
}) {
  const intl = useIntl();

  const { defaultSettlementMethods } = useContext(StaticDataContext);
  const [defaultInfIndexRole] = useDefaultIndexRole(Agenda.INF);

  const defaultSettlementMethod = defaultSettlementMethods.find(
    (method) => method.agenda === Agenda.INF
  );

  const defaultPreFilters = [
    ...((evidenceProps?.tableProps?.defaultPreFilters ?? []) as Filter[]),
    {
      field: 'agenda.id',
      operation: ApiFilterOperation.EQ,
      value: Agenda.INF,
    },
  ];

  const defaultInfFile = {
    id: uuidv4(),
    created: new Date().toISOString(),
    agenda: Agenda.INF,
    state: EsFileState.NEW,
    incomingNumber: `SPR-TEST-${Math.ceil(Math.random() * 1000)}`,
    deliveryDate: new Date().toISOString(),
    settlementMethods: defaultSettlementMethod ? [defaultSettlementMethod] : [],
    indexUserRole: defaultInfIndexRole!,
  };

  const props = merge(
    {
      evidenceProps: {
        version: 5,
        tableProps: {
          defaultPreFilters,
          tableName: intl.formatMessage({
            id: 'ES__INF_FILES__TABLE__TITLE',
            defaultMessage: 'Spisy INF',
          }),
        },
        detailProps: {
          toolbarProps: {
            title: intl.formatMessage({
              id: 'ES__INF_FILES__DETAIL__TITLE',
              defaultMessage: 'Spis INF',
            }),
            disableBtn: disableBtnFactory(defaultInfFile),
          },
          initNewItem: () => ({ ...defaultInfFile }),
        },
      },
    },
    { evidenceProps }
  );

  return useEsFiles(props);
}
