import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  AppSettingsProvider,
  FilesProvider,
  FormAnchorProvider,
  LocaleName,
  LocaleProvider,
  NavigationProvider,
  PromptProvider,
  SnackbarProvider,
  UserProvider,
  ValidationProvider,
  WebsocketProvider,
  useEventCallback,
} from '@eas/common-web';
import { AuthProvider } from '@components/auth/auth-provider';
import { ThemeProvider } from '@components/theme/theme-provider';
import { Me } from '@models';
import { Role } from '@enums';
import { AppPublic } from './app-public';
import { AppSecure } from './app-secure';

function removeDuplicates<T>(arr: T[]) {
  return [...new Set(arr)];
}

export function App() {
  const checkAuthority = useEventCallback(
    (user: Me, role: string, state: { agenda?: string }) => {
      const isSuperAdmin = user.userRoles
        .map((userRole) => userRole.role)
        .includes(Role.SUPERADMIN);

      if (isSuperAdmin) {
        return true;
      }

      const nonAgendaRestricted = user.userRoles.filter(
        (userRole) =>
          userRole.role !== Role.SOLVER &&
          userRole.role !== Role.INDEX &&
          userRole.role !== Role.READER
      );

      const hasNonAgendaRestricted = nonAgendaRestricted
        .map((userRole) => userRole.role)
        .includes(role as Role);

      if (hasNonAgendaRestricted) {
        return true;
      }

      const agendaRestricted = user.userRoles.filter(
        (userRole) =>
          userRole.role === Role.SOLVER ||
          userRole.role === Role.INDEX ||
          userRole.role === Role.READER
      );

      const hasAgendaRestricted = agendaRestricted
        .map((userRole) => userRole.role)
        .includes(role as Role);

      const allAgendas = user.userRoles
        .map((userRole) => userRole?.agendas ?? [])
        .flat();

      const agendas = removeDuplicates(allAgendas);

      return hasAgendaRestricted && state?.agenda
        ? agendas.includes(state?.agenda)
        : hasAgendaRestricted;
    }
  );

  return (
    <LocaleProvider defaultLocale={LocaleName.cs}>
      <ValidationProvider>
        <ThemeProvider>
          <SnackbarProvider timeout={3000}>
            <AuthProvider>
              <UserProvider
                meUrl="/api/evidence-spisu/user/context"
                logoutUrl="/api/evidence-spisu/logout"
                checkPermission={checkAuthority}
              >
                <AppSettingsProvider url="/api/evidence-spisu/app-settings">
                  <ThemeProvider>
                    <BrowserRouter>
                      <FormAnchorProvider>
                        <FilesProvider url="/api/evidence-spisu/files">
                          <NavigationProvider>
                            <PromptProvider>
                              <Switch>
                                <Route path="/es">
                                  <WebsocketProvider
                                    wsUrl="/api/evidence-spisu/stomp"
                                    destinations={[
                                      '/user/queue/session',
                                      '/user/queue/notification',
                                    ]}
                                    debug={false}
                                  >
                                    <AppSecure />
                                  </WebsocketProvider>
                                </Route>
                                <Route path="/">
                                  <AppPublic />
                                </Route>
                              </Switch>
                            </PromptProvider>
                          </NavigationProvider>
                        </FilesProvider>
                      </FormAnchorProvider>
                    </BrowserRouter>
                  </ThemeProvider>
                </AppSettingsProvider>
              </UserProvider>
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </ValidationProvider>
    </LocaleProvider>
  );
}
