import { noop } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  NotificationContext,
  TableField,
  fetchNotificationPreferences,
} from '@eas/common-web';
import { DashboardDivider } from '@modules/dashboard/divider';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { NotificationEvent, NotificationPreference } from '@models';
import { NotificationSubscriptionType } from '@enums';
import { useNotificationColumns } from './notifications-columns';
import { NotificationsToolbar } from './notifications-toolbar';

const useStyles = makeStyles({
  card: {
    width: '100%',
    borderRadius: 16,
    background:
      'linear-gradient(0deg, rgb(245 246 247) 0%, rgb(255 255 255) 100%)',
    margin: '0 0 24px',
    padding: 20,
  },
});

export function Notifications() {
  const classes = useStyles();

  const { notificationEvents } = useContext(StaticDataContext);
  const { notificationPreferenceUrl } = useContext(NotificationContext);

  const [editing, setEditing] = useState(false);
  const [defaultPreferences, setDefaultPreferences] = useState<{
    [key: string]: NotificationSubscriptionType;
  }>({});
  const [notificationPreferences, setNotificationPreferences] = useState<
    NotificationPreference[] | undefined
  >(undefined);

  const notificationColumns = useNotificationColumns(
    editing,
    setNotificationPreferences
  );

  useEffect(() => {
    const loadNotifications = async () => {
      const notificationPreferences = await fetchNotificationPreferences(
        notificationPreferenceUrl
      ).json();

      setDefaultPreferences(notificationPreferences);

      const preferences = notificationEvents
        .filter((event: NotificationEvent) =>
          Object.keys(notificationPreferences).includes(event.id)
        )
        .map((event: NotificationEvent) => ({
          event: event,
          type: notificationPreferences[event.id],
          defaultType: notificationPreferences[event.id],
        }));

      setNotificationPreferences(preferences);
    };

    loadNotifications();
  }, []);

  const resetDefaultValues = () => {
    setNotificationPreferences((prev) =>
      prev?.map((preference) => ({
        ...preference,
        type: defaultPreferences[preference.event.id],
      }))
    );
  };

  return (
    <>
      <Card className={classes.card} elevation={2}>
        <DashboardDivider
          text={
            <FormattedMessage
              id="ES__PROFILE__NOTIFICATIONS"
              defaultMessage="Nastavení notifikací"
            />
          }
        />
        <TableField
          value={notificationPreferences}
          ToolbarComponent={() => (
            <NotificationsToolbar
              editing={editing}
              setEditing={setEditing}
              resetDefaultValues={resetDefaultValues}
              notificationPreferences={notificationPreferences}
            />
          )}
          columns={notificationColumns}
          showRadioCond={() => false}
          showDetailBtnCond={() => false}
          onChange={noop}
          maxRows={5}
        />
      </Card>
    </>
  );
}
