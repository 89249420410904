import React from 'react';
import Grid from '@material-ui/core/Grid';
import { UnresolvedByJudgeData } from '@models';
import { EvidenceApiUrl } from '@enums';
import { StatisticCard } from '../card/card';
import { DatedHeader } from '../card/dated-header';
import { useStatisticWithoutParams } from '../statistics-hook';
import { useUnresolvedByJudgeCountsVisualisation } from './unresolved-by-judge-counts-hook';

export function UnresolvedByJudgeCounts() {
  const url = EvidenceApiUrl.STATISTICS_UNRESOLVED;

  const { data } = useStatisticWithoutParams<UnresolvedByJudgeData>({
    url,
  });

  const { Visualisations } = useUnresolvedByJudgeCountsVisualisation({
    data: data?.unresolvedByJudgeCounts,
  });

  return (
    <Grid item xs={6}>
      <StatisticCard
        title="Počty nevyřízených soudních spisů podle roku podání a soudce"
        url={url}
        Header={() => (
          <DatedHeader
            date={{ dateFrom: data?.dateFrom, dateTo: data?.dateTo }}
          />
        )}
        Visualisations={Visualisations}
      />
    </Grid>
  );
}
