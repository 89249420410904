import React from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { ActionButton, TableFieldToolbarButton } from '@eas/common-web';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { NotificationPreference } from '@models';
import { NotificationSubscriptionType } from '@enums';
import { updateNotificationPreferences } from '../actions/update-notification-preferences-hook';

export const useCustomStyles = makeStyles(() => ({
  tableActions: {
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    paddingTop: 10,
    padding: '0 16px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
}));

interface NotificationsToolbarProps {
  editing: boolean;
  setEditing: (editing: boolean) => void;
  resetDefaultValues: () => void;
  notificationPreferences: NotificationPreference[] | undefined;
}

export function NotificationsToolbar({
  editing,
  setEditing,
  resetDefaultValues,
  notificationPreferences,
}: NotificationsToolbarProps) {
  const intl = useIntl();
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <div className={customClasses.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        {!editing && (
          <TableFieldToolbarButton
            IconComponent={EditIcon}
            show={true}
            disabled={false}
            title="Upravit"
            onClick={() => setEditing(true)}
            color="primary"
            variant="contained"
          >
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'ES__NOTIFICATION_TOOLBAR__EDIT__BUTTON',
                defaultMessage: 'Upravit',
              })}
            </Typography>
          </TableFieldToolbarButton>
        )}
        {editing && (
          <>
            <ActionButton
              promptKey="NOTIFICATION_SUBSCRIPTION_CHANGE"
              apiCall={() => {
                const result: {
                  [key: string]: NotificationSubscriptionType;
                } = {};
                notificationPreferences?.forEach((preference) => {
                  result[preference.event.id] =
                    preference.type as NotificationSubscriptionType;
                });
                return updateNotificationPreferences(result);
              }}
              buttonLabel={intl.formatMessage({
                id: 'ES__NOTIFICATION__SUBSCRIPTION__CHANGE',
                defaultMessage: 'Uložit změny',
              })}
              dialogText={intl.formatMessage({
                id: 'ES__NOTIFICATION__SUBSCRIPTION__CHANGE__TEXT',
                defaultMessage:
                  'Skutečně chcete změnit způsob odebírání notifikací?',
              })}
              dialogTitle={intl.formatMessage({
                id: 'ES__NOTIFICATION__SUBSCRIPTION__CHANGE__TITLE',
                defaultMessage: 'Varování',
              })}
              ButtonComponent={function Button({ label, onClick }) {
                return (
                  <TableFieldToolbarButton
                    IconComponent={CheckIcon}
                    show={true}
                    disabled={false}
                    title={label}
                    onClick={onClick}
                    color="primary"
                    variant="contained"
                  >
                    <Typography variant="body2">
                      {intl.formatMessage({
                        id: 'ES__NOTIFICATION_TOOLBAR__SAVE__BUTTON',
                        defaultMessage: 'Uložit',
                      })}
                    </Typography>
                  </TableFieldToolbarButton>
                );
              }}
              onSuccess={async () => setEditing(false)}
              dialogDisableBackdrop={true}
            />
            <TableFieldToolbarButton
              IconComponent={CloseIcon}
              show={true}
              disabled={false}
              title="Zrušit"
              onClick={() => {
                resetDefaultValues();
                setEditing(false);
              }}
              color="secondary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__NOTIFICATION_TOOLBAR__CANCEL__BUTTON',
                  defaultMessage: 'Zrušit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          </>
        )}
      </ButtonGroup>
    </div>
  );
}
