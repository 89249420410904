import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import {
  useRunningDeadlineColumns,
  useRunningDeadlineFiltersFields,
} from '@modules/es-file-court/court-file-columns';
import { RUNNING_DEADLINE_SOURCE } from '@modules/es-file/es-file-hooked-sources';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DeadlineState } from '@enums';
import { DeadlineField } from './deadline-field';

export function RunningDeadlineField({ disabled }: { disabled?: boolean }) {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode } = useContext(DetailContext);
  const { deadlineStates } = useContext(StaticDataContext);

  const disabledDeadlines = isLocked || mode !== DetailMode.VIEW || !!disabled;
  const deadlineSource = hookedSources[RUNNING_DEADLINE_SOURCE]?.source;
  const deadlinePreFilters = useMemo(
    () => [
      {
        field: 'state.id',
        operation: ApiFilterOperation.EQ,
        value: DeadlineState.RUNNING,
      },
    ],
    []
  );
  const deadlineColumns = useRunningDeadlineColumns();
  const deadlineFieldFilters = useRunningDeadlineFiltersFields();

  return (
    <DeadlineField
      display="IN_ES_FILE"
      label={
        <FormattedMessage
          id="ES__ES_FILES__DETAIL__DEADLINES_PANEL_TITLE"
          defaultMessage="Běžící lhůty"
        />
      }
      columns={deadlineColumns}
      preFilters={deadlinePreFilters}
      filtersFields={deadlineFieldFilters}
      deadlineStates={deadlineStates}
      disabled={disabledDeadlines}
      source={deadlineSource}
    />
  );
}
