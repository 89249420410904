const NOTIFICATION_TEMPLATES = {
  title: 'Šablony notifikací',
  description: 'Přehled šablon pro vytváření notifikací.',
  actions: [
    { name: 'Nový', description: 'Vytvoření nové šablony.' },

    {
      name: 'Upravit',
      description: 'Uživatel může upravovat jednotlivé atributy šablony.',
    },
    {
      name: 'Zneplatnit',
      description: 'Šablona se zneaktivní a notifikace nebude vytvářena.',
    },

    {
      name: 'Obnovit platnost',
      description: 'Šablona se aktivuje a notifikace bude vytvářena.',
    },
    {
      name: 'Ukázka',
      description: 'Zobrazení ukázky naformátovaného textu a předmětu.',
    },

    { name: 'Smazat', description: 'Odstranění šablony ze systému' },
  ],
};

const ALL_NOTIFICATIONS = {
  title: 'Všechny notifikace',
  description: 'Přehled všech notifikací evidovaných v systému',
};

export const NOTIFICATIONS = {
  title: 'Notifikace',
  sections: [NOTIFICATION_TEMPLATES, ALL_NOTIFICATIONS],
};
