import { Role, UIElement } from '@enums';

export const internalDocumentAccessMap = new Map([
  [
    Role.INDEX,
    [
      UIElement.InternalDocument.SUMMARY,
      UIElement.InternalDocument.NOTE,
      UIElement.InternalDocument.PAGE_COUNT,
      UIElement.InternalDocument.DECISION,
      UIElement.Attachment.ADD_ATTACHMENT,
      UIElement.Attachment.EDIT_ATTACHMENT,
      UIElement.Attachment.DELETE_ATTACHMENT,
      UIElement.Attachment.COPY_DRAFT,
      UIElement.InternalDocument.EDIT_DOCUMENT_BUTTON,
      UIElement.InternalDocument.CANCEL_BUTTON,
      UIElement.InternalDocument.REOPEN_INTERNAL_DOCUMENT_BUTTON,
    ],
  ],
  [Role.LUSTRUM, [UIElement.InternalDocument.REOPEN_INTERNAL_DOCUMENT_BUTTON]],
  [
    Role.SECRETARY,
    [
      UIElement.InternalDocument.SUMMARY,
      UIElement.InternalDocument.NOTE,
      UIElement.InternalDocument.PAGE_COUNT,
      UIElement.InternalDocument.DECISION,
      UIElement.Attachment.ADD_ATTACHMENT,
      UIElement.Attachment.EDIT_ATTACHMENT,
      UIElement.Attachment.DELETE_ATTACHMENT,
      UIElement.Attachment.COPY_DRAFT,
      UIElement.InternalDocument.EDIT_DOCUMENT_BUTTON,
      UIElement.InternalDocument.CANCEL_BUTTON,
    ],
  ],
  [
    Role.SOLVER,
    [
      UIElement.InternalDocument.SUMMARY,
      UIElement.InternalDocument.NOTE,
      UIElement.InternalDocument.PAGE_COUNT,
      UIElement.Attachment.ADD_ATTACHMENT,
      UIElement.Attachment.EDIT_ATTACHMENT,
      UIElement.Attachment.DELETE_ATTACHMENT,
      UIElement.Attachment.COPY_DRAFT,
      UIElement.InternalDocument.EDIT_DOCUMENT_BUTTON,
    ],
  ],
]);
