import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { CourtFile } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { useSprColumns } from '../spr-file-columns';

export function useColumns(): TableColumn<CourtFile>[] {
  const intl = useIntl();
  const {
    columnDeliveryDate,
    columnIncomingNumber,
    columnSubmissionContent,
    columnWriters,
    columnSolverUserRoles,
  } = useSprColumns();

  const { states } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(states);

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__SPR_FILES_ALL__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.SPR_FILES_ALL}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    columnDeliveryDate,
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: intl.formatMessage({
        id: 'ES__SPR_FILES_ALL__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      valueMapper: TableCells.useSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    columnIncomingNumber,
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__SPR_FILES_ALL__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 120,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    columnSubmissionContent,
    columnWriters,
    columnSolverUserRoles,
  ];
}
