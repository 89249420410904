import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  NavigationContext,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { EvidenceBrowserUrl, UIElement } from '@enums';
import { ActionBar as DeliveredDocumentActionbar } from '../document-delivered/delivered-document-actionbar';
import { useDeliveredDocumentPermission } from '../document/document-permission';
import { useNewCourtFileDialog } from './dialog-actions/new-court-file-hook';
import { useNewInfDialog } from './dialog-actions/new-inf-hook';
import { useNewSprDialog } from './dialog-actions/new-spr-hook';
import { useReturnDialog } from './dialog-actions/return-hook';

export function ActionBar() {
  const { navigate } = useContext(NavigationContext);
  const { mode, source, onPersisted, setMode } = useContext(DetailContext);
  const { tableRef } = useContext(EvidenceContext);

  const intl = useIntl();

  const { callApi: returnApiCall, showButton: showReturnButton } =
    useReturnDialog();

  const {
    callApi: newCourtFileApiCall,
    showButton: showNewCourtFileButton,
    redirectBag: newCourtFileRedirectBag,
  } = useNewCourtFileDialog();

  const {
    callApi: newSprApiCall,
    showButton: showNewSprButton,
    redirectBag: newSprRedirectBag,
  } = useNewSprDialog();

  const {
    callApi: newInfApiCall,
    showButton: showNewInfButton,
    redirectBag: newInfRedirectBag,
  } = useNewInfDialog();

  const { hasPermission } = useDeliveredDocumentPermission(source.data);

  return (
    <>
      {showNewCourtFileButton && (
        <DetailActionButton
          promptKey="NEW_COURT_FILE"
          apiCall={newCourtFileApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENTS_DELIVERED__ACTIONBAR__NEW_COURT_FILE',
            defaultMessage: 'Nový soudní spis',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENTS_DELIVERED__DIALOG_NEW_COURT_FILE__TITLE',
            defaultMessage: 'Nový soudní spis',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENTS_DELIVERED__DIALOG_NEW_COURT_FILE__TEXT',
            defaultMessage: 'Opravdu si přejete vytvořit nový soudní spis?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          buttonDisabled={
            !hasPermission(UIElement.DeliveredDocument.NEW_COURT_FILE)
          }
          {...newCourtFileRedirectBag}
        />
      )}
      {showNewSprButton && (
        <DetailActionButton
          promptKey="NEW_SPR"
          apiCall={newSprApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__ACTIONBAR__NEW_SPR',
            defaultMessage: 'Nové SPR',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__DIALOG_NEW_SPR__TITLE',
            defaultMessage: 'Nové SPR',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__DIALOG_NEW_SPR__TEXT',
            defaultMessage: 'Opravdu si přejete vytvořit nový SPR spis?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          buttonDisabled={!hasPermission(UIElement.DeliveredDocument.NEW_SPR)}
          {...newSprRedirectBag}
        />
      )}
      {showNewInfButton && (
        <DetailActionButton
          promptKey="NEW_INF"
          apiCall={newInfApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__ACTIONBAR__NEW_INF',
            defaultMessage: 'Nové INF',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__DIALOG_NEW_INF__TITLE',
            defaultMessage: 'Nové INF',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__DIALOG_NEW_INF__TEXT',
            defaultMessage: 'Opravdu si přejete vytvořit nový INF spis?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          buttonDisabled={!hasPermission(UIElement.DeliveredDocument.NEW_INF)}
          {...newInfRedirectBag}
        />
      )}
      <DeliveredDocumentActionbar />
      {showReturnButton && (
        <DetailActionButton
          promptKey="RETURN"
          apiCall={returnApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__ACTIONBAR__RETURN',
            defaultMessage: 'Vrátit',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__DIALOG_RETURN__TITLE',
            defaultMessage: 'Varování',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENT_DELIVERED__DIALOG_RETURN__TEXT',
            defaultMessage: 'Opravdu chcete vrátit dokument do spisové služby?',
          })}
          ButtonComponent={SecondaryDetailActionbarButton}
          buttonDisabled={!hasPermission(UIElement.DeliveredDocument.RETURN)}
          onSuccess={async () => {
            tableRef.current?.resetSelection();
            setMode(DetailMode.NONE);
            onPersisted(null);
          }}
        />
      )}
      {mode === DetailMode.VIEW && source.data?.id && (
        <PrimaryDetailActionbarButton
          label={intl.formatMessage({
            id: 'ES__DOCUMENTS_DELIVERED__ACTIONBAR__SCREENING',
            defaultMessage: 'Lustrovat',
          })}
          onClick={() =>
            navigate(EvidenceBrowserUrl.SCREENING, true, {
              action: 'SCREENING_FOR_DOCUMENT',
              data: source.data,
            })
          }
          disabled={
            !hasPermission(UIElement.DeliveredDocument.SCREENING_FOR_DOCUMENT)
          }
        />
      )}
    </>
  );
}
