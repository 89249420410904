import { getErrorMessage } from '@utils/error-message';
import { useContext, useRef } from 'react';
import {
  AbortableFetch,
  SnackbarContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { ErrorObject } from '@models';
import { EvidenceApiUrl } from '@enums';

/**
 * Api call
 *
 * @param id
 */
function callApi(id: string) {
  return abortableFetch(`${EvidenceApiUrl.NOTIFICATION_RECEIVED}/${id}/read`, {
    method: 'PUT',
  });
}

export function useRead({ refresh }: { refresh: () => void }) {
  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const { showSnackbar } = useContext(SnackbarContext);

  const handleRead = useEventCallback(async (id: string) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(id);

      await fetch.current.raw();

      refresh();
    } catch (e) {
      const err = e as ErrorObject<string>;

      const message = getErrorMessage(err);

      if (err.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  return { handleRead };
}
