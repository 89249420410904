/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const VERIFY_DATA_BOX: MessageType<'ISDS_CLIENT_ERROR'> = {
  ISDS_CLIENT_ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__SUBJECT__VERIFY_DATA_BOX__ERROR"
      defaultMessage="Nebyl nalezen subjekt se zadanou datovou schránkou."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default { VERIFY_DATA_BOX };
