import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useDeleteDraftDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback((draftId: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/${source.data?.id}/draft/${draftId}`,
      {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      }
    );
  });

  return {
    callApi,
  };
}
