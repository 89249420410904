import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DatedEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { RestMessage } from '@models';

export function useColumns(): TableColumn<RestMessage>[] {
  const intl = useIntl();
  const { columnCreated } = DatedEvidence.useDatedColumns<RestMessage>();

  const { naspisLogEventTypes } = useContext(StaticDataContext);
  const useEventTypes = () => useStaticListSource(naspisLogEventTypes);

  return [
    {
      datakey: 'eventType',
      filterkey: 'eventType.id',
      sortkey: 'eventType.name',
      name: intl.formatMessage({
        id: 'ES__NASPIS_LOG_RECORD__TABLE__COLUMN__EVENT_TYPE',
        defaultMessage: 'Typ události',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useEventTypes),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useEventTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    columnCreated,
    {
      datakey: 'currentDataboxId',
      name: intl.formatMessage({
        id: 'ES__NASPIS_LOG_RECORD__TABLE__COLUMN__CURRENT_DATA_BOX_ID',
        defaultMessage: 'Id stávající datové schránky uživatele',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'newDataboxId',
      name: intl.formatMessage({
        id: 'ES__NASPIS_LOG_RECORD__TABLE__COLUMN__NEW_DATA_BOX_ID',
        defaultMessage: 'Id nové datové schránky uživatele',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'email',
      name: intl.formatMessage({
        id: 'ES__NASPIS_LOG_RECORD__TABLE__COLUMN__EMAIL',
        defaultMessage: 'Email uživatele',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
  ];
}
