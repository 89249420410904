import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormTextField } from '@eas/common-web';

export function DeadlineDialog() {
  return (
    <>
      <FormTextField
        type="number"
        name="duration"
        label={
          <FormattedMessage
            id="ES__ES_FILES__DEADLINE_FORM_FIELDS__FIELD_LABEL__DURATION"
            defaultMessage="Počet dnů"
          />
        }
        autoFocus={true}
        labelOptions={{
          hide: true,
        }}
      />
    </>
  );
}
