import React, { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { Viewing } from '@models';
import { useViewingColumns } from './viewing-columns';
import { ViewingToolbar } from './viewing-toolbar';

export interface ViewingFieldProps {
  disabled: boolean;
  source?: ScrollableSource<Viewing>;
}

export function ViewingField({ disabled, source }: ViewingFieldProps) {
  const columns = useViewingColumns();

  const tableRef = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return <ViewingToolbar {...props} ref={editRef} disabled={disabled} />;
      },
    [disabled]
  );

  if (!source) {
    return <> Missing VIEWING_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__VIEWINGS__PANEL_TITLE"
          defaultMessage="Nahlížení do kárného řízení"
        />
      }
      expandable={true}
      defaultExpanded={true}
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        ref={tableRef}
        disabled={disabled}
        columns={columns}
        source={source}
        maxRows={7}
        ToolbarComponent={ToolbarComponent}
        onRowClick={(_, index) => {
          if (!disabled) {
            tableRef.current?.setSelectedIndex(index);
            editRef.current?.executeAction();
          }
        }}
        highlightSelectedRow={true}
        showDetailBtnCond={() => false}
      />
    </FormPanel>
  );
}
