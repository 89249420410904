import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useEditAssigningRulesDialog() {
  const callApi = useEventCallback((id: string, formData: any) =>
    abortableFetch(`${EvidenceApiUrl.ASSIGNING_RULES}/${formData.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    })
  );

  return {
    callApi,
  };
}
