import React, { useContext } from 'react';
import { DetailContext, DetailHandle } from '@eas/common-web';
import { EsFile } from '@models';
import { useCourtFilePermission } from '../../../es-file/es-file-permission';
import { DecisionFields } from '../../../es-file/fields/decisions/decisions';

export function DecisionsFields() {
  const { source } = useContext<DetailHandle<EsFile>>(DetailContext);

  const { hasPermission } = useCourtFilePermission(source.data);

  return (
    <>
      <DecisionFields hasPermission={hasPermission} />
    </>
  );
}
