import React from 'react';
import { useIntl } from 'react-intl';
import { Action, Evidence, actionsFactory } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { EvidenceApiUrl } from '@enums';

export function Actions() {
  const intl = useIntl();
  const validationSchema = actionsFactory.useValidationSchema();

  const evidence = useDictionaryEvidence<Action>({
    identifier: 'ACTIONS',
    apiProps: {
      url: EvidenceApiUrl.ACTIONS,
    },
    tableProps: {
      columns: actionsFactory.useColumns(),
      tableName: intl.formatMessage({
        id: 'EAS_ACTIONS_TABLE_TITLE',
        defaultMessage: 'Skripty',
      }),
    },
    detailProps: {
      entityName: 'EsFile',
      FieldsComponent: actionsFactory.Fields,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'EAS_ACTIONS_DETAIL_TITLE',
          defaultMessage: 'Skript',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
