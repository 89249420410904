import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { ChallengedAct } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useAddChallengedActDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback((formData: ChallengedAct) => {
    return abortableFetch(`${EvidenceApiUrl.ES_FILES}/challenged-act`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        ...formData,
        esFile: { id: source.data.id, agenda: source.data.agenda },
      }),
    });
  });

  return {
    callApi,
  };
}
