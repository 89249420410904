import React, { ReactElement, Ref, forwardRef } from 'react';
import {
  TableActionButton as ActionButton,
  ActionButtonHandle,
  TableActionButtonProps,
} from '@eas/common-web';

export const TableActionButton = forwardRef(function TableActionButton<
  T = unknown
>(props: TableActionButtonProps<T>, ref: Ref<ActionButtonHandle>) {
  return (
    <ActionButton
      {...props}
      ref={ref}
      dialogDisableBackdrop={props.dialogDisableBackdrop ?? true}
    />
  );
}) as <T = unknown>(
  p: TableActionButtonProps<T> & { ref?: Ref<ActionButtonHandle> }
) => ReactElement;
