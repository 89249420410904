import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictEnvelopeContent } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { useColumns } from './dict-envelope-content-columns';
import { Fields } from './dict-envelope-content-fields';
import { useValidationSchema } from './dict-envelope-content-schema';

export function DictEnvelopeContents() {
  const validationSchema = useValidationSchema();
  const intl = useIntl();

  const evidence = useDictionaryEvidence<DictEnvelopeContent>({
    version: 2,
    identifier: 'DICT_ENVELOPE_CONTENTS',
    apiProps: {
      url: EvidenceApiUrl.DICT_ENVELOPE_CONTENTS,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.ENVELOPE_CONTENT,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_ENVELOPE_CONTENTS__TABLE__TITLE',
        defaultMessage: 'Obsahy obálek',
      }),
      reportTag: ExportTags.DICT_ENVELOPE_CONTENTS,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.ENVELOPE_CONTENT,
      validationSchema,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_ENVELOPE_CONTENTS__DETAIL__TITLE',
          defaultMessage: 'Obsah obálky',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
