import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
  UserContext,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { Role } from '@enums';
import { useAddViewingDialog } from './dialog-actions/add-viewing-hook';
import { useEditViewingDialog } from './dialog-actions/edit-viewing-hook';
import { ViewingDialog } from './viewing-dialog';
import { useValidationSchema } from './viewing-schema';

export const ViewingToolbar = forwardRef(function ViewingsToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
  }: TableFieldToolbarProps & { disabled: boolean },
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();

  const intl = useIntl();

  const { hasPermission, user } = useContext(UserContext);
  const { source: detailSource } = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const viewings = tableSource.items;

  const validationSchema = useValidationSchema();

  const { callApi: addViewingApiCall } = useAddViewingDialog();
  const { callApi: editViewingApiCall } = useEditViewingDialog();

  /**
   * Disabled for users who are not ADMIN or did not create the viewing
   */
  const hasPermissionToEdit =
    hasPermission(Role.ADMIN) ||
    (selectedIndex &&
      tableSource.items?.[selectedIndex]?.createdBy?.id === user.id);

  const selectedViewing =
    selectedIndex !== undefined ? viewings?.[selectedIndex] : {};

  const isSelected = selectedIndex !== undefined;

  useEffect(() => {
    setSelectedIndex(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailSource]);

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_VIEWING"
          apiCall={addViewingApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__VIEWINGS_TOOLBAR__ADD_VIEWING',
            defaultMessage: 'Udělit právo nahlížení',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__VIEWINGS_TOOLBAR__ADD_VIEWING__TITLE',
            defaultMessage: 'Nahlížení',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__VIEWINGS_TOOLBAR__ADD_VIEWING__TEXT',
            defaultMessage: 'Vyplňte Formulář.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__VIEWINGS_TOOLBAR__ADD_VIEWING__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={ViewingDialog}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formValidationSchema={validationSchema}
          dialogWidth={700}
        />
        <DetailActionButton
          promptKey="EDIT_VIEWING"
          apiCall={(id, formData) =>
            editViewingApiCall(
              id,
              tableSource.items![selectedIndex!].id,
              formData
            )
          }
          ref={ref}
          buttonLabel={intl.formatMessage({
            id: 'ES__VIEWINGS_TOOLBAR__EDIT_VIEWING',
            defaultMessage: 'Editovat právo nahlížení',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__VIEWINGS_TOOLBAR__EDIT_VIEWING__TITLE',
            defaultMessage: 'Nahlížení',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__VIEWINGS_TOOLBAR__EDIT_VIEWING__TEXT',
            defaultMessage: 'Vyplňte formulář.',
          })}
          FormFields={ViewingDialog}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={disabled || !isSelected || !hasPermissionToEdit}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__VIEWINGS_TOOLBAR__EDIT_VIEWING__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formInitialValues={selectedViewing}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formValidationSchema={validationSchema}
          dialogWidth={700}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    disabled: boolean;
    ref: Ref<ActionButtonHandle>;
  }
) => ReactElement;
