import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useReopenDialog } from '@modules/document/dialog-actions/reopen-hook';
import { useOutgoingDocumentPermission } from '@modules/document/document-permission';
import { useAddTaskDialog } from '@composite/tasks/dialog-actions/add-task-hook';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { OutgoingDocument, Task } from '@models';
import { TaskState, TaskType, UIElement } from '@enums';
import { useCancelDialog } from '../document/dialog-actions/cancel-hook';
import { useForwardToDispatch } from './dialog-actions/forward-to-dispatch-hook';
import { useForwardToSend } from './dialog-actions/forward-to-send-hook';
import { useReturnSend } from './dialog-actions/return-send-hook';

const useStyles = makeStyles(() => ({
  wrapper: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 25,
  },
}));

export function ActionBar() {
  const classes = useStyles();
  const { mode, source } =
    useContext<DetailHandle<OutgoingDocument>>(DetailContext);
  const intl = useIntl();

  const { callApi: forwardToSendApiCall, showButton: showForwardToSendButton } =
    useForwardToSend();

  const { callApi: returnSendApiCall, showButton: showReturnSendButton } =
    useReturnSend();

  const {
    callApi: forwardToDispatchApiCall,
    showButton: showForwardToDispatchButton,
    ResultFields,
  } = useForwardToDispatch();

  const { callApi: cancelApiCall, showButton: showCancelButton } =
    useCancelDialog();

  const { hasPermission } = useOutgoingDocumentPermission();

  const { AddTaskButton, disabledBtn: disabledAddTaskBtn } = useAddTaskDialog();
  const { showButton: showReopenButton, ReopenButton } = useReopenDialog();

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div className={classes.wrapper}>
          <div>
            {showForwardToSendButton && (
              <DetailActionButton
                promptKey="FORWARD_TO_SEND"
                apiCall={forwardToSendApiCall}
                buttonLabel={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENTS__ACTIONBAR__FORWARD_TO_SEND',
                  defaultMessage: 'Předat k odeslání',
                })}
                dialogTitle={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENTS__DIALOG_FORWARD_TO_SEND__TITLE',
                  defaultMessage: 'Předání dokumentu k odeslání',
                })}
                dialogText={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENT__DIALOG_FORWARD_TO_SEND__TEXT',
                  defaultMessage: 'Skutečně chcete předat dokument k odeslání?',
                })}
                ButtonComponent={PrimaryDetailActionbarButton}
                buttonDisabled={
                  !hasPermission(UIElement.OutgoingDocument.FORWARD_TO_SEND)
                }
              />
            )}
            {showForwardToDispatchButton && (
              <DetailActionButton
                promptKey="FORWARD_TO_DISPATCH"
                apiCall={forwardToDispatchApiCall}
                buttonLabel={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENTS__ACTIONBAR__FORWARD_TO_DISPATCH',
                  defaultMessage: 'Předat k vypravení',
                })}
                dialogTitle={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENTS__DIALOG_FORWARD_TO_DISPATCH__TITLE',
                  defaultMessage: 'Předání dokumentu k vypravení',
                })}
                dialogText={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENT__DIALOG_FORWARD_TO_DISPATCH__TEXT',
                  defaultMessage:
                    'Skutečně chcete předat dokument k vypravení?',
                })}
                ButtonComponent={PrimaryDetailActionbarButton}
                resultDialog={{
                  title: intl.formatMessage({
                    id: 'ES__OUTGOING_DOCUMENT__ACTIONBAR__FORWARD_TO_DISPATCH_RESULT__TITLE',
                    defaultMessage: 'Předání dokumentu k vypravení',
                  }),
                  text: intl.formatMessage({
                    id: 'ES__OUTGOING_DOCUMENTS__ACTIONBAR__FORWARD_TO_DISPATCH_RESULT__TEXT',
                    defaultMessage: 'Vypravení',
                  }),
                  FormFields: ResultFields,
                  width: 800,
                }}
                buttonDisabled={
                  !hasPermission(UIElement.OutgoingDocument.FORWARD_TO_DISPATCH)
                }
              />
            )}
            <AddTaskButton
              buttonLabel={intl.formatMessage({
                id: 'ES__OUTGOING_DOCUMENTS__ACTIONBAR__ADD_TASK',
                defaultMessage: 'Nová úloha',
              })}
              ButtonComponent={PrimaryDetailActionbarButton}
              formInitialValues={
                {
                  esFile: source.data?.esFile,
                  type: TaskType.CUSTOM,
                  state: TaskState.SOLVING,
                  document: source.data,
                } as Task
              }
              disabled={disabledAddTaskBtn}
            />
            {showReturnSendButton && (
              <DetailActionButton
                promptKey="RETURN_SEND"
                apiCall={returnSendApiCall}
                buttonLabel={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENTS__ACTIONBAR__RETURN_SEND',
                  defaultMessage: 'Vrátit odeslání',
                })}
                dialogTitle={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENTS__DIALOG_RETURN_SEND__TITLE',
                  defaultMessage: 'Vrácení odeslání',
                })}
                dialogText={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENT__DIALOG_RETURN_SEND__TEXT',
                  defaultMessage: 'Skutečně chcete vrátit odeslání dokumentu?',
                })}
                ButtonComponent={PrimaryDetailActionbarButton}
                buttonDisabled={
                  !hasPermission(UIElement.OutgoingDocument.RETURN_SEND)
                }
              />
            )}
            {showReopenButton && (
              <ReopenButton
                buttonDisabled={
                  !hasPermission(
                    UIElement.OutgoingDocument.REOPEN_OUTGOING_DOCUMENT_BUTTON
                  )
                }
              />
            )}
          </div>
          <div>
            {showCancelButton && (
              <DetailActionButton
                promptKey="CANCEL"
                apiCall={cancelApiCall}
                buttonLabel={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENTS__ACTIONBAR__CANCEL',
                  defaultMessage: 'Stornovat',
                })}
                dialogTitle={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENTS__DIALOG_CANCEL__TITLE',
                  defaultMessage: 'Storno',
                })}
                dialogText={intl.formatMessage({
                  id: 'ES__OUTGOING_DOCUMENT__DIALOG_CANCEL__TEXT',
                  defaultMessage: 'Skutečně chcete stornovat dokument?',
                })}
                ButtonComponent={SecondaryDetailActionbarButton}
                buttonDisabled={
                  !hasPermission(UIElement.OutgoingDocument.CANCEL_BUTTON)
                }
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
