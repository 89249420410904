import { useFetch, useScrollableSource } from '@eas/common-web';
import { DictionaryObject, Draft } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useDraftSource(esFileId: string) {
  return useScrollableSource<Draft>({
    url: `${EvidenceApiUrl.ES_FILES}/${esFileId}/draft/list`,
  });
}

/**
 * List all available templates.
 */
export function useTemplates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/draft/template/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
