import { abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function openEsFileApiCall(id: string) {
  return abortableFetch(`${EvidenceApiUrl.ES_FILES}/${id}/open`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}
