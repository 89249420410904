import { useFetch } from '@eas/common-web';
import { DictionaryObject } from '@models';
import { EvidenceApiUrl } from '@enums';

/**
 * List all available document task types.
 */
export function useTypes() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.TASKS}/type/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}

/**
 * List all available document task states.
 */
export function useStates() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.TASKS}/state/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}
