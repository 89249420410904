import {
  ApiFilterOperation,
  HookedSource,
  useScrollableSource,
} from '@eas/common-web';
import { useHistorySource } from '@composite/history/history-api';
import { DeliveredDocument, Document } from '@models';
import { DocumentType, EvidenceApiUrl, HistoryEntityType } from '@enums';

export const DEADLINE_SOURCE = 'DEADLINE_SOURCE';
export const DISPATCH_SOURCE = 'DISPATCH_SOURCE';

function useDeadlineSource(): HookedSource<Document> {
  const source = useScrollableSource();

  return {
    [DEADLINE_SOURCE]: {
      source,
      shouldLoad: (data) => {
        if (data.type === DocumentType.OUTGOING) {
          return true;
        }

        if (
          data.type === DocumentType.DELIVERED &&
          (data as DeliveredDocument).processed
        ) {
          return true;
        }
        return false;
      },
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.DOCUMENTS}/${id}/deadline/list`);
        source.setParams({
          size: 10,
          sort: [
            {
              field: 'endDate',
              type: 'FIELD',
              order: 'ASC',
            },
          ],
        });
      },
    },
  };
}

function useDispatchSource(): HookedSource<Document> {
  const source = useScrollableSource();

  return {
    [DISPATCH_SOURCE]: {
      source,
      shouldLoad: ({ type }) => type === DocumentType.OUTGOING,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/list`);
        source.setParams({
          size: 10,
          filters: [
            {
              operation: ApiFilterOperation.EQ,
              value: id,
              field: 'document.id',
            },
          ],
        });
      },
    },
  };
}

export function useDocumentsHookedSources(): HookedSource<Document> {
  const deadlines = useDeadlineSource();
  const dispatches = useDispatchSource();
  const history = useHistorySource({
    entityType: HistoryEntityType.DOCUMENT,
  });

  return {
    ...deadlines,
    ...dispatches,
    ...history,
  };
}
