import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { VIEWING_REQUEST_SOURCE } from '@modules/es-file/es-file-hooked-sources';
import { ViewingRequestField } from '@composite/viewing-requests/viewing-request-field';
import { UIElement } from '@enums';

export function ViewingsAndLoansFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const disabledViewingRequests =
    mode !== DetailMode.VIEW || !hasPermission(UIElement.CourtFile.VIEWINGS);

  const viewingRequestsSource = hookedSources[VIEWING_REQUEST_SOURCE]?.source;

  return (
    <>
      <ViewingRequestField
        disabled={disabledViewingRequests}
        source={viewingRequestsSource}
      />
    </>
  );
}
