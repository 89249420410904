import { EsFileState, Role, UIElement } from '@enums';

export const sprFileAccessMap = new Map([
  [
    EsFileState.NEW,
    new Map([
      [Role.LUSTRUM, [UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON]],
      [
        Role.HEAD_OSV,
        [
          UIElement.SprFile.INCOMING_NUMBER,
          UIElement.SprFile.DELIVERY_DATE,
          UIElement.SprFile.SUBMISSION_CONTENT,
          UIElement.SprFile.SOLVER_USER_ROLES,
          UIElement.SprFile.NOTES,
          UIElement.SprFile.CREATE_SPR_FILE_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_BUTTON,
          UIElement.SprFile.DELETE_SPR_FILE_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_AGENDA_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_STATE_BUTTON,
          UIElement.SprFile.INDEX_USER_ROLE,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.SprFile.INDEX_USER_ROLE,
          UIElement.SprFile.SOLVER_USER_ROLES,
          UIElement.SprFile.UPDATE_SPR_FILE_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.SprFile.INCOMING_NUMBER,
          UIElement.SprFile.DELIVERY_DATE,
          UIElement.SprFile.SUBMISSION_CONTENT,
          UIElement.SprFile.SOLVER_USER_ROLES,
          UIElement.SprFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.SprFile.WRITERS,
          UIElement.SprFile.CREATE_SPR_FILE_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_BUTTON,
          UIElement.SprFile.DELETE_SPR_FILE_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_AGENDA_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_STATE_BUTTON,
          UIElement.SprFile.INDEX_USER_ROLE,
        ],
      ],
    ]),
  ],
  [
    EsFileState.IN_PROGRESS,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.SprFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.SprFile.NOTES,
          UIElement.SprFile.CREATE_FILE_COVER_BUTTON,
          UIElement.SprFile.INDEX_USER_ROLE,
          UIElement.SprFile.UPDATE_SPR_FILE_BUTTON,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.SprFile.INDEX_USER_ROLE,
          UIElement.SprFile.SOLVER_USER_ROLES,
          UIElement.SprFile.UPDATE_SPR_FILE_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.SprFile.SUBMISSION_CONTENT,
          UIElement.SprFile.SOLVER_USER_ROLES,
          UIElement.SprFile.SETTLEMENT_METHODS,
          UIElement.SprFile.SETTLEMENT_DATE,
          UIElement.SprFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.SprFile.WRITERS,
          UIElement.SprFile.TASKS_AND_DEADLINES,
          UIElement.SprFile.DRAFTS,
          UIElement.SprFile.CREATE_FILE_COVER_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_STATE_BUTTON,
          UIElement.SprFile.INDEX_USER_ROLE,
        ],
      ],
      [
        Role.SOLVER,
        [
          UIElement.SprFile.SUBMISSION_CONTENT,
          UIElement.SprFile.SETTLEMENT_METHODS,
          UIElement.SprFile.SETTLEMENT_DATE,
          UIElement.SprFile.NOTES,
          UIElement.SprFile.TASKS_AND_DEADLINES,
          UIElement.SprFile.DRAFTS,
          UIElement.SprFile.CREATE_FILE_COVER_BUTTON,
          UIElement.SprFile.UPDATE_SPR_FILE_BUTTON,
        ],
      ],
    ]),
  ],
  [
    EsFileState.CLOSED,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.SprFile.CREATE_FILE_COVER_BUTTON,
          UIElement.SprFile.REOPEN_SPR_FILE_BUTTON,
        ],
      ],
      [Role.HEAD_OSV, [UIElement.SprFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.INDEX,
        [
          UIElement.SprFile.REOPEN_SPR_FILE_BUTTON,
          UIElement.SprFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [Role.SOLVER, [UIElement.SprFile.CREATE_FILE_COVER_BUTTON]],
    ]),
  ],
]);
