import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DictSubjectIndex } from '@models';

export function useColumns(): TableColumn<DictSubjectIndex>[] {
  const intl = useIntl();
  const { columnActive } = DictionaryEvidence.useDictionaryColumns<
    DictSubjectIndex
  >();

  return [
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ES__DICT_SUBJECT_INDEX__TABLE__COLUMN__NAME',
        defaultMessage: 'Název',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    columnActive,
  ];
}
