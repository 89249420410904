import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DetailContext, PrimaryDetailActionbarButton } from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { AnonymizationRequest, DecisionState, UIElement } from '@enums';
import { useDecisionPermission } from './decision-permission';
import { useChangeStateDialog } from './dialog-actions/change-state-hook';
import { useRegenerateAttachmentsDialog } from './dialog-actions/regenerate-attachments-hook';

export function ActionBar() {
  const intl = useIntl();
  const { source } = useContext(DetailContext);

  const { hasPermission } = useDecisionPermission(source.data);

  const { callApi: toAnonymizeApiCall, redirectBag: toAnonymizeRedirectBag } =
    useChangeStateDialog(DecisionState.TO_ANONYMIZE);

  const { callApi: inProgressApiCall, redirectBag: inProgressRedirectBag } =
    useChangeStateDialog(DecisionState.IN_PROGRESS);

  const {
    callApi: toMakeAccessible,
    redirectBag: toMakeAccessibleRedirectBag,
  } = useChangeStateDialog(DecisionState.TO_MAKE_ACCESSIBLE);

  const { callApi: accessibleApiCall, redirectBag: accessibleRedirectBag } =
    useChangeStateDialog(DecisionState.ACCESSIBLE);

  const { callApi: indexingApiCall, redirectBag: indexingRedirectBag } =
    useChangeStateDialog(DecisionState.INDEXING);

  const { callApi: regenerateAttachmentsApiCall } =
    useRegenerateAttachmentsDialog();

  const { callApi: cancelAccessApiCall, redirectBag: cancelAccessRedirectBag } =
    useChangeStateDialog(DecisionState.TO_ANONYMIZE);

  const showToAnonymizeButton = [
    DecisionState.IN_PROGRESS,
    DecisionState.INDEXING,
    DecisionState.TO_MAKE_ACCESSIBLE,
  ].includes(source?.data?.state);

  const showInProgressButton = [DecisionState.INDEXING].includes(
    source?.data?.state
  );

  const showToMakeAccessibleButton =
    [
      DecisionState.IN_PROGRESS,
      DecisionState.TO_ANONYMIZE,
      DecisionState.INDEXING,
    ].includes(source?.data?.state) &&
    source.data?.esFile?.anonymizationRequest !==
      AnonymizationRequest.REQUESTED;

  const showIndexingButton = [
    DecisionState.IN_PROGRESS,
    DecisionState.TO_ANONYMIZE,
    DecisionState.TO_MAKE_ACCESSIBLE,
  ].includes(source.data?.state);

  const showAccessibleButton =
    source?.data?.state === DecisionState.TO_MAKE_ACCESSIBLE &&
    source.data?.allowPublishing;

  const showCancelAccessButton =
    source?.data?.state === DecisionState.ACCESSIBLE;

  return (
    <>
      {showIndexingButton && (
        <DetailActionButton
          promptKey="INDEXING"
          apiCall={indexingApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DECISION__ACTIONBAR__INDEXING',
            defaultMessage: 'Předat k indexaci',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_INDEXING__TITLE',
            defaultMessage: 'Předat k indexaci',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_INDEXING__TEXT',
            defaultMessage:
              'Opravdu chcete předat vybrané rozhodnutí k indexaci?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={450}
          buttonDisabled={!hasPermission(UIElement.Decision.INDEXING)}
          {...indexingRedirectBag}
        />
      )}
      {showToAnonymizeButton && (
        <DetailActionButton
          promptKey="TO_ANONYMIZE"
          apiCall={toAnonymizeApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DECISION__ACTIONBAR__TO_ANONYMIZE',
            defaultMessage: 'Předat k anonymizaci',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_TO_ANONYMIZE__TITLE',
            defaultMessage: 'Předat k anonymizaci',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_TO_ANONYMIZE__TEXT',
            defaultMessage:
              'Opravdu chcete předat vybrané rozhodnutí k anonymizaci?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={450}
          buttonDisabled={!hasPermission(UIElement.Decision.TO_ANONYMIZE)}
          {...toAnonymizeRedirectBag}
        />
      )}
      {showToMakeAccessibleButton && (
        <DetailActionButton
          promptKey="TO_MAKE_ACCESSIBLE"
          apiCall={toMakeAccessible}
          buttonLabel={intl.formatMessage({
            id: 'ES__DECISION__ACTIONBAR__TO_MAKE_ACCESSIBLE',
            defaultMessage: 'Předat k zpřístupnění',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_TO_MAKE_ACCESSIBLE__TITLE',
            defaultMessage: 'Předat k zpřístupnění',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_TO_MAKE_ACCESSIBLE__TEXT',
            defaultMessage:
              'Opravdu chcete předat vybrané rozhodnutí k zpřístupnění?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={450}
          buttonDisabled={!hasPermission(UIElement.Decision.TO_MAKE_ACCESSIBLE)}
          {...toMakeAccessibleRedirectBag}
        />
      )}
      {showInProgressButton && (
        <DetailActionButton
          promptKey="IN_PROGRESS"
          apiCall={inProgressApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DECISION__ACTIONBAR__IN_PROGRESS',
            defaultMessage: 'Zpět - rozpracováno',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_IN_PROGRESS__TITLE',
            defaultMessage: 'Zpět - rozpracováno',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_IN_PROGRESS__TEXT',
            defaultMessage:
              'Opravdu chcete vrátit vybrané rozhodnutí zpět k rozpracování?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={450}
          buttonDisabled={!hasPermission(UIElement.Decision.IN_PROGRESS)}
          {...inProgressRedirectBag}
        />
      )}
      {showAccessibleButton && (
        <DetailActionButton
          promptKey="ACCESSIBLE"
          apiCall={accessibleApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DECISION__ACTIONBAR__ACCESSIBLE',
            defaultMessage: 'Zpřístupnit rozhodnutí',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_ACCESSIBLE__TITLE',
            defaultMessage: 'Zpřístupnit rozhodnutí',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_ACCESSIBLE__TEXT',
            defaultMessage: 'Opravdu chcete zpřístupnit vybrané rozhodnutí?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={450}
          buttonDisabled={!hasPermission(UIElement.Decision.ACCESSIBLE)}
          {...accessibleRedirectBag}
        />
      )}
      {showCancelAccessButton && (
        <DetailActionButton
          promptKey="CANCEL_ACCESS"
          apiCall={cancelAccessApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DECISION__ACTIONBAR__CANCEL_ACCESS',
            defaultMessage: 'Zrušit zpřístupnění',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_CANCEL_ACCESS__TITLE',
            defaultMessage: 'Zrušit zpřístupnění',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_CANCEL_ACCESS__TEXT',
            defaultMessage:
              'Opravdu chcete zrušit zpřístupnění vybraného rozhodnutí?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={450}
          {...cancelAccessRedirectBag}
        />
      )}
      {source.data?.state !== DecisionState.ACCESSIBLE && (
        <DetailActionButton
          promptKey="REGENERATE_ATTACHMENTS"
          apiCall={regenerateAttachmentsApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DECISION__ACTIONBAR__REGENERATE_ATTACHMENTS',
            defaultMessage: 'Přegenerovat přílohy',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_REGENERATE_ATTACHMENTS__TITLE',
            defaultMessage: 'Přegenerování příloh',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DECISION__DIALOG_REGENERATE_ATTACHMENTS__TEXT',
            defaultMessage: 'Opravdu chcete přegenerovat zveřejněné přílohy?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={450}
          buttonDisabled={
            !hasPermission(UIElement.Decision.REGENERATE_ATTACHMENTS)
          }
        />
      )}
    </>
  );
}
