import * as Yup from 'yup';
import { User, UserSubstitution } from '@models';

export function useValidationSchema() {
  return Yup.object<UserSubstitution>().shape({
    substitute: Yup.mixed<User>().nullable().required(),
    substituteFor: Yup.mixed<User>()
      .test('', '', function (value) {
        const { createError, path } = this;

        if (value && value.id === this.parent.substitute?.id) {
          return createError({
            path,
            message: 'Zastupující a zastupovaný uživatel nesmí být stejný.',
          });
        }

        return true;
      })
      .nullable()
      .required(),
    from: Yup.string()
      .nullable()
      .test('', '', function (value) {
        const { path, createError } = this;

        if (!value) {
          return createError({
            path,
            message: 'Povinné pole',
          });
        }

        if (new Date(value) < new Date()) {
          return createError({
            path,
            message: 'Zástup musí začínat v budoucnosti',
          });
        }

        return true;
      }),
  }) as Yup.Schema<UserSubstitution>;
}
