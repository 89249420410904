import { useIntl } from 'react-intl';
import {
  TableFieldCells,
  TableFieldColumn,
  TextField,
  useStaticListSource,
} from '@eas/common-web';
import { DictionaryObject, User } from '@models';

export function useColumns(
  agendas: DictionaryObject[],
  withAgendaFields: boolean,
  withDefaultUserFields: boolean
): TableFieldColumn<User>[] {
  const intl = useIntl();

  const agendaSource = useStaticListSource(agendas);

  const agendaColumns: TableFieldColumn<User>[] = [
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__AGENDA',
        defaultMessage: 'Agendy',
      }),
      datakey: 'agendas',
      width: 250,
      CellComponent: TableFieldCells.useSelectCellFactory(
        () => agendaSource,
        TextField,
        true,
        true
      ),
    },
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__DEFAULT_FOR',
        defaultMessage: 'Přednastaveno',
      }),
      datakey: 'defaultFor',
      width: 250,
      CellComponent: TableFieldCells.useSelectCellFactory(
        () => agendaSource,
        TextField,
        true,
        true
      ),
    },
  ];

  const defaultUserColumns: TableFieldColumn<User>[] = [
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__DEFAULT',
        defaultMessage: 'Výchozí uživatel',
      }),
      datakey: 'default',
      width: 250,
      CellComponent: TableFieldCells.BooleanCell,
    },
  ];

  const columns: TableFieldColumn<User>[] = [
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__USER_NAME',
        defaultMessage: 'Uživatelské jméno',
      }),
      datakey: 'user.username',
      sortkey: 'user.username',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN_EMAIL',
        defaultMessage: 'Email',
      }),
      datakey: 'user.email',
      sortkey: 'user.email',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__FIRST_NAME',
        defaultMessage: 'Jméno',
      }),
      datakey: 'user.firstName',
      sortkey: 'user.firstName',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__LAST_NAME',
        defaultMessage: 'Příjmení',
      }),
      datakey: 'user.lastName',
      sortkey: 'user.lastName',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__PHONE',
        defaultMessage: 'Telefon',
      }),
      datakey: 'user.phone',
      sortkey: 'user.phone',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__TITLES_BEFORE',
        defaultMessage: 'Tituly před',
      }),
      datakey: 'user.titlesBefore',
      sortkey: 'user.titlesBefore',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__ES_FILES__USER__TABLE_FIELD__COLUMN__TITLES_AFTER',
        defaultMessage: 'Tituly za',
      }),
      datakey: 'user.titlesAfter',
      sortkey: 'user.titlesAfter',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    ...(withAgendaFields ? agendaColumns : []),
    ...(withDefaultUserFields ? defaultUserColumns : []),
  ];

  return columns;
}
