import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useContext } from 'react';
import {
  ApiFilterOperation,
  EvidenceContext,
  useEventCallback,
} from '@eas/common-web';
import { FilterState } from '../filter-types';
import { normalizeFilterState } from '../filter-utils';

export function useReducer({
  filtersState,
  setFiltersState,
}: {
  filtersState: FilterState[];
  setFiltersState: Dispatch<SetStateAction<FilterState[]>>;
}) {
  const { tableRef } = useContext(EvidenceContext);

  /**
   * Append reducer into filter state.
   */
  const appendReducer = useEventCallback((newReducers: string[]) => {
    const filters = cloneDeep(filtersState);

    const reducers = filters[0].filters![1].filters;
    reducers?.push({
      operation: ApiFilterOperation.NOT,
      visible: true,
      filters: [
        {
          field: 'id',
          operation: ApiFilterOperation.IN,
          values: newReducers,
        },
      ],
    });

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  /**
   * Toggle visibility of reducer.
   */
  const toggleReducer = useEventCallback((index: number) => {
    const filters = cloneDeep(filtersState);

    const reducers = filters[0].filters![1].filters;

    reducers![index] = {
      ...reducers![index],
      visible: !reducers![index].visible,
    };

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  /**
   * Remove reducer.
   */
  const removeReducer = useEventCallback((index: number) => {
    const filters = cloneDeep(filtersState);

    const reducers = filters[0].filters![1].filters;
    reducers?.splice(index, 1);

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  return {
    appendReducer,
    toggleReducer,
    removeReducer,
  };
}
