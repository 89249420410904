import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { Dispatch } from '@models';
import { EntityName, EvidenceApiUrl } from '@enums';
import { useColumns } from './document-dispatch-columns';
import { Fields } from './document-dispatch-fields';

export function DocumentDispatches() {
  const intl = useIntl();

  const evidence = useAuthoredEvidence<Dispatch>({
    version: 12,
    identifier: 'DISPATCHES',
    apiProps: {
      url: EvidenceApiUrl.DOCUMENTS_DISPATCHES,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DISPATCHES__TABLE__TITLE',
        defaultMessage: 'Vypravení',
      }),
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.DISPATCH,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DISPATCHES__DETAIL__TITLE',
          defaultMessage: 'Vypravení',
        }),
        showBtn: (btn) => ['REFRESH', 'SHARE'].includes(btn),
        subtitle: (value) => value?.jid ?? '',
      },
    },
  });

  return <Evidence {...evidence} />;
}
