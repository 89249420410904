import { noop, stubFalse } from 'lodash';
import React, { useContext } from 'react';
import {
  TableField,
  abortableFetch,
  useEventCallback,
  useFormSelector,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Document } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from '../document-columns';

export function useCreateAcceptanceLetterDialog() {
  const { documentTypes, documentDeliveryMethods, documentDispatchStates } =
    useContext(StaticDataContext);

  const columns = useColumns({
    documentTypes,
    documentDeliveryMethods,
    documentDispatchStates,
  });

  const callApi = useEventCallback((id: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/${id}/acceptance-letter`,
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      }
    );
  });

  const ResultFields = () => {
    const documents = useFormSelector((data: Document[]) => data ?? []);

    return (
      <>
        {documents?.length && (
          <TableField
            value={documents}
            onChange={noop}
            columns={columns}
            showToolbar={false}
            showDetailBtnCond={stubFalse}
            showRadioCond={stubFalse}
          />
        )}
      </>
    );
  };

  return {
    callApi,
    ResultFields,
  };
}
