import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  FormContext,
  PromptContext,
  useEventCallback,
  useFormSelector,
  usePrompts,
} from '@eas/common-web';
import { Decision, DecisionText } from '@models';

export function useConfirmCopyDialog(fieldName: keyof DecisionText) {
  const intl = useIntl();
  const PROMPT_KEY = 'CONFIRM_COPY';

  const { setFieldValue } = useContext(FormContext);
  const { testPrompt } = useContext(PromptContext);

  const { text } = useFormSelector((data: Decision) => ({
    text: data.text?.[fieldName],
  }));

  const handleConfirmDialog = useEventCallback(() => {
    setFieldValue(`anonymizedText.${fieldName}`, text);
  });

  usePrompts([
    {
      key: `${PROMPT_KEY}_${fieldName.toLocaleUpperCase()}`,
      dialogTitle: intl.formatMessage({
        id: 'ES__DECISIONS__ANONYMIZED_TEXT__CONFIRM_COPY__TITLE',
        defaultMessage: 'Upozornění',
      }),
      dialogText: intl.formatMessage({
        id: 'ES__DECISIONS__ANONYMIZED_TEXT__CONFIRM_COPY__TEXT',
        defaultMessage: 'Skutečne chcete převzít text rozhodnutí?',
      }),
    },
  ]);

  const handleConfirmCopy = useEventCallback(() => {
    testPrompt({
      key: `${PROMPT_KEY}_${fieldName.toLocaleUpperCase()}`,
      submitCallback: handleConfirmDialog,
    });
  });

  return { handleConfirmCopy };
}
