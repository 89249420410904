import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ActionButton, abortableFetch } from '@eas/common-web';
import { useDownloadReport } from '@modules/statistics/actions/download-report-hook';

const useStyles = makeStyles({
  butonWrapper: { cursor: 'pointer', display: 'flex' },
});

export function DownloadPdf({
  url,
  label = 'PDF',
}: {
  url: string;
  label?: string;
}) {
  const classes = useStyles();

  const { callApi } = useDownloadReport();

  return (
    <span
      className={classes.butonWrapper}
      onClick={() => callApi(`${url}&exportType=DOCX`)}
    >
      <GetAppIcon />
      {label}
    </span>
  );
}

export function DownloadDocx({
  url,
  label = 'DOCX',
}: {
  url: string;
  label?: string;
}) {
  const classes = useStyles();

  const { callApi } = useDownloadReport();

  return (
    <span
      className={classes.butonWrapper}
      onClick={() => callApi(`${url}&exportType=DOCX`)}
    >
      <GetAppIcon />
      {label}
    </span>
  );
}

export function ForwardToEssl({ url }: { url: string }) {
  const classes = useStyles();

  return (
    <ActionButton
      promptKey="FORWARD_TO_ESSL"
      buttonLabel={null}
      dialogText="Skutečně chcete předat spisy ke skartaci?"
      dialogTitle="Předání ke skartaci"
      ButtonComponent={({ onClick, ...props }) => (
        <span className={classes.butonWrapper} onClick={onClick}>
          <DeleteIcon {...props} />
          SKARTOVAT
        </span>
      )}
      apiCall={() =>
        abortableFetch(url, {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'PUT',
        })
      }
    />
  );
}
