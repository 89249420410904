import {
  ApiFilterOperation,
  TableFilterState,
  useEventCallback,
} from '@eas/common-web';
import { Operation } from '../filter-api';

export function useFilterOperation(
  state: TableFilterState,
  onChangeState: (state: TableFilterState) => void
) {
  const handleChangeOperation = useEventCallback(
    (operation: Operation | Operation[] | string | string[] | null) => {
      if (operation) {
        onChangeState({
          ...state,
          operation: operation as ApiFilterOperation,
        });
      }
    }
  );

  return { handleChangeOperation };
}
