export enum JobType {
  /**
   * Vytvoření dokumentu
   */
  CREATE_DOCUMENT = 'CREATE_DOCUMENT',

  /**
   * Vytvoření spisu
   */
  CREATE_RECORD = 'CREATE_RECORD',

  /**
   * Vytvoření přílohy dokumentu
   */
  CREATE_DOCUMENT_COMPONENT = 'CREATE_DOCUMENT_COMPONENT',

  /**
   * Smazání přílohy dokumentu
   */
  DELETE_DOCUMENT_COMPONENT = 'DELETE_DOCUMENT_COMPONENT',
}

/**
 * Typ entity
 */
export enum EntityType {
  /**
   * Dokument
   */
  DOCUMENT = 'DOCUMENT',

  /**
   * Spis
   */
  ES_FILE = 'ES_FILE',

  /**
   * Příloha dokumentu
   */
  DOCUMENT_ATTACHMENT = 'DOCUMENT_ATTACHMENT',
}

/**
 * Stav synchonizační akce se spisovou službou
 */
export enum JobState {
  /**
   * Čekající
   */
  PENDING = 'PENDING',

  /**
   * Běžící
   */
  RUNNING = 'RUNNING',

  /**
   * Úspěšně ukončena
   */
  SUCCESS = 'SUCCESS',

  /**
   * Selžena
   */
  FAILED = 'FAILED',

  /**
   * Selžena, bez opakování
   */
  FAILED_NO_RETRY = 'FAILED_NO_RETRY',

  /**
   * Zrušena
   */
  CANCELLED = 'CANCELLED',
}
