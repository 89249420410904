import React from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  eqFilterParams,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
} from '@components/form/misc/label-mappers';
import { CourtFile } from '@models';
import { EvidenceBrowserUrl, Role } from '@enums';
import { useJudges } from '../../judge/judge-api';
import { useUserRoles } from '../../role/role-api';
import { useCourtFileColumns } from '../court-file-columns';

export function useColumns(): TableColumn<CourtFile>[] {
  const intl = useIntl();
  const { columnDeliveryDate, columnOpponent, columnProposers, columnType } =
    useCourtFileColumns();
  const useSecretaries = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.SECRETARY }));
  const useAssistants = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.ASSISTANT }));

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_JUDGE_OFFICE__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.COURT_FILES_JUDGE_OFFICE}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    columnDeliveryDate,
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_JUDGE_OFFICE__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 120,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'judge',
      filterkey: 'judge.id',
      sortkey: 'judge.name',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_JUDGE_OFFICE__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj',
      }),
      width: 200,
      valueMapper: autocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useJudges,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    columnProposers,
    columnOpponent,
    columnType,
    {
      datakey: 'secretaryUserRole',
      filterkey: 'secretaryUserRole.id',
      sortkey: 'secretaryUserRole.name',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_JUDGE_OFFICE__TABLE__COLUMN__SECRETARY',
        defaultMessage: 'Tajemnice',
      }),
      width: 200,
      valueMapper: autocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSecretaries,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'assistantUserRole',
      filterkey: 'assistantUserRole.id',
      sortkey: 'assistantUserRole.name',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_JUDGE_OFFICE__TABLE__COLUMN__ASSISTANT',
        defaultMessage: 'Asistent',
      }),
      width: 200,
      valueMapper: autocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useAssistants,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}
