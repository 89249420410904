import { useContext } from 'react';
import { DetailContext, DetailHandle } from '@eas/common-web';
import { openEsFileApiCall } from '@modules/es-file/dialog-actions/open-file/open-file-hook';
import { DisciplinaryProceedingsFile } from '@models';
import { EsFileState } from '@enums';

export function useReopenDialog() {
  const { source } =
    useContext<DetailHandle<DisciplinaryProceedingsFile>>(DetailContext);

  const showButton = source.data?.state === EsFileState.CLOSED;

  return {
    callApi: openEsFileApiCall,
    showButton,
  };
}
