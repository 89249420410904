enum Element {
  /**
   * Číslo jednací
   */
  REFERENCE_NUMBER = 'REFERENCE_NUMBER',

  /**
   * Agenda
   */
  AGENDA = 'AGENDA',

  /**
   * Stručný obsah
   */
  SUMMARY = 'SUMMARY',

  /**
   * Stav vypravení dokumentu
   */
  DISPATCH_STATE = 'DISPATCH_STATE',

  /**
   * Poznámka
   */
  NOTE = 'NOTE',

  /**
   * Datum vytvoření
   */
  CREATED_DATE = 'CREATED_DATE',

  /**
   * Datum doručení
   */
  DELIVERY_DATE = 'DELIVERY_DATE',

  /**
   * Počet listů
   */
  PAGE_COUNT = 'PAGE_COUNT',

  /**
   * Rozhodnutí
   */
  DECISION = 'DECISION',

  /**
   * Forma rozhodnutí
   */
  DECISION_FORM = 'DECISION_FORM',

  /**
   * Pořadí
   */
  ORDER = 'ORDER',

  /**
   * Čárový kód
   */
  BAR_CODE = 'BAR_CODE',

  /**
   * Lhůty
   */
  DEADLINES = 'DEADLINES',

  /**
   * AKCE: Předat k odeslání
   */
  FORWARD_TO_SEND = 'FORWARD_TO_SEND',

  /**
   * AKCE: Vrátit odselání
   */
  RETURN_SEND = 'RETURN_SEND',

  /**
   * AKCE: Předat k vypravení
   */
  FORWARD_TO_DISPATCH = 'FORWARD_TO_DISPATCH',

  /**
   * AKCE: Upravit dokument
   */
  EDIT_DOCUMENT_BUTTON = 'EDIT_DOCUMENT_BUTTON',

  /**
   * AKCE: Stornovat
   */
  CANCEL_BUTTON = 'CANCEL_BUTTON',

  /**
   * AKCE: Znovu otevřít
   */
  REOPEN_OUTGOING_DOCUMENT_BUTTON = 'REOPEN_OUTGOING_DOCUMENT_BUTTON',
}

export default Element;
