import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { grey } from '@material-ui/core/colors';
import {
  AbortableFetch,
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  defaultCreateItem,
} from '@eas/common-web';
import { identifySubjectApiCall } from '@composite/contact/dialog-actions/search-data-box/search-data-box-hook';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { Subject } from '@models';
import { EntityName, EvidenceApiUrl, HistoryEntityType } from '@enums';
import { useColumns } from './subject-columns';
import { FieldsWithHistory } from './subject-fields';
import { useValidationSchema } from './subject-schema';

export function Subjects() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const include = useMemo(() => ['DEACTIVATED'], []);

  const evidence = useAuthoredEvidence<Subject>({
    version: 4,
    identifier: 'SUBJECTS',
    apiProps: {
      url: EvidenceApiUrl.SUBJECTS,
      hookedSources: useDictionaryHookedSources<Subject>({
        entityType: HistoryEntityType.SUBJECT,
      }),
      createItem: createItem,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__SUBJECTS__TABLE__TITLE',
        defaultMessage: 'Subjekty',
      }),
      defaultSorts: [],
      showReportButton: false,
      include,
      createRowStyle: (item: Subject) =>
        !item?.active
          ? {
              color: grey[500],
              backgroundColor: grey[100],
            }
          : {},
    },
    detailProps: {
      entityName: EntityName.SUBJECT,
      validationSchema,
      FieldsComponent: FieldsWithHistory,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__SUBJECTS__DETAIL__TITLE',
          defaultMessage: 'Subjekty',
        }),
        ActionBar: DictionaryEvidence.dictionaryActionbarFactory({}),
        subtitle: (subject) => subject?.name ?? '',
        showBtn: (btn) => btn !== 'REMOVE',
      },
    },
  });

  return <Evidence {...evidence} />;
}

function createItem(api: string, item: Subject) {
  const fetch = defaultCreateItem(api, item);

  const augmented: AbortableFetch = {
    response: fetch.response,
    abort: fetch.abort,
    json: async () => {
      const data = await fetch.json();
      if (item.identified) {
        await identifySubjectApiCall(data.id).raw();
      }

      return data;
    },
    text: async () => {
      throw new Error('Unsupported operation');
    },
    raw: async () => {
      throw new Error('Unsupported operation');
    },
    none: async () => {
      throw new Error('Unsupported operation');
    },
  };

  return augmented;
}
