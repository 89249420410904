enum Element {
  /**
   * Anonymizovat
   */
  ANONYMIZE = 'ANONYMIZE',

  /**
   * Žádost o anonymizaci
   */
  ANONYMIZATION_REQUEST = 'ANONYMIZATION_REQUEST',

  /**
   * Předměty řízení
   */
  PROCEEDINGS_SUBJECTS = 'PROCEEDINGS_SUBJECTS',

  /**
   * Věcný rejstřík
   */
  SUBJECT_INDEXES = 'SUBJECT_INDEXES',

  /**
   * Předpisy
   */
  REGULATIONS = 'REGULATIONS',
}

export default Element;
