export enum DeadlineState {
  /**
   * Běží
   */
  RUNNING = 'RUNNING',

  /**
   * Dodržená
   */
  MET = 'MET',

  /**
   * Nedodržená
   */
  NOT_MET = 'NOT_MET',

  /**
   * Stornována
   */
  CANCELED = 'CANCELED',
}
