import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DictionaryObject,
  FormFieldContext,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { User } from '@models';
import { useColumns } from './user-columns';
import { UserToolbar } from './user-toolbar';

export type UserFieldProps = {
  source?: ScrollableSource<User>;
  withAgendaFields: boolean;
  withDefaultUserFields: boolean;
  agendas: DictionaryObject[];
  defaultableAgendas: DictionaryObject[];
};

export function UserField({
  source,
  withAgendaFields,
  withDefaultUserFields,
  agendas,
  defaultableAgendas,
}: UserFieldProps) {
  const detailCtx = useContext(DetailContext);

  const preFilters = [
    {
      field: 'role.id',
      operation: ApiFilterOperation.EQ,
      value: detailCtx.source.data.id,
    },
  ];

  const columns = useColumns(agendas, withAgendaFields, withDefaultUserFields);

  if (!source) {
    return <> Missing USER_ROLE_SOURCE </>;
  }

  return (
    <FormFieldContext.Provider value={{ disabled: false }}>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__ROLES__DETAIL__PANEL_TITLE__USERS"
            defaultMessage="Uživatelé"
          />
        }
        expandable={true}
        defaultExpanded={true}
      >
        <RemoteTableField
          source={source}
          columns={columns}
          reactToColumnsChange={true}
          ToolbarComponent={function Toolbar(props: TableFieldToolbarProps) {
            return (
              <UserToolbar
                {...props}
                preFilters={preFilters}
                withAgendaFields={withAgendaFields}
                withDefaultUserFields={withDefaultUserFields}
                agendas={agendas}
                defaultableAgendas={defaultableAgendas}
              />
            );
          }}
          maxRows={15}
        />
      </FormPanel>
    </FormFieldContext.Provider>
  );
}
