import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  ActionButton,
  FormSelect,
  LocaleContext,
  PrimaryDetailActionbarButton,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DictionaryObject, ProceedingDurationDataInput } from '@models';
import { CourtFileType, IntervalType } from '@enums';
import { useStyles } from '../card/dated-header';
import { IntervalFields } from '../card/interval-header';

export const Header = ({
  params,
  setParams,
}: {
  params: ProceedingDurationDataInput;
  setParams: (parameters: ProceedingDurationDataInput) => void;
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { courtFileTypes } = useContext(StaticDataContext);
  const { locale } = useContext(LocaleContext);

  const courtFileTypeSource = useStaticListSource(courtFileTypes);

  const validationSchema = Yup.object().shape({
    intervalType: Yup.mixed<IntervalType>().nullable().required(),
    courtFileType: Yup.mixed<CourtFileType>().nullable().required(),
    year: Yup.string().when('intervalType', {
      is: IntervalType.YEAR,
      then: Yup.number().nullable().required(),
    }),
    from: Yup.string().when('intervalType', {
      is: IntervalType.RANGE,
      then: Yup.string().nullable().required(),
    }),
    to: Yup.string().when('intervalType', {
      is: IntervalType.RANGE,
      then: Yup.string().nullable().required(),
    }),
  }) as Yup.Schema<ProceedingDurationDataInput>;

  return (
    <div className={classes.wrapper}>
      {!!params.from && !!params.to && (
        <div className={classes.dateWrapper}>
          <div className={classes.labelWrapper}>
            <Typography className={classes.dateLabel}>OD</Typography>
            <Typography>
              {format(new Date(params?.from), locale.dateFormat)}
            </Typography>
          </div>
          <div className={classes.labelWrapper}>
            <Typography className={classes.dateLabel}>DO</Typography>
            <Typography>
              {format(new Date(params?.to), locale.dateFormat)}
            </Typography>
          </div>
        </div>
      )}
      {params.intervalType === IntervalType.ALL && (
        <Typography className={classes.dateLabel}>
          VŠE OD PRVNÍHO PODÁNÍ
        </Typography>
      )}
      {params.year && (
        <div className={classes.labelWrapper}>
          <Typography className={classes.dateLabel}>ROK</Typography>
          <Typography>{params.year}</Typography>
        </div>
      )}
      <div className={classes.labelWrapper}>
        <Typography className={classes.dateLabel}>
          TYP SOUDNÍHO SPISU
        </Typography>
        <Typography>
          {
            courtFileTypeSource.items.find((a) => a.id === params.courtFileType)
              ?.name
          }
        </Typography>
      </div>
      <ActionButton
        promptKey="CHANGE_PROCEEDING_DURATION_PARAMETERS"
        buttonLabel={intl.formatMessage({
          id: 'ES__STATISTICS__CHANGE_PROCEEDING_DURATION_PARAMETERS',
          defaultMessage: 'Upravit parametry',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__STATISTICS__CHANGE_PROCEEDING_DURATION_PARAMETERS__TITLE',
          defaultMessage: 'Úprava parametrů',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__STATISTICS__CHANGE_PROCEEDING_DURATION_PARAMETERS__TEXT',
          defaultMessage: 'Vyplňte parametry.',
        })}
        ButtonComponent={(props) => (
          <PrimaryDetailActionbarButton {...props} endIcon={<SettingsIcon />} />
        )}
        formInitialValues={params}
        FormFields={() => <Fields courtFileTypes={courtFileTypes} />}
        formValidationSchema={validationSchema}
        submitCallback={setParams}
        dialogWidth={700}
        dialogDisableBackdrop={true}
      />
    </div>
  );
};

function Fields({ courtFileTypes }: { courtFileTypes: DictionaryObject[] }) {
  const courtFileTypeSource = useStaticListSource(courtFileTypes);

  return (
    <>
      <FormSelect
        source={courtFileTypeSource}
        label="Typ soudního spisu"
        name="courtFileType"
        valueIsId
        required
      />
      <IntervalFields />
    </>
  );
}
