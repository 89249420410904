import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { TableFieldCells, useStaticListSource } from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { EsFilesCountByAgendaDto } from '@models';
import { Agenda, EvidenceApiUrl } from '@enums';
import { DownloadDocx, DownloadPdf } from './card/actions';
import { ReportCard, useStyles } from './card/card';
import { Header } from './registers-header';

export function Registers() {
  const classes = useStyles();

  const { agendas } = useContext(StaticDataContext);
  const useAgendas = () => useStaticListSource(agendas);

  const [data, setData] = useState<
    EsFilesCountByAgendaDto & { agenda: Agenda }[]
  >([]);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const loadData = async () => {
      const response = await fetch(
        `${EvidenceApiUrl.REPORTS}/register/overview?year=${year}`
      );

      if (response.ok) {
        const json = await response.json();

        const sprFiles = {
          agenda: Agenda.SPR,
          resolvedCount: json.resolvedCount.sprFilesCount,
          shreddedCount: json.shreddedCount.sprFilesCount,
          totalCount: json.totalCount.sprFilesCount,
          unresolvedCount: json.unresolvedCount.sprFilesCount,
        };

        const courtFiles = {
          agenda: Agenda.COURT,
          resolvedCount: json.resolvedCount.courtFilesCount,
          shreddedCount: json.shreddedCount.courtFilesCount,
          totalCount: json.totalCount.courtFilesCount,
          unresolvedCount: json.unresolvedCount.courtFilesCount,
        };

        const infFiles = {
          agenda: Agenda.INF,
          resolvedCount: json.resolvedCount.infFilesCount,
          shreddedCount: json.shreddedCount.infFilesCount,
          totalCount: json.totalCount.infFilesCount,
          unresolvedCount: json.unresolvedCount.infFilesCount,
        };

        const disciplinaryProceedingsFiles = {
          agenda: Agenda.DISCIPLINARY_PROCEEDINGS,
          resolvedCount: json.resolvedCount.disciplinaryProceedingsCount,
          shreddedCount: json.shreddedCount.disciplinaryProceedingsCount,
          totalCount: json.totalCount.disciplinaryProceedingsCount,
          unresolvedCount: json.unresolvedCount.disciplinaryProceedingsCount,
        };

        const plenaryOpinionsFiles = {
          agenda: Agenda.PLENARY_OPINION,
          resolvedCount: json.resolvedCount.plenaryOpinionsCount,
          shreddedCount: json.shreddedCount.plenaryOpinionsCount,
          totalCount: json.totalCount.plenaryOpinionsCount,
          unresolvedCount: json.unresolvedCount.plenaryOpinionsCount,
        };

        setData([
          sprFiles,
          courtFiles,
          infFiles,
          disciplinaryProceedingsFiles,
          plenaryOpinionsFiles,
        ]);
      }
    };

    loadData();
  }, [year]);

  return (
    <Grid item xs={12}>
      <ReportCard
        title="Rejstřík"
        data={data}
        Header={() => <Header year={year} setYear={setYear} />}
        columns={[
          {
            datakey: 'agenda',
            name: 'Agenda',
            width: 200,
            CellComponent: TableFieldCells.useSelectCellFactory(useAgendas),
          },
          {
            datakey: 'resolvedCount',
            name: 'Vyřízených spisů',
            width: 150,
          },
          {
            datakey: 'totalCount',
            name: 'Celkem spisů',
            width: 150,
          },
          {
            datakey: 'unresolvedCount',
            name: 'Nevyřízených spisů',
            width: 150,
          },
          {
            datakey: 'shreddedCount',
            name: 'Skartovaných spisů',
            width: 150,
          },
          {
            datakey: 'actions',
            name: 'Akce',
            width: 350,
            CellComponent: ({ rowValue }) => {
              const CourtFileCell = () => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={classes.actions}>
                    <DownloadPdf
                      label="PDF (Plenární spisy)"
                      url={`${EvidenceApiUrl.REPORTS}/register/overview/generate?year=${year}&courtFileType=PLENARY&agenda=${rowValue?.agenda}`}
                    />
                    <DownloadDocx
                      label="DOCX (Plenární spisy)"
                      url={`${EvidenceApiUrl.REPORTS}/register/overview/generate?year=${year}&courtFileType=PLENARY&agenda=${rowValue?.agenda}`}
                    />
                  </div>
                  <div className={classes.actions}>
                    <DownloadPdf
                      label="PDF (Senátní spisy)"
                      url={`${EvidenceApiUrl.REPORTS}/register/overview/generate?year=${year}&courtFileType=SENATE&agenda=${rowValue?.agenda}`}
                    />
                    <DownloadDocx
                      label="DOCX (Senátní spisy)"
                      url={`${EvidenceApiUrl.REPORTS}/register/overview/generate?year=${year}&courtFileType=SENATE&agenda=${rowValue?.agenda}`}
                    />
                  </div>
                </div>
              );

              return rowValue?.agenda === Agenda.COURT ? (
                <CourtFileCell />
              ) : (
                <div className={classes.actions}>
                  <DownloadPdf
                    url={`${EvidenceApiUrl.REPORTS}/register/overview/generate?year=${year}&agenda=${rowValue?.agenda}`}
                  />
                  <DownloadDocx
                    url={`${EvidenceApiUrl.REPORTS}/register/overview/generate?year=${year}&agenda=${rowValue?.agenda}`}
                  />
                </div>
              );
            },
          },
        ]}
      />
    </Grid>
  );
}
