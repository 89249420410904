import { stubFalse } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  FormCheckbox,
  FormDictionaryField,
  FormPanel,
  FormTableField,
  useScrollableSource,
} from '@eas/common-web';
import { useContacts } from '@modules/subject/subject-api';
import {
  useContactColumns,
  useContactFiltersFields,
} from '@modules/subject/subject-columns';
import {
  ContactAutocompleteItem,
  createRowStyle,
} from '@composite/contact/conact-utils';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';
import { dictionaryFieldLabelMapper } from '@components/form/misc/label-mappers';
import { Contact } from '@models';
import { EvidenceApiUrl } from '@enums';

export function Fields() {
  const historyDescriptors = useCommonHistoryDescriptors();
  const { contactColumns } = useContactColumns({ datakeyPrefix: 'contact' });

  const ContactField = () => {
    const contactSource = useScrollableSource<Contact>({
      url: `${EvidenceApiUrl.SUBJECT_CONTACTS}/list`,
    });

    const contacts = useContacts();

    const { contactColumns } = useContactColumns();
    const { contactFiltersFields } = useContactFiltersFields();

    return (
      <FormDictionaryField
        name="contact"
        label={
          <FormattedMessage
            id="ES__DICT_OPPONENTS__DETAIL__FIELD_LABEL__CONTACTS"
            defaultMessage="Kontakty"
          />
        }
        dialogSource={contactSource}
        autocompleteSource={contacts}
        columns={contactColumns}
        filters={contactFiltersFields}
        maxRows={9}
        dialogWidth={1000}
        labelMapper={dictionaryFieldLabelMapper}
        AutocompleteItemComponent={ContactAutocompleteItem}
        createRowStyle={createRowStyle}
      />
    );
  };

  return (
    <>
      <FormPanel label="Obecné">
        <DictionaryEvidence.FieldName />
        <FormCheckbox
          name="generateNotifications"
          label={
            <FormattedMessage
              id="ES__DICT_OPPONENTS__DETAIL__FIELD_LABEL__GENERATE_NOTIFICATIONS"
              defaultMessage="Generovat notifikace"
            />
          }
        />
        <FormCheckbox
          name="insertScannedContent"
          label={
            <FormattedMessage
              id="ES__DICT_OPPONENTS__DETAIL__FIELD_LABEL__INSERT_SCANNED_CONTENT"
              defaultMessage="Vložit návrh na zahájení řízení"
            />
          }
        />
        <DictionaryEvidence.FieldActive />
      </FormPanel>
      <FormPanel label="Kontakty">
        <FormTableField
          name="contacts"
          columns={contactColumns}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          showDetailBtnCond={stubFalse}
          FormFieldsComponent={ContactField}
        />
      </FormPanel>
      <HistoryField
        descriptors={[
          ...historyDescriptors,
          { key: 'email', label: 'Email' },
          { key: 'generateNotifications', label: 'Generovat notifikace' },
          {
            key: 'insertScannedContent',
            label: 'Vložit návrh na zahájení řízení',
          },
        ]}
      />
    </>
  );
}
