import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
} from 'react';
import {
  CrudSource,
  DetailContext,
  DetailHandle,
  DetailMode,
  WebsocketContext,
  useCrudSource,
} from '@eas/common-web';
import { Document } from '@models';
import { EvidenceApiUrl } from '@enums';

export interface DocumentContextType {
  openState: {
    [key: number]: boolean;
  };
  setOpenState: Dispatch<
    SetStateAction<{
      [key: number]: boolean;
    }>
  >;
}

export const DocumentContext = createContext<DocumentContextType>({} as any);

export function DocumentDialogDataProvider({
  document,
  children,
}: {
  document: Document;
  children: ({ source }: { source: CrudSource<Document> }) => ReactNode;
}) {
  const { subscribe, unsubscribe } = useContext(WebsocketContext);

  const source = useCrudSource<Document>({
    url: EvidenceApiUrl.DOCUMENTS,
  });

  useEffect(() => {
    const subscription = document?.id
      ? subscribe(`/topic/entity/${document.id}/update`, () => {
          document.version = (document.version ?? 0) + 1;
        })
      : undefined;

    return () => {
      if (subscription !== undefined) {
        unsubscribe(subscription);
      }
    };
  }, [document]);

  useEffect(() => {
    source.reset(document);
  }, []);

  return (
    <DetailContext.Provider
      value={
        {
          source,
          isLocked: false,
          mode: DetailMode.VIEW,
          isExisting: !!source?.data?.id,
        } as DetailHandle<Document>
      }
    >
      {children({ source })}
    </DetailContext.Provider>
  );
}
