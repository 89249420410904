import React from 'react';
import { HistoryFieldProps } from '../history-types';
import { StringHistoryField } from './string-history-field';

export function BooleanHistoryField({
  value,
  position,
}: HistoryFieldProps<boolean>) {
  return (
    <StringHistoryField value={value ? 'Ano' : 'Ne'} position={position} />
  );
}
