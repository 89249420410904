/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const ASSIGN_ROLE: MessageType<
  'SUCCESS' | 'ERROR' | 'USER_ROLE_ALREADY_EXISTS'
> = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__ROLE__ASSIGN__SUCCESS"
      defaultMessage="Role byla uživateli úspěšně přiřazena."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__ROLE__ASSIGN__ERROR"
      defaultMessage="Chyba při přiřazování role uživateli."
    />,
    SnackbarVariant.ERROR,
  ],
  USER_ROLE_ALREADY_EXISTS: [
    <FormattedMessage
      id="ES__MESSAGE__ROLE__ASSIGN__ALREADY_EXISTS"
      defaultMessage="Chyba při přiřazování role uživateli: Uživatel již má roli přirazenou."
    />,
    SnackbarVariant.ERROR,
  ],
};

const UNASSIGN_ROLE: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__ROLE__UNASSIGN_USER__SUCCESS"
      defaultMessage="Role byla uživateli úspěšně odebrána."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__ROLE__UNASSIGN_USER__ERROR"
      defaultMessage="Chyba při odebírání uživatelské role."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default { ASSIGN_ROLE, UNASSIGN_ROLE };
