import React, { useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import {
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldSpeedDialAction,
  TableFieldToolbarHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { ViewingRequest } from '@models';
import { ViewingRequestState } from '@enums';
import { useColumns } from './viewing-request-columns';
import { ViewingRequestToolbar } from './viewing-request-toolbar';

export interface ViewingRequestFieldProps {
  disabled: boolean;
  source?: ScrollableSource<ViewingRequest>;
}

export function ViewingRequestField({
  disabled,
  source,
}: ViewingRequestFieldProps) {
  const intl = useIntl();

  const columns = useColumns();

  const tableRef = useRef<TableFieldHandle>(null);
  const toolbarRef = useRef<TableFieldToolbarHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <ViewingRequestToolbar
            selectedIndex={props.selectedIndex}
            setSelectedIndex={props.setSelectedIndex}
            ref={toolbarRef}
            disabled={disabled}
          />
        );
      },
    [disabled]
  );

  const actions: TableFieldSpeedDialAction<ViewingRequest>[] = useMemo(
    () => [
      {
        Icon: SentimentSatisfiedAltIcon,
        name: intl.formatMessage({
          id: 'ES__VIEWING_REQUESTS__PROCESS_VIEWING_REQUEST',
          defaultMessage: 'Schválit žádost',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('APPROVE');
        },
        disabled: () => disabled,
        show: (request) => request?.state === ViewingRequestState.NEW,
      },
      {
        Icon: SentimentVeryDissatisfiedIcon,
        name: intl.formatMessage({
          id: 'ES__VIEWING_REQUESTS__CANCEL_VIEWING_REQUEST',
          defaultMessage: 'Zamítnout žádost',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('REJECT');
        },
        disabled: () => disabled,
        show: (request) => request?.state === ViewingRequestState.NEW,
      },
    ],
    [intl]
  );

  if (!source) {
    return <> Missing VIEWING_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__VIEWING_REQUESTS__PANEL_TITLE"
          defaultMessage="Žádosti o nahlížení"
        />
      }
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        ref={tableRef}
        columns={columns}
        ToolbarComponent={ToolbarComponent}
        speedDialActions={actions}
        maxRows={7}
        onRowClick={() => {}}
        showSpeedDialCond={(request) =>
          request.state === ViewingRequestState.NEW
        }
        showRadioCond={() => false}
        showDetailBtnCond={() => false}
        source={source}
        disabled={disabled}
      />
    </FormPanel>
  );
}
