import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { useDeliveredDocuments } from '../document-delivered/delivered-documents';
import { ActionBar } from './processed-actionbar';
import { useColumns } from './processed-columns';

export function ProcessedDocuments() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useDeliveredDocuments({
    evidenceProps: {
      identifier: 'PROCESSED_DELIVERED_DOCUMENTS',
      version: 10,
      tableProps: {
        defaultPreFilters: [
          {
            field: 'processed',
            operation: ApiFilterOperation.EQ,
            value: 'true',
          },
          {
            field: 'removedFromEsFile',
            operation: ApiFilterOperation.EQ,
            value: 'false',
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__DOCUMENTS_DELIVERED_PROCESSED__TABLE__TITLE',
          defaultMessage: 'Zpracované dokumenty',
        }),
        columns: columns,
      },
      detailProps: {
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ES__DOCUMENTS_DELIVERED_PROCESSED__DETAIL__TITLE',
            defaultMessage: 'Zpracovaný dokument',
          }),
          ActionBar,
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
