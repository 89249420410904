import { DeliveredDocumentState, Role, UIElement } from '@enums';

export const deliveredDocumentAccessMap = new Map([
  [
    DeliveredDocumentState.UNPROCESSED,
    new Map([
      [
        Role.MAILROOM,
        [
          UIElement.DeliveredDocument.RETURN,
          UIElement.Attachment.ADD_ATTACHMENT,
          UIElement.Attachment.ADD_ATTACHMENT_BULK,
          UIElement.Attachment.EDIT_ATTACHMENT,
          UIElement.Attachment.DELETE_ATTACHMENT,
          UIElement.Attachment.REVALIDATE_ATTACHMENT,
          UIElement.Attachment.DOWNLOAD_VALIDATION_REPORT,
        ],
      ],
      [
        Role.LUSTRUM,
        [
          UIElement.DeliveredDocument.AGENDA,
          UIElement.DeliveredDocument.SUMMARY,
          UIElement.DeliveredDocument.NOTE,
          UIElement.DeliveredDocument.NEW_COURT_FILE,
          UIElement.DeliveredDocument.NEW_SPR,
          UIElement.DeliveredDocument.NEW_INF,
          UIElement.DeliveredDocument.TO_ES_FILE,
          UIElement.DeliveredDocument.COPY,
          UIElement.DeliveredDocument.RETURN,
          UIElement.DeliveredDocument.SCREENING_FOR_DOCUMENT,
          UIElement.DeliveredDocument.EDIT_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.REOPEN_DELIVERED_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.PRECENDING,
          UIElement.Attachment.ADD_ATTACHMENT,
          UIElement.Attachment.DELETE_ATTACHMENT,
          UIElement.Attachment.EDIT_ATTACHMENT,
          UIElement.Attachment.REVALIDATE_ATTACHMENT,
          UIElement.Attachment.DOWNLOAD_VALIDATION_REPORT,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.DeliveredDocument.AGENDA,
          UIElement.DeliveredDocument.SUMMARY,
          UIElement.DeliveredDocument.NOTE,
          UIElement.DeliveredDocument.NEW_COURT_FILE,
          UIElement.DeliveredDocument.NEW_SPR,
          UIElement.DeliveredDocument.NEW_INF,
          UIElement.DeliveredDocument.TO_ES_FILE,
          UIElement.DeliveredDocument.COPY,
          UIElement.DeliveredDocument.RETURN,
          UIElement.DeliveredDocument.SCREENING_FOR_DOCUMENT,
          UIElement.DeliveredDocument.EDIT_DOCUMENT_BUTTON,
          UIElement.Attachment.REVALIDATE_ATTACHMENT,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.DeliveredDocument.AGENDA,
          UIElement.DeliveredDocument.SUMMARY,
          UIElement.DeliveredDocument.NOTE,
          UIElement.DeliveredDocument.ORIGINATOR,
          UIElement.DeliveredDocument.PAGE_COUNT,
          UIElement.DeliveredDocument.NEW_SPR,
          UIElement.DeliveredDocument.NEW_INF,
          UIElement.DeliveredDocument.TO_ES_FILE,
          UIElement.DeliveredDocument.EDIT_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.REOPEN_DELIVERED_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.SCREENING_FOR_DOCUMENT,
          UIElement.Attachment.EDIT_ATTACHMENT,
          UIElement.Attachment.REVALIDATE_ATTACHMENT,
        ],
      ],
    ]),
  ],
  [
    DeliveredDocumentState.PROCESSED,
    new Map([
      [
        Role.MAILROOM,
        [
          UIElement.Attachment.ADD_ATTACHMENT,
          UIElement.Attachment.EDIT_ATTACHMENT,
          UIElement.Attachment.ADD_ATTACHMENT_BULK,
          UIElement.Attachment.DELETE_ATTACHMENT,
          UIElement.Attachment.REVALIDATE_ATTACHMENT,
          UIElement.Attachment.DOWNLOAD_VALIDATION_REPORT,
        ],
      ],
      [
        Role.LUSTRUM,
        [
          UIElement.DeliveredDocument.SUMMARY,
          UIElement.DeliveredDocument.NOTE,
          UIElement.DeliveredDocument.COPY,
          UIElement.DeliveredDocument.EDIT_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.REOPEN_DELIVERED_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.REMOVE_BUTTON,
          UIElement.DeliveredDocument.SCREENING_FOR_DOCUMENT,
          UIElement.Attachment.REVALIDATE_ATTACHMENT,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.DeliveredDocument.SUMMARY,
          UIElement.DeliveredDocument.NOTE,
          UIElement.DeliveredDocument.COPY,
          UIElement.DeliveredDocument.EDIT_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.SCREENING_FOR_DOCUMENT,
          UIElement.Attachment.REVALIDATE_ATTACHMENT,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.DeliveredDocument.SUMMARY,
          UIElement.DeliveredDocument.NOTE,
          UIElement.DeliveredDocument.ORIGINATOR,
          UIElement.DeliveredDocument.PAGE_COUNT,
          UIElement.DeliveredDocument.DEADLINES,
          UIElement.DeliveredDocument.COMPLAINT,
          UIElement.Attachment.EDIT_ATTACHMENT,
          UIElement.Attachment.ADD_ATTACHMENT,
          UIElement.Attachment.ADD_ATTACHMENT_BULK,
          UIElement.Attachment.DELETE_ATTACHMENT,
          UIElement.Attachment.REVALIDATE_ATTACHMENT,
          UIElement.Attachment.DOWNLOAD_VALIDATION_REPORT,
          UIElement.DeliveredDocument.EDIT_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.REOPEN_DELIVERED_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.REMOVE_BUTTON,
          UIElement.DeliveredDocument.SCREENING_FOR_DOCUMENT,
        ],
      ],
      [
        Role.SOLVER,
        [
          UIElement.DeliveredDocument.EDIT_DOCUMENT_BUTTON,
          UIElement.DeliveredDocument.COMPLAINT,
        ],
      ],
    ]),
  ],
]);
