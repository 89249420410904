import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { OutgoingDocument } from '@models';
import { Agenda, EsFileState, EvidenceApiUrl } from '@enums';

export function useCancelDialog() {
  const { source } = useContext<DetailHandle<OutgoingDocument>>(DetailContext);

  const callApi = useEventCallback(() =>
    abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${source.data?.id}/cancel`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  const showInCourtFile =
    source?.data?.esFile?.agenda === Agenda.COURT &&
    source.data.esFile.state &&
    [
      EsFileState.NEW,
      EsFileState.REGISTER_NEW,
      EsFileState.REGISTER,
      EsFileState.JUDGE_OFFICE,
    ].includes(source.data.esFile.state);

  const showInPlenaryOpinionFile =
    source?.data?.esFile?.agenda === Agenda.PLENARY_OPINION &&
    source.data?.esFile.state === EsFileState.PROPOSED;

  const showInSprFiles =
    source?.data?.esFile?.agenda === Agenda.SPR &&
    source.data.esFile.state === EsFileState.IN_PROGRESS;

  const showInInfFiles =
    source?.data?.esFile?.agenda === Agenda.INF &&
    source.data.esFile.state === EsFileState.IN_PROGRESS;

  const showInDisciplinaryProceedingsFiles =
    source?.data?.esFile?.agenda === Agenda.DISCIPLINARY_PROCEEDINGS &&
    source.data.esFile.state === EsFileState.IN_PROGRESS;

  const showButton =
    source.data?.id &&
    (showInCourtFile ||
      showInPlenaryOpinionFile ||
      showInSprFiles ||
      showInInfFiles ||
      showInDisciplinaryProceedingsFiles);

  return {
    callApi,
    showButton,
  };
}
