import { ApiFilterOperation, useStaticListSource } from '@eas/common-web';

export interface Operation {
  id: ApiFilterOperation | 'DEFAULT';
  name: string;
}

export function useStringOperations() {
  return useStaticListSource<Operation>([
    {
      id: ApiFilterOperation.CONTAINS,
      name: 'Jako',
    },
    {
      id: ApiFilterOperation.EQ,
      name: '=',
    },
    {
      id: ApiFilterOperation.NOT,
      name: '!=',
    },
  ]);
}

export function useNullableComparingOperations() {
  return useStaticListSource<Operation>([
    {
      id: ApiFilterOperation.NOT_NULL,
      name: 'Vyplněno',
    },
    {
      id: ApiFilterOperation.IS_NULL,
      name: 'Nevyplněno',
    },
    {
      id: 'DEFAULT',
      name: '-',
    },
  ]);
}

export function useNullableAutocompleteOperations() {
  return useStaticListSource<Operation>([
    {
      id: ApiFilterOperation.OR,
      name: 'Hodnota',
    },
    {
      id: ApiFilterOperation.NOT_NULL,
      name: 'Vyplněné',
    },
    {
      id: ApiFilterOperation.IS_NULL,
      name: 'Nevyplněné',
    },
  ]);
}

export function useCompareOperations() {
  return useStaticListSource<Operation>([
    {
      id: ApiFilterOperation.EQ,
      name: '=',
    },
    {
      id: ApiFilterOperation.GT,
      name: '>',
    },
    {
      id: ApiFilterOperation.LT,
      name: '<',
    },
    {
      id: ApiFilterOperation.LTE,
      name: '<=',
    },
    {
      id: ApiFilterOperation.GTE,
      name: '>=',
    },
  ]);
}
