/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const LOGIN: MessageType<'502' | '503' | 'ERROR'> = {
  502: [
    <FormattedMessage
      id="ES__MESSAGE__USER__LOGIN__ERROR_502"
      defaultMessage="ERROR 502: Proxy server obdržel od hlavního serveru neplatnou odpověď."
    />,
    SnackbarVariant.ERROR,
  ],
  503: [
    <FormattedMessage
      id="ES__MESSAGE__USER__LOGIN__ERROR_503"
      defaultMessage="ERROR 503: Server dočasně nemůže zpracovat požadavek. Buď je přetížený nebo se provádí údržba."
    />,
    SnackbarVariant.ERROR,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__USER__LOGIN__ERROR"
      defaultMessage="Chyba při přihlašování uživatele."
    />,
    SnackbarVariant.ERROR,
  ],
};

const ASSIGN_ROLES: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__USER__ASSIGN_ROLES__SUCCESS"
      defaultMessage="Role byly uživateli úspěšně přiřazeny."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__USER__ASSIGN_ROLES__ERROR"
      defaultMessage="Chyba při přiřazování rolí uživateli."
    />,
    SnackbarVariant.ERROR,
  ],
};

const UNASSIGN_ROLE: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__USER__UNASSIGN_ROLE__SUCCESS"
      defaultMessage="Role byla uživateli úspěšně odebrána."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__USER__UNASSIGN_ROLE__ERROR"
      defaultMessage="Chyba při odebírání uživatelské role."
    />,
    SnackbarVariant.ERROR,
  ],
};

const REPLACE_USER: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__USER__REPLACE_USER__SUCCESS"
      defaultMessage="Uživatel byl úspěšně nahrazen."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__REPLACE_USER__ERROR"
      defaultMessage="Chyba při nahrazování uživatele."
    />,
    SnackbarVariant.ERROR,
  ],
};

const ACTIVE_USER_SUBSTITUTION: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__ACTIVE_USER_SUBSTITUTION__SUCCESS"
      defaultMessage="Zástup byl úspěšně aktivován."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__ACTIVE_USER_SUBSTITUTION__ERROR"
      defaultMessage="Chyba při aktivaci zástupu."
    />,
    SnackbarVariant.ERROR,
  ],
};

const DEACTIVE_USER_SUBSTITUTION: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__DEACTIVE_USER_SUBSTITUTION__SUCCESS"
      defaultMessage="Zástup byl úspěšně ukončen."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__DEACTIVE_USER_SUBSTITUTION__ERROR"
      defaultMessage="Chyba při deaktivaci zástupu."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default {
  LOGIN,
  ASSIGN_ROLES,
  UNASSIGN_ROLE,
  REPLACE_USER,
  ACTIVE_USER_SUBSTITUTION,
  DEACTIVE_USER_SUBSTITUTION,
};
