import { groupBy, noop, stubFalse, sumBy } from 'lodash';
import React from 'react';
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { lighten } from '@material-ui/core/styles';
import { TableField } from '@eas/common-web';
import { useStyles } from '@modules/dashboard/search/result-table';
import { UnresolvedByJudgeCount } from '@models';
import { ChartView } from '@enums';
import { Chart } from '../card/chart';
import { useYearsColumns } from './unresolved-by-judge-counts-columns';

export function useUnresolvedByJudgeCountsVisualisation({
  data = [],
  chartHeight,
  maxTableRows = 7,
}: {
  data?: UnresolvedByJudgeCount[];
  chartHeight?: number;
  maxTableRows?: number;
}) {
  const Table = () => {
    const classes = useStyles();

    const parsedData = parseUnresolvedByJudgeCountsData(data);
    const yearsColumns = useYearsColumns(data);
    const totalSums: any = { judgeName: 'Celkem' };
    yearsColumns.forEach((column) => {
      totalSums[column.datakey] = sumBy(parsedData, column.datakey);
    });

    return (
      <TableField
        value={[...parsedData, ...(parsedData?.length > 0 ? [totalSums] : [])]}
        onChange={noop}
        columns={[
          {
            name: 'Soudce',
            datakey: 'judgeName',
            width: 150,
          },
          ...yearsColumns,
        ]}
        showRadioCond={stubFalse}
        classes={classes}
        maxRows={maxTableRows}
        showToolbar={false}
        createRowStyle={(item) =>
          item?.judgeName === 'Celkem'
            ? { backgroundColor: lighten('#0d98ba', 0.8) }
            : {}
        }
      />
    );
  };

  const CourtFilesChart = () => {
    const groupedJudges = groupBy(data, 'judgeName');

    const chartData = Object.entries(groupedJudges).map(([key, values]) => {
      const result = {
        judgeName: key,
        senateCount: sumBy(values, 'senateCount'),
        plenaryCount: sumBy(values, 'plenaryCount'),
      };

      return result;
    });

    return (
      <Chart data={chartData} ChartComponent={BarChart} height={chartHeight}>
        <YAxis />
        <Legend
          formatter={(value) =>
            value === 'plenaryCount' ? 'Plenární spisy' : 'Senátní spisy'
          }
        />
        {chartData?.length > 0 && (
          <Tooltip
            formatter={(value: string, name: string) => [
              value,
              name === 'plenaryCount' ? 'Plenární spisy' : 'Senátní spisy',
            ]}
          />
        )}
        <XAxis dataKey="judgeName" allowDataOverflow={true} domain={[]} />
        <Bar dataKey="senateCount" stackId="a" fill="#ff5555" />
        <Bar dataKey="plenaryCount" stackId="a" fill="#5555ff" />
      </Chart>
    );
  };

  const availableViews = [
    {
      view: ChartView.TABLE,
      tooltip: 'Tabulka - nevyřízené soudní spisy dle soudce',
    },
    {
      view: ChartView.BAR_CHART,
      tooltip: 'Sloupcový graf - nevyřízené soudní spisy dle soudce',
    },
  ];

  return {
    Visualisations: {
      [ChartView.BAR_CHART]: CourtFilesChart,
      [ChartView.TABLE]: Table,
    },
    availableViews,
  };
}

const parseUnresolvedByJudgeCountsData = (data: UnresolvedByJudgeCount[]) => {
  const groupedJudges = groupBy(data, 'judgeName');
  const parsedData = Object.entries(groupedJudges).map(([key, values]) => {
    const result: any = { judgeName: key };
    let totalSenate = 0;
    let totalPlenary = 0;

    values.forEach(({ year, plenaryCount, senateCount }) => {
      result[`${year}.plenary`] = plenaryCount;
      result[`${year}.senate`] = senateCount;
      totalPlenary = totalPlenary + (plenaryCount ?? 0);
      totalSenate = totalSenate + (senateCount ?? 0);
    });

    result['totalSenate'] = totalSenate;
    result['totalPlenary'] = totalPlenary;

    return result;
  });

  return parsedData;
};
