import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DictNote } from '@models';

export function useColumns(): TableColumn<DictNote>[] {
  const intl = useIntl();
  const { columnActive } = DictionaryEvidence.useDictionaryColumns<DictNote>();

  return [
    {
      datakey: 'description',
      name: intl.formatMessage({
        id: 'ES__DICT_NOTES__TABLE__COLUMN__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    columnActive,
  ];
}
