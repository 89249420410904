import { flatten, groupBy } from 'lodash';
import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { TableFieldCellProps, TableFieldCells } from '@eas/common-web';

const useStyles = makeStyles((theme) => ({
  totalCell: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    width: 100,
    textAlign: 'center',
    marginLeft: -10,
    borderLeft: '1px solid #fff',
    borderRight: '1px solid #fff',
  },
}));

export function useYearsColumns(DATA: any) {
  const classes = useStyles();

  const TotalCell = (props: TableFieldCellProps<any>) => (
    <div className={classes.totalCell}>
      <TableFieldCells.TextCell {...props} />
    </div>
  );

  const groupedYears = groupBy(DATA, 'year');
  const yearsColumns = [
    ...flatten(
      Object.keys(groupedYears).map((year) => [
        {
          name: `${year} - SN`,
          datakey: `${year}.senate`,
          width: 75,
        },
        {
          name: `${year} - PL`,
          datakey: `${year}.plenary`,
          width: 75,
        },
      ])
    ),
    {
      name: 'Celkem - SN',
      datakey: 'totalSenate',
      width: 90,
      CellComponent: TotalCell,
    },
    {
      name: 'Celkem - PL',
      datakey: 'totalPlenary',
      width: 90,
      CellComponent: TotalCell,
    },
  ];

  return yearsColumns;
}
