import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: 32,
  },
  line: {
    width: '100%',
    flexShrink: 1,
  },
  text: {
    textAlign: 'center',
    padding: '0 16px',
    whiteSpace: 'nowrap',
  },
}));

export function DashboardDivider({ text }: { text: React.ReactNode }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Divider className={classes.line} />
      <Typography variant="body1" className={classes.text}>
        {text}
      </Typography>
      <Divider className={classes.line} />
    </div>
  );
}
