import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { useDefaultIndexRole } from '@modules/role/role-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Agenda, DisciplinaryProceedingsProposer, EsFileState } from '@enums';
import { disableBtnFactory } from '../es-file/es-file-utils';
import { useEsFiles } from '../es-file/es-files';
import { useColumns } from './disciplinary-proceedings-file-columns';
import { Toolbar } from './disciplinary-proceedings-file-toolbar';

export function DisciplinaryProceedingsFiles() {
  const intl = useIntl();
  const { defaultSettlementMethods } = useContext(StaticDataContext);
  const [defaultDisciplinaryProceedingsIndexRole] = useDefaultIndexRole(
    Agenda.DISCIPLINARY_PROCEEDINGS
  );

  const defaultSettlementMethod = defaultSettlementMethods.find(
    (method) => method.agenda === Agenda.DISCIPLINARY_PROCEEDINGS
  );

  const defaultPreFilters = [
    {
      field: 'agenda.id',
      operation: ApiFilterOperation.EQ,
      value: Agenda.DISCIPLINARY_PROCEEDINGS,
    },
  ];
  const columns = useColumns();

  const defaultDisciplinaryProceedingsFile = {
    id: uuidv4(),
    agenda: Agenda.DISCIPLINARY_PROCEEDINGS,
    state: EsFileState.NEW,
    proposer: DisciplinaryProceedingsProposer.CHAIRMAN,
    indexUserRole: defaultDisciplinaryProceedingsIndexRole!,
    settlementMethods: defaultSettlementMethod ? [defaultSettlementMethod] : [],
  };

  const { evidenceBag } = useEsFiles({
    evidenceProps: {
      version: 6,
      tableProps: {
        columns,
        defaultPreFilters,
        tableName: intl.formatMessage({
          id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__TITLE',
          defaultMessage: 'Spisy kárného řízení',
        }),
        toolbarProps: {
          after: <Toolbar />,
        },
      },
      detailProps: {
        initNewItem: () => ({ ...defaultDisciplinaryProceedingsFile }),
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__TITLE',
            defaultMessage: 'Spis kárného řízení',
          }),
          disableBtn: disableBtnFactory(defaultDisciplinaryProceedingsFile),
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
