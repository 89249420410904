enum Element {
  /**
   * AKCE: Přidat přijatý dokument
   */
  ASSING_DELIVERED_DOCUMENT_BUTTON = 'ASSING_DELIVERED_DOCUMENT_BUTTON',

  /**
   * AKCE: Přidat interní dokument
   */
  CREATE_INTERNAL_DOCUMENT_BUTTON = 'CREATE_INTERNAL_DOCUMENT_BUTTON',

  /**
   * AKCE: Přidat odchozí dokument
   */
  CREATE_OUTGOING_DOCUMENT_BUTTON = 'CREATE_OUTGOING_DOCUMENT_BUTTON',

  /**
   * AKCE: Vytvořit akceptační dopis
   */
  CREATE_ACCEPTANCE_LETTER_BUTTON = 'CREATE_ACCEPTANCE_LETTER_BUTTON',

  /**
   * AKCE: Vytvořit protokol o hlasování
   */
  CREATE_VOTING_PROTOCOL_BUTTON = 'CREATE_VOTING_PROTOCOL_BUTTON',

  /**
   * AKCE: Nastavit stížnost
   */
  SET_COMPLAINT_BUTTON = 'SET_COMPLAINT_BUTTON',

  /**
   * AKCE: Nastavit správní rozhodnutí
   */
  SET_DECISION_BUTTON = 'SET_DECISION_BUTTON',
}

export default Element;
