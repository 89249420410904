import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {
  FormPanel,
  FormTableField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { TableActionButton } from '@components/action-button/table-action-button';
import { Role } from '@enums';
import { importUsers } from './user-api';

const useColumns = () => {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ES__SYNC_USERS__COLUMN__EMAIL',
        defaultMessage: 'Email',
      }),
      datakey: 'email',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__SYNC_USERS__COLUMN__FIRST_NAME',
        defaultMessage: 'Jméno',
      }),
      datakey: 'firstName',
      width: 150,
    },
    {
      name: intl.formatMessage({
        id: 'ES__SYNC_USERS__COLUMN__LAST_NAME',
        defaultMessage: 'Příjmení',
      }),
      datakey: 'lastName',
      width: 150,
    },
    {
      name: intl.formatMessage({
        id: 'ES__SYNC_USERS__COLUMN__USERNAME',
        defaultMessage: 'Uživatelské jméno',
      }),
      datakey: 'username',
      width: 150,
    },
  ];
};

function ResultFields() {
  const intl = useIntl();
  const columns = useColumns();

  const { deactivatedCount, newCount, updatedCount } = useFormSelector(
    (values: {
      deactivatedCount: number;
      newCount: number;
      updatedCount: number;
    }) => ({
      deactivatedCount: values.deactivatedCount,
      newCount: values.newCount,
      updatedCount: values.updatedCount,
    })
  );

  return (
    <>
      <div style={{ width: 700, marginTop: 30 }}>
        {!newCount && !updatedCount && !deactivatedCount && (
          <Typography>
            {intl.formatMessage({
              id: 'ES__SYNC_USERS__COLUMN__NO_CHANGES',
              defaultMessage: 'Beze změn',
            })}
          </Typography>
        )}
        {!!newCount && (
          <FormPanel
            label={intl.formatMessage({
              id: 'ES__SYNC_USERS__COLUMN__NEW',
              defaultMessage: 'Importovaní uživatelé',
            })}
          >
            <FormTableField
              name="newUsers"
              layoutOptions={{
                noSpacing: true,
              }}
              columns={columns}
              showRadioCond={() => false}
              showDetailBtnCond={() => false}
              showToolbar={false}
              maxRows={12}
              labelOptions={{
                hide: true,
              }}
            />
          </FormPanel>
        )}
        {!!updatedCount && (
          <FormPanel
            label={intl.formatMessage({
              id: 'ES__SYNC_USERS__COLUMN__UPDATED',
              defaultMessage: 'Změneni uživatelé',
            })}
          >
            <FormTableField
              name="updatedUsers"
              layoutOptions={{
                noSpacing: true,
              }}
              columns={columns}
              showRadioCond={() => false}
              showDetailBtnCond={() => false}
              showToolbar={false}
              maxRows={12}
              labelOptions={{
                hide: true,
              }}
            />
          </FormPanel>
        )}
        {!!deactivatedCount && (
          <FormPanel
            label={intl.formatMessage({
              id: 'ES__SYNC_USERS__COLUMN__DEACTIVATED',
              defaultMessage: 'Deaktivovaní uživatelé',
            })}
          >
            <FormTableField
              name="deactivatedUsers"
              layoutOptions={{
                noSpacing: true,
              }}
              columns={columns}
              showRadioCond={() => false}
              showDetailBtnCond={() => false}
              showToolbar={false}
              maxRows={12}
              labelOptions={{
                hide: true,
              }}
            />
          </FormPanel>
        )}
      </div>
    </>
  );
}

export function TableToolbar() {
  const intl = useIntl();
  const { hasPermission } = useContext(UserContext);

  const showButton = hasPermission(Role.HEAD_OSV);

  return (
    <>
      {showButton && (
        <TableActionButton
          promptKey="SYNC_USERS"
          apiCall={() => importUsers()}
          buttonDisabled={false}
          buttonLabel={<>AD</>}
          buttonTooltip={intl.formatMessage({
            id: 'ES__USERS__ACTIONBAR__SYNC_USERS',
            defaultMessage: 'Synchronizovat uživatelé s AD',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__USERS__ACTIONBAR__SYNC_USERS__TITLE',
            defaultMessage: 'Synchronizace uživatelů s AD',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__USERS__ACTIONBAR__SYNC_USERS__TEXT',
            defaultMessage:
              'Opravdu chcete spustit sychronizaci uživatelů s AD?',
          })}
          resultDialog={{
            title: intl.formatMessage({
              id: 'ES__USERS__ACTIONBAR__SYNC_USERS_RESULT__TITLE',
              defaultMessage: 'Synchronizace uživatelů s AD',
            }),
            text: intl.formatMessage({
              id: 'ES__USERS__ACTIONBAR__SYNC_USERS_RESULT__TEXT',
              defaultMessage: 'Výsledek synchronizace:',
            }),
            FormFields: ResultFields,
            width: 800,
          }}
        />
      )}
    </>
  );
}
