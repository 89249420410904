import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {
  ActionButtonHandle,
  ApiFilterOperation,
  DetailContext,
  DetailMode,
  FieldFilter,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarButtons,
  TableFieldToolbarHandle,
  TableFieldToolbarProps,
  useStaticListSource,
} from '@eas/common-web';
import { useViewingRequestFields } from '@modules/viewing-request/viewing-request-fields';
import { useValidationSchema } from '@modules/viewing-request/viewing-request-schema';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { DictionaryObject, ViewingRequest } from '@models';
import { ViewingMethod } from '@enums';
import { useAddViewingRequest } from './dialog-actions/add-viewing-request-hook';
import {
  ApproveViewingRequestButton,
  RejectViewingRequestButton,
} from './dialog-actions/change-viewing-request-state-hook';

export const ViewingRequestToolbar = forwardRef(function ViewingRequestToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
  }: TableFieldToolbarProps & {
    disabled: boolean;
  },
  ref: Ref<TableFieldToolbarHandle>
) {
  const classes = useStyles();
  const intl = useIntl();

  const detailCtx = useContext(DetailContext);

  const { viewingMethods, viewingRequestStates, participantTypes } =
    useContext(StaticDataContext);

  const { source: tasks } = useContext(RemoteTableFieldContext);
  const { callApi: addViewingRequestApiCall } = useAddViewingRequest();

  const validationSchema = useValidationSchema();

  useEffect(
    () => {
      setSelectedIndex(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailCtx?.source]
  );

  const approveRef = useRef<ActionButtonHandle>(null);
  const rejectRef = useRef<ActionButtonHandle>(null);

  useImperativeHandle(
    ref,
    () => ({
      executeAction: (action) => {
        switch (action) {
          case 'APPROVE':
            approveRef.current?.executeAction();
            break;
          case 'REJECT':
            rejectRef.current?.executeAction();
            break;

          default:
            break;
        }
      },
    }),
    []
  );

  const filtersFields = useFiltersFields(
    viewingMethods,
    viewingRequestStates,
    participantTypes
  );

  const FormFields = useViewingRequestFields();

  const selectedViewingRequest =
    selectedIndex !== undefined ? tasks.items[selectedIndex] : {};

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_PHYSICAL_REQUEST"
          apiCall={(_, formData) =>
            addViewingRequestApiCall(selectedViewingRequest.id, formData)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__VIEWING_REQUESTS_TOOLBAR__ADD_VIEWING_REQUEST',
            defaultMessage: 'Žádost o fyzické nahlížení',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__VIEWING_REQUESTS_TOOLBAR__VIEW_VIEWING_REQUEST__TITLE',
            defaultMessage: 'Žádost o nahlížení',
          })}
          dialogText={null}
          dialogWidth={700}
          FormFields={() => (
            <DetailContext.Provider
              value={{
                ...detailCtx,
                mode: DetailMode.NEW,
              }}
            >
              <FormFields />
            </DetailContext.Provider>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formInitialValues={
            {
              viewingMethod: ViewingMethod.PHYSICAL,
              esFile: detailCtx.source.data,
            } as ViewingRequest
          }
          ButtonComponent={({ label, onClick }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled ?? false}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__VIEWING_REQUESTS_TOOLBAR__ADD_PHYSICAL_REQUEST__BUTTON',
                  defaultMessage: 'Žádost o fyzické nahlížení',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formValidationSchema={validationSchema}
        />

        <ApproveViewingRequestButton
          ref={approveRef}
          viewingRequest={selectedViewingRequest}
          modes={[]}
        />
        <RejectViewingRequestButton
          ref={rejectRef}
          viewingRequest={selectedViewingRequest}
          modes={[]}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={tasks}
          filtersFields={filtersFields}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    ref: Ref<TableFieldToolbarHandle>;
    disabled: boolean;
  }
) => ReactElement;

function useFiltersFields(
  viewingMethods: DictionaryObject[],
  states: DictionaryObject[],
  participantTypes: DictionaryObject[]
) {
  const intl = useIntl();

  const useViewingMethods = () => useStaticListSource(viewingMethods);
  const useStates = () => useStaticListSource(states);
  const useParticipantTypes = () => useStaticListSource(participantTypes);

  const filtersFields = [
    {
      datakey: 'created',
      filterkey: 'created',
      label: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__CREATED',
        defaultMessage: 'Datum vytvoření',
      }),
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterLocalDateTimeCell,
    },
    {
      datakey: 'requester.label',
      filterkey: 'requester.label',
      label: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__REQUESTER',
        defaultMessage: 'Nahlížející',
      }),
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      datakey: 'dataBoxId',
      filterkey: 'dataBoxId',
      label: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__DATA_BOX_ID',
        defaultMessage: 'Číslo datové schránky',
      }),
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      datakey: 'requester.type',
      filterkey: 'requester.type.name',
      label: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__REQUESTER_TYPE',
        defaultMessage: 'Typ účastníka',
      }),
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useParticipantTypes),
    },
    {
      datakey: 'viewingMethod',
      filterkey: 'viewingMethod.name',
      label: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__VIEWING_METHOD',
        defaultMessage: 'Způsob nahlížení',
      }),
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useViewingMethods),
    },
    {
      datakey: 'state',
      filterkey: 'state.name',
      label: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useStates),
    },
  ];

  return filtersFields;
}
