import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DecisionText } from '@composite/decision-text/decision-text';
import { AnonymizedDecisionActions } from '../anonymized-decision-actions';

export function DifferentOpinionFields() {
  return (
    <>
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__TEXT_DIFFERENT_OPINION"
            defaultMessage="Odlišné stanovisko - plné znění"
          />
        }
        name="text.differentOpinion"
        enableHighlighting={true}
      />
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__ANONYMIZED_TEXT_DIFFERENT_OPINION"
            defaultMessage="Odlišné stanovisko - anonymizováno"
          />
        }
        name="anonymizedText.differentOpinion"
        Before={<AnonymizedDecisionActions fieldName="differentOpinion" />}
        enableHighlighting={true}
      />
    </>
  );
}
