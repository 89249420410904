import { createContext } from 'react';
import { ScrollableSource } from '@eas/common-web';
import { RawSimpleLog } from '@models';

export interface SubjectHistoryContextType {
  historySource: ScrollableSource<RawSimpleLog>;
}

export const SubjectHistoryContext = createContext<SubjectHistoryContextType>(
  undefined as any
);
