import clsx from 'clsx';
import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useEventCallback } from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DashboardCard } from '../card';
import { useStyles } from '../dashboard';
import { DashboardDivider } from '../divider';
import { ResultTable } from './result-table';
import {
  useDecisionColumns,
  useDocumentColumns,
  useEsFileColumns,
} from './search-columns';
import { useSearch } from './search-hook';

export function Search() {
  const classes = useStyles();

  const {
    documentTypes,
    documentDeliveryMethods,
    documentDispatchStates,
    agendas,
    states,
    anonymizationRequests,
    decisionForms,
  } = useContext(StaticDataContext);

  const {
    searchText,
    setSearchText,
    esFileSource,
    documentSource,
    decisionSource,
    handleSearch,
  } = useSearch();

  const documentColumns = useDocumentColumns({
    documentTypes,
    documentDeliveryMethods,
    documentDispatchStates,
  });

  const esFileColumns = useEsFileColumns({ agendas, states });
  const decisionColumns = useDecisionColumns({
    anonymizationRequests,
    decisionForms,
  });

  const handleSearchTextChange = useEventCallback((e) => {
    setSearchText(e.target.value);
  });

  const handleKeyDown = useEventCallback((e) => {
    if (e.key === 'Enter' && searchText) {
      e.preventDefault();

      handleSearch();
    }
  });

  return (
    <Grid item xs={9} className={classes.wrapper}>
      <Typography
        variant="subtitle1"
        className={clsx(classes.padded, classes.subtitle)}
      >
        Vyhledávání
      </Typography>

      <DashboardCard title="Rychlé vyhledání spisu, dokumentu, rozhodnutí dle spisové značky (čísla jednacího), čárového kódu">
        <Paper className={classes.input} elevation={0}>
          <InputBase
            placeholder="Začněte psát"
            fullWidth={true}
            value={searchText}
            onChange={handleSearchTextChange}
            onKeyDown={handleKeyDown}
            autoFocus={true}
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.search}
            disabled={!searchText}
            onClick={handleSearch}
          >
            Hledej
          </Button>
        </Paper>
      </DashboardCard>

      <DashboardDivider text="Výsledky" />
      <ResultTable
        title="Spisy"
        source={esFileSource}
        columns={esFileColumns}
      />
      <ResultTable
        title="Dokumenty"
        source={documentSource}
        columns={documentColumns}
      />
      <ResultTable
        title="Rozhodnutí"
        source={decisionSource}
        columns={decisionColumns}
      />
    </Grid>
  );
}
