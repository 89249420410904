import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    { key: 'esFile', label: 'Spis' },
    { key: 'document', label: 'Dokument' },
    { key: 'solver', label: 'Pro uživatele' },
    { key: 'state', label: 'Stav úlohy' },
    { key: 'type', label: 'Typ úlohy' },
  ];
}
