import * as Yup from 'yup';
import { FileRef } from '@eas/common-web';
import { DocumentAttachment } from '@models';
import { AttachmentType } from '@enums';

export function useAddAttachmentValidationSchema() {
  return Yup.object<DocumentAttachment>().shape({
    name: Yup.string().nullable().required(),
    type: Yup.mixed<AttachmentType>().nullable().required(),
    file: Yup.mixed<FileRef>()
      .nullable()
      .test('', '', function () {
        const { parent, createError, path } = this;

        if (parent.fileLoading) {
          return createError({ path, message: 'Soubor se nahrává' });
        }

        return true;
      }),
  });
}

export function useAddAttachmentBulkValidationSchema() {
  return Yup.object<DocumentAttachment & { count: string }>().shape({
    name: Yup.string().nullable().required(),
    count: Yup.string().nullable().required(),
  });
}
