import { getErrorMessage } from '@utils/error-message';
import React, { useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  AbortableFetch,
  ApiFilterOperation,
  DetailContext,
  DialogHandle,
  DictionaryFieldDialog,
  FieldFilter,
  SnackbarContext,
  SnackbarVariant,
  TableFieldCells,
  TableFieldToolbarButton,
  abortableFetch,
  useEventCallback,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Document, ErrorObject } from '@models';
import { DocumentType, EvidenceApiUrl } from '@enums';

function callApi(id: string, documents: string[]) {
  return abortableFetch(`${EvidenceApiUrl.ES_FILES}/${id}/document/assign`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(documents),
  });
}

export function useAssignDeliveredDocumentDialog() {
  const { source } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useRef<AbortableFetch | null>(null);
  const intl = useIntl();

  const wrappedCallApi = useEventCallback(async (formData: Document) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(source.data.id, [formData.id]);
      await fetch.current.none();

      source.refresh();

      const message = intl.formatMessage({
        id: 'EAS_ACTION_BUTTON_MSG_SUCCESS',
        defaultMessage: 'Akce byla úspěšně vykonána.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
    } catch (e) {
      const err = e as ErrorObject<string>;

      const message = getErrorMessage(err);

      if (err.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  return {
    callApi: wrappedCallApi,
  };
}

export function AssignDeliveredDocumentButton({
  disabled,
}: {
  disabled: boolean;
}) {
  const intl = useIntl();

  const dialogRef = useRef<DialogHandle>(null);

  const { callApi: addDeliveredDocumentApiCall } =
    useAssignDeliveredDocumentDialog();

  const deliveredDocuments = useScrollableSource<Document>({
    url: `${EvidenceApiUrl.DOCUMENTS}/list`,
  });

  const { documentDeliveryMethods } = useContext(StaticDataContext);
  const useDocumentDeliveryMethods = () =>
    useStaticListSource(documentDeliveryMethods);

  return (
    <>
      <TableFieldToolbarButton
        title={
          <FormattedMessage
            id="ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT"
            defaultMessage="Přidat doručený dokument"
          />
        }
        variant="contained"
        color="primary"
        onClick={() => dialogRef.current?.open()}
        IconComponent={AddCircleOutlineIcon}
        disabled={disabled}
        show={true}
      >
        <Typography variant="body2">
          <FormattedMessage
            id="ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__BUTTON"
            defaultMessage="Přidat doručený"
          />
        </Typography>
      </TableFieldToolbarButton>
      <DictionaryFieldDialog<Document>
        title={
          <FormattedMessage
            id="ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__DIALOG"
            defaultMessage="Doručené dokumenty - nezpracované"
          />
        }
        ref={dialogRef}
        columns={[
          {
            name: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__DELIVERY_DATE',
              defaultMessage: 'Datum doručení',
            }),
            datakey: 'deliveryDate',
            sortkey: 'deliveryDate',
            width: 120,
            CellComponent: TableFieldCells.DateCell,
          },
          {
            name: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__INCOMING_NUMBER',
              defaultMessage: 'Došlé číslo',
            }),
            datakey: 'incomingNumber',
            sortkey: 'incomingNumber',
            width: 200,
          },
          {
            name: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__SUMMARY',
              defaultMessage: 'Stručný obsah',
            }),
            datakey: 'summary',
            sortkey: 'summary',
            width: 200,
          },
          {
            name: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__SNDER',
              defaultMessage: 'Odesílatel',
            }),
            datakey: 'delivery.sender.label',
            width: 400,
          },
          {
            name: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__DELIVERY_METHOD',
              defaultMessage: 'Způsob doručení',
            }),
            datakey: 'deliveryMethod',
            sortkey: 'deliveryMethod.name',
            width: 150,
            CellComponent: TableFieldCells.useSelectCellFactory(
              useDocumentDeliveryMethods
            ),
          },
        ]}
        filtersFields={[
          {
            label: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__DELIVERY_DATE',
              defaultMessage: 'Datum doručení',
            }),
            datakey: 'deliveryDate',
            filterkey: 'deliveryDate',
            defaultOperation: ApiFilterOperation.EQ,
            FilterComponent: FieldFilter.FilterDateCell,
          },
          {
            label: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__INCOMING_NUMBER',
              defaultMessage: 'Došlé číslo',
            }),
            datakey: 'incomingNumber',
            filterkey: 'incomingNumber',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterTextCell,
          },
          {
            label: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__SUMMARY',
              defaultMessage: 'Stručný obsah',
            }),
            datakey: 'summary',
            filterkey: 'summary',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterTextCell,
          },
          {
            label: intl.formatMessage({
              id: 'ES__DOCUMENTS_TOOLBAR__ADD_DELIVERED_DOCUMENT__DELIVERY_METHOD',
              defaultMessage: 'Způsob doručení',
            }),
            datakey: 'deliveryMethod',
            filterkey: 'deliveryMethod.id',
            defaultOperation: ApiFilterOperation.EQ,
            FilterComponent: FieldFilter.FilterSelectCell(
              useDocumentDeliveryMethods
            ),
          },
        ]}
        preFilters={[
          {
            field: 'processed',
            operation: ApiFilterOperation.EQ,
            value: 'false',
          },
          {
            field: 'type.id',
            operation: ApiFilterOperation.EQ,
            value: DocumentType.DELIVERED,
          },
        ]}
        sorts={[
          {
            field: 'incomingNumber',
            order: 'DESC',
            type: 'FIELD',
          },
        ]}
        onChange={
          addDeliveredDocumentApiCall as (
            formData: Document | Document[]
          ) => Promise<undefined>
        }
        source={deliveredDocuments}
        width={1000}
      />
    </>
  );
}
