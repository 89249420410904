import * as Yup from 'yup';

export function useRoleValidationSchema() {
  return Yup.object().shape({
    user: Yup.mixed().required('Povinné pole'),
  });
}

export function useDefaultRoleValidationSchema() {
  return Yup.object().shape({
    agenda: Yup.mixed().required('Povinné pole'),
  });
}
