import { HistoryFieldDescriptor } from '@composite/history/history-types';
import { useHistoryDescriptors as useCommonDocumentHistoryDescriptors } from '../document/document-history-descriptors';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const documentDescriptors = useCommonDocumentHistoryDescriptors();

  return [
    ...documentDescriptors,
    {
      key: 'deliveryDate',
      label: 'Datum doručení',
    },
    {
      key: 'decision',
      label: 'Rozhodnutí',
    },
    {
      key: 'linkedDecision',
      label: 'Navázné rozhodnutí',
    },
  ];
}
