import React, { useMemo } from 'react';
import { scheduleRunsFactory } from '@eas/common-web';

export function ScheduleRuns() {
  const Runs = scheduleRunsFactory(null);

  const MemoizedRuns = useMemo(() => Runs, []);

  return <MemoizedRuns />;
}
