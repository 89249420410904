import { stubFalse, stubTrue } from 'lodash';
import React, { useContext, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { grey } from '@material-ui/core/colors';
import {
  ActionButtonHandle,
  FormContext,
  FormFieldContext,
  FormPanel,
  FormTableField,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { Contact } from '@models';
import { useContactColumns } from './contact-columns';
import { ContactDialog } from './contact-dialog';
import { ContactToolbar } from './contact-toolbar';

export interface ContactFieldProps {
  disabled?: boolean;
  display: 'IN_SUBJECT' | 'OUT_OF_SUBJECT';
  onRowSelect?: (row: Contact | null, index?: number) => void;
}

export function ContactField({
  disabled,
  display,
  onRowSelect,
}: ContactFieldProps) {
  const formContext = useContext(FormContext);

  const contacts = formContext?.getFieldValues().contacts;
  const contactColumns = useContactColumns();

  const editRef = useRef<ActionButtonHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <ContactToolbar
            {...props}
            ref={editRef}
            contacts={contacts}
            display={display}
            disabled={disabled}
          />
        );
      },
    [disabled, contacts]
  );

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__SUBJECTS__DETAIL__PANEL_TITLE__CONTACTS"
          defaultMessage="Kontakty"
        />
      }
      expandable={false}
    >
      <FormFieldContext.Provider value={{ disabled: false }}>
        <FormTableField
          name="contacts"
          columns={contactColumns}
          onSelect={(row) => {
            formContext.setFieldValue('selectedContact', row);
            onRowSelect?.(row);
          }}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          FormFieldsComponent={ContactDialog}
          ToolbarComponent={ToolbarComponent}
          showDetailBtnCond={stubFalse}
          showRadioCond={stubTrue}
          createRowStyle={(item: Contact) =>
            !item?.active
              ? {
                  color: grey[500],
                }
              : {}
          }
        />
      </FormFieldContext.Provider>
    </FormPanel>
  );
}
