import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { useUserRoles } from '@modules/role/role-api';
import { CreatedByCell } from '@components/evidence/authored-evidence/created-by-cell';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Task } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { Redirect } from '../redirect/redirect';

export function useColumns(): TableFieldColumn<Task>[] {
  const intl = useIntl();
  const { taskStates, taskTypes } = useContext(StaticDataContext);

  const useTypes = () => useStaticListSource(taskTypes);
  const useStates = () => useStaticListSource(taskStates);

  const columns: TableFieldColumn<Task>[] = [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: function Cell(props: TableFieldCellProps<Task>) {
        const url = EvidenceBrowserUrl.TASKS;

        return (
          <>
            <Redirect url={url} id={props.rowValue!.id} />
          </>
        );
      },
    },
    {
      datakey: 'name',
      sortkey: 'name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TASKS__TABLE_FIELD__COLUMN__NAME',
        defaultMessage: 'Název',
      }),
      width: 200,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TASKS__TABLE_FIELD__COLUMN__TYPE',
        defaultMessage: 'Typ úlohy',
      }),
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useTypes),
    },
    {
      datakey: 'document.referenceNumber',
      sortkey: 'document.referenceNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TASKS__TABLE_FIELD__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      width: 200,
    },
    {
      datakey: 'createdBy.name',
      sortkey: 'createdBy.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TASKS__TABLE_FIELD__COLUMN__CREATED_BY',
        defaultMessage: 'Vytvořil',
      }),
      width: 150,
      CellComponent: CreatedByCell,
    },
    {
      datakey: 'solver',
      sortkey: 'solver.label',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TASKS__TABLE_FIELD__COLUMN__SOLVER',
        defaultMessage: 'Pro uživatele',
      }),
      width: 250,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useUserRoles,
        multiple: false,
        labelMapper: (value) => value.label ?? '',
      }),
    },
    {
      datakey: 'created',
      sortkey: 'created',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TASKS__TABLE_FIELD__COLUMN__CREATED',
        defaultMessage: 'Datum vytvoření',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TASKS__TABLE_FIELD__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useStates),
    },
  ];

  return columns;
}
