import { useFetch } from '@eas/common-web';
import { DictionaryObject } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useNaspisLogEventTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.NASPIS_LOG_RECORDS}/type/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
