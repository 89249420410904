import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { DecisionState } from '@enums';
import { useDecisions } from '../decision/decisions';

export function InProgressDecisions() {
  const intl = useIntl();

  const defaultPreFilters = [
    {
      field: 'state.id',
      operation: ApiFilterOperation.EQ,
      value: DecisionState.IN_PROGRESS,
    },
  ];

  const { evidenceBag } = useDecisions({
    evidenceProps: {
      tableProps: {
        defaultPreFilters,
        tableName: intl.formatMessage({
          id: 'ES__IN_PROGRESS_DECISIONS__TABLE__TITLE',
          defaultMessage: 'Rozhodnutí - rozpracovaná',
        }),
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
