import { format, parseISO } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  LocaleContext,
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { useContactColumns } from '@modules/subject/subject-columns';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { LegalAdviser, LegalEntity, NaturalPerson, Participant } from '@models';
import { SubjectType } from '@enums';

export function useLegalAdviserColumns(): TableFieldColumn<LegalAdviser>[] {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const { legalAdviserTypes } = useContext(StaticDataContext);

  const { columnAddress, columnDataBoxId } = useContactColumns({
    datakeyPrefix: 'contact',
  });

  const useTypes = () => useStaticListSource(legalAdviserTypes);

  const columns: TableFieldColumn<LegalAdviser>[] = [
    {
      name: intl.formatMessage({
        id: 'ES__LEGAL_ADVISERS__COLUMN__NAME',
        defaultMessage: 'Příjmení a jméno / Název',
      }),
      datakey: 'contact.subject.name',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__LEGAL_ADVISERS__COLUMN__OTHER_IDENTIFICATION',
        defaultMessage: 'Jiná identifikace',
      }),
      datakey: 'contact.subject.otherIdentification',
      width: 200,
    },
    columnAddress,
    {
      name: intl.formatMessage({
        id: 'ES__LEGAL_ADVISERS__COLUMN__YEAR_OR_ICO',
        defaultMessage: 'Rok narození / IČO',
      }),
      datakey: 'contact.subject.ico',
      width: 200,
      CellComponent: function Cell(props) {
        let value = '';
        const subject = props.rowValue.contact.subject;

        if (subject?.type !== SubjectType.NATURAL_PERSON) {
          value = (subject as LegalEntity).ico ?? '';
        } else if (subject?.type === SubjectType.NATURAL_PERSON) {
          const person = subject as NaturalPerson;
          value = person?.birthDate
            ? format(parseISO(person?.birthDate), locale.dateFormat)
            : '';
        }

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
    columnDataBoxId,
    {
      name: intl.formatMessage({
        id: 'ES__LEGAL_ADVISERS__COLUMN__TYPE',
        defaultMessage: 'Typ právního zástupce',
      }),
      datakey: 'legalAdviserType',
      sortkey: 'legalAdviserType.name',
      CellComponent: TableFieldCells.useSelectCellFactory(useTypes),
      width: 300,
    },
    {
      name: intl.formatMessage({
        id: 'ES__LEGAL_ADVISERS__COLUMN__CLIENTS',
        defaultMessage: 'Klienti',
      }),
      datakey: 'clients',
      width: 200,
      CellComponent: function Cell(props) {
        const clients = props.value;

        const value = clients
          ?.map((client: Participant) => client.label)
          .join('; ');

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
  ];

  return useMemo(() => columns, [intl, locale.dateFormat]);
}
