import { abortableFetch, useEventCallback } from '@eas/common-web';
import { UserRole } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useAssignRoleDialog() {
  const callApi = useEventCallback((id: string, formData: UserRole) => {
    return abortableFetch(`${EvidenceApiUrl.USER_ROLE}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        role: id,
        ...formData,
      }),
    });
  });

  return {
    callApi,
  };
}
