import { useIntl } from 'react-intl';
import { TableFieldCells, TableFieldColumn } from '@eas/common-web';
import { UserSubstitution } from '@models';

export function useUserSubstitutionLogColumns(): TableFieldColumn<UserSubstitution>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'start',
      sortkey: 'started',
      name: intl.formatMessage({
        id: 'ES__USER_SUBSTITUTION_LOGS__COLUMN__START',
        defaultMessage: 'Začátek',
      }),
      width: 300,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'end',
      sortkey: 'ended',
      name: intl.formatMessage({
        id: 'ES__USER_SUBSTITUTION_LOGS__COLUMN__END',
        defaultMessage: 'Konec',
      }),
      width: 300,
      CellComponent: TableFieldCells.DateTimeCell,
    },
  ];
}
