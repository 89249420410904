import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Avatar from '@material-ui/core/Avatar/Avatar';
import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card/Card';
import Container from '@material-ui/core/Container/Container';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { NavigationContext } from '@eas/common-web';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
  header: {
    textAlign: 'left',
    marginBottom: 30,
    fontSize: 16,
    fontWeight: 500,
  },
  button: {
    width: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: 16,
    background:
      'linear-gradient(0deg, rgb(245 246 247) 0%, rgb(255 255 255) 100%)',
    margin: '0 0 24px',
    padding: 32,
    minWidth: 500,
  },
}));

export function Logout() {
  const { navigate } = useContext(NavigationContext);
  const classes = useStyles();

  return (
    <Container maxWidth="xs" className={classes.paper}>
      <Card className={classes.card} elevation={2}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography className={classes.title} variant="h5">
          <FormattedMessage id="ES__LOGOUT__TITLE" defaultMessage="Odhlášení" />
        </Typography>
        <Typography className={classes.header} variant="h5">
          <FormattedMessage
            id="ES__LOGOUT__TEXT"
            defaultMessage="Uživatel byl úspěšně odhlášen."
          />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => navigate('/')}
          disableElevation
        >
          <Typography component="span" variant="body1">
            <FormattedMessage
              id="ES__LOGOUT__LOGIN"
              defaultMessage="Znovu přihlásit"
            />
          </Typography>
        </Button>
      </Card>
    </Container>
  );
}
