import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormSelect,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useBarcodePrinters } from '@modules/dict-barcode-printer/dict-barcode-printer-api';
import { useAgendas } from '@modules/es-file/es-file-api';
import { useRoles } from '@modules/role/role-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { UserRole } from '@models';
import { Role } from '@enums';

const useStyles = makeStyles({
  warning: {
    color: red['A700'],
    marginTop: 10,
  },
});

export function UserRoleDialog() {
  const [roles] = useRoles();
  const [agendas] = useAgendas();

  const { mode } = useContext(DetailContext);
  const classes = useStyles();

  const roleSource = useStaticListSource(roles ?? []);
  const agendaSource = useStaticListSource(agendas ?? []);

  const { selectedRole } = useFormSelector((values: UserRole) => ({
    selectedRole: values.role,
  }));

  const showAgendaField = [Role.INDEX, Role.SOLVER, Role.READER].find(
    (role) => role === selectedRole
  );
  const showBarcodePrinterField = [Role.LUSTRUM, Role.MAILROOM].find(
    (role) => role === selectedRole
  );

  const barcodePrinters = useBarcodePrinters();

  return (
    <>
      <FormSelect
        name="role"
        label={
          <FormattedMessage
            id="ES__USER_ROLES__FIELD_LABEL__ROLE"
            defaultMessage="Užívatelská role"
          />
        }
        required={true}
        source={roleSource}
        autoFocus={true}
        valueIsId={true}
        disabled={mode !== DetailMode.NEW}
      />
      {showAgendaField && (
        <FormSelect
          name="agendas"
          label={
            <FormattedMessage
              id="ES__USER_ROLES__FIELD_LABEL__AGENDA"
              defaultMessage="Agendy"
            />
          }
          source={agendaSource}
          valueIsId={true}
          multiple={true}
        />
      )}
      {showBarcodePrinterField && (
        <FormAutocomplete
          name="barcodePrinter"
          label={
            <FormattedMessage
              id="ES__USER_ROLES__FIELD_LABEL__BARCODE_PRINTER"
              defaultMessage="Tiskárna čárových kódů"
            />
          }
          source={barcodePrinters}
          labelMapper={autocompleteLabelMapper}
        />
      )}
      {mode === DetailMode.NEW && selectedRole === Role.JUDGE && (
        <Typography className={classes.warning}>
          Upozornění: Po přiřazení role je nutné uživatele přiřadit k
          požadovanému soudci v modulu Číselníky → Soudci.
        </Typography>
      )}
      {mode === DetailMode.NEW && selectedRole === Role.SECRETARY && (
        <Typography className={classes.warning}>
          Upozornění: Pro přířazení tajemnice k soudci je nutné upravit
          požadovaného soudce v modulu Číselníky → Soudci.
        </Typography>
      )}
      {mode === DetailMode.NEW && selectedRole === Role.ASSISTANT && (
        <Typography className={classes.warning}>
          Upozornění: Pro přířazení asistenta k soudci je nutné upravit
          požadovaného soudce v modulu Číselníky → Soudci.
        </Typography>
      )}
    </>
  );
}
