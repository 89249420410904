export enum RoleAuthority {
  /**
   * Superadmin
   */
  SUPERADMIN = 'ROLE_SUPERADMIN',

  /**
   * Administrátor
   */
  ADMIN = 'ROLE_ADMIN',

  /**
   * Rejstřík
   */
  INDEX = 'ROLE_INDEX',

  /**
   * Tajemnice
   */
  SECRETARY = 'ROLE_SECRETARY',

  /**
   * Lustrum
   */
  LUSTRUM = 'ROLE_LUSTRUM',

  /**
   * Soudce
   */
  JUDGE = 'ROLE_JUDGE',

  /**
   * Asistent
   */
  ASSISTANT = 'ROLE_ASSISTANT',

  /**
   * Spisovna
   */
  FILE_ROOM = 'ROLE_FILE_ROOM',

  /**
   * Podatelna/výpravna
   */
  MAILROOM = 'ROLE_MAILROOM',

  /**
   * Právník
   */
  LAWYER = 'ROLE_LAWYER',

  /**
   * Vedoucí OSV
   */
  HEAD_OSV = 'ROLE_HEAD_OSV',

  /**
   * Správce žádostí o nahlížení
   */
  VIEWING_REQUEST_MANAGER = 'ROLE_VIEWING_REQUEST_MANAGER',

  /**
   * Analytik
   */
  ANALYST = 'ROLE_ANALYST',

  /**
   * Anonymizace
   */
  ANONYMIZATION = 'ROLE_ANONYMIZATION',

  /**
   * Zveřejnění
   */
  PUBLICATION = 'ROLE_PUBLICATION',

  /**
   * Řešitel
   */
  SOLVER = 'ROLE_SOLVER',
}

export enum Role {
  /**
   * Superadmin
   */
  SUPERADMIN = 'SUPERADMIN',

  /**
   * Administrátor
   */
  ADMIN = 'ADMIN',

  /**
   * Rejstřík
   */
  INDEX = 'INDEX',

  /**
   * Tajemnice
   */
  SECRETARY = 'SECRETARY',

  /**
   * Lustrum
   */
  LUSTRUM = 'LUSTRUM',

  /**
   * Soudce
   */
  JUDGE = 'JUDGE',

  /**
   * Asistent
   */
  ASSISTANT = 'ASSISTANT',

  /**
   * Spisovna
   */
  FILE_ROOM = 'FILE_ROOM',

  /**
   * Podatelna/výpravna
   */
  MAILROOM = 'MAILROOM',

  /**
   * Právník
   */
  LAWYER = 'LAWYER',

  /**
   * Vedoucí OSV
   */
  HEAD_OSV = 'HEAD_OSV',

  /**
   * Správce žádostí o nahlížení
   */
  VIEWING_REQUEST_MANAGER = 'VIEWING_REQUEST_MANAGER',

  /**
   * Analytik
   */
  ANALYST = 'ANALYST',

  /**
   * Anonymizace
   */
  ANONYMIZATION = 'ANONYMIZATION',

  /**
   * Zveřejnění
   */
  PUBLICATION = 'PUBLICATION',

  /**
   * Řešitel
   */
  SOLVER = 'SOLVER',

  /**
   * Čtenář
   */
  READER = 'READER',

  /**
   * Poradce - sbírky
   */
  ADVISER = 'ADVISER',
}
