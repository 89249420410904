import { getErrorMessage } from '@utils/error-message';
import { noop } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  DetailContext,
  DetailHandle,
  EvidenceContext,
  PromptContext,
  SnackbarContext,
  TableField,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  abortableFetch,
  useEventCallback,
  usePrompts,
  useStaticListSource,
} from '@eas/common-web';
import { RedirectForm, useRedirect } from '@composite/redirect/dialog-redirect';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Document, ErrorObject, EsFile } from '@models';
import {
  DocumentType,
  EsFileState,
  EvidenceApiUrl,
  EvidenceBrowserUrl,
} from '@enums';
import { DOCUMENT_SOURCE } from './../../es-file-hooked-sources';
import { getOptions } from './remove-utils';

const useStyles = makeStyles((theme) => ({
  warning: {
    marginTop: 15,
    color: theme.palette.error.main,
  },
}));

const useColumns = () => {
  const intl = useIntl();
  const { documentTypes } = useContext(StaticDataContext);

  const useDocumentTypes = () => useStaticListSource(documentTypes);

  const columns: TableFieldColumn<Document>[] = [
    {
      datakey: 'id',
      name: '',
      width: 45,
      CellComponent: RescreeningRedirectCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__TABLE__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      datakey: 'referenceNumber',
      width: 100,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__TABLE__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Stručný obsah',
      }),
      datakey: 'summary',
      width: 300,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__TABLE__COLUMN__TYPE',
        defaultMessage: 'Typ',
      }),
      datakey: 'type',
      width: 300,
      CellComponent: TableFieldCells.useSelectCellFactory(useDocumentTypes),
    },
  ];

  return columns;
};

function RescreeningRedirectCell(props: TableFieldCellProps<Document>) {
  const url =
    props.rowValue.type === DocumentType.DELIVERED
      ? EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED
      : props.rowValue.type === DocumentType.INTERNAL
      ? EvidenceBrowserUrl.DOCUMENTS_INTERNAL
      : EvidenceBrowserUrl.DOCUMENTS_OUTGOING;

  return (
    <>
      <Redirect url={url} id={props.value} showRedirectInCurrent={false} />
    </>
  );
}

export function useRemoveDialog() {
  const classes = useStyles();
  const match = useRouteMatch();

  const { hookedSources } = useContext(EvidenceContext);
  const { testPrompt } = useContext(PromptContext);
  const { source, del, formRef, onPersisted } =
    useContext<DetailHandle<EsFile>>(DetailContext);
  const { tableRef } = useContext(EvidenceContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const documentSource = hookedSources[DOCUMENT_SOURCE]?.source;

  const documentColumns = useColumns();

  const inAllFiles = [
    EvidenceBrowserUrl.ES_FILES_ALL + '/:id?',
    EvidenceBrowserUrl.ES_FILES_SEARCH + '/:id?',
  ].includes(match.path);

  const inInfFiles =
    match.path === EvidenceBrowserUrl.INF_FILES_IN_PROGRESS + '/:id?';

  const redirectBag = useRedirect<
    EsFile,
    RedirectForm & { state?: EsFileState }
  >({
    options: (values) => getOptions(),
    redirectTo: (values) => {
      switch (values?.state) {
        case EsFileState.IN_PROGRESS:
          return inInfFiles
            ? EvidenceBrowserUrl.INF_FILES_CLOSED
            : EvidenceBrowserUrl.SPR_FILES_CLOSED;

        default:
          return EvidenceBrowserUrl.COURT_FILES_CLOSED;
      }
    },
    initialValues: {
      state: source.data?.state,
    },
  });

  usePrompts(
    [
      {
        key: 'REMOVE_WARNING',
        dialogTitle: (
          <FormattedMessage
            id="ES__REMOVE_WARNING__TITLE"
            defaultMessage="Varování"
          />
        ),
        dialogText: (
          <>
            <FormattedMessage
              id="ES__DETAIL__DIALOG_CONFIRM__TEXT"
              defaultMessage="Opravdu chcete smazat záznam?"
            />
            {!!documentSource?.count && (
              <>
                <Typography variant="body1" className={classes.warning}>
                  <FormattedMessage
                    id="ES__DETAIL__DIALOG_CONFIRM__WARNING"
                    defaultMessage="UPOZORNĚNÍ: U následujících dokumentů bude změnen stav."
                  />
                </Typography>
                <TableField
                  maxRows={10}
                  showToolbar={false}
                  value={documentSource.items}
                  showRadioCond={() => false}
                  showDetailBtnCond={() => false}
                  visibleActionsColumn={false}
                  columns={documentColumns}
                  onChange={noop}
                />
              </>
            )}
          </>
        ),
        dialogWidth: 700,
      },
      {
        key: 'REMOVE_FROM_REGISTER_WARNING',
        dialogTitle: (
          <FormattedMessage
            id="ES__REMOVE_WARNING__TITLE"
            defaultMessage="Varování"
          />
        ),
        dialogText: (
          <>
            <FormattedMessage
              id="ES__DETAIL__DIALOG_CONFIRM__TEXT"
              defaultMessage="Opravdu chcete spis vyvést z rejstříku?"
            />
          </>
        ),
        ...(inAllFiles ? {} : redirectBag),
      },
    ],
    [documentSource]
  );

  const onRemove = useEventCallback(() => {
    testPrompt({
      key: 'REMOVE_WARNING',
      submitCallback: () => del(),
    });
  });

  const removeFromRegisterApi = useEventCallback(() =>
    abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/${source.data?.id}/remove-from-register`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  const onRemoveFromRegister = useEventCallback(() => {
    testPrompt({
      key: 'REMOVE_FROM_REGISTER_WARNING',
      submitCallback: async () => {
        try {
          await removeFromRegisterApi().raw();

          if (!inAllFiles) {
            redirectBag.onResult(source.data!);

            source.reset();
            formRef?.resetValidation();
            tableRef.current?.resetSelection();

            redirectBag.onSuccess();
          } else {
            onPersisted(source.data!.id);
            await source.refresh();
          }
        } catch (e) {
          const err = e as ErrorObject<string>;
          const message = getErrorMessage(err);

          if (err.name !== 'AbortError') {
            showSnackbar(...message);
          }
        }
      },
    });
  });

  const isNew = source.data?.state === EsFileState.NEW;
  const isClosed =
    source.data?.state === EsFileState.CLOSED ||
    source.data?.state === EsFileState.REGISTRY;

  if (isNew || (source?.data?.documents ?? []).length === 0) {
    return {
      onClick: onRemove,
    };
  } else if (!isNew && !isClosed) {
    return {
      onClick: onRemoveFromRegister,
      label: (
        <FormattedMessage
          id="ES__DETAIL__ACTIONBAR__REMOVE_FROM_REGISTER"
          defaultMessage="Vyvést"
        />
      ),
    };
  }
}
