import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ApiFilterOperation,
  EvidenceContext,
  useEventCallback,
} from '@eas/common-web';
import { useCompareOperations, useStringOperations } from './filter-api';
import { FilterContext } from './filter-context';
import { FilterState } from './filter-types';
import { deriveFiltersState } from './filter-utils';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
  },
  listItem: {
    display: 'inline',
  },
  listItemLabel: {
    marginLeft: 15,
    marginRight: 30,
  },
  panel: {
    backgroundColor: 'white',
    boxShadow: 'none',
    /** to disable moving down while expanding */
    margin: '0 0 5px 0!important',
    '&::before': {
      opacity: 0,
    },
  },
  summary: {
    minHeight: '32px !important',
    height: 32,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 0,
  },
  summaryActive: {
    backgroundColor: `${theme.palette.primary.light}aa`,
  },
  summaryContent: {
    alignItems: 'center',
  },
  button: {
    marginRight: 15,
  },
  chip: {
    margin: 5,
  },
}));

export function FilterExtenders({
  setFilters,
}: {
  setFilters: Dispatch<SetStateAction<FilterState[]>>;
}) {
  /**
   * Styles.
   */
  const classes = useStyles();

  /**
   * Context stuff.
   */
  const {
    removeExtender,
    toggleExtender,
    extenders,
    setActiveExtender,
    activeExtender,
    deactivateAllExtenders,
  } = useContext(FilterContext);
  const { tableRef } = useContext(EvidenceContext);

  const stringOperations = useStringOperations();
  const compareOperations = useCompareOperations();

  /**
   *
   */
  const getField = useEventCallback((key: string) => {
    const field = tableRef.current?.columns.find(
      (col) => col.datakey === key || col.filterkey === key
    );

    return field?.name ?? '';
  });

  /**
   *
   */
  const getOperation = useEventCallback((key: ApiFilterOperation) => {
    const operation = [
      ...stringOperations.items,
      ...compareOperations.items,
    ].find((op) => op.id === key);

    return operation?.name ?? '';
  });

  /**
   *
   */
  const editing = activeExtender !== undefined;

  return (
    <div>
      {extenders.length > 0 && (
        <>
          {extenders.map((filterGroup, index) => {
            return (
              <ExpansionPanel key={index} className={classes.panel}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  classes={{
                    root: clsx(classes.summary, {
                      [classes.summaryActive]: activeExtender === index,
                    }),
                    content: classes.summaryContent,
                  }}
                >
                  <Switch
                    checked={filterGroup.visible}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleExtender(index);
                    }}
                    className={classes.button}
                    color="primary"
                    size="small"
                    disabled={!filterGroup.confirmed || editing}
                  />
                  <Typography
                    variant="body1"
                    color={!filterGroup.visible ? 'primary' : 'initial'}
                    className={classes.listItemLabel}
                  >
                    FILTR {index + 1}
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const filters = deriveFiltersState(
                        tableRef.current?.filters ?? [],
                        filterGroup.filters
                      );

                      setFilters(filters);
                      setActiveExtender(index);
                      deactivateAllExtenders(index);
                    }}
                    className={classes.button}
                    color="default"
                    size="small"
                    disabled={!filterGroup.confirmed || editing}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>

                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeExtender(index);
                    }}
                    className={classes.button}
                    color="secondary"
                    size="small"
                    disabled={!filterGroup.confirmed || editing}
                  >
                    <DeleteForeverIcon fontSize="small" />
                  </IconButton>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ul className={classes.list}>
                    {(filterGroup.filters ?? []).map((data, i) => {
                      const field = getField(data.id!);
                      const operation = getOperation(data.operation!);

                      const isDate = new RegExp(
                        /([0-9]{4}-[0-9]{2}-[0-9]{2})/gm
                      ).test(data.value);

                      const date = new Date(data.value);
                      const object = data?.object?.label ?? data?.object?.name;

                      const value =
                        !isNaN(date.getTime()) && isDate
                          ? date.toLocaleDateString()
                          : object ?? data.value;

                      return (
                        <li className={classes.listItem} key={i}>
                          <Chip
                            label={
                              <p>
                                {field} <i>{operation}</i> „{value}“
                              </p>
                            }
                            className={classes.chip}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </>
      )}
    </div>
  );
}
