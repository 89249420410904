import React, { useContext, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldToolbarProps,
  UserContext,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { TextNote } from '@models';
import { Role } from '@enums';
import { useNoteColumns } from './note-columns';
import { NoteToolbar } from './note-toolbar';

export interface NoteFieldProps {
  disabled: boolean;
  source: ScrollableSource<TextNote>;
}

export function NoteField({ disabled, source }: NoteFieldProps) {
  const columns = useNoteColumns();
  const { hasPermission, user } = useContext(UserContext);

  const tableRef = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return <NoteToolbar {...props} ref={editRef} disabled={disabled} />;
      },
    [disabled]
  );

  if (!source) {
    return <> Missing NOTE_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__NOTES__PANEL_TITLE"
          defaultMessage="Poznámky"
        />
      }
      expandable={true}
      defaultExpanded={true}
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        ref={tableRef}
        disabled={disabled}
        columns={columns}
        source={source}
        maxRows={7}
        ToolbarComponent={ToolbarComponent}
        onRowClick={(_, index) => {
          /**
           * Disabled for users who are not ADMIN or INDEX or did not create the note
           */
          const hasPermissionToEdit =
            hasPermission(Role.ADMIN) ||
            hasPermission(Role.INDEX) ||
            source.items?.[index]?.createdBy?.id === user.id;

          if (!disabled && hasPermissionToEdit) {
            tableRef.current?.setSelectedIndex(index);
            editRef.current?.executeAction();
          }
        }}
        highlightSelectedRow={true}
        showDetailBtnCond={() => false}
      />
    </FormPanel>
  );
}
