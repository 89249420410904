import { EsFileState, Role, UIElement } from '@enums';

export const plenaryOpinionAccessMap = new Map([
  [
    EsFileState.PROPOSED,
    new Map([
      [Role.LUSTRUM, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.HEAD_OSV,
        [
          UIElement.PlenaryOpinionFile.NOTES,
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.PlenaryOpinionFile.SOURCE_FILES,
          UIElement.PlenaryOpinionFile.CHALLENGED_FILES,
          UIElement.PlenaryOpinionFile.SETTLEMENT_METHODS,
          UIElement.PlenaryOpinionFile.DECISION_DATE,
          UIElement.PlenaryOpinionFile.NOTES,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.PlenaryOpinionFile.SENATE,
          UIElement.PlenaryOpinionFile.JUDGE,
          UIElement.EsFileCommon.PROCEEDINGS_SUBJECTS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.PlenaryOpinionFile.TASKS,
          UIElement.EsFileCommon.ANONYMIZATION_REQUEST,
          UIElement.EsFileCommon.ANONYMIZE,
          UIElement.PlenaryOpinionFile.CREATE_PLENARY_OPINION_FILE_BUTTON,
          UIElement.PlenaryOpinionFile.UPDATE_PLENARY_OPINION_FILE_BUTTON,
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
          UIElement.PlenaryOpinionFile.STATE,
          UIElement.PlenaryOpinionFile.INDEX_USER_ROLE,
          UIElement.PlenaryOpinionFile.ANALYST_USER_ROLE,
          UIElement.PlenaryOpinionFile.TASKS,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.PlenaryOpinionFile.UPDATE_PLENARY_OPINION_FILE_BUTTON,
          UIElement.PlenaryOpinionFile.SENATE,
          UIElement.PlenaryOpinionFile.JUDGE,
          UIElement.PlenaryOpinionFile.INDEX_USER_ROLE,
          UIElement.PlenaryOpinionFile.ANALYST_USER_ROLE,
        ],
      ],
      [
        Role.LAWYER,
        [
          UIElement.EsFileCommon.PROCEEDINGS_SUBJECTS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.PlenaryOpinionFile.TASKS,
          UIElement.PlenaryOpinionFile.UPDATE_PLENARY_OPINION_FILE_BUTTON,
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.SECRETARY,
        [
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
          UIElement.Document.CREATE_VOTING_PROTOCOL_BUTTON,
        ],
      ],
      [
        Role.JUDGE,
        [
          UIElement.PlenaryOpinionFile.NOTES,
          UIElement.PlenaryOpinionFile.TASKS,
          UIElement.PlenaryOpinionFile.DRAFTS,
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [Role.ASSISTANT, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.ANALYST,
        [
          UIElement.PlenaryOpinionFile.NOTES,
          UIElement.EsFileCommon.PROCEEDINGS_SUBJECTS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.PlenaryOpinionFile.TASKS,
          UIElement.PlenaryOpinionFile.DRAFTS,
          UIElement.EsFileCommon.ANONYMIZE,
          UIElement.EsFileCommon.ANONYMIZATION_REQUEST,
          UIElement.PlenaryOpinionFile.UPDATE_PLENARY_OPINION_FILE_BUTTON,
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [Role.FILE_ROOM, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
    ]),
  ],
  [
    EsFileState.REJECTED,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
          UIElement.PlenaryOpinionFile.REOPEN_PLENARY_OPINION_FILE_BUTTON,
        ],
      ],
      [Role.HEAD_OSV, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.INDEX,
        [
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
          UIElement.PlenaryOpinionFile.REOPEN_PLENARY_OPINION_FILE_BUTTON,
        ],
      ],
      [Role.LAWYER, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.SECRETARY, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.JUDGE, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ASSISTANT, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANALYST, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.FILE_ROOM, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
    ]),
  ],
  [
    EsFileState.ACCEPTED,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
          UIElement.PlenaryOpinionFile.REOPEN_PLENARY_OPINION_FILE_BUTTON,
        ],
      ],
      [Role.HEAD_OSV, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.INDEX,
        [
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
          UIElement.PlenaryOpinionFile.REOPEN_PLENARY_OPINION_FILE_BUTTON,
        ],
      ],
      [Role.LAWYER, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.SECRETARY, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.JUDGE, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ASSISTANT, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANALYST, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.FILE_ROOM, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
    ]),
  ],
  [
    EsFileState.WITHDRAWN,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
          UIElement.PlenaryOpinionFile.REOPEN_PLENARY_OPINION_FILE_BUTTON,
        ],
      ],
      [Role.HEAD_OSV, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.INDEX,
        [
          UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON,
          UIElement.PlenaryOpinionFile.REOPEN_PLENARY_OPINION_FILE_BUTTON,
        ],
      ],
      [Role.LAWYER, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.SECRETARY, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.JUDGE, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ASSISTANT, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANALYST, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
      [Role.FILE_ROOM, [UIElement.PlenaryOpinionFile.CREATE_FILE_COVER_BUTTON]],
    ]),
  ],
]);
