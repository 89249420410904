export const DECISIONS = {
  title: 'Rozhodnutí',
  actions: [
    {
      name: 'Upravení rozhodnutí',
      description: 'Uživatel může upravovat jednotlivé atributy rozhodnutí.',
    },
    {
      name: 'Předat k indexaci',
      description:
        'Akce dostupná pro rozhodnutí ve stavu <K>Rozpracováno, K anonymizaci, K zpřístupnění</b>. Změna stavu na stav <b>K indexaci</b>.',
    },
    {
      name: 'Předat k anonymizaci',
      description:
        'Akce dostupná pro rozhodnutí ve stavu <b>K indexaci, Rozpracováno, K zpřístupnění</b>. Změna stavu na <b>K anonymizaci</b>.',
    },
    {
      name: 'Předat k zpřístupnění',
      description:
        'Akce dostupná pro rozhodnutí ve stavu <b>K indexaci, K anonymizaci</b>. Změna stavu na <b>K zpřístupnění</b>.',
    },
    {
      name: 'Zpět - rozpracováno',
      description:
        'Akce dostupná pro rozhodnutí ve stavu  <b>K indexaci</b>. Změna stavu na <b>Rozpracováno</b>.',
    },
    {
      name: 'Zpřístupnit rozhodnutí',
      description:
        'Akce dostupná pro rozhodnutí ve stavu <b>K zpřístupnění</b>, které mají povoleno zveřejnění. Změna stavu na <b>Zpřístupněno</b>. ',
    },
    {
      name: 'Zrušit zpřístupnění',
      description:
        'Akce dostupná pro rozhodnutí ve stavu <b>Zpřístupněno</b>. Změna stavu na <b>K anonymizaci</b>.',
    },
    {
      name: 'Přegenerovat přílohy',
      description: 'Manuální přegenerování příloh.',
    },
  ],
};
