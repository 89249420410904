enum Element {
  /**
   * Spisová značka
   */
  FILE_NUMBER = 'FILE_NUMBER',

  /**
   * Čárový kód
   */
  BAR_CODE = 'BAR_CODE',

  /**
   * Evidenční číslo spisové služby
   */
  EVIDENCE_NUMBER = 'EVIDENCE_NUMBER',

  /**
   * Datum vytvoření
   */
  CREATED = 'CREATED',

  /**
   * Datum doručení
   */
  DELIVERY_DATE = 'DELIVERY_DATE',

  /**
   * Datum pro statistiku
   */
  STATS_DATE = 'STATS_DATE',

  /**
   * Došlé číslo
   */
  INCOMING_NUMBER = 'INCOMING_NUMBER',

  /**
   * Agenda
   */
  AGENDA = 'AGENDA',

  /**
   * Typ soudního spisu
   */
  TYPE = 'TYPE',

  /**
   * Stav
   */
  STATE = 'STATE',

  /**
   * Rejstřík
   */
  INDEX = 'INDEX',

  /**
   * Tajemnice
   */
  SECRETARY = 'SECRETARY',

  /**
   * Asistent
   */
  ASSISTANT = 'ASSISTANT',

  /**
   * Analytik - indexace
   */
  ANALYST = 'ANALYST',

  /**
   * Typ řízení
   */
  PROCESSING_TYPE = 'PROCESSING_TYPE',

  /**
   * Způsob vyřízení
   */
  SETTLEMENT_METHODS = 'SETTLEMENT_METHODS',

  /**
   * Forma rozhodnutí
   */
  DECISSION_FORM = 'DECISSION_FORM',

  /**
   * Datum projednání
   */
  DISCUSSION_DATE = 'DISCUSSION_DATE',

  /**
   * Datum vyhlášení
   */
  PUBLICATION_DATE = 'PUBLICATION_DATE',

  /**
   * Vykonatelnost
   */
  FEASIBILITY = 'FEASIBILITY',

  /**
   * Datum uzavření
   */
  CLOSING_DATE = 'CLOSING_DATE',

  /**
   * Délka řízení ve dnech
   */
  PROCEEDING_DURATION = 'PROCEEDING_DURATION',

  /**
   * Stanoviska pléna
   */
  CONTESTED_OPINIONS = 'CONTESTED_OPINIONS',

  /**
   * Běžící lhůty
   */
  RUNNING_DEADLINES = 'RUNNING_DEADLINES',

  /**
   * Poznámky
   */
  NOTES = 'NOTES',

  /**
   * Navrhovatelé
   */
  PROPOSERS = 'PROPOSERS',

  /**
   * Vedlejší účastníci
   */
  ACCESSORY_PARTICIPANTS = 'ACCESSORY_PARTICIPANTS',

  /**
   * Právní zástupci
   */
  LEGAL_ADVISERS = 'LEGAL_ADVISERS',

  /**
   * Členové rozhodujícího orgánu
   */
  DECIDING_AUTHORITY_MEMBERS = 'DECIDING_AUTHORITY_MEMBERS',

  /**
   * Napadené akty
   */
  CHALLENGED_ACTS = 'CHALLENGED_ACTS',

  /**
   * Soudci a zpracovatelé
   */
  JUDGE = 'JUDGE',

  /**
   * Nahlížení a zápůjčky
   */
  VIEWINGS = 'VIEWINGS',

  /**
   * Úlohy a lhůty
   */
  TASKS_AND_DEADLINES = 'TASKS_AND_DEADLINES',

  /**
   * Koncepty
   */
  DRAFTS = 'DRAFTS',

  /**
   * AKCE: Nový spis
   */
  CREATE_COURT_FILE_BUTTON = 'CREATE_COURT_FILE_BUTTON',

  /**
   * AKCE: Upravit spis
   */
  UPDATE_COURT_FILE_BUTTON = 'UPDATE_COURT_FILE_BUTTON',

  /**
   * AKCE: Smazat spis
   */
  DELETE_COURT_FILE_BUTTON = 'DELETE_COURT_FILE_BUTTON',

  /**
   * AKCE: Přidělit spisovou značku
   */
  CREATE_FILE_NUMBER_BUTTON = 'CREATE_FILE_NUMBER_BUTTON',

  /**
   * AKCE: Změnit agendu
   */
  UPDATE_COURT_FILE_AGENDA_BUTTON = 'UPDATE_COURT_FILE_AGENDA_BUTTON',

  /**
   * AKCE: Obal spisu
   */
  CREATE_FILE_COVER_BUTTON = 'CREATE_FILE_COVER_BUTTON',

  /**
   * AKCE: Kancelář soudce
   */
  UPDATE_TO_JUDGE_OFFICE_BUTTON = 'UPDATE_TO_JUDGE_OFFICE_BUTTON',

  /**
   * AKCE: Rejstřík - nový spis
   */
  UPDATE_TO_REGISTER_NEW_BUTTON = 'UPDATE_TO_REGISTER_NEW_BUTTON',

  /**
   * AKCE: Rejstřík
   */
  UPDATE_TO_REGISTER_BUTTON = 'UPDATE_TO_REGISTER_BUTTON',

  /**
   * AKCE: K indexaci
   */
  UPDATE_TO_INDEX_BUTTON = 'UPDATE_TO_INDEX_BUTTON',

  /**
   * AKCE: Uzavřený
   */
  UPDATE_TO_CLOSED_BUTTON = 'UPDATE_TO_CLOSED_BUTTON',

  /**
   * AKCE: Spisovna
   */
  UPDATE_TO_REGISTRY_BUTTON = 'UPDATE_TO_REGISTRY_BUTTON',

  /**
   * AKCE: Spojení spisů
   */
  MERGE_FILES_BUTTON = 'MERGE_FILES_BUTTON',

  /**
   * AKCE: Znovu otevřít
   */
  REOPEN_COURT_FILE_BUTTON = 'REOPEN_COURT_FILE_BUTTON',
}

export default Element;
