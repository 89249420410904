import React, { ReactNode } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    field: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10,
    },
    label: {
      marginBottom: 2,
      fontWeight: 600,
    },
  })
);

export function TextProfileField({
  value,
  label,
}: {
  value?: string;
  label: ReactNode;
}) {
  const classes = useStyles();

  return (
    <div className={classes.field}>
      <Typography variant="h6" className={classes.label}>
        {label}
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={value}
        disabled={true}
      />
    </div>
  );
}
