import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DictionaryEvidence, FormTextArea } from '@eas/common-web';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';

export function Fields() {
  const historyDescriptors = useCommonHistoryDescriptors();

  return (
    <>
      <FormTextArea
        name="name"
        required
        label={
          <FormattedMessage
            id="ES__DICT_ENVELOPE_CONTENTS__DETAIL__FIELD_LABEL__DESCRIPTION"
            defaultMessage="Název"
          />
        }
      />
      <DictionaryEvidence.FieldActive />
      <HistoryField descriptors={historyDescriptors} />
    </>
  );
}
