const NEW_INF_FILES = {
  title: 'Nové spisy',
  actions: [
    {
      name: 'Vytvoření INF spisu',
      description:
        'INF spis lze vytvořit dvěma způsoby: <i>1. v pohledu Dokumenty - Nezpracované → akce Nové INF; 2. v pohledu Spisy - INF spisy - Nové INF → akce Nové INF</i>',
    },
    {
      name: 'Odstranění spisu',
      description: 'Odstranit je možné pouze ve stavu <b>Nový</b>. ',
    },
    {
      name: 'Změnit stav',
      description:
        'Po změně stavu na <b>V řešení</b> se přidělí spisová značka.',
    },
    { name: 'Změnit agendu', description: 'Změna agendy.' },
    {
      name: 'Upravení spisu',
      description: 'Uživatel má možnost upravovat jednotlivé atributy spisu.',
    },
  ],
};

const IN_PROGRESS_INF_FILES = {
  title: 'V řešení',
  actions: [
    {
      name: 'Vyvést',
      description:
        'Při vyvedení z rejstříku je změněn <b>Způsob vyřízení</b> na <i>Vyvedeno z rejstříku</i>, jsou stornovány všechny aktivní úlohy a běžící lhůty spisu, spis je uzavřen. Akce je dostupná u spisů, které nejsou uzavřené a mají přidělenu spisovou značku.',
    },
    { name: 'Změnit stav', description: 'Změnit stav na Uzavřeno.' },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
    {
      name: 'Upravení spisu',
      description: 'Uživatel může upravovat jednotlivé atributy spisu.',
    },
  ],
};

const CLOSED_INF_FILES = {
  title: 'Uzavřené',
  actions: [
    { name: 'Znovu otevřít', description: 'Otevření spisu.' },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
  ],
};

export const INF_FILES = {
  title: 'Spisy INF',
  subsections: [NEW_INF_FILES, IN_PROGRESS_INF_FILES, CLOSED_INF_FILES],
};
