import { noop } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DictionaryEvidence,
  FormPanel,
  FormTextArea,
  TreeBuilder,
} from '@eas/common-web';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';
import { useProceedingsSubjectsTree } from './dict-proceedings-subject-api';

export function Fields() {
  const historyDescriptors = useCommonHistoryDescriptors();

  const { source } = useContext(DetailContext);

  const subTree = useProceedingsSubjectsTree({
    rootId: source.data?.id,
  });

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DICT_PROCEEDINGS_SUBJECT__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormTextArea
          name="name"
          required
          label={
            <FormattedMessage
              id="ES__DICT_PROCEEDINGS_SUBJECT__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Název"
            />
          }
        />
        <DictionaryEvidence.FieldActive />
      </FormPanel>
      <TreeBuilder source={subTree} value={[]} onChange={noop} />
      <HistoryField
        descriptors={[
          ...historyDescriptors,
          { key: 'parent', label: 'Nadřazený prvek' },
          { key: 'children', label: 'Podřazené prvky' },
          { key: 'childrenCount', label: 'Počet potomků' },
          { key: 'level', label: 'Úroveň zanoření' },
          { key: 'hierarchicalOrder', label: 'Hierachické pořadí' },
        ]}
      />
    </>
  );
}
