import { useEffect, useRef } from 'react';
import { PanelHandle } from '@eas/common-web';

export function useAutoCollapse<T>(items: T[], defaultExpanded?: boolean) {
  const panelRef = useRef<PanelHandle>(null);

  useEffect(() => {
    if (defaultExpanded === undefined) {
      if (items.length > 0) {
        panelRef.current?.setExpanded(true);
      } else {
        panelRef.current?.setExpanded(false);
      }
    }
  }, [items.length, defaultExpanded]);

  return { panelRef };
}
