import { format, isBefore, parseISO } from 'date-fns';
import * as Yup from 'yup';
import { AssigningRule, Judge } from '@models';

export function useValidationSchema() {
  return Yup.object<AssigningRule>().shape({
    validFrom: Yup.string()
      .nullable()
      .required()
      .test('', '', function (validFrom) {
        const { createError, path, options } = this;
        const currentDate = format(new Date(), 'yyyy-MM-dd');

        if (
          (options?.context as any)?.values?.newMode &&
          validFrom &&
          isBefore(parseISO(validFrom), parseISO(currentDate))
        ) {
          return createError({
            path,
            message: 'Datum nesmí být v minulosti',
          });
        }

        return true;
      }),
    validTo: Yup.string()
      .nullable()
      .test('', '', function (validTo) {
        const { createError, path, parent } = this;
        if (
          parent.validFrom &&
          validTo &&
          isBefore(parseISO(validTo), parseISO(parent.validFrom))
        ) {
          return createError({
            path,
            message: 'Datum konce platnosti musí být po datu začátku platnosti',
          });
        }

        return true;
      }),
    judge: Yup.mixed<Judge>().nullable().required(),
  });
}
