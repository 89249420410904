import { get } from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ActionButtonHandle,
  DetailContext,
  DetailHandle,
  DetailMode,
  FormContext,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
  useCrudSource,
  useEventCallback,
  useFormSelector,
  useScrollableSource,
} from '@eas/common-web';
import { HistoryField } from '@composite/history/history-field';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { Contact, RawSimpleLog, Subject } from '@models';
import { EvidenceApiUrl, HistoryEntityType } from '@enums';
import { useAddSubjectDialog } from './dialog-actions/add-subject-hook';
import { useEditSubjectDialog } from './dialog-actions/edit-subject-hook';
import { Fields } from './subject-fields';
import { SubjectHistoryContext } from './subject-history-context';
import { useSubjectToolbarValidationSchema } from './subject-schema';

export function SubjectToolbar({
  createRef,
  editRef,
  fieldName = 'subject',
  onResultValue,
}: {
  createRef: React.RefObject<ActionButtonHandle>;
  editRef: React.RefObject<ActionButtonHandle>;
  fieldName?: string;
  onResultValue?: (result: Subject) => void;
}) {
  const intl = useIntl();
  const selectedContactRef = useRef<Contact | null>(null);

  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useContext(FormContext);

  const { subject } = useFormSelector((values: Subject) => ({
    subject: get(values, fieldName),
  }));

  const validationSchema = useSubjectToolbarValidationSchema();

  const { callApi: addSubjectApiCall } = useAddSubjectDialog();
  const { callApi: editSubjectApiCall } = useEditSubjectDialog();

  const source = useCrudSource<Subject>({
    url: EvidenceApiUrl.SUBJECTS,
  });

  useEffect(() => {
    source.reset(subject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject?.id]);

  const handleProcessResult = useEventCallback(async (value) => {
    const subjectWithSelectedContact = {
      ...value,
      selectedContact: value.contacts?.find(
        (contact: Contact) => contact.id === selectedContactRef?.current?.id
      ),
    };

    onResultValue
      ? onResultValue(subjectWithSelectedContact)
      : setFieldValue(fieldName, subjectWithSelectedContact);

    const message = intl.formatMessage({
      id: 'EAS_ACTION_BUTTON_MSG_SUCCESS',
      defaultMessage: 'Akce byla úspěšně vykonána.',
    });

    showSnackbar(message, SnackbarVariant.SUCCESS);
  });

  const handleSelectRow = useEventCallback(
    (row) => (selectedContactRef.current = row)
  );

  return (
    <>
      <DetailActionButton
        ref={createRef}
        promptKey="ADD_SUBJECT"
        apiCall={addSubjectApiCall}
        buttonLabel={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__ADD_SUBJECT',
          defaultMessage: 'Přidat adresáta',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__ADD_SUBJECT__TITLE',
          defaultMessage: 'Adresát',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__ADD_SUBJECT__TEXT',
          defaultMessage: 'Vyplňte informace o adresátovi.',
        })}
        FormFields={() => (
          <DetailContext.Provider
            value={
              {
                source,
                mode: DetailMode.NEW,
                isExisting: !!source?.data?.id,
              } as DetailHandle<Subject>
            }
          >
            <Fields
              display="OUT_OF_SUBJECT"
              contactsProps={{
                onRowSelect: handleSelectRow,
              }}
              panelLabel={
                <FormattedMessage
                  id="ES__SUBJECTS__DETAIL__PANEL_TITLE__RECIPIENT"
                  defaultMessage="Adresát"
                />
              }
            />
          </DetailContext.Provider>
        )}
        modes={[]}
        refreshAfterConfirm={false}
        dialogWidth={1000}
        formValidationSchema={validationSchema}
        onResult={handleProcessResult}
      />
      <DetailActionButton
        ref={editRef}
        onBeforeDialog={async () => {
          const fetch = abortableFetch(
            `${EvidenceApiUrl.SUBJECTS}/${subject.id}`,
            {
              method: 'GET',
              headers: new Headers({ 'Content-Type': 'application/json' }),
            }
          );

          const subjectDetail = await fetch.json();

          setFieldValue(fieldName, subjectDetail);
          source.reset(subjectDetail);

          return true;
        }}
        promptKey="EDIT_SUBJECT"
        apiCall={(id, values) => editSubjectApiCall(subject.id, values)}
        buttonLabel={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__EDIT_SUBJECT',
          defaultMessage: 'Editovat adresáta',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__EDIT_SUBJECT__TITLE',
          defaultMessage: 'Adresát',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__EDIT_SUBJECT__TEXT',
          defaultMessage: 'Upravte informace o adresátovi.',
        })}
        FormFields={() => (
          <DetailContext.Provider
            value={
              {
                source,
                mode: DetailMode.EDIT,
                isExisting: !!source?.data?.id,
              } as DetailHandle<Subject>
            }
          >
            <FieldsWithHistory handleSelectRow={handleSelectRow} />
          </DetailContext.Provider>
        )}
        modes={[]}
        dialogWidth={1000}
        refreshAfterConfirm={false}
        formInitialValues={subject}
        formValidationSchema={validationSchema}
        onResult={handleProcessResult}
      />
    </>
  );
}

const FieldsWithHistory = ({
  handleSelectRow,
}: {
  handleSelectRow: (row?: Contact | null) => void;
}) => {
  const { source } = useContext(DetailContext);

  const historySource = useScrollableSource<RawSimpleLog>({
    url: `${EvidenceApiUrl.HISTORY}?id=${source.data.id}&entityType=${HistoryEntityType.SUBJECT}`,
    params: {
      size: 10,
      sort: [
        {
          type: 'FIELD',
          field: 'created',
          order: 'DESC',
        },
      ],
    },
  });

  useEffect(() => {
    historySource.reset();
    historySource.loadMore();
  }, []);

  return (
    <SubjectHistoryContext.Provider value={{ historySource }}>
      <Fields
        display="OUT_OF_SUBJECT"
        contactsProps={{
          onRowSelect: handleSelectRow,
        }}
        HistoryFields={() => (
          <HistoryField descriptors={[]} source={historySource} />
        )}
        panelLabel={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__PANEL_TITLE__RECIPIENT"
            defaultMessage="Adresát"
          />
        }
      />
    </SubjectHistoryContext.Provider>
  );
};
