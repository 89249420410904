import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { EsFileState } from '@enums';
import { useCourtFiles } from '../court-files';
import { useColumns } from './court-file-register-columns';

export function CourtFilesRegister() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useCourtFiles({
    evidenceProps: {
      version: 9,
      tableProps: {
        columns,
        defaultPreFilters: [
          {
            field: 'state.id',
            operation: ApiFilterOperation.EQ,
            value: EsFileState.REGISTER,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__COURT_FILES_REGISTER__TABLE__TITLE',
          defaultMessage: 'Soudní spisy - Rejstřík',
        }),
      },
      detailProps: {
        toolbarProps: {
          showBtn: (btn) => !['NEW'].includes(btn),
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
