import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  FormPanel,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Agenda } from '@enums';
import { Fields as CourtFields } from '../es-file-court/court-file-fields';
import { Fields as DisciplinaryProceedingsFields } from '../es-file-disciplinary-proceedings/disciplinary-proceedings-file-fields';
import { Fields as InfFields } from '../es-file-inf/inf-file-fields';
import { Fields as PlenaryOpinionFields } from '../es-file-plenary-opinion/plenary-opinion-file-fields';
import { Fields as SprFields } from '../es-file-spr/spr-file-fields';

function General() {
  const { agendas } = useContext(StaticDataContext);

  const agendaSource = useStaticListSource(agendas);

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__ES_FILES__DETAIL__GENERAL_PANEL_TITLE"
          defaultMessage="Obecné"
        />
      }
    >
      <FormSelect
        name="agenda"
        label={
          <FormattedMessage
            id="ES__ES_FILES__DETAIL__FIELD_LABEL__AGENDA"
            defaultMessage="Agenda"
          />
        }
        required
        source={agendaSource}
        disabled={true}
        valueIsId={true}
      />
    </FormPanel>
  );
}

export function Fields() {
  const { source } = useContext(DetailContext);

  switch (source?.data?.agenda) {
    case Agenda.COURT:
      return <CourtFields />;
    case Agenda.SPR:
      return <SprFields />;
    case Agenda.INF:
      return <InfFields />;
    case Agenda.PLENARY_OPINION:
      return <PlenaryOpinionFields />;
    case Agenda.DISCIPLINARY_PROCEEDINGS:
      return <DisciplinaryProceedingsFields />;
    default:
      return <General />;
  }
}
