import { noop } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormCheckbox,
  FormCustomField,
  FormLocalDateTimeField,
  FormPanel,
  FormTextArea,
  FormTextField,
  TextField,
  useFormSelector,
} from '@eas/common-web';
import { DeliveredDocument } from '@models';

export function SenderFields() {
  const { dataBoxMessageEnvelope } = useFormSelector(
    (data: DeliveredDocument) => ({
      dataBoxMessageEnvelope: data.delivery?.dataBoxMessageEnvelope,
    })
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DELIVERED__DETAIL__SENDER_PANEL_TITLE"
            defaultMessage="Odesílatel"
          />
        }
      >
        <FormTextField
          name="delivery.subject"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__SUBJECT"
              defaultMessage="Věc"
            />
          }
          disabled={true}
        />
        <FormTextArea
          name="delivery.sender.label"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__SENDER"
              defaultMessage="Odesílatel"
            />
          }
          disabled={true}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DELIVERED__DETAIL__ENVELOPE_PANEL_TITLE"
            defaultMessage="Obálka datové zprávy"
          />
        }
      >
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.dataBoxMessageId"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__MESSAGE_ID"
              defaultMessage="ID datové zprávy"
            />
          }
          disabled={true}
        />
        <FormLocalDateTimeField
          name="delivery.dataBoxMessageEnvelope.deliveryDate"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__DELIVERY_DATE"
              defaultMessage="Datum doručení"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.subject"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__SUBJECT"
              defaultMessage="Předmět"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.sender"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__SENDER"
              defaultMessage="Odesílatel"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.senderDataBoxId"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__SENDER_ID"
              defaultMessage="ID datové schránky odesílatele"
            />
          }
          disabled={true}
        />
        <FormCustomField
          name="delivery.dataBoxMessageEnvelope.empowerment"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__EMPOWERMENT"
              defaultMessage="Zmocnění - zákon, paragraf, odstavec"
            />
          }
        >
          {dataBoxMessageEnvelope && (
            <TextField
              value={`${dataBoxMessageEnvelope?.empowermentNumber} / ${dataBoxMessageEnvelope?.empowermentYear} ${dataBoxMessageEnvelope?.empowermentSectionSign} ${dataBoxMessageEnvelope?.empowermentParagraph}`}
              onChange={noop}
              disabled={true}
            />
          )}
        </FormCustomField>
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.empowermentLetter"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__EMPOWERMENT_LETTER"
              defaultMessage="Zmocnění - písmeno"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.ourReferenceNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__OUR_REFERENCE_NUMBER"
              defaultMessage="Naše číslo jednací"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.ourFileNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__OUR_FILE_NUMBER"
              defaultMessage="Naše spisová značka"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.yoursReferenceNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__YOURS_REFERENCE_NUMBER"
              defaultMessage="Vaše číslo jednací"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.yoursFileNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__YOURS_FILE_NUMBER"
              defaultMessage="Vaše spisová značka"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="delivery.dataBoxMessageEnvelope.toHands"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__TO_HANDS"
              defaultMessage="K rukám"
            />
          }
          disabled={true}
        />
        <FormCheckbox
          name="delivery.dataBoxMessageEnvelope.toOwnHands"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__TO_OWN_HANDS"
              defaultMessage="Do vlastních rukou"
            />
          }
          disabled={true}
        />
        <FormCheckbox
          name="delivery.dataBoxMessageEnvelope.fictionDeliveryProhibited"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__FICTION_DELIVERY_PROHIBITED"
              defaultMessage="Zakázáno doručení fikcí"
            />
          }
          disabled={true}
        />
      </FormPanel>
    </>
  );
}
