import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AutocompleteSource,
  DomainObject,
  FilterComponentProps,
  TableFilterCells,
} from '@eas/common-web';
import { useStyles } from '../filter-styles';

export function FilterAutocompleteCell<TYPE extends DomainObject>(
  dataHook: () => AutocompleteSource<TYPE>,
  labelMapper?: (option: TYPE) => string,
  multiple = false
) {
  const classes = useStyles();

  return useMemo(
    () =>
      function Cell(props: FilterComponentProps) {
        const FilterAutocomplete = TableFilterCells.useFilterAutocompleteCellFactory(
          dataHook,
          labelMapper
        );

        const MultipleFilterAutocomplete = TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          dataHook,
          labelMapper
        );

        return (
          <div
            className={clsx(classes.textWithoutSelectWrapper, {
              [classes.disabled]: props.disabled,
            })}
          >
            {!multiple && <FilterAutocomplete {...props} />}
            {multiple && <MultipleFilterAutocomplete {...props} />}
          </div>
        );
      },
    [dataHook]
  );
}
