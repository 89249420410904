import React from 'react';
import { useIntl } from 'react-intl';
import { grey } from '@material-ui/core/colors';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { User } from '@models';
import { EntityName, EvidenceApiUrl, ExportTags } from '@enums';
import { ActionBar } from './user-actionbar';
import { useColumns } from './user-columns';
import { Fields } from './user-fields';
import { useUserHookedSources } from './user-hooked-sources';
import { useValidationSchema } from './user-schema';
import { TableToolbar } from './user-toolbar';

export function Users() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useAuthoredEvidence<User>({
    version: 3,
    identifier: 'USERS',
    apiProps: {
      url: EvidenceApiUrl.USERS,
      hookedSources: useUserHookedSources,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__USERS__TABLE__TITLE',
        defaultMessage: 'Uživatelé',
      }),
      reportTag: ExportTags.USERS,
      createRowStyle: (user) =>
        !user.active
          ? {
              color: grey[400],
            }
          : {},
      defaultSorts: [],
      toolbarProps: {
        after: <TableToolbar />,
      },
    },
    detailProps: {
      entityName: EntityName.USER,
      validationSchema,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__USERS__DETAIL__TITLE',
          defaultMessage: 'Uživatel',
        }),
        ActionBar: DictionaryEvidence.dictionaryActionbarFactory({
          After: ActionBar,
        }),
        showBtn: (name) => {
          if (['REMOVE', 'NEW'].includes(name)) {
            return false;
          }
          return true;
        },
        subtitle: (value) => value?.username ?? '',
      },
    },
  });

  return <Evidence {...evidence} />;
}
