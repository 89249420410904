import { merge } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Detail,
  DetailHandle,
  DetailToolbarButton2,
  DetailToolbarButtonType,
  DomainObject,
  EvidenceApiProps,
  EvidenceContext,
  EvidenceDetailProps,
  EvidenceTableProps,
  NavigationContext,
  SplitScreen,
  SplitScreenHandle,
  Table,
  TableContext,
  TableHandle,
  useCrudSource,
  useEventCallback,
  useHookedSources,
  useScrollableSource,
} from '@eas/common-web';
import { Filter } from '@composite/filter/filter';
import { FilterOutButton } from '@composite/filter/filter-out-button';
import { FilterProvider } from '@composite/filter/filter-provider';
import { FilterState } from '@composite/filter/filter-types';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    height: 'calc(100vh - 67px)',
  },
  wrapperLeft: {
    height: '100%',
    backgroundColor: 'white',
    overflowY: 'auto',
    minWidth: 400,
  },
  wrapperRight: {
    height: '100%',
    minWidth: 400,
    overflowY: 'auto',
  },
  toolbarWrapper: {
    backgroundColor: 'red',
  },
  searchBarWrapper: {
    backgroundColor: 'red',
  },
});

interface SearchProps<OBJECT extends DomainObject> {
  apiProps: EvidenceApiProps<OBJECT>;
  tableProps: EvidenceTableProps<OBJECT> & { settingsKey: string };
  detailProps: EvidenceDetailProps<OBJECT>;
  filterProps: {
    defaults: FilterState[];
    initVisibility?: string[];
    groupLabels: Record<string | number, string>;
    title?: React.ReactNode;
  };
}

export function Search<OBJECT extends DomainObject>({
  apiProps,
  tableProps,
  detailProps,
  filterProps,
}: SearchProps<OBJECT>) {
  const classes = useStyles();
  const route = useRouteMatch();
  const intl = useIntl();

  const { navigate } = useContext(NavigationContext);

  const [view, setView] = useState<'search' | 'detail'>('search');

  const defaultTitle = useRef(document.title);

  const { settingsKey, columns, ...restTableProps } = tableProps;
  const tableRef = useRef<TableHandle<OBJECT>>(null);
  const tableSource = useScrollableSource<OBJECT>({
    url: `${apiProps.url}/list`,
  });

  const { hookedSources, refreshAllHookedSources, resetAllHookedSources } =
    useHookedSources(apiProps.hookedSources);

  const detailRef = useRef<DetailHandle<OBJECT>>(null);
  const splitScreenRef = useRef<SplitScreenHandle>(null);
  const crudSource = useCrudSource<OBJECT>({
    url: apiProps.url,
    getItem: apiProps.getItem,
    createItem: apiProps.createItem,
    updateItem: apiProps.updateItem,
    deleteItem: apiProps.deleteItem,
    refreshAllHookedSources,
    resetAllHookedSources,
  });

  const handleRowClick = useEventCallback((id) => {
    if (view === 'search') {
      tableRef.current?.toggleRowSelection(id!);
    }
  });

  const handleActiveRowChange = useEventCallback((id: string | null) => {
    detailRef.current?.setActive(id);
    detailRef.current?.formRef?.resetValidation();
  });

  const DetailBtn = () => (
    <DetailToolbarButton2
      type={DetailToolbarButtonType.PRIMARY}
      onClick={() => setView('detail')}
      startIcon={<VisibilityIcon />}
      label={
        <FormattedMessage
          id="ES__SEARCH__TOOLBAR__BTN_DETAIL"
          defaultMessage="Procházet"
        />
      }
    />
  );

  const SearchBtn = () => (
    <DetailToolbarButton2
      type={DetailToolbarButtonType.PRIMARY}
      onClick={() => {
        handleActiveRowChange(null);
        tableRef.current?.setActiveRow(null);
        navigate(route.path.replace(':id', ''), false);
        setView('search');
      }}
      startIcon={<SearchIcon />}
      label={
        <FormattedMessage
          id="ES__SEARCH__TOOLBAR__BTN_SEARCH"
          defaultMessage="Hledat"
        />
      }
    />
  );

  const handleDetailPersisted = useEventCallback((id: string | null) => {
    tableRef.current?.refresh();
    tableRef.current?.setActiveRow(id);
  });

  return (
    <EvidenceContext.Provider
      value={
        {
          apiUrl: apiProps.url,
          tableRef,
          detailRef,
          splitScreenRef,
          tableSource,
          crudSource,
          hookedSources,
        } as EvidenceContext<OBJECT>
      }
    >
      <FilterProvider settingsKey={settingsKey}>
        <TableContext.Provider value={tableRef.current as TableHandle<OBJECT>}>
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <AutoSizer>
                {({ height, width }) => (
                  <div style={{ height, width, display: 'flex' }}>
                    <SplitScreen
                      ref={splitScreenRef}
                      rightMinWidth={955}
                      defaultTitle={defaultTitle.current}
                      rightTitle={
                        tableProps.tableName ??
                        intl.formatMessage({
                          id: 'ES__SEARCH__TITLE',
                          defaultMessage: 'Hledání',
                        })
                      }
                    >
                      <div className={classes.wrapperLeft}>
                        <Table
                          version={16}
                          ref={tableRef}
                          tableId="ES__ES_FILE_SEARCH__TABLE"
                          source={tableSource}
                          height={height}
                          columns={columns ?? []}
                          showReportButton={false}
                          tableName="Spisy - hledání"
                          showColumnButton={false}
                          showFilterButton={false}
                          showBulkActionButton={false}
                          showSearchbar={false}
                          disabledActiveRow={view === 'search'}
                          defaultSorts={[]}
                          onRowClick={handleRowClick}
                          onActiveChange={handleActiveRowChange}
                          toolbarProps={{
                            after: <FilterOutButton />,
                          }}
                          {...restTableProps}
                        />
                      </div>
                      <div className={classes.wrapperRight}>
                        {view === 'search' && (
                          <Filter {...filterProps} Before={DetailBtn} />
                        )}
                        {view === 'detail' && (
                          <Detail
                            ref={detailRef}
                            {...detailProps}
                            source={crudSource}
                            toolbarProps={merge(detailProps.toolbarProps, {
                              Before: SearchBtn,
                            })}
                            onPersisted={handleDetailPersisted}
                          />
                        )}
                      </div>
                    </SplitScreen>
                  </div>
                )}
              </AutoSizer>
            </div>
          </div>
        </TableContext.Provider>
      </FilterProvider>
    </EvidenceContext.Provider>
  );
}
