export const SCREENING = {
  title: 'Lustrum',
  description:
    'Modul lustrace slouží k nalezení souvisejících spisů (kauz) k doručenému dokumentu. Je možné provést lustraci pro konkrétní dokument, ale také bez dokumentu.',
  actions: [
    {
      name: 'Filtrování výsledků',
      description:
        'Spisy je možné filtrovat na základě vyhledávacích polí, která jsou seskupena do 3 sloupců - <i>Atributy navrhovatele, Atributy spisu, Ostatní</i>.',
    },
    {
      name: 'Přidání do výsledku',
      description:
        'Po vyfiltrování výsledků lze nalezené záznamy přidat do výsledků lustrace. Je možné provést filtraci a přidání do výsledků několikrát.',
    },
    {
      name: 'Odebrání z výsledku',
      description:
        'Z výsledných nalezených záznamů lze odstranit jednotlivé záznamy. Takto odstraněné záznamy nebudou zahrnuty do výsledku lustra.',
    },
    {
      name: 'Zobrazení přehledu spisů',
      description:
        'Hlavní stránka modulu lustrace je přehled všech spisů. Ve spisech lze filtrovat pomocí zobrazených filtrů.',
    },
    {
      name: 'Vygenerování výsledného dokumentu lustrace',
      description:
        'Dokument vznikne provedením lustrace a obsahuje hlavičku, tabulku s výslednými spisy a patičku.',
    },
    {
      name: 'Opakovaný lustr',
      description:
        'Opakovaná lustrace představuje operaci, při které se vloží výsledný dokument do všech spisů z výsledků lustrace. Ke spisům je přiložen interní přijatý dokument s přílohou – výsledný vygenerovaný dokument. Opakovaná lustrace lze provést bez nutnosti zvolení doručeného dokumentu (jako v <b>Lustraci pro dokument</b>).',
    },
    {
      name: 'Lustrace pro dokument',
      description:
        'Provedení lustrace pro 1 konkrétní doručený dokument. V detailu dokumentu (typ <b>Nezpracovaný</b>) uživatel klikne na tlačítko <b>Lustrovat</b> a systém zobrazí modul lustraci, kde je zvolen vybraný dokument. Po provedení lustrace uživatel vkládá datum lustrace a systém vygeneruje dokument,, který se přiřadí k vybranému doručenému dokumentu jako přijatý dokument s přílohou – výsledný vygenerovaný dokument. Množina výsledků je omezena vloženým datem od uživatele: Datem podání <= zadané.',
    },
    {
      name: 'Aktivace a deaktivace zadaného filtru',
      description:
        'V přehledu filtrů má uživatel možnost jedotlivé filtry deaktivovat a znovuaktivovat.',
    },
    {
      name: 'Smazání zadaného filtru',
      description:
        'V přehledu filtrů má uživatel možnost jednotlivé filtry odstranit.',
    },
    {
      name: 'Tisk výsledného lustra',
      description:
        'Uživatel vybere nezpracovaný dokument. Po stisknutí tlačítka <b>Vytvořit lustr</b> se v dokumentu vytvoří příloha typu <b>Lustrum</b>, kterou lze vytisknout.',
    },
  ],
};
