import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryObject,
  EmptyComponent,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { Decision } from '@models';
import { useDecisionFieldColumns } from './decisions-columns';

export interface DecisionFieldProps {
  decisionForms: DictionaryObject[];
  decisionStates: DictionaryObject[];
  source: ScrollableSource<Decision>;
}

export function DecisionField({
  source,
  decisionForms,
  decisionStates,
}: DecisionFieldProps) {
  const columns = useDecisionFieldColumns({ decisionForms, decisionStates });

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__DECISIONS__PANEL_TITLE"
          defaultMessage="Rozhodnutí"
        />
      }
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        columns={columns}
        source={source}
        maxRows={7}
        ToolbarComponent={EmptyComponent}
        showDetailBtnCond={() => false}
        showRadioCond={() => false}
      />
    </FormPanel>
  );
}
