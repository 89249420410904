import { DatedEvidence, DatedObject, EvidenceProps } from '@eas/common-web';
import { useEvidence } from './evidence';

export function useDatedEvidence<OBJECT extends DatedObject>(
  options: EvidenceProps<OBJECT>
): EvidenceProps<OBJECT> {
  const props = useEvidence(options);

  return DatedEvidence.useDatedEvidence(props);
}
