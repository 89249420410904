import clsx from 'clsx';
import React from 'react';
import {
  ApiFilterOperation,
  FilterComponentProps,
  Select,
  TextField,
} from '@eas/common-web';
import { useStringOperations } from '../filter-api';
import { useStyles } from '../filter-styles';
import { useFilterOperation } from '../hooks/filter-operation-hook';

export function DisabledFilterTextCell(props: FilterComponentProps) {
  return <FilterTextCell {...props} disabled={true} />;
}

export function FilterTextCell({
  disabled,
  value,
  onChange,
  state,
  onChangeState,
  handleValueChange,
  handleOperationChange,
  operation,
}: FilterComponentProps & {
  handleValueChange?: any;
  handleOperationChange?: any;
  operation?: ApiFilterOperation;
}) {
  const classes = useStyles();
  const operations = useStringOperations();
  const { handleChangeOperation: defaultHandleChangeOperation } =
    useFilterOperation(state, onChangeState);

  return (
    <div className={clsx(classes.root, { [classes.disabled]: disabled })}>
      <div className={classes.selectWrapper}>
        <Select
          source={operations}
          value={operation ?? state.operation}
          onChange={handleOperationChange ?? defaultHandleChangeOperation}
          valueIsId={true}
          clearable={false}
          disabled={disabled}
        />
      </div>
      <div className={classes.textWrapper}>
        <TextField
          value={value}
          onChange={handleValueChange ?? onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
