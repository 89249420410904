import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  EvidenceContext,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { UserField } from '@composite/users/user-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Role } from '@enums';
import { USER_ROLE_SOURCE } from './role-hooked-sources';

export function Fields() {
  const { agendas, defaultableAgendas } = useContext(StaticDataContext);
  const { hookedSources } = useContext(EvidenceContext);
  const { source } = useContext(DetailContext);

  const userSource = hookedSources[USER_ROLE_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__ROLES__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormTextField
          name="name"
          required
          label={
            <FormattedMessage
              id="ES__ROLES__DETAIL__PANEL_TITLE_NAME"
              defaultMessage="Název role"
            />
          }
        />
      </FormPanel>
      <UserField
        withAgendaFields={source.data.id === Role.INDEX}
        withDefaultUserFields={[
          Role.PUBLICATION,
          Role.ANONYMIZATION,
          Role.ADVISER,
          Role.VIEWING_REQUEST_MANAGER,
          Role.MAILROOM,
          Role.FILE_ROOM,
          Role.HEAD_OSV,
          Role.LAWYER,
          Role.LUSTRUM,
        ].includes(source.data.id)}
        agendas={agendas}
        defaultableAgendas={defaultableAgendas}
        source={userSource}
      />
    </>
  );
}
