import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';

export function Fields() {
  const historyDescriptors = useCommonHistoryDescriptors();

  const { agendas } = useContext(StaticDataContext);

  const agendasSource = useStaticListSource(agendas);

  return (
    <>
      <DictionaryEvidence.FieldName />
      <DictionaryEvidence.FieldActive />
      <DictionaryEvidence.FieldOrder />
      <FormSelect
        name="defaultForAgendas"
        source={agendasSource}
        label={
          <FormattedMessage
            id="ES__DICT_PROCESSING_TYPES__DETAIL__FIELD_LABEL__DEFAULT_FOR_AGENDAS"
            defaultMessage="Výchozí pro agendy"
          />
        }
        valueIsId={true}
        multiple={true}
        disabled={true}
      />
      <HistoryField
        descriptors={[
          ...historyDescriptors,
          { key: 'defaultForAgendas', label: 'Výchozí pro agendy' },
        ]}
      />
    </>
  );
}
