import React from 'react';
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@eas/common-web';
import { EsAppSettings as AppSettingsModel } from '@models';
import { AppSettingsFields } from './app-settings-fields';
import { useAppSettings } from './app-settings-hook';

const useStyles = makeStyles({
  container: {
    marginTop: 15,
    justifyContent: 'center',
    width: '100%',
  },
  titleWrapper: {
    textAlign: 'left',
    borderBottom: '1px solid #cdcdcd',
  },
  title: {
    display: 'inline-block',
    margin: '10px 0',
    fontSize: 25,
    fontWeight: 500,
  },
});

export function AppSettings() {
  const {
    loading,
    editing,
    ref,
    validationSchema,
    handleSubmit,
    handleCancelEditing,
    handleStartEditing,
  } = useAppSettings();

  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid container spacing={5} className={classes.container}>
        <Grid item xs={12}>
          <div className={classes.titleWrapper}>
            <Typography variant="h1" className={classes.title}>
              <FormattedMessage
                id="ES__APP_SETTTINGS__TITLE"
                defaultMessage="Nastavení aplikace"
              />
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Form<AppSettingsModel>
            editing={!loading && editing}
            initialValues={{} as any}
            onSubmit={handleSubmit}
            ref={ref}
            validationSchema={validationSchema as any}
          >
            <AppSettingsFields
              loading={loading}
              editing={editing}
              handleStartEditing={handleStartEditing}
              handleCancelEditing={handleCancelEditing}
            />
          </Form>
        </Grid>
      </Grid>
    </Container>
  );
}
