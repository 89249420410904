import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DictSenate } from '@models';

export function useColumns(): TableColumn<DictSenate>[] {
  const intl = useIntl();
  const { columnActive, columnName } = DictionaryEvidence.useDictionaryColumns<
    DictSenate
  >();

  return [
    {
      datakey: 'number',
      name: intl.formatMessage({
        id: 'ES__DICT_SENATES__TABLE__COLUMN__NUMBER',
        defaultMessage: 'Číslo senátu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    columnName,
    columnActive,
  ];
}
