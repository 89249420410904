import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useConvertToPdfDialog() {
  const callApi = useEventCallback((id: string, attachmentId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${id}/attachment/${attachmentId}/convert-to-pdf`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  return {
    callApi,
  };
}
