import { DiffEditor, DiffOnMount } from '@monaco-editor/react';
import React, { useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DetailContext, DetailHandle } from '@eas/common-web';
import { Decision, DecisionText } from '@models';

const useStyles = makeStyles({
  root: {
    border: '1px solid #e4e4e4',
  },
});

export const DifferenceEditor = ({
  fieldName,
  onMount,
  disabled,
}: {
  fieldName: keyof DecisionText;
  onMount: DiffOnMount;
  disabled?: boolean;
}) => {
  const classes = useStyles();

  const { formRef } = useContext<DetailHandle<Decision>>(DetailContext);

  const formValues = formRef?.getFieldValues();
  const text = formValues?.text?.[fieldName];
  const anonymizedText = formValues?.anonymizedText?.[fieldName];

  return (
    <div style={{ overflow: 'hidden', width: '100%' }}>
      <DiffEditor
        original={text}
        width="100%"
        height="70vh"
        modified={anonymizedText}
        onMount={onMount}
        className={classes.root}
        options={{
          readOnly: disabled,
          automaticLayout: true,
          wordWrap: 'on',
          renderOverviewRuler: false,
          lineNumbers: 'off',

          quickSuggestions: false,
          suggestOnTriggerCharacters: false,
          acceptSuggestionOnEnter: 'off',
          acceptSuggestionOnCommitCharacter: false,

          snippetSuggestions: undefined,
          roundedSelection: false,
          glyphMargin: false,
          folding: false,
          // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
          minimap: {
            enabled: false,
          },
        }}
      />
    </div>
  );
};
