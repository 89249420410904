import { getErrorMessage } from '@utils/error-message';
import { flatten } from 'lodash';
import React, { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DialogHandle,
  DictionaryFieldDialog,
  FieldFilter,
  SnackbarContext,
  TableFieldCells,
  TableFieldToolbarButton,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import {
  CourtFile,
  DocumentAttachment,
  ErrorObject,
  OutgoingDocument,
} from '@models';
import { DocumentType, EvidenceApiUrl } from '@enums';
import { useAllAttachmentTypes, useAttachmentSource } from '../attachment-api';

function callCopyAttachmentsApi(id: string, values: string[]) {
  return abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/attachment/copy`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(values),
  });
}

export function CopyAttachmentButton({ disabled }: { disabled: boolean }) {
  const { source, isLocked } =
    useContext<DetailHandle<OutgoingDocument>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const dialogRef = useRef<DialogHandle>(null);
  const attachments = useAttachmentSource();
  const intl = useIntl();

  const callCopyApi = useEventCallback(
    async (selectedAttachments: DocumentAttachment[]) => {
      try {
        await callCopyAttachmentsApi(
          source.data!.id,
          selectedAttachments.map((attachment) => attachment.id)
        ).raw();

        dialogRef.current?.close();
        source.refresh();
      } catch (e) {
        const err = e as ErrorObject<string>;

        const message = getErrorMessage(err);

        if (err.name !== 'AbortError') {
          showSnackbar(...message);
        }
      }
    }
  );

  return (
    <>
      <TableFieldToolbarButton
        IconComponent={ControlPointIcon}
        show={true}
        disabled={isLocked || disabled}
        title="Přidat kopii"
        onClick={() => dialogRef.current?.open()}
        color="primary"
        variant="contained"
      >
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'ES__DOCUMENTS__ACTIONBAR__SEARCH_ATTACHMENT',
            defaultMessage: 'Přidat kopii',
          })}
        </Typography>
      </TableFieldToolbarButton>
      <DictionaryFieldDialog
        ref={dialogRef}
        columns={[
          {
            name: 'Spisová značka',
            datakey: 'document.esFile.number.value',
            width: 150,
          },
          {
            name: 'Číslo jednací',
            datakey: 'document.order',
            CellComponent: (props) => (
              <TableFieldCells.TextCell
                {...props}
                value={props.rowValue.document.referenceNumber}
              />
            ),
            width: 200,
          },
          {
            name: 'Stručný obsah',
            datakey: 'document.summary',
            width: 200,
          },
          {
            name: 'Datum doručení',
            datakey: 'document.deliveryDate',
            width: 200,
            CellComponent: TableFieldCells.DateCell,
          },
          {
            name: 'Název přílohy',
            datakey: 'name',
            width: 200,
          },
          {
            name: 'Název souboru',
            datakey: 'file.name',
            width: 200,
          },
        ]}
        filtersFields={[
          {
            label: 'Název přílohy',
            datakey: 'name',
            filterkey: 'name',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterTextCell,
          },
          {
            label: 'Typ přílohy',
            datakey: 'type.id',
            filterkey: 'type.id',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterSelectCell(
              useAllAttachmentTypes
            ),
          },
        ]}
        preFilters={[
          {
            field: 'document.esFile.id',
            operation: ApiFilterOperation.IN,
            values: getEsFileIds(source?.data?.esFile),
          },
          {
            field: 'document.type.id',
            operation: ApiFilterOperation.EQ,
            value: DocumentType.DELIVERED,
          },
        ]}
        sorts={[
          {
            field: 'document.order',
            type: 'FIELD',
            order: 'DESC',
          },
          {
            field: 'name',
            type: 'FIELD',
            order: 'ASC',
          },
        ]}
        onChange={
          callCopyApi as (
            selected: DocumentAttachment | DocumentAttachment[]
          ) => Promise<undefined>
        }
        width={1000}
        source={attachments}
        multiple={true}
      />
    </>
  );
}

const getEsFileIds = (esFile?: CourtFile): string[] => {
  let ids = [esFile?.id];

  if (esFile?.continuingFileFor?.length) {
    const mergedFilesIds = flatten(
      esFile.continuingFileFor.map((file) =>
        (file.mergedEsFiles ?? []).map((file) => file.id)
      )
    );

    ids = [...ids, ...mergedFilesIds];
  }

  return ids as string[];
};
