import * as Yup from 'yup';
import { EsFileRegulation } from '@models';

export function useValidationSchema() {
  return Yup.object().shape({
    regulations: Yup.array()
      .of<EsFileRegulation>(
        Yup.mixed<EsFileRegulation>().test('', '', function (val) {
          const { createError } = this;

          if (!val.number) {
            return createError({
              path: 'regulations',
              message: `Pole Číslo nesmí být prázdné`,
            });
          }

          if (!val.year) {
            return createError({
              path: 'regulations',
              message: `Pole Rok nesmí být prázdné`,
            });
          }

          if (!val.type) {
            return createError({
              path: 'regulations',
              message: `Pole Typ nesmí být prázdné`,
            });
          }

          return true;
        })
      )
      .nullable()
      .min(1, 'Vyplňte alespoň 1 předpis'),
  });
}
