import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { RedirectForm, useRedirect } from '@composite/redirect/dialog-redirect';
import { EsFile } from '@models';
import {
  Agenda,
  EsFileState,
  EvidenceApiUrl,
  EvidenceBrowserUrl,
} from '@enums';
import { useEsFileMetadata } from '../../es-file-utils';
import { useFormFields } from './change-agenda-dialog';
import { getOptions } from './change-agenda-utils';

export function useChangeAgendaDialog() {
  const match = useRouteMatch();

  const { source } = useContext<DetailHandle<EsFile>>(DetailContext);

  const { hasChangeAgendaPermission } = useEsFileMetadata(source.data!);

  const inAllFiles = [
    EvidenceBrowserUrl.ES_FILES_ALL + '/:id?',
    EvidenceBrowserUrl.ES_FILES_SEARCH + '/:id?',
  ].includes(match.path);

  const callApi = useEventCallback((id: string, formData: { agenda: Agenda }) =>
    abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/${id}/agenda/${formData.agenda}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
      }
    )
  );

  const showButton = source.data?.state === EsFileState.NEW;
  const disableButton = !hasChangeAgendaPermission;

  const FormFields = useFormFields();

  const initialAgenda =
    source.data?.agenda === Agenda.COURT ? Agenda.SPR : Agenda.COURT;

  const redirectBag = useRedirect<EsFile, RedirectForm & { agenda?: Agenda }>({
    options: (values) =>
      getOptions(source.data?.agenda, values?.agenda ?? initialAgenda),
    redirectTo: (values) => {
      switch (values?.agenda) {
        case Agenda.SPR:
          return EvidenceBrowserUrl.SPR_FILES_NEW;
        case Agenda.DISCIPLINARY_PROCEEDINGS:
          return EvidenceBrowserUrl.DISCIPLINARY_PROCEEDINGS_FILES;
        case Agenda.INF:
          return EvidenceBrowserUrl.INF_FILES_NEW;
        case Agenda.COURT:
          return EvidenceBrowserUrl.COURT_FILES_NEW;

        default:
          return EvidenceBrowserUrl.ES_FILES_ALL;
      }
    },
    FormFields,
    initialValues: {
      agenda: initialAgenda,
    },
  });

  return {
    callApi,
    showButton,
    disableButton,
    redirectBag: inAllFiles ? { FormFields } : redirectBag,
  };
}
