import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DetailContext, PrimaryDetailActionbarButton } from '@eas/common-web';
import { usePlenaryOpinionPermission } from '@modules/es-file/es-file-permission';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { UIElement } from '@enums';
import { useReopenDialog } from './dialog-actions/reopen-hook';

export function ActionBar() {
  const intl = useIntl();
  const { source } = useContext(DetailContext);
  const { hasPermission } = usePlenaryOpinionPermission(source.data);

  const { callApi: reopenApiCall, showButton: showReopenButton } =
    useReopenDialog();

  return (
    <>
      {showReopenButton && (
        <DetailActionButton
          promptKey="REOPEN"
          apiCall={reopenApiCall}
          isLocked={false}
          buttonLabel={intl.formatMessage({
            id: 'ES__PLENARY_OPINION_FILES__ACTIONBAR__REOPEN',
            defaultMessage: 'Znovu otevřít',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__PLENARY_OPINION_FILES__DIALOG_REOPEN__TITLE',
            defaultMessage: 'Opětovné otevření spisu',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__PLENARY_OPINION_FILES__DIALOG_REOPEN__TEXT',
            defaultMessage: 'Opravdu chcete znovu otevřít vybraný spis?',
          })}
          buttonDisabled={
            !hasPermission(
              UIElement.PlenaryOpinionFile.REOPEN_PLENARY_OPINION_FILE_BUTTON
            )
          }
          ButtonComponent={PrimaryDetailActionbarButton}
        />
      )}
    </>
  );
}
