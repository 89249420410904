import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DeliveredDocument } from '@models';
import { EvidenceBrowserUrl } from '@enums';

export function useColumns(): TableColumn<DeliveredDocument>[] {
  const intl = useIntl();

  const { agendas, documentDeliveryMethods } = useContext(StaticDataContext);
  const useAgendas = () => useStaticListSource(agendas);
  const useDeliveryMethods = () => useStaticListSource(documentDeliveryMethods);

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__DELIVERED_DOCUMENTS_UNPROCESSED__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.DOCUMENTS_DELIVERED_UNPROCESSED}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    {
      datakey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__DELIVERED_DOCUMENTS_UNPROCESSED__TABLE__COLUMN__DELIVERY_DATE',
        defaultMessage: 'Datum doručení',
      }),
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'incomingNumber',
      name: intl.formatMessage({
        id: 'ES__DELIVERED_DOCUMENTS_UNPROCESSED__TABLE__COLUMN__INCOMING_NUMBER',
        defaultMessage: 'Došlé číslo',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'summary',
      name: intl.formatMessage({
        id: 'ES__DELIVERED_DOCUMENTS_UNPROCESSED__TABLE__COLUMN__SUMMARY',
        defaultMessage: 'Stručný obsah',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'preceding',
      name: intl.formatMessage({
        id: 'ES__DELIVERED_DOCUMENTS_UNPROCESSED__TABLE__COLUMN__PRECEDING',
        defaultMessage: 'Předchází',
      }),
      width: 100,
      CellComponent: TableCells.BooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'validation.valid',
      name: intl.formatMessage({
        id: 'ES__DELIVERED_DOCUMENTS_PROCESSED__TABLE__COLUMN__VALID',
        defaultMessage: 'Dokument je validní',
      }),
      width: 200,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'deliveryMethod',
      sortkey: 'deliveryMethod.name',
      filterkey: 'deliveryMethod.id',
      name: intl.formatMessage({
        id: 'ES__DELIVERED_DOCUMENTS_UNPROCESSED__TABLE__COLUMN__DELIVERY_METHOD',
        defaultMessage: 'Způsob doručení',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useDeliveryMethods),
      valueMapper: TableCells.useSelectCellFactory(useDeliveryMethods),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'agenda',
      sortkey: 'agenda.name',
      filterkey: 'agenda.id',
      name: intl.formatMessage({
        id: 'ES__DELIVERED_DOCUMENTS_UNPROCESSED__TABLE__COLUMN__AGENDA',
        defaultMessage: 'Agenda',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useAgendas),
      valueMapper: TableCells.useSelectCellFactory(useAgendas),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}
