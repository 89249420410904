import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DictBarcodePrinter } from '@models';

export function useColumns(): TableColumn<DictBarcodePrinter>[] {
  const intl = useIntl();
  const { columnName } = DictionaryEvidence.useDictionaryColumns<
    DictBarcodePrinter
  >();

  return [
    columnName,
    {
      datakey: 'sharedDirectory',
      name: intl.formatMessage({
        id: 'ES__DICT_BARCODE_PRINTERS__TABLE__COLUMN__SHARED_DIRECTORY',
        defaultMessage: 'Sdílený adresář',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'hasDefaultValue',
      name: intl.formatMessage({
        id: 'ES__DICT_BARCODE_PRINTERS__TABLE__COLUMN__HAS_DEFAULT_VALUE',
        defaultMessage: 'Výchozí hodnota',
      }),
      width: 100,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
  ];
}
