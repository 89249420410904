import clsx from 'clsx';
import React, { useState } from 'react';
import {
  ApiFilterOperation,
  FilterComponentProps,
  useEventCallback,
} from '@eas/common-web';
import { Operation, useNullableComparingOperations } from '../filter-api';
import { useStyles } from '../filter-styles';
import { FilterTextCell } from './filter-text-cell';
import { NullableFilterCell } from './nullable-filter-cell';

export function DisabledFilterFilledTextCell(props: FilterComponentProps) {
  return <NullableFilterTextCell {...props} disabled={true} />;
}

export function NullableFilterTextCell({
  disabled,
  value,
  state,
  onChangeState,
  ...props
}: FilterComponentProps) {
  const classes = useStyles();
  const nullComparingOperations = useNullableComparingOperations();

  const [operation, setOperation] = useState(state.operation);
  const [filled, setFilled] = useState<ApiFilterOperation | 'DEFAULT'>(() => {
    if (
      [ApiFilterOperation.IS_NULL, ApiFilterOperation.NOT_NULL].includes(
        state.operation
      )
    ) {
      return state.operation;
    }

    return value ? ApiFilterOperation.NOT_NULL : 'DEFAULT';
  });

  const handleFilledChange = useEventCallback(
    (newFilled: string | Operation | Operation[] | string[] | null) => {
      setFilled(newFilled as ApiFilterOperation);

      switch (newFilled) {
        case ApiFilterOperation.IS_NULL:
        case ApiFilterOperation.NOT_NULL:
          onChangeState({ ...state, operation: newFilled, value: null });
          break;

        default:
          onChangeState({
            ...state,
            operation,
            value: null,
          });
      }
    }
  );

  const handleOperationChange = useEventCallback((newOperation: any) => {
    setOperation(newOperation as ApiFilterOperation);

    if (value) {
      onChangeState({ ...state, operation: newOperation });
    }
  });

  const handleValueChange = useEventCallback((newValue: string | null) => {
    if (newValue) {
      onChangeState({ ...state, operation, value: newValue });
    } else {
      onChangeState({
        ...state,
        operation: ApiFilterOperation.NOT_NULL,
        value: null,
      });
    }
  });

  return (
    <div
      className={clsx(classes.root, classes.columnDirection, {
        [classes.disabled]: disabled,
      })}
    >
      <NullableFilterCell
        source={nullComparingOperations}
        value={filled}
        onChange={handleFilledChange}
        addMargin={filled === ApiFilterOperation.NOT_NULL}
      />
      {filled === ApiFilterOperation.NOT_NULL && (
        <FilterTextCell
          {...props}
          disabled={disabled}
          value={value}
          state={state}
          onChangeState={onChangeState}
          handleValueChange={handleValueChange}
          handleOperationChange={handleOperationChange}
          operation={operation}
        />
      )}
    </div>
  );
}
