import React from 'react';
import Grid from '@material-ui/core/Grid';
import { AssignedAndResolvedEsFilesData, IntervalInput } from '@models';
import { EvidenceApiUrl, IntervalType } from '@enums';
import { StatisticCard } from '../card/card';
import { IntervalHeader } from '../card/interval-header';
import { useStatistic } from '../statistics-hook';
import { useAssignedAndResolvedCountsVisualisation } from './assigned-and-resolved-counts-hook';

export function AssignedAndResolvedCounts() {
  const url = EvidenceApiUrl.STATISTICS_ASSIGNED_AND_RESOLVED_ES_FILES;

  const { data, params, setParams } = useStatistic<
    IntervalInput,
    AssignedAndResolvedEsFilesData
  >({
    url,
    initialParams: {
      intervalType: IntervalType.ALL,
    },
  });

  const { Visualisations } = useAssignedAndResolvedCountsVisualisation({
    data: data?.assignedAndResolvedByJudgeCounts,
    addSum: true,
  });

  return (
    <Grid item xs={6}>
      <StatisticCard
        title="Přidělené a vyřízené spisy podle soudců"
        url={url}
        params={params}
        Header={() => <IntervalHeader params={params} setParams={setParams} />}
        Visualisations={Visualisations}
      />
    </Grid>
  );
}
