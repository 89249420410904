import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DictZipCode } from '@models';

export function useColumns(): TableColumn<DictZipCode>[] {
  const { columnActive, columnCode, columnName } =
    DictionaryEvidence.useDictionaryColumns<DictZipCode>();

  return [
    columnCode,
    columnName,
    {
      datakey: 'city',
      name: 'Město',
      CellComponent: TableCells.TextCell,
      width: 250,
      filterable: true,
      sortable: true,
    },
    columnActive,
  ];
}
