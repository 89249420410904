import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { UserSubstitution } from '@models';
import { EntityName, EvidenceApiUrl } from '@enums';
import { useColumns } from './user-substitution-columns';
import { Fields } from './user-substitution-fields';
import { useUserSubstitutionHookedSources } from './user-substitution-hooked-sources';
import { useValidationSchema } from './user-substitution-schema';

export function UserSubstitutions() {
  const intl = useIntl();

  const validationSchema = useValidationSchema();

  const evidence = useAuthoredEvidence<UserSubstitution>({
    identifier: 'USER_SUBSTITUTIONS',
    apiProps: {
      url: EvidenceApiUrl.USER_SUBSTITUTIONS,
      hookedSources: useUserSubstitutionHookedSources,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__USER_SUBSTITUTIONS__TABLE__TITLE',
        defaultMessage: 'Zástupy uživatelů',
      }),
      defaultSorts: [],
      showReportButton: false,
    },
    detailProps: {
      entityName: EntityName.USER_SUBSTITUTION,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__USER_SUBSTITUTIONS__DETAIL__TITLE',
          defaultMessage: 'Zástup uživatele',
        }),
        showBtn: (name) => !['REMOVE'].includes(name),
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
