import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useDatedEvidence } from '@composite/evidence/dated-evidence';
import { AssignedFileNumber } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './assigned-file-number-columns';
import { Fields } from './assigned-file-number-fields';

export function AssignedFileNumbers() {
  const intl = useIntl();
  const columns = useColumns();

  const evidence = useDatedEvidence<AssignedFileNumber>({
    version: 6,
    identifier: 'ASSIGNED_FILE_NUMBERS',
    apiProps: {
      url: EvidenceApiUrl.ASSIGNED_FILE_NUMBERS,
    },
    tableProps: {
      columns: columns,
      tableName: intl.formatMessage({
        id: 'ES__ASSIGNED_FILE_NUMBERS__TABLE__TITLE',
        defaultMessage: 'Historie přidělení spisových značek',
      }),
      defaultSorts: [],
      showReportButton: false,
    },
    detailProps: {
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__ASSIGNED_FILE_NUMBERS__DETAIL__TITLE',
          defaultMessage: 'Historie přidělení spisové značky',
        }),
        showBtn: (btn) => ['REFRESH', 'SHARE'].includes(btn),
      },
    },
  });

  return <Evidence {...evidence} />;
}
