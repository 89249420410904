import React from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryObject,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { useSettlementMethods } from '@modules/dict-settlement-method/dict-settlement-method-api';
import { useJudges } from '@modules/judge/judge-api';
import { useSubjects } from '@modules/subject/subject-api';
import { getDocumentUrl } from '@composite/documents/document-columns';
import { Redirect } from '@composite/redirect/redirect';
import {
  Decision,
  Document,
  EsFile,
  EsFileUnion,
  Proposer,
  UserRole,
  Writer,
} from '@models';
import { EvidenceBrowserUrl } from '@enums';

export function useDocumentColumns({
  documentTypes,
  documentDeliveryMethods,
  documentDispatchStates,
}: {
  documentTypes: DictionaryObject[];
  documentDeliveryMethods: DictionaryObject[];
  documentDispatchStates: DictionaryObject[];
}): TableFieldColumn<Document>[] {
  const intl = useIntl();

  const useDocumentTypes = () => useStaticListSource(documentTypes);
  const useDocumentDeliveryMethods = () =>
    useStaticListSource(documentDeliveryMethods);
  const useDocumentDispatchStates = () =>
    useStaticListSource(documentDispatchStates);

  return [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: function Cell(props: TableFieldCellProps<Document>) {
        const url = getDocumentUrl(props.rowValue.type!);

        return <Redirect url={url} id={props.value} />;
      },
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__DELIVERY_DATE',
        defaultMessage: 'Datum doručení',
      }),
      datakey: 'deliveryDate',
      sortkey: 'deliveryDate',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__CREATED_DATE',
        defaultMessage: 'Datum vytvoření',
      }),
      datakey: 'createdDate',
      sortkey: 'createdDate',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      datakey: 'referenceNumber',
      sortkey: 'referenceNumber',
      width: 150,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__INCOMING_NUMBER',
        defaultMessage: 'Došlé číslo',
      }),
      datakey: 'incomingNumber',
      sortkey: 'incomingNumber',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__SUMMARY',
        defaultMessage: 'Stručný obsah',
      }),
      datakey: 'summary',
      sortkey: 'summary',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__TYPE',
        defaultMessage: 'Typ',
      }),
      datakey: 'type',
      sortkey: 'type.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useDocumentTypes),
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__DELIVERY_METHOD',
        defaultMessage: 'Způsob doručení',
      }),
      datakey: 'deliveryMethod',
      sortkey: 'deliveryMethod.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useDocumentDeliveryMethods
      ),
    },
    {
      name: intl.formatMessage({
        id: 'ES__DOCUMENTS__COLUMN__DISPATCH_STATE',
        defaultMessage: 'Stav vypravení',
      }),
      datakey: 'dispatchState',
      sortkey: 'dispatchState.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useDocumentDispatchStates
      ),
    },
  ];
}

export function useEsFileColumns({
  states,
  agendas,
}: {
  states: DictionaryObject[];
  agendas: DictionaryObject[];
}): TableFieldColumn<EsFile>[] {
  const intl = useIntl();

  const useStates = () => useStaticListSource(states);
  const useAgendas = () => useStaticListSource(agendas);

  return [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: function Cell(props) {
        return (
          <Redirect url={EvidenceBrowserUrl.ES_FILES_ALL} id={props.value} />
        );
      },
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 100,
      CellComponent: TableFieldCells.useSelectCellFactory(useStates),
    },
    {
      datakey: 'agenda',
      sortkey: 'agenda.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__STATE',
        defaultMessage: 'Agenda',
      }),
      width: 100,
      CellComponent: TableFieldCells.useSelectCellFactory(useAgendas),
    },
    {
      datakey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SUBMISSION_DATE',
        defaultMessage: 'Datum doručení',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'incomingNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__INCOMING_NUMBER',
        defaultMessage: 'Došlé číslo',
      }),
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 120,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'judgeSolversMerged',
      sortkey: 'judgeSolversMerged.concatenated',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj / Řešitel',
      }),
      width: 150,
      CellComponent: function Cell(props) {
        const rowValue = props.rowValue as EsFileUnion;
        let value = '';

        if ('judge' in rowValue) {
          value = rowValue.judge?.label ?? '';
        } else if ('solverUserRoles' in rowValue) {
          value =
            rowValue.solverUserRoles
              ?.map((solver: UserRole) => solver.label)
              .join(', ') ?? '';
        }

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
    {
      datakey: 'participants',
      sortkey: 'participants.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROPOSERS',
        defaultMessage: 'Navrhovatel / Pisatel',
      }),
      width: 200,
      CellComponent: function Cell(props) {
        const rowValue = props.rowValue as EsFileUnion;
        let value = '';

        if ('proposers' in rowValue) {
          value =
            rowValue.proposers
              ?.map((proposer: Proposer) => proposer.label)
              .join(',') ?? '';
        } else if ('writers' in rowValue) {
          value =
            rowValue.writers?.map((writer: Writer) => writer.label).join(',') ??
            '';
        }

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
    {
      datakey: 'settlementMethods',
      sortkey: 'settlementMethods.concatenated',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SETTLEMENT_METHODS',
        defaultMessage: 'Způsob vyřízení',
      }),
      width: 150,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useSettlementMethods,
        multiple: true,
      }),
    },
  ];
}

export function useDecisionColumns({
  decisionForms,
  anonymizationRequests,
}: {
  decisionForms: DictionaryObject[];
  anonymizationRequests: DictionaryObject[];
}): TableFieldColumn<Decision>[] {
  const intl = useIntl();

  const useDecisionForms = () => useStaticListSource(decisionForms);
  const useAnonymizationRequests = () =>
    useStaticListSource(anonymizationRequests);

  return [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: function Cell(props: TableFieldCellProps<Decision>) {
        return (
          <Redirect url={EvidenceBrowserUrl.DECISIONS_ALL} id={props.value} />
        );
      },
    },
    {
      datakey: 'esFile.number.value',
      sortkey: 'esFile.number.valueSort',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ES_FILE',
        defaultMessage: 'Spisová značka',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'ecli',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__EUROPEAN_CASE_LAW_ID',
        defaultMessage: 'Identifikátor evropské judikatury',
      }),
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'form',
      sortkey: 'form.name',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__FORM',
        defaultMessage: 'Forma rozhodnutí',
      }),
      width: 150,
      CellComponent: TableFieldCells.useSelectCellFactory(useDecisionForms),
    },
    {
      datakey: 'decisionDate',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__DECISION_DATE',
        defaultMessage: 'Datum rozhodnutí',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'esFile.anonymizationRequest',
      sortkey: 'anonymizationRequest.name',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ANONYMIZATION_REQUEST',
        defaultMessage: 'Žádost o anonymizaci',
      }),
      width: 150,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useAnonymizationRequests
      ),
    },
    {
      datakey: 'settlementMethods',
      sortkey: 'settlementMethods.concatenated',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__SETTLEMENT_METHODS',
        defaultMessage: 'Typ výroku',
      }),
      width: 200,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useSettlementMethods,
        multiple: true,
      }),
    },
    {
      datakey: 'esFile.judge',
      sortkey: 'esFile.judge.name',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj',
      }),
      width: 200,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useJudges,
        multiple: false,
      }),
    },
    {
      datakey: 'esFile.proposers',
      sortkey: 'esFile.proposers.contact.subject.name',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PROPOSERS',
        defaultMessage: 'Navrhovatelé',
      }),
      width: 300,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useSubjects,
        multiple: true,
      }),
    },
  ];
}
