import clsx from 'clsx';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    width: '100%',
    borderRadius: 16,
    background:
      'linear-gradient(0deg, rgb(245 246 247) 0%, rgb(255 255 255) 100%)',
    margin: '0 0 24px',
  },
  invisible: {
    backgroundColor: 'transparent',
  },
  content: {
    padding: 32,
  },
  title: {
    fontSize: 14,
  },
});

export function DashboardCard({
  title,
  children,
  invisible,
  action,
}: {
  children?: React.ReactNode;
  title?: string;
  invisible?: boolean;
  action?: boolean;
}) {
  const classes = useStyles();

  const content = (
    <div className={classes.content}>
      {title && (
        <>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {title}
          </Typography>
          <Divider />
        </>
      )}

      {children}
    </div>
  );

  return (
    <Card
      className={clsx(classes.card, { [classes.invisible]: invisible })}
      elevation={invisible ? 0 : 2}
    >
      {action && <CardActionArea>{content}</CardActionArea>}
      {!action && content}
    </Card>
  );
}
