import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { RedirectForm, useRedirect } from '@composite/redirect/dialog-redirect';
import { Decision } from '@models';
import { DecisionState, EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';
import { getOptions } from './change-state-utils';

export function useChangeStateDialog(state: DecisionState) {
  const match = useRouteMatch();

  const { source } = useContext(DetailContext);

  const inAllDecisions = [
    EvidenceBrowserUrl.DECISIONS_ALL + '/:id?',
    EvidenceBrowserUrl.DECISIONS_SEARCH + '/:id?',
  ].includes(match.path);

  const callApi = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.DECISIONS}/${id}/state/${state}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    })
  );

  const initialState = source.data?.state;

  const redirectBag = useRedirect<Decision, RedirectForm>({
    options: () => getOptions(source.data?.state, state ?? initialState),
    redirectTo: () => {
      switch (state) {
        case DecisionState.IN_PROGRESS:
          return EvidenceBrowserUrl.DECISIONS_IN_PROGRESS;
        case DecisionState.TO_ANONYMIZE:
          return EvidenceBrowserUrl.DECISIONS_TO_ANONYMIZE;
        case DecisionState.TO_MAKE_ACCESSIBLE:
          return EvidenceBrowserUrl.DECISIONS_TO_MAKE_ACCESSIBLE;
        case DecisionState.ACCESSIBLE:
          return EvidenceBrowserUrl.DECISIONS_ACCESSIBLE;
        case DecisionState.INDEXING:
          return EvidenceBrowserUrl.DECISIONS_INDEXING;

        default:
          return EvidenceBrowserUrl.DECISIONS_IN_PROGRESS;
      }
    },
  });

  return { callApi, redirectBag: inAllDecisions ? {} : { ...redirectBag } };
}
