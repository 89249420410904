import { noop } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  FieldFilter,
  FileField,
  FormContext,
  FormCustomField,
  FormFileField,
  FormPanel,
  TableFieldCells,
  useFormSelector,
} from '@eas/common-web';
import { useDecisionPermission } from '@modules/decision/decision-permission';
import { useAttachmentSource } from '@composite/attachments/attachment-api';
import { useDraftSource } from '@composite/drafts/draft-api';
import { CreatedByCell } from '@components/evidence/authored-evidence/created-by-cell';
import { Decision, DocumentAttachment, Draft } from '@models';
import { UIElement } from '@enums';
import { AddAttachmentButton } from './add-attachment-button';

export function AttachmentFields() {
  const intl = useIntl();

  const { source } = useContext<DetailHandle<Decision>>(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const { hasPermission } = useDecisionPermission(source.data);

  const draftsSource = useDraftSource(source?.data?.esFile?.id ?? '');
  const documentAttachmentsSource = useAttachmentSource();

  const { fullTextDocx, fullTextPdf } = useFormSelector((data: Decision) => ({
    fullTextDocx: data.attachments?.fullTextDocx,
    fullTextPdf: data.attachments?.fullTextPdf,
  }));

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DECISIONS__DETAIL__FULL_TEXT_ATTACHMENTS_PANEL_TITLE"
            defaultMessage="Rozhodnutí - plné znění"
          />
        }
      >
        <FormCustomField
          name="attachments.fullTextPdf"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__FULL_TEXT_PDF"
              defaultMessage="PDF"
            />
          }
        >
          <FileField value={fullTextPdf} onChange={noop} disabled={true} />
          <AddAttachmentButton
            source={documentAttachmentsSource}
            columns={[
              {
                name: 'Číslo jednací',
                datakey: 'document.referenceNumber',
                width: 200,
              },
              {
                name: 'Stručný obsah',
                datakey: 'document.summary',
                width: 200,
              },
              {
                name: 'Datum doručení',
                datakey: 'document.deliveryDate',
                width: 200,
                CellComponent: TableFieldCells.DateCell,
              },
              {
                name: 'Název přílohy',
                datakey: 'name',
                width: 200,
              },
              {
                name: 'Název souboru',
                datakey: 'file.name',
                width: 200,
              },
            ]}
            filtersFields={[
              {
                label: intl.formatMessage({
                  id: 'ES__DRAFTS__COLUMN__NAME',
                  defaultMessage: 'Název',
                }),
                datakey: 'name',
                filterkey: 'name',
                defaultOperation: ApiFilterOperation.CONTAINS,
                FilterComponent: FieldFilter.FilterTextCell,
              },
            ]}
            preFilters={[
              {
                field: 'document.id',
                operation: ApiFilterOperation.EQ,
                value: source?.data?.document?.id,
              },
              { field: 'file.id', operation: ApiFilterOperation.NOT_NULL },
            ]}
            onChange={(attachment) => {
              setFieldValue(
                'attachments.fullTextPdf',
                (attachment as DocumentAttachment).file
              );
            }}
            disabled={!hasPermission(UIElement.Decision.FULL_TEXT)}
          />
        </FormCustomField>
        <FormCustomField
          name="attachments.fullTextDocx"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__FULL_TEXT_DOCX"
              defaultMessage="DOCX"
            />
          }
        >
          <FileField value={fullTextDocx} onChange={noop} disabled={true} />
          <AddAttachmentButton
            source={draftsSource}
            columns={[
              {
                name: intl.formatMessage({
                  id: 'ES__DRAFTS__COLUMN__NAME',
                  defaultMessage: 'Název konceptu',
                }),
                datakey: 'name',
                width: 200,
              },
              {
                name: intl.formatMessage({
                  id: 'ES__DRAFTS__COLUMN__FILE_NAME',
                  defaultMessage: 'Název souboru',
                }),
                datakey: 'file.name',
                width: 200,
              },
              {
                name: intl.formatMessage({
                  id: 'ES__DRAFTS__COLUMN__CREATED_BY',
                  defaultMessage: 'Tvůrce',
                }),
                datakey: 'createdBy.name',
                width: 200,
                CellComponent: CreatedByCell,
              },
              {
                name: intl.formatMessage({
                  id: 'ES__DRAFTS__COLUMN__UPDATED',
                  defaultMessage: 'Datum editace',
                }),
                datakey: 'updated',
                width: 200,
                CellComponent: TableFieldCells.DateCell,
              },
            ]}
            filtersFields={[
              {
                label: intl.formatMessage({
                  id: 'ES__DRAFTS__COLUMN__NAME',
                  defaultMessage: 'Název',
                }),
                datakey: 'name',
                filterkey: 'name',
                defaultOperation: ApiFilterOperation.CONTAINS,
                FilterComponent: FieldFilter.FilterTextCell,
              },
            ]}
            onChange={(draft) => {
              setFieldValue('attachments.fullTextDocx', (draft as Draft).file);
            }}
            disabled={!hasPermission(UIElement.Decision.FULL_TEXT)}
          />
        </FormCustomField>

        <FormFileField
          name="attachments.fullTextTxt"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__FULL_TEXT_TXT"
              defaultMessage="TXT"
            />
          }
          disabled={true}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DECISIONS__DETAIL__PUBLIC_TEXT_ATTACHMENTS_PANEL_TITLE"
            defaultMessage="Rozhodnutí - zveřejněné"
          />
        }
      >
        <FormFileField
          name="attachments.publicTextPdf"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__PUBLIC_TEXT_PDF"
              defaultMessage="PDF"
            />
          }
          disabled={!hasPermission(UIElement.Decision.PUBLIC_TEXT)}
        />
        <FormFileField
          name="attachments.publicTextDocx"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__PUBLIC_TEXT_DOCX"
              defaultMessage="DOCX"
            />
          }
          disabled={!hasPermission(UIElement.Decision.PUBLIC_TEXT)}
        />
        <FormFileField
          name="attachments.publicTextTxt"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__PUBLIC_TEXT_TXT"
              defaultMessage="TXT"
            />
          }
          disabled={!hasPermission(UIElement.Decision.PUBLIC_TEXT)}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DECISIONS__DETAIL__CORRECTED_TEXT_PANEL_TITLE"
            defaultMessage="Rozhodnutí po korektuře"
          />
        }
      >
        <FormFileField
          name="attachments.correctedTextDocx"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__CORRECTED_TEXT_DOCX"
              defaultMessage="DOCX"
            />
          }
          accept={['docx']}
          disabled={!hasPermission(UIElement.Decision.CORRECTED_TEXT)}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DECISIONS__DETAIL__OTHER_ANNOUNCEMENTS_PANEL_TITLE"
            defaultMessage="Jiná sdělení"
          />
        }
      >
        <FormFileField
          name="attachments.otherAnnouncementsPdf"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__OTHER_ANNOUNCEMENTS_PDF"
              defaultMessage="PDF"
            />
          }
          accept={['pdf']}
          disabled={!hasPermission(UIElement.Decision.OTHER_ANNOUNCEMENTS)}
        />
      </FormPanel>
    </>
  );
}
