import { Params, Sort, useAutocompleteSource, useFetch } from '@eas/common-web';
import { DictionaryAutocomplete, DictionaryObject } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useSubjects() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceApiUrl.SUBJECTS}/autocomplete`,
    apiUrl: EvidenceApiUrl.SUBJECTS,
  });
}

/**
 * List all available subject types.
 */
export function useTypes() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.SUBJECTS}/type/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}

/**
 * List all available isds subject types.
 */
export function useIsdsSubjectTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.SUBJECTS}/isds/type/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available data box types.
 */
export function useDataBoxTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.SUBJECTS}/data-box/type/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export const CONTACTS_SORT = [
  {
    field: 'subject.label',
    type: 'FIELD',
    order: 'ASC',
  },
  {
    field: 'subject.id',
    type: 'FIELD',
    order: 'ASC',
  },
  {
    field: 'label',
    type: 'FIELD',
    order: 'ASC',
  },
] as Sort[];

export function useContacts(params?: Params) {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceApiUrl.SUBJECT_CONTACTS}/autocomplete`,
    apiUrl: EvidenceApiUrl.SUBJECT_CONTACTS,
    params: params ?? {
      sort: CONTACTS_SORT,
    },
  });
}
