import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  DictionaryObject,
  EmptyComponent,
  FormContext,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextField,
  TableFieldCells,
  eqFilterParams,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { CityField } from '@components/form/address/city-field';
import { ZipCodeField } from '@components/form/address/zip-code-field';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { IdentifiedPersonDto } from '@models';
import { EvidenceApiUrl, SubjectType } from '@enums';

export function IdentifyDialog({ id }: { id: string }) {
  const { setFieldValue } = useContext(FormContext);
  const { subjectTypes } = useContext(StaticDataContext);

  const [loading, setLoading] = useState(false);

  const subjectTypeSource = useStaticListSource(subjectTypes);
  const useSubjectTypes = () => useStaticListSource(subjectTypes);

  const { selectedSubject } = useFormSelector(
    (data: { selectedSubject: IdentifiedPersonDto }) => ({
      selectedSubject: data.selectedSubject,
    })
  );

  useEffect(() => {
    const loadSubjects = async () => {
      setLoading(true);
      const response = await fetch(
        `${EvidenceApiUrl.SUBJECT_CONTACTS}/${id}/identify`
      );

      if (response.ok) {
        const subjects = await response.json();
        setFieldValue('subjects', subjects);
      }

      setLoading(false);
    };

    loadSubjects();
  }, []);

  const AddressField = ({
    prefix,
    label,
  }: {
    prefix?: string;
    label?: string;
  }) => {
    return (
      <FormPanel label={label}>
        <FormTextField name={`${prefix}.street`} label="Ulice" />
        <FormTextField
          name={`${prefix}.descriptiveNumber`}
          label="Číslo popisné"
        />
        <FormTextField
          name={`${prefix}.orientationNumber`}
          label="Číslo orientační"
        />
        <ZipCodeField prefix={prefix} />
        <CityField prefix={prefix} />
      </FormPanel>
    );
  };

  const columns = [
    {
      datakey: 'subjectType',
      name: 'Typ subjektu',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory<
        IdentifiedPersonDto,
        DictionaryObject
      >(useSubjectTypes),
    },
    {
      datakey: 'firstName',
      name: 'Jméno',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'lastName',
      name: 'Příjmení',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'maidenName',
      name: 'Rodné příjmení',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'companyName',
      name: 'Název firmy',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'ico',
      name: 'IČO',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'address.label',
      name: 'Adresa pobytu / sídla',
      width: 250,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'deliveryAddress.label',
      name: 'Doručovací adresa',
      width: 250,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'email',
      name: 'Email',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'phoneNumber',
      name: 'Telefonní číslo',
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
  ];

  return (
    <>
      {loading && (
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <>
          <FormPanel
            label={
              <FormattedMessage
                id="ES__CONTACTS__IDENTIFY_CONTACT_DIALOG__PANEL_TITLE__RESULTS"
                defaultMessage="Nalezené výsledky"
              />
            }
            expandable={false}
            summary={<FormTableCount formKey="subjects" />}
          >
            <FormTableField
              name="subjects"
              labelOptions={{
                hide: true,
              }}
              layoutOptions={{ noSpacing: true }}
              ToolbarComponent={EmptyComponent}
              maxRows={5}
              onSelect={async (row) => {
                setFieldValue('selectedSubject', row);

                const subject = row as IdentifiedPersonDto;

                if (subject?.address?.zipCode) {
                  const response = await fetch(
                    `${EvidenceApiUrl.DICT_ZIP_CODES}/autocomplete`,
                    {
                      headers: new Headers({
                        'Content-Type': 'application/json',
                      }),
                      method: 'POST',
                      body: JSON.stringify({
                        params: eqFilterParams({
                          field: 'code',
                          value: subject?.address?.zipCode,
                        }),
                      }),
                    }
                  );

                  if (response.ok) {
                    const zipCodes = await response.json();

                    setFieldValue(
                      'selectedSubject.address.zipCode',
                      zipCodes?.count === 1 ? zipCodes?.items?.[0] : undefined
                    );
                  }
                }
              }}
              columns={columns}
            />
          </FormPanel>
          {selectedSubject && (
            <>
              <FormPanel
                label={
                  <FormattedMessage
                    id="ES__CONTACTS__IDENTIFY_CONTACT_DIALOG__PANEL_TITLE__SELECTED_SUBJECT"
                    defaultMessage="Subjekt"
                  />
                }
                expandable={false}
              >
                <FormSelect
                  source={subjectTypeSource}
                  name="subjectType"
                  label="Typ subjektu"
                  disabled
                  valueIsId
                />
                {selectedSubject?.subjectType !== SubjectType.LEGAL_ENTITY && (
                  <>
                    <FormTextField
                      name="selectedSubject.firstName"
                      label="Jméno"
                    />
                    <FormTextField
                      name="selectedSubject.lastName"
                      label="Příjmení"
                    />
                    <FormTextField
                      name="selectedSubject.maidenName"
                      label="Rodné příjmení"
                    />
                  </>
                )}
                {selectedSubject?.subjectType !==
                  SubjectType.NATURAL_PERSON && (
                  <>
                    <FormTextField
                      name="selectedSubject.companyName"
                      label="Název firmy"
                    />
                    <FormTextField name="selectedSubject.ico" label="IČO" />
                  </>
                )}

                <FormTextField name="selectedSubject.email" label="Email" />
                <FormTextField
                  name="selectedSubject.phoneNumber"
                  label="Telefonní číslo"
                />
              </FormPanel>
              <AddressField prefix="selectedSubject.address" />
            </>
          )}
        </>
      )}
    </>
  );
}
