import * as Yup from 'yup';
import { useValidationSchema as useAddressValidationSchema } from '@components/form/address/address-schema';
import {
  Contact,
  DictContactType,
  IsdsSearchSubjectResponseDto,
  IsdsSubjectRequestDto,
} from '@models';
import { DataBoxType, IsdsSubjectType } from '@enums';

export const dataBoxIdentifierRule = /^[abcdefghijkmnpqrstuvwxyz23456789]{7}$/;
export const dataBoxMessage =
  'Číslo datové schránky musí být kombinací 7 povolených znaků (abcdefghijkmnpqrstuvwxyz23456789).';
export const emailMessage = 'E-mail musí být ve správném formátu.';
export const icoValidation = Yup.string()
  .test('', '', function (value) {
    const { path, createError } = this;

    const stringValue = value?.toString();

    const isValid =
      stringValue &&
      (11 -
        ((8 * Number(stringValue[0]) +
          7 * Number(stringValue[1]) +
          6 * Number(stringValue[2]) +
          5 * Number(stringValue[3]) +
          4 * Number(stringValue[4]) +
          3 * Number(stringValue[5]) +
          2 * Number(stringValue[6])) %
          11)) %
        10 ===
        Number(stringValue[7]);

    if (value && stringValue?.length !== 8) {
      return createError({
        path,
        message: 'IČO musí sestávat z 8 číslic',
      });
    }

    if (value && !isValid) {
      return createError({
        path,
        message: 'Zadané IČO není validní.',
      });
    }

    return true;
  })
  .nullable();

export function useValidationSchema() {
  const addressValidation = useAddressValidationSchema();

  return Yup.object<Contact>().shape({
    type: Yup.mixed<DictContactType>().nullable().required(),
    email: Yup.string().email(emailMessage).nullable(),
    dataBox: Yup.object()
      .shape(
        {
          identifier: Yup.string().when('type', {
            is: (type) => !!type,
            then: Yup.string()
              .matches(dataBoxIdentifierRule, dataBoxMessage)
              .nullable()
              .required(),
            otherwise: Yup.string()
              .matches(dataBoxIdentifierRule, dataBoxMessage)
              .nullable()
              .notRequired(),
          }),
          type: Yup.mixed<DataBoxType>().when('identifier', {
            is: (identifier) => !!identifier,
            then: Yup.mixed<DataBoxType>().nullable().required(),
          }),
        },
        [['identifier', 'type']]
      )
      .nullable(),
    address: addressValidation,
  }) as Yup.Schema<Contact>;
}

export function useIsdsSearchSubjectSchema() {
  const nameValidation = Yup.string()
    .nullable()
    .min(2, 'Musí sestávat minálně ze 2 znaků.');

  return Yup.object<
    IsdsSubjectRequestDto & IsdsSearchSubjectResponseDto
  >().shape(
    {
      subjectType: Yup.mixed<IsdsSubjectType>()
        .nullable()
        .when('dataBoxId', {
          is: (dataBoxId) => !dataBoxId,
          then: Yup.mixed<IsdsSubjectType>().nullable().required(),
          otherwise: Yup.mixed<IsdsSubjectType>().nullable().notRequired(),
        }),
      firstName: nameValidation.notRequired(),
      lastName: Yup.string().when(['subjectType', 'firmName', 'ico'], {
        is: (subjectType, firmName, ico) =>
          subjectType === IsdsSubjectType.NATURAL_PERSON ||
          ([
            IsdsSubjectType.PUBLIC_AUTHORITY,
            IsdsSubjectType.BUSINESS_NATURAL_PERSON,
          ].includes(subjectType) &&
            !firmName &&
            !ico),
        then: nameValidation.required(),
        otherwise: nameValidation.notRequired(),
      }),
      dataBoxId: Yup.string()
        .matches(dataBoxIdentifierRule, dataBoxMessage)
        .nullable()
        .notRequired(),
      ico: Yup.string()
        .nullable()
        .when(['subjectType', 'firmName', 'lastName'], {
          is: (subjectType, firmName, lastName) =>
            (subjectType === IsdsSubjectType.LEGAL_ENTITY && !firmName) ||
            ([
              IsdsSubjectType.PUBLIC_AUTHORITY,
              IsdsSubjectType.BUSINESS_NATURAL_PERSON,
            ].includes(subjectType) &&
              !lastName &&
              !firmName),
          then: icoValidation.required(),
          otherwise: icoValidation.notRequired(),
        }),
      firmName: Yup.string()
        .nullable()
        .when(['subjectType', 'ico', 'lastName'], {
          is: (subjectType, ico, lastName) =>
            (subjectType &&
              subjectType === IsdsSubjectType.LEGAL_ENTITY &&
              !ico) ||
            ([
              IsdsSubjectType.PUBLIC_AUTHORITY,
              IsdsSubjectType.BUSINESS_NATURAL_PERSON,
            ].includes(subjectType) &&
              !ico &&
              !lastName),
          then: nameValidation.required(),
          otherwise: nameValidation.notRequired(),
        }),
    },
    [
      ['ico', 'firmName'],
      ['ico', 'lastName'],
      ['lastName', 'firmName'],
    ]
  ) as Yup.Schema<IsdsSubjectRequestDto & IsdsSearchSubjectResponseDto>;
}
