import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { ViewingRequest } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { Redirect } from '../redirect/redirect';

export function useColumns(): TableFieldColumn<ViewingRequest>[] {
  const intl = useIntl();
  const { viewingRequestStates, viewingMethods, participantTypes } =
    useContext(StaticDataContext);

  const useParticipantTypes = () => useStaticListSource(participantTypes);
  const useStates = () => useStaticListSource(viewingRequestStates);
  const useViewingMethods = () => useStaticListSource(viewingMethods);

  const columns: TableFieldColumn<ViewingRequest>[] = [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: function Cell(props: TableFieldCellProps<ViewingRequest>) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.VIEWING_REQUEST_ALL}
              id={props.rowValue!.id}
            />
          </>
        );
      },
    },
    {
      datakey: 'created',
      sortkey: 'created',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__CREATED',
        defaultMessage: 'Datum vytvoření',
      }),
      CellComponent: TableFieldCells.DateTimeCell,
      width: 200,
    },
    {
      datakey: 'requester.label',
      sortkey: 'requester.label',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__REQUESTER',
        defaultMessage: 'Nahlížející',
      }),
      width: 200,
    },
    {
      datakey: 'requester.contact.dataBox.identifier',
      sortkey: 'requester.contact.dataBox.identifier',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__DATA_BOX_ID',
        defaultMessage: 'Číslo datové schránky',
      }),
      width: 200,
    },
    {
      datakey: 'requester.type',
      sortkey: 'requester.type.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__REQUESTER_TYPE',
        defaultMessage: 'Typ účastníka',
      }),
      CellComponent: TableFieldCells.useSelectCellFactory(useParticipantTypes),
      width: 150,
    },
    {
      datakey: 'viewingMethod',
      sortkey: 'viewingMethod.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__VIEWING_METHOD',
        defaultMessage: 'Způsob nahlížení',
      }),
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useViewingMethods),
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__VIEWING_REQUESTS__TABLE_FIELD__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useStates),
    },
  ];

  return columns;
}
