import clsx from 'clsx';
import React from 'react';
import { FilterComponentProps, LocalDateField, Select } from '@eas/common-web';
import { useCompareOperations } from '../filter-api';
import { useStyles } from '../filter-styles';
import { useFilterOperation } from '../hooks/filter-operation-hook';

export function DisabledFilterDateCell(props: FilterComponentProps) {
  return <FilterDateCell {...props} disabled />;
}

export function DisabledFilterDateWithoutOperationCell(
  props: FilterComponentProps
) {
  return <FilterDateCell {...props} hideOperationSelect disabled />;
}

export function FilterDateWithoutOperationCell(props: FilterComponentProps) {
  return <FilterDateCell {...props} hideOperationSelect />;
}

export function FilterDateCell({
  disabled,
  value,
  onChange,
  state,
  onChangeState,
  hideOperationSelect,
}: FilterComponentProps & { hideOperationSelect?: boolean }) {
  const classes = useStyles();
  const operations = useCompareOperations();
  const { handleChangeOperation } = useFilterOperation(state, onChangeState);

  return (
    <div className={clsx(classes.root, { [classes.disabled]: disabled })}>
      {!hideOperationSelect && (
        <div className={classes.selectWrapper}>
          <Select
            source={operations}
            value={state.operation}
            onChange={handleChangeOperation}
            valueIsId={true}
            clearable={false}
            disabled={disabled}
          />
        </div>
      )}
      <div
        className={clsx({
          [classes.textWithoutSelectWrapper]: hideOperationSelect,
          [classes.textWrapper]: !hideOperationSelect,
        })}
      >
        <LocalDateField value={value} onChange={onChange} disabled={disabled} />
      </div>
    </div>
  );
}
