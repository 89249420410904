const ASSIGNED_FILE_NUMBERS = {
  title: 'Historie přidělení spisových značek',
  description: 'Přehled historie přidělení všech spisových značek.',
  actions: [
    {
      name: 'Vrátit',
      description:
        'Po kliknutí na tlačítko <b>Vrátit</b> u záznamu dojde k vrácení přidělení spisových značek všech spisů až po daný záznam (včetně). Spisy se vrací do stavu <b>Soudní spisy - Nové spisy</b>.',
    },
  ],
};

const ASSIGNING_RULES = {
  title: 'Pravidla přidělování',
  description:
    'Pravidla přidělování jsou využity při přidělování spisové značky. Ke každému soudci lze přiřadit více pravidel přidělování, nemusí mít přidělené žádné.',
  actions: [
    {
      name: 'Vytvoření pravidla',
      description: 'Vytvoření pravidla přidělování spisových značek.',
    },
    {
      name: 'Upravení pravidla',
      description:
        'Uživatel může upravovat jednotlivé atributy pravidla přidělování.',
    },
  ],
};

const ASSIGNMENT_SETTINGS = {
  title: 'Způsob přidělení spisové značky',
  description: 'Nastavení způsobu přidělení spisové značky.',
};

export const ES_FILE_NUMBERS = {
  title: 'Spisové značky',
  sections: [ASSIGNED_FILE_NUMBERS, ASSIGNING_RULES, ASSIGNMENT_SETTINGS],
};
