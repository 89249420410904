import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormCheckbox,
  FormLocalDateField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  eqFilterParams,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { DeadlineField } from '@composite/deadlines/deadline-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { OutgoingDocument } from '@models';
import { Agenda, UIElement } from '@enums';
import { useBriefSummaries } from '../../../dict-brief-summary/dict-brief-summary-api';
import { DEADLINE_SOURCE } from '../../../document/document-hooked-sources';
import { useOutgoingDocumentPermission } from '../../../document/document-permission';
import { EsFileFields } from '../../../document/fields/es-file/es-file';

export function OutgoingDocumentFields() {
  const { agendas, decisionForms, documentOutgoingDispatchStates } =
    useContext(StaticDataContext);
  const detailCtx = useContext(DetailContext);

  const { agenda, showDecision } = useFormSelector(
    (data: OutgoingDocument) => ({
      agenda: data.agenda,
      showDecision:
        data.agenda === Agenda.COURT || data.agenda === Agenda.PLENARY_OPINION,
    })
  );

  const agendaSource = useStaticListSource(agendas);
  const dispatchStateSource = useStaticListSource(
    documentOutgoingDispatchStates
  );
  const decisionFormSource = useStaticListSource(decisionForms);

  const briefSummaries = useBriefSummaries(
    agenda ? eqFilterParams({ field: 'agenda.id', value: agenda }) : {}
  );

  /**
   * Permissions
   */
  const { hasPermission } = useOutgoingDocumentPermission();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__DOCUMENT_OUTGOING__DETAIL__GENERAL_PANEL_TITLE"
          defaultMessage="Obecné"
        />
      }
    >
      <FormTextField
        name="referenceNumber"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__REFERENCE_NUMBER"
            defaultMessage="Číslo jednací"
          />
        }
        disabled={!hasPermission(UIElement.OutgoingDocument.REFERENCE_NUMBER)}
      />
      <FormSelect
        name="agenda"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__AGENDA"
            defaultMessage="Agenda"
          />
        }
        valueIsId={true}
        source={agendaSource}
        disabled={!hasPermission(UIElement.OutgoingDocument.AGENDA)}
      />
      <FormAutocomplete
        name="summary"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__SUMMARY"
            defaultMessage="Stručný obsah"
          />
        }
        autoFocus={true}
        required
        freeSolo={true}
        labelMapper={autocompleteLabelMapper}
        source={briefSummaries}
        disabled={!hasPermission(UIElement.OutgoingDocument.SUMMARY)}
      />
      <FormSelect
        name="dispatchState"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__DISPATCH_STATE"
            defaultMessage="Stav vypravení dokumentu"
          />
        }
        source={dispatchStateSource}
        valueIsId={true}
        disabled={!hasPermission(UIElement.OutgoingDocument.DISPATCH_STATE)}
      />
      <FormTextArea
        name="note"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__NOTE"
            defaultMessage="Poznámka"
          />
        }
        disabled={!hasPermission(UIElement.OutgoingDocument.NOTE)}
      />
      {detailCtx?.mode === DetailMode.VIEW && (
        <>
          <FormLocalDateTimeField
            name="createdDate"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__CREATED_DATE"
                defaultMessage="Datum vytvoření"
              />
            }
            disabled={!hasPermission(UIElement.OutgoingDocument.CREATED_DATE)}
          />
          <FormLocalDateField
            name="deliveryDate"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__DELIVERY_DATE"
                defaultMessage="Datum doručení"
              />
            }
            disabled={!hasPermission(UIElement.OutgoingDocument.DELIVERY_DATE)}
          />
        </>
      )}
      <FormTextField
        type="number"
        name="pageCount"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__PAGE_COUNT"
            defaultMessage="Počet listů"
          />
        }
        disabled={!hasPermission(UIElement.OutgoingDocument.PAGE_COUNT)}
      />
      {showDecision && (
        <>
          <FormCheckbox
            name="decision"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_OUTGOING_OUTGOING__DETAIL__FIELD_LABEL__DECISION"
                defaultMessage="Rozhodnutí"
              />
            }
            disabled={!hasPermission(UIElement.OutgoingDocument.DECISION)}
          />
          <FormSelect
            name="decisionForm"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__DECISION_FORM"
                defaultMessage="Forma rozhodnutí"
              />
            }
            valueIsId={true}
            source={decisionFormSource}
            disabled={!hasPermission(UIElement.OutgoingDocument.DECISION_FORM)}
          />
        </>
      )}
      <FormTextField
        type="number"
        name="order"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__ORDER"
            defaultMessage="Pořadí"
          />
        }
        disabled={!hasPermission(UIElement.OutgoingDocument.ORDER)}
      />
      <FormTextField
        name="barCode"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__FIELD_LABEL__BAR_CODE"
            defaultMessage="Čárový kód"
          />
        }
        disabled={!hasPermission(UIElement.OutgoingDocument.BAR_CODE)}
      />
    </FormPanel>
  );
}

export function General() {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode } = useContext(DetailContext);
  const { deadlineStates } = useContext(StaticDataContext);

  const { hasPermission } = useOutgoingDocumentPermission();

  const disabledDeadlines =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.OutgoingDocument.DEADLINES);
  const deadlineSource = hookedSources[DEADLINE_SOURCE]?.source;

  return (
    <>
      <EsFileFields />
      <OutgoingDocumentFields />

      <DeadlineField
        display="IN_DOCUMENT"
        deadlineStates={deadlineStates}
        disabled={disabledDeadlines}
        source={deadlineSource}
      />
    </>
  );
}
