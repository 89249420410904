import { useContext } from 'react';
import {
  DetailContext,
  DictionaryObject,
  Params,
  useAutocompleteSource,
  useFetch,
  useScrollableSource,
} from '@eas/common-web';
import { DictionaryAutocomplete, EsFileAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

/**
 * List all available agendas.
 */
export function useAgendas() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/agenda/list`,
    { method: 'GET', cacheResponse: true }
  );
}

/**
 * List all available court types.
 */
export function useCourtFileTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/court/type/list`,
    { method: 'GET', cacheResponse: true }
  );
}

/**
 * List all available file states.
 */
export function useStates() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.ES_FILES}/state/list`, {
    method: 'GET',
    cacheResponse: false,
  });
}

/**
 * List all available file regulation types.
 */
export function useRegulationTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/regulation/regulation-type/list`,
    { method: 'GET', cacheResponse: true }
  );
}

/**
 * List all available file regulation entity types.
 */
export function useRegulationEntityTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/regulation/type/list`,
    { method: 'GET', cacheResponse: true }
  );
}

/**
 * List all files.
 */
export function useEsFiles(params?: Params) {
  return useAutocompleteSource<EsFileAutocomplete>({
    url: `${EvidenceApiUrl.ES_FILES}/autocomplete`,
    params,
  });
}

/**
 * List all available decision forms
 */
export function useDecisionForms() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/decision-form/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available court types.
 */
export function useLegalAdviserTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/participant/legal-adviser/type/list`,
    { method: 'GET', cacheResponse: true }
  );
}

/**
 * List all participants.
 */
export function useParticipants(props?: {
  params?: Params;
  esFileId?: string;
  loadDetail?: boolean;
}) {
  const { source } = useContext(DetailContext);
  const { params, esFileId, loadDetail } = props || {};

  const url = `${EvidenceApiUrl.ES_FILES}/${
    esFileId ?? source?.data?.id
  }/participant`;

  return useAutocompleteSource<DictionaryAutocomplete>({
    url: `${url}/autocomplete`,
    params,
    apiUrl: loadDetail ? url : undefined,
  });
}

/**
 * List all participants contacts.
 */
export function useParticipantsContacts(props?: {
  params?: Params;
  esFileId?: string;
  loadDetail?: boolean;
}) {
  const { source } = useContext(DetailContext);
  const { params, esFileId, loadDetail } = props || {};

  const url = `${EvidenceApiUrl.SUBJECT_CONTACTS}/${
    esFileId ?? source?.data?.id
  }/participant`;

  return useAutocompleteSource<DictionaryAutocomplete>({
    url: `${url}/autocomplete`,
    params,
    apiUrl: loadDetail ? EvidenceApiUrl.SUBJECT_CONTACTS : undefined,
  });
}

/**
 * List all participant types.
 */
export function useParticipantTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/participant/type/list`,
    { method: 'GET', cacheResponse: true }
  );
}

/**
 * List all available es files.
 */
export function useEsFileListSource(params?: Params) {
  return useScrollableSource({
    url: `${EvidenceApiUrl.ES_FILES}/list`,
    params,
  });
}

/**
 * List all available disciplinary proceedings proposers.
 */
export function useDisciplinaryProceedingsProposers() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/disciplinary-proceedings/proposer/list`,
    { method: 'GET', cacheResponse: true }
  );
}

/**
 * List all available outgoing document anonymization requests
 */
export function useAnonymizationRequests() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ES_FILES}/anonymization-request/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
