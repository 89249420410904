import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';

export function useJobTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'CREATE_DOCUMENT', name: 'Vytvoření dokumentu' },
    { id: 'CREATE_RECORD', name: 'Vytvoření spisu' },
    { id: 'CREATE_DOCUMENT_COMPONENT', name: 'Vytvoření přílohy dokumentu' },
    { id: 'DELETE_DOCUMENT_COMPONENT', name: 'Smazání přílohy dokumentu' },
  ]);
}

export function useEntityTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'DOCUMENT', name: 'Dokument' },
    { id: 'ES_FILE', name: 'Spis' },
    { id: 'DOCUMENT_ATTACHMENT', name: 'Příloha dokumentu' },
  ]);
}

export function useJobStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'PENDING', name: 'Čekající' },
    { id: 'RUNNING', name: 'Běžící' },
    { id: 'SUCCESS', name: 'Úspěšně ukončena' },
    { id: 'FAILED', name: 'Selžena' },
    { id: 'FAILED_NO_RETRY', name: 'Selžena, bez opakování' },
    { id: 'CANCELLED', name: 'Zrušena' },
  ]);
}
