import React from 'react';
import { useIntl } from 'react-intl';
import {
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  eqFilterParams,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import {
  autocompleteLabelMapper,
  multipleAutocompleteColumnMapper,
} from '@components/form/misc/label-mappers';
import { SprFile } from '@models';
import { Agenda, EvidenceBrowserUrl } from '@enums';
import { useSettlementMethods } from '../../dict-settlement-method/dict-settlement-method-api';
import { useSprColumns } from '../spr-file-columns';

export function useColumns(): TableColumn<SprFile>[] {
  const intl = useIntl();
  const {
    columnDeliveryDate,
    columnIncomingNumber,
    columnSolverUserRoles,
    columnSubmissionContent,
    columnWriters,
  } = useSprColumns();
  const useSprSettlementMethods = () =>
    useSettlementMethods(
      eqFilterParams({ field: 'agenda.id', value: Agenda.SPR })
    );

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__SPR_FILES__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.SPR_FILES_IN_PROGRESS}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    columnDeliveryDate,
    columnIncomingNumber,
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__SPR_FILES__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 120,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    columnSubmissionContent,
    columnWriters,
    columnSolverUserRoles,
    {
      datakey: 'settlementMethods',
      sortkey: 'settlementMethods.concatenated',
      filterkey: 'settlementMethods.id',
      name: intl.formatMessage({
        id: 'ES__SPR_FILES__TABLE__COLUMN__SETTLEMENT_METHODS',
        defaultMessage: 'Způsob vyřízení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: multipleAutocompleteColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSprSettlementMethods,
        autocompleteLabelMapper
      ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'settlementDate',
      name: intl.formatMessage({
        id: 'ES__SPR_FILES__TABLE__COLUMN__SETTLEMENT_DATE',
        defaultMessage: 'Datum vyřízení',
      }),
      width: 150,
      CellComponent: TableCells.DateCell,
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
    },
  ];
}
