import clsx from 'clsx';
import { noop } from 'lodash';
import React, { Ref, forwardRef, useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  Dialog,
  DialogHandle,
  FileField,
  FileRef,
  FormCheckbox,
  FormContext,
  FormCustomField,
  FormTextArea,
  FormTextField,
  ListSource,
  TableField,
  abortableFetch,
  useEventCallback,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DictionaryObject, Draft } from '@models';
import { EvidenceApiUrl } from '@enums';

export const useStyles = makeStyles(() => ({
  uploadButton: {
    boxShadow: '0 0 0',
    minWidth: 100,
    height: 22,
    '& p': {
      display: 'block',
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      boxShadow: '0 0 0',
    },
  },
  marginRight: {
    marginRight: '2pt',
  },
}));

export function DraftDialog() {
  const classes = useStyles();

  const { templates } = useContext(StaticDataContext);
  const { setFieldValue } = useContext(FormContext);

  const templateSource = useStaticListSource(templates);
  const templateRef = useRef<DialogHandle>(null);

  const { file } = useFormSelector((data: Draft) => ({ file: data.file }));

  const handleTemplateChange = useEventCallback((value) =>
    setFieldValue('file', value)
  );

  const handleOpenTemplateDialog = useEventCallback(() => {
    templateRef.current?.open();
  });

  return (
    <>
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ES__DRAFTS__FIELD_LABEL__NAME"
            defaultMessage="Název přílohy"
          />
        }
        required
        autoFocus={true}
      />
      <FormTextArea
        name="note"
        label={
          <FormattedMessage
            id="ES__DRAFTS__FIELD_LABEL__NOTE"
            defaultMessage="Poznámka"
          />
        }
      />
      <FormCheckbox
        name="finalVersion"
        label={
          <FormattedMessage
            id="ES__DRAFTS__FIELD_LABEL__FINAL_VERSION"
            defaultMessage="Konečná verze"
          />
        }
        disabled={true}
      />
      <FormCustomField
        label={
          <FormattedMessage
            id="ES__DRAFTS__FIELD_LABEL__FILE"
            defaultMessage="Soubor"
          />
        }
      >
        <MuiButton
          size="small"
          variant="contained"
          component="label"
          onClick={handleOpenTemplateDialog}
          classes={{ root: clsx(classes.uploadButton, classes.marginRight) }}
        >
          <Typography>
            <FormattedMessage
              id="ES__DRAFTS__FILE__TEMPLATE_BTN"
              defaultMessage="Ze šablony"
            />
          </Typography>
        </MuiButton>
        <TemplateDialog
          ref={templateRef}
          source={templateSource}
          onChange={handleTemplateChange}
        />
        <FileField
          value={file}
          onChange={(newFile) => setFieldValue('file', newFile)}
          classes={{ uploadButton: classes.uploadButton }}
          uploadLabel={
            <FormattedMessage
              id="ES__DRAFTS__FILE__STORAGE_BTN"
              defaultMessage="Z úložiště"
            />
          }
        />
      </FormCustomField>
    </>
  );
}

const TemplateDialog = forwardRef(function TemplateDialog(
  {
    source,
    onChange,
  }: {
    source: ListSource<DictionaryObject>;
    onChange: (value: FileRef | null) => void;
  },
  ref: Ref<DialogHandle>
) {
  const intl = useIntl();
  const detailCtx = useContext(DetailContext);

  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<string>();

  const handleSubmit = useEventCallback(async () => {
    if (template) {
      setLoading(true);

      const fileRef = await abortableFetch(
        `${EvidenceApiUrl.ES_FILES}/${detailCtx?.source.data.id}/draft?template=${template}`,
        {
          method: 'POST',
        }
      ).json();

      if (fileRef !== undefined) {
        onChange(fileRef);
      } else {
        onChange(null);
      }

      setLoading(false);
    }
  });

  const handleSelect = useEventCallback((id: string) => {
    setTemplate(id);
  });

  return (
    <Dialog
      title={
        <FormattedMessage
          id="ES__DRAFTS__TEMPLATE_DIALOG__TITLE"
          defaultMessage="Šablony"
        />
      }
      ref={ref}
      onConfirm={handleSubmit}
      loading={loading}
    >
      {() => (
        <div style={{ width: 600 }}>
          <TableField
            value={source.items}
            onChange={noop}
            columns={[
              {
                name: intl.formatMessage({
                  id: 'ES__TEMPLATE_DIALOG__COLUMN__NAME',
                  defaultMessage: 'Název',
                }),
                datakey: 'name',
                width: 500,
              },
            ]}
            showToolbar={false}
            onSelect={(_, i) => handleSelect(source.items[i].id)}
          />
        </div>
      )}
    </Dialog>
  );
});
