import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RedirectAction } from '@composite/redirect/dialog-redirect';
import { Agenda } from '@enums';

const OPTION_COURT_FILE = (
  <FormattedMessage
    id="REDIRECT__OPTION__COURT_FILES"
    defaultMessage="Pokračovat v evidenci: Nové soudní spisy"
  />
);

const SOURCE_COURT_FILE = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_COURT_FILE,
};

const DEST_COURT_FILE = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_COURT_FILE,
};

const OPTION_SPR = (
  <FormattedMessage
    id="REDIRECT__OPTION__SPR"
    defaultMessage="Pokračovat v evidenci: Nové SPR spisy"
  />
);

const SOURCE_SPR = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_SPR,
};

const DEST_SPR = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_SPR,
};

const OPTION_PLENARY_OPINION = (
  <FormattedMessage
    id="REDIRECT__OPTION__PLENARY_OPINION"
    defaultMessage="Pokračovat v evidenci: Nové spisy stanoviska pléna"
  />
);

const SOURCE_PLENARY_OPINION = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_PLENARY_OPINION,
};

const DEST_PLENARY_OPINION = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_PLENARY_OPINION,
};

const OPTION_DISCIPLINARY_PROCEEDINGS = (
  <FormattedMessage
    id="REDIRECT__OPTION_DISCIPLINARY_PROCEEDINGS"
    defaultMessage="Pokračovat v evidenci: Nové spisy kárného řízení"
  />
);

const SOURCE_DISCIPLINARY_PROCEEDINGS = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_DISCIPLINARY_PROCEEDINGS,
};

const DEST_DISCIPLINARY_PROCEEDINGS = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_DISCIPLINARY_PROCEEDINGS,
};

const OPTION_INF = (
  <FormattedMessage
    id="REDIRECT__OPTION__INF"
    defaultMessage="Pokračovat v evidenci: Nové INF spisy"
  />
);

const SOURCE_INF = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_INF,
};

const DEST_INF = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_INF,
};

export function getOptions(source?: Agenda, dest?: Agenda) {
  const options = [];

  switch (source) {
    case Agenda.COURT:
      options.push(SOURCE_COURT_FILE);
      break;
    case Agenda.DISCIPLINARY_PROCEEDINGS:
      options.push(SOURCE_DISCIPLINARY_PROCEEDINGS);
      break;
    case Agenda.INF:
      options.push(SOURCE_INF);
      break;
    case Agenda.PLENARY_OPINION:
      options.push(SOURCE_PLENARY_OPINION);
      break;
    case Agenda.SPR:
      options.push(SOURCE_SPR);
      break;
  }

  switch (dest) {
    case Agenda.COURT:
      options.push(DEST_COURT_FILE);
      break;
    case Agenda.DISCIPLINARY_PROCEEDINGS:
      options.push(DEST_DISCIPLINARY_PROCEEDINGS);
      break;
    case Agenda.INF:
      options.push(DEST_INF);
      break;
    case Agenda.PLENARY_OPINION:
      options.push(DEST_PLENARY_OPINION);
      break;
    case Agenda.SPR:
      options.push(DEST_SPR);
      break;
  }

  return options;
}
