import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  ActionButtonComponentProps,
  ActionButtonHandle,
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  FieldFilter,
  PagedTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarButtons,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { EsFile, EsFileRegulation } from '@models';
import { RegulationEntityType, RegulationType } from '@enums';
import {
  CompleteButton,
  useAddRegulationDialog,
} from './dialog-actions/add-regulation-hook';
import { useDeleteRegulationDialog } from './dialog-actions/delete-regulation-hook';
import { useEditRegulationDialog } from './dialog-actions/edit-regulation-hook';
import { RegulationDialog } from './regulation-dialog';
import { RegulationFieldProps } from './regulation-field';
import { useValidationSchema } from './regulation-schema';

export const RegulationToolbar = forwardRef(function RegulationToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    regulationEntityTypes,
    regulationTypes,
    disabled,
  }: TableFieldToolbarProps & RegulationFieldProps & { disabled: boolean },
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();

  const intl = useIntl();

  const { source: detailSource } =
    useContext<DetailHandle<EsFile>>(DetailContext);
  const { source: tableSource } = useContext(PagedTableFieldContext);
  const regulations = tableSource.items;

  const validationSchema = useValidationSchema();

  const { callApi: addRegulationApiCall } = useAddRegulationDialog();
  const { callApi: editRegulationApiCall } = useEditRegulationDialog();
  const { callApi: deleteRegulationApiCall } = useDeleteRegulationDialog();

  useEffect(
    () => {
      setSelectedIndex(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailSource]
  );

  const selectedRegulation =
    selectedIndex != undefined ? regulations?.[selectedIndex] : {};

  const selectedRegulationObject = {
    regulations: [
      {
        ...selectedRegulation,
        sectionOrArticlePart:
          selectedRegulation.type === RegulationEntityType.ARTICLE
            ? selectedRegulation.article
            : selectedRegulation.sectionSign,
      },
    ],
  };

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_REGULATION"
          apiCall={addRegulationApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__ADD_REGULATION',
            defaultMessage: 'Přidat předpis',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__ADD_REGULATION__TITLE',
            defaultMessage: 'Předpis',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__ADD_REGULATION__TEXT',
            defaultMessage: 'Vyplňte informace o předpisu.',
          })}
          dialogActions={(loading) => [
            <CompleteButton key="complete_button" loading={loading} />,
          ]}
          ButtonComponent={({ label, onClick }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__REGULATIONS_TOOLBAR__ADD_REGULATION__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formInitialValues={{
            regulations: [
              {
                regulationType:
                  RegulationType.CONSTITUTIONAL_LAWS_AND_INTERNATIONAL_TREATIES,
                type: RegulationEntityType.PARAGRAPH,
              } as EsFileRegulation,
            ],
          }}
          FormFields={() => (
            <RegulationDialog
              singleRow={false}
              regulationEntityTypes={regulationEntityTypes}
              regulationTypes={regulationTypes}
              disabled={disabled}
            />
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          dialogWidth={1300}
          formValidationSchema={validationSchema}
        />

        <DetailActionButton
          ref={ref}
          promptKey="EDIT_REGULATION"
          apiCall={(id, formData) =>
            editRegulationApiCall(
              id,
              regulations![selectedIndex!].id,
              formData.regulations[0]
            )
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__EDIT_REGULATION',
            defaultMessage: 'Editovat předpis',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__EDIT_REGULATION__TITLE',
            defaultMessage: 'Předpis',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__EDIT_REGULATION__TEXT',
            defaultMessage: 'Upravte informace o předpisu.',
          })}
          dialogWidth={1300}
          FormFields={() => (
            <RegulationDialog
              singleRow={false}
              regulationEntityTypes={regulationEntityTypes}
              regulationTypes={regulationTypes}
              disabled={disabled}
            />
          )}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={selectedIndex === undefined}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__REGULATIONS_TOOLBAR__EDIT_REGULATION__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formInitialValues={selectedRegulationObject}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />

        <DetailActionButton
          promptKey="COPY_REGULATION"
          apiCall={(id, formData) =>
            addRegulationApiCall(id, {
              regulations: [formData.regulations[0]],
            })
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__COPY_REGULATION',
            defaultMessage: 'Kopírovat předpis',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__COPY_REGULATION__TITLE',
            defaultMessage: 'Předpis',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__REGULATIONS_TOOLBAR__COPY_REGULATION__TEXT',
            defaultMessage: 'Upravte informace o předpisu.',
          })}
          dialogWidth={1300}
          FormFields={() => (
            <RegulationDialog
              singleRow={false}
              regulationEntityTypes={regulationEntityTypes}
              regulationTypes={regulationTypes}
              disabled={disabled}
            />
          )}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={FileCopyIcon}
              show={true}
              disabled={selectedIndex === undefined}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__REGULATIONS_TOOLBAR__COPY_REGULATION__BUTTON',
                  defaultMessage: 'Kopírovat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formInitialValues={selectedRegulationObject}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />

        <DetailActionButton
          promptKey="DELETE_REGULATION"
          apiCall={(id) =>
            deleteRegulationApiCall(id, regulations![selectedIndex!].id)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__COURT_FILES__REGULATIONS_TOOLBAR__DELETE_REGULATION',
            defaultMessage: 'Odstranit předpis',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__COURT_FILES__DIALOG_DELETE_REGULATION__TITLE',
            defaultMessage: 'Předpis',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__COURT_FILES__DIALOG_DELETE_REGULATION__TEXT',
            defaultMessage: 'Skutečně chcete odstranit předpis?',
          })}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={selectedIndex === undefined}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__REGULATIONS_TOOLBAR__DELETE_REGULATION__BUTTON',
                  defaultMessage: 'Smazat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        {/* <TableFieldToolbarButtons.ColumnButton /> */}
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          filtersFields={[
            {
              label: intl.formatMessage({
                id: 'ES__REGULATIONS_TOOLBAR__FILTER__TEXT',
                defaultMessage: 'Předpis',
              }),
              datakey: 'label',
              filterkey: 'label',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
          ]}
          source={tableSource}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps &
    RegulationFieldProps & {
      ref: Ref<ActionButtonHandle>;
      disabled: boolean;
    }
) => ReactElement;
