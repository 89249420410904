import clsx from 'clsx';
import React, {
  ComponentType,
  DependencyList,
  useEffect,
  useState,
} from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardDivider } from '@modules/dashboard/divider';
import { ChartView, EvidenceApiUrl } from '@enums';
import { useSupportedExportTypes } from '../statistics-api';
import { ChartSwitch } from './chart-switch';
import { Export } from './export';

const useStyles = makeStyles({
  card: {
    width: '100%',
    borderRadius: 16,
    margin: '0 0 24px',
  },
  content: {
    padding: '32px 0px 16px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 24px',
  },
  divider: {
    padding: '0px 32px',
  },
});

export function StatisticCard<PARAMS>({
  url,
  title,
  params,
  availableViews = [],
  Header,
  Visualisations,
  deps,
}: {
  url: EvidenceApiUrl;
  title: string;
  params?: PARAMS;
  availableViews?: { view: ChartView; tooltip?: string }[];
  Header?: ComponentType;
  Visualisations?: { [key: string]: ComponentType };
  deps?: DependencyList;
}) {
  const classes = useStyles();
  const exportTypes = useSupportedExportTypes(url);

  const [view, setView] = useState(ChartView.TABLE);

  const Visualisation = Visualisations?.[view];

  useEffect(() => {
    setView(ChartView.TABLE);
  }, [deps]);

  return (
    <Card className={clsx(classes.card)}>
      <div className={classes.content}>
        <div className={classes.divider}>
          <DashboardDivider text={title} />
        </div>
        {Header && <Header />}
        {Visualisation && <Visualisation />}
        <div className={classes.footer}>
          <Export
            url={url}
            params={params}
            supportedExportTypes={exportTypes.items}
          />
          {availableViews?.length > 1 && (
            <ChartSwitch
              availableViews={availableViews}
              currentView={view}
              setCurrentView={setView}
            />
          )}
        </div>
      </div>
    </Card>
  );
}
