import React from 'react';
import { HistoryFieldType } from '@enums';
import { BooleanHistoryField } from './boolean-history-field';
import { LabeledHistoryField } from './labeled-history-field';
import { LocalDateHistoryField } from './local-date-history-field';
import { LocalDateTimeHistoryField } from './local-date-time-history-field';
import { StringHistoryField } from './string-history-field';

export function SimpleLogValue({
  value,
  ...props
}: {
  value?: string | boolean | { id: string; label: string };
  type: HistoryFieldType;
  position: 'before' | 'after';
}) {
  switch (props.type) {
    case HistoryFieldType.STRING:
    case HistoryFieldType.COLLECTION:
      return <StringHistoryField {...props} value={value as string} />;
    case HistoryFieldType.BOOLEAN:
      return <BooleanHistoryField {...props} value={value as boolean} />;
    case HistoryFieldType.LABELED:
      return (
        <LabeledHistoryField
          {...props}
          value={value as { id: string; label: string }}
        />
      );
    case HistoryFieldType.LOCAL_DATE:
      return <LocalDateHistoryField {...props} value={value as string} />;
    case HistoryFieldType.LOCAL_DATE_TIME:
      return <LocalDateTimeHistoryField {...props} value={value as string} />;

    default:
      return null;
  }
}
