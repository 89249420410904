import React, { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { useAutoCollapse } from '@components/form/misc/auto-collapse-hook';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { Proposer } from '@models';
import { useProposerColumns } from './proposer-columns';
import { ProposerToolbar } from './proposer-toolbar';

export interface ProposerFieldProps {
  disabled: boolean;
  source?: ScrollableSource<Proposer>;
}

export function ProposerField({ disabled, source }: ProposerFieldProps) {
  const { panelRef } = useAutoCollapse(source?.items ?? []);

  const columns = useProposerColumns();

  const tableRef = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return <ProposerToolbar {...props} ref={editRef} disabled={disabled} />;
      },
    [disabled]
  );

  if (!source) {
    return <> Missing PROPOSER_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__PROPOSERSS__PANEL_TITLE"
          defaultMessage="Navrhovatelé"
        />
      }
      expandable={true}
      ref={panelRef}
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        ref={tableRef}
        source={source}
        showDetailBtnCond={() => false}
        columns={columns}
        onRowClick={(_, index) => {
          if (!disabled) {
            tableRef.current?.setSelectedIndex(index);
            editRef.current?.executeAction();
          }
        }}
        maxRows={10}
        ToolbarComponent={ToolbarComponent}
        disabled={disabled}
      />
    </FormPanel>
  );
}
