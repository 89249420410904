import { useEffect, useState } from 'react';
import { DomainObject, EvidenceProps } from '@eas/common-web';

export function useEvidence<OBJECT extends DomainObject>({
  detailProps,
  ...props
}: EvidenceProps<OBJECT>) {
  const subtitle = detailProps?.toolbarProps?.subtitle;
  const title = detailProps?.toolbarProps?.title;
  const { width } = useWindowWidth();

  const deriveSubtitle = (item: OBJECT | null) =>
    typeof subtitle === 'string'
      ? subtitle
      : typeof subtitle === 'function'
      ? subtitle(item)
      : undefined;

  const deriveTitle = (item: OBJECT | null) =>
    typeof title === 'string'
      ? title
      : typeof title === 'function'
      ? title(item)
      : undefined;

  const documentTitle = (item: OBJECT | null): string => {
    const derivedSubtitle = deriveSubtitle(item);
    const derivedTitle = deriveTitle(item);

    return derivedSubtitle !== undefined && derivedSubtitle.length > 0
      ? `${derivedSubtitle} - ${detailProps?.toolbarProps?.title}`
      : derivedTitle ?? '';
  };

  return {
    ...props,
    detailProps: {
      ...detailProps,
      documentTitle,
    },
    switcherProps: {
      midPoint: 0.4 * width,
    },
  };
}

function getWindowWidth() {
  const { innerWidth: width } = window;

  return {
    width,
  };
}

export default function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}
