import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import SendIcon from '@material-ui/icons/Send';
import {
  ApiFilterOperation,
  EvidenceContext,
  Filter,
  FormCustomField,
  TableActionButton,
  TableSelectedContext,
  TextField,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { Agenda, EvidenceApiUrl } from '@enums';

interface RegistryFilesCounts {
  courtFilesCount?: string;
  plenaryOpinionsCount?: string;
  infFilesCount?: string;
  disciplinaryProceedingsCount?: string;
  sprFilesCount?: string;
}

export function useReturnDialog(agenda: Agenda, filters?: Filter[]) {
  const { tableSource } = useContext(EvidenceContext);
  const { selected } = useContext(TableSelectedContext);

  const callApi = useEventCallback((params, selected, test = true) =>
    abortableFetch(`${EvidenceApiUrl.ES_FILES}/return?test=${test}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        ...params,
        filters: [
          ...(filters ?? params?.filters ?? []),
          ...(selected?.length
            ? [
                {
                  operation: ApiFilterOperation.IDS,
                  ids: selected,
                },
              ]
            : []),
        ],
      }),
    })
  );

  const FormFields = () => {
    const [data, setData] = useState<RegistryFilesCounts>();

    useEffect(() => {
      const testReturn = async () => {
        const response = await callApi(
          tableSource.getParams(),
          selected
        ).json();

        setData(response);
      };

      testReturn();
    }, []);

    return (
      <>
        {agenda === Agenda.COURT && (
          <FormCustomField label="Soudní">
            <TextField value={data?.courtFilesCount} disabled />
          </FormCustomField>
        )}
        {agenda === Agenda.INF && (
          <FormCustomField label="INF">
            <TextField value={data?.infFilesCount} disabled />
          </FormCustomField>
        )}
        {agenda === Agenda.SPR && (
          <FormCustomField label="SPR">
            <TextField value={data?.sprFilesCount} disabled />
          </FormCustomField>
        )}
        {agenda === Agenda.DISCIPLINARY_PROCEEDINGS && (
          <FormCustomField label="Karné řízení">
            <TextField value={data?.disciplinaryProceedingsCount} disabled />
          </FormCustomField>
        )}
        {agenda === Agenda.PLENARY_OPINION && (
          <FormCustomField label="Stanovisko pléna">
            <TextField value={data?.plenaryOpinionsCount} disabled />
          </FormCustomField>
        )}
      </>
    );
  };

  const ReturnButton = () => {
    const intl = useIntl();

    return (
      <TableActionButton
        promptKey="RETURN_FILES"
        apiCall={(params, selected) => callApi(params, selected, false)}
        buttonDisabled={false}
        buttonLabel={<SendIcon />}
        buttonTooltip={intl.formatMessage({
          id: 'ES__ES_FILES__ACTIONBAR__RETURN_FILES',
          defaultMessage: 'Vrátit spisy do spisovny',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__ES_FILES__ACTIONBAR__RETURN_FILES__TITLE',
          defaultMessage: 'Vrácení spisů do spisovny',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__ES_FILES__ACTIONBAR__RETURN_FILES__TEXT',
          defaultMessage:
            'Níže jsou uvedeny počty spisů, které budou vráceny do spisovny. Pokud chcete dokončit akci, klikněte na POTVRDIT.',
        })}
        dialogDisableBackdrop={true}
        FormFields={FormFields}
        dialogWidth={450}
      />
    );
  };

  return { ReturnButton };
}
