import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  RemoteTableFieldContext,
  SnackbarContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { ErrorObject } from '@models';
import { Messages } from '@enums';
import { useAddUserRoleDialog } from './dialog-actions/add-user-role-hook';
import { useDeleteUserRoleDialog } from './dialog-actions/delete-user-role-hook';
import { useEditUserRoleDialog } from './dialog-actions/edit-user-role-hook';
import { UserRoleDialog } from './user-role-dialog';
import { useValidationSchema } from './user-role-schema';

export const UserRoleToolbar = forwardRef(function UserRolesToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
  }: TableFieldToolbarProps & { disabled: boolean },
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();

  const intl = useIntl();

  const { showSnackbar } = useContext(SnackbarContext);
  const detailCtx = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const userRoles = tableSource.items;

  const validationSchema = useValidationSchema();

  const { callApi: addUserRolesApiCall } = useAddUserRoleDialog();
  const { callApi: editUserRoleApiCall } = useEditUserRoleDialog();
  const { callApi: deleteUserRoleApiCall } = useDeleteUserRoleDialog();

  const selectedUserRole =
    selectedIndex !== undefined ? userRoles?.[selectedIndex] : {};

  const isSelected = selectedIndex !== undefined;

  useEffect(
    () => {
      setSelectedIndex(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailCtx?.source]
  );

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_USER_ROLE"
          apiCall={addUserRolesApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE',
            defaultMessage: 'Přidat uživatelskou roli',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE__TITLE',
            defaultMessage: 'Uživatelská role',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE__TEXT',
            defaultMessage: 'Vyplňte informace o uživatelské roli.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__USER_ROLES_TOOLBAR__ADD_USER_ROLE__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={() => (
            <DetailContext.Provider
              value={{ ...detailCtx, mode: DetailMode.NEW }}
            >
              <UserRoleDialog />
            </DetailContext.Provider>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          dialogWidth={600}
          formValidationSchema={validationSchema}
          onError={async (err: ErrorObject<'USER_ROLE_ALREADY_EXISTS'>) => {
            if (err.code) {
              const error =
                Messages.Role.ASSIGN_ROLE?.[err.code] ??
                Messages.Role.ASSIGN_ROLE.ERROR;
              showSnackbar(...error);
            }
          }}
        />

        <DetailActionButton
          ref={ref}
          promptKey="EDIT_USER_ROLE"
          apiCall={(_, values) =>
            editUserRoleApiCall(selectedUserRole.id, values)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__EDIT_USER_ROLE',
            defaultMessage: 'Editovat uživatelskou roli',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__EDIT_USER_ROLE__TITLE',
            defaultMessage: 'Uživatelská role',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__EDIT_USER_ROLE__TEXT',
            defaultMessage: 'Upravte informace o uživatelské roli.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__USER_ROLES_TOOLBAR__EDIT_USER_ROLE__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          dialogWidth={600}
          FormFields={UserRoleDialog}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formInitialValues={selectedUserRole}
          formValidationSchema={validationSchema}
        />

        <DetailActionButton
          promptKey="DELETE_USER_ROLE"
          apiCall={() => deleteUserRoleApiCall(selectedUserRole.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__DELETE_USER_ROLE',
            defaultMessage: 'Odstranit uživatelskou roli',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__DELETE_USER_ROLE__TITLE',
            defaultMessage: 'Uživatelská role',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__USER_ROLES_TOOLBAR__DELETE_USER_ROLE__TEXT',
            defaultMessage: 'Skutečně chcete odstranit uživatelskou roli?',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__USER_ROLES_TOOLBAR__DELETE_USER_ROLE__BUTTON',
                  defaultMessage: 'Smazat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    ref: Ref<ActionButtonHandle>;
    disabled: boolean;
  }
) => ReactElement;
