import React, { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { useAutoCollapse } from '@components/form/misc/auto-collapse-hook';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { LegalAdviser } from '@models';
import { useLegalAdviserColumns } from './legal-adviser-columns';
import { LegalAdviserToolbar } from './legal-adviser-toolbar';

export interface LegalAdviserFieldProps {
  disabled: boolean;
  source?: ScrollableSource<LegalAdviser>;
}

export function LegalAdviserField({
  disabled,
  source,
}: LegalAdviserFieldProps) {
  const { panelRef } = useAutoCollapse(source?.items ?? []);

  const columns = useLegalAdviserColumns();

  const tableRef = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <LegalAdviserToolbar {...props} ref={editRef} disabled={disabled} />
        );
      },
    [disabled]
  );

  if (!source) {
    return <> Missing LEGAL_ADVISERS_SOURCE </>;
  }

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__LEGAL_ADVISERS__PANEL_TITLE"
            defaultMessage="Právní zástupci"
          />
        }
        ref={panelRef}
        expandable={true}
        summary={<FormTableCount source={source} />}
      >
        <RemoteTableField
          ref={tableRef}
          disabled={disabled}
          columns={columns}
          source={source}
          maxRows={15}
          ToolbarComponent={ToolbarComponent}
          onRowClick={(_, index) => {
            if (!disabled) {
              tableRef.current?.setSelectedIndex(index);
              editRef.current?.executeAction();
            }
          }}
          highlightSelectedRow={true}
          showDetailBtnCond={() => false}
        />
      </FormPanel>
    </>
  );
}
