import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DecisionText } from '@composite/decision-text/decision-text';
import { AnonymizedDecisionActions } from '../anonymized-decision-actions';

export function AbstractFields() {
  return (
    <>
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__TEXT_ABSTRACT"
            defaultMessage="Abstrakt - plné znění"
          />
        }
        name="text.abstract"
        enableHighlighting={true}
      />
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__ANONYMIZED_TEXT_ABSTRACT"
            defaultMessage="Abstrakt - anonymizováno"
          />
        }
        name="anonymizedText.abstract"
        Before={<AnonymizedDecisionActions fieldName="abstract" />}
        enableHighlighting={true}
      />
    </>
  );
}
