import { useHistoryDescriptors as useDeadlineHistoryDescriptors } from '@composite/deadlines/deadline-history-descriptors';
import { useHistoryDescriptors as useDispatchHistoryDescriptors } from '@composite/dispatches/dispatch-history-descriptors';
import { HistoryFieldDescriptor } from '@composite/history/history-types';
import { useHistoryDescriptors as useCommonDocumentHistoryDescriptors } from '../document/document-history-descriptors';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const documentDescriptors = useCommonDocumentHistoryDescriptors();
  const dispatchDescriptors = useDispatchHistoryDescriptors();
  const deadlineDescriptors = useDeadlineHistoryDescriptors();

  return [
    ...documentDescriptors,
    ...dispatchDescriptors,
    ...deadlineDescriptors,
    {
      key: 'deliveryDate',
      label: 'Datum doručení',
    },
    {
      key: 'dispatchState',
      label: 'Stav vypravení',
    },
    {
      key: 'decision',
      label: 'Rozhodnutí',
    },
    {
      key: 'decisionForm',
      label: 'Forma rozhodnutí',
    },
    {
      key: 'deadlines',
      label: 'Lhůty',
    },
    {
      key: 'administrativeDecision',
      label: 'Správní rozhodnutí',
    },
    {
      key: 'dispatches',
      label: 'Vypravení',
    },
    {
      key: 'linkedDecision',
      label: 'Navázné rozhodnutí',
    },
  ];
}
