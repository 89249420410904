import { useEffect, useState } from 'react';
import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { deriveQueryParams } from './statistics-utils';

export function useStatistic<PARAMS, RESULT>({
  url,
  initialParams,
}: {
  url: EvidenceApiUrl;
  initialParams: PARAMS;
}) {
  const [params, setParams] = useState<PARAMS>(initialParams);
  const [data, setData] = useState<RESULT>();

  const loadData = async (params: PARAMS) => {
    const data = await abortableFetch(
      `${url}/data${deriveQueryParams(params)}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    ).json();

    setData(data);
  };

  useEffect(() => {
    loadData(params);
  }, []);

  const handleSetParams = useEventCallback((newParams: PARAMS) => {
    setParams(newParams);
    loadData(newParams);
  });

  return { data, params, setParams: handleSetParams };
}

export function useStatisticWithoutParams<RESULT>({
  url,
}: {
  url: EvidenceApiUrl;
}) {
  const [data, setData] = useState<RESULT>();

  const loadData = async () => {
    const data = await abortableFetch(`${url}/data`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).json();

    setData(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return { data };
}
