import { cloneDeep, groupBy, keys } from 'lodash';
import { ApiFilterOperation, TableFilter } from '@eas/common-web';
import { FilterState } from './filter-types';

const NON_NESTED = 'NON_NESTED';

/**
 * Derives filter states from supplied/constructed filters.
 */
export function deriveFiltersState(
  filters: TableFilter[],
  defaultState?: FilterState[]
) {
  return filters.map((filter) => {
    const defaults = defaultState?.find((def) => def.id === filter.datakey);

    return {
      id: filter.datakey,
      label: filter.label,
      field: filter.filterkey,
      path: filter.filterPath,
      value: defaults?.value ?? null,
      object: defaults?.object ?? null,
      visible: defaults?.visible ?? true,
      operation: (defaults?.operation ??
        filter.operation) as ApiFilterOperation,
    } as FilterState;
  });
}

function removeDummy(filters: FilterState[]) {
  filters.forEach((e) => {
    delete e.confirmed;
    delete e.visible;
    delete e.id;
  });
}

/**
 * Removes empty filters
 */
export function normalizeFilterState(state: FilterState[]) {
  const filters = cloneDeep(state);

  let extenders = filters?.[0]?.filters?.[0]?.filters ?? [];
  let reducers = filters?.[0]?.filters?.[1]?.filters ?? [];

  extenders = extenders.filter((f) => f.visible);
  reducers = reducers.filter((f) => f.visible);

  // fix mess
  removeDummy(extenders);
  extenders.forEach((e) => {
    removeDummy(e.filters ?? []);
  });

  // fix all NOT filters
  extenders.forEach((extender, extenderIndex) => {
    extender.filters?.forEach((filter, filterIndex) => {
      if (filter.operation === ApiFilterOperation.NOT) {
        extenders[extenderIndex].filters![filterIndex] = {
          operation: ApiFilterOperation.NOT,
          path: filter.path,
          filters: [
            {
              field: filter.field,
              operation: ApiFilterOperation.CONTAINS,
              value: filter.value,
              object: filter.object,
            },
          ],
        };
      }
    });
  });

  // fix all NESTED filters
  extenders.forEach((extender) => {
    const groupedFilters = groupBy(
      extender.filters,
      (e) => e?.path ?? NON_NESTED
    );

    extender.filters = [];
    keys(groupedFilters).forEach((path) => {
      if (path === NON_NESTED) {
        extender.filters?.push(...(groupedFilters?.[NON_NESTED] ?? []));
      } else {
        extender.filters?.push({
          operation: ApiFilterOperation.NESTED,
          path,
          filter: {
            operation: ApiFilterOperation.AND,
            filters: groupedFilters[path],
          },
        });
      }
    });
  });

  if (extenders.length === 0 && reducers.length === 0) {
    return [];
  } else if (extenders.length > 0 && reducers.length === 0) {
    filters![0].filters![0].filters = extenders;
    filters[0].filters?.splice(1, 1);
  } else if (extenders.length === 0 && reducers.length > 0) {
    filters![0].filters![1].filters = reducers;
    filters[0].filters?.splice(0, 1);
  } else {
    filters![0].filters![0].filters = extenders;
    filters![0].filters![1].filters = reducers;
  }

  return filters;
}
