import { get } from 'lodash';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  ApiFilterOperation,
  TableCellProps,
  TableCells,
  TableColumn,
  TableFilterCells,
  Tooltip,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { ChallengedAct, CourtFile, EsFile, Participant } from '@models';

export const useStyles = makeStyles({
  tableCell: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'top',
    width: '100%',

    '& input': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: 600,
  },
});

export function useColumns(): TableColumn<EsFile>[] {
  const intl = useIntl();

  const { states, agendas } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(states);
  const useAgendas = () => useStaticListSource(agendas);

  return [
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      valueMapper: TableCells.useSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'agenda',
      sortkey: 'agenda.name',
      filterkey: 'agenda.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__STATE',
        defaultMessage: 'Agenda',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useAgendas),
      valueMapper: TableCells.useSelectCellFactory(useAgendas),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}

export function ParticipantCell<T extends EsFile>(datakey: keyof T) {
  return function Cell(props: TableCellProps<T>) {
    const classes = useStyles();

    const participants = get(props.rowValue, datakey) as Participant[];

    return (
      <Tooltip
        placement="top-start"
        type="HOVER"
        title={
          <div className={classes.tooltip}>
            {participants?.map((participant) => {
              const parsedLabel = participant?.label?.split(',');

              return (
                <span key={participant.id}>
                  - <span className={classes.bold}>{parsedLabel?.[0]}</span>,
                  {parsedLabel?.slice(1).join(',')}
                </span>
              );
            })}
          </div>
        }
      >
        <Typography className={classes.tableCell}>{props.value}</Typography>
      </Tooltip>
    );
  };
}

export function ChallengedActCell(props: TableCellProps<CourtFile>) {
  const classes = useStyles();

  return (
    <Tooltip
      placement="top-start"
      type="HOVER"
      title={
        <div className={classes.tooltip}>
          {props.rowValue?.challengedActs?.map((act: ChallengedAct) => (
            <span key={act.id}>{`- ${act.label}`}</span>
          ))}
        </div>
      }
    >
      <Typography className={classes.tableCell}>{props.value}</Typography>
    </Tooltip>
  );
}
