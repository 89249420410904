import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DetailContext, DetailHandle } from '@eas/common-web';
import { openEsFileApiCall } from '@modules/es-file/dialog-actions/open-file/open-file-hook';
import { RedirectForm, useRedirect } from '@composite/redirect/dialog-redirect';
import { EsFile, InfFile } from '@models';
import { EsFileState, EvidenceBrowserUrl } from '@enums';
import { getOptions } from '../change-state/change-state-utils';

export function useReopenDialog() {
  const match = useRouteMatch();

  const { source } = useContext<DetailHandle<InfFile>>(DetailContext);

  const inAllFiles = [
    EvidenceBrowserUrl.ES_FILES_ALL + '/:id?',
    EvidenceBrowserUrl.ES_FILES_SEARCH + '/:id?',
  ].includes(match.path);

  const showButton = source.data?.state === EsFileState.CLOSED;

  const redirectBag = useRedirect<EsFile, RedirectForm>({
    options: () => getOptions(source.data?.state, EsFileState.IN_PROGRESS),
    redirectTo: () => EvidenceBrowserUrl.INF_FILES_IN_PROGRESS,
  });

  return {
    callApi: openEsFileApiCall,
    showButton,
    redirectBag: inAllFiles ? {} : { ...redirectBag },
  };
}
