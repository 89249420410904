import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormSelect, useStaticListSource } from '@eas/common-web';
import { DictionaryObject } from '@models';

export function useUserDefaultRoleDialog({
  agendas,
}: {
  agendas: DictionaryObject[];
}) {
  return function UserDialog() {
    const agendaSource = useStaticListSource(agendas);

    return (
      <>
        <FormSelect
          name="agenda"
          label={
            <FormattedMessage
              id="ES__ROLE__ASSIGN_USERS_HOOK__FIELD_LABEL__AGENDA"
              defaultMessage="Agenda"
            />
          }
          source={agendaSource}
          valueIsId={true}
        />
      </>
    );
  };
}
