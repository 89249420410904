import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { DocumentAttachment } from '@models';
import { EsFileState, EvidenceApiUrl } from '@enums';

export function useAddAttachmentDialog() {
  const callApi = useEventCallback(
    (id: string, { convertToPdf, sign, ...values }: DocumentAttachment) => {
      return abortableFetch(
        `${EvidenceApiUrl.DOCUMENTS}/${id}/attachment?convertToPdf=${
          convertToPdf ?? false
        }&sign=${sign ?? false}`,
        {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'POST',
          body: JSON.stringify(values),
        }
      );
    }
  );

  const { source } = useContext(DetailContext);

  // TODO
  const disabled =
    !source.data?.id ||
    source.data?.esFile?.state === EsFileState.REGISTRY ||
    source.data?.esFile?.state === EsFileState.CLOSED;

  const showButton = source.data?.id;

  return {
    callApi,
    disabled,
    showButton,
  };
}
