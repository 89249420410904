import React, { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  DomainObject,
  EvidenceContext,
  EvidenceStateAction,
  FormRadioGroup,
  NavigationContext,
  SnackbarContext,
  SnackbarVariant,
  useEventCallback,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { EvidenceBrowserUrl } from '@enums';

export enum RedirectAction {
  REDIRECT_TO_RESULT = 'REDIRECT_TO_RESULT',
  STAY_IN_EVIDENCE = 'STAY_IN_EVIDENCE',
}

export interface RedirectItem {
  id: RedirectAction;
  toResult?: boolean;
  name: React.ReactNode;
}

export interface RedirectForm {
  afterSubmit?: RedirectItem;
}

export function useRedirect<T extends DomainObject, FORM extends RedirectForm>({
  options,
  redirectTo,
  FormFields,
  initialValueIndex = 0,
  initialValues,
}: {
  options: RedirectItem[] | ((values: FORM | null) => RedirectItem[]);
  redirectTo:
    | EvidenceBrowserUrl
    | ((values: FORM | null) => EvidenceBrowserUrl);
  FormFields?: () => JSX.Element;
  initialValueIndex?: number;
  initialValues?: FORM;
}) {
  const resultRef = useRef<T | null>(null);
  const valuesRef = useRef<FORM | null>(null);

  const match = useRouteMatch();
  const intl = useIntl();
  const { navigate } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, onPersisted, setMode } = useContext<DetailHandle<T>>(
    DetailContext
  );
  const { tableRef } = useContext(EvidenceContext);

  const redirectToSameUrl = match.path === redirectTo + '/:id?';

  function RedirectFormFields() {
    const values = useFormSelector((values: FORM) => values);

    const items = typeof options === 'function' ? options(values) : options;

    const source = useStaticListSource<RedirectItem>(items);

    useEffect(() => {
      valuesRef.current = values;
    }, [values]);

    return (
      <>
        {FormFields && <FormFields />}
        <FormRadioGroup<RedirectItem>
          name="afterSubmit"
          label=""
          labelOptions={{
            hide: true,
          }}
          source={source}
        />
      </>
    );
  }

  const onResult = useEventCallback(async (result: T) => {
    if (valuesRef.current?.afterSubmit?.toResult) {
      resultRef.current = result;
    } else {
      resultRef.current = source.data;
    }
  });

  const onSuccess = useEventCallback(async () => {
    if (
      valuesRef.current?.afterSubmit?.id === RedirectAction.STAY_IN_EVIDENCE
    ) {
      if (valuesRef.current?.afterSubmit?.toResult && source.data?.id) {
        onPersisted(source.data.id);

        tableRef.current?.refresh();

        const message = intl.formatMessage({
          id: 'EAS_ACTION_BUTTON_MSG_SUCCESS',
          defaultMessage: 'Akce byla úspěšně vykonána.',
        });

        showSnackbar(message, SnackbarVariant.SUCCESS);
      } else {
        onPersisted(null);
        setMode(DetailMode.NONE);
      }
    } else {
      const url =
        typeof redirectTo === 'function'
          ? redirectTo(valuesRef.current!)
          : redirectTo + '/' + resultRef.current?.id;

      if (redirectToSameUrl) {
        onPersisted(resultRef.current?.id ?? null);
      } else {
        navigate(url, false, {
          action: EvidenceStateAction.SHOW_ITEM,
          data: resultRef.current?.id,
        });
      }
    }
  });

  const formInitialValues = {
    afterSubmit:
      typeof options === 'function'
        ? options(valuesRef.current)[initialValueIndex]
        : options[initialValueIndex],
    ...initialValues,
  } as FORM;

  return {
    FormFields: RedirectFormFields,
    onResult,
    onSuccess,
    formInitialValues,
  };
}
