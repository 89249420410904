import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useDecisions } from '../decision/decisions';
import { useColumns } from './all-decisions-columns';

export function AllDecisions() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useDecisions({
    evidenceProps: {
      tableProps: {
        columns,
        tableName: intl.formatMessage({
          id: 'ES__ALL_DECISIONS__TABLE__TITLE',
          defaultMessage: 'Rozhodnutí',
        }),
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
