import {
  ApiFilterOperation,
  Params,
  ResultDto,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { DictSettlementMethod } from '@models';
import { EvidenceApiUrl } from '@enums';
import { DictionaryObject } from './../../../../search-web/apps/nalus/src/models/dictionary-object';

export function useSettlementMethods(params?: Params) {
  return useAutocompleteSource<DictSettlementMethod>({
    url: `${EvidenceApiUrl.DICT_SETTLEMENT_METHODS}/autocomplete`,
    params,
  });
}

export function useCourtFileSettlementMethods(params?: Params) {
  return useAutocompleteSource<DictSettlementMethod>({
    url: `${EvidenceApiUrl.DICT_SETTLEMENT_METHODS}/autocomplete`,
    params: {
      filters: [
        {
          field: 'agenda.id',
          operation: ApiFilterOperation.EQ,
          value: 'COURT',
        },
      ],
    },
  });
}

export function useDefaultSettlementMethods() {
  const [response] = useFetch<ResultDto<DictSettlementMethod>>(
    `${EvidenceApiUrl.DICT_SETTLEMENT_METHODS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filters: [
          {
            field: 'tags',
            operation: ApiFilterOperation.IN,
            values: ['DEFAULT_VALUE'],
          },
        ],
      }),
    }
  );

  return response?.items;
}

/**
 * List all available settlement method tags
 */
export function useSettlementMethodTags() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DICT_SETTLEMENT_METHODS}/tag/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
