import React, { ComponentType, PropsWithChildren } from 'react';
import { ResponsiveContainer } from 'recharts';

export function Chart({
  data,
  children,
  ChartComponent,
  height = 245,
}: PropsWithChildren<{
  data: any[];
  ChartComponent: ComponentType<any>;
  height?: number;
}>) {
  return (
    <ResponsiveContainer width="95%" height={height}>
      <ChartComponent
        data={data}
        width={150}
        margin={{
          top: 20,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {children}
      </ChartComponent>
    </ResponsiveContainer>
  );
}
