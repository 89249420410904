import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { grey } from '@material-ui/core/colors';
import { useEsFiles } from '@modules/es-file/es-files';
import { Search } from '@composite/search/search';
import { EsFile } from '@models';
import { EsFileState } from '@enums';
import { useColumns as useAllColumns } from './../es-file-all/all-es-files-columns';
import { useColumns } from './es-file-search-columns';

export function EsFileSearch() {
  const intl = useIntl();
  const columns = useColumns();
  const allColumns = useAllColumns().map((col) => {
    col.filterable = false;
    return col;
  });

  const { props } = useEsFiles({
    evidenceProps: {
      detailProps: {
        toolbarProps: {
          showBtn: (btn) => btn !== 'NEW',
        },
      },
      tableProps: {
        tableName: intl.formatMessage({
          id: 'ES__ES_FILE_SEARCH__TABLE__TITLE',
          defaultMessage: 'Spisy - hledání',
        }),
      },
    },
  });

  return (
    <Search
      apiProps={props.apiProps}
      detailProps={props.detailProps}
      tableProps={{
        columns: [...columns, ...allColumns],
        settingsKey: 'ES_FILE_SEARCH',
        createRowStyle: (item: EsFile) =>
          item.state === EsFileState.REGISTRY
            ? {
                color: grey[500],
                backgroundColor: grey[100],
              }
            : {},
      }}
      filterProps={{
        title: (
          <FormattedMessage
            id="ES__ES_FILE_SEARCH__TOOLBAR__TITLE"
            defaultMessage="Vyhledávání"
          />
        ),
        initVisibility: [
          'agenda.id',
          'number.value',
          'participants.name',
          'decisionForm.id',
          'settlementMethods.id',
          'processingType.id',
          'solverUserRoles.id',
          'settlementDateFrom',
          'settlementDateTo',
        ],
        defaults: [],
        groupLabels: {
          1: intl.formatMessage({
            id: 'ES__ES_FILE_SEARCH__LABEL_GROUP_1',
            defaultMessage: 'Společné',
          }),
          2: intl.formatMessage({
            id: 'ES__ES_FILE_SEARCH__LABEL_GROUP_2',
            defaultMessage: 'Soudní spisy',
          }),
          3: intl.formatMessage({
            id: 'ES__ES_FILE_SEARCH__LABEL_GROUP_2',
            defaultMessage: 'Spisy INF a SPR',
          }),
        },
      }}
    />
  );
}
