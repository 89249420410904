/**
 * Typ účastníka
 */
export enum ParticipantType {
  /**
   * Navrhovatel
   */
  PROPOSER = 'PROPOSER',

  /**
   * Vedlejší účastník
   */
  ACCESSORY_PARTICIPANT = 'ACCESSORY_PARTICIPANT',

  /**
   * Pisatel
   */
  WRITER = 'WRITER',

  /**
   * Právní zástupce
   */
  LEGAL_ADVISER = 'LEGAL_ADVISER',
}

/**
 * Typ právního zástupce
 */
export enum LegalAdviserType {
  /**
   * Navrhovatel
   */
  PROPOSER = 'PROPOSER',

  /**
   * Vedlejší účastník
   */
  ACCESSORY_PARTICIPANT = 'ACCESSORY_PARTICIPANT',

  /**
   * Ostatní
   */
  OTHER = 'OTHER',
}
