import * as Yup from 'yup';
import { DictBarcodePrinter } from '@models';

export function useValidationSchema() {
  return Yup.object<DictBarcodePrinter>().shape({
    description: Yup.string().nullable().required(),
    sharedDirectory: Yup.string().nullable().required(),
    name: Yup.string().nullable().required(),
    zplCommand: Yup.string().nullable().required(),
  });
}
