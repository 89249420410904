import React, { useContext } from 'react';
import {
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  DecisionFormProceedingDuration,
  DictionaryObject,
  JudgeProceedingDuration,
} from '@models';

export function useJudgeProceedingDurationCountsColumns(): TableFieldColumn<JudgeProceedingDuration>[] {
  return [
    { name: 'Soudce', datakey: 'judgeName', width: 300 },
    ...proceedingColumns,
  ];
}
export function useDecisionFormDurationCountsColumns(): TableFieldColumn<DecisionFormProceedingDuration>[] {
  const { decisionForms } = useContext(StaticDataContext);
  const useDecisionForms = () => useStaticListSource(decisionForms);

  const DecisionFormSelectCell = TableFieldCells.useSelectCellFactory<
    DecisionFormProceedingDuration,
    DictionaryObject
  >(useDecisionForms);

  return [
    {
      name: 'Forma rozhodnutí',
      datakey: 'decisionForm',
      width: 300,
      CellComponent: function Cell(props) {
        return decisionForms.find((form) => form.id === props.value) ? (
          <DecisionFormSelectCell {...props} />
        ) : (
          <TableFieldCells.TextCell
            {...props}
            value="Senátní/plenární celkem"
          />
        );
      },
    },
    ...proceedingColumns,
  ];
}

const proceedingColumns = [
  { name: '2 týdny', datakey: 'g01', width: 80 },
  { name: '3 týdny', datakey: 'g02', width: 80 },
  { name: 'Do měsíce', datakey: 'g03', width: 80 },
  { name: '1,5 měsíce', datakey: 'g04', width: 80 },
  { name: '2 měsíce', datakey: 'g05', width: 80 },
  { name: '3 měsíce', datakey: 'g06', width: 80 },
  { name: '4 měsíce', datakey: 'g07', width: 80 },
  { name: '5 měsíců', datakey: 'g08', width: 80 },
  { name: '6 měsíců', datakey: 'g09', width: 80 },
  { name: '8 měsíců', datakey: 'g10', width: 80 },
  { name: '10 měsíců', datakey: 'g11', width: 80 },
  { name: '1 rok', datakey: 'g12', width: 80 },
  { name: '15 měsíců', datakey: 'g13', width: 80 },
  { name: '1,5 roku', datakey: 'g14', width: 80 },
  { name: '2 roky', datakey: 'g15', width: 80 },
  { name: '2,5 roku', datakey: 'g16', width: 80 },
  { name: '3 roky', datakey: 'g17', width: 80 },
  { name: '4 roky', datakey: 'g18', width: 80 },
  { name: '> 4 roky', datakey: 'g19', width: 80 },
  { name: 'Celkem', datakey: 'total', width: 80 },
  { name: 'Průměrná délka řízení', datakey: 'avg', width: 150 },
];
