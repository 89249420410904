import { noop, stubFalse } from 'lodash';
import React from 'react';
import { lighten } from '@material-ui/core/styles';
import { TableField } from '@eas/common-web';
import { useStyles } from '@modules/dashboard/search/result-table';
import { ProceedingDurationData } from '@models';
import { ChartView } from '@enums';
import {
  useDecisionFormDurationCountsColumns,
  useJudgeProceedingDurationCountsColumns,
} from './proceeding-duration-counts-columns';

export function useProceedingDurationCountsVisualisation({
  data,
  maxTableRows = 7,
}: {
  data?: ProceedingDurationData;
  maxTableRows?: number;
}) {
  const JudgeTable = () => {
    const classes = useStyles();

    const columns = useJudgeProceedingDurationCountsColumns();

    return (
      <TableField
        value={data?.judgeProceedingDurations ?? []}
        onChange={noop}
        columns={columns}
        showRadioCond={stubFalse}
        classes={classes}
        maxRows={maxTableRows}
        showToolbar={false}
      />
    );
  };

  const DecisionFormTable = () => {
    const classes = useStyles();

    const columns = useDecisionFormDurationCountsColumns();

    return (
      <TableField
        value={data?.decisionFormProceedingDurations}
        onChange={noop}
        columns={columns}
        showRadioCond={stubFalse}
        classes={classes}
        maxRows={maxTableRows}
        showToolbar={false}
        createRowStyle={(item) =>
          !item?.decisionForm
            ? { backgroundColor: lighten('#0d98ba', 0.8) }
            : {}
        }
      />
    );
  };

  return {
    Visualisations: {
      [ChartView.TABLE]: JudgeTable,
      [ChartView.TABLE_2]: DecisionFormTable,
    },
    availableViews: [
      { view: ChartView.TABLE, tooltip: 'Tabulka - délka řízení dle soudců' },
      {
        view: ChartView.TABLE_2,
        tooltip: 'Tabulka - délka řízení dle formy rozhodnutí',
      },
    ],
  };
}
