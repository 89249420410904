import React, { useContext } from 'react';
import {
  FormContext,
  FormInlineTableField,
  PromptDialogContext,
  useEventCallback,
} from '@eas/common-web';
import { EsFileRegulation } from '@models';
import { RegulationEntityType, RegulationType } from '@enums';
import { useRegulationColumns } from './regulation-columns';
import { RegulationFieldProps } from './regulation-field';

type RegulationDialogProps = RegulationFieldProps & {
  singleRow: boolean;
};

export function RegulationDialog(
  props: RegulationDialogProps & { disabled: boolean }
) {
  const { setFieldValue } = useContext(FormContext);
  const { formRef } = useContext(PromptDialogContext);
  const collectionDatakey = 'regulations';

  const handleRegulationTypeChange = useEventCallback((index: number) => {
    const fieldName = `${collectionDatakey}[${index}]`;
    const regulations = formRef.current?.getFieldValues().regulations;

    if (regulations[index].regulationType === RegulationType.OTHER) {
      setFieldValue(`${fieldName}.type`, RegulationEntityType.PARAGRAPH);
    } else {
      setFieldValue(`${fieldName}.type`, RegulationEntityType.ARTICLE);
    }
  });

  const columns = useRegulationColumns(
    !!props.singleRow,
    handleRegulationTypeChange,
    collectionDatakey,
    props
  );

  return (
    <>
      <FormInlineTableField
        columns={columns}
        showToolbar={!props.singleRow}
        withRemove={!props.singleRow}
        name={collectionDatakey}
        labelOptions={{
          hide: true,
        }}
        initNewItem={() =>
          ({
            regulationType:
              RegulationType.CONSTITUTIONAL_LAWS_AND_INTERNATIONAL_TREATIES,
            type: RegulationEntityType.PARAGRAPH,
          } as EsFileRegulation)
        }
        maxRows={15}
        showDetailBtnCond={() => false}
      />
    </>
  );
}
