import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { ViewingRequest } from '@models';
import { VerifyDataBoxButton } from './dialog-actions/verify-data-box-button';

export function ActionBar() {
  const { source } = useContext<DetailHandle<ViewingRequest>>(DetailContext);

  const dataBoxId = source?.data?.requester?.contact?.dataBox?.identifier;

  return (
    <>
      {dataBoxId && (
        <VerifyDataBoxButton
          dataBoxId={dataBoxId}
          ButtonComponent={PrimaryDetailActionbarButton}
        />
      )}
    </>
  );
}
