import {
  ApiFilterOperation,
  HookedSource,
  useScrollableSource,
} from '@eas/common-web';
import { User } from '@models';
import { EvidenceApiUrl } from '@enums';

export const USER_ROLE_SOURCE = 'USER_ROLE_SOURCE';

function useUserRoleSource(): HookedSource<User> {
  const source = useScrollableSource({
    url: `${EvidenceApiUrl.USER_ROLE}/list`,
  });

  return {
    [USER_ROLE_SOURCE]: {
      source,
      shouldLoad: () => true,
      modifyBeforeLoad: ({ id }) => {
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'user.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
          sort: [
            {
              field: 'role.name',
              order: 'ASC',
              type: 'FIELD',
            },
          ],
        });
      },
    },
  };
}

export function useUserHookedSources(): HookedSource<User> {
  const userRoles = useUserRoleSource();

  return Object.assign(userRoles);
}
