import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RedirectAction } from '@composite/redirect/dialog-redirect';

const OPTION_IN_CURRENT_EVIDENCE = (
  <FormattedMessage
    id="REDIRECT__OPTION__IN_CURRENT_EVIDENCE"
    defaultMessage="Pokračovat v otevřené evidenci"
  />
);

const SOURCE_IN_CURRENT_EVIDENCE = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_IN_CURRENT_EVIDENCE,
};

const OPTION_CLOSED = (
  <FormattedMessage
    id="REDIRECT__OPTION__CLOSED"
    defaultMessage="Pokračovat v evidenci: Uzavřené"
  />
);

const DEST_CLOSED = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_CLOSED,
};

export function getOptions() {
  return [SOURCE_IN_CURRENT_EVIDENCE, DEST_CLOSED];
}
