import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { DisciplinaryProceedingsFile } from '@models';
import { EsFileState, EvidenceApiUrl } from '@enums';

export function useChangeStateDialog() {
  const { source } =
    useContext<DetailHandle<DisciplinaryProceedingsFile>>(DetailContext);

  const callApi = useEventCallback(
    (id: string, formData: { state: EsFileState }) =>
      abortableFetch(
        `${EvidenceApiUrl.ES_FILES}/${id}/state/${formData.state}`,
        {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'PUT',
        }
      )
  );

  const showButton = source.data?.state !== EsFileState.CLOSED;

  return {
    callApi,
    showButton,
  };
}
