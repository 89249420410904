import { Agenda } from '@enums';

export function useSubmissionCountsColumns(agenda?: Agenda) {
  return [
    {
      name: 'Rok',
      datakey: 'year',
      width: 150,
    },
    {
      name: 'Podání celkem',
      datakey: 'totalCount',
      width: 200,
    },
    ...(!agenda || agenda === Agenda.COURT
      ? [
          {
            name: 'Senátní',
            datakey: 'senateCount',
            width: 200,
          },
          {
            name: 'Plenární',
            datakey: 'plenaryCount',
            width: 200,
          },
        ]
      : []),
  ];
}
