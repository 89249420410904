import React from 'react';
import { FormattedMessage } from 'react-intl';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card/Card';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Form, FormSubmitButton, formFieldFactory } from '@eas/common-web';
import { formTextFieldFactory } from '@components/form/simple-text-field/simple-text-field';
import { LoginData } from '@models';
import { useLogin } from './login-hook';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    color: theme.palette.primary.main,
  },
  button: {
    width: '100%',
  },
  field: {
    width: '100%',
    margin: 15,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: 16,
    background:
      'linear-gradient(0deg, rgb(245 246 247) 0%, rgb(255 255 255) 100%)',
    margin: '0 0 24px',
    padding: 32,
    minWidth: 500,
  },
}));

const LoginFields = {
  UsernameField: formFieldFactory(
    formTextFieldFactory({ label: 'Uživatelské jméno' }),
    ({ children }) => <>{children}</>
  ),
  PasswordField: formFieldFactory(
    formTextFieldFactory({ label: 'Heslo', type: 'password' }),
    ({ children }) => <>{children}</>
  ),
};

export function Login() {
  const classes = useStyles();
  const { ref, handleSubmit, validationSchema, getFieldError } = useLogin();

  return (
    <Form<LoginData>
      onSubmit={handleSubmit}
      editing={true}
      ref={ref}
      validationSchema={validationSchema}
      initialValues={{ username: '', password: '' }}
    >
      <Container maxWidth="xs" className={classes.paper}>
        <Card className={classes.card} elevation={2}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography className={classes.title} variant="h5">
            <FormattedMessage
              id="ES__PROFILE__TITLE"
              defaultMessage="Přihlášení"
            />
          </Typography>
          <div className={classes.field}>
            <LoginFields.UsernameField
              name="username"
              error={!!getFieldError('username')}
              helperText={getFieldError('username')}
            />
          </div>
          <div className={classes.field}>
            <LoginFields.PasswordField
              name="password"
              error={!!getFieldError('password')}
              helperText={getFieldError('password')}
            />
          </div>
          <FormSubmitButton
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            className={classes.button}
          >
            <Typography component="span" variant="body1">
              <FormattedMessage
                id="ES__PROFILE__LOGIN"
                defaultMessage="Přihlásit"
              />
            </Typography>
          </FormSubmitButton>
        </Card>
      </Container>
    </Form>
  );
}
