import clsx from 'clsx';
import { noop, stubFalse } from 'lodash';
import React, { ComponentType } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { TableField, TableFieldColumn } from '@eas/common-web';
import { DashboardDivider } from '@modules/dashboard/divider';
import { useStyles as useTableStyles } from '@modules/dashboard/search/result-table';

export const useStyles = makeStyles({
  card: {
    width: '100%',
    borderRadius: 16,
    margin: '0 0 24px',
  },
  content: {
    padding: '32px 0px 16px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 24px',
  },
  divider: {
    padding: '0px 32px',
  },
  actions: { display: 'flex', gap: '20px' },
});

export function ReportCard<OBJECT>({
  title,
  data,
  columns,
  Header,
}: {
  title: string;
  data: OBJECT[];
  columns: TableFieldColumn<OBJECT>[];
  Header?: ComponentType;
}) {
  const classes = useStyles();
  const tableClasses = useTableStyles();

  return (
    <Card className={clsx(classes.card)}>
      <div className={classes.content}>
        <div className={classes.divider}>
          <DashboardDivider text={title} />
        </div>
        {Header && <Header />}
        <TableField<OBJECT>
          value={data}
          onChange={noop}
          columns={columns}
          showRadioCond={stubFalse}
          classes={tableClasses}
          maxRows={10}
          showToolbar={false}
        />
      </div>
    </Card>
  );
}
