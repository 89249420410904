import { getErrorMessage } from '@utils/error-message';
import { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  AbortableFetch,
  DetailContext,
  DetailHandle,
  FilesContext,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { ErrorObject, EsFile } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useEsFileMetadata } from '../../es-file-utils';

export function useGenerateFileCover() {
  const { source } = useContext<DetailHandle<EsFile>>(DetailContext);
  const { getFileUrl } = useContext(FilesContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { hasFileCoverPermission } = useEsFileMetadata(source.data!);

  const fetch = useRef<AbortableFetch | null>(null);
  const intl = useIntl();

  const callApi = useEventCallback(() =>
    abortableFetch(`${EvidenceApiUrl.ES_FILES}/${source.data?.id}/cover`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  const wrappedCallApi = useEventCallback(async () => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi();

      let message = intl.formatMessage({
        id: 'FILE_COVER__REQUEST_SUCCESSFULLY_SENT',
        defaultMessage: 'Dokument se generuje, čekejte prosím.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);

      const response = await fetch.current.json();

      const a = document.createElement('a');
      a.href = getFileUrl(response.id);
      a.download = response.name || 'obal spisu.pdf';

      document.body.appendChild(a); // Required for FF
      a.click();
      a.remove();

      message = intl.formatMessage({
        id: 'FILE_COVER__REQUEST_SUCCESSFULL',
        defaultMessage: 'Akce byla úspěšně vykonána.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
    } catch (e) {
      const err = e as ErrorObject<string>;
      const message = getErrorMessage(err);

      if (err.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  const showButton = source.data?.id && source.data?.number;
  const disableButton = !hasFileCoverPermission;

  return {
    callApi: wrappedCallApi,
    showButton,
    disableButton,
  };
}
