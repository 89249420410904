/**
 * Zobrazení spisů dle asistentů
 */
export enum AssistantFilesView {
  /**
   * Aktivní
   */
  ACTIVE = 'ACTIVE',

  /**
   * Všechny
   */
  ALL = 'ALL',
}
