import React from 'react';
import { DictionaryEvidence } from '@eas/common-web';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';

export function Fields() {
  const historyDescriptors = useCommonHistoryDescriptors();

  return (
    <>
      <DictionaryEvidence.FieldName />
      <DictionaryEvidence.FieldCode />
      <DictionaryEvidence.FieldOrder />
      <DictionaryEvidence.FieldActive />
      <HistoryField descriptors={historyDescriptors} />
    </>
  );
}
