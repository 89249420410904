import { useEffect, useState } from 'react';
import { ResultDto } from '@eas/common-web';
import { CourtFile } from '@models';
import { getLatestOpenEsFile } from '../screening-api';

export function useDefaults() {
  const [defaultSubmissionDate, setDefaultSubmissionDate] = useState<string>();

  useEffect(() => {
    const callApi = async () => {
      const response = getLatestOpenEsFile();
      const json: ResultDto<CourtFile> = await response.json();

      const latestDate = json.items?.[0]?.deliveryDate;

      if (latestDate) {
        setDefaultSubmissionDate(`${latestDate.substring(0, 4)}-01-01`);
      } else {
        setDefaultSubmissionDate('2015-01-01');
      }
    };

    callApi();
  }, []);

  return {
    defaultSubmissionDate,
  };
}
