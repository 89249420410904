import React, { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  DictionaryObject,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { Dispatch } from '@models';
import { UIElement } from '@enums';
import { useDispatchColumns } from './dispatch-columns';
import { DispatchToolbar } from './dispatch-toolbar';

export interface DispatchFieldProps {
  dispatchMethods: DictionaryObject[];
  dispatchStates: DictionaryObject[];
  dispatchDeliveryMethods: DictionaryObject[];
  disabled: boolean;
  source?: ScrollableSource<Dispatch>;
  hasPermission: (element: string) => boolean;
}

export function DispatchField({
  dispatchMethods,
  dispatchStates,
  dispatchDeliveryMethods,
  disabled,
  source,
  hasPermission,
}: DispatchFieldProps) {
  const columns = useDispatchColumns({
    dispatchMethods,
    dispatchStates,
  });

  const tableRef = useRef<TableFieldHandle>(null);

  const editRef = useRef<ActionButtonHandle>(null);

  const hasNoPermissions = Object.keys(UIElement.Dispatch).every(
    (key) => !hasPermission(key)
  );

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(toolbarProps: TableFieldToolbarProps) {
        return (
          <DispatchToolbar
            {...toolbarProps}
            ref={editRef}
            disabled={disabled || hasNoPermissions}
            dispatchMethods={dispatchMethods}
            dispatchStates={dispatchStates}
            dispatchDeliveryMethods={dispatchDeliveryMethods}
            hasPermission={hasPermission}
          />
        );
      },
    [disabled]
  );

  if (!source) {
    return <> Missing DISPATCH_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__DISPATCHES__PANEL_TITLE"
          defaultMessage="Vypravení"
        />
      }
      expandable={true}
      defaultExpanded={true}
      summary={<FormTableCount source={source} />}
    >
      <RemoteTableField
        ref={tableRef}
        disabled={disabled || hasNoPermissions}
        columns={columns}
        source={source}
        maxRows={7}
        ToolbarComponent={ToolbarComponent}
        onRowClick={(_, index) => {
          if (!disabled || !hasPermission(UIElement.Dispatch.EDIT_DISPATCH)) {
            tableRef.current?.setSelectedIndex(index);
            editRef.current?.executeAction();
          }
        }}
        highlightSelectedRow={true}
        showDetailBtnCond={() => false}
      />
    </FormPanel>
  );
}
