import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from './search/search';
import { DocumentsStatistics } from './statistics/documents';
import { EsFilesStatistics } from './statistics/es-files';
import { UserOverviewStatistics } from './statistics/user-overview';

export const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 24,
  },
  root: {
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f1f3f4',
    overflow: 'auto',
  },
  subtitle: {
    fontWeight: 600,
  },
  content: {
    marginTop: 50,
    marginBottom: 50,
    flex: '1 0 auto',
  },
  padded: {
    padding: 16,
    margin: 0,
  },
  input: {
    marginTop: 16,
    paddingLeft: 8,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: '#e7e7e7',
  },
  search: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
}));

export function Dashboard() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Search />
            <UserOverviewStatistics />
          </Grid>
          <EsFilesStatistics />
          <DocumentsStatistics />
        </Container>
      </div>
    </div>
  );
}
