import { Dispatch, SetStateAction, createContext } from 'react';
import { FilterState } from './filter-types';

export type FilterContext = {
  activeExtender: number | undefined;
  setActiveExtender: Dispatch<SetStateAction<number | undefined>>;
  confirmExtender: () => void;
  activateAllExtenders: () => void;
  deactivateAllExtenders: (except?: number) => void;
  appendExtender: (newExtenders: FilterState[]) => number;
  toggleExtender: (index: number) => void;
  removeExtender: (index: number) => void;
  extenders: FilterState[];
  appendReducer: (newReducers: string[]) => void;
  toggleReducer: (index: number) => void;
  removeReducer: (index: number) => void;
  reducers: FilterState[];
  activateAll: () => void;
  deactivateAll: () => void;
  filters: FilterState[];
  setFilters: Dispatch<SetStateAction<FilterState[]>>;
};

export const FilterContext = createContext<FilterContext>({
  setActiveExtender: () => undefined,
  activeExtender: undefined,
  confirmExtender: () => {},
  activateAllExtenders: () => {},
  deactivateAllExtenders: () => {},
  appendExtender: () => 0,
  toggleExtender: () => {},
  removeExtender: () => {},
  extenders: [],
  appendReducer: () => {},
  toggleReducer: () => {},
  removeReducer: () => {},
  reducers: [],
  activateAll: () => {},
  deactivateAll: () => {},
  filters: [],
  setFilters: () => {},
});
