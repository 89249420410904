export enum NaSpisLogRecordEventType {
  /**
   * Nový účastník
   */
  NEW_PARTICIPANT = 'NEW_PARTICIPANT',

  /**
   * Úprava účastníka
   */
  UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT',

  /**
   * Smazání účastníka
   */
  DELETE_PARTICIPANT = 'DELETE_PARTICIPANT',

  /**
   * Schválení žádosti o nahlížení
   */
  APPROVE_VIEWING_REQUEST_STATE = 'APPROVE_VIEWING_REQUEST_STATE',

  /**
   * Zamítnutí žádosti o nahlížení
   */
  REJECT_VIEWING_REQUEST_STATE = 'REJECT_VIEWING_REQUEST_STATE',

  /**
   * Úprava čisla datové schránky
   */
  CHANGE_DATABOX_ID = 'CHANGE_DATABOX_ID',

  /**
   * Ověření emailu
   */
  VERIFY_EMAIL = 'VERIFY_EMAIL',

  /**
   * Kontrola uživatele
   */
  CHECK_USER = 'CHECK_USER',

  /**
   * Získání seznamu soudních spisů
   */
  GET_COURT_FILES = 'GET_COURT_FILES',

  /**
   * Ověření datové schránky
   */
  VERIFY_DATABOX = 'VERIFY_DATABOX',

  /**
   * Žádost o nahlížení
   */
  VIEWING_REQUEST = 'VIEWING_REQUEST',

  /**
   * Dokončení žádosti o nahlížení
   */
  FINALIZE_VIEWING_REQUEST = 'FINALIZE_VIEWING_REQUEST',
}
