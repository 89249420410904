import React, { ComponentType, ReactNode, useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButtonProps,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import {
  useCourtFilePermission,
  useInfFilePermission,
  usePlenaryOpinionPermission,
  useSprFilePermission,
} from '@modules/es-file/es-file-permission';
import { useTasksFields } from '@modules/task/task-fields';
import { useValidationSchema } from '@modules/task/task-schema';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { EsFile, Task } from '@models';
import { Agenda, EvidenceApiUrl, UIElement } from '@enums';

export function useAddTaskDialog() {
  const detailCtx = useContext(DetailContext);

  const intl = useIntl();

  const validationSchema = useValidationSchema();
  const FormFields = useTasksFields();

  const callApi = useEventCallback((_, formData: Task) => {
    return abortableFetch(`${EvidenceApiUrl.TASKS}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        ...formData,
      }),
    });
  });

  const AddTaskButton = ({
    ButtonComponent,
    formInitialValues,
    buttonLabel,
    disabled,
    modes = [DetailMode.VIEW],
  }: {
    ButtonComponent: ComponentType<DetailToolbarButtonProps>;
    formInitialValues: Task;
    buttonLabel: ReactNode;
    disabled?: boolean;
    modes?: DetailMode[];
  }) => (
    <DetailActionButton
      promptKey="ADD_TASK"
      apiCall={callApi}
      buttonLabel={buttonLabel}
      dialogTitle={intl.formatMessage({
        id: 'ES__ES_FILES__DIALOG_ADD_TASK__TITLE',
        defaultMessage: 'Vytvoření úlohy',
      })}
      dialogText={intl.formatMessage({
        id: 'ES__ES_FILES__DIALOG_ADD_TASK__TEXT',
        defaultMessage: 'Vyplňte informace o úloze.',
      })}
      ButtonComponent={ButtonComponent}
      FormFields={() => (
        <DetailContext.Provider value={{ ...detailCtx, mode: DetailMode.NEW }}>
          <FormFields />
        </DetailContext.Provider>
      )}
      modes={modes}
      dialogWidth={700}
      buttonDisabled={disabled}
      formValidationSchema={validationSchema}
      formInitialValues={formInitialValues}
    />
  );

  const disabledBtn = useDisableBtn(detailCtx?.source?.data?.agenda)?.(
    detailCtx?.source?.data?.esFile
  );

  return {
    AddTaskButton,
    disabledBtn,
  };
}

const useDisableBtn = (agenda?: Agenda) => {
  switch (agenda) {
    case Agenda.COURT:
      return useDisableCourtFileTasks;
    case Agenda.SPR:
      return useDisableSprFileTasks;
    case Agenda.INF:
      return useDisableInfFileTasks;
    case Agenda.PLENARY_OPINION:
      return useDisablePlenaryOpinionFileTasks;
  }
};

const useDisableCourtFileTasks = (esFile?: EsFile) => {
  const { hasPermission } = useCourtFilePermission(esFile);

  return !hasPermission(UIElement.CourtFile.TASKS_AND_DEADLINES);
};

const useDisableSprFileTasks = (esFile?: EsFile) => {
  const { hasPermission } = useSprFilePermission(esFile);

  return !hasPermission(UIElement.SprFile.TASKS_AND_DEADLINES);
};

const useDisableInfFileTasks = (esFile?: EsFile) => {
  const { hasPermission } = useInfFilePermission(esFile);

  return !hasPermission(UIElement.InfFile.TASKS_AND_DEADLINES);
};

const useDisablePlenaryOpinionFileTasks = (esFile?: EsFile) => {
  const { hasPermission } = usePlenaryOpinionPermission(esFile);

  return !hasPermission(UIElement.PlenaryOpinionFile.TASKS);
};
