import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { FormFields } from './accessory-participant-dialog';
import { useValidationSchema } from './accessory-participant-schema';
import { useAddAccessoryParticipantDialog } from './dialog-actions/add-accessory-participant-hook';
import { useDeleteAccessoryParticipantDialog } from './dialog-actions/delete-accessory-participant-hook';
import { useEditAccessoryParticipantDialog } from './dialog-actions/edit-accessory-participant-hook';

export const AccessoryParticipantToolbar = forwardRef(
  function AccessoryParticipantsToolbar(
    {
      selectedIndex,
      setSelectedIndex,
      disabled,
    }: TableFieldToolbarProps & { disabled: boolean },
    ref: Ref<ActionButtonHandle>
  ) {
    const classes = useStyles();

    const intl = useIntl();

    const { source } = useContext(DetailContext);
    const { source: tableSource } = useContext(RemoteTableFieldContext);
    const accessoryParticipants = tableSource.items;

    const validationSchema = useValidationSchema();

    const { callApi: addAccessoryParticipantApiCall } =
      useAddAccessoryParticipantDialog();
    const { callApi: editAccessoryParticipantApiCall } =
      useEditAccessoryParticipantDialog();
    const { callApi: deleteAccessoryParticipantApiCall } =
      useDeleteAccessoryParticipantDialog();

    const selectedAccessoryParticipant =
      selectedIndex !== undefined ? accessoryParticipants?.[selectedIndex] : {};

    const isSelected = selectedIndex !== undefined;

    useEffect(
      () => {
        setSelectedIndex(undefined);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [source]
    );

    return (
      <div className={classes.tableActions}>
        <ButtonGroup className={classes.buttonGroup}>
          <DetailActionButton
            promptKey="ADD_ACCESSORY_PARTICIPANT"
            apiCall={addAccessoryParticipantApiCall}
            buttonLabel={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__ADD_ACCESSORY_PARTICIPANT',
              defaultMessage: 'Přidat vedlejšího účastníka',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__ADD_ACCESSORY_PARTICIPANT__TITLE',
              defaultMessage: 'Vedlejší účastník',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__ADD_ACCESSORY_PARTICIPANT__TEXT',
              defaultMessage: 'Vyplňte informace o vedlejším účastníkovi.',
            })}
            ButtonComponent={({ onClick, label }) => (
              <TableFieldToolbarButton
                IconComponent={ControlPointIcon}
                show={true}
                disabled={disabled}
                title={label}
                onClick={onClick}
                color="primary"
                variant="contained"
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__ADD_ACCESSORY_PARTICIPANT__BUTTON',
                    defaultMessage: 'Přidat',
                  })}
                </Typography>
              </TableFieldToolbarButton>
            )}
            FormFields={FormFields}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            dialogWidth={700}
            formValidationSchema={validationSchema}
          />

          <DetailActionButton
            ref={ref}
            promptKey="EDIT_ACCESSORY_PARTICIPANT"
            apiCall={(id, values) =>
              editAccessoryParticipantApiCall(
                id,
                selectedAccessoryParticipant.id,
                values
              )
            }
            buttonLabel={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__EDIT_ACCESSORY_PARTICIPANT',
              defaultMessage: 'Editovat vedlejšího účastníka',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__EDIT_ACCESSORY_PARTICIPANT__TITLE',
              defaultMessage: 'Vedlejší účastník',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__EDIT_ACCESSORY_PARTICIPANT__TEXT',
              defaultMessage: 'Upravte informace o vedlejším účastníkovi.',
            })}
            ButtonComponent={({ onClick, label }) => (
              <TableFieldToolbarButton
                IconComponent={EditIcon}
                show={true}
                disabled={disabled || !isSelected}
                title={label}
                onClick={onClick}
                color="default"
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__EDIT_ACCESSORY_PARTICIPANT__BUTTON',
                    defaultMessage: 'Upravit',
                  })}
                </Typography>
              </TableFieldToolbarButton>
            )}
            dialogWidth={700}
            FormFields={FormFields}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            formInitialValues={selectedAccessoryParticipant}
            formValidationSchema={validationSchema}
          />

          <DetailActionButton
            promptKey="DELETE_ACCESSORY_PARTICIPANT"
            apiCall={(id) =>
              deleteAccessoryParticipantApiCall(
                id,
                selectedAccessoryParticipant.id
              )
            }
            buttonLabel={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__DELETE_ACCESSORY_PARTICIPANT',
              defaultMessage: 'Odstranit vedlejšího účastníka',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__DELETE_ACCESSORY_PARTICIPANT__TITLE',
              defaultMessage: 'Vedlejší účastník',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__DELETE_ACCESSORY_PARTICIPANT__TEXT',
              defaultMessage: 'Skutečně chcete odstranit vedlejšího účastníka?',
            })}
            ButtonComponent={({ onClick, label }) => (
              <TableFieldToolbarButton
                IconComponent={RemoveIcon}
                show={true}
                disabled={disabled || !isSelected}
                title={label}
                onClick={onClick}
                color="secondary"
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'ES__ACCESSORY_PARTICIPANTS_TOOLBAR__DELETE_ACCESSORY_PARTICIPANT__BUTTON',
                    defaultMessage: 'Smazat',
                  })}
                </Typography>
              </TableFieldToolbarButton>
            )}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
          />
        </ButtonGroup>
      </div>
    );
  }
) as (
  p: TableFieldToolbarProps & {
    disabled: boolean;
    ref: Ref<ActionButtonHandle>;
  }
) => ReactElement;
