import { getErrorMessage } from '@utils/error-message';
import { RefObject, useContext, useRef, useState } from 'react';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  FormHandle,
  SnackbarContext,
  abortableFetch,
} from '@eas/common-web';
import {
  Address,
  ErrorObject,
  IsdsSearchSubjectResponseDto,
  IsdsSubjectRequestDto,
} from '@models';
import { EvidenceApiUrl } from '@enums';

export const identifySubjectApiCall = (id: string) =>
  abortableFetch(`${EvidenceApiUrl.SUBJECTS}/${id}/identify`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

export const searchDataBoxApiCall = (formData: IsdsSubjectRequestDto) => {
  const { address, ...contact } = formData;

  const {
    orientationNumber,
    descriptiveNumber,
    zipCode,
    country,
    ...addressData
  } = (address || {}) as Address;

  const parsedAddress =
    !formData?.address ||
    Object.values(formData.address).every((value) => value === null)
      ? undefined
      : {
          ...addressData,
          zipCode: zipCode?.code,
          country: country?.code,
          numberInMunicipality: descriptiveNumber,
          numberInStreet: orientationNumber,
        };

  return abortableFetch(`${EvidenceApiUrl.SUBJECTS}/isds/search`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      ...contact,
      address: parsedAddress,
    }),
  });
};

export function useSearchDataBoxDialog() {
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, mode, formRef: detailFormRef } = useContext(DetailContext);

  const resultRef = useRef<ActionButtonHandle>(null);
  const [result, setResult] = useState<
    | (IsdsSearchSubjectResponseDto & { subject: IsdsSubjectRequestDto })
    | undefined
  >(undefined);

  const handleSubmit = async (
    formData: IsdsSubjectRequestDto,
    formRef?: RefObject<FormHandle<IsdsSubjectRequestDto>>,
    setLoading?: (value: boolean) => void
  ) => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      let errors = [];

      if (formRef?.current) {
        errors = await formRef?.current?.validateForm();
      }

      if (errors.length === 0) {
        const { subjects, maxResultCountReached } = await searchDataBoxApiCall(
          formData
        ).json();

        if (mode === DetailMode.NEW) {
          detailFormRef?.setFieldValue('identified', true);
        } else {
          await identifySubjectApiCall(source?.data.id).raw();
        }

        setResult({
          subjects,
          subject: formData,
          maxResultCountReached,
        });
        formRef?.current?.setFieldValue('subjects', subjects);
        formRef?.current?.setFieldValue(
          'maxResultCountReached',
          maxResultCountReached
        );

        requestAnimationFrame(() => {
          resultRef.current?.executeAction();
        });
      }

      if (setLoading) {
        setLoading(false);
      }
    } catch (e) {
      if (setLoading) {
        setLoading(false);
      }
      const err = e as ErrorObject<string>;

      const message = getErrorMessage(err);

      if (err.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  };

  return {
    handleSubmit,
    resultRef,
    result,
  };
}
