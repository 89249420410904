import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyComponent, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictSubjectIndex as SubjectIndex } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { useColumns } from './dict-subject-index-columns';
import { Fields } from './dict-subject-index-fields';
import { useValidationSchema } from './dict-subject-index-schema';

export function DictSubjectIndex() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<SubjectIndex>({
    version: 2,
    identifier: 'SUBJECT_INDEX',
    apiProps: {
      url: EvidenceApiUrl.DICT_SUBJECT_INDEX,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.SUBJECT_INDEX,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_SUBJECT_INDEX__TABLE__TITLE',
        defaultMessage: 'Věcný rejstřík',
      }),
      reportTag: ExportTags.DICT_SUBJECT_INDEX,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.SUBJECT_INDEX,
      validationSchema,
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_SUBJECT_INDEX__DETAIL__TITLE',
          defaultMessage: 'Věcný rejstřík',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
