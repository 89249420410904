import React, { useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  FormAutocomplete,
  FormContext,
  FormDictionaryField,
  useScrollableSource,
} from '@eas/common-web';
import { useProposerDistinctions } from '@modules/dict-proposer-distinction/dict-proposer-distinction-api';
import { CONTACTS_SORT, useContacts } from '@modules/subject/subject-api';
import {
  useContactColumns,
  useContactFiltersFields,
} from '@modules/subject/subject-columns';
import { SubjectToolbar } from '@modules/subject/subject-toolbar';
import {
  ContactAutocompleteItem,
  createRowStyle,
} from '@composite/contact/conact-utils';
import {
  autocompleteLabelMapper,
  dictionaryFieldLabelMapper,
} from '@components/form/misc/label-mappers';
import { Subject } from '@models';
import { EvidenceApiUrl } from '@enums';

export function FormFields() {
  const { setFieldValue } = useContext(FormContext);

  const { contactColumns } = useContactColumns({
    labelPrefix: 'Adresát',
  });
  const { contactFiltersFields } = useContactFiltersFields({
    labelPrefix: 'Adresát',
  });

  const createRef = useRef<ActionButtonHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const proposerDistinctions = useProposerDistinctions();
  const contacts = useContacts();
  const contactSource = useScrollableSource({
    url: `${EvidenceApiUrl.SUBJECT_CONTACTS}/list`,
  });

  return (
    <>
      <SubjectToolbar
        createRef={createRef}
        editRef={editRef}
        fieldName="contact.subject"
        onResultValue={(subject: Subject) => {
          setFieldValue('contact', subject.selectedContact);
        }}
      />
      <FormDictionaryField
        name="contact"
        required={true}
        label={
          <FormattedMessage
            id="ES__WRITERS__FIELD_LABEL__SUBJECT"
            defaultMessage="Adresát"
          />
        }
        filters={contactFiltersFields}
        columns={contactColumns}
        autoFocus={true}
        autocompleteSource={contacts}
        dialogSource={contactSource}
        dialogWidth={1000}
        labelMapper={dictionaryFieldLabelMapper}
        onCreate={() => createRef.current?.executeAction()}
        onEdit={() => editRef.current?.executeAction()}
        dialogSorts={CONTACTS_SORT}
        createRowStyle={createRowStyle}
        AutocompleteItemComponent={ContactAutocompleteItem}
      />
      <FormAutocomplete
        name="proposerDistinction"
        label={
          <FormattedMessage
            id="ES__WRITERS__FIELD_LABEL__PROPOSER_DISTINCTION"
            defaultMessage="Rozlišení pisatele"
          />
        }
        labelMapper={autocompleteLabelMapper}
        source={proposerDistinctions}
      />
    </>
  );
}
