/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Dictionary messages
 */
const REVALIDATE: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__DICTIONARY__REVALIDATE__SUCCESS"
      defaultMessage="Záznam byl úspěšně obnoven."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__DICTIONARY__REVALIDATE__ERROR"
      defaultMessage="Chyba při obnovení záznamu."
    />,
    SnackbarVariant.ERROR,
  ],
};

const INVALIDATE: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__DICTIONARY__INVALIDATE__SUCCESS"
      defaultMessage="Záznam byl úspěšně zneplatněn."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__DICTIONARY__INVALIDATE__ERROR"
      defaultMessage="Chyba při zneplatnění záznamu."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default { REVALIDATE, INVALIDATE };
