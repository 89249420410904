import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    { key: 'availableFrom', label: 'Datum a čas zpřístupnění' },
    { key: 'availableTill', label: 'Datum a čas ukončení' },
    { key: 'esFile', label: 'Kárné řízení' },
    { key: 'reason', label: 'Důvod' },
    { key: 'user', label: 'Uživatel' },
  ];
}
