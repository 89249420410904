import { noop } from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Grid from '@material-ui/core/Grid';
import MuiMenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useEventCallback } from '@eas/common-web';
import { DashboardBarChart } from '../bar-chart';
import { DashboardCard } from '../card';
import { useDocumentsStatistics, useSetCustomRange } from '../dashboard-hook';
import { DashboardDivider } from '../divider';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: -10,
  },
  select: {
    minWidth: 200,
  },
  label: {
    display: 'flex',
  },
  value: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: 10,
    marginRight: 10,
  },
  subtitle: {
    padding: 16,
    margin: 0,
    fontWeight: 600,
  },
}));

interface RangeOption {
  id: string;
  label: string;
  value: number;
}

export function DocumentsStatistics() {
  const intl = useIntl();
  const classes = useStyles();

  const customRangeOptionId = 'CUSTOM';
  const rangeOptions = [
    {
      id: 'LAST_WEEK',
      label: 'Poslední týden',
      value: 7,
    },
    {
      id: 'LAST_10_DAYS',
      label: 'Posledních 10 dní',
      value: 10,
    },
    {
      id: 'LAST_MONTH',
      label: 'Poslední měsíc',
      value: 31,
    },
    {
      id: 'LAST_YEAR',
      label: 'Poslední rok',
      value: 365,
    },
    {
      id: customRangeOptionId,
      label: 'Vlastní nastavení',
      value: 0,
    },
  ];

  const [selectedRangeOption, setSelectedRangeOption] = useState<RangeOption>(
    rangeOptions[0]
  );

  const { outgoingDocuments } = useDocumentsStatistics(
    selectedRangeOption!.value
  );

  const handleChange = useEventCallback(
    async (e: ChangeEvent<{ name?: string; value: unknown }>) => {
      const selectedOption = rangeOptions.find(
        (opt) => opt.label === e.target.value
      );

      if (selectedOption && selectedOption?.id !== customRangeOptionId) {
        setSelectedRangeOption(selectedOption);
      }
    }
  );

  const handleSelectCustomOption = (value: number) => {
    const customOption = rangeOptions.find((option) => option.id === 'CUSTOM');

    const editedCustomOption = {
      ...customOption,
      value: value,
    };

    setSelectedRangeOption(editedCustomOption as RangeOption);
  };

  const { handleSetRange } = useSetCustomRange(handleSelectCustomOption);

  return (
    <>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Statistiky dokumentů
      </Typography>

      <DashboardCard>
        <Grid container>
          <DashboardDivider text="Odchozí dokumenty" />
          <div className={classes.wrapper}>
            <div className={classes.label}>
              <Typography>
                {intl.formatMessage({
                  id: 'ES__DASHBOARD__DOCUMENTS__LABEL__LAST',
                  defaultMessage: 'Zobrazeny výsledky za posledních',
                })}
              </Typography>
              <Typography className={classes.value}>
                {selectedRangeOption.value}
              </Typography>
              <Typography>
                {intl.formatMessage({
                  id: 'ES__DASHBOARD__DOCUMENTS__LABEL__DAYS',
                  defaultMessage: 'dní.',
                })}
              </Typography>
            </div>
            <FormControl size="small">
              <Select
                className={classes.select}
                onChange={handleChange}
                value={
                  rangeOptions.find((opt) => opt.id === selectedRangeOption.id)
                    ?.label
                }
                variant="outlined"
              >
                {rangeOptions.map((option) => (
                  <MuiMenuItem
                    key={option.id}
                    value={option.label}
                    onClick={
                      option.id === customRangeOptionId ? handleSetRange : noop
                    }
                  >
                    {option.label}
                  </MuiMenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <DashboardBarChart height={300} data={outgoingDocuments} />
        </Grid>
      </DashboardCard>
    </>
  );
}
