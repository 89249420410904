import { stubFalse } from 'lodash';
import React, { useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  RemoteTableField,
  ScrollableSource,
  TableFieldColumn,
  useUpdateEffect,
} from '@eas/common-web';

interface ResultTableProps<T> {
  title: string;
  source: ScrollableSource<T>;
  columns: TableFieldColumn<T>[];
}

export const useStyles = makeStyles({
  grid: {
    overflow: 'auto',
    backgroundColor: '#fff',
    marginBottom: 20,
  },
  rounded: {
    '&:nth-child(4)': {
      borderRadius: '16px 16px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 16px 16px',
    },
  },
  content: {
    justifyContent: 'space-between',
  },
  root: {
    '&:nth-child(4):before': {
      backgroundColor: 'initial',
    },
  },
});

export function ResultTable<T>({
  title,
  source,
  columns,
}: ResultTableProps<T>) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(source.count > 0);

  useUpdateEffect(() => {
    setExpanded(source.count > 0);
  }, [source.count]);

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      classes={{ root: classes.root, rounded: classes.rounded }}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ root: classes.root, content: classes.content }}
      >
        <Typography>{title}</Typography>
        <Typography>{`[ ${source.items.length} / ${source.count} ]`}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: 0 }}>
        <RemoteTableField
          source={source}
          columns={columns}
          classes={classes}
          showToolbar={false}
          showRadioCond={stubFalse}
          maxRows={7}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
