import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import {
  ApiFilterOperation,
  Evidence,
  TableContext,
  TableSelectedContext,
  UserContext,
} from '@eas/common-web';
import { TableActionButton } from '@components/action-button/table-action-button';
import { EsFileState, Role } from '@enums';
import { useCourtFiles } from '../court-files';
import { callApi as assignFileNumberApiCall } from '../dialog-actions/assign-file-number-hook';
import { useColumns } from './court-file-new-columns';

export function CourtFilesNew() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useCourtFiles({
    evidenceProps: {
      version: 7,
      tableProps: {
        columns,
        defaultPreFilters: [
          {
            field: 'state.id',
            operation: ApiFilterOperation.EQ,
            value: EsFileState.NEW,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__COURT_FILES__NEW__TABLE__TITLE',
          defaultMessage: 'Soudní spisy - Nové spisy',
        }),
        toolbarProps: {
          after: <TableToolbar />,
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}

function TableToolbar() {
  const intl = useIntl();
  const { refresh } = useContext(TableContext);
  const { hasPermission } = useContext(UserContext);
  const { selected } = useContext(TableSelectedContext);

  const showButton = hasPermission(Role.HEAD_OSV);

  return (
    <>
      {showButton && (
        <TableActionButton
          promptKey="ASSIGN_MULTIPLE_FILE_NUMBERS"
          apiCall={(_, selected) =>
            assignFileNumberApiCall(selected.length ? selected : undefined)
          }
          buttonDisabled={selected.length === 0}
          buttonLabel={<LibraryAddIcon />}
          buttonTooltip={intl.formatMessage({
            id: 'ES__COURT_FILES__ACTIONBAR__ASSIGN_MULTIPLE_FILE_NUMBERS',
            defaultMessage: 'Přidělit spisovou značku',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__COURT_FILES__ASSIGN_MULTIPLE_FILE_NUMBERS__TITLE',
            defaultMessage: 'Přidělení spisové značky',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__COURT_FILES__ASSIGN_MULTIPLE_FILE_NUMBERS__TEXT',
            defaultMessage:
              'Opravdu chcete vybraným spisům přidělit spisovou značku?',
          })}
          onError={async () => refresh()}
        />
      )}
    </>
  );
}
