import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { EsFile } from '@models';
import { Agenda } from '@enums';
import { ActionBar as CourtActionBar } from '../es-file-court/court-file-actionbar';
import { ActionBar as DisciplinaryProceedingsActionBar } from '../es-file-disciplinary-proceedings/disciplinary-proceedings-file-actionbar';
import { ActionBar as InfActionBar } from '../es-file-inf/inf-file-actionbar';
import { ActionBar as PlenaryOpinionActionBar } from '../es-file-plenary-opinion/plenary-opinion-file-actionbar';
import { ActionBar as SprActionBar } from '../es-file-spr/spr-file-actionbar';
import { useChangeAgendaDialog } from './dialog-actions/change-agenda/change-agenda-hook';
import { useValidationSchema } from './dialog-actions/change-agenda/change-agenda-schema';
import { useGenerateFileCover } from './dialog-actions/generate-file-cover/generate-file-cover-hook';

const useStyles = makeStyles(() => ({
  wrapper: {
    flexWrap: 'wrap',
  },
}));

const getActionbarForAgenda = (agenda: Agenda | undefined) => {
  switch (agenda) {
    case Agenda.COURT:
      return <CourtActionBar />;
    case Agenda.SPR:
      return <SprActionBar />;
    case Agenda.INF:
      return <InfActionBar />;
    case Agenda.DISCIPLINARY_PROCEEDINGS:
      return <DisciplinaryProceedingsActionBar />;
    case Agenda.PLENARY_OPINION:
      return <PlenaryOpinionActionBar />;
  }
};

export function ActionBar() {
  const classes = useStyles();
  const { mode, source } = useContext<DetailHandle<EsFile>>(DetailContext);

  const intl = useIntl();

  const AgendaActionBar = getActionbarForAgenda(source.data?.agenda);

  const {
    callApi: changeAgendaApiCall,
    showButton: showChangeAgendaButton,
    disableButton: disableChangeAgendaButton,
    redirectBag: changeAgendaRedirectBag,
  } = useChangeAgendaDialog();
  const changeAgendaSchema = useValidationSchema();

  const {
    callApi: generateFileCoverApiCall,
    showButton: showGenerateFileCoverButton,
    disableButton: disabledGenerateFileCoverButton,
  } = useGenerateFileCover();

  return (
    <>
      {mode === DetailMode.VIEW && (
        <div className={classes.wrapper}>
          {showChangeAgendaButton && (
            <DetailActionButton
              promptKey="CHANGE_AGENDA"
              apiCall={changeAgendaApiCall}
              buttonLabel={intl.formatMessage({
                id: 'ES__ES_FILES__ACTIONBAR__CHANGE_AGENDA',
                defaultMessage: 'Změnit agendu',
              })}
              dialogTitle={intl.formatMessage({
                id: 'ES__ES_FILES__DIALOG_CHANGE_AGENDA__TITLE',
                defaultMessage: 'Změna agendy',
              })}
              dialogText={intl.formatMessage({
                id: 'ES__ES_FILES__DIALOG_CHANGE_AGENDA__TEXT',
                defaultMessage: 'Vyberte novou agendu.',
              })}
              ButtonComponent={PrimaryDetailActionbarButton}
              formValidationSchema={changeAgendaSchema}
              buttonDisabled={disableChangeAgendaButton}
              {...changeAgendaRedirectBag}
            />
          )}

          {showGenerateFileCoverButton && (
            <PrimaryDetailActionbarButton
              label={intl.formatMessage({
                id: 'ES__ES_FILES__ACTIONBAR__GENERATE_COVER',
                defaultMessage: 'Obal spisu',
              })}
              onClick={generateFileCoverApiCall}
              disabled={disabledGenerateFileCoverButton}
              isLocked={false}
            />
          )}
          {AgendaActionBar}
        </div>
      )}
    </>
  );
}
