import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useSetDecisionDialog() {
  const callApi = useEventCallback((esFileId, documentId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/inf/${esFileId}/document/outgoing/${documentId}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
        body: JSON.stringify({ decision: true }),
      }
    )
  );

  return {
    callApi,
  };
}

export function useRemoveDecisionDialog() {
  const callApi = useEventCallback((esFileId, documentId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/inf/${esFileId}/document/outgoing/${documentId}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
        body: JSON.stringify({ decision: false }),
      }
    )
  );

  return {
    callApi,
  };
}
