import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RedirectAction } from '@composite/redirect/dialog-redirect';
import { DecisionState } from '@enums';

const OPTION_TO_ANONYMIZE = (
  <FormattedMessage
    id="REDIRECT__OPTION__TO_ANONYMIZE"
    defaultMessage="Pokračovat v rozhodnutích k anonymizaci"
  />
);

const SOURCE_TO_ANONYMIZE = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_TO_ANONYMIZE,
};

const DEST_TO_ANONYMIZE = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_TO_ANONYMIZE,
};

const OPTION_IN_PROGRESS = (
  <FormattedMessage
    id="REDIRECT__OPTION__IN_PROGRESS"
    defaultMessage="Pokračovat v rozpracovaných rozhodnutích"
  />
);

const SOURCE_IN_PROGRESS = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_IN_PROGRESS,
};

const DEST_IN_PROGRESS = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_IN_PROGRESS,
};

const OPTION_TO_MAKE_ACCESSIBLE = (
  <FormattedMessage
    id="REDIRECT__OPTION__TO_MAKE_ACCESSIBLE"
    defaultMessage="Pokračovat v rozhodnutích k zpřístupnění"
  />
);

const SOURCE_TO_MAKE_ACCESSIBLE = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_TO_MAKE_ACCESSIBLE,
};

const DEST_TO_MAKE_ACCESSIBLE = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_TO_MAKE_ACCESSIBLE,
};

const OPTION_ACCESSIBLE = (
  <FormattedMessage
    id="REDIRECT__OPTION__ACCESSIBLE"
    defaultMessage="Pokračovat v zpřístupněných rozhodnutích"
  />
);

const SOURCE_ACCESSIBLE = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_ACCESSIBLE,
};

const DEST_ACCESSIBLE = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_ACCESSIBLE,
};

const OPTION_INDEXING = (
  <FormattedMessage
    id="REDIRECT__OPTION__INDEXING"
    defaultMessage="Pokračovat v rozhodnutích k indexaci"
  />
);

const SOURCE_INDEXING = {
  id: RedirectAction.STAY_IN_EVIDENCE,
  name: OPTION_INDEXING,
};

const DEST_INDEXING = {
  id: RedirectAction.REDIRECT_TO_RESULT,
  name: OPTION_INDEXING,
};

export function getOptions(source?: DecisionState, dest?: DecisionState) {
  const options = [];

  switch (source) {
    case DecisionState.IN_PROGRESS:
      options.push(SOURCE_IN_PROGRESS);
      break;
    case DecisionState.TO_ANONYMIZE:
      options.push(SOURCE_TO_ANONYMIZE);
      break;
    case DecisionState.TO_MAKE_ACCESSIBLE:
      options.push(SOURCE_TO_MAKE_ACCESSIBLE);
      break;
    case DecisionState.ACCESSIBLE:
      options.push(SOURCE_ACCESSIBLE);
      break;
    case DecisionState.INDEXING:
      options.push(SOURCE_INDEXING);
      break;
  }

  switch (dest) {
    case DecisionState.IN_PROGRESS:
      options.push(DEST_IN_PROGRESS);
      break;
    case DecisionState.TO_ANONYMIZE:
      options.push(DEST_TO_ANONYMIZE);
      break;
    case DecisionState.TO_MAKE_ACCESSIBLE:
      options.push(DEST_TO_MAKE_ACCESSIBLE);
      break;
    case DecisionState.ACCESSIBLE:
      options.push(DEST_ACCESSIBLE);
      break;
    case DecisionState.INDEXING:
      options.push(DEST_INDEXING);
      break;
  }

  return options;
}
