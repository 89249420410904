import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  ActionButton,
  FormLocalDateField,
  LocaleContext,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: '20px',
    margin: '-20px 24px 20px 24px',
    justifyContent: 'space-between',
  },
  dateWrapper: {
    display: 'flex',
    gap: '20px',
  },
  labelWrapper: {
    display: 'flex',
    gap: '10px',
  },
  dateLabel: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

export const DatedHeader = ({
  date,
  setDate,
}: {
  date: { dateFrom?: string; dateTo?: string };
  setDate?: (date: { dateFrom: string; dateTo: string }) => void;
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { locale } = useContext(LocaleContext);

  const validationSchema = Yup.object().shape({
    dateFrom: Yup.string().nullable().required(),
    dateTo: Yup.string().nullable().required(),
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.dateWrapper}>
        {date.dateFrom && (
          <div className={classes.labelWrapper}>
            <Typography className={classes.dateLabel}>OD</Typography>
            <Typography>
              {format(new Date(date.dateFrom), locale.dateFormat)}
            </Typography>
          </div>
        )}
        {date.dateTo && (
          <div className={classes.labelWrapper}>
            <Typography className={classes.dateLabel}>DO</Typography>
            <Typography>
              {format(new Date(date.dateTo), locale.dateFormat)}
            </Typography>
          </div>
        )}
      </div>
      {setDate && (
        <ActionButton
          promptKey="CHANGE_DATE"
          buttonLabel={intl.formatMessage({
            id: 'ES__STATISTICS__CHANGE_DATE',
            defaultMessage: 'Upravit parametry',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__STATISTICS__CHANGE_DATE__TITLE',
            defaultMessage: 'Úprava parametrů',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__STATISTICS__CHANGE_DATE__TEXT',
            defaultMessage: 'Vyplňte údaje.',
          })}
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton
              {...props}
              endIcon={<SettingsIcon />}
            />
          )}
          formInitialValues={date}
          FormFields={() => (
            <>
              <FormLocalDateField name="dateFrom" label="Od" required />
              <FormLocalDateField name="dateTo" label="Do" required />
            </>
          )}
          formValidationSchema={validationSchema}
          submitCallback={(values) => setDate(values)}
          dialogDisableBackdrop={true}
        />
      )}
    </div>
  );
};
