import { abortableFetch, useEventCallback } from '@eas/common-web';
import { LegalAdviser } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useEditLegalAdviserDialog() {
  const callApi = useEventCallback(
    (id: string, legalAdviserId: string, formData: LegalAdviser) => {
      return abortableFetch(
        `${EvidenceApiUrl.ES_FILES}/${id}/participant/${legalAdviserId}`,
        {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(formData),
        }
      );
    }
  );

  return {
    callApi,
  };
}
