import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TableFieldCells, TableFieldColumn } from '@eas/common-web';
import { Viewing } from '@models';

export function useViewingColumns(): TableFieldColumn<Viewing>[] {
  const intl = useIntl();

  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: 'ES__VIEWINGS__COLUMN__USER',
          defaultMessage: 'Uživatel',
        }),
        datakey: 'user.label',
        width: 300,
      },
      {
        name: intl.formatMessage({
          id: 'ES__VIEWINGS__COLUMN__AVAILABLE_FROM',
          defaultMessage: 'Datum a čas zpřístupnění',
        }),
        datakey: 'availableFrom',
        width: 150,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        name: intl.formatMessage({
          id: 'ES__VIEWINGS__COLUMN__AVAILABLE_TILL',
          defaultMessage: 'Datum a čas ukončení',
        }),
        datakey: 'availableTill',
        width: 150,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        name: intl.formatMessage({
          id: 'ES__VIEWINGS__COLUMN__REASON',
          defaultMessage: 'Důvod',
        }),
        datakey: 'reason',
        sortkey: 'reason',
        width: 300,
      },
    ],
    []
  );
}
