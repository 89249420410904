import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { Decision } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useRegenerateAttachmentsDialog() {
  const { source } = useContext<DetailHandle<Decision>>(DetailContext);

  const callApi = useEventCallback(() =>
    abortableFetch(
      `${EvidenceApiUrl.DECISIONS}/${source?.data?.id}/attachments/regenerate`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  return {
    callApi,
  };
}
