import * as Yup from 'yup';
import { icoValidation } from '@composite/contact/contact-schema';
import { Contact, Subject } from '@models';
import { SubjectType } from '@enums';

export function useValidationSchema() {
  return Yup.object<Subject>().shape(subjectSchema);
}

export function useSubjectToolbarValidationSchema() {
  return Yup.object<Subject>().shape({
    selectedContact: Yup.mixed<Contact>()
      .test('', '', function (value) {
        const { createError } = this;

        if (!value) {
          return createError({
            path: 'contacts',
            message: 'Musí být vybrán jeden kontakt.',
          });
        }
        return true;
      })
      .nullable(),
    ...subjectSchema,
  }) as Yup.Schema<Subject>;
}

const subjectSchema = {
  type: Yup.mixed<SubjectType>().nullable().required('Musí být vyplněné'),
  firstName: Yup.string()
    .nullable()
    .test('', '', function (value) {
      const { createError, path, parent } = this;

      if (
        !value &&
        parent.type === SubjectType.NATURAL_PERSON &&
        !parent.lastName
      ) {
        return createError({
          path,
          message: 'Musí být vyplněné',
        });
      }

      if (
        !value &&
        parent.type === SubjectType.UNSPECIFIED &&
        !parent.companyName &&
        !parent.lastName
      ) {
        return createError({
          path,
          message: 'Musí být vyplněné, pokud není vyplněno příjmení ani název',
        });
      }

      return true;
    }),
  birthDate: Yup.string()
    .nullable()
    .test('', '', function (value) {
      const { createError, path } = this;

      if (value && new Date(value) > new Date()) {
        return createError({ path, message: 'Datum nesmí být v budoucnosti' });
      }

      return true;
    }),
  lastName: Yup.string()
    .nullable()
    .test('', '', function (value) {
      const { createError, path, parent } = this;

      if (
        !value &&
        parent.type === SubjectType.NATURAL_PERSON &&
        !parent.firstName
      ) {
        return createError({
          path,
          message: 'Musí být vyplněné',
        });
      }

      if (
        !value &&
        parent.type === SubjectType.UNSPECIFIED &&
        !parent.companyName &&
        !parent.firstName
      ) {
        return createError({
          path,
          message: 'Musí být vyplněné, pokud není vyplněno jméno ani název',
        });
      }

      return true;
    }),
  contacts: Yup.array<Contact>()
    .nullable()
    .min(1, 'Povinné pole')
    .required('Povinné pole'),
  ico: icoValidation.notRequired(),
  companyName: Yup.string()
    .nullable()
    .test('', '', function (value) {
      const { createError, path, parent } = this;

      if (
        !value &&
        [
          SubjectType.BUSINESS_NATURAL_PERSON,
          SubjectType.LEGAL_ENTITY,
        ].includes(parent.type)
      ) {
        return createError({
          path,
          message: 'Musí být vyplněné',
        });
      }

      if (
        !value &&
        parent.type === SubjectType.UNSPECIFIED &&
        !parent.lastName &&
        !parent.firstName
      ) {
        return createError({
          path,
          message: 'Musí být vyplněné, pokud není vyplněno jméno ani příjmení',
        });
      }

      return true;
    }),
};
