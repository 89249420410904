import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormLocalDateTimeField,
  FormTextArea,
  useFormSelector,
} from '@eas/common-web';
import { useUsers } from '@modules/user/user-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Viewing } from '@models';

export function ViewingDialog() {
  const users = useUsers();

  const isExisting = useFormSelector((values: Viewing) => !!values?.id);

  return (
    <>
      <FormAutocomplete
        name="user"
        label={
          <FormattedMessage
            id="ES__VIEWINGS__FIELD_LABEL__USER"
            defaultMessage="Uživatel"
          />
        }
        source={users}
        labelMapper={autocompleteLabelMapper}
        autoFocus={true}
        disabled={isExisting}
      />
      <FormLocalDateTimeField
        name="availableFrom"
        label={
          <FormattedMessage
            id="ES__VIEWINGS__FIELD_LABEL__AVAILABLE_FROM"
            defaultMessage="Datum a čas zpřístupnění"
          />
        }
        disabled={isExisting}
      />
      <FormLocalDateTimeField
        name="availableTill"
        label={
          <FormattedMessage
            id="ES__VIEWINGS__FIELD_LABEL__AVAILABLE_TILL"
            defaultMessage="Datum a čas ukončení"
          />
        }
      />
      <FormTextArea
        name="reason"
        label={
          <FormattedMessage
            id="ES__VIEWINGS__FIELD_LABEL__AVAILABLE_TILL"
            defaultMessage="Důvod"
          />
        }
        minRows={3}
      />
    </>
  );
}
