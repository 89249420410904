/**
 * Služby REST
 */
export enum RestServiceName {
  /**
   * eSSL
   */
  ESSL = 'ESSL',

  /**
   * NALUS
   */
  NALUS = 'NALUS',

  /**
   * ISDS
   */
  ISDS = 'ISDS',
}
