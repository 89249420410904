import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  DictionaryObject,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { Dispatch } from '@models';
import {
  DispatchMethod,
  DispatchState,
  RecipientSource,
  UIElement,
} from '@enums';
import { useAddDispatchDialog } from './dialog-actions/add-dispatch-hook';
import { useDeleteDispatchDialog } from './dialog-actions/delete-dispatch-hook';
import { useEditDispatchDialog } from './dialog-actions/edit-dispatch-hook';
import {
  GenerateEnvelopesDialog,
  callApi as generateEnvelopesApiCall,
} from './dialog-actions/generate-envelopes-hook';
import {
  SendByNoticeBoardDialog,
  callApi as sendByNoticeBoardApiCall,
  useSendByNoticeBoardValidationSchema,
} from './dialog-actions/send-by-notice-board';
import { DispatchDialog } from './dispatch-dialog';
import { useValidationSchema } from './dispatch-schema';

type DispatchFieldToolbarProps = TableFieldToolbarProps & {
  dispatchMethods: DictionaryObject[];
  dispatchStates: DictionaryObject[];
  dispatchDeliveryMethods: DictionaryObject[];
  disabled: boolean;
  hasPermission: (element: string) => boolean;
};

export const DispatchToolbar = forwardRef(function DispatchesToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
    dispatchMethods,
    dispatchStates,
    dispatchDeliveryMethods,
    hasPermission,
  }: DispatchFieldToolbarProps,
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();
  const schema = useValidationSchema();
  const sendByNoticeBoardSchema = useSendByNoticeBoardValidationSchema();

  const intl = useIntl();

  const { source: detailSource } = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const dispatches = tableSource.items;

  const { handleSubmit: handleCreateDispatch } = useAddDispatchDialog();
  const { handleSubmit: handleEditDispatch } = useEditDispatchDialog();
  const { callApi: deleteDispatchApiCall } = useDeleteDispatchDialog();

  const selectedDispatch =
    selectedIndex !== undefined ? dispatches?.[selectedIndex] : {};

  const isSelected = selectedIndex !== undefined;

  useEffect(() => {
    setSelectedIndex(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailSource]);

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_DISPATCH"
          submitCallback={handleCreateDispatch}
          buttonLabel={intl.formatMessage({
            id: 'ES__DISPATCHES_TOOLBAR__ADD_DISPATCH',
            defaultMessage: 'Přidat vypravení',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DISPATCHES_TOOLBAR__ADD_DISPATCH__TITLE',
            defaultMessage: 'Vypravení',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DISPATCHES_TOOLBAR__ADD_DISPATCH__TEXT',
            defaultMessage: 'Vyplňte informace o vypravení.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={
                disabled || !hasPermission(UIElement.Dispatch.ADD_DISPATCH)
              }
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__DISPATCHES_TOOLBAR__ADD_DISPATCH__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={(props) => (
            <DispatchDialog
              {...props}
              dispatchMethods={dispatchMethods}
              dispatchStates={dispatchStates}
              dispatchDeliveryMethods={dispatchDeliveryMethods}
            />
          )}
          formValidationSchema={schema}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formInitialValues={
            {
              state: DispatchState.PREPARED,
              method: DispatchMethod.LETTER,
              document: detailSource.data,
              recipientSource: RecipientSource.PARTICIPANTS,
            } as Dispatch
          }
          dialogWidth={800}
        />

        <DetailActionButton
          ref={ref}
          promptKey="EDIT_DISPATCH"
          submitCallback={handleEditDispatch}
          buttonLabel={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__EDIT_DISPATCH',
            defaultMessage: 'Editovat vypravení',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__EDIT_DISPATCH__TITLE',
            defaultMessage: 'Vypravení',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__EDIT_DISPATCH__TEXT',
            defaultMessage: 'Upravte informace o vypravení.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={
                disabled ||
                !isSelected ||
                !hasPermission(UIElement.Dispatch.EDIT_DISPATCH)
              }
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__DISPATCHS_TOOLBAR__EDIT_DISPATCH__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          dialogWidth={700}
          FormFields={(props) => (
            <DispatchDialog
              {...props}
              dispatchMethods={dispatchMethods}
              dispatchStates={dispatchStates}
              dispatchDeliveryMethods={dispatchDeliveryMethods}
            />
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formInitialValues={{
            ...selectedDispatch,
            recipientSource: RecipientSource.DIRECTORY,
            recipient: {
              ...selectedDispatch?.recipient,
              type: selectedDispatch?.recipient?.contact?.type,
              originalContact: {
                email: selectedDispatch?.recipient?.email,
                dataBoxId: selectedDispatch?.recipient?.dataBoxId,
                type: selectedDispatch?.recipient?.contact?.type,
                country: selectedDispatch?.recipient?.country,
                city: selectedDispatch?.recipient?.city,
                street: selectedDispatch?.recipient?.street,
                orientationNumber:
                  selectedDispatch?.recipient?.orientationNumber,
                descriptiveNumber:
                  selectedDispatch?.recipient?.descriptiveNumber,
                zipCode: selectedDispatch?.recipient?.zipCode,
              },
            },
          }}
          formValidationSchema={schema}
        />

        <DetailActionButton
          promptKey="DELETE_DISPATCH"
          apiCall={() => deleteDispatchApiCall(selectedDispatch.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__DELETE_DISPATCH',
            defaultMessage: 'Odstranit vypravení',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__DELETE_DISPATCH__TITLE',
            defaultMessage: 'Vypravení',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__DELETE_DISPATCH__TEXT',
            defaultMessage: 'Skutečně chcete odstranit vypravení?',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={
                disabled ||
                !isSelected ||
                !hasPermission(UIElement.Dispatch.DELETE_DISPATCH)
              }
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__DISPATCHS_TOOLBAR__DELETE_DISPATCH__BUTTON',
                  defaultMessage: 'Smazat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="SEND_BY_NOTICE_BOARD"
          apiCall={(_, values) =>
            sendByNoticeBoardApiCall(selectedDispatch.id, values)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__SEND_BY_NOTICE_BOARD',
            defaultMessage: 'Doručit úřední deskou',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__SEND_BY_NOTICE_BOARD__TITLE',
            defaultMessage: 'Doručit úřední deskou',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DISPATCHS_TOOLBAR__SEND_BY_NOTICE_BOARD__TEXT',
            defaultMessage: 'Vyplňte formulář.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={SendIcon}
              show={true}
              disabled={
                disabled || !isSelected
                // || !hasPermission(UIElement.Dispatch.SEND_BY_NOTICE_BOARD)
              }
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__DISPATCHS_TOOLBAR__SEND_BY_NOTICE_BOARD__BUTTON',
                  defaultMessage: 'Doručit úřední deskou',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          dialogWidth={700}
          FormFields={SendByNoticeBoardDialog}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formValidationSchema={sendByNoticeBoardSchema}
        />
        <DetailActionButton
          promptKey="GENERATE_ENVELOPES"
          apiCall={generateEnvelopesApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENT_OUTGOING__ACTIONBAR__GENERATE_ENVELOPES',
            defaultMessage: 'Generovat obálky',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENT_OUTGOING__ACTIONBAR__GENERATE_ENVELOPES__TITLE',
            defaultMessage: 'Generovat obálky',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENT_OUTGOING__ACTIONBAR__GENERATE_ENVELOPES__TEXT',
            defaultMessage:
              'Vyplněním formuláře vygenerujete obálky pro odchozí dokument.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={
                disabled ||
                !hasPermission(UIElement.Dispatch.GENERATE_ENVELOPES)
              }
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__DOCUMENT_OUTGOING__ACTIONBAR__GENERATE_ENVELOPES__BUTTON',
                  defaultMessage: 'Generovat obálky',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={GenerateEnvelopesDialog}
          dialogWidth={800}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: DispatchFieldToolbarProps & {
    ref: Ref<ActionButtonHandle>;
  }
) => ReactElement;
