import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { RedirectForm, useRedirect } from '@composite/redirect/dialog-redirect';
import { CourtFile, EsFile } from '@models';
import { EsFileState, EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';
import { getOptions } from './change-state-utils';

export function useChangeStateDialog(state: EsFileState) {
  const match = useRouteMatch();

  const { source } = useContext<DetailHandle<CourtFile>>(DetailContext);

  const inAllFiles = [
    EvidenceBrowserUrl.ES_FILES_ALL + '/:id?',
    EvidenceBrowserUrl.ES_FILES_SEARCH + '/:id?',
    match.path === EvidenceBrowserUrl.COURT_FILES_ALL + '/:id?',
  ].includes(match.path);

  const callApi = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.ES_FILES}/${id}/state/${state}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    })
  );

  const initialState = source.data?.state;

  const redirectBag = useRedirect<EsFile, RedirectForm>({
    options: () => getOptions(source.data?.state, state ?? initialState),
    redirectTo: () => {
      switch (state) {
        case EsFileState.JUDGE_OFFICE:
          return EvidenceBrowserUrl.COURT_FILES_JUDGE_OFFICE;
        case EsFileState.REGISTER_NEW:
          return EvidenceBrowserUrl.COURT_FILES_REGISTER_NEW;
        case EsFileState.REGISTER:
          return EvidenceBrowserUrl.COURT_FILES_REGISTER;
        case EsFileState.INDEX:
          return EvidenceBrowserUrl.COURT_FILES_INDEX;
        case EsFileState.REGISTRY:
          return EvidenceBrowserUrl.COURT_FILES_REGISTRY;
        case EsFileState.CLOSED:
          return EvidenceBrowserUrl.COURT_FILES_CLOSED;

        default:
          return EvidenceBrowserUrl.ES_FILES_ALL;
      }
    },
  });

  return {
    callApi,
    redirectBag: inAllFiles ? {} : { ...redirectBag },
  };
}
