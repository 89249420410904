import { eqFilterParams, useFetch, useStaticListSource } from '@eas/common-web';
import { DictContactType, DictionaryAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useContactTypes() {
  const [data] = useFetch<{ items: DictionaryAutocomplete[] }>(
    `${EvidenceApiUrl.DICT_CONTACT_TYPES}/autocomplete`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        size: -1,
      }),
    }
  );

  return useStaticListSource(data?.items ?? []);
}

export async function getDefaultContactType() {
  const response = await fetch(`${EvidenceApiUrl.DICT_CONTACT_TYPES}/list`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(eqFilterParams({ field: 'isDefault', value: true })),
  });

  const json = await response.json();

  return json.items[0] as DictContactType;
}
