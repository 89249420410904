import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { DraftField } from '@composite/drafts/draft-field';
import { EsFile } from '@models';
import { UIElement } from '@enums';
import { DRAFT_SOURCE } from '../../../es-file/es-file-hooked-sources';
import { useInfFilePermission } from '../../../es-file/es-file-permission';

export function DraftsFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } = useContext<DetailHandle<EsFile>>(
    DetailContext
  );

  const { hasPermission } = useInfFilePermission(source.data);

  // eslint-disable-next-line prettier/prettier
  const disabledDrafts =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.InfFile.DRAFTS);
  const draftSource = hookedSources[DRAFT_SOURCE]?.source;

  return (
    <>
      <DraftField disabled={disabledDrafts} source={draftSource} />
    </>
  );
}
