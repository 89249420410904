import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonComponentProps,
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { LegalAdviser } from '@models';
import { LegalAdviserType } from '@enums';
import { useAddLegalAdviserDialog } from './dialog-actions/add-legal-adviser-hook';
import { useDeleteLegalAdviserDialog } from './dialog-actions/delete-legal-adviser-hook';
import { useEditLegalAdviserDialog } from './dialog-actions/edit-legal-adviser-hook';
import { LegalAdviserDialog } from './legal-adviser-dialog';
import { useValidationSchema } from './legal-adviser-schema';

export const LegalAdviserToolbar = forwardRef(function LegalAdviserToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
  }: TableFieldToolbarProps & { disabled: boolean },
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();

  const intl = useIntl();

  const { source: detailSource } = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const legalAdvisers = tableSource.items;

  const validationSchema = useValidationSchema();

  const { callApi: addLegalAdviserApiCall } = useAddLegalAdviserDialog();
  const { callApi: editLegalAdviserApiCall } = useEditLegalAdviserDialog();
  const { callApi: deleteLegalAdviserApiCall } = useDeleteLegalAdviserDialog();

  const selectedLegalAdviser =
    selectedIndex !== undefined ? legalAdvisers[selectedIndex] : {};

  const isSelected = selectedIndex !== undefined;

  useEffect(
    () => {
      setSelectedIndex(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailSource]
  );

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_LEGAL_ADVISER"
          apiCall={addLegalAdviserApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__LEGAL_ADVISERS_TOOLBAR__ADD_LEGAL_ADVISER',
            defaultMessage: 'Přidat právního zástupce',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DIALOG_ADD_LEGAL_ADVISER__TITLE',
            defaultMessage: 'Právní zástupce',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DIALOG_ADD_LEGAL_ADVISER__TEXT',
            defaultMessage: 'Vyplňte formulář.',
          })}
          ButtonComponent={({ label, onClick }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__LEGAL_ADVISERS_TOOLBAR__ADD_LEGAL_ADVISER__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={LegalAdviserDialog}
          formValidationSchema={validationSchema}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formInitialValues={
            { legalAdviserType: LegalAdviserType.PROPOSER } as LegalAdviser
          }
          dialogWidth={700}
        />
        <DetailActionButton
          ref={ref}
          promptKey="EDIT_LEGAL_ADVISER"
          apiCall={(id, formData) =>
            editLegalAdviserApiCall(id, selectedLegalAdviser.id, formData)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__LEGAL_ADVISERS_TOOLBAR__EDIT_LEGAL_ADVISER',
            defaultMessage: 'Editovat právního zástupce',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__LEGAL_ADVISERS_TOOLBAR__EDIT_LEGAL_ADVISER__TITLE',
            defaultMessage: 'Právní zástupce',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__LEGAL_ADVISERS_TOOLBAR__EDIT_LEGAL_ADVISER__TEXT',
            defaultMessage: 'Vyplňte formulář.',
          })}
          dialogWidth={700}
          FormFields={LegalAdviserDialog}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__LEGAL_ADVISERS_TOOLBAR__EDIT_LEGAL_ADVISER__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          formInitialValues={selectedLegalAdviser}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formValidationSchema={validationSchema}
        />
        <DetailActionButton
          promptKey="DELETE_LEGAL_ADVISER"
          apiCall={(id) =>
            deleteLegalAdviserApiCall(id, selectedLegalAdviser.id)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__LEGAL_ADVISERS_TOOLBAR__DELETE_LEGAL_ADVISER',
            defaultMessage: 'Odstranit právního zástupce',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__LEGAL_ADVISERS_TOOLBAR__DELETE_LEGAL_ADVISER__TITLE',
            defaultMessage: 'Právní zástupce',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__LEGAL_ADVISERS_TOOLBAR__DELETE_LEGAL_ADVISER__TEXT',
            defaultMessage: 'Skutečně chcete odstranit právního zástupce?',
          })}
          ButtonComponent={({ onClick, label }: ActionButtonComponentProps) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">Smazat</Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    ref: Ref<ActionButtonHandle>;
    disabled: boolean;
  }
) => ReactElement;
