import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButton2,
  DetailToolbarButtonType,
  EvidenceDefaultsContext,
} from '@eas/common-web';

export function DocumentToolbar() {
  const history = useHistory();

  const defaults = useContext(EvidenceDefaultsContext);
  const { mode, save, cancelEditing } = useContext(DetailContext);

  const prevMode = useRef(mode);

  const DetailToolbarButtonComponent =
    defaults.ToolbarButtonComponent ?? DetailToolbarButton2;

  const showSave = mode === DetailMode.NEW;
  const showClose = mode === DetailMode.NEW;

  useEffect(() => {
    if (prevMode.current === DetailMode.NEW && mode === DetailMode.VIEW) {
      history.go(-2);
    }

    prevMode.current = mode;
  }, [mode]);

  return (
    <>
      {showSave && (
        <DetailToolbarButtonComponent
          startIcon={<SaveIcon />}
          label={
            <FormattedMessage
              id="ES__DETAIL__ACTIONBAR__SAVE"
              defaultMessage="Uložit"
            />
          }
          onClick={() => {
            save();
          }}
          disabled={false}
          type={DetailToolbarButtonType.PRIMARY}
        />
      )}
      {showClose && (
        <DetailToolbarButtonComponent
          startIcon={<UndoIcon />}
          label={
            <FormattedMessage
              id="ES__DETAIL__ACTIONBAR__CLOSE"
              defaultMessage="Zrušit"
            />
          }
          disabled={false}
          onClick={() => {
            cancelEditing();
            history.goBack();
          }}
        />
      )}
    </>
  );
}
