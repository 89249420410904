import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  Evidence,
  ScheduleContext,
  ScheduleJob,
  scheduleJobsFactory,
} from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { JobsActionbar } from './schedule-job-actionbar';

export function ScheduleJobs() {
  const validationSchema = scheduleJobsFactory.useValidationSchema();
  const { jobUrl: url } = useContext(ScheduleContext);

  const evidence = useDictionaryEvidence<ScheduleJob>({
    identifier: 'SCHEDULE_JOBS',
    apiProps: {
      url,
    },
    tableProps: {
      columns: scheduleJobsFactory.useColumns(),
      tableName: 'Časové úlohy',
    },
    detailProps: {
      toolbarProps: {
        title: 'Časová úloha',
        ActionBar: DictionaryEvidence.dictionaryActionbarFactory({
          Before: JobsActionbar,
        }),
      },
      FieldsComponent: scheduleJobsFactory.Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
