import { format, parseISO } from 'date-fns';
import { noop, stubFalse } from 'lodash';
import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  FormPanel,
  LocaleContext,
  TableField,
  useFormSelector,
} from '@eas/common-web';
import { Tabs } from '@composite/tabs/tabs';
import { Tab } from '@composite/tabs/tabs-types';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  CollectionHistoryField,
  HistoryFieldUnion,
  RawSimpleLog,
} from '@models';
import { HistoryFieldType } from '@enums';
import { CollectionField } from './fields/collection-history-field';
import { useDetailColumns } from './history-columns';
import { HistoryContext } from './history-context';

const useStyles = makeStyles(() => ({
  labeledField: {
    display: 'flex',
  },
  wrapper: {
    width: 1400,
  },
  fieldsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  label: {
    marginRight: 10,
    fontWeight: 600,
  },
  value: {},
}));

export function HistoryFields() {
  const classes = useStyles();
  const { descriptors } = useContext(HistoryContext);
  const { locale } = useContext(LocaleContext);
  const { historyEventTypes } = useContext(StaticDataContext);

  const { content, type, created, createdBy } = useFormSelector(
    (data: RawSimpleLog) => ({
      content: JSON.parse(data.content ?? '[]'),
      type: data.type,
      created: format(parseISO(data.created ?? ''), locale.dateFormat),
      createdBy: data.createdBy?.name,
    })
  );

  const collections = content.filter(
    (field: HistoryFieldUnion) => field.type === HistoryFieldType.COLLECTION
  );
  const restAttributes = content.filter(
    (field: HistoryFieldUnion) => field.type !== HistoryFieldType.COLLECTION
  );

  let config = collections
    .filter((collection: CollectionHistoryField) => collection.changes.length)
    .map((collection: CollectionHistoryField) => ({
      label:
        descriptors.find((descriptor) => descriptor.key === collection.key)
          ?.label ?? '',
      key: collection.key,
      content: <CollectionField {...collection} />,
    }));

  if (restAttributes.length) {
    config = [
      {
        label: 'Atributy záznamu',
        key: 'general',
        content: <ChangedAttributes data={restAttributes} />,
      },
      ...config,
    ];
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.fieldsWrapper}>
        <div className={classes.labeledField}>
          <Typography className={classes.label}>Typ události:</Typography>
          <Typography className={classes.value}>
            {historyEventTypes.find((event) => event.id === type)?.name}
          </Typography>
        </div>
        <div className={classes.labeledField}>
          <Typography className={classes.label}>Datum:</Typography>
          <Typography className={classes.value}>{created}</Typography>
        </div>
        <div className={classes.labeledField}>
          {createdBy && (
            <>
              <Typography className={classes.label}>Autor:</Typography>
              <Typography className={classes.value}>{createdBy}</Typography>
            </>
          )}
        </div>
      </div>
      <Tabs
        config={config}
        onClick={(setActiveTab, selectedTab) => {
          const index = config.findIndex(
            (tab: Tab) => tab.key === selectedTab.key
          );
          setActiveTab(index);
        }}
      />
    </div>
  );
}

const ChangedAttributes = ({ data }: { data: HistoryFieldUnion[] }) => {
  const columns = useDetailColumns();

  return (
    <FormPanel
      label="Změněné atributy"
      defaultExpanded={true}
      expandable={true}
    >
      <TableField<HistoryFieldUnion>
        value={data}
        showToolbar={false}
        maxRows={15}
        columns={columns}
        onChange={noop}
        showRadioCond={stubFalse}
      />
    </FormPanel>
  );
};
