import { HistoryFieldDescriptor } from './history-types';

export function useCommonHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'created',
      label: 'Datum a čas vytvoření',
    },
    {
      key: 'createdDate',
      label: 'Datum vytvoření',
    },
    {
      key: 'createdBy.id',
      label: 'Vytvořil (id)',
    },
    {
      key: 'createdBy.name',
      label: 'Vytvořil (jméno)',
    },
    {
      key: 'createdByDelegate.name',
      label: 'Vytvořeno v zástupu (jméno)',
    },
    {
      key: 'createdByDelegate.id',
      label: 'Vytvořeno v zástupu (id)',
    },
    {
      key: 'deleted',
      label: 'Datum odstranění',
    },
    {
      key: 'deletedBy.id',
      label: 'Odstranil (id)',
    },
    {
      key: 'deletedBy.id',
      label: 'Odstranil (jméno)',
    },
    {
      key: 'updated',
      label: 'Datum editace',
    },
    {
      key: 'updatedBy.id',
      label: 'Editoval (id)',
    },
    {
      key: 'updatedBy.name',
      label: 'Editoval (jméno)',
    },
    {
      key: 'updatedByDelegate.id',
      label: 'Editováno v zástupu (id)',
    },
    {
      key: 'updatedByDelegate.name',
      label: 'Editováno v zástupu (jméno)',
    },
    {
      key: 'id',
      label: 'Id',
    },
    {
      key: 'label',
      label: 'Popisek',
    },
    {
      key: 'name',
      label: 'Název',
    },
    {
      key: 'order',
      label: 'Pořadí',
    },
    {
      key: 'code',
      label: 'Kód',
    },
    {
      key: 'version',
      label: 'Verze',
    },
    {
      key: 'description',
      label: 'Popis',
    },
    {
      key: 'validAndActive',
      label: 'Validní a aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
  ];
}
