import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EsFile } from '@models';
import { EsFileState, EvidenceApiUrl } from '@enums';

export function useMergeFilesDialog() {
  const { source } = useContext<DetailHandle<EsFile>>(DetailContext);

  const callApi = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.ES_FILES}/${id}/merge`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showButton =
    !!source?.data?.state &&
    [
      EsFileState.REGISTER,
      EsFileState.REGISTER_NEW,
      EsFileState.JUDGE_OFFICE,
    ].includes(source.data?.state);

  return { callApi, showButton };
}
