import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';

export function useDeleteNoteDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback((noteId: string) => {
    return abortableFetch(`${source.url}/note/${noteId}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  });

  return {
    callApi,
  };
}
