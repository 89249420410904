import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  RemoteTableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { useAddProposerDialog } from './dialog-actions/add-proposer-hook';
import { useDeleteProposerDialog } from './dialog-actions/delete-proposer-hook';
import { useEditProposerDialog } from './dialog-actions/edit-proposer-hook';
import { FormFields } from './proposer-dialog';
import { useValidationSchema } from './proposer-schema';

export const ProposerToolbar = forwardRef(function ProposersToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
  }: TableFieldToolbarProps & { disabled: boolean },
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();

  const intl = useIntl();

  const { source } = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const proposers = tableSource.items;

  const validationSchema = useValidationSchema();

  const { callApi: addProposerApiCall } = useAddProposerDialog();
  const { callApi: editProposerApiCall } = useEditProposerDialog();
  const { callApi: deleteProposerApiCall } = useDeleteProposerDialog();

  const selectedProposer =
    selectedIndex !== undefined ? proposers?.[selectedIndex] : {};

  const isSelected = selectedIndex !== undefined;

  useEffect(
    () => {
      setSelectedIndex(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source]
  );

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        <DetailActionButton
          promptKey="ADD_PROPOSER"
          apiCall={addProposerApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__ADD_PROPOSER',
            defaultMessage: 'Přidat navrhovatele',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__ADD_PROPOSER__TITLE',
            defaultMessage: 'Navrhovatel',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__ADD_PROPOSER__TEXT',
            defaultMessage: 'Vyplňte informace o navrhovateli.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={ControlPointIcon}
              show={true}
              disabled={disabled}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__PROPOSERS_TOOLBAR__ADD_PROPOSER__BUTTON',
                  defaultMessage: 'Přidat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          FormFields={FormFields}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          dialogWidth={700}
          formValidationSchema={validationSchema}
        />

        <DetailActionButton
          ref={ref}
          promptKey="EDIT_PROPOSER"
          apiCall={(id, values) =>
            editProposerApiCall(id, selectedProposer.id, values)
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__EDIT_PROPOSER',
            defaultMessage: 'Editovat navrhovatele',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__EDIT_PROPOSER__TITLE',
            defaultMessage: 'Navrhovatel',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__EDIT_PROPOSER__TEXT',
            defaultMessage: 'Upravte informace o navrhovateli.',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={EditIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="default"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__PROPOSERS_TOOLBAR__EDIT_PROPOSER__BUTTON',
                  defaultMessage: 'Upravit',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          dialogWidth={700}
          FormFields={FormFields}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
          formInitialValues={selectedProposer}
          formValidationSchema={validationSchema}
        />

        <DetailActionButton
          promptKey="DELETE_PROPOSER"
          apiCall={(id) => deleteProposerApiCall(id, selectedProposer.id)}
          buttonLabel={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__DELETE_PROPOSER',
            defaultMessage: 'Odstranit navrhovatele',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__DELETE_PROPOSER__TITLE',
            defaultMessage: 'Navrhovatel',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__PROPOSERS_TOOLBAR__DELETE_PROPOSER__TEXT',
            defaultMessage: 'Skutečně chcete odstranit navrhovatele?',
          })}
          ButtonComponent={({ onClick, label }) => (
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={disabled || !isSelected}
              title={label}
              onClick={onClick}
              color="secondary"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__PROPOSERS_TOOLBAR__DELETE_PROPOSER__BUTTON',
                  defaultMessage: 'Smazat',
                })}
              </Typography>
            </TableFieldToolbarButton>
          )}
          modes={[DetailMode.VIEW, DetailMode.EDIT]}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    disabled: boolean;
    ref: Ref<ActionButtonHandle>;
  }
) => ReactElement;
