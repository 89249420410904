import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EsFileRegulation } from '@models';
import { EvidenceApiUrl, RegulationEntityType } from '@enums';

export function useEditRegulationDialog() {
  const callApi = useEventCallback(
    (esFileId: string, regulationId: string, formData: EsFileRegulation) => {
      const { sectionOrArticlePart, ...rest } = formData;

      return abortableFetch(
        `${EvidenceApiUrl.ES_FILES}/${esFileId}/regulation/${regulationId}`,
        {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(
            formData.type === RegulationEntityType.ARTICLE
              ? { ...rest, article: sectionOrArticlePart }
              : { ...rest, sectionSign: sectionOrArticlePart }
          ),
        }
      );
    }
  );

  return {
    callApi,
  };
}
