/**
 * Stav úlohy
 */
export enum TaskState {
  /**
   * Ke zpracování
   */
  SOLVING = 'SOLVING',

  /**
   * Hotovo
   */
  PROCESSED = 'PROCESSED',

  /**
   * Stornováno
   */
  CANCELED = 'CANCELED',
}

/**
 * Typ úlohy
 */
export enum TaskType {
  /**
   * Automatická úloha
   */
  SYSTEM = 'SYSTEM',

  /**
   * Uživatelská úloha
   */
  CUSTOM = 'CUSTOM',
}
