import { noop } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TableField, UserContext } from '@eas/common-web';
import { DashboardDivider } from '@modules/dashboard/divider';
import { useUserRoleColumns } from '@composite/user-roles/user-role-columns';

const useStyles = makeStyles({
  card: {
    width: '100%',
    borderRadius: 16,
    background:
      'linear-gradient(0deg, rgb(245 246 247) 0%, rgb(255 255 255) 100%)',
    margin: '0 0 24px',
    padding: 20,
  },
});

export function UserRoles() {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  const roleColumns = useUserRoleColumns();

  return (
    <>
      <Card className={classes.card} elevation={2}>
        <DashboardDivider
          text={
            <FormattedMessage
              id="ES__PROFILE__ROLES"
              defaultMessage="Uživatelské role"
            />
          }
        />
        <TableField
          value={user?.userRoles}
          columns={roleColumns}
          showRadioCond={() => false}
          showToolbar={false}
          onChange={noop}
          maxRows={5}
        />
      </Card>
    </>
  );
}
