import * as Yup from 'yup';
import {
  dataBoxIdentifierRule,
  dataBoxMessage,
  emailMessage,
} from '@composite/contact/contact-schema';
import {
  descriptiveNumberValidation,
  orientationNumberValidation,
} from '@components/form/address/address-schema';
import { DictCountry, DictZipCode, Dispatch, Recipient } from '@models';
import { DispatchMethod, SubjectType } from '@enums';

export function useValidationSchema() {
  return Yup.object<Dispatch>().shape({
    method: Yup.mixed<DispatchMethod>().nullable().required(),
    recipient: Yup.object<Recipient>()
      .when('method', {
        is: DispatchMethod.EMAIL,
        then: Yup.object().shape({
          email: Yup.string().email(emailMessage).nullable().required(),
          subjectType: Yup.mixed<SubjectType>().nullable().required(),
          dataBoxId: Yup.string()
            .matches(dataBoxIdentifierRule, dataBoxMessage)
            .nullable(),
          descriptiveNumber: descriptiveNumberValidation,
          orientationNumber: orientationNumberValidation,
        }),
      })
      .when('method', {
        is: DispatchMethod.DATA_BOX,
        then: Yup.object().shape({
          email: Yup.string().email(emailMessage),
          dataBoxId: Yup.string()
            .matches(dataBoxIdentifierRule, dataBoxMessage)
            .nullable()
            .required(),
          subjectType: Yup.mixed<SubjectType>().nullable().required(),
          descriptiveNumber: descriptiveNumberValidation,
          orientationNumber: orientationNumberValidation,
        }),
      })
      .when('method', {
        is: DispatchMethod.LETTER,
        then: Yup.object().shape({
          email: Yup.string().email(emailMessage).nullable(),
          dataBoxId: Yup.string()
            .matches(dataBoxIdentifierRule, dataBoxMessage)
            .nullable(),
          subjectType: Yup.mixed<SubjectType>().nullable().required(),
          country: Yup.mixed<DictCountry>().nullable().required(),
          city: Yup.string().nullable().required(),
          street: Yup.string().nullable().required(),
          descriptiveNumber: descriptiveNumberValidation.required(),
          orientationNumber: orientationNumberValidation,
          zipCode: Yup.mixed<DictZipCode>().nullable().required(),
        }),
      })
      .nullable()
      .required(),
  });
}
