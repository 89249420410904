import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    {
      key: 'barCode',
      label: 'Čárový kód',
    },
    {
      key: 'deliveryNote',
      label: 'Doručenka',
    },
    {
      key: 'deliveryTime',
      label: 'Datum a čas doručení',
    },
    {
      key: 'dispatchDeliveryMethod',
      label: 'Způsob doručení vypravení',
    },
    {
      key: 'dispatchTime',
      label: 'Datum a čas vypravení',
    },
    {
      key: 'document',
      label: 'Odchozí dokument',
    },
    {
      key: 'electronic',
      label: 'Elektronické',
    },
    {
      key: 'envelope',
      label: 'Obálka',
    },
    {
      key: 'essDispatch',
      label: 'Vypravení v ESS',
    },
    {
      key: 'method',
      label: 'Způsob vypravení',
    },
    {
      key: 'nonDeliveryReason',
      label: 'Důvod nedoručení',
    },
    {
      key: 'noticeBoard.noticeDate',
      label: 'Úřední deska - datum oznámení',
    },
    {
      key: 'noticeBoard.postingDate',
      label: 'Úřední deska - datum vyvěšení',
    },
    {
      key: 'noticeBoard.expirationDate',
      label: 'Úřední deska - datum snětí',
    },
    {
      key: 'number',
      label: 'Číslo vypravení (ID)',
    },
    {
      key: 'recipient.address',
      label: 'Adresát - adresa',
    },
    {
      key: 'recipient.birthDate',
      label: 'Adresát - datum narození',
    },
    {
      key: 'recipient.city',
      label: 'Adresát - město',
    },
    {
      key: 'recipient.companyName',
      label: 'Adresát - název společnosti',
    },
    {
      key: 'recipient.country',
      label: 'Adresát - země',
    },
    {
      key: 'recipient.dataBoxId',
      label: 'Adresát - ID datové schránky',
    },
    {
      key: 'recipient.descriptiveNumber',
      label: 'Adresát - číslo popisné',
    },
    {
      key: 'recipient.fileNumber',
      label: 'Adresát - spisová značka',
    },
    {
      key: 'recipient.email',
      label: 'Adresát - e-mail',
    },
    {
      key: 'recipient.firstName',
      label: 'Adresát - jméno',
    },
    {
      key: 'recipient.ico',
      label: 'Adresát - IČO',
    },
    {
      key: 'recipient.label',
      label: 'Adresát',
    },
    {
      key: 'recipient.lastName',
      label: 'Adresát - příjmení',
    },
    {
      key: 'recipient.name',
      label: 'Adresát - jméno',
    },
    {
      key: 'recipient.orientationNumber',
      label: 'Adresát - číslo orientační',
    },
    {
      key: 'recipient.referenceNumber',
      label: 'Adresát - číslo jednací',
    },
    {
      key: 'recipient.street',
      label: 'Adresát - ulice',
    },
    {
      key: 'recipient.subjectType',
      label: 'Adresát - typ subjektu',
    },
    {
      key: 'recipient.titleAfter',
      label: 'Adresát - titull za',
    },
    {
      key: 'recipient.titleBefore',
      label: 'Adresát - titul před',
    },
    {
      key: 'recipient.zipCode',
      label: 'Adresát - PSČ',
    },
    {
      key: 'state',
      label: 'Stav vypravení',
    },
    {
      key: 'additionalServices.deliveryNote',
      label: 'Doručenka',
    },
    {
      key: 'additionalServices.intoOwnHands',
      label: 'Dodání do vlastních rukou',
    },
    {
      key: 'additionalServices.intoOwnHandsToAddressee',
      label: 'Dodání do vlastních rukou výhradně jen adresáta',
    },
    {
      key: 'shipmentNumber',
      label: 'Číslo zásilky',
    },
    {
      key: 'shipmentType',
      label: 'Typ zásilky',
    },
    {
      key: 'envelopeContent',
      label: 'Obsah obálky',
    },
    {
      key: 'dataBoxMessageId',
      label: 'Číslo datové zprávy',
    },
    {
      key: 'empowerment',
      label: 'Zmocnění',
    },
    {
      key: 'fictionDeliveryEnabled',
      label: 'Povoleno doručení fikcí',
    },
    {
      key: 'toHands',
      label: 'K rukám',
    },
    {
      key: 'intoOwnHands',
      label: 'Zpráva typu "Do vlastních rukou"',
    },
  ];
}
