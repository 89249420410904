import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { Draft } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useEditDraftDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback((draftId: string, formData: Draft) => {
    return abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/${source.data?.id}/draft/${draftId}`,
      {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(formData),
      }
    );
  });

  return {
    callApi,
  };
}
