import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormCustomField,
  FormDateField,
  FormDateTimeField,
  FormSelect,
  FormTextField,
  TextField,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { AssignedFileNumber } from '@models';
import { useSenates } from '../dict-senate/dict-senate-api';
import { useJudges } from '../judge/judge-api';

export function Fields() {
  const judges = useJudges();
  const senates = useSenates();

  const { courtFileTypes } = useContext(StaticDataContext);

  const courtFileTypeSource = useStaticListSource(courtFileTypes);

  const { proposer } = useFormSelector((data: AssignedFileNumber) => ({
    proposer: data?.proposer,
  }));

  return (
    <>
      <FormDateTimeField
        name="assignedTime"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__ASSIGNED_TIME"
            defaultMessage="Datum a čas přidělení"
          />
        }
      />
      <FormTextField
        name="number"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__FILE_NUMBER"
            defaultMessage="Spisová značka"
          />
        }
      />
      <FormTextField
        name="esFile.incomingNumber"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__INCOMING_NUMBER"
            defaultMessage="Došlé číslo"
          />
        }
      />
      <FormAutocomplete
        name="judge"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__JUDGE"
            defaultMessage="Soudce zpravodaj"
          />
        }
        source={judges}
        labelMapper={autocompleteLabelMapper}
      />
      <FormCustomField
        name="proposer"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__PROPOSER"
            defaultMessage="Navrhovatel"
          />
        }
      >
        <TextField value={proposer?.label ?? ''} disabled={true} />
      </FormCustomField>
      <FormDateField
        name="deliveryDate"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__SUBMISSION_DATE"
            defaultMessage="Datum doručení"
          />
        }
      />
      <FormAutocomplete
        name="lastSenate"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__LAST_SENATE"
            defaultMessage="Poslední senát"
          />
        }
        source={senates}
        labelMapper={autocompleteLabelMapper}
      />
      <FormSelect
        name="type"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__TYPE"
            defaultMessage="Typ"
          />
        }
        source={courtFileTypeSource}
        valueIsId
      />
      <FormTextField
        name="order"
        type="number"
        label={
          <FormattedMessage
            id="ES__ASSIGNED_FILE_NUMBERS__DETAIL__FIELD_LABEL__ORDER"
            defaultMessage="Pořadí"
          />
        }
      />
    </>
  );
}
