import React from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
} from '@components/form/misc/label-mappers';
import { CourtFile } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { useJudges } from '../../judge/judge-api';
import { useCourtFileColumns } from '../court-file-columns';

export function useColumns(): TableColumn<CourtFile>[] {
  const intl = useIntl();
  const { columnDeliveryDate, columnOpponent, columnProposers, columnType } =
    useCourtFileColumns();

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_REGISTER__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.COURT_FILES_REGISTRY}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    columnDeliveryDate,
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_REGISTER__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 120,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'judge',
      filterkey: 'judge.id',
      sortkey: 'judge.name',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_REGISTRY__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj',
      }),
      width: 200,
      valueMapper: autocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useJudges,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    columnProposers,
    columnOpponent,
    columnType,
    {
      datakey: 'closingDate',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES__TABLE__COLUMN__CLOSING_DATE',
        defaultMessage: 'Datum uzavření',
      }),
      width: 150,
      CellComponent: TableCells.DateCell,
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
    },
  ];
}
