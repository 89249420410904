import {
  useListSource,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import { DictionaryAutocomplete, DocumentAttachment } from '@models';
import { AttachmentType, DocumentType, EvidenceApiUrl } from '@enums';

export function useAttachmentTypes(
  documentType?: DocumentType,
  disabled?: boolean
) {
  const types = useListSource<
    DictionaryAutocomplete & { allowedFor: DocumentType[] }
  >({
    url: `${EvidenceApiUrl.DOCUMENTS}/attachment/type/list`,
    method: 'GET',
  });

  if (!disabled) {
    types.items = types.items.filter(
      (type) => type.id !== AttachmentType.LUSTRUM
    );
  }

  if (documentType) {
    types.items = types.items.filter((type) =>
      type.allowedFor.includes(documentType)
    );
  }

  return types;
}

export function useStaticAttachmentTypes() {
  return useStaticListSource<DictionaryAutocomplete & { allowedFor: string[] }>(
    [
      { allowedFor: ['DELIVERED'], id: 'MAIN', name: 'Hlavní příloha' },
      { allowedFor: ['DELIVERED'], id: 'POWER_OF_ATTORNEY', name: 'Plná moc' },
      {
        allowedFor: ['DELIVERED'],
        id: 'CHALLENGED_DECISION',
        name: 'Napadená rozhodnutí',
      },
      {
        allowedFor: ['DELIVERED'],
        id: 'OTHER',
        name: 'Další přílohy',
      },
      {
        allowedFor: ['OUTGOING'],
        id: 'OUTGOING',
        name: 'Přílohy odchozího dokumentu',
      },
      {
        allowedFor: ['OUTGOING'],
        id: 'ENVELOPE',
        name: 'Obálky',
      },
      {
        allowedFor: ['OUTGOING'],
        id: 'DELIVERY_NOTE',
        name: 'Doručenky',
      },
      { allowedFor: ['DELIVERED', 'INTERNAL'], id: 'LUSTRUM', name: 'Lustrum' },
      { allowedFor: ['INTERNAL'], id: 'INTERNAL', name: 'Interní příloha' },
    ]
  );
}

export function useAllAttachmentTypes() {
  return useListSource<DictionaryAutocomplete & { allowedFor: DocumentType[] }>(
    {
      url: `${EvidenceApiUrl.DOCUMENTS}/attachment/type/list`,
      method: 'GET',
    }
  );
}

export function useAttachmentSource() {
  return useScrollableSource<DocumentAttachment>({
    url: `${EvidenceApiUrl.DOCUMENTS}/attachment/list`,
    params: {
      size: -1,
    },
  });
}
