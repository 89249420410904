import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  TableFieldCells,
  TableFieldColumn,
  TextField,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { UserRole } from '@models';

export function useUserRoleColumns(): TableFieldColumn<UserRole>[] {
  const intl = useIntl();

  const { roles, agendas } = useContext(StaticDataContext);
  const useRoles = () => useStaticListSource(roles);
  const useAgendas = () => useStaticListSource(agendas);

  return [
    {
      name: intl.formatMessage({
        id: 'ES__USER_ROLES__COLUMN__LABEL',
        defaultMessage: 'Název role',
      }),
      datakey: 'role',
      width: 300,
      CellComponent: TableFieldCells.useSelectCellFactory(useRoles),
    },
    {
      name: intl.formatMessage({
        id: 'ES__USER_ROLES__COLUMN__AGENDAS',
        defaultMessage: 'Agendy',
      }),
      datakey: 'agendas',
      width: 300,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useAgendas,
        TextField,
        true,
        true
      ),
    },
    {
      name: intl.formatMessage({
        id: 'ES__USER_ROLES__COLUMN__BARCODE_PRINTERS',
        defaultMessage: 'Tiskárny čárových kódů',
      }),
      datakey: 'barcodePrinter.name',
      width: 300,
    },
  ];
}
