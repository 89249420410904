import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  FieldFilter,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Subject } from '@models';

export function useColumns(): TableColumn<Subject>[] {
  const intl = useIntl();

  const { subjectTypes } = useContext(StaticDataContext);

  const useTypes = () => useStaticListSource(subjectTypes);

  return [
    {
      datakey: 'type',
      filterkey: 'type.id',
      sortkey: 'type.name',
      name: intl.formatMessage({
        id: 'ES__SUBJECTS__TABLE__COLUMN__TYPE',
        defaultMessage: 'Typ subjektu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'firstName',
      name: intl.formatMessage({
        id: 'ES__SUBJECT__TABLE__COLUMN__FIRST_NAME',
        defaultMessage: 'Jméno',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'lastName',
      name: intl.formatMessage({
        id: 'ES__SUBJECT__TABLE__COLUMN__LAST_NAME',
        defaultMessage: 'Příjmení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ES__SUBJECT__TABLE__COLUMN__COMPANY_NAME',
        defaultMessage: 'Název firmy/organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
  ];
}

export function useContactColumns(prefixes?: {
  labelPrefix?: string;
  datakeyPrefix?: string;
}) {
  const intl = useIntl();

  const labelPrefix = prefixes?.labelPrefix
    ? `${prefixes?.labelPrefix} - `
    : '';

  const datakeyPrefix = prefixes?.datakeyPrefix
    ? `${prefixes.datakeyPrefix}.`
    : '';

  const columnFirstName = {
    name: intl.formatMessage(
      {
        id: 'ES__SUBJECT_CONTACTS__COLUMN__SUBJECT_FIRST_NAME',
        defaultMessage: '{labelPrefix}Jméno',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}subject.firstName`,
    sortkey: `${datakeyPrefix}subject.firstName`,
    width: 200,
  };

  const columnLastName = {
    name: intl.formatMessage(
      {
        id: 'ES__SUBJECT_CONTACTS__COLUMN__SUBJECT_LAST_NAME',
        defaultMessage: '{labelPrefix}Příjmení',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}subject.lastName`,
    sortkey: `${datakeyPrefix}subject.lastName`,
    width: 200,
  };

  const columnCompanyName = {
    name: intl.formatMessage(
      {
        id: 'ES__SUBJECT_CONTACTS__COLUMN__SUBJECT_COMPANY_NAME',
        defaultMessage: '{labelPrefix}Název firmy/organizace',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}subject.companyName`,
    sortkey: `${datakeyPrefix}subject.companyName`,
    width: 200,
  };

  const columnAddress = {
    name: intl.formatMessage(
      {
        id: 'ES__SUBJECT_CONTACTS__COLUMN__ADDRESS',
        defaultMessage: '{labelPrefix}Adresa',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}address.label`,
    sortkey: `${datakeyPrefix}address.label`,
    width: 300,
  };

  const columnDataBoxId = {
    name: intl.formatMessage(
      {
        id: 'ES__SUBJECT_CONTACTS__COLUMN__DATA_BOX_ID',
        defaultMessage: '{labelPrefix}ID datové schránky',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}dataBox.identifier`,
    sortkey: `${datakeyPrefix}dataBox.identifier`,
    width: 150,
  };

  const columnEmail = {
    name: intl.formatMessage(
      {
        id: 'ES__SUBJECT_CONTACTS__COLUMN__EMAIL',
        defaultMessage: '{labelPrefix}Email',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}email`,
    sortkey: `${datakeyPrefix}email`,
    width: 200,
  };

  return {
    columnFirstName,
    columnLastName,
    columnCompanyName,
    columnAddress,
    columnDataBoxId,
    columnEmail,
    contactColumns: [
      columnFirstName,
      columnLastName,
      columnCompanyName,
      columnAddress,
      columnDataBoxId,
      columnEmail,
    ],
  };
}

export function useContactFiltersFields(prefixes?: {
  labelPrefix?: string;
  datakeyPrefix?: string;
}) {
  const intl = useIntl();

  const labelPrefix = prefixes?.labelPrefix
    ? `${prefixes?.labelPrefix} - `
    : '';

  const datakeyPrefix = prefixes?.datakeyPrefix
    ? `${prefixes.datakeyPrefix}.`
    : '';

  const filtersFieldName = {
    label: intl.formatMessage(
      {
        id: 'ES__CONTACTS__FILTER__NAME',
        defaultMessage: '{labelPrefix}Jméno a příjmení/název organizace',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}subject.label`,
    filterkey: `${datakeyPrefix}subject.label`,
    defaultOperation: ApiFilterOperation.CONTAINS,
    FilterComponent: FieldFilter.FilterTextCell,
  };

  const filtersFieldFirstName = {
    label: intl.formatMessage(
      {
        id: 'ES__CONTACTS__FILTER__FIRST_NAME',
        defaultMessage: '{labelPrefix}Jméno',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}subject.firstName`,
    filterkey: `${datakeyPrefix}subject.firstName`,
    defaultOperation: ApiFilterOperation.CONTAINS,
    FilterComponent: FieldFilter.FilterTextCell,
  };

  const filtersFieldLastName = {
    label: intl.formatMessage(
      {
        id: 'ES__CONTACTS__FILTER__LAST_NAME',
        defaultMessage: '{labelPrefix}Příjmení',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}subject.lastName`,
    filterkey: `${datakeyPrefix}subject.lastName`,
    defaultOperation: ApiFilterOperation.CONTAINS,
    FilterComponent: FieldFilter.FilterTextCell,
  };

  const filtersFieldCompanyName = {
    label: intl.formatMessage(
      {
        id: 'ES__CONTACTS__FILTER__COMPANY_NAME',
        defaultMessage: '{labelPrefix}Název firmy/organizace',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}subject.companyName`,
    filterkey: `${datakeyPrefix}subject.companyName`,
    defaultOperation: ApiFilterOperation.CONTAINS,
    FilterComponent: FieldFilter.FilterTextCell,
  };

  const filtersFieldDataBoxId = {
    label: intl.formatMessage(
      {
        id: 'ES__CONTACTS__FILTER__ISDS_IDS',
        defaultMessage: '{labelPrefix}ID datové schránky',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}dataBox.identifier`,
    filterkey: `${datakeyPrefix}dataBox.identifier`,
    defaultOperation: ApiFilterOperation.CONTAINS,
    FilterComponent: FieldFilter.FilterTextCell,
  };

  const filtersFieldEmail = {
    label: intl.formatMessage(
      {
        id: 'ES__CONTACTS__FILTER__EMAIL',
        defaultMessage: '{labelPrefix}Email',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}email`,
    filterkey: `${datakeyPrefix}email`,
    defaultOperation: ApiFilterOperation.CONTAINS,
    FilterComponent: FieldFilter.FilterTextCell,
  };

  const filtersFieldAddress = {
    label: intl.formatMessage(
      {
        id: 'ES__CONTACTS__FILTER__ADDRESS',
        defaultMessage: '{labelPrefix}Adresa',
      },
      { labelPrefix }
    ),
    datakey: `${datakeyPrefix}address.label`,
    filterkey: `${datakeyPrefix}address.label`,
    defaultOperation: ApiFilterOperation.CONTAINS,
    FilterComponent: FieldFilter.FilterTextCell,
  };

  return {
    filtersFieldFirstName,
    filtersFieldLastName,
    filtersFieldCompanyName,
    filtersFieldDataBoxId,
    filtersFieldEmail,
    filtersFieldAddress,
    filtersFieldName,
    contactFiltersFields: [
      filtersFieldName,
      filtersFieldFirstName,
      filtersFieldLastName,
      filtersFieldCompanyName,
      filtersFieldAddress,
      filtersFieldDataBoxId,
      filtersFieldEmail,
    ],
  };
}
