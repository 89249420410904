import { noop, stubFalse, sumBy } from 'lodash';
import React from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { lighten } from '@material-ui/core/styles';
import { TableField } from '@eas/common-web';
import { useStyles } from '@modules/dashboard/search/result-table';
import { SettlementMethodCount } from '@models';
import { ChartView } from '@enums';
import { Chart } from '../card/chart';
import { getRandomColor } from '../statistics-utils';
import { useSettlementMethodCountsColumns } from './settlement-method-counts-columns';

export function useSettlementMethodCountsVisualisation({
  data = [],
  chartHeight,
  maxTableRows = 7,
}: {
  data?: SettlementMethodCount[];
  chartHeight?: number;
  maxTableRows?: number;
}) {
  const Table = () => {
    const classes = useStyles();

    const parsedData = parseData(data);
    const columns = useSettlementMethodCountsColumns();

    return (
      <TableField
        value={parsedData}
        onChange={noop}
        columns={columns}
        showRadioCond={stubFalse}
        classes={classes}
        maxRows={maxTableRows}
        showToolbar={false}
        createRowStyle={(item) =>
          item?.settlementMethod === 'Celkem'
            ? { backgroundColor: lighten('#0d98ba', 0.8) }
            : {}
        }
      />
    );
  };

  const PlenaryFilesChart = () => {
    const colors = Array.from({ length: data.length }, getRandomColor);

    return (
      <Chart data={data} ChartComponent={PieChart} height={chartHeight}>
        <Tooltip
          formatter={(value: string, name: string, item) => {
            return [value, item.payload.settlementMethod];
          }}
        />
        <Legend
          formatter={(_, { payload }) =>
            (payload as SettlementMethodCount).settlementMethod
          }
        />
        <Pie
          data={data}
          dataKey="countTotal"
          cx="50%"
          cy="50%"
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </Chart>
    );
  };

  const availableViews = [
    {
      view: ChartView.TABLE,
      tooltip: 'Tabulka - vyřízené spisy dle způsobu vyřízení',
    },
    {
      view: ChartView.PIE_CHART,
      tooltip: 'Koláčový graf - vyřízené spisy dle způsobu vyřízení',
    },
  ];

  return {
    Visualisations: {
      [ChartView.PIE_CHART]: PlenaryFilesChart,
      [ChartView.TABLE]: Table,
    },
    availableViews,
  };
}

const parseData = (data: SettlementMethodCount[]) => {
  const parsedData: SettlementMethodCount[] = data.filter(
    (item) => item?.countTotal !== 0
  );

  if (parsedData?.length > 0) {
    parsedData.push({
      settlementMethod: 'Celkem',
      countTotal: sumBy(data, 'countTotal'),
      countPlenary: sumBy(data, 'countPlenary'),
      countSenate: sumBy(data, 'countSenate'),
    });
  }

  return parsedData;
};
