import { getErrorMessage } from '@utils/error-message';
import { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  AbortableFetch,
  FilesContext,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { useEditAttachmentDialog } from './edit-attachment-hook';

export function useSignAttachmentDialog() {
  const fetch = useRef<AbortableFetch | null>(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const { callApi: editAttachmentApiCall } = useEditAttachmentDialog();
  const { uploadFile } = useContext(FilesContext);
  const intl = useIntl();

  const invokeIqSigner = useEventCallback((content: string, fileName: string) =>
    abortableFetch(`http://localhost:31200/sign-document-base64`, {
      method: 'POST',
      body: JSON.stringify({
        content,
        fileName,
      }),
    })
  );

  const wrappedInvokeIqSigner = useEventCallback(
    async (documentId: string, attachmentId: string) => {
      try {
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        const attachmentJson = await getAttachement(documentId, attachmentId);
        const blob = await getFile(attachmentJson.file.id);
        const content = await blobToBase64(blob);

        fetch.current = invokeIqSigner(content, attachmentJson.file.name);

        const resultContent: string = await fetch.current.text();
        const resultBlob = await base64toBlob(
          resultContent,
          attachmentJson.file.name
        );

        const savedFile = await uploadFile(resultBlob);
        if (!savedFile) {
          throw new Error('Soubor se nepodařilo uložit.');
        }

        fetch.current = editAttachmentApiCall(documentId, {
          ...attachmentJson,
          file: { id: savedFile.id },
        });
        await fetch.current.raw();

        const message = intl.formatMessage({
          id: 'DOCUMENTS__REQUEST_SUCCESSFULLY_SENT',
          defaultMessage: 'Dokument byl úspěšně podepsán.',
        });

        showSnackbar(message, SnackbarVariant.SUCCESS);
      } catch (err) {
        const message = getErrorMessage(err as Error);

        if ((err as Error).name !== 'AbortError') {
          showSnackbar(...message);
        }
      }
    }
  );

  return {
    callApi: wrappedInvokeIqSigner,
  };
}

async function getAttachement(documentId: string, attachmentId: string) {
  return await abortableFetch(
    `${EvidenceApiUrl.DOCUMENTS}/${documentId}/attachment/${attachmentId}`,
    {
      method: 'GET',
    }
  ).json();
}

async function getFile(fileId: string) {
  const fileResponse = await abortableFetch(
    `${EvidenceApiUrl.FILES}/${fileId}`,
    {
      method: 'GET',
    }
  ).raw();
  return await fileResponse.blob();
}

function blobToBase64(blob: Blob) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      const base64data = dataUrl.slice(dataUrl.indexOf(',') + 1);

      resolve(base64data);
    };
    reader.readAsDataURL(blob);
  });
}

async function base64toBlob(
  base64: string,
  fileName: string,
  type = 'application/octet-stream'
) {
  const response = await fetch(`data:${type};base64,${base64}`);
  const blob = await response.blob();

  return new File([blob], fileName, { type });
}
