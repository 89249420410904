import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryObject,
  FormDateTimeField,
  FormPanel,
  FormSelect,
  FormTextField,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { DataBoxFields } from '@modules/document-dispatch/fields/data-box-fields';
import { LetterFields } from '@modules/document-dispatch/fields/letter-fields';
import { Dispatch } from '@models';
import { DispatchMethod, DispatchState } from '@enums';
import { RecipientFields } from './fields/recipient-fields';

type DispatchFieldDialogProps = {
  dispatchMethods: DictionaryObject[];
  dispatchStates: DictionaryObject[];
  dispatchDeliveryMethods: DictionaryObject[];
};

export function DispatchDialog({
  dispatchMethods,
  dispatchStates,
  dispatchDeliveryMethods,
}: DispatchFieldDialogProps) {
  const methodsSource = useStaticListSource(dispatchMethods);
  const deliveryMethodsSource = useStaticListSource(dispatchDeliveryMethods);
  const stateSource = useStaticListSource(dispatchStates);

  const { method, state } = useFormSelector((data: Dispatch) => ({
    method: data.method,
    state: data.state,
  }));

  return (
    <>
      <RecipientFields />
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DISPATCHES__DISPATCH_PANEL_TITLE"
            defaultMessage="Vypravení"
          />
        }
      >
        <FormSelect
          name="method"
          label={
            <FormattedMessage
              id="ES__DISPATCHES__FIELD_LABEL__TYPE"
              defaultMessage="Způsob vypravení"
            />
          }
          required
          source={methodsSource}
          valueIsId={true}
          disabled={state !== DispatchState.PREPARED}
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__DISPATCHES__FIELD_LABEL__STATE"
              defaultMessage="Stav vypravení"
            />
          }
          source={stateSource}
          valueIsId={true}
          disabled={true}
        />
        <FormSelect
          name="dispatchDeliveryMethod"
          label={
            <FormattedMessage
              id="ES__DISPATCHES__FIELD_LABEL__DELIVERY_METHODS"
              defaultMessage="Způsob doručení vypravení"
            />
          }
          source={deliveryMethodsSource}
          valueIsId={true}
          disabled={true}
        />
        <FormDateTimeField
          name="dispatchTime"
          representation="local-date-time"
          label={
            <FormattedMessage
              id="ES__DISPATCHES__FIELD_LABEL__DISPATCH_TIME"
              defaultMessage="Datum vypravení"
            />
          }
          disabled={true}
        />
        <FormDateTimeField
          name="deliveryTime"
          representation="local-date-time"
          label={
            <FormattedMessage
              id="ES__DISPATCHES__FIELD_LABEL__DELIVERY_TIME"
              defaultMessage="Datum doručení"
            />
          }
          disabled={
            method === DispatchMethod.DATA_BOX ||
            state !== DispatchState.DISPATCHED
          }
        />
        {method === DispatchMethod.LETTER && (
          <>
            <FormTextField
              name="yoursReferenceNumber"
              label={
                <FormattedMessage
                  id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__YOURS_REFERENCE_NUMBER"
                  defaultMessage="Vaše číslo jednací"
                />
              }
            />
            <FormTextField
              name="yoursFileNumber"
              label={
                <FormattedMessage
                  id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__YOURS_FILE_NUMBER"
                  defaultMessage="Vaše spisová značka"
                />
              }
            />
          </>
        )}
        <FormTextField
          name="jid"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__JID"
              defaultMessage="JID"
            />
          }
          disabled={true}
        />
      </FormPanel>
      {method === DispatchMethod.DATA_BOX && (
        <DataBoxFields
          display="IN_DOCUMENT"
          disabled={state !== DispatchState.PREPARED}
        />
      )}
      {method === DispatchMethod.LETTER && (
        <LetterFields disabled={state !== DispatchState.PREPARED} />
      )}
    </>
  );
}
