import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictActType } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { Fields } from './dict-act-type-fields';
import { useValidationSchema } from './dict-act-type-schema';

export function DictActTypes() {
  const validationSchema = useValidationSchema();
  const intl = useIntl();
  const { columnName, columnActive, columnOrder } =
    DictionaryEvidence.useDictionaryColumns<DictActType>();

  const evidence = useDictionaryEvidence<DictActType>({
    version: 2,
    identifier: 'ACT_TYPES',
    apiProps: {
      url: EvidenceApiUrl.DICT_ACT_TYPE,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.ACT_TYPE,
      }),
    },
    tableProps: {
      columns: [columnName, columnActive, columnOrder],
      tableName: intl.formatMessage({
        id: 'ES__DICT_ACT_TYPES__TABLE__TITLE',
        defaultMessage: 'Druhy aktu',
      }),
      reportTag: ExportTags.DICT_ACT_TYPES,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
      showBulkActionButton: false,
      showNamedSettingsButton: false,
    },
    detailProps: {
      entityName: EntityName.ACT_TYPE,
      validationSchema,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_ACT_TYPES__DETAIL__TITLE',
          defaultMessage: 'Druh aktu',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
