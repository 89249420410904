import { useContext } from 'react';
import {
  DetailContext,
  ScheduleContext,
  abortableFetch,
} from '@eas/common-web';

export function useStartDialog() {
  const { source } = useContext(DetailContext);

  const { jobUrl } = useContext(ScheduleContext);

  const callApi = (id: string) => {
    return abortableFetch(`${jobUrl}/${id}/start`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

  const showStartButton = !source.data?.running;

  return { callApi, showStartButton };
}
