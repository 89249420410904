import { grey } from '@material-ui/core/colors';
import { Document, EsFile } from '@models';
import { Agenda, EsFileState, EssDocumentState } from '@enums';

export function isFileLocked(esFile?: EsFile) {
  if (esFile?.agenda === Agenda.PLENARY_OPINION) {
    return (
      esFile?.state &&
      [
        EsFileState.ACCEPTED,
        EsFileState.REJECTED,
        EsFileState.WITHDRAWN,
        EsFileState.REGISTRY,
      ].includes(esFile?.state)
    );
  }

  return (
    esFile?.state === EsFileState.CLOSED ||
    esFile?.state === EsFileState.REGISTRY ||
    esFile?.state == EsFileState.SHREDDED
  );
}

export function createLockedFileStyle(esFile?: EsFile) {
  return isFileLocked(esFile)
    ? { color: grey[500], backgroundColor: grey[100] }
    : {};
}

export function isDocumentLocked(document?: Document) {
  return (
    isFileLocked(document?.esFile) ||
    document?.canceledDate ||
    document?.essDocument?.state === EssDocumentState.SHREDDED
  );
}

export function createdLockedDocumentStyle(document?: Document) {
  return isDocumentLocked(document)
    ? { color: grey[500], backgroundColor: grey[100] }
    : {};
}
