import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  EvidenceContext,
  FormAutocomplete,
  FormInstantField,
  FormPanel,
  FormSelect,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useUsers } from '@modules/user/user-api';
import { UserSubstitutionLogField } from '@composite/user-substitution-log/user-substitution-log-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { User, UserSubstitution } from '@models';
import { SUBSTITUTION_LOG_SOURCE } from './user-substitution-hooked-sources';

export function Fields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { userSubstitutionStates } = useContext(StaticDataContext);

  const { substitute, substituteFor } = useFormSelector(
    (data: UserSubstitution) => ({
      substitute: data.substitute,
      substituteFor: data.substituteFor,
    })
  );

  const getNotEqualToUserFilters = (user: User) => [
    {
      operation: ApiFilterOperation.NOT,
      filters: [
        {
          field: 'id',
          operation: ApiFilterOperation.EQ,
          value: user?.id,
        },
      ],
    },
  ];

  const statesSource = useStaticListSource(userSubstitutionStates);
  const substituteUsers = useUsers({
    filters: substituteFor
      ? getNotEqualToUserFilters(substituteFor)
      : undefined,
  });

  const substituteForUsers = useUsers({
    filters: substitute ? getNotEqualToUserFilters(substitute) : undefined,
  });

  const disabledLogs = false; // TODO
  const logsSource = hookedSources[SUBSTITUTION_LOG_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DICT_USER_SUBSTITUTIONS__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormAutocomplete
          name="substitute"
          label={
            <FormattedMessage
              id="ES__DICT_USER_SUBSTITUTIONS__DETAIL__FIELD_LABEL__SUBSTITUTE"
              defaultMessage="Zastupující uživatel"
            />
          }
          source={substituteUsers}
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormAutocomplete
          name="substituteFor"
          label={
            <FormattedMessage
              id="ES__DICT_USER_SUBSTITUTIONS__DETAIL__FIELD_LABEL__SUBSTITUTE_FOR"
              defaultMessage="Zastupovaný uživatel"
            />
          }
          source={substituteForUsers}
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormInstantField
          name="from"
          label={
            <FormattedMessage
              id="ES__DICT_USER_SUBSTITUTIONS__DETAIL__FIELD_LABEL__FROM"
              defaultMessage="Zastupovat od"
            />
          }
          representation="local-date-time"
          required
        />
        <FormInstantField
          name="to"
          label={
            <FormattedMessage
              id="ES__DICT_USER_SUBSTITUTIONS__DETAIL__FIELD_LABEL__TO"
              defaultMessage="Zastupovat do"
            />
          }
          representation="local-date-time"
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__DICT_USER_SUBSTITUTIONS__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav"
            />
          }
          source={statesSource}
          valueIsId
          disabled
        />

        <UserSubstitutionLogField disabled={disabledLogs} source={logsSource} />
      </FormPanel>
    </>
  );
}
