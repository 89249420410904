import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  FormAutocomplete,
  FormContext,
  FormSelect,
  eqFilterParams,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { User, UserReplacement } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useUserRoles } from '../../../role/role-api';

export function useFormFields() {
  const { agendas } = useContext(StaticDataContext);
  const { source } = useContext<DetailHandle<User>>(DetailContext);

  return function FormFields() {
    const { setFieldValue } = useContext(FormContext);

    const { agenda, role } = useFormSelector((data: UserReplacement) => ({
      agenda: data?.agenda,
      role: data?.role,
    }));

    const agendasSource = useStaticListSource(
      agendas.filter((agenda) => role?.agendas?.includes(agenda.id))
    );

    const userRoles = useUserRoles(
      {
        ...eqFilterParams({ field: 'user.id', value: source?.data?.id }),
      },
      EvidenceApiUrl.USER_ROLE
    );

    const userAndRoleFilters = [
      {
        field: 'role.id',
        value: role?.role,
        operation: ApiFilterOperation.EQ,
      },
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'user.id',
            value: source?.data?.id,
            operation: ApiFilterOperation.EQ,
          },
        ],
      },
    ];
    const agendasFilter =
      !agenda && (role?.agendas ?? []).length > 0
        ? {
            operation: ApiFilterOperation.AND,
            filters: role!.agendas!.map((agenda) => ({
              field: 'agendas.id',
              operation: ApiFilterOperation.EQ,
              value: agenda,
            })),
          }
        : {
            field: 'agendas.id',
            value: agenda,
            operation: ApiFilterOperation.EQ,
          };
    const replacingUsers = useUserRoles(
      {
        filters:
          agenda || (role?.agendas ?? []).length > 0
            ? [...userAndRoleFilters, agendasFilter]
            : userAndRoleFilters,
      },
      EvidenceApiUrl.USER_ROLE
    );

    return (
      <>
        <FormAutocomplete
          source={userRoles}
          name="role"
          label={
            <FormattedMessage
              id="ES__USER__REPLACE_USER_HOOK__FIELD_LABEL__ROLE"
              defaultMessage="Role"
            />
          }
          required
          labelMapper={autocompleteLabelMapper}
          notifyChange={() => {
            setFieldValue('replacingUser', undefined);
            setFieldValue('agenda', undefined);
          }}
        />
        {role?.agendas?.length && (
          <FormSelect
            source={agendasSource}
            name="agenda"
            label={
              <FormattedMessage
                id="ES__USER__REPLACE_USER_HOOK__FIELD_LABEL__AGENDA"
                defaultMessage="Agenda"
              />
            }
            valueIsId
            notifyChange={() => {
              setFieldValue('replacingUser', undefined);
            }}
          />
        )}
        <FormAutocomplete
          source={replacingUsers}
          name="replacingUser"
          label={
            <FormattedMessage
              id="ES__USER__REPLACE_USER_HOOK__FIELD_LABEL__REPLACING_USER"
              defaultMessage="Nahrazující uživatel"
            />
          }
          required
          disabled={!role}
          labelMapper={autocompleteLabelMapper}
        />
      </>
    );
  };
}
