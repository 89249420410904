/**
 * Forma rozhodnutí
 */
export enum DecisionForm {
  /**
   * Nález
   */
  FINDING = 'FINDING',

  /**
   * Usnesení
   */
  RESOLUTION = 'RESOLUTION',

  /**
   * Stanovisko pléna
   */
  PLENARY_OPINION = 'PLENARY_OPINION',
}

/**
 * Typ spisu
 */
export enum CourtFileType {
  /**
   * Senátní
   */
  SENATE = 'SENATE',

  /**
   * Plenární
   */
  PLENARY = 'PLENARY',
}

/**
 * Stav spisu
 */
export enum EsFileState {
  /**
   * Kancelář soudce
   */
  JUDGE_OFFICE = 'JUDGE_OFFICE',

  /**
   * Nové spisy
   */
  NEW = 'NEW',

  /**
   * Indexace
   */
  INDEX = 'INDEX',

  /**
   * Rejstřík
   */
  REGISTER = 'REGISTER',

  /**
   * Rejstřík - nové spisy
   */
  REGISTER_NEW = 'REGISTER_NEW',

  /**
   * Spisovna
   */
  REGISTRY = 'REGISTRY',

  /**
   * V řešení
   */
  IN_PROGRESS = 'IN_PROGRESS',

  /**
   * Uzavřené SPR
   */
  CLOSED = 'CLOSED',
  /**
   * Navrženo
   */
  PROPOSED = 'PROPOSED',

  /**
   * Přijato
   */
  ACCEPTED = 'ACCEPTED',

  /**
   * Nepřijato
   */
  REJECTED = 'REJECTED',

  /**
   * Vzato zpět
   */
  WITHDRAWN = 'WITHDRAWN',

  /**
   * Skartováno
   */
  SHREDDED = 'SHREDDED',
}

/**
 * Typ předpisu
 */
export enum RegulationType {
  /**
   * Ústavní zákony a mezinárodní smlouvy
   */
  CONSTITUTIONAL_LAWS_AND_INTERNATIONAL_TREATIES = 'CONSTITUTIONAL_LAWS_AND_INTERNATIONAL_TREATIES',

  /**
   * Ostatní
   */
  OTHER = 'OTHER',
}

export enum RegulationEntityType {
  /**
   * Paragraf
   */
  PARAGRAPH = 'PARAGRAPH',

  /**
   * Článek
   */
  ARTICLE = 'ARTICLE',
}

/**
 * Navrhovatel (pro kárné řízení)
 */
export enum DisciplinaryProceedingsProposer {
  /**
   * Předseda ÚS
   */
  CHAIRMAN = 'CHAIRMAN',

  /**
   * Plénum
   */
  PLENUM = 'PLENUM',
}

/**
 * Žádost o anonymizaci
 */
export enum AnonymizationRequest {
  /**
   * Zažádáno
   */
  REQUESTED = 'REQUESTED',

  /**
   * Vyhověno
   */
  GRANTED = 'GRANTED',

  /**
   * Zamítnuto
   */
  REJECTED = 'REJECTED',
}

export enum EssRecordState {
  /**
   * Nevyřízen
   */
  UNRESOLVED = 'UNRESOLVED',

  /**
   * Vyřízen
   */
  RESOLVED = 'RESOLVED',

  /**
   * Uzavřen
   */
  CLOSED = 'CLOSED',

  /**
   * Předán do spisovny
   */
  FORWARDED_TO_REGISTRY = 'FORWARDED_TO_REGISTRY',

  /**
   * Předán do archivu
   */
  FORWARDED_TO_ARCHIVE = 'FORWARDED_TO_ARCHIVE',

  /**
   * Stornován
   */
  CANCELED = 'CANCELED',

  /**
   * Skartován
   */
  SHREDDED = 'SHREDDED',
}
