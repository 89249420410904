import React, { useContext } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  ApiFilterOperation,
  FieldFilter,
  RemoteTableFieldContext,
  TableFieldToolbarButtons,
} from '@eas/common-web';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';

export const HistoryToolbar = () => {
  const classes = useStyles();
  const { source } = useContext(RemoteTableFieldContext);

  return (
    <div className={classes.tableActions}>
      <div />
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={source}
          preFilters={[]}
          filtersFields={[
            {
              label: 'Datum',
              datakey: 'created',
              filterkey: 'created',
              defaultOperation: ApiFilterOperation.EQ,
              FilterComponent: FieldFilter.FilterDateCell,
            },
            {
              label: 'Autor',
              datakey: 'createdBy.name',
              filterkey: 'createdBy.name',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Typ události',
              datakey: 'type',
              filterkey: 'type',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
          ]}
        />
      </ButtonGroup>
    </div>
  );
};
