import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IntervalInput, SettlementMethodCountData } from '@models';
import { EvidenceApiUrl, IntervalType } from '@enums';
import { StatisticCard } from '../card/card';
import { IntervalHeader } from '../card/interval-header';
import { useStatistic } from '../statistics-hook';
import { useSettlementMethodCountsVisualisation } from './settlement-method-counts-hook';

export function SettlementMethodCounts() {
  const url = EvidenceApiUrl.STATISTICS_SETTLEMENT_METHOD_COUNT;

  const { data, params, setParams } = useStatistic<
    IntervalInput,
    SettlementMethodCountData
  >({
    url,
    initialParams: {
      intervalType: IntervalType.ALL,
    },
  });

  const { Visualisations } = useSettlementMethodCountsVisualisation({
    data: data?.settlementMethodCounts,
  });

  return (
    <Grid item xs={6}>
      <StatisticCard
        title="Počty vyřízených spisů dle způsobu vyřízení"
        url={url}
        params={params}
        Header={() => <IntervalHeader params={params} setParams={setParams} />}
        Visualisations={Visualisations}
      />
    </Grid>
  );
}
