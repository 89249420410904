import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  ActionButton,
  FormTextField,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: '20px',
    margin: '-20px 24px 20px 24px',
    justifyContent: 'space-between',
  },
  dateWrapper: {
    display: 'flex',
    gap: '20px',
  },
  labelWrapper: {
    display: 'flex',
    gap: '10px',
  },
  dateLabel: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

export const Header = ({
  year,
  setYear,
}: {
  year?: number;
  setYear?: Dispatch<SetStateAction<number>>;
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    year: Yup.number().nullable().required(),
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.labelWrapper}>
        <Typography className={classes.dateLabel}>ROK</Typography>
        <Typography>{year ?? '-'}</Typography>
      </div>
      {setYear && (
        <ActionButton
          promptKey="CHANGE_YEAR"
          buttonLabel={intl.formatMessage({
            id: 'ES__STATISTICS__CHANGE_YEAR',
            defaultMessage: 'Upravit parametry',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__STATISTICS__CHANGE_YEAR__TITLE',
            defaultMessage: 'Úprava parametrů',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__STATISTICS__CHANGE_YEAR__TEXT',
            defaultMessage: 'Vyplňte údaje.',
          })}
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton
              {...props}
              endIcon={<SettingsIcon />}
            />
          )}
          formInitialValues={{ year }}
          FormFields={() => (
            <>
              <FormTextField type="number" name="year" label="Rok" required />
            </>
          )}
          formValidationSchema={validationSchema}
          submitCallback={(values) => setYear(values.year)}
          dialogDisableBackdrop={true}
        />
      )}
    </div>
  );
};
