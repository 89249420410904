import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  ActionButtonComponentProps,
  DetailContext,
  DetailHandle,
  PrimaryDetailActionbarButton,
  SnackbarContext,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { CourtFile, ErrorObject } from '@models';
import { EsFileState, Messages, UIElement } from '@enums';
import { useCourtFilePermission } from '../es-file/es-file-permission';
import { useAssignFileNumberDialog } from './dialog-actions/assign-file-number-hook';
import { useChangeStateDialog } from './dialog-actions/change-state/change-state-hook';
import { FormFields } from './dialog-actions/merge-files/merge-files-dialog';
import { useMergeFilesDialog } from './dialog-actions/merge-files/merge-files-hook';
import { useValidationSchema as useMergeFilesSchema } from './dialog-actions/merge-files/merge-files-schema';
import { useReopenDialog } from './dialog-actions/reopen/reopen-hook';

export function ActionBar() {
  const { showSnackbar } = useContext(SnackbarContext);
  const { source } = useContext<DetailHandle<CourtFile>>(DetailContext);
  const { hasPermission } = useCourtFilePermission(source.data);

  const intl = useIntl();

  const {
    callApi: assignFileNumberApiCall,
    showButton: showAddAssignedFileNumberButton,
    disableButton: disableAddAssignedFileNumberButton,
    redirectBag: addAssignedFileNumberRedirectBag,
  } = useAssignFileNumberDialog();

  const {
    callApi: toRegisterNewApiCall,
    redirectBag: toRegisterNewRedirectBag,
  } = useChangeStateDialog(EsFileState.REGISTER_NEW);

  const { callApi: toRegisterApiCall, redirectBag: toRegisterRedirectBag } =
    useChangeStateDialog(EsFileState.REGISTER);

  const { callApi: toClosedApiCall, redirectBag: toClosedRedirectBag } =
    useChangeStateDialog(EsFileState.CLOSED);

  const { callApi: toIndexedApiCall, redirectBag: toIndexedRedirectBag } =
    useChangeStateDialog(EsFileState.INDEX);

  const {
    callApi: toJudgeOfficeApiCall,
    redirectBag: toJudgeOfficeRedirectBag,
  } = useChangeStateDialog(EsFileState.JUDGE_OFFICE);

  const { callApi: mergeFilesApiCall, showButton: showMergeFilesButton } =
    useMergeFilesDialog();

  const {
    callApi: reopenApiCall,
    showButton: showReopenButton,
    redirectBag: reopenRedirectBag,
  } = useReopenDialog();

  const mergeFilesSchema = useMergeFilesSchema();

  return (
    <>
      {showAddAssignedFileNumberButton && (
        <DetailActionButton
          promptKey="ADD_SINGLE_ASSIGNED_FILE_NUMBER"
          apiCall={(id) => assignFileNumberApiCall([id])}
          buttonLabel={intl.formatMessage({
            id: 'ES__ES_FILES__ACTIONBAR__ADD_SINGLE_ASSIGNED_FILE_NUMBER',
            defaultMessage: 'Přidělit spisovou značku',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__ES_FILES__DIALOG_ADD_SINGLE_ASSIGNED_FILE_NUMBER__TITLE',
            defaultMessage: 'Přidělení spisové značky',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__ES_FILES__DIALOG_ADD_SINGLE_ASSIGNED_FILE_NUMBER__TEXT',
            defaultMessage:
              'Opravdu chcete vybranému spisu přidělit spisovou značku?',
          })}
          ButtonComponent={(props: ActionButtonComponentProps) => (
            <PrimaryDetailActionbarButton
              {...props}
              disabled={disableAddAssignedFileNumberButton}
            />
          )}
          onError={async (err: ErrorObject<'ATTRIBUTE_VALUE_IS_NULL'>) => {
            if (err.code) {
              let error = [];

              if (err.details?.property === 'proposers') {
                error =
                  Messages.EsFile.ASSIGN_FILE_NUMBER.ATTRIBUTE_PROPOSER_IS_NULL;

                showSnackbar(...error);
              } else if (err.details?.property === 'statsDate') {
                error =
                  Messages.EsFile.ASSIGN_FILE_NUMBER
                    .ATTRIBUTE_STATS_DATE_IS_NULL;

                showSnackbar(...error);
              }
            }
          }}
          {...addAssignedFileNumberRedirectBag}
        />
      )}
      {source.data?.state &&
        [EsFileState.INDEX, EsFileState.REGISTER].includes(
          source.data?.state
        ) && (
          <DetailActionButton
            promptKey="TO_JUDGE_OFFICE"
            apiCall={toJudgeOfficeApiCall}
            buttonLabel={intl.formatMessage({
              id: 'ES__ES_FILES__ACTIONBAR__TO_JUDGE_OFFICE',
              defaultMessage: 'Kancelář soudce',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__ES_FILES__DIALOG_TO_JUDGE_OFFICE__TITLE',
              defaultMessage: 'Kancelář soudce',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__ES_FILES__TO_JUDGE_OFFICE__TEXT',
              defaultMessage:
                'Opravdu chcete vybranému spisu změnit stav na "Kancelář soudce"?',
            })}
            dialogWidth={500}
            ButtonComponent={PrimaryDetailActionbarButton}
            buttonDisabled={
              !hasPermission(UIElement.CourtFile.UPDATE_TO_JUDGE_OFFICE_BUTTON)
            }
            {...toJudgeOfficeRedirectBag}
          />
        )}
      {source.data?.state &&
        [EsFileState.REGISTER_NEW, EsFileState.JUDGE_OFFICE].includes(
          source.data?.state
        ) && (
          <DetailActionButton
            promptKey="TO_INDEX"
            apiCall={toIndexedApiCall}
            buttonLabel={intl.formatMessage({
              id: 'ES__ES_FILES__ACTIONBAR__TO_INDEX',
              defaultMessage: 'K indexaci',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__ES_FILES__DIALOG_TO_INDEX__TITLE',
              defaultMessage: 'K indexaci',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__ES_FILES__TO_INDEX__TEXT',
              defaultMessage:
                'Opravdu chcete vybranému spisu změnit stav na "Indexace"?',
            })}
            dialogWidth={500}
            ButtonComponent={PrimaryDetailActionbarButton}
            buttonDisabled={
              !hasPermission(UIElement.CourtFile.UPDATE_TO_INDEX_BUTTON)
            }
            {...toIndexedRedirectBag}
          />
        )}
      {source.data?.state === EsFileState.INDEX && (
        <DetailActionButton
          promptKey="TO_REGISTER_NEW"
          apiCall={toRegisterNewApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__ES_FILES__ACTIONBAR__TO_REGISTER_NEW',
            defaultMessage: 'Rejstřík - nový spis',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__ES_FILES__DIALOG_TO_REGISTER_NEW__TITLE',
            defaultMessage: 'Rejstřík - nový spis',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__ES_FILES__TO_REGISTER_NEW__TEXT',
            defaultMessage:
              'Opravdu chcete vybranému spisu změnit stav na "Rejstřík - nový spis"?',
          })}
          dialogWidth={500}
          ButtonComponent={PrimaryDetailActionbarButton}
          buttonDisabled={
            !hasPermission(UIElement.CourtFile.UPDATE_TO_REGISTER_NEW_BUTTON)
          }
          {...toRegisterNewRedirectBag}
        />
      )}
      {source.data?.state === EsFileState.JUDGE_OFFICE && (
        <DetailActionButton
          promptKey="TO_REGISTER"
          apiCall={toRegisterApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__ES_FILES__ACTIONBAR__TO_REGISTER',
            defaultMessage: 'Rejstřík',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__ES_FILES__DIALOG_TO_REGISTER__TITLE',
            defaultMessage: 'Rejstřík',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__ES_FILES__TO_REGISTER__TEXT',
            defaultMessage:
              'Opravdu chcete vybranému spisu změnit stav na "Rejstřík"?',
          })}
          dialogWidth={500}
          ButtonComponent={PrimaryDetailActionbarButton}
          buttonDisabled={
            !hasPermission(UIElement.CourtFile.UPDATE_TO_REGISTER_BUTTON)
          }
          {...toRegisterRedirectBag}
        />
      )}
      {source.data?.state === EsFileState.REGISTER && (
        <DetailActionButton
          promptKey="TO_CLOSED"
          apiCall={toClosedApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__ES_FILES__ACTIONBAR__TO_CLOSED',
            defaultMessage: 'Uzavřený',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__ES_FILES__DIALOG_TO_CLOSED__TITLE',
            defaultMessage: 'Uzavřený',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__ES_FILES__TO_CLOSED__TEXT',
            defaultMessage:
              'Opravdu chcete vybranému spisu změnit stav na "Uzavřený"?',
          })}
          dialogWidth={500}
          ButtonComponent={PrimaryDetailActionbarButton}
          buttonDisabled={
            !hasPermission(UIElement.CourtFile.UPDATE_TO_CLOSED_BUTTON)
          }
          {...toClosedRedirectBag}
        />
      )}
      {showMergeFilesButton && (
        <DetailActionButton
          promptKey="MERGE_FILES"
          apiCall={mergeFilesApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__ES_FILES__ACTIONBAR__MERGE_FILES',
            defaultMessage: 'Spojení spisů',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__ES_FILES__DIALOG_MERGE_FILES__TITLE',
            defaultMessage: 'Spojení spisů',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__ES_FILES__MERGE_FILES__TEXT',
            defaultMessage: 'Vyplňte údaje.',
          })}
          dialogWidth={500}
          FormFields={FormFields}
          ButtonComponent={PrimaryDetailActionbarButton}
          formInitialValues={{ id: uuidv4(), continuingEsFile: source.data }}
          formValidationSchema={mergeFilesSchema}
          buttonDisabled={
            !hasPermission(UIElement.CourtFile.MERGE_FILES_BUTTON)
          }
        />
      )}
      {showReopenButton && (
        <DetailActionButton
          promptKey="REOPEN"
          apiCall={reopenApiCall}
          isLocked={false}
          buttonLabel={intl.formatMessage({
            id: 'ES__INF_FILES__ACTIONBAR__REOPEN',
            defaultMessage: 'Znovu otevřít',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__INF_FILES__DIALOG_REOPEN__TITLE',
            defaultMessage: 'Opětovné otevření spisu',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__INF_FILES__DIALOG_REOPEN__TEXT',
            defaultMessage: 'Opravdu chcete znovu otevřít vybraný spis?',
          })}
          buttonDisabled={
            !hasPermission(UIElement.CourtFile.REOPEN_COURT_FILE_BUTTON)
          }
          ButtonComponent={PrimaryDetailActionbarButton}
          {...reopenRedirectBag}
        />
      )}
    </>
  );
}
