import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import HelpIcon from '@material-ui/icons/Help';
import ReportIcon from '@material-ui/icons/Report';
import {
  ApiFilterOperation,
  DetailContext,
  DetailMode,
  DictionaryObject,
  EvidenceContext,
  FormPanel,
  PagedTableField,
  TableFieldHandle,
  TableFieldSpeedDialAction,
  TableFieldToolbarHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { DeliveredDocument, Document, OutgoingDocument } from '@models';
import { Agenda, DocumentType, UIElement } from '@enums';
import { useColumns } from './document-columns';
import { DocumentContext } from './document-context';
import { DocumentTableFieldRow } from './document-table-field-row';
import { DocumentToolbar } from './document-toolbar';

export function DocumentField(props: {
  documentTypes: DictionaryObject[];
  documentDeliveryMethods: DictionaryObject[];
  documentDispatchStates: DictionaryObject[];
  hasPermission: (element: string) => boolean;
}) {
  const intl = useIntl();

  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } = useContext(DetailContext);

  const [openState, setOpenState] = useState({});

  useEffect(() => {
    setOpenState({});
  }, [source.data]);

  const columns = useColumns(props);

  const maxRows = 15;
  const preFilters = useMemo(
    () => [
      {
        field: 'esFile.id',
        operation: ApiFilterOperation.EQ,
        value: source.data.id,
      },
    ],
    [source.data.id]
  );

  const documentSource = hookedSources['DOCUMENT_SOURCE']?.source;

  const tableRef = useRef<TableFieldHandle>(null);
  const toolbarRef = useRef<TableFieldToolbarHandle>(null);

  const disabled = isLocked || mode !== DetailMode.VIEW;

  const Toolbar = useMemo(
    () =>
      function Toolbar(toolbarProps: TableFieldToolbarProps) {
        return (
          <DocumentToolbar
            {...toolbarProps}
            ref={toolbarRef}
            disabled={disabled}
            preFilters={preFilters}
            documentTypes={props.documentTypes}
            documentDeliveryMethods={props.documentDeliveryMethods}
            documentDispatchStates={props.documentDispatchStates}
            hasPermission={props.hasPermission}
          />
        );
      },
    [
      disabled,
      preFilters,
      props.documentDeliveryMethods,
      props.documentDispatchStates,
      props.documentTypes,
      props.hasPermission,
    ]
  );

  const actions: TableFieldSpeedDialAction<Document>[] = useMemo(
    () => [
      {
        Icon: ReportIcon,
        name: intl.formatMessage({
          id: 'ES__DOCUMENTS__SET_COMPLAINT',
          defaultMessage: 'Nastavit stížnost',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('SET_COMPLAINT');
        },
        show: (document) => {
          return (
            document.agenda === Agenda.INF &&
            document.type === DocumentType.DELIVERED &&
            !(document as DeliveredDocument).complaint &&
            props.hasPermission(UIElement.Document.SET_COMPLAINT_BUTTON)
          );
        },
      },
      {
        Icon: ReportIcon,
        name: intl.formatMessage({
          id: 'ES__DOCUMENTS__REMOVE_COMPLAINT',
          defaultMessage: 'Odebrat stížnost',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('REMOVE_COMPLAINT');
        },
        show: (document) => {
          return (
            document.agenda === Agenda.INF &&
            document.type === DocumentType.DELIVERED &&
            !!(document as DeliveredDocument).complaint &&
            props.hasPermission(UIElement.Document.SET_COMPLAINT_BUTTON)
          );
        },
      },
      {
        Icon: HelpIcon,
        name: intl.formatMessage({
          id: 'ES__DOCUMENTS__SET_DECISION',
          defaultMessage: 'Nastavit správní rozhodnutí',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('SET_DECISION');
        },
        show: (document) => {
          return (
            document.agenda === Agenda.INF &&
            document.type === DocumentType.OUTGOING &&
            !(document as OutgoingDocument).decision &&
            props.hasPermission(UIElement.Document.SET_DECISION_BUTTON)
          );
        },
      },
      {
        Icon: HelpIcon,
        name: intl.formatMessage({
          id: 'ES__DOCUMENTS__REMOVE__DECISION',
          defaultMessage: 'Odebrat správní rozhodnutí',
        }),
        onClick: async (_, index) => {
          await tableRef.current?.setSelectedIndex(index);
          toolbarRef.current?.executeAction('REMOVE_DECISION');
        },
        show: (document) => {
          return (
            document.agenda === Agenda.INF &&
            document.type === DocumentType.OUTGOING &&
            !!(document as OutgoingDocument).decision &&
            props.hasPermission(UIElement.Document.SET_DECISION_BUTTON)
          );
        },
      },
    ],
    [intl]
  );

  if (!documentSource) {
    return <> Missing DOCUMENT_FIELD_SOURCE </>;
  }

  const showSpeedDial = (document: Document) => {
    const decisionButton =
      document.type === DocumentType.OUTGOING &&
      props.hasPermission(UIElement.Document.SET_DECISION_BUTTON);
    const complaintButton =
      document.type === DocumentType.DELIVERED &&
      props.hasPermission(UIElement.Document.SET_COMPLAINT_BUTTON);

    return decisionButton || complaintButton;
  };

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENTS__PANEL_TITLE"
            defaultMessage="Dokumenty"
          />
        }
        summary={<FormTableCount source={documentSource} />}
      >
        <DocumentContext.Provider value={{ openState, setOpenState }}>
          <PagedTableField<Document>
            ref={tableRef}
            columns={columns}
            ToolbarComponent={Toolbar}
            speedDialActions={actions}
            maxRows={maxRows}
            source={documentSource}
            showDetailBtnCond={() => false}
            showRadioCond={() => false}
            showSpeedDialCond={showSpeedDial}
            RowComponent={DocumentTableFieldRow}
            disabled={disabled}
            createRowStyle={(row) =>
              row?.type === DocumentType.DELIVERED &&
              (row as DeliveredDocument).removedFromEsFile
                ? {
                    textDecoration: 'line-through',
                  }
                : {}
            }
          />
        </DocumentContext.Provider>
      </FormPanel>
    </>
  );
}
