import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  EvidenceContext,
  FormAutocomplete,
  FormCheckbox,
  FormPanel,
  FormSelect,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { AssigningRuleField } from '@composite/assigning-rules/assigning-rule-field';
import { HistoryField } from '@composite/history/history-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Role } from '@enums';
import { useSenates } from '../dict-senate/dict-senate-api';
import { useUserRoles } from '../role/role-api';
import { useHistoryDescriptors } from './judge-history-descriptors';
import { ASSIGNING_RULE_SOURCE } from './judge-hooked-sources';

export function Fields() {
  const historyDescriptors = useHistoryDescriptors();

  const { judgeFunctions } = useContext(StaticDataContext);
  const { hookedSources } = useContext(EvidenceContext);

  const assigningRuleSource = hookedSources[ASSIGNING_RULE_SOURCE]?.source;

  const judgeFunctionSource = useStaticListSource(judgeFunctions);
  const senates = useSenates();
  const judgeUsers = useUserRoles(
    eqFilterParams({ field: 'role.id', value: Role.JUDGE })
  );
  const secretaryUsers = useUserRoles(
    eqFilterParams({ field: 'role.id', value: Role.SECRETARY })
  );
  const indexUsers = useUserRoles(
    eqFilterParams({ field: 'role.id', value: Role.INDEX })
  );
  const assistantUsers = useUserRoles(
    eqFilterParams({ field: 'role.id', value: Role.ASSISTANT })
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__JUDGES__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormAutocomplete
          name="judgeUserRole"
          label={
            <FormattedMessage
              id="ES__JUDGES__DETAIL__FIELD_LABEL__JUDGE"
              defaultMessage="Soudce"
            />
          }
          required
          source={judgeUsers}
          labelMapper={autocompleteLabelMapper}
        />
        <FormSelect
          name="function"
          label={
            <FormattedMessage
              id="ES__JUDGES__DETAIL__FIELD_LABEL__FUNCTION"
              defaultMessage="Funkce"
            />
          }
          source={judgeFunctionSource}
          valueIsId
        />
        <FormAutocomplete
          name="secretaryUserRole"
          label={
            <FormattedMessage
              id="ES__JUDGES__DETAIL__FIELD_LABEL__SECRETARY"
              defaultMessage="Tajemnice"
            />
          }
          required
          source={secretaryUsers}
          labelMapper={autocompleteLabelMapper}
        />
        <FormAutocomplete
          name="indexUserRole"
          label={
            <FormattedMessage
              id="ES__JUDGES__DETAIL__FIELD_LABEL__INDEX"
              defaultMessage="Vedoucí rejstříku (senátní spisy)"
            />
          }
          required
          source={indexUsers}
          labelMapper={autocompleteLabelMapper}
        />
        <FormAutocomplete
          name="assistantUserRoles"
          label={
            <FormattedMessage
              id="ES__JUDGES__DETAIL__FIELD_LABEL__ASSISTANTS"
              defaultMessage="Asistent"
            />
          }
          multiple
          source={assistantUsers}
          labelMapper={autocompleteLabelMapper}
        />
        <FormAutocomplete
          name="senates"
          label={
            <FormattedMessage
              id="ES__JUDGES__DETAIL__FIELD_LABEL__SENATES"
              defaultMessage="Senáty"
            />
          }
          source={senates}
          labelMapper={autocompleteLabelMapper}
          multiple
        />
        <DictionaryEvidence.FieldActive />
        <FormCheckbox
          name="showInNalus"
          label={
            <FormattedMessage
              id="ES__JUDGES__DETAIL__FIELD_LABEL__SHOW_IN_NALUS"
              defaultMessage="Zobrazit v NALUSu"
            />
          }
        />
      </FormPanel>
      <AssigningRuleField source={assigningRuleSource} />
      <HistoryField descriptors={historyDescriptors} />
    </>
  );
}
