enum Element {
  /**
   * Číslo jednací
   */
  REFERENCE_NUMBER = 'REFERENCE_NUMBER',

  /**
   * Agenda
   */
  AGENDA = 'AGENDA',

  /**
   * Stručný obsah
   */
  SUMMARY = 'SUMMARY',

  /**
   * Datum vytvoření
   */
  CREATED_DATE = 'CREATED_DATE',

  /**
   * Rozhodnutí
   */
  DECISION = 'DECISION',

  /**
   * Počet listů
   */
  PAGE_COUNT = 'PAGE_COUNT',

  /**
   * Poznámka
   */
  NOTE = 'NOTE',

  /**
   * Pořadí
   */
  ORDER = 'ORDER',

  /**
   * Čárový kód
   */
  BAR_CODE = 'BAR_CODE',

  /**
   * AKCE: Upravit dokument
   */
  EDIT_DOCUMENT_BUTTON = 'EDIT_DOCUMENT_BUTTON',

  /**
   * AKCE: Stornovat
   */
  CANCEL_BUTTON = 'CANCEL_BUTTON',

  /**
   * AKCE: Znovu otevřít
   */
  REOPEN_INTERNAL_DOCUMENT_BUTTON = 'REOPEN_INTERNAL_DOCUMENT_BUTTON',
}

export default Element;
