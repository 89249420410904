import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const commonDescriptors = useCommonHistoryDescriptors();

  return [
    {
      key: 'senates',
      label: 'Senáty',
    },
    {
      key: 'documents',
      label: 'Dokumenty',
    },
    {
      key: 'fullName',
      label: 'Jméno a příjmení',
    },

    {
      key: 'showInNalus',
      label: 'Zobrazit v NALUSu',
    },
    {
      key: 'secretaryUserRole',
      label: 'Tajemnice',
    },
    {
      key: 'judgeUserRole',
      label: 'Soudce',
    },
    {
      key: 'indexUserRole',
      label: 'Vedoucí rejstříku (senátní spisy)',
    },
    {
      key: 'function',
      label: 'Funkce',
    },
    ...commonDescriptors,
  ];
}
