/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Deadline messages
 */
const SAVE: MessageType<'SUCCESS' | 'INSUFFICIENT_DAYS_REMAINING' | 'ERROR'> = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__DEADLINE__SAVE__SUCCESS"
      defaultMessage="Lhůta byla úspěšně uložena."
    />,
    SnackbarVariant.SUCCESS,
  ],
  INSUFFICIENT_DAYS_REMAINING: [
    <FormattedMessage
      id="ES__MESSAGE__DEADLINE__SAVE__INSUFFICIENT_DAYS_REMAINING"
      defaultMessage="Chyba při ukládání lhůty: Konečné datum nemůže být v minulosti."
    />,
    SnackbarVariant.ERROR,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__DEADLINE__SAVE__ERROR"
      defaultMessage="Chyba při ukládání lhůty."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default { SAVE };
