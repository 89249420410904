import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DictEnvelopeContent } from '@models';

export function useColumns(): TableColumn<DictEnvelopeContent>[] {
  const intl = useIntl();
  const { columnActive } = DictionaryEvidence.useDictionaryColumns<
    DictEnvelopeContent
  >();

  return [
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ES__DICT_ENVELOPE_CONTENTS__TABLE__COLUMN__NAME',
        defaultMessage: 'Název',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    columnActive,
  ];
}
