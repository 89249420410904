export const DISPATCHES = {
  title: 'Vypravení',
  description:
    'Vypravení reprezentuje konkrétní odeslání odchozího dokumentu vybranému adresátovi. Součástí každého vypravení jsou všechny přílohy, které odchozí dokument obsahuje. Pokud již byl odchozí dokument nějakému adresátu odeslán, systém neumožní přílohy uživateli měnit ani přidávat.',
};

export const ELECTRONIC_DOCUMENT_DISPATCHES = {
  title: 'Vypravení - elektronické dokumenty',
  description:
    'Zobrazení všech vypravení se způsobem vypravení E-mail nebo Datová zpráva evidovaných v systému.',
  actions: [
    {
      name: 'Vypravit',
      description:
        'Předání elektronického dokumentu určeného k vypravení eSSL k automatickému vypravení prostřednictvím DS a Emailu.',
    },
    {
      name: 'Vrátit',
      description: 'Vrácení elektronického dokumentu určeného k vypravení.',
    },
  ],
};
