import React from 'react';
import { ApiFilterOperation } from '@eas/common-web';
import { useReturnDialog } from '@modules/es-file/dialog-actions/return/return-hook';
import { Agenda, EsFileState } from '@enums';

export function Toolbar() {
  const { ReturnButton } = useReturnDialog(Agenda.DISCIPLINARY_PROCEEDINGS, [
    {
      field: 'state.id',
      operation: ApiFilterOperation.EQ,
      value: EsFileState.CLOSED,
    },
    {
      field: 'agenda.id',
      operation: ApiFilterOperation.EQ,
      value: Agenda.DISCIPLINARY_PROCEEDINGS,
    },
  ]);

  return <ReturnButton />;
}
