/**
 * Browser urls
 */
export enum EvidenceBrowserUrl {
  ACTIONS = '/es/skripty',
  ALOG = '/es/vis-logovani',
  APP_SETTINGS = '/es/nastaveni',
  ASSIGNED_FILE_NUMBERS = '/es/historie-prideleni-spisovych-znacek',
  ASSIGNING_RULES = '/es/pravidla-pridelovani',
  COURT_FILES_ALL = '/es/spisy/soudni/vsechny',
  COURT_FILES_CLOSED = '/es/spisy/soudni/uzavrene',
  COURT_FILES_JUDGE_OFFICE = '/es/spisy/soudni/kancelar-soudce',
  COURT_FILES_INDEX = '/es/spisy/soudni/indexace',
  COURT_FILES_NEW = '/es/spisy/soudni/nove',
  COURT_FILES_REGISTER = '/es/spisy/soudni/rejstrik',
  COURT_FILES_REGISTER_NEW = '/es/spisy/soudni/rejstrik-nove',
  COURT_FILES_REGISTRY = '/es/spisy/soudni/spisovna',
  COURT_FILES_SHREDDED = '/es/spisy/soudni/skartovano',
  DECISIONS = '/es/rozhodnuti',
  DECISIONS_ACCESSIBLE = '/es/rozhodnuti/zpristupnena',
  DECISIONS_ALL = '/es/rozhodnuti/vsechna',
  DECISIONS_SEARCH = '/es/rozhodnuti/hledani',
  DECISIONS_INDEXING = '/es/rozhodnuti/indexace',
  DECISIONS_IN_PROGRESS = '/es/rozhodnuti/rozpracovana',
  DECISIONS_TO_ANONYMIZE = '/es/rozhodnuti/k-anonymizaci',
  DECISIONS_TO_MAKE_ACCESSIBLE = '/es/rozhodnuti/k-zpristupneni',
  DICT_ACT_TYPE = '/es/druh-aktu',
  DICT_BARCODE_PRINTERS = '/es/tiskarny-carovych-kodu',
  DICT_BRIEF_SUMMARY = '/es/strucne-obsahy',
  DICT_CONCERNED_AUTHORITY_TYPES = '/es/typy-dotceneho-organu',
  DICT_CONTACT_TYPES = '/es/typy-kontaktu',
  DICT_COUNTRIES = '/es/zeme',
  DICT_DISPATCH_TYPES = '/es/typy-odeslani',
  DICT_ENVELOPE_CONTENTS = '/es/obsah-obalek',
  DICT_HOLIDAYS = '/es/svatky',
  DICT_PROCEEDINGS_SUBJECT = '/es/predmety-rizeni',
  DICT_NOTES = '/es/poznamky',
  DICT_OPPONENTS = '/es/odpurci',
  DICT_PROCESSING_TYPE = '/es/typy-rizeni',
  DICT_PROPOSER_DISTINCTION = '/es/rozliseni-navrhovatele',
  DICT_PROPOSER_TYPE = '/es/typy-navrhovatelu',
  DICT_REGULATION_ORDER = '/es/indexy-pravnich-predpisu',
  DICT_SENATES = '/es/senaty',
  DICT_SETTLEMENT_METHODS = '/es/zpusoby-vyrizeni',
  DICT_SUBJECT_INDEX = '/es/vecny-rejstrik',
  DICT_SUBMISSION_CONTENT = '/es/obsahy-podani-spr',
  DICT_TASK_NAME = '/es/nazev-ulohy',
  DICT_ZIP_CODES = '/es/psc',
  DISCIPLINARY_PROCEEDINGS_FILES = '/es/spisy/karne-rizeni',
  DOCUMENTS = '/es/dokumenty',
  DOCUMENTS_DELIVERED_PROCESSED = '/es/dokumenty/dorucene/zpracovane',
  DOCUMENTS_DELIVERED_UNPROCESSED = '/es/dokumenty/dorucene/nezpracovane',
  DOCUMENTS_DISPATCHES = '/es/vypraveni',
  DOCUMENTS_DISPATCHES_ALL = '/es/vypraveni/vsechny',
  DOCUMENTS_DISPATCHES_ELECTRONIC = '/es/vypraveni/elektronicke-dokumenty',
  DOCUMENTS_INTERNAL = '/es/dokumenty/interni',
  DOCUMENTS_OUTGOING = '/es/dokumenty/odchozi',
  ES_FILES = '/es/spisy',
  ES_FILES_ALL = '/es/spisy/vsechny',
  ES_FILES_SEARCH = '/es/spisy/hledani',
  ESS_INCOMING_BATCHES = '/es/ess/prichozi-davky',
  ESS_OUTGOING_BATCHES = '/es/ess/odchozi-davky',
  EXPORT_REQUESTS = '/es/fronta-exportu',
  EXPORT_TEMPLATES = '/es/sablony-exportu',
  GLOBAL_SETTINGS = '/es/globalni-nastaveni',
  HELP = '/es/napoveda',
  HOME = '/es',
  INF_FILES_ALL = '/es/spisy/inf/vsechny',
  INF_FILES_CLOSED = '/es/spisy/inf/uzavrene',
  INF_FILES_IN_PROGRESS = '/es/spisy/inf/v-reseni',
  INF_FILES_NEW = '/es/spisy/inf/nove',
  INF_FILES_REGISTRY = '/es/spisy/inf/spisovna',
  INF_FILES_SHREDDED = '/es/spisy/inf/skartovano',
  JUDGES = '/es/soudci',
  JOBS = '/es/ulohy-na-spisove-sluzbe',
  LOGOUT = '/odhlaseni',
  NASPIS_LOG_RECORDS = '/es/logovani-naspis',
  NOTIFICATIONS = '/es/vsechny-notifikace',
  NOTIFICATION_TEMPLATES = '/es/sablony-notifikaci',
  PLENARY_OPINION_FILES = '/es/spisy/stanovisko-plena',
  PROFILE = '/es/profil',
  REGISTERS = '/es/rejstriky-soudni-spisy',
  REST_MESSAGES = '/es/rest-zpravy',
  ROLES = '/es/uzivatelske-role',
  SCHEDULE_JOBS = '/es/casove-ulohy',
  SCHEDULE_RUNS = '/es/behy-casovych-uloh',
  SEQUENCES = '/es/ciselne-rady',
  SCREENING = '/es/lustrace',
  SPR_FILES_ALL = '/es/spisy/spr/vsechny',
  SPR_FILES_CLOSED = '/es/spisy/spr/uzavrene',
  SPR_FILES_IN_PROGRESS = '/es/spisy/spr/v-reseni',
  SPR_FILES_REGISTRY = '/es/spisy/spr/spisovna',
  SPR_FILES_NEW = '/es/spisy/spr/nove',
  SPR_FILES_SHREDDED = '/es/spisy/spr/skartovano',
  STATISTICS = '/es/statistiky',
  SUBJECTS = '/es/subjekty',
  TASKS = '/es/ulohy',
  USERS = '/es/uzivatele',
  USER_SUBSTITUTIONS = '/es/zastupy-uzivatelu',
  VIEWING_REQUEST_ALL = '/es/vsechny-zadosti-o-nahlizeni',
  VIEWING_REQUEST_NEW = '/es/nove-zadosti-o-nahlizeni',
  VIEWING_REQUEST_PROCESSING = '/es/zpracovavane-zadosti-o-nahlizeni',
  VIEWING_REQUEST_RESOLVED = '/es/vyrizene-zadosti-o-nahlizeni',
}

export enum EvidenceApiUrl {
  ACTIONS = '/api/evidence-spisu/actions',
  ALOG = '/api/evidence-spisu/alog',
  ASSIGNING_RULES = '/api/evidence-spisu/assigning-rule',
  ASSIGNED_FILE_NUMBERS = '/api/evidence-spisu/es-file/number',
  ASSIGNMENT_SETTINGS = '/api/evidence-spisu/es-file/number/setting',
  AUTH = '/api/evidence-spisu/auth',
  ES_FILES = '/api/evidence-spisu/es-file',
  DECISIONS = '/api/evidence-spisu/es-file/decision',
  DICT_ACT_TYPE = '/api/evidence-spisu/act/type',
  DICT_BARCODE_PRINTERS = '/api/evidence-spisu/barcode-printer',
  DICT_BRIEF_SUMMARIES = '/api/evidence-spisu/brief-summary',
  DICT_CONCERNED_AUTHORITY_TYPES = '/api/evidence-spisu/concerned-authority/type',
  DICT_CONTACT_TYPES = '/api/evidence-spisu/subject/contact/type',
  DICT_COUNTRIES = '/api/evidence-spisu/country',
  DICT_DISPATCH_TYPES = '/api/evidence-spisu/dispatch-type',
  DICT_ENVELOPE_CONTENTS = '/api/evidence-spisu/envelope/content',
  DICT_HOLIDAYS = '/api/evidence-spisu/holiday',
  DICT_PROCEEDINGS_SUBJECT = '/api/evidence-spisu/subject/proceedings',
  DICT_NOTES = '/api/evidence-spisu/note',
  DICT_OPPONENTS = '/api/evidence-spisu/opponent',
  DICT_PROPOSER_DISTINCTION = '/api/evidence-spisu/proposer/distinction',
  DICT_PROPOSER_TYPE = '/api/evidence-spisu/proposer/type',
  DICT_PROCESSING_TYPE = '/api/evidence-spisu/processing-type',
  DICT_REGULATION_ORDER = '/api/evidence-spisu/regulation-order',
  DICT_SETTLEMENT_METHODS = '/api/evidence-spisu/settlement-method',
  DICT_SENATES = '/api/evidence-spisu/senate',
  DICT_SUBMISSION_CONTENTS = '/api/evidence-spisu/es-file/spr/submission/content',
  DICT_SUBJECT_INDEX = '/api/evidence-spisu/subject-index',
  DICT_TASK_NAME = '/api/evidence-spisu/task/name',
  DICT_ZIP_CODES = '/api/evidence-spisu/zip-code',
  DOCUMENTS_DISPATCHES = '/api/evidence-spisu/document/dispatch',
  DOCUMENTS = '/api/evidence-spisu/document',
  FILES = '/api/evidence-spisu/files',
  GLOBAL_SETTINGS = '/api/evidence-spisu/settings',
  HISTORY = '/api/evidence-spisu/history',
  JUDGES = '/api/evidence-spisu/judge',
  JOBS = '/api/evidence-spisu/ess/job',
  NASPIS_LOG_RECORDS = '/api/evidence-spisu/log/naspis',
  NOTIFICATIONS = '/api/evidence-spisu/notification',
  NOTIFICATION_EVENTS = '/api/evidence-spisu/notification/event',
  NOTIFICATION_PREFERENCES = '/api/evidence-spisu/notification/preference',
  NOTIFICATION_RECEIVED = '/api/evidence-spisu/notification/received',
  NOTIFICATION_TEMPLATES = '/api/evidence-spisu/notification/template',
  REPORTS = '/api/evidence-spisu/reporting',
  REST_MESSAGES = '/api/evidence-spisu/rest/logger/messages',
  ROLES = '/api/evidence-spisu/role',
  SCHEDULE_JOBS = '/api/evidence-spisu/schedule/jobs',
  SCHEDULE_RUNS = '/api/evidence-spisu/schedule/runs',
  STATISTICS = '/api/evidence-spisu/statistics',
  STATISTICS_ASSIGNED_AND_RESOLVED_ES_FILES = '/api/evidence-spisu/statistics/assigned-and-resolved-es-files',
  STATISTICS_MONTHLY_OUTPUT = '/api/evidence-spisu/statistics/monthly-statistic-output',
  STATISTICS_PROCEEDING_DURATION = '/api/evidence-spisu/statistics/proceeding-duration',
  STATISTICS_SETTLEMENT_METHOD_COUNT = '/api/evidence-spisu/statistics/settlement-method-count',
  STATISTICS_SUBMISSION_COUNT = '/api/evidence-spisu/statistics/submission-count',
  STATISTICS_UNRESOLVED = '/api/evidence-spisu/statistics/unresolved',
  SUBJECTS = '/api/evidence-spisu/subject',
  SUBJECT_CONTACTS = '/api/evidence-spisu/subject/contact',
  TASKS = '/api/evidence-spisu/task',
  USERS = '/api/evidence-spisu/user',
  USER_SUBSTITUTIONS = '/api/evidence-spisu/user/substitution',
  USER_ROLE = '/api/evidence-spisu/user/role',
  VIEWING = '/api/evidence-spisu/es-file/viewing',
  VIEWING_REQUEST = '/api/evidence-spisu/es-file/viewing/request',
}
