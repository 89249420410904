import React from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { grey } from '@material-ui/core/colors';
import { EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { AssigningRule } from '@models';
import { EvidenceApiUrl, HistoryEntityType } from '@enums';
import { useColumns } from './assigning-rule-columns';
import { Fields } from './assigning-rule-fields';
import { useValidationSchema } from './assigning-rule-schema';

const createRowStyle = (value: AssigningRule) =>
  !value.valid
    ? {
        color: grey[400],
      }
    : {};

export function AssigningRules() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useAuthoredEvidence<AssigningRule>({
    identifier: 'ASSIGNING_RULES',
    version: 6,
    apiProps: {
      url: EvidenceApiUrl.ASSIGNING_RULES,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.ASSIGNING_RULE,
      }),
    },
    tableProps: {
      defaultSorts: [
        { field: 'valid', datakey: 'valid', order: 'DESC', type: 'FIELD' },
      ],
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__TITLE',
        defaultMessage: 'Pravidla přidělování',
      }),
      showReportButton: false,
      createRowStyle: createRowStyle,
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__ASSIGNING_RULES__DETAIL__TITLE',
          defaultMessage: 'Pravidlo přidělování',
        }),
        showBtn: (btn) => btn !== 'REMOVE',
      },
      // Needed for dates validation
      initNewItem: () => ({ id: uuidv4(), newMode: true } as any),
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
