import { format, parseISO } from 'date-fns';
import React, { useContext } from 'react';
import { LocaleContext } from '@eas/common-web';
import { HistoryFieldProps } from '../history-types';
import { StringHistoryField } from './string-history-field';

export function LocalDateHistoryField({
  value,
  position,
}: HistoryFieldProps<string>) {
  const { locale } = useContext(LocaleContext);

  const formattedValue = value
    ? format(parseISO(value), locale.dateFormat)
    : undefined;

  return <StringHistoryField value={formattedValue} position={position} />;
}
