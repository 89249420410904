import { DictionaryAutocomplete, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

/**
 * List all deadline states.
 */
export function useDeadlineStates() {
  return useFetch<DictionaryAutocomplete[]>(
    `${EvidenceApiUrl.DOCUMENTS}/deadline/state/list`,
    { method: 'GET', cacheResponse: true }
  );
}
