import React, { useState } from 'react';
import { useContext, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import {
  NavigationContext,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { Messages } from '@enums';

function SecureRouteBody(
  props: RouteProps & { roles?: string[]; agenda?: string; viewing?: boolean }
) {
  const [allowed, setAllowed] = useState(false);

  const { hasPermission, user } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const BodyComponent = props.component as () => JSX.Element;

  useEffect(() => {
    const permitted = props.roles
      ? !props.roles.some(
          (role) =>
            hasPermission(
              role,
              props.agenda ? { agenda: props.agenda } : null
            ) ||
            (user?.additionalDetails?.hasActiveViewings && props.viewing)
        )
      : false;

    setAllowed(!permitted);

    if (permitted) {
      navigate('/es');
      const warning = Messages.SecureRoute.ACCESS.WARNING;
      showSnackbar(warning[0], warning[1], false);
    }
  });

  return <>{allowed && <BodyComponent />}</>;
}

export function SecureRoute(
  props: RouteProps & { roles?: string[]; agenda?: string; viewing?: boolean }
) {
  return (
    <Route {...props} exact>
      <SecureRouteBody {...props} />
    </Route>
  );
}
