import { cloneDeep, debounce } from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ApiFilterOperation,
  EvidenceContext,
  NavigationContext,
  UserSettingsContext,
  useEventCallback,
  useUpdateEffect,
} from '@eas/common-web';
import { FilterContext } from '../filter-context';
import { FilterState, SavedFilters } from '../filter-types';
import { deriveFiltersState, normalizeFilterState } from '../filter-utils';
import { useExtender } from './filter-extender-hook';
import { useReducer } from './filter-reducer-hook';

/**
 * EXAMPLE OF filters
 * -> 4 extenders
 *    -> fileNumber CONTAINS '11'
 *    -> fileNumber CONTAINS '22'
 *    -> proposers.firstName CONTAINS 'Marian' && proposers.lastName CONTAINS 'Camak' && fileNumber CONTAINS '33'
 *    -> fileNumber NOT CONTAINS '44'
 * -> 1 reducer
 *    -> id NOT IN [f66e..., eee4..., d1ee...  ]
 *
 * {
 *   "filters": [
 *     {
 *       "operation": "AND",
 *       "filters": [
 *         {
 *           "operation": "OR",
 *           "filters": [
 *
 *             {
 *               "operation": "AND",
 *               "filters": [
 *                 {
 *                   "field": "fileNumber",
 *                   "operation": "CONTAINS",
 *                   "value": "11"
 *                 },
 *               ],
 *             },
 *
 *             {
 *               "operation": "AND",
 *               "filters": [
 *                 {
 *                   "field": "fileNumber",
 *                   "operation": "CONTAINS",
 *                   "value": "22"
 *                 },
 *               ],
 *             },
 *
 *             {
 *               "operation": "AND",
 *               "filters": [
 *                 {
 *                   "operation": "NESTED",
 *                   "path": "proposers",
 *                   "filter": {
 *                     "operation": "AND",
 *                     "filters": [
 *                       {
 *                         "field": "proposers.firstName",
 *                         "operation": "CONTAINS",
 *                         "value": "Marian"
 *                       },
 *                       {
 *                         "field": "proposers.lastName",
 *                         "operation": "CONTAINS",
 *                         "value": "Camak"
 *                       },
 *                     ],
 *                   },
 *                 },
 *                 {
 *                   "field": "fileNumber",
 *                   "operation": "CONTAINS",
 *                   "value": "33"
 *                 },
 *               ],
 *             },
 *
 *             {
 *               "operation": "AND",
 *               "filters": [
 *                 {
 *                   "operation": "NOT",
 *                   "filters":
 *                     [
 *                       {
 *                         "operation": "AND",
 *                         "filters": [
 *                           {
 *                             "field": "fileNumber",
 *                             "operation": "CONTAINS",
 *                             "value": "44"
 *                           }
 *                         }
 *                     ],
 *                  },
 *               }
 *             },
 *
 *           ],
 *         },
 *         {
 *           "operation": "AND",
 *           "filters": [
 *             {
 *               "operation": "NOT",
 *               "filters": [
 *                 {
 *                   "field": "id",
 *                   "operation": "IN",
 *                   "values": [
 *                     "f66e1144-4457-4b31-934a-5ea05a30e507",
 *                     "eee48ace-d89b-462a-b599-a9ec9226ceac",
 *                     "d1ee14e0-b046-4cea-9957-bbef47e448f7"
 *                   ]
 *                 }
 *               ]
 *             }
 *           ]
 *         }
 *       ]
 *     }
 *   ],
 * }
 */
export function useFilterState({
  settingsKey,
  defaults,
}: {
  settingsKey: string;
  defaults?: FilterState[];
}) {
  const initedFilters = useRef(false);
  const { tableRef } = useContext(EvidenceContext);
  const { stateAction } = useContext(NavigationContext);
  const { getCustomSettings, setCustomSettings } =
    useContext(UserSettingsContext);

  /**
   * Inner state of filter form.
   */
  const [filters, setFilters] = useState<FilterState[]>([]);

  /**
   * Initialize inner state of filter form.
   */
  useUpdateEffect(() => {
    if (!initedFilters.current) {
      setFilters(deriveFiltersState(tableRef.current?.filters ?? [], defaults));
      initedFilters.current = true;
    }
  }, [tableRef.current?.filters]);

  const [filtersState, setFiltersState] = useState<FilterState[]>(() => {
    const saved = getCustomSettings(settingsKey, 1) as SavedFilters;

    if (saved?.filters && stateAction?.action !== 'SCREENING_FOR_DOCUMENT') {
      setTimeout(() => {
        tableRef.current?.setPreFilters(
          normalizeFilterState(saved?.filters ?? [])
        );
      }, 0);

      return saved?.filters;
    } else {
      return [
        {
          operation: ApiFilterOperation.AND,
          value: true,
          filters: [
            {
              operation: ApiFilterOperation.OR,
              visible: true,
              filters: [], // extenders
            },
            {
              operation: ApiFilterOperation.AND,
              visible: true,
              filters: [], // reducers
            },
          ],
        },
      ];
    }
  });

  const syncSettings = useCallback((filtersState: FilterState[]) => {
    setCustomSettings(settingsKey, {
      version: 1,
      filters: filtersState,
    } as SavedFilters);
  }, []);

  const debouncedSyncSettings = useMemo(() => debounce(syncSettings, 1000), []);

  useEffect(() => {
    const activateAll = () => {
      const filters = cloneDeep(filtersState);

      const extenders = filters[0].filters![0].filters;

      extenders?.forEach((e) => {
        e.visible = true;
        e.confirmed = true;
      });

      const reducers = filters[0].filters![1].filters;

      reducers?.forEach((r) => {
        r.visible = true;
      });

      return filters;
    };

    debouncedSyncSettings(activateAll());
  }, [filtersState]);

  /**
   * Iterate over all extenders and reducers and each set as visible.
   */
  const activateAll = useEventCallback(() => {
    const filters = cloneDeep(filtersState);

    const extenders = filters[0].filters![0].filters;

    extenders?.forEach((e) => {
      e.visible = true;
    });

    const reducers = filters[0].filters![1].filters;

    reducers?.forEach((r) => {
      r.visible = true;
    });

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  /**
   * Iterate over all extenders and reducers and each set as invisible.
   */
  const deactivateAll = useEventCallback(() => {
    const filters = cloneDeep(filtersState);

    const extenders = filters[0].filters![0].filters;

    extenders?.forEach((r) => {
      r.visible = false;
    });

    const reducers = filters[0].filters![1].filters;
    reducers?.forEach((r) => {
      r.visible = false;
    });

    setFiltersState(filters);

    tableRef.current?.setPreFilters(normalizeFilterState(filters));
  });

  const {
    activeExtender,
    setActiveExtender,
    activateAllExtenders,
    deactivateAllExtenders,
    appendExtender,
    confirmExtender,
    toggleExtender,
    removeExtender,
  } = useExtender({ filtersState, setFiltersState });
  const { appendReducer, toggleReducer, removeReducer } = useReducer({
    filtersState,
    setFiltersState,
  });

  const context: FilterContext = useMemo(
    () => ({
      filtersState,

      // extender stuff
      extenders: filtersState?.[0]?.filters?.[0]?.filters ?? [],
      activeExtender,
      setActiveExtender,
      activateAllExtenders,
      deactivateAllExtenders,
      appendExtender,
      confirmExtender,
      toggleExtender,
      removeExtender,

      // reducer stuff
      reducers: filtersState?.[0]?.filters?.[1]?.filters ?? [],
      appendReducer,
      toggleReducer,
      removeReducer,

      activateAll,
      deactivateAll,

      filters,
      setFilters,
    }),
    [
      filtersState,
      setActiveExtender,
      confirmExtender,
      activateAllExtenders,
      deactivateAllExtenders,
      activeExtender,
      appendExtender,
      toggleExtender,
      appendReducer,
      toggleReducer,
      removeExtender,
      removeReducer,
      activateAll,
      deactivateAll,
      filters,
      setFilters,
    ]
  );

  return {
    context,
  };
}
