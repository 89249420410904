import { useEffect, useMemo, useState } from 'react';
import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { AuthContextType, AuthType } from './auth-types';

/**
 *  Endpoint for getting information about types of currently supported authentications
 */
function authTypeCall() {
  return abortableFetch(EvidenceApiUrl.AUTH, {
    method: 'GET',
  });
}

export function useAuth(): AuthContextType {
  const [authType, setAuthType] = useState<AuthContextType>({});

  const getAuthType = useEventCallback(async () => {
    try {
      const response = authTypeCall();
      const data: AuthType[] = await response.json();

      if (data?.[0] === AuthType.FORM) {
        setAuthType({ type: data[0], loginUrl: '/' });
      } else {
        setAuthType({
          type: data[0],
          loginUrl: `${EvidenceApiUrl.AUTH}/kerberos`,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    getAuthType();
  }, []);

  return useMemo(
    () => ({
      loginUrl: authType.loginUrl,
      type: authType.type,
    }),
    [authType]
  );
}
