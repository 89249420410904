import { CourtFileType } from '@enums';

export function useAssignedAndResolvedCountsColumns(
  courtFileType?: CourtFileType
) {
  const typeLabel =
    courtFileType === CourtFileType.SENATE ? 'senátních' : 'plenárních';

  const singleTypeColumns = [
    {
      name: 'Soudce',
      datakey: 'judgeName',
      width: 400,
    },
    {
      name: `Přidělených ${typeLabel} spisů`,
      datakey: 'assignedCount',
      width: 200,
    },
    {
      name: `Vyřízených ${typeLabel} spisů`,
      datakey: 'resolvedCount',
      width: 200,
    },
  ];

  const bothTypesColumns = [
    {
      name: 'Soudce',
      datakey: 'judgeName',
      width: 250,
    },
    {
      name: 'Přiděleno - SN',
      datakey: 'countSenateAssigned',
      width: 125,
    },
    {
      name: 'Ukončeno - SN',
      datakey: 'countSenateResolved',
      width: 125,
    },
    {
      name: 'Přiděleno - PL',
      datakey: 'countPlenaryAssigned',
      width: 125,
    },
    {
      name: 'Ukončeno - PL',
      datakey: 'countPlenaryResolved',
      width: 125,
    },
  ];

  return courtFileType ? singleTypeColumns : bothTypesColumns;
}
