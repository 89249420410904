import React from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryObject,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { Decision } from '@models';
import { DecisionState, EvidenceBrowserUrl } from '@enums';
import { Redirect } from '../redirect/redirect';

export function useDecisionFieldColumns({
  decisionForms,
  decisionStates,
}: {
  decisionForms: DictionaryObject[];
  decisionStates: DictionaryObject[];
}): TableFieldColumn<Decision>[] {
  const intl = useIntl();

  const useForms = () => useStaticListSource(decisionForms);
  const useStates = () => useStaticListSource(decisionStates);

  const getEvidenceUrl = (state?: DecisionState) => {
    switch (state) {
      case DecisionState.IN_PROGRESS:
        return EvidenceBrowserUrl.DECISIONS_IN_PROGRESS;
      case DecisionState.TO_ANONYMIZE:
        return EvidenceBrowserUrl.DECISIONS_TO_ANONYMIZE;
      case DecisionState.TO_MAKE_ACCESSIBLE:
        return EvidenceBrowserUrl.DECISIONS_TO_MAKE_ACCESSIBLE;
      case DecisionState.ACCESSIBLE:
        return EvidenceBrowserUrl.DECISIONS_ACCESSIBLE;
      default:
        return EvidenceBrowserUrl.DECISIONS;
    }
  };

  return [
    {
      datakey: 'id',
      name: '',
      width: 90,
      CellComponent: function Cell(props: TableFieldCellProps<Decision>) {
        const url = getEvidenceUrl(props.rowValue.state);

        return <Redirect url={url} id={props.value} />;
      },
    },
    {
      name: intl.formatMessage({
        id: 'ES__DECISIONS_TOOLBAR__COLUMN__ALLOW_PUBLISHING',
        defaultMessage: 'Umožnit zveřejnění',
      }),
      datakey: 'allowPublishing',
      sortkey: 'allowPublishing',
      width: 100,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DECISIONS_TOOLBAR__COLUMN__NUMBER',
        defaultMessage: 'Číslo rozhodnutí',
      }),
      datakey: 'number',
      sortkey: 'number',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__FORM',
        defaultMessage: 'Forma rozhodnutí',
      }),
      datakey: 'form',
      sortkey: 'form.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useForms),
    },
    {
      name: intl.formatMessage({
        id: 'ES__DECISIONS_TOOLBAR__COLUMN__POPULAR_NAME',
        defaultMessage: 'Populární název',
      }),
      datakey: 'popularName',
      sortkey: 'popularName',
      width: 350,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DECISIONS_TOOLBAR__COLUMN__DECISION_DATE',
        defaultMessage: 'Datum rozhodnutí',
      }),
      datakey: 'decisionDate',
      sortkey: 'decisionDate',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      datakey: 'state',
      sortkey: 'state.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useStates),
    },
  ];
}
