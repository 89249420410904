import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeadlineFields } from '@modules/es-file-inf/fields/deadlines/deadlines';
import { useHistoryDescriptors } from '@modules/es-file/es-file-history-descriptors';
import { HistoryField } from '@composite/history/history-field';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { DocumentFields } from './fields/documents/documents';
import { DraftsFields } from './fields/drafts/drafts';
import { GeneralFields } from './fields/general/general';

export function Fields() {
  const historyDescriptors = useHistoryDescriptors();

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Obecné"
        />
      ),
      key: 'GENERAL',
      validationKeys: ['agenda', 'proposer', 'participant'],
      content: <GeneralFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__PANEL_TITLE__DOCUMENTS"
          defaultMessage="Dokumenty"
        />
      ),
      key: 'DOCUMENTS',
      content: <DocumentFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__PANEL_TITLE__DEADLINES"
          defaultMessage="Lhůty"
        />
      ),
      key: 'DEADLINES',
      content: <DeadlineFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__PANEL_TITLE__DRAFTS"
          defaultMessage="Koncepty"
        />
      ),
      key: 'Drafts',
      content: <DraftsFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__PANEL_TITLE__HISTORY"
          defaultMessage="Historie"
        />
      ),
      key: 'HISTORY',
      content: <HistoryField descriptors={historyDescriptors} />,
    },
  ];

  return <Tabs config={config} />;
}
