import { useHistoryDescriptors as useDeadlineHistoryDescriptors } from '@composite/deadlines/deadline-history-descriptors';
import { HistoryFieldDescriptor } from '@composite/history/history-types';
import { useHistoryDescriptors as useCommonDocumentHistoryDescriptors } from '../document/document-history-descriptors';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const documentDescriptors = useCommonDocumentHistoryDescriptors();
  const deadlineDescriptors = useDeadlineHistoryDescriptors();

  return [
    ...documentDescriptors,
    ...deadlineDescriptors,
    {
      key: 'attachmentsLabelRange',
      label: 'Rozsah číslování příloh',
    },
    {
      key: 'complaint',
      label: 'Příznak, zda-li se jedná o stížnost',
    },
    {
      key: 'copyDetails.order',
      label: 'Pořadí kopie',
    },
    {
      key: 'copyDetails.original',
      label: 'Originální dokument',
    },
    {
      key: 'deadlines',
      label: 'Lhůty',
    },
    {
      key: 'delivery.subject',
      label: 'Doručení - věc',
    },
    {
      key: 'delivery.sender',
      label: 'Doručení - odesílatel',
    },
    {
      key: 'incomingNumber',
      label: 'Došlé číslo',
    },
    {
      key: 'originator',
      label: 'Původce',
    },
    {
      key: 'preceding',
      label: 'Předchází',
    },
    {
      key: 'processed',
      label: 'Zpracován',
    },
    {
      key: 'verification',
      label: 'Ověření',
    },
  ];
}
