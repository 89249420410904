import { useContext } from 'react';
import { DetailContext, DetailHandle } from '@eas/common-web';
import { openEsFileApiCall } from '@modules/es-file/dialog-actions/open-file/open-file-hook';
import { PlenaryOpinionFile } from '@models';
import { EsFileState } from '@enums';

export function useReopenDialog() {
  const { source } =
    useContext<DetailHandle<PlenaryOpinionFile>>(DetailContext);

  const showButton =
    source.data?.state &&
    [
      EsFileState.ACCEPTED,
      EsFileState.REJECTED,
      EsFileState.WITHDRAWN,
    ].includes(source.data.state);

  return {
    callApi: openEsFileApiCall,
    showButton,
  };
}
