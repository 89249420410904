import { useContext } from 'react';
import { UserContext, abortableFetch, useEventCallback } from '@eas/common-web';
import { Deadline } from '@models';
import { EvidenceApiUrl, Role } from '@enums';
import { DeadlineContext } from '../deadline-context';

export function useAddDeadlineDialog() {
  const { hasPermission } = useContext(UserContext);
  const { display } = useContext(DeadlineContext);

  const callApi = useEventCallback((id: string, formData: Deadline) => {
    return abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/deadline`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  const show =
    display === 'IN_DOCUMENT' &&
    (hasPermission(Role.INDEX) || hasPermission(Role.SECRETARY));

  return {
    callApi,
    show,
  };
}
