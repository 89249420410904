import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyComponent, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictSenate } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { useColumns } from './dict-senate-columns';
import { Fields } from './dict-senate-fields';
import { useValidationSchema } from './dict-senate-schema';

export function DictSenates() {
  const validationSchema = useValidationSchema();
  const intl = useIntl();

  const evidence = useDictionaryEvidence<DictSenate>({
    version: 2,
    identifier: 'SENATES',
    apiProps: {
      url: EvidenceApiUrl.DICT_SENATES,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.SENATE,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_SENATES__TABLE__TITLE',
        defaultMessage: 'Senáty',
      }),
      reportTag: ExportTags.DICT_SENATES,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.SENATE,
      validationSchema,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_SENATES__DETAIL__TITLE',
          defaultMessage: 'Senát',
        }),
        showBtn: (btn) => !['NEW', 'REMOVE'].includes(btn),
        ActionBar: EmptyComponent,
      },
    },
  });

  return <Evidence {...evidence} />;
}
