import { stubTrue } from 'lodash';
import {
  HookedSource,
  useFetch,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import { DictionaryObject, DomainObject } from '@models';
import { EvidenceApiUrl, HistoryEntityType } from '@enums';

export const HISTORY_SOURCE = 'HISTORY_SOURCE';

export function useHistorySource<TYPE extends DomainObject>({
  shouldLoad,
  entityType,
}: {
  shouldLoad?: (data: TYPE) => boolean;
  entityType: HistoryEntityType;
}): HookedSource<TYPE> {
  const source = useScrollableSource();

  return {
    [HISTORY_SOURCE]: {
      source,
      shouldLoad: shouldLoad ?? stubTrue,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(
          `${EvidenceApiUrl.HISTORY}?id=${id}&entityType=${entityType}`
        );
        source.setParams({
          size: 10,
          sort: [
            {
              type: 'FIELD',
              field: 'created',
              order: 'DESC',
            },
          ],
        });
      },
    },
  };
}

export function useEntityTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.HISTORY}/entity-type/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useEventTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.HISTORY}/event-type/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useHistoryCollectionOperation() {
  return useStaticListSource([
    { id: 'ADD', name: 'Vytvoření záznamu' },
    { id: 'REMOVE', name: 'Odstranění záznamu' },
    { id: 'UPDATE', name: 'Editace záznamu' },
  ]);
}
