export const INTERNAL_DOCUMENTS = {
  title: 'Interní dokumenty',
  actions: [
    {
      name: 'Upravení dokumentu',
      description:
        'Pouze u dokumentů, které jsou vázány na neuzavřený spis. Uživatel může upravovat jednotlivé atributy dokumenty.',
    },
    { name: 'Nová úloha', description: 'Vytvoření nové uživatelské úlohy.' },
  ],
};
