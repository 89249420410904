export const TASKS = {
  title: 'Úlohy',
  description:
    'Úlohy jsou nástrojem aplikace Evidence soudních spisů pro notifikaci uživatelů o nutnosti provedení úkonů nad danou entitou (spisem, dokumentem).',
  actions: [
    {
      name: 'Vytvoření úlohy',
      description: 'Úlohu je možné vytvořit na spisu či dokumentu.',
    },
    {
      name: 'Upravení úlohy',
      description: 'Uživatel může upravovat jednotlivé atributy úlohy.',
    },
    {
      name: 'Hotovo',
      description:
        'Převedení úlohy do stavu <b>Hotovo</b>, dostupné pro úlohy ve stavu <b>Ke zpracování</b>.',
    },
    {
      name: 'Storno',
      description:
        'Převedení úlohy do stavu <b>Stornováno</b>, dostupné pro úlohy ve stavu <b>Ke zpracování</b>.',
    },
  ],
};
