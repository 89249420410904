import { noop, stubFalse } from 'lodash';
import React, { useContext, useRef } from 'react';
import {
  ActionButtonHandle,
  DetailContext,
  DetailHandle,
  EmptyComponent,
  FormPanel,
  TableField,
  TableFieldHandle,
} from '@eas/common-web';
import { Delimeter } from '@components/form/delimeter/delimeter';
import { useAutoCollapse } from '@components/form/misc/auto-collapse-hook';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { Document, DocumentAttachment } from '@models';
import { AttachmentType, UIElement } from '@enums';
import { useAttachmentColumns } from './attachment-columns';
import { AttachmentDialog } from './attachment-dialog';
import { AttachmentToolbar } from './attachment-toolbar';

export interface AttachmentFieldProps {
  attachmentType?: AttachmentType;
  attachments: DocumentAttachment[];
  defaultName: string;
  disabled?: boolean;
  hasPermission: (element: string) => boolean;
  onAddedAttachment?: (item: DocumentAttachment) => Promise<void>;
  showAddButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showRevalidateButton?: boolean;
  showDownloadReportButton?: boolean;
  disableEditButton?: (attachment: DocumentAttachment) => boolean;
}

export function AttachmentField({
  attachmentType,
  attachments,
  label,
  defaultName = '',
  showToolbar = true,
  showAddButton = true,
  showEditButton = true,
  showDeleteButton = true,
  showRevalidateButton = true,
  showDownloadReportButton = true,
  disableEditButton = stubFalse,
  defaultExpanded,
  hasPermission,
  disabled = false,
  onAddedAttachment,
}: {
  attachmentType?: AttachmentType;
  attachments: DocumentAttachment[];
  label: React.ReactNode;
  defaultName: string;
  showToolbar?: boolean;
  showAddButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showRevalidateButton?: boolean;
  showDownloadReportButton?: boolean;
  disableEditButton?: (attachment: DocumentAttachment) => boolean;
  defaultExpanded?: boolean;
  hasPermission: (element: string) => boolean;
  disabled?: boolean;
  onAddedAttachment?: (item: any) => Promise<void>;
}) {
  const { source, isLocked } =
    useContext<DetailHandle<Document>>(DetailContext);

  const { panelRef } = useAutoCollapse(attachments, defaultExpanded);

  const columns = useAttachmentColumns(attachmentType);

  const tableRef = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const hasNoPermissions = Object.keys(UIElement.Attachment).every(
    (key) => !hasPermission(key)
  );
  const disabledField =
    disabled || !showToolbar || isLocked || hasNoPermissions;

  return (
    <>
      <FormPanel
        label={label}
        expandable={true}
        defaultExpanded={defaultExpanded ?? false}
        ref={panelRef}
        summary={<FormTableCount items={attachments} />}
      >
        <TableField<DocumentAttachment>
          ref={tableRef}
          value={attachments}
          onChange={noop}
          ToolbarComponent={(props) =>
            showToolbar ? (
              <AttachmentToolbar
                {...props}
                attachmentType={attachmentType}
                attachments={attachments}
                defaultName={defaultName}
                ref={editRef}
                hasPermission={hasPermission}
                disabled={disabledField}
                onAddedAttachment={onAddedAttachment}
                showAddButton={showAddButton}
                showEditButton={showEditButton}
                showDeleteButton={showDeleteButton}
                showRevalidateButton={showRevalidateButton}
                showDownloadReportButton={showDownloadReportButton}
                disableEditButton={disableEditButton}
              />
            ) : (
              <EmptyComponent />
            )
          }
          columns={columns}
          FormFieldsComponent={function Fields() {
            return <AttachmentDialog documentType={source.data?.type} />;
          }}
          maxRows={15}
          disabled={disabledField}
          onRowClick={(_, index) => {
            if (
              !disabledField &&
              hasPermission(UIElement.Attachment.EDIT_ATTACHMENT)
            ) {
              tableRef.current?.setSelectedIndex(index);
              editRef.current?.executeAction();
            }
          }}
        />
      </FormPanel>
      <Delimeter />
    </>
  );
}
