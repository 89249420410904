import { stubTrue } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  EvidenceContext,
  PagedTableFieldContext,
  TableFieldToolbarButton,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { InternalDocumentFields } from '@modules/document-internal/fields/general/general';
import { useValidationSchema } from '@modules/document-internal/internal-document-schema';
import { DECISION_SOURCE } from '@modules/es-file/es-file-hooked-sources';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { Document } from '@models';
import { AttachmentType, EvidenceApiUrl } from '@enums';
import { AttachmentField } from '../../attachments/attachment-field';
import { DocumentDialogDataProvider } from '../document-context';

export function useAddInternalDocumentDialog() {
  const callCreateApi = useEventCallback((id: string, formData: Document) => {
    return abortableFetch(`${EvidenceApiUrl.DOCUMENTS}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  const callEditApi = useEventCallback((id: string, formData: Document) => {
    return abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${formData.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  return {
    callCreateApi,
    callEditApi,
  };
}

export function AddInternalDocumentButton({
  initialValues,
  disabled,
  refreshAfterConfirm = false,
}: {
  initialValues: Document;
  disabled: boolean;
  refreshAfterConfirm?: boolean;
}) {
  const intl = useIntl();

  const { source: documentSource } = useContext(PagedTableFieldContext);
  const { hookedSources } = useContext(EvidenceContext);
  const { refreshAll } = useContext(DetailContext);
  const decisionsSource = hookedSources[DECISION_SOURCE]?.source;

  const [result, setResult] = useState<Document | null>(null);
  const attachmentRef = useRef<ActionButtonHandle>(null);

  const validationSchema = useValidationSchema();
  const { callCreateApi, callEditApi } = useAddInternalDocumentDialog();

  const refreshSources = () => {
    documentSource.reset();
    documentSource.loadMore();

    decisionsSource.reset();
    decisionsSource.loadMore();
  };

  return (
    <>
      <DetailActionButton
        promptKey="ADD_INTERNAL_DOCUMENT"
        apiCall={callCreateApi}
        buttonLabel={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_INTERNAL_DOCUMENT',
          defaultMessage: 'Přidat interní dokument',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_INTERNAL_DOCUMENT__TITLE',
          defaultMessage: 'Interní dokument',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_INTERNAL_DOCUMENT__TEXT',
          defaultMessage: 'Vyplňte formulář.',
        })}
        dialogWidth={800}
        modes={[DetailMode.VIEW, DetailMode.EDIT]}
        ButtonComponent={function Button({ label, onClick }) {
          return (
            <TableFieldToolbarButton
              IconComponent={AddCircleOutlineIcon}
              show={true}
              disabled={disabled ?? false}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__DOCUMENTS_TOOLBAR__ADD_INTERNAL_DOCUMENT__BUTTON',
                  defaultMessage: 'Přidat interní',
                })}
              </Typography>
            </TableFieldToolbarButton>
          );
        }}
        FormFields={function Fields() {
          return (
            <DocumentDialogDataProvider document={result!}>
              {() => <InternalDocumentFields />}
            </DocumentDialogDataProvider>
          );
        }}
        formInitialValues={initialValues}
        formValidationSchema={validationSchema}
        refreshAfterConfirm={false}
        onResult={async (result: Document) => {
          setResult(result);

          requestAnimationFrame(() => {
            attachmentRef.current?.executeAction();
          });
        }}
      />
      <DetailActionButton
        ref={attachmentRef}
        promptKey="ADD_INTERNAL_DOCUMENT_ATTACHMENTS"
        apiCall={callEditApi}
        closeCallback={refreshSources}
        buttonLabel={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_INTERNAL_DOCUMENT_ATTACHMENTS',
          defaultMessage: 'Přidat přílohy',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_INTERNAL_DOCUMENT_ATTACHMENTS__TITLE',
          defaultMessage: 'Přílohy interního dokumentu',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_INTERNAL_DOCUMENT_ATTACHMENTS__TEXT',
          defaultMessage: 'Vyplňte formulář.',
        })}
        dialogWidth={800}
        dialogShowClose={false}
        modes={[]}
        onSuccess={async () => {
          if (refreshAfterConfirm) {
            refreshAll();
          }
          refreshSources();
          setResult(null);
        }}
        dialogDisableBackdrop={true}
        formInitialValues={result}
        FormFields={function Fields() {
          return (
            <DocumentDialogDataProvider document={result!}>
              {({ source }) => (
                <>
                  <InternalDocumentFields />
                  <AttachmentField
                    label={
                      <FormattedMessage
                        id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__INTERNAL"
                        defaultMessage="Přílohy interního dokumentu"
                      />
                    }
                    defaultName={result?.summary ?? ''}
                    attachments={source.data?.attachments ?? []}
                    attachmentType={AttachmentType.INTERNAL}
                    defaultExpanded={true}
                    hasPermission={stubTrue}
                  />
                </>
              )}
            </DocumentDialogDataProvider>
          );
        }}
      />
    </>
  );
}
