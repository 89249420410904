import { abortableFetch, useEventCallback } from '@eas/common-web';
import { Viewing } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useAddViewingDialog() {
  const callApi = useEventCallback((id: string, formData: Viewing) => {
    return abortableFetch(`${EvidenceApiUrl.ES_FILES}/${id}/viewing`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  return {
    callApi,
  };
}
