enum Element {
  /**
   * Spisová značka
   */
  FILE_NUMBER = 'FILE_NUMBER',

  /**
   * Čárový kód
   */
  BAR_CODE = 'BAR_CODE',

  /**
   * Datum vytvoření
   */
  CREATED_DATE = 'CREATED_DATE',

  /**
   * Agenda
   */
  AGENDA = 'AGENDA',

  /**
   * Stav
   */
  STATE = 'STATE',

  /**
   * Navrhovatel
   */
  PROPOSER = 'PROPOSER',

  /**
   * Účastník
   */
  PARTICIPANT = 'PARTICIPANT',

  /**
   * Právní zástupce
   */
  LEGAL_ADVISER = 'LEGAL_ADVISER',

  /**
   * Vedoucí rejstříku
   */
  INDEX_USER_ROLE = 'INDEX_USER_ROLE',

  /**
   * Rozhodující orgán
   */
  DECIDING_AUTHORITY = 'DECIDING_AUTHORITY',

  /**
   * Způsob vyřízení
   */
  SETTLEMENT_METHOD = 'SETTLEMENT_METHOD',

  /**
   * Nahlížení do kárného řízení
   */
  VIEWINGS = 'VIEWINGS',

  /**
   * Datum rozhodnutí
   */
  DECISION_DATE = 'DECISION_DATE',

  /**
   * Datum doručení rozhodnutí
   */
  DECISION_DELIVERY_DATE = 'DECISION_DELIVERY_DATE',

  /**
   * Datum uzavření
   */
  CLOSING_DATE = 'CLOSING_DATE',

  /**
   * Běžící lhůty
   */
  RUNNING_DEADLINES = 'RUNNING_DEADLINES',

  /**
   * Lhůty
   */
  DEADLINES = 'DEADLINES',

  /**
   * Poznámky
   */
  NOTES = 'NOTES',

  /**
   * Koncepty
   */
  DRAFTS = 'DRAFTS',

  /**
   * AKCE: Nový spis
   */
  CREATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON = 'CREATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON',

  /**
   * AKCE: Upravit spis
   */
  UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON = 'UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON',

  /**
   * AKCE: Smazat spis
   */
  DELETE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON = 'DELETE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON',

  /**
   * AKCE: Změnit agendu
   */
  UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_AGENDA_BUTTON = 'UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_AGENDA_BUTTON',

  /**
   * AKCE: Změnit stav
   */
  UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_STATE_BUTTON = 'UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_STATE_BUTTON',

  /**
   * AKCE: Vyvést z rejstříku
   */
  TAKE_OUT_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON = 'TAKE_OUT_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON',

  /**
   * AKCE: Znovu otevřít
   */
  REOPEN_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON = 'REOPEN_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON',

  /**
   * AKCE: Obal spisu
   */
  CREATE_FILE_COVER_BUTTON = 'CREATE_FILE_COVER_BUTTON',
}

export default Element;
