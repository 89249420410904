import React from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { AddAssigningRulesButton } from '@composite/assigning-rules/dialog-actions/add-assigning-rules-hook';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { Judge } from '@models';
import { EvidenceApiUrl, ExportTags } from '@enums';
import { useColumns } from './judge-columns';
import { Fields } from './judge-fields';
import { useJudgeHookedSources } from './judge-hooked-sources';
import { useValidationSchema } from './judge-schema';

export function Judges() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useAuthoredEvidence<Judge>({
    identifier: 'JUDGES',
    version: 6,
    apiProps: {
      url: EvidenceApiUrl.JUDGES,
      hookedSources: useJudgeHookedSources,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__JUDGES__TABLE__TITLE',
        defaultMessage: 'Soudci',
      }),
      reportTag: ExportTags.DICT_JUDGES,
      createRowStyle: DictionaryEvidence.createDictionaryRowStyle,
      defaultSorts: [],
    },
    detailProps: {
      validationSchema,
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__JUDGES__DETAIL__TITLE',
          defaultMessage: 'Soudce',
        }),
        ActionBar: DictionaryEvidence.dictionaryActionbarFactory({
          After: function AfterButton() {
            return (
              <AddAssigningRulesButton
                ButtonComponent={PrimaryDetailActionbarButton}
              />
            );
          },
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
