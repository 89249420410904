import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Decision } from '@models';
import { useColumns as useDecisionColumns } from '../decision/decision-columns';

export function useColumns(): TableColumn<Decision>[] {
  const intl = useIntl();
  const columns = useDecisionColumns();

  const { decisionStates } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(decisionStates);

  return [
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      valueMapper: TableCells.useSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    ...columns,
  ];
}
