/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Common messages
 */
const ERROR: MessageType<string> = {
  OPERATION_ACCESS_DENIED: [
    <FormattedMessage
      id="ES__MESSAGE__COMMON__ERROR_OPERATION_ACCESS_DENIED"
      defaultMessage="Přihlášený uživatel nemá dostatečná oprávnění pro vykonání akce."
    />,
    SnackbarVariant.ERROR,
  ],
  UNEXPECTED: [
    <FormattedMessage
      id="ES__MESSAGE__COMMON__ERROR_UNEXPECTED"
      defaultMessage="Došlo k neočekávané chybě, kontaktujte administrátora."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default { ERROR };
