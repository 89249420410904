import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { RedirectForm, useRedirect } from '@composite/redirect/dialog-redirect';
import { EsFile, InfFile } from '@models';
import { EsFileState, EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';
import { useFormFields } from './change-state-dialog';
import { getOptions } from './change-state-utils';

export function useChangeStateDialog() {
  const match = useRouteMatch();

  const { source } = useContext<DetailHandle<InfFile>>(DetailContext);

  const inAllFiles = [
    EvidenceBrowserUrl.ES_FILES_ALL + '/:id?',
    EvidenceBrowserUrl.ES_FILES_SEARCH + '/:id?',
    match.path === EvidenceBrowserUrl.INF_FILES_ALL + '/:id?',
  ].includes(match.path);

  const callApi = useEventCallback(
    (id: string, formData: { state: EsFileState }) =>
      abortableFetch(
        `${EvidenceApiUrl.ES_FILES}/${id}/state/${formData.state}`,
        {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'PUT',
        }
      )
  );

  const showButton =
    source.data?.state &&
    ![
      EsFileState.CLOSED,
      EsFileState.SHREDDED,
      EsFileState.IN_PROGRESS,
    ].includes(source.data?.state);

  const FormFields = useFormFields();

  const initialState =
    source.data?.state === EsFileState.NEW
      ? EsFileState.IN_PROGRESS
      : EsFileState.CLOSED;

  const redirectBag = useRedirect<
    EsFile,
    RedirectForm & { state?: EsFileState }
  >({
    options: (values) =>
      getOptions(source.data?.state, values?.state ?? initialState),
    redirectTo: (values) => {
      switch (values?.state) {
        case EsFileState.IN_PROGRESS:
          return EvidenceBrowserUrl.INF_FILES_IN_PROGRESS;
        case EsFileState.CLOSED:
          return EvidenceBrowserUrl.INF_FILES_CLOSED;

        default:
          return EvidenceBrowserUrl.ES_FILES_ALL;
      }
    },
    FormFields,
    initialValues: {
      state: initialState,
    },
  });

  return {
    callApi,
    showButton,
    redirectBag: inAllFiles ? { FormFields } : { ...redirectBag },
  };
}
