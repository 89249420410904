import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ContextHelp, useFormSelector } from '@eas/common-web';
import { CourtFile, PlenaryOpinionFile } from '@models';

export const useStyles = makeStyles({
  disabledComponent: {
    lineHeight: '22px',
    height: 22,
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  highlight: {
    backgroundColor: red[50],
    color: red[500],
  },
});

export function HighlightedDisabledComponent<
  T extends CourtFile | PlenaryOpinionFile
>(props: {
  value: string | null;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}) {
  const classes = useStyles();
  const intl = useIntl();

  const settlementMethods = useFormSelector((values: T) => {
    const decisions = values.decisions;
    const settlementMethods = decisions
      ?.map((d) => d.settlementMethods)
      .flat()
      .map((d) => d?.label);

    return settlementMethods;
  });

  const values = props.value?.split('; ');

  return (
    <>
      {values?.map((value) => {
        const highlighted = !settlementMethods?.includes(value);

        return (
          <Typography
            key={value}
            variant="body1"
            className={clsx(classes.disabledComponent, {
              [classes.highlight]: highlighted,
            })}
          >
            {value}
            {highlighted && (
              <ContextHelp
                type="CLICKABLE"
                label={intl.formatMessage({
                  id: 'ES__SETTLEMENT_METHOD__HIGHLIGHTED',
                  defaultMessage:
                    'Způsob vyžízení není součastí žádného rozhodnutí',
                })}
              />
            )}
          </Typography>
        );
      })}
    </>
  );
}
