import { get } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDebouncedCallback } from 'use-debounce/lib';
import {
  ApiFilterOperation,
  FormContext,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { useZipCodes } from '@modules/dict-zip-code/dict-zip-code-api';

export function CityField({
  prefix,
  prefill = true,
  required,
  disabled,
  notifyChange,
}: {
  prefix?: string;
  prefill?: boolean;
  disabled?: boolean;
  required?: boolean;
  notifyChange?: (value: unknown) => void;
}) {
  const { setFieldValue } = useContext(FormContext);
  const name = prefix ? `${prefix}.city` : 'city';
  const zipCodeName = prefix ? `${prefix}.zipCode` : 'zipCode';

  const { city } = useFormSelector((data) => ({
    city: get(data, name),
  }));

  const zipCodes = useZipCodes({
    params: {
      filters: [
        {
          field: 'city',
          operation: ApiFilterOperation.CONTAINS,
          value: city,
        },
      ],
    },
  });

  const handleChange = useDebouncedCallback(async (value) => {
    if (prefill && value) {
      zipCodes.reset();
      const { count, items } = await zipCodes.loadMore();

      if (count === 1) {
        setFieldValue(zipCodeName, items[0]);
      }
    }
  }, 500);

  return (
    <FormTextField
      name={name}
      label={
        <FormattedMessage
          id="ES__SUBJECTS__DETAIL__FIELD_LABEL__CITY"
          defaultMessage="Město"
        />
      }
      notifyChange={(value) => {
        handleChange[0](value);
        notifyChange?.(value);
      }}
      required={required}
      disabled={disabled}
    />
  );
}
