import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormAutocomplete } from '@eas/common-web';
import { useNotes } from '@modules/dict-note/dict-note-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';

export function NoteDialog() {
  const notes = useNotes();

  return (
    <>
      <FormAutocomplete
        name="text"
        label={
          <FormattedMessage
            id="ES__NOTES__FIELD_LABEL__NOTE"
            defaultMessage="Poznámka"
          />
        }
        source={notes}
        freeSolo={true}
        labelMapper={autocompleteLabelMapper}
        autoFocus={true}
        labelOptions={{
          hide: true,
        }}
      />
    </>
  );
}
