import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormPanel,
  FormSelect,
  FormTextField,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Dispatch } from '@models';
import { DispatchMethod, EvidenceBrowserUrl } from '@enums';

export function Fields() {
  const { dispatchMethods, documentDispatchStates } = useContext(
    StaticDataContext
  );
  const methodsSource = useStaticListSource(dispatchMethods);
  const statesSource = useStaticListSource(documentDispatchStates);

  const { esFile, document, method } = useFormSelector((data: Dispatch) => ({
    document: data.document,
    esFile: data?.document?.esFile,
    method: data.method,
  }));

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormTextField
          name="document.referenceNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__FIELD_LABEL__DOCUMENT_REFERENCE_NUMBER"
              defaultMessage="Číslo jednací"
            />
          }
          after={
            document?.id && (
              <Redirect
                url={EvidenceBrowserUrl.DOCUMENTS_OUTGOING}
                id={document!.id}
              />
            )
          }
          disabled={true}
        />
        <FormTextField
          name="document.esFile.number.value"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__FIELD_LABEL__ES_FILE_FILE_NUMBER"
              defaultMessage="Spisová značka"
            />
          }
          after={
            esFile?.id && (
              <Redirect url={EvidenceBrowserUrl.ES_FILES_ALL} id={esFile.id} />
            )
          }
          disabled={true}
        />
        <FormTextField
          name="document.summary"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__FIELD_LABEL__DOCUMENT_SUMMARY"
              defaultMessage="Stručný obsah"
            />
          }
        />
        <FormTextField
          name="recipient.label"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__FIELD_LABEL__RECIPIENT"
              defaultMessage="Adresát"
            />
          }
          disabled={true}
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__FIELD_LABEL__DOCUMENT_STATE"
              defaultMessage="Stav"
            />
          }
          source={statesSource}
          valueIsId={true}
        />
        <FormSelect
          name="method"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__FIELD_LABEL__METHOD"
              defaultMessage="Způsob vypravení"
            />
          }
          source={methodsSource}
          valueIsId={true}
        />
        {method === DispatchMethod.EMAIL && (
          <FormTextField
            name="recipient.email"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__FIELD_LABEL__RECIPIENT_EMAIL"
                defaultMessage="Email"
              />
            }
            disabled={true}
          />
        )}
        {method === DispatchMethod.DATA_BOX && (
          <FormTextField
            name="recipient.dataBoxId"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES_ELECTRONIC__DETAIL__FIELD_LABEL__RECIPIENT_DATA_BOX_ID"
                defaultMessage="ID datové schránky"
              />
            }
            disabled={true}
          />
        )}
      </FormPanel>
    </>
  );
}
