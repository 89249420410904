import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  EvidenceContext,
  FormSelect,
  PrimaryDetailActionbarButton,
  abortableFetch,
  useEventCallback,
  useStaticListSource,
} from '@eas/common-web';
import { useAgendas } from '@modules/es-file/es-file-api';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { Agenda } from '@enums';

function useMakeDefaultForAgendaDialog() {
  const { apiUrl } = useContext(EvidenceContext);

  const callApi = useEventCallback(
    (id: string, { agenda }: { agenda: Agenda }) => {
      return abortableFetch(`${apiUrl}/${id}/make-default?agenda=${agenda}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
    }
  );

  return {
    callApi,
  };
}

export function MakeDefaultForAgendaButton() {
  const intl = useIntl();
  const { callApi } = useMakeDefaultForAgendaDialog();

  return (
    <DetailActionButton
      promptKey="MAKE_DEFAULT_FOR_AGENDA"
      apiCall={callApi}
      buttonLabel={intl.formatMessage({
        id: 'ES__DICTIONARY_EVIDENCE__MAKE_DEFAULT_FOR_AGENDA',
        defaultMessage: 'Nastavit jako výchozí',
      })}
      dialogTitle={intl.formatMessage({
        id: 'ES__DICTIONARY_EVIDENCE__MAKE_DEFAULT_FOR_AGENDA__TITLE',
        defaultMessage: 'Změna výchozí hodnoty',
      })}
      dialogText={intl.formatMessage({
        id: 'ES__DICTIONARY_EVIDENCE__MAKE_DEFAULT_FOR_AGENDA__TEXT',
        defaultMessage:
          'Vyberte agendu, pro kterou bude zvolená hodnota číselníku nastavena jako výchozí.',
      })}
      FormFields={MakeDefaultForAgendaDialog}
      ButtonComponent={PrimaryDetailActionbarButton}
    />
  );
}

function MakeDefaultForAgendaDialog() {
  const [agendas] = useAgendas();

  const agendaSource = useStaticListSource(agendas ?? []);

  return (
    <>
      <FormSelect
        name="agenda"
        label={
          <FormattedMessage
            id="ES__MAKE_DEFAULT_FOR_AGENDA_DIALOG__FIELD_LABEL__AGENDA"
            defaultMessage="Agenda"
          />
        }
        required={true}
        source={agendaSource}
        autoFocus={true}
        valueIsId={true}
      />
    </>
  );
}
