import { useContext, useEffect, useState } from 'react';
import { NavigationContext, useEventCallback } from '@eas/common-web';
import { FilterContext } from '@composite/filter/filter-context';
import { DeliveredDocument } from '@models';

export function useScreeningForDocument() {
  const { extenders, reducers, removeExtender, removeReducer } =
    useContext(FilterContext);

  const [deliveredDocument, setDeliveredDocument] =
    useState<DeliveredDocument>();

  const removeDeliveredDocument = useEventCallback(() => {
    setDeliveredDocument(undefined);
  });

  const { stateAction } = useContext(NavigationContext);
  useEffect(() => {
    if (stateAction?.action === 'SCREENING_FOR_DOCUMENT') {
      setDeliveredDocument(stateAction.data);

      extenders.forEach((_, idx) => {
        removeExtender(idx);
      });

      reducers.forEach((_, idx) => {
        removeReducer(idx);
      });
    }
  }, []);

  return {
    deliveredDocument,
    removeDeliveredDocument,
    selectDeliveredDocument: setDeliveredDocument,
  };
}
