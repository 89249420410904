import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DetailContext, PrimaryDetailActionbarButton } from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { UIElement } from '@enums';
import { useDisciplinaryProceedingsPermission } from '../es-file/es-file-permission';
import { useFormFields } from './dialog-actions/change-state/change-state-dialog';
import { useChangeStateDialog } from './dialog-actions/change-state/change-state-hook';
import { useValidationSchema } from './dialog-actions/change-state/change-state-schema';
import { useReopenDialog } from './dialog-actions/reopen/reopen-hook';

export function ActionBar() {
  const intl = useIntl();
  const { source } = useContext(DetailContext);
  const { hasPermission } = useDisciplinaryProceedingsPermission(source.data);

  const { callApi: changeStateApiCall, showButton: changeStateShowButton } =
    useChangeStateDialog();
  const FormFields = useFormFields();

  const { callApi: reopenApiCall, showButton: showReopenButton } =
    useReopenDialog();

  const changeStateValidationSchema = useValidationSchema();

  return (
    <>
      {changeStateShowButton && (
        <DetailActionButton
          promptKey="CHANGE_STATE"
          apiCall={changeStateApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__ACTIONBAR__CHANGE_STATE',
            defaultMessage: 'Změnit stav',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__DIALOG_CHANGE_STATE__TITLE',
            defaultMessage: 'Změna stavu',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__DIALOG_CHANGE_STATE__TEXT',
            defaultMessage: 'Vyberte nový stav.',
          })}
          buttonDisabled={
            !hasPermission(
              UIElement.DisciplinaryProceedingsFile
                .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_STATE_BUTTON
            )
          }
          ButtonComponent={PrimaryDetailActionbarButton}
          formValidationSchema={changeStateValidationSchema}
          FormFields={FormFields}
        />
      )}
      {showReopenButton && (
        <DetailActionButton
          promptKey="REOPEN"
          apiCall={reopenApiCall}
          isLocked={false}
          buttonLabel={intl.formatMessage({
            id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__ACTIONBAR__REOPEN',
            defaultMessage: 'Znovu otevřít',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__DIALOG_REOPEN__TITLE',
            defaultMessage: 'Opětovné otevření spisu',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__DIALOG_REOPEN__TEXT',
            defaultMessage: 'Opravdu chcete znovu otevřít vybraný spis?',
          })}
          buttonDisabled={
            !hasPermission(
              UIElement.DisciplinaryProceedingsFile
                .REOPEN_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON
            )
          }
          ButtonComponent={PrimaryDetailActionbarButton}
        />
      )}
    </>
  );
}
