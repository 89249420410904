import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyComponent, Evidence } from '@eas/common-web';
import { useDatedEvidence } from '@composite/evidence/dated-evidence';
import { Job } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './job-columns';
import { Fields } from './job-fields';

export function Jobs() {
  const intl = useIntl();

  const evidence = useDatedEvidence<Job>({
    identifier: 'JOBS',
    apiProps: {
      url: EvidenceApiUrl.JOBS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__JOBS__TABLE__TITLE',
        defaultMessage: 'Úlohy na spisové službě',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      showNamedSettingsButton: false,
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__JOBS__DETAIL__TITLE',
          defaultMessage: 'Úloha',
        }),
        showBtn: (btn) => ['REFRESH', 'SHARE'].includes(btn),
      },
    },
  });

  return <Evidence {...evidence} />;
}
