import {
  Params,
  ResultDto,
  abortableFetch,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { User, UserAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export const importUsers = () => {
  return abortableFetch(`${EvidenceApiUrl.USERS}/import`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
};

export function useUsersCount() {
  const [response] = useFetch<ResultDto<User>>(
    `${EvidenceApiUrl.USERS}/autocomplete`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        params: {
          size: -1,
        },
      }),
    }
  );

  return response?.count ?? 0;
}

export function useUsers(params?: Params) {
  return useAutocompleteSource<UserAutocomplete>({
    url: `${EvidenceApiUrl.USERS}/autocomplete`,
    params,
  });
}
