import React, { useContext } from 'react';
import {
  EvidenceContext,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { RawSimpleLog } from '@models';
import { HISTORY_SOURCE } from './history-api';
import { useTableColumns } from './history-columns';
import { HistoryContext } from './history-context';
import { HistoryFields } from './history-fields';
import { HistoryToolbar } from './history-toolbar';
import { HistoryFieldDescriptor } from './history-types';

export function HistoryField({
  descriptors,
  source,
}: {
  descriptors: HistoryFieldDescriptor[];
  source?: ScrollableSource<RawSimpleLog>;
}) {
  const { hookedSources } = useContext(EvidenceContext);
  const historySource = source ?? hookedSources?.[HISTORY_SOURCE]?.source;

  const columns = useTableColumns();

  return (
    <FormPanel
      label="Historie"
      expandable
      summary={<FormTableCount source={historySource} />}
    >
      <HistoryContext.Provider value={{ descriptors }}>
        <RemoteTableField<RawSimpleLog>
          source={historySource}
          showRadioCond={() => false}
          showDetailBtnCond={({ content }) =>
            (JSON.parse(content ?? '[]') ?? []).length > 0
          }
          ToolbarComponent={HistoryToolbar}
          FormFieldsComponent={HistoryFields}
          columns={columns}
          disabled={true}
        />
      </HistoryContext.Provider>
    </FormPanel>
  );
}
