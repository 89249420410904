import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { DictContactType } from '@models';
import { EntityName, EvidenceApiUrl } from '@enums';
import { Fields } from './dict-contact-type-fields';

export function DictContactTypes() {
  const intl = useIntl();
  const { columnName, columnActive } =
    DictionaryEvidence.useDictionaryColumns<DictContactType>();

  const evidence = useDictionaryEvidence<DictContactType>({
    identifier: 'CONTACT_TYPES',
    apiProps: {
      url: EvidenceApiUrl.DICT_CONTACT_TYPES,
    },
    tableProps: {
      columns: [columnName, columnActive],
      tableName: intl.formatMessage({
        id: 'ES__DICT_CONTACT_TYPES__TABLE__TITLE',
        defaultMessage: 'Typy kontaktů',
      }),
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.CONTACT_TYPE,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_CONTACT_TYPES__DETAIL__TITLE',
          defaultMessage: 'Typ kontaktu',
        }),
        ActionBar: EmptyComponent,
        showBtn: (btn) => ['REFRESH', 'SHARE'].includes(btn),
      },
    },
  });

  return <Evidence {...evidence} />;
}
