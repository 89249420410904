import { useContext } from 'react';
import { DetailContext, abortableFetch } from '@eas/common-web';
import { Contact } from '@models';
import { EvidenceApiUrl } from '@enums';

export function callApi(id: string, values: Contact) {
  return abortableFetch(
    `${EvidenceApiUrl.SUBJECTS}/${id}/contact/${values.id}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify(values),
    }
  );
}

export function useEditContactDialog() {
  const { source } = useContext(DetailContext);

  // TODO
  const showButton = source.data?.id;

  return {
    callApi,
    showButton,
  };
}
