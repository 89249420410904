import {
  ApiFilterOperation,
  HookedSource,
  useScrollableSource,
} from '@eas/common-web';
import { useHistorySource } from '@composite/history/history-api';
import { Judge } from '@models';
import { EvidenceApiUrl, HistoryEntityType } from '@enums';

export const ASSIGNING_RULE_SOURCE = 'ASSIGNING_RULE_SOURCE';

function useAssigningRuleSource(): HookedSource<Judge> {
  const source = useScrollableSource({
    url: `${EvidenceApiUrl.ASSIGNING_RULES}/list`,
  });

  return {
    [ASSIGNING_RULE_SOURCE]: {
      source,
      shouldLoad: () => true,
      modifyBeforeLoad: ({ id }) => {
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'judge.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useJudgeHookedSources(): HookedSource<Judge> {
  const assigningRules = useAssigningRuleSource();
  const history = useHistorySource({ entityType: HistoryEntityType.JUDGE });

  return Object.assign(assigningRules, history);
}
