import * as Yup from 'yup';
import { Address } from '@models';

export const orientationNumberValidation = Yup.string()
  .test('', '', function (value) {
    const { path, createError } = this;

    if (value && value.length > 6) {
      return createError({
        path,
        message: 'Číslo orientační může mít maximálně 6 znaků',
      });
    }
    return true;
  })
  .nullable();

export const descriptiveNumberValidation = Yup.string()
  .test('', '', function (value) {
    const { path, createError } = this;

    if (value && value.length > 8) {
      return createError({
        path,
        message: 'Číslo popisné může mít maximálně 8 znaků',
      });
    }
    return true;
  })
  .nullable();

export function useValidationSchema() {
  return Yup.object<Address>().shape({
    orientationNumber: orientationNumberValidation,
    descriptiveNumber: descriptiveNumberValidation,
  });
}
