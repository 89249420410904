import React from 'react';
import { useIntl } from 'react-intl';
import { AlogEvent, EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './alog-columns';
import { Fields } from './alog-fields';

export function Alog() {
  const intl = useIntl();

  const columns = useColumns();

  const evidence = useAuthoredEvidence<AlogEvent>({
    version: 2,
    identifier: 'ALOG',
    apiProps: {
      url: EvidenceApiUrl.ALOG,
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'EAS_ALOG_TABLE_TITLE',
        defaultMessage: 'Auditní log',
      }),
      defaultSorts: [
        { field: 'created', datakey: 'created', order: 'DESC', type: 'FIELD' },
      ],
      showReportButton: false,
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        showBtn: (btn) => ['REFRESH', 'SHARE'].includes(btn),
        title: intl.formatMessage({
          id: 'EAS_ALOG_DETAIL_TITLE',
          defaultMessage: 'Auditní log',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
