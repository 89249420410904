import { stubTrue } from 'lodash';
import { HookedSource, useScrollableSource } from '@eas/common-web';
import { UserSubstitution } from '@models';
import { EvidenceApiUrl } from '@enums';

export const SUBSTITUTION_LOG_SOURCE = 'SUBSTITUTION_LOG_SOURCE';

function useSubstitutionLogSource(): HookedSource<UserSubstitution> {
  const source = useScrollableSource();

  return {
    [SUBSTITUTION_LOG_SOURCE]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.USER_SUBSTITUTIONS}/${id}/log`);
      },
    },
  };
}

export function useUserSubstitutionHookedSources(): HookedSource<UserSubstitution> {
  const substitutionLogs = useSubstitutionLogSource();

  return Object.assign(substitutionLogs);
}
