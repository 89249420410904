import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormCustomField, TextField, useFormSelector } from '@eas/common-web';
import { AuthoredObject } from '@models';

const CreatedBy = () => {
  const { createdByDelegate, createdBy } = useFormSelector(
    (data: AuthoredObject) => ({
      createdByDelegate: data?.createdByDelegate,
      createdBy: data?.createdBy,
    })
  );

  const name = createdByDelegate?.substitutedBy
    ? `${createdByDelegate?.substitutedBy?.name} (zástup za ${createdByDelegate?.name})`
    : createdByDelegate?.name !== 'system'
    ? createdByDelegate?.name
    : createdBy?.name;

  return (
    <FormCustomField
      label={
        <FormattedMessage
          id="ES__AUTHORED_FIELDS__FIELD_LABEL__CREATED_BY"
          defaultMessage="Vytvořil"
        />
      }
    >
      <TextField value={name} disabled={true} />
    </FormCustomField>
  );
};

const UpdatedBy = () => {
  const { updatedByDelegate, updatedBy } = useFormSelector(
    (data: AuthoredObject) => ({
      updatedByDelegate: data?.updatedByDelegate,
      updatedBy: data?.updatedBy,
    })
  );

  const name = updatedByDelegate?.substitutedBy
    ? `${updatedByDelegate?.substitutedBy?.name} (zástup za ${updatedByDelegate?.name})`
    : updatedByDelegate?.name !== 'system'
    ? updatedByDelegate?.name
    : updatedBy?.name;

  return (
    <FormCustomField
      label={
        <FormattedMessage
          id="ES__AUTHORED_FIELDS__FIELD_LABEL__UPDATED_BY"
          defaultMessage="Autor úpravy"
        />
      }
    >
      <TextField value={name} disabled={true} />
    </FormCustomField>
  );
};

export const AuthoredFields = {
  CreatedBy,
  UpdatedBy,
};
