/**
 * Stav zástupu uživatele
 */
export enum UserSubstitutionState {
  /**
   * Aktivní
   */
  ACTIVE = 'ACTIVE',

  /**
   * Vypršel
   */
  EXPIRED = 'EXPIRED',

  /**
   * Čekající
   */
  PENDING = 'PENDING',
}
