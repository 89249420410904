import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  FormAutocomplete,
  FormLocalDateField,
  FormTextField,
} from '@eas/common-web';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Agenda, EsFileState } from '@enums';
import { useEsFiles } from '../../../es-file/es-file-api';

export function FormFields() {
  const esFiles = useEsFiles({
    filters: [
      {
        field: 'agenda.id',
        operation: ApiFilterOperation.EQ,
        value: Agenda.COURT,
      },
      {
        field: 'state.id',
        operation: ApiFilterOperation.IN,
        values: [
          EsFileState.REGISTER,
          EsFileState.REGISTER_NEW,
          EsFileState.JUDGE_OFFICE,
        ],
      },
    ],
  });

  return (
    <>
      <FormLocalDateField
        name="mergeDate"
        label={
          <FormattedMessage
            id="ES__ES_FILE__MERGE_FILES_HOOK__FIELD_LABEL__MERGE_DATE"
            defaultMessage="Datum spojení"
          />
        }
        required
      />
      <FormTextField
        name="continuingEsFile.number.value"
        label={
          <FormattedMessage
            id="ES__ES_FILE__MERGE_FILES_HOOK__FIELD_LABEL__CONTINUING_ES_FILE_NUMBER"
            defaultMessage="Pokračující spis"
          />
        }
        disabled
      />
      <FormAutocomplete
        name="mergedEsFiles"
        source={esFiles}
        label={
          <FormattedMessage
            id="ES__ES_FILE__MERGE_FILES_HOOK__FIELD_LABEL__CONTINUING_ES_FILE_NUMBER"
            defaultMessage="Spojené spisy - uzavřené"
          />
        }
        labelMapper={autocompleteLabelMapper}
        multiple
        required
      />
    </>
  );
}
