import { useAutocompleteSource, useFetch } from '@eas/common-web';
import { DictProcessingType, DictProcessingTypeAutocomplete } from '@models';
import { Agenda, EvidenceApiUrl } from '@enums';

export function useProcessingTypes() {
  return useAutocompleteSource<DictProcessingTypeAutocomplete>({
    url: `${EvidenceApiUrl.DICT_PROCESSING_TYPE}/autocomplete`,
  });
}

export function useDefaultProcessingType(agenda: Agenda) {
  return useFetch<DictProcessingType>(
    `${EvidenceApiUrl.DICT_PROCESSING_TYPE}/default?agenda=${agenda}`,
    { method: 'GET', cacheResponse: true }
  );
}
