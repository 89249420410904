import * as Yup from 'yup';
import { Contact, LegalAdviser, Participant } from '@models';

export function useValidationSchema() {
  return Yup.object<LegalAdviser>().shape({
    contact: Yup.mixed<Contact>().nullable().required(),
    clients: Yup.array<Participant>()
      .nullable()
      .min(1, 'Musí být vybrán alespoň 1 klient.')
      .required(),
  });
}
