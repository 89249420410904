import React, { ReactNode } from 'react';
import { grey } from '@material-ui/core/colors';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import { Contact } from '@models';

const useStyles = makeStyles({
  autocompleteValue: {
    paddingLeft: 10,
    marginLeft: '-10px',
    borderBottom: `1px solid ${grey[300]}`,
    marginRight: '-10px',
    paddingRight: 10,
  },
});

export const createRowStyle = (contact: Contact) =>
  (contact as Contact)?.change
    ? {
        backgroundColor: grey[100],
      }
    : {};

export function ContactAutocompleteItem({
  item,
  children,
}: {
  item: Contact;
  children: ReactNode;
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classes.autocompleteValue}
      style={{
        backgroundColor: item?.change
          ? lighten(theme.palette.primary.light, 0.5)
          : '#fff',
      }}
    >
      {children}
    </div>
  );
}
