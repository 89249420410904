import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { AccessoryParticipantField } from '@composite/accessory-participants/accessory-participant-field';
import { ChallengedActField } from '@composite/challenged-acts/challenged-act-field';
import { LegalAdviserField } from '@composite/legal-advisers/legal-adviser-field';
import { ProposerField } from '@composite/proposers/proposer-field';
import { Delimeter } from '@components/form/delimeter/delimeter';
import { EsFile } from '@models';
import { UIElement } from '@enums';
import {
  ACCESSORY_PROPOSER_SOURCE,
  CHALLENGED_ACT_SOURCE,
  LEGAL_ADVISER_SOURCE,
  PROPOSER_SOURCE,
} from '../../../es-file/es-file-hooked-sources';
import { useCourtFilePermission } from '../../../es-file/es-file-permission';

export function MemberFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } =
    useContext<DetailHandle<EsFile>>(DetailContext);

  const { hasPermission } = useCourtFilePermission(source.data);

  const disabledProposers = isLocked || mode !== DetailMode.VIEW;
  const proposerSource = hookedSources[PROPOSER_SOURCE]?.source;

  const disabledAccessoryParticipants = isLocked || mode !== DetailMode.VIEW;
  const accessoryParticipantsSource =
    hookedSources[ACCESSORY_PROPOSER_SOURCE]?.source;

  const disabledLegalAdviser = isLocked || mode !== DetailMode.VIEW;
  const legalAdviserSource = hookedSources[LEGAL_ADVISER_SOURCE]?.source;

  const disabledChallengedAct = isLocked || mode !== DetailMode.VIEW;
  const challengedActSource = hookedSources[CHALLENGED_ACT_SOURCE]?.source;

  return (
    <>
      <ProposerField
        disabled={
          disabledProposers || !hasPermission(UIElement.CourtFile.PROPOSERS)
        }
        source={proposerSource}
      />

      <Delimeter />

      <AccessoryParticipantField
        disabled={
          disabledAccessoryParticipants ||
          !hasPermission(UIElement.CourtFile.ACCESSORY_PARTICIPANTS)
        }
        source={accessoryParticipantsSource}
      />

      <Delimeter />

      <LegalAdviserField
        disabled={
          disabledLegalAdviser ||
          !hasPermission(UIElement.CourtFile.LEGAL_ADVISERS)
        }
        source={legalAdviserSource}
      />

      <Delimeter />

      <ChallengedActField
        disabled={
          disabledChallengedAct ||
          !hasPermission(UIElement.CourtFile.CHALLENGED_ACTS)
        }
        source={challengedActSource}
      />
    </>
  );
}
