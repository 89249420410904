import clsx from 'clsx';
import { format } from 'date-fns';
import React, { useContext, useRef } from 'react';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Form,
  FormHandle,
  FormLocalDateField,
  FormSelect,
  FormSubmitButton,
  useStaticListSource,
} from '@eas/common-web';
import { DashboardDivider } from '@modules/dashboard/divider';
import { useDownloadReport } from '@modules/statistics/actions/download-report-hook';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DecisionForm, EvidenceApiUrl } from '@enums';
import { useStyles } from './card/card';

const useHeaderStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '-20px 24px 20px 24px',
    gap: '10px',
  },
  labelWrapper: {
    display: 'flex',
    gap: '10px',
    width: '50%',
  },
  label: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    width: 'fitContent',
    whiteSpace: 'nowrap',
  },
  downloadBtn: { marginLeft: 24, marginTop: '-10px' },
}));

export function PublicationFiles() {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();

  const ref =
    useRef<
      FormHandle<{ to: string; from: string; decisionForm: DecisionForm }>
    >(null);

  const { decisionForms } = useContext(StaticDataContext);
  const decisionFormSource = useStaticListSource(decisionForms);

  const { callApi } = useDownloadReport();

  const SubmitButton = () => (
    <FormSubmitButton
      type="submit"
      size="small"
      className={headerClasses.downloadBtn}
    >
      <IconButton size="small">
        <GetAppIcon />
      </IconButton>
      <Typography>GENEROVAT SESTAVU</Typography>
    </FormSubmitButton>
  );

  const validationSchema = Yup.object().shape({
    to: Yup.string().nullable().required(),
    from: Yup.string().nullable().required(),
    decisionForm: Yup.mixed<DecisionForm>().nullable().required(),
  });

  return (
    <Form
      editing={true}
      ref={ref}
      initialValues={{
        decisionForm: DecisionForm.FINDING,
        to: format(new Date(), 'yyyy-MM-dd'),
        from: format(new Date(), 'yyyy-MM-dd'),
      }}
      onSubmit={async ({ decisionForm, from, to }) => {
        const errors = await ref.current?.validateForm();

        if (errors?.length === 0) {
          callApi(
            `${EvidenceApiUrl.REPORTS}/publication/generate?decisionForm=${decisionForm}&from=${from}&to=${to}`
          );
        }
      }}
      validationSchema={validationSchema}
    >
      <Grid item xs={12}>
        <Card className={clsx(classes.card)}>
          <div className={classes.content}>
            <div className={classes.divider}>
              <DashboardDivider text="Spisy pro publikaci ve sbírce" />
            </div>
            <div className={headerClasses.wrapper}>
              <div className={headerClasses.labelWrapper}>
                <Typography className={headerClasses.label}>
                  DATUM ROZHODNUTÍ OD
                </Typography>
                <FormLocalDateField
                  name="from"
                  labelOptions={{ hide: true }}
                  layoutOptions={{ noUnderline: true }}
                />
              </div>
              <div className={headerClasses.labelWrapper}>
                <Typography className={headerClasses.label}>
                  DATUM ROZHODNUTÍ DO
                </Typography>
                <FormLocalDateField
                  name="to"
                  labelOptions={{ hide: true }}
                  layoutOptions={{ noUnderline: true }}
                />
              </div>
              <div className={headerClasses.labelWrapper}>
                <Typography className={headerClasses.label}>
                  ROZHODNUTÉ SPISY
                </Typography>
                <FormSelect
                  name="decisionForm"
                  labelOptions={{ hide: true }}
                  source={decisionFormSource}
                  valueIsId
                  layoutOptions={{ noUnderline: true }}
                />
              </div>
            </div>
            <SubmitButton />
          </div>
        </Card>
      </Grid>
    </Form>
  );
}
