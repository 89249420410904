import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { TextNote } from '@models';

export function useEditNoteDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback((noteId: string, formData: TextNote) => {
    return abortableFetch(`${source.url}/note/${noteId}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  return {
    callApi,
  };
}
