import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { DetailContext, useFormSelector } from '@eas/common-web';
import { AttachmentField } from '@composite/attachments/attachment-field';
import { Document } from '@models';
import { AttachmentType } from '@enums';
import { useDeliveredDocumentPermission } from '../../../document/document-permission';

export function Content() {
  const { source } = useContext(DetailContext);
  const { attachments, summary } = useFormSelector((data: Document) => ({
    attachments: data.attachments ?? [],
    summary: data.summary ?? '',
  }));

  const mainAttachments = attachments.filter(
    (a) => a.type === AttachmentType.MAIN
  );

  const lustrumAttachments = attachments.filter(
    (a) => a.type === AttachmentType.LUSTRUM
  );

  const powerOfAttorneyAttachments = attachments.filter(
    (a) => a.type === AttachmentType.POWER_OF_ATTORNEY
  );

  const challengedDecisionAttachments = attachments.filter(
    (a) => a.type === AttachmentType.CHALLENGED_DECISION
  );

  const otherAttachments = attachments.filter(
    (a) =>
      a.type &&
      [
        AttachmentType.OTHER,
        AttachmentType.DELIVERY_NOTE,
        AttachmentType.ENVELOPE,
      ].includes(a.type)
  );

  const { hasPermission } = useDeliveredDocumentPermission(source.data);

  return (
    <>
      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DELIVERED__DETAIL__PANEL_TITLE__MAIN"
            defaultMessage="Hlavní příloha"
          />
        }
        attachments={mainAttachments}
        attachmentType={AttachmentType.MAIN}
        defaultName={summary}
        hasPermission={hasPermission}
      />

      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DELIVERED__DETAIL__PANEL_TITLE__LUSTRUM"
            defaultMessage="Lustrum"
          />
        }
        attachments={lustrumAttachments}
        attachmentType={AttachmentType.LUSTRUM}
        defaultName={summary}
        showAddButton={false}
        showEditButton={false}
        showRevalidateButton={false}
        showDownloadReportButton={false}
        hasPermission={hasPermission}
      />

      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DELIVERED__DETAIL__PANEL_TITLE__POWER_OF_ATTORNEY"
            defaultMessage="Plná moc"
          />
        }
        attachments={powerOfAttorneyAttachments}
        attachmentType={AttachmentType.POWER_OF_ATTORNEY}
        defaultName={summary}
        hasPermission={hasPermission}
      />

      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DELIVERED__DETAIL__PANEL_TITLE__CHALLENGED_DECISION"
            defaultMessage="Napadená rozhodnutí"
          />
        }
        attachments={challengedDecisionAttachments}
        attachmentType={AttachmentType.CHALLENGED_DECISION}
        defaultName={summary}
        hasPermission={hasPermission}
      />

      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DELIVERED__DETAIL__PANEL_TITLE__OTHER"
            defaultMessage="Další přílohy"
          />
        }
        attachments={otherAttachments}
        attachmentType={AttachmentType.OTHER}
        defaultName={summary}
        hasPermission={hasPermission}
        disableEditButton={(a) =>
          a.type === AttachmentType.DELIVERY_NOTE ||
          a.type === AttachmentType.ENVELOPE
        }
      />
    </>
  );
}
