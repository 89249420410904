import React, { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useReactToPrint } from 'react-to-print';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  DetailToolbarButton2,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Decision } from '@models';
import {
  createContent,
  createDecisionData,
  createQuote,
} from '../decision-utils';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 100px',
  },
  quote: {
    whiteSpace: 'pre-wrap',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  popularName: {
    whiteSpace: 'pre-wrap',
    marginBotton: '2rem',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginBottom: '2rem',
  },
  content: {
    whiteSpace: 'pre-wrap',
    textAlign: 'justify',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  labeledValue: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    fontWeight: 400,

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${grey[300]}`,
    },
  },
  label: {
    flexBasis: '40%',
    color: grey[600],
  },
  value: {
    flexBasis: '60%',
  },
  block: {
    border: `1px solid ${grey[300]}`,
    padding: '1rem',
    width: '100%',
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginBottom: '1rem',
  },
});

export function usePrintDialog() {
  const intl = useIntl();
  const classes = useStyles();

  const { decisionForms } = useContext(StaticDataContext);
  const { source, mode } = useContext<DetailHandle<Decision>>(DetailContext);
  const contentRef = useRef<HTMLDivElement>(null);

  const quote = createQuote(decisionForms, source.data);
  const content = createContent(source.data);
  const showButton = mode === DetailMode.VIEW;

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  const Content = () => (
    <div style={{ display: 'none' }}>
      <div ref={contentRef} className={classes.wrapper}>
        <style>{'@page { size: A4 portrait; margin: 75px 0; }'}</style>
        <Typography className={classes.quote}>{quote}</Typography>
        <Typography className={classes.popularName}>
          {source?.data?.popularName}
        </Typography>
        {source?.data && <Card decision={source.data} />}
        <Typography className={classes.content}>{content}</Typography>
      </div>
    </div>
  );

  const PrintButton = () => (
    <>
      <DetailToolbarButton2
        label={intl.formatMessage({
          id: 'ES__DECISION__ACTIONBAR__PRINT',
          defaultMessage: 'Tisk',
        })}
        startIcon={<PrintIcon />}
        onClick={handlePrint}
      />
      <Content />
    </>
  );

  return { showButton, PrintButton };
}

export default function Card({ decision }: { decision: Decision }) {
  const classes = useStyles();
  const { decisionForms } = useContext(StaticDataContext);

  return (
    <div className={classes.block}>
      <Typography className={classes.cardTitle}>Karta záznamu</Typography>
      <div>
        {Array.from(createDecisionData(decisionForms, decision)).map(
          ([label, value]) => (
            <LabeledValue key={label} label={label} value={value} />
          )
        )}
      </div>
    </div>
  );
}

function LabeledValue({
  label,
  value,
}: {
  label: string;
  value: string | string[];
}) {
  const classes = useStyles();

  return (
    <div className={classes.labeledValue}>
      <label className={classes.label}>{label}</label>
      <div className={classes.value}>
        {Array.isArray(value) ? (
          value.map((item, index) => (
            <label key={index} className="text-gray-800">
              - {item}
            </label>
          ))
        ) : (
          <label className="break-words text-gray-800">{value}</label>
        )}
      </div>
    </div>
  );
}
