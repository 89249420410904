import React from 'react';
import { DictionaryEvidence } from '@eas/common-web';

export function Fields() {
  return (
    <>
      <DictionaryEvidence.FieldName />
      <DictionaryEvidence.FieldCode />
      <DictionaryEvidence.FieldOrder />
      <DictionaryEvidence.FieldActive />
    </>
  );
}
