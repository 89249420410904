import * as Yup from 'yup';
import { DictBriefSummary } from '@models';
import { Agenda } from '@enums';

export function useValidationSchema() {
  return Yup.object<DictBriefSummary>().shape({
    name: Yup.string().nullable().required(),
    agenda: Yup.mixed<Agenda>().nullable().required(),
  });
}
