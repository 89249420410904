import {
  DictionaryObject,
  Params,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { DictionaryAutocomplete, UserRole } from '@models';
import { EvidenceApiUrl } from '@enums';
import { Agenda } from '@enums';

export function useRoles() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.ROLES}/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}

export function useUserRoles(params?: Params, apiUrl?: string) {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceApiUrl.USERS}/role/autocomplete`,
    params,
    apiUrl,
  });
}

/**
 * List all available defaultable agendas.
 */
export function useDefaultableAgendas() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.USER_ROLE}/default/allowed-agendas/list`,
    { method: 'GET', cacheResponse: true }
  );
}

export function useDefaultIndexRole(agenda: Agenda) {
  try {
    const response = useFetch<UserRole>(
      `${EvidenceApiUrl.USER_ROLE}/default?forAgenda=${agenda}`,
      { method: 'GET' }
    );

    return response;
  } catch (err) {
    console.log(err);

    return [null];
  }
}
