import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useColumns as useAllColumns } from '@modules/decision/decision-columns';
import { useDecisions } from '@modules/decision/decisions';
import { Search } from '@composite/search/search';
import { useColumns } from './decsion-search-columns';

export function DecisionSearch() {
  const columns = useColumns();
  const allColumns = useAllColumns().map((col) => {
    col.filterable = false;
    return col;
  });
  const intl = useIntl();

  const { props } = useDecisions({
    evidenceProps: {
      tableProps: {
        tableName: intl.formatMessage({
          id: 'ES__DECISIONS_SEARCH__TABLE__TITLE',
          defaultMessage: 'Rozhodnutí - hledání',
        }),
      },
    },
  });

  return (
    <Search
      apiProps={props.apiProps}
      detailProps={props.detailProps}
      tableProps={{
        ...props.tableProps,
        columns: [...columns, ...allColumns],
        settingsKey: 'DECISION_SEARCH',
      }}
      filterProps={{
        title: (
          <FormattedMessage
            id="ES__DECISION_SEARCH__TOOLBAR__TITLE"
            defaultMessage="Vyhledávání"
          />
        ),
        initVisibility: [
          'text.introduction',
          'text.statement',
          'text.narration',
          'form.id',
          'esFile.number.value',
          'form.id',
          'decisionDate.from',
          'esFile.anonymizationRequest.id',
          'settlementMethods.id',
          'esFile.judge.id',
          'esFile.proposers.contact.subject.name',
        ],
        defaults: [],
        groupLabels: {
          1: intl.formatMessage({
            id: 'ES__DECISION_SEARCH__LABEL_GROUP_2',
            defaultMessage: 'Obecné',
          }),
          2: intl.formatMessage({
            id: 'ES__DECISION_SEARCH__LABEL_GROUP_1',
            defaultMessage: 'Text',
          }),
          3: intl.formatMessage({
            id: 'ES__DECISION_SEARCH__LABEL_GROUP_2',
            defaultMessage: 'Spis',
          }),
        },
      }}
    />
  );
}
