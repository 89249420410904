import { useMemo } from 'react';
import { TableFieldCells, TableFieldColumn } from '@eas/common-web';
import { useActTypes } from '@modules/dict-act-type/dict-act-type-api';
import { useConcernedAuthorityTypes } from '@modules/dict-concerned-authority-type/dict-concerned-authority-type-api';
import { useOpponents } from '@modules/dict-opponent/dict-opponent-api';
import { ChallengedAct } from '@models';

export function useChallengedActColumns(): TableFieldColumn<ChallengedAct>[] {
  const columns: TableFieldColumn<ChallengedAct>[] = [
    {
      name: 'Druh aktu',
      datakey: 'actType',
      width: 300,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useActTypes,
        multiple: false,
      }),
    },
    {
      name: 'Typ dotčeného orgánu',
      datakey: 'concernedAuthorityType',
      width: 300,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useConcernedAuthorityTypes,
        multiple: false,
      }),
    },
    {
      name: 'Název dotčeného orgánu',
      datakey: 'concernedAuthority',
      width: 300,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useOpponents,
        multiple: false,
      }),
    },
    {
      name: 'Spisová značka / číslo aktu',
      datakey: 'externalFileNumber',
      width: 200,
    },
    {
      name: 'Název aktu',
      datakey: 'actName',
      width: 200,
    },
    {
      name: 'Napadená ustanovení',
      datakey: 'challengedProvisions',
      width: 200,
    },
    {
      name: 'Prvoinstanční',
      datakey: 'firstInstance',
      width: 200,
      CellComponent: TableFieldCells.BooleanCell,
    },
  ];

  return useMemo(() => columns, []);
}
