import { Role, UIElement } from '@enums';

export const outgoingDocumentAccessMap = new Map([
  [
    Role.INDEX,
    [
      UIElement.OutgoingDocument.SUMMARY,
      UIElement.OutgoingDocument.NOTE,
      UIElement.OutgoingDocument.PAGE_COUNT,
      UIElement.OutgoingDocument.DECISION,
      UIElement.OutgoingDocument.DECISION_FORM,
      UIElement.OutgoingDocument.DEADLINES,
      UIElement.Attachment.ADD_ATTACHMENT,
      UIElement.Attachment.EDIT_ATTACHMENT,
      UIElement.Attachment.DELETE_ATTACHMENT,
      UIElement.Attachment.COPY_ATTACHMENT,
      UIElement.Attachment.COPY_DRAFT,
      UIElement.Attachment.SIGN_ATTACHMENT,
      UIElement.Attachment.CONVERT_TO_PDF,
      UIElement.Dispatch.ADD_DISPATCH,
      UIElement.Dispatch.EDIT_DISPATCH,
      UIElement.Dispatch.DELETE_DISPATCH,
      UIElement.Dispatch.GENERATE_ENVELOPES,
      UIElement.OutgoingDocument.FORWARD_TO_SEND,
      UIElement.OutgoingDocument.RETURN_SEND,
      UIElement.OutgoingDocument.FORWARD_TO_DISPATCH,
      UIElement.OutgoingDocument.EDIT_DOCUMENT_BUTTON,
      UIElement.OutgoingDocument.CANCEL_BUTTON,
      UIElement.OutgoingDocument.REOPEN_OUTGOING_DOCUMENT_BUTTON,
    ],
  ],
  [Role.LUSTRUM, [UIElement.OutgoingDocument.REOPEN_OUTGOING_DOCUMENT_BUTTON]],
  [
    Role.SECRETARY,
    [
      UIElement.OutgoingDocument.SUMMARY,
      UIElement.OutgoingDocument.NOTE,
      UIElement.OutgoingDocument.PAGE_COUNT,
      UIElement.OutgoingDocument.DECISION,
      UIElement.OutgoingDocument.DECISION_FORM,
      UIElement.OutgoingDocument.DEADLINES,
      UIElement.Attachment.ADD_ATTACHMENT,
      UIElement.Attachment.EDIT_ATTACHMENT,
      UIElement.Attachment.DELETE_ATTACHMENT,
      UIElement.Attachment.COPY_ATTACHMENT,
      UIElement.Attachment.COPY_DRAFT,
      UIElement.Attachment.SIGN_ATTACHMENT,
      UIElement.Attachment.CONVERT_TO_PDF,
      UIElement.Dispatch.ADD_DISPATCH,
      UIElement.Dispatch.EDIT_DISPATCH,
      UIElement.Dispatch.DELETE_DISPATCH,
      UIElement.Dispatch.GENERATE_ENVELOPES,
      UIElement.OutgoingDocument.FORWARD_TO_SEND,
      UIElement.OutgoingDocument.RETURN_SEND,
      UIElement.OutgoingDocument.EDIT_DOCUMENT_BUTTON,
      UIElement.OutgoingDocument.CANCEL_BUTTON,
    ],
  ],
  [
    Role.SOLVER,
    [
      UIElement.OutgoingDocument.SUMMARY,
      UIElement.OutgoingDocument.NOTE,
      UIElement.OutgoingDocument.PAGE_COUNT,
      UIElement.OutgoingDocument.EDIT_DOCUMENT_BUTTON,
      UIElement.Attachment.ADD_ATTACHMENT,
      UIElement.Attachment.COPY_ATTACHMENT,
      UIElement.Attachment.COPY_DRAFT,
      UIElement.Attachment.CONVERT_TO_PDF,
      UIElement.Attachment.SIGN_ATTACHMENT,
      UIElement.Attachment.DELETE_ATTACHMENT,
      UIElement.Attachment.EDIT_ATTACHMENT,
      UIElement.Dispatch.ADD_DISPATCH,
      UIElement.Dispatch.EDIT_DISPATCH,
      UIElement.Dispatch.GENERATE_ENVELOPES,
      UIElement.OutgoingDocument.FORWARD_TO_SEND,
      UIElement.OutgoingDocument.RETURN_SEND,
    ],
  ],
]);
