import React from 'react';
import { useIntl } from 'react-intl';
import { PrimaryDetailActionbarButton } from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useDispatchSingleDocumentDialog } from './dialog-actions/dispatch-document-hook';
import { useReturnSingleDocumentDialog } from './dialog-actions/return-document-hook';

export function ActionBar() {
  const intl = useIntl();

  const {
    callApi: dispatchDocumentApiCall,
    showButton: showDispatchDocumentButton,
  } = useDispatchSingleDocumentDialog();

  const {
    callApi: returnDocumentApiCall,
    showButton: showReturnDocumentButton,
  } = useReturnSingleDocumentDialog();

  return (
    <>
      {showDispatchDocumentButton && (
        <DetailActionButton
          promptKey="DISPATCH_SINGLE_DOCUMENT"
          apiCall={dispatchDocumentApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__ACTIONBAR__DISPATCH_SINGLE_DOCUMENT',
            defaultMessage: 'Vypravit',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DIALOG_DISPATCH_SINGLE_DOCUMENT__TITLE',
            defaultMessage: 'Vypravení dokumentu',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DIALOG_DISPATCH_SINGLE_DOCUMENT__TEXT',
            defaultMessage: 'Opravdu chcete vypravit dokument?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
        />
      )}
      {showReturnDocumentButton && (
        <DetailActionButton
          promptKey="RETURN_SINGLE_DOCUMENT"
          apiCall={returnDocumentApiCall}
          buttonLabel={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__ACTIONBAR__RETURN_SINGLE_DOCUMENT',
            defaultMessage: 'Vrátit',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DIALOG_RETURN_SINGLE_DOCUMENT__TITLE',
            defaultMessage: 'Vrácení vypravení',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DIALOG_RETURN_SINGLE_DOCUMENT__TEXT',
            defaultMessage: 'Opravdu chcete vrátit vypravení?',
          })}
          ButtonComponent={PrimaryDetailActionbarButton}
        />
      )}
    </>
  );
}
