import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useDeleteAssignedFileNumberDialog() {
  const callApi = useEventCallback((id: string) => {
    return abortableFetch(`${EvidenceApiUrl.ASSIGNED_FILE_NUMBERS}/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  });

  return {
    callApi,
  };
}
