import { noop, stubFalse } from 'lodash';
import React from 'react';
import { green, orange, red } from '@material-ui/core/colors';
import { FormPanel, TableField, TableFieldCells } from '@eas/common-web';
import { CollectionHistoryField, CollectionHistoryFieldChange } from '@models';
import { useHistoryCollectionOperation } from '../history-api';
import { HistoryTableFieldRow } from '../history-table-field-row';

export function CollectionField({ changes }: CollectionHistoryField) {
  return (
    <FormPanel
      label="Změněné atributy"
      defaultExpanded={true}
      expandable={true}
    >
      <TableField
        value={changes}
        showToolbar={false}
        maxRows={15}
        createRowStyle={createRowStyle}
        RowComponent={HistoryTableFieldRow}
        columns={[
          {
            name: '',
            datakey: 'id',
            width: 30,
          },
          {
            name: 'Popis',
            datakey: 'label',
            width: 700,
          },
          {
            name: 'Akce',
            datakey: 'operation',
            width: 200,
            CellComponent: TableFieldCells.useSelectCellFactory(
              useHistoryCollectionOperation
            ),
          },
        ]}
        onChange={noop}
        showRadioCond={stubFalse}
      />
    </FormPanel>
  );
}

const createRowStyle = (item: CollectionHistoryFieldChange) => {
  return item?.operation === 'ADD'
    ? {
        color: green[500],
        backgroundColor: green[50],
      }
    : item?.operation === 'REMOVE'
    ? {
        color: red[500],
        backgroundColor: red[50],
      }
    : { color: orange[500], backgroundColor: orange[50] };
};
