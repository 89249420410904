import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 15,
  },
});

export function Delimeter() {
  const classes = useStyles();

  return <div className={classes.root} />;
}
