import {
  TreeOptions,
  useAutocompleteSource,
  useTreeSource,
} from '@eas/common-web';
import { DictSubjectIndex, TreeObject } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useSubjectIndexes() {
  return useAutocompleteSource<DictSubjectIndex>({
    url: `${EvidenceApiUrl.DICT_SUBJECT_INDEX}/autocomplete`,
  });
}

export function useSubjectIndexesTree(
  options: Partial<TreeOptions<TreeObject>> = {}
) {
  return useTreeSource({
    ...options,
    url: EvidenceApiUrl.DICT_SUBJECT_INDEX,
    getKey: (item) => item.id,
  });
}
