import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { TableFilter, TableFilterState } from '@eas/common-web';
import { FilterState } from './filter-types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    marginLeft: 16,
    marginTop: 10,
    flex: '1 1 250px',
  },
  labelWrapper: {
    width: '100%',
  },
  fieldWrapper: {
    width: '100%',
  },
});

interface TableFilterDialogItemProps {
  /**
   * Filter.
   */
  filter: TableFilter;

  /**
   * Filter state.
   */
  state: FilterState;

  /**
   * OnChange handler for filter value.
   */
  onChangeValue: (value: any) => void;

  /**
   * OnChange handler for complex filter value.
   */
  onChangeFilterState: (state: FilterState) => void;

  /**
   * Visibility state
   */
  visible: boolean;
}

export function FilterComponent({
  filter,
  state,
  onChangeValue,
  onChangeFilterState,
  visible,
}: TableFilterDialogItemProps) {
  const classes = useStyles();
  const { FilterComponent } = filter;

  if (!visible) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapper}>
        <Typography variant="body1">{filter.label}</Typography>
      </div>
      <div className={classes.fieldWrapper}>
        <FilterComponent
          filter={filter}
          disabled={false}
          state={state as TableFilterState}
          value={state.value}
          onChange={onChangeValue}
          onChangeState={onChangeFilterState}
        />
      </div>
    </div>
  );
}
