import { abortableFetch, useEventCallback } from '@eas/common-web';
import { Writer } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useEditWriterDialog() {
  const callApi = useEventCallback(
    (id: string, writerId: string, formData: Writer) => {
      return abortableFetch(
        `${EvidenceApiUrl.ES_FILES}/${id}/participant/${writerId}`,
        {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(formData),
        }
      );
    }
  );

  return {
    callApi,
  };
}
