import { merge } from 'lodash';
import { useIntl } from 'react-intl';
import { EmptyComponent, EvidenceProps } from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { SaveButton } from '@composite/settlement-method/save-button';
import {
  createLockedFileStyle,
  isFileLocked,
} from '@components/evidence/utils/is-locked';
import { EsFile } from '@models';
import { EntityName, EvidenceApiUrl, ExportTags } from '@enums';
import { ActionBar } from './es-file-actionbar';
import { useColumns } from './es-file-columns';
import { Fields } from './es-file-fields';
import { useEsFilesHookedSources } from './es-file-hooked-sources';
import { useValidationSchema } from './es-file-schema';
import { RemoveButton } from './es-file-toolbar';
import { disableBtnFactory } from './es-file-utils';

export const ES_FILES_IDENTIFIER = 'ES_FILES';
export const ES_FILES_VERSION = 10;

export function useEsFiles({
  evidenceProps,
}: {
  evidenceProps?: Partial<EvidenceProps<EsFile>>;
}) {
  const intl = useIntl();

  const defaultColumns = useColumns();
  const columns = evidenceProps?.tableProps?.columns ?? defaultColumns;

  const validationSchema = useValidationSchema();

  const props = merge(
    {
      version: ES_FILES_VERSION,
      identifier: ES_FILES_IDENTIFIER,
      apiProps: {
        url: EvidenceApiUrl.ES_FILES,
        hookedSources: useEsFilesHookedSources,
      },
      tableProps: {
        columns,
        tableName: intl.formatMessage({
          id: 'ES__ES_FILES__TABLE__TITLE',
          defaultMessage: 'Spisy',
        }),
        reportTag: ExportTags.ES_FILES,
        defaultSorts: [],
        createRowStyle: createLockedFileStyle,
        showBulkActionButton: false,
        showNamedSettingsButton: false,
      },
      detailProps: {
        entityName: EntityName.ES_FILE,
        FieldsComponent: Fields,
        GeneralFieldsComponent: EmptyComponent,
        lock: isFileLocked,
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ES__ES_FILES__DETAIL__TITLE',
            defaultMessage: 'Spis',
          }),
          subtitle: (value: EsFile) => value?.number?.value ?? '',
          disableBtn: disableBtnFactory(),
          SaveButton,
          RemoveButton,
          ActionBar,
        },
        validationSchema,
      },
    },
    evidenceProps
  );

  const evidenceBag = useAuthoredEvidence<EsFile>(props);

  return { evidenceBag, props };
}
