export const OUTGOING_DOCUMENTS = {
  title: 'Odchozí dokumenty',
  actions: [
    {
      name: 'Upravení dokumentu',
      description:
        'Pouze u dokumentů, které jsou vázány na neuzavřený spis. Uživatel může upravovat jednotlivé atributy dokumenty.',
    },
    { name: 'Nová úloha', description: 'Vytvoření nové uživatelské úlohy.' },
    {
      name: 'Předat k odeslání',
      description:
        'Akce je dostupná u dokumentů, které mají nějaké vypravení ve stavu <b>Připraveno</b>.',
    },
    {
      name: 'Předat k vypravení',
      description:
        'Akce je dostupná u dokumentů, které mají vypravení ve stavu <b>Neodesláno</b>.',
    },
  ],
};
