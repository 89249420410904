import {
  AlignmentType,
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import { useContext } from 'react';
import { createDecisionData } from '@modules/decision/decision-utils';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Decision } from '@models';

export interface DocxProps {
  /**
   * Citation of the decision
   */
  quote: string;

  /**
   * Decision object
   */
  decision: Decision;

  /**
   * Text of the decision
   */
  content: string;
}

function createRow(label: string, value: string) {
  return new TableRow({
    children: [
      new TableCell({
        children: [new Paragraph(label)],
      }),
      new TableCell({
        children: [new Paragraph(value)],
      }),
    ],
  });
}

export function useCreateDocx({ quote, decision, content }: DocxProps) {
  const { decisionForms } = useContext(StaticDataContext);

  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: quote,
                bold: true,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: decision?.popularName,
                bold: true,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: '',
                break: 2,
              }),
            ],
          }),
          new Table({
            width: {
              size: '100%',
              type: WidthType.PERCENTAGE,
            },
            rows: [
              ...Array.from(createDecisionData(decisionForms, decision)).map(
                ([label, value]) =>
                  createRow(
                    label,
                    typeof value === 'string' ? value : value.join(', ')
                  )
              ),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: '',
                break: 2,
              }),
            ],
          }),
          ...content.split('\n').map((line) => {
            return new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: line,
                }),
              ],
            });
          }),
        ],
      },
    ],
  });

  return doc;
}
