import React from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryObject,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { useContactColumns } from '@modules/subject/subject-columns';
import { Dispatch, Recipient } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { Redirect } from '../redirect/redirect';

export function useDispatchColumns({
  dispatchMethods,
  dispatchStates,
  showRedirectInCurrent = true,
  preColumns = [],
  postColumns = [],
}: {
  dispatchMethods: DictionaryObject[];
  dispatchStates: DictionaryObject[];
  showRedirectInCurrent?: boolean;
  preColumns?: TableFieldColumn<Dispatch>[];
  postColumns?: TableFieldColumn<Dispatch>[];
}): TableFieldColumn<Dispatch>[] {
  const intl = useIntl();

  const useMethods = () => useStaticListSource(dispatchMethods);
  const useStates = () => useStaticListSource(dispatchStates);

  const { columnEmail } = useContactColumns({
    datakeyPrefix: 'recipient',
    labelPrefix: 'Adresát',
  });

  return [
    {
      datakey: 'id',
      name: '',
      width: 90,
      CellComponent: function Cell(props: TableFieldCellProps<Dispatch>) {
        return (
          <Redirect
            url={EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ALL}
            id={props.value}
            showRedirectInCurrent={showRedirectInCurrent}
          />
        );
      },
    },
    ...preColumns,
    {
      name: intl.formatMessage({
        id: 'ES__DISPATCHES__COLUMN__RECIPIENT',
        defaultMessage: 'Adresát',
      }),
      datakey: 'recipient',
      sortkey: 'recipient.contact.subject.name',
      width: 300,
      CellComponent: (props) => {
        const recipient = props.value as Recipient;

        return (
          <TableFieldCells.TextCell
            {...props}
            value={`${recipient.contact?.subject?.name}; ${recipient.address}`}
          />
        );
      },
    },
    {
      name: intl.formatMessage({
        id: 'ES__DISPATCHES__COLUMN__DATA_BOX_ID',
        defaultMessage: 'Adresát - ID datové schránky',
      }),
      datakey: 'recipient.dataBoxId',
      sortkey: 'recipient.dataBoxId',
      width: 200,
    },
    columnEmail,
    {
      name: intl.formatMessage({
        id: 'ES__DISPATCHES__COLUMN__METHOD',
        defaultMessage: 'Způsob vypravení',
      }),
      datakey: 'method',
      sortkey: 'method.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useMethods),
    },
    {
      name: intl.formatMessage({
        id: 'ES__DISPATCHES__COLUMN__STATE',
        defaultMessage: 'Stav vypravení',
      }),
      datakey: 'state',
      sortkey: 'state.name',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useStates),
    },
    {
      name: intl.formatMessage({
        id: 'ES__DISPATCHES__COLUMN__DISPATCH_TIME',
        defaultMessage: 'Datum vypravení',
      }),
      datakey: 'dispatchTime',
      sortkey: 'dispatchTime',
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DISPATCHES__COLUMN__DELIVERY_TIME',
        defaultMessage: 'Datum doručení',
      }),
      datakey: 'deliveryTime',
      sortkey: 'deliveryTime',
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DISPATCHES__COLUMN__JID',
        defaultMessage: 'JID',
      }),
      datakey: 'jid',
      sortkey: 'jid',
      width: 100,
    },
    ...postColumns,
  ];
}
