import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {
  ApiFilterOperation,
  TableCells,
  TableColumn,
  TableContext,
  TableFilterCells,
  Tooltip,
} from '@eas/common-web';
import { useSenates } from '@modules/dict-senate/dict-senate-api';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
} from '@components/form/misc/label-mappers';
import { AssigningRule } from '@models';
import { useJudges } from '../judge/judge-api';

export function useColumns(): TableColumn<AssigningRule>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'judge',
      filterkey: 'judge.id',
      sortkey: 'judge.name',
      name: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useJudges),
      valueMapper: autocompleteColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'valid',
      name: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__COLUMN__VALID',
        defaultMessage: 'Aktivní',
      }),
      width: 150,
      CellComponent: TableCells.BooleanCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'judge.senates',
      filterkey: 'judge.senates.id',
      sortkey: 'judge.senates.name',
      name: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__COLUMN__SENATE_NUMBER',
        defaultMessage: 'Číslo senátu',
      }),
      width: 200,
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSenates,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'validFrom',
      name: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__COLUMN__VALID_FROM',
        defaultMessage: 'Platnost od',
      }),
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'validTo',
      name: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__COLUMN__VALID_TO',
        defaultMessage: 'Platnost do',
      }),
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'stopSenate',
      name: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__COLUMN__STOP_SENATE',
        defaultMessage: 'Pozastavit senátní',
      }),
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'stopPlenary',
      name: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__COLUMN__STOP_PLENARY',
        defaultMessage: 'Pozastavit plenární',
      }),
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },

    {
      datakey: 'otherRules',
      name: intl.formatMessage({
        id: 'ES__ASSIGNING_RULES__TABLE__COLUMN__OTHER_RULES',
        defaultMessage: 'Ostatní pravidla',
      }),
      width: 200,
      CellComponent: function Cell({ value }) {
        const { classes } = useContext(TableContext);

        return (
          <Tooltip title={value} placement="top-start" type="HOVER">
            <Typography className={classes.tableCell}>{value}</Typography>
          </Tooltip>
        );
      },
    },
  ];
}
