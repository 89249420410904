import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

/**
 * List all available decision forms
 */
export function useDecisionForms() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.DECISIONS}/form/list`, {
    method: 'GET',
  });
}

/**
 * List all available decision states
 */
export function useDecisionStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DECISIONS}/state/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available publish in collection states
 */
export function usePublishInCollection() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DECISIONS}/publish-in-collection/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
