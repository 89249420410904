import React, { useContext } from 'react';
import {
  InternalSettings,
  InternalSettingsColumnCells,
  InternalSettingsFieldComponents,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { useUserRoles } from '@modules/role/role-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { EvidenceApiUrl, ParticipantType, Role } from '@enums';

export function GlobalSettings() {
  const { participantTypes } = useContext(StaticDataContext);

  const useAnalysts = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.ANALYST }));

  const useIndexes = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.INDEX }));

  const useParticipantTypes = () =>
    useStaticListSource(
      participantTypes.filter((type) => type.id !== ParticipantType.WRITER)
    );

  return (
    <InternalSettings
      config={[
        {
          code: 'NASPIS_PARTICIPANT_TYPE',
          ColumnCell: InternalSettingsColumnCells.SelectCell({
            dataHook: useParticipantTypes,
            valueIsId: true,
            multiple: true,
          }),
          FieldComponent: InternalSettingsFieldComponents.useSelect({
            dataHook: useParticipantTypes,
            multiple: true,
          }),
        },
        {
          code: 'PLENARY_INDEX_HEAD',
          ColumnCell: InternalSettingsColumnCells.AutocompleteCell({
            dataHook: useIndexes,
            labelMapper: autocompleteLabelMapper,
          }),
          FieldComponent: InternalSettingsFieldComponents.useAutocomplete({
            dataHook: useIndexes,
            labelMapper: autocompleteLabelMapper,
          }),
          autocompleteUrl: `${EvidenceApiUrl.USERS}/role/autocomplete`,
        },
        {
          code: 'PLENARY_ANALYST',
          ColumnCell: InternalSettingsColumnCells.AutocompleteCell({
            dataHook: useAnalysts,
            labelMapper: autocompleteLabelMapper,
          }),
          FieldComponent: InternalSettingsFieldComponents.useAutocomplete({
            dataHook: useAnalysts,
            labelMapper: autocompleteLabelMapper,
          }),
          autocompleteUrl: `${EvidenceApiUrl.USERS}/role/autocomplete`,
        },
      ]}
    />
  );
}
