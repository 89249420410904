import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  Tooltip,
  useStaticListSource,
} from '@eas/common-web';
import { useStyles } from '@modules/es-file/es-file-columns';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  ChallengedAct,
  DictionaryObject,
  EsFile,
  EsFileUnion,
  UserRole,
} from '@models';
import { EvidenceBrowserUrl } from '@enums';

export function useSimilarFileColumns(): TableFieldColumn<EsFile>[] {
  const intl = useIntl();
  const classes = useStyles();

  const { states } = useContext(StaticDataContext);
  const useStates = () =>
    useStaticListSource(states.map(({ id, name }) => ({ id, name })));

  const StateCell = TableFieldCells.useSelectCellFactory<
    EsFile,
    DictionaryObject
  >(useStates);

  return useMemo(
    () => [
      {
        datakey: 'id',
        name: '',
        width: 50,
        CellComponent: function Cell(props: TableFieldCellProps<EsFile>) {
          return (
            <Redirect
              url={EvidenceBrowserUrl.ES_FILES_ALL}
              id={props.value}
              showRedirectInCurrent={false}
            />
          );
        },
      },
      {
        name: intl.formatMessage({
          id: 'ES__SIMILAR_FILES__COLUMN__DELIVERY_DATE',
          defaultMessage: 'Datum doručení',
        }),
        datakey: 'deliveryDate',
        sortkey: 'deliveryDate',
        width: 150,
        CellComponent: TableFieldCells.DateCell,
      },
      {
        name: intl.formatMessage({
          id: 'ES__SIMILAR_FILES__COLUMN__FILE_NUMBER',
          defaultMessage: 'Spisová značka',
        }),
        datakey: 'number.value',
        sortkey: 'number.valueSort',
        width: 150,
      },
      {
        name: intl.formatMessage({
          id: 'ES__SIMILAR_FILES__COLUMN__STATE',
          defaultMessage: 'Stav',
        }),
        datakey: 'state',
        sortkey: 'state.name',
        width: 200,
        CellComponent: StateCell,
      },
      {
        name: intl.formatMessage({
          id: 'ES__SIMILAR_FILES__COLUMN__PARTICIPANT',
          defaultMessage: 'Navrhovatel / pisatel',
        }),
        datakey: 'participants',
        sortkey: 'participants.label',
        width: 300,
        CellComponent: function Cell(props) {
          const rowValue = props.rowValue as EsFile;
          let value = '';

          value = (rowValue.participants ?? [])
            .map((proposer) => proposer.label)
            ?.join('; ');

          return <TableFieldCells.TextCell {...props} value={value} />;
        },
      },
      {
        name: intl.formatMessage({
          id: 'ES__SIMILAR_FILES__COLUMN__JUDGE',
          defaultMessage: 'Soudce zpravodaj / Řešitel',
        }),
        datakey: 'judgeSolversMerged',
        sortkey: 'judgeSolversMerged.concatenated',
        width: 300,
        CellComponent: function Cell(props) {
          const rowValue = props.rowValue as EsFileUnion;
          let value = '';

          if ('judge' in rowValue) {
            value = rowValue.judge?.label ?? '';
          } else if ('solverUserRoles' in rowValue) {
            value =
              rowValue.solverUserRoles
                ?.map((solver: UserRole) => solver.label)
                .join(', ') ?? '';
          }

          return <TableFieldCells.TextCell {...props} value={value} />;
        },
      },
      {
        name: intl.formatMessage({
          id: 'ES__SIMILAR_FILES__COLUMN__CHALLENGED_ACTS',
          defaultMessage: 'Odpůrce',
        }),
        datakey: 'challengedActs',
        sortkey: 'challengedActs.label',
        width: 300,
        CellComponent: function Cell(props) {
          return (
            <Tooltip
              placement="top-start"
              type="HOVER"
              title={
                <div className={classes.tooltip}>
                  {(props.value ?? []).map((act: ChallengedAct) => (
                    <span key={act.id}>{`- ${act.label}`}</span>
                  ))}
                </div>
              }
            >
              <Typography className={classes.tableCell}>
                {props.value
                  ?.map((act: ChallengedAct) => act.label)
                  ?.join(', ')}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    [StateCell]
  );
}
