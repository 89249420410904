export function useSettlementMethodCountsColumns() {
  return [
    {
      name: 'Způsob vyřízení',
      datakey: 'settlementMethod',
      width: 400,
    },
    {
      name: 'Senátní',
      datakey: 'countSenate',
      width: 125,
    },
    {
      name: 'Plenární',
      datakey: 'countPlenary',
      width: 125,
    },
    {
      name: 'Celkem',
      datakey: 'countTotal',
      width: 125,
    },
  ];
}
