import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { WriterField } from '@composite/writers/writer-field';
import { EsFile } from '@models';
import { UIElement } from '@enums';
import { WRITER_SOURCE } from '../../../es-file/es-file-hooked-sources';
import { useSprFilePermission } from '../../../es-file/es-file-permission';

export function WritersFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } = useContext<DetailHandle<EsFile>>(
    DetailContext
  );
  const { hasPermission } = useSprFilePermission(source.data);

  const disabledWriters =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.SprFile.WRITERS);
  const writerSource = hookedSources[WRITER_SOURCE]?.source;

  return (
    <>
      <WriterField disabled={disabledWriters} source={writerSource} />
    </>
  );
}
