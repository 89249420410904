import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    { key: 'esFile', label: 'Spis' },
    { key: 'letter', label: 'Písmeno' },
    { key: 'number', label: 'Číslo' },
    { key: 'order', label: 'Index' },
    { key: 'paragraph', label: 'Odstavec' },
    { key: 'sectionSign', label: 'Paragraf' },
    { key: 'regulationType', label: 'Typ předpisu' },
    { key: 'type', label: 'Typ' },
    { key: 'year', label: 'Rok' },
  ];
}
