/**
 * Typ intervalu
 */
export enum IntervalType {
  /**
   * Rozsah (od-do)
   */
  RANGE = 'RANGE',

  /**
   * Konkrétní rok
   */
  YEAR = 'YEAR',

  /**
   * Vše od prvního podání
   */
  ALL = 'ALL',
}

export enum Statistic {
  ASSIGNED_AND_RESOLVED_PLENARY_COUNTS = 'ASSIGNED_AND_RESOLVED_PLENARY_COUNTS',
  ASSIGNED_AND_RESOLVED_SENATE_COUNTS = 'ASSIGNED_AND_RESOLVED_SENATE_COUNTS',
  AVG_PROCEEDING_DURATION = 'AVG_PROCEEDING_DURATION',
  SETTLEMENT_METHOD_COUNTS = 'SETTLEMENT_METHOD_COUNTS',
  SUBMISSION_COUNTS = 'SUBMISSION_COUNTS',
  UNRESOLVED_BY_JUDGE_COUNTS = 'UNRESOLVED_BY_JUDGE_COUNTS',
}

export enum ChartView {
  TABLE = 'TABLE',
  TABLE_2 = 'TABLE_2',
  BAR_CHART = 'BAR_CHART',
  PIE_CHART = 'PIE_CHART',
  LINE_CHART = 'LINE_CHART',
  LINE_CHART_2 = 'LINE_CHART_2',
}
