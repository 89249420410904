import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { OutgoingDocument } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useForwardToSend() {
  const { source } = useContext<DetailHandle<OutgoingDocument>>(DetailContext);

  const callApi = useEventCallback(() =>
    abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${source.data?.id}/dispatch/to-send/all`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  const showButton =
    source.data?.id && (source?.data?.preparedDispatchesCount ?? 0) > 0;

  return {
    callApi,
    showButton,
  };
}
