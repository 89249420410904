import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Tooltip, useFieldTooltip } from '@eas/common-web';
import { useStyles } from '../history-styles';
import { HistoryFieldProps } from '../history-types';

export function StringHistoryField({
  position,
  value,
}: HistoryFieldProps<string>) {
  const classes = useStyles();

  const { tooltipRef, withTooltip } = useFieldTooltip();

  const content = (
    <span
      className={clsx({
        [classes.textAdded]: position === 'after',
        [classes.textDeleted]: position === 'before',
      })}
      ref={tooltipRef}
    >
      {position === 'before' && `- ${value}`}
      {position === 'after' && `+ ${value}`}
    </span>
  );

  return (
    <Typography variant="body1" className={classes.content}>
      {value &&
        (withTooltip ? (
          <Tooltip title={value} placement="top-start" type="HOVER">
            {content}
          </Tooltip>
        ) : (
          content
        ))}
    </Typography>
  );
}
