import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictProposerType } from '@models';
import { EntityName, EvidenceApiUrl, HistoryEntityType } from '@enums';
import { Fields } from './dict-proposer-type-fields';

export function DictProposerTypes() {
  const intl = useIntl();
  const { columnName, columnCode, columnOrder, columnActive } =
    DictionaryEvidence.useDictionaryColumns<DictProposerType>();

  const evidence = useDictionaryEvidence<DictProposerType>({
    version: 1,
    identifier: 'PROPOSER_TYPES',
    apiProps: {
      url: EvidenceApiUrl.DICT_PROPOSER_TYPE,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.PROPOSER_TYPE,
      }),
    },
    tableProps: {
      columns: [columnName, columnCode, columnOrder, columnActive],
      tableName: intl.formatMessage({
        id: 'ES__DICT_PROPOSER_TYPES__TABLE__TITLE',
        defaultMessage: 'Typy navrhovatelů',
      }),
      showReportButton: false,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.PROPOSER_TYPE,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_PROPOSER_TYPES__DETAIL__TITLE',
          defaultMessage: 'Typ navrhovatele',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
