import { ApiFilterOperation, Params, abortableFetch } from '@eas/common-web';
import { Document } from '@models';
import { EvidenceApiUrl } from '@enums';

export function getLatestOpenEsFile() {
  return abortableFetch(EvidenceApiUrl.ES_FILES + '/list', {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify({
      size: 1,
      sort: [
        {
          type: 'FIELD',
          field: 'deliveryDate',
          order: 'ASC',
        },
      ],
      filters: [
        {
          operation: ApiFilterOperation.NOT,
          filters: [
            {
              field: 'state.id',
              operation: ApiFilterOperation.EQ,
              value: 'REGISTRY',
            },
          ],
        },
      ],
    }),
  });
}

export function createScreening(
  params: Params,
  selected: string[],
  {
    date,
    deliveredDocument,
  }: {
    date: string;
    deliveredDocument: Document;
  }
) {
  return abortableFetch(
    `${EvidenceApiUrl.ES_FILES}/court/lustrum?date=${date}&deliveredDocument=${deliveredDocument.id}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(params),
    }
  );
}

export function createRepeatedScreening(filtered: string[]) {
  return function apiCall(
    params: Params,
    selected: string[],
    {
      date,
    }: {
      date: string;
    }
  ) {
    let url = `/lustrum/repeated?date=${date}`;
    if (filtered.length) {
      url += `&esFile=${filtered.join(',')}`;
    }

    return abortableFetch(`${EvidenceApiUrl.ES_FILES}${url}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(params),
    });
  };
}
