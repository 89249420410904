import { getErrorMessage } from '@utils/error-message';
import { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  AbortableFetch,
  FilesContext,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { ErrorObject } from '@models';

export function useDownloadReport() {
  const { getFileUrl } = useContext(FilesContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useRef<AbortableFetch | null>(null);
  const intl = useIntl();

  const callApi = useEventCallback((apiUrl: string) =>
    abortableFetch(apiUrl, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    })
  );

  const wrappedCallApi = useEventCallback(async (apiUrl: string) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(apiUrl);

      let message = intl.formatMessage({
        id: 'STATISTICS__REQUEST_SUCCESSFULLY_SENT',
        defaultMessage: 'Report se generuje, čekejte prosím.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);

      const response = await fetch.current.json();

      const a = document.createElement('a');
      a.href = getFileUrl(response.id);
      a.download = response.name || 'report.pdf';

      document.body.appendChild(a); // Required for FF
      a.click();
      a.remove();

      message = intl.formatMessage({
        id: 'STATISTICS__REQUEST_SUCCESSFULL',
        defaultMessage: 'Akce byla úspěšně vykonána.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
    } catch (e) {
      const err = e as ErrorObject<string>;
      const message = getErrorMessage(err);

      if (err.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  return {
    callApi: wrappedCallApi,
  };
}
