import React, { useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {
  DetailContext,
  DetailMode,
  DialogHandle,
  DictionaryFieldDialog,
  Filter,
  ScrollableSource,
  TableFieldColumn,
} from '@eas/common-web';

const useStyles = makeStyles(() => ({
  chooseDraftBtn: {
    width: 100,
    height: 22,
    padding: '0 10px',
    marginLeft: 3,
    fontSize: '12px',
    fontWeight: 600,
    flexShrink: 0,
  },
}));

export function AddAttachmentButton<OBJECT>({
  source,
  columns,
  filtersFields,
  preFilters,
  onChange,
  disabled = false,
}: {
  source: ScrollableSource<OBJECT>;
  columns: TableFieldColumn<OBJECT>[];
  filtersFields: any[];
  preFilters?: Filter[];
  onChange: (value: OBJECT | OBJECT[]) => void;
  disabled?: boolean;
}) {
  const classes = useStyles();

  const { mode } = useContext(DetailContext);
  const dialogRef = useRef<DialogHandle>(null);

  return (
    <>
      {mode !== DetailMode.VIEW && (
        <>
          <Button
            onClick={() => dialogRef.current?.open()}
            variant="text"
            startIcon={<SearchIcon />}
            className={classes.chooseDraftBtn}
            disabled={disabled}
          >
            <FormattedMessage
              id="ES__DECISIONS__ATTACHMENTS__CHOOSE_ATTACHMENT"
              defaultMessage="Vybrat"
            />
          </Button>
          <DictionaryFieldDialog
            ref={dialogRef}
            source={source}
            columns={columns}
            filtersFields={filtersFields}
            preFilters={preFilters}
            onChange={onChange}
            maxRows={9}
            width={800}
          />
        </>
      )}
    </>
  );
}
