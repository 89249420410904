import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useUsers } from '@modules/user/user-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
} from '@components/form/misc/label-mappers';
import { UserSubstitution } from '@models';

export function useColumns(): TableColumn<UserSubstitution>[] {
  const intl = useIntl();

  const { userSubstitutionStates } = useContext(StaticDataContext);

  const useStates = () => useStaticListSource(userSubstitutionStates);

  return [
    {
      datakey: 'substitute',
      sortkey: 'substitute.label',
      filterkey: 'substitute.id',
      name: intl.formatMessage({
        id: 'ES__USER_SUBSTITUTIONS__TABLE__COLUMN__SUBSTITUTE',
        defaultMessage: 'Zastupující uživatel',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useUsers,
        autocompleteLabelMapper
      ),
      valueMapper: autocompleteColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'substituteFor',
      sortkey: 'substituteFor.label',
      filterkey: 'substituteFor.id',
      name: intl.formatMessage({
        id: 'ES__USER_SUBSTITUTIONS__TABLE__COLUMN__SUBSTITUTE_FOR',
        defaultMessage: 'Zastupovaný uživatel',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useUsers,
        autocompleteLabelMapper
      ),
      valueMapper: autocompleteColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'from',
      name: intl.formatMessage({
        id: 'ES__USER_SUBSTITUTIONS__TABLE__COLUMN__FROM',
        defaultMessage: 'Zastupovat od',
      }),
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'to',
      name: intl.formatMessage({
        id: 'ES__USER_SUBSTITUTIONS__TABLE__COLUMN__TO',
        defaultMessage: 'Zastupovat do',
      }),
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: intl.formatMessage({
        id: 'ES__USER_SUBSTITUTIONS__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      valueMapper: TableCells.useSelectCellFactory(useStates),
      sortable: true,
      filterable: true,
    },
  ];
}
