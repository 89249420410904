/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  AdminProvider,
  AppSettingsContext,
  DetailToolbar2,
  EvidenceDefaultsProvider,
  ExportProvider,
  Menubar,
  MenubarContext,
  NamedSettingsProvider,
  NavigationContext,
  SplitScreenMode,
  UserContext,
  UserSettingsProvider,
  useFirstRender,
  MenuItem,
  InternalSettingsProvider,
  NotificationProvider,
  ScheduleProvider,
  InactivityProvider,
} from '@eas/common-web';
import { DECISIONS_ACCESS } from '@modules/decision/decision-permission';
import {
  DOCUMENTS_ACCESS,
  OUTGOING_DOCUMENTS_ACCESS,
  PROCESSED_DOCUMENTS_ACCESS,
  UNPROCESSED_DELIVERED_DOCUMENTS_ACCESS,
} from '@modules/document/document-permission';
import {
  ALL_FILES_ACCESS,
  COURT_FILES_ACCESS,
  DISCIPLINARY_PROCEEDINGS_FILE_ACCESS,
  INF_FILES_ACCESS,
  PLENARY_OPINION_FILES_ACCESS,
  SPR_FILES_ACCESS,
} from '@modules/es-file/es-file-permission';
import { useExportTags } from '@modules/export/export-api';
import { StaticDataProvider } from '@components/evidence/static-data-provider/static-data-provider';
import { useMenuItems } from '@components/menu/menu-items';
import { SecureRoute } from '@components/secure-page/secure-route';
import { EsAppSettings } from '@models';
import { Agenda, EvidenceApiUrl, EvidenceBrowserUrl, Role } from '@enums';
import darkLogo from './logo-dark.svg';
import lightLogo from './logo-light.svg';
import * as R from './modules';
import { NotificationButton } from '@components/notification/notification-button';

export const useStyles = makeStyles({
  logo: {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  title: {
    fontWeight: 400,
    fontSize: 12,
    color: 'white',
    marginLeft: 10,
  },
  userButton: {
    fontSize: 12,
  },
  subMenuItem: {
    margin: 0,
  },
  subMenuItemIcon: {
    minWidth: 25,
  },
  substitutedUser: {
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    fontWeight: 700,
    marginRight: 25,
  }
});

export function AppSecure() {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);
  const { pathname } = useLocation();

  const menuRef = useRef<MenubarContext>(null);

  useFirstRender(() => {
    if (!user) {
      if (pathname) {
        window.sessionStorage.setItem('redirectTo', pathname);
      }
      navigate('/', true);
    } else {
      const redirectTo = window.sessionStorage.getItem('redirectTo');
      window.sessionStorage.removeItem('redirectTo');

      if (redirectTo) {
        navigate(redirectTo, true);
      }
    }
  });

  const exportTags = useExportTags();
  const { filteredItems, userBtnActions, devTools } = useMenuItems(menuRef);

  const { settings } = useContext<AppSettingsContext<EsAppSettings>>(
    AppSettingsContext as any
  );

  return (
    <InactivityProvider timeout={3600}>
      <ExportProvider url="/api/evidence-spisu/export" tags={exportTags} disableAsync={true}>
        <UserSettingsProvider url="/api/evidence-spisu/user-settings">
          <NotificationProvider
            notificationUrl={EvidenceApiUrl.NOTIFICATIONS}
            notificationTemplateUrl={EvidenceApiUrl.NOTIFICATION_TEMPLATES}
            ruleUrl={`${EvidenceApiUrl.NOTIFICATION_TEMPLATES}/rule`}
            notificationEventUrl={EvidenceApiUrl.NOTIFICATION_EVENTS}
            notificationPreferenceUrl={EvidenceApiUrl.NOTIFICATION_PREFERENCES}
            notificationSubscriptionTypeUrl={`${EvidenceApiUrl.NOTIFICATION_PREFERENCES}/subscription/type`}
          >
            <NamedSettingsProvider
              url="/api/evidence-spisu/named-settings"
            >
              <InternalSettingsProvider url={EvidenceApiUrl.GLOBAL_SETTINGS}>
                <ScheduleProvider jobUrl={EvidenceApiUrl.SCHEDULE_JOBS} runUrl={EvidenceApiUrl.SCHEDULE_RUNS}>
                  <EvidenceDefaultsProvider
                    appName="Evidence spisů"
                    appLogo={darkLogo}
                    enableEmptyDetailContainer={true}
                    enableFilterBox={true}
                    ToolbarComponent={DetailToolbar2}
                    splitScreen={+settings.evidenceMode ?? SplitScreenMode.SPLIT}
                    entityLocking={{enabled: true, expirationReminderTime: 30, forceReleasePermission: Role.SUPERADMIN}}
                  >
                    <Menubar
                      ref={menuRef}
                      userBtnActions={userBtnActions}
                      logoUrl="/es"
                      logo={<img src={lightLogo} className={classes.logo} />}
                      title={
                        <Typography className={classes.title}>
                          <FormattedMessage
                            id="ES__APP_TITLE"
                            defaultMessage="Evidence spisů"
                          />
                        </Typography>
                      }
                      items={filteredItems}
                      classOverrides={{
                        userButton: classes.userButton,
                        subMenuItem: classes.subMenuItem,
                        subMenuItemIcon: classes.subMenuItemIcon,
                      }}
                      beforeUserBtn={
                        <>
                          {user?.additionalDetails?.activeSubstitution && (
                            <span className={classes.substitutedUser}>
                              {`Zástup: ${user?.additionalDetails?.activeSubstitution?.substitutedUser?.name} (${user?.additionalDetails?.activeSubstitution?.substitutedUser?.username})`}
                            </span>
                          )}
                          <NotificationButton />
                        </>
                      }
                    >
                      <StaticDataProvider>
                        <AdminProvider
                          permission={Role.SUPERADMIN}
                          prefix="/es"
                          reindexUrl="/api/evidence-spisu/reindex"
                          soapMessagesUrl="/api/evidence-spisu/soap/logger/messages"
                        />
                        <MenubarChanger devTools={devTools} />
                        <Switch>
                          <SecureRoute path={EvidenceBrowserUrl.HOME} exact component={R.Dashboard} />
                          <SecureRoute path={EvidenceBrowserUrl.PROFILE} exact component={R.Profile} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ALOG}/:id?`} component={R.Alog} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ACTIONS}/:id?`} component={R.Actions} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.APP_SETTINGS}/:id?`} component={R.AppSettings} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ASSIGNED_FILE_NUMBERS}/:id?`} component={R.AssignedFileNumbers} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ES_FILES_ALL}/:id?`} component={R.AllEsFiles} roles={ALL_FILES_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ES_FILES_SEARCH}/:id?`} component={R.EsFileSearch} roles={ALL_FILES_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DISCIPLINARY_PROCEEDINGS_FILES}/:id?`} component={R.DisciplinaryProceedingsFiles} roles={DISCIPLINARY_PROCEEDINGS_FILE_ACCESS} agenda={Agenda.DISCIPLINARY_PROCEEDINGS} viewing={true} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_ALL}/:id?`} component={R.CourtFilesAll} roles={COURT_FILES_ACCESS} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_CLOSED}/:id?`} component={R.CourtFilesClosed} roles={COURT_FILES_ACCESS} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_NEW}/:id?`} component={R.CourtFilesNew} roles={[Role.INDEX, Role.LUSTRUM, Role.MAILROOM, Role.HEAD_OSV]} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_REGISTER_NEW}/:id?`} component={R.CourtFilesRegisterNew} roles={COURT_FILES_ACCESS} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_REGISTER}/:id?`} component={R.CourtFilesRegister} roles={COURT_FILES_ACCESS} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_REGISTRY}/:id?`} component={R.CourtFilesRegistry} roles={COURT_FILES_ACCESS} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_JUDGE_OFFICE}/:id?`} component={R.CourtFilesJudgeOffice} roles={COURT_FILES_ACCESS} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_SHREDDED}/:id?`} component={R.CourtFilesShredded} roles={COURT_FILES_ACCESS} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.COURT_FILES_INDEX}/:id?`} component={R.CourtFilesIndex} roles={COURT_FILES_ACCESS} agenda={Agenda.COURT} />
                          <SecureRoute path={`${EvidenceBrowserUrl.INF_FILES_ALL}/:id?`} component={R.InfFilesAll} roles={INF_FILES_ACCESS} agenda={Agenda.INF} />
                          <SecureRoute path={`${EvidenceBrowserUrl.INF_FILES_NEW}/:id?`} component={R.InfFilesNew} roles={INF_FILES_ACCESS} agenda={Agenda.INF} />
                          <SecureRoute path={`${EvidenceBrowserUrl.INF_FILES_IN_PROGRESS}/:id?`} component={R.InfFilesInProgress} roles={INF_FILES_ACCESS} agenda={Agenda.INF} />
                          <SecureRoute path={`${EvidenceBrowserUrl.INF_FILES_CLOSED}/:id?`} component={R.InfFilesClosed} roles={INF_FILES_ACCESS} agenda={Agenda.INF} />
                          <SecureRoute path={`${EvidenceBrowserUrl.INF_FILES_REGISTRY}/:id?`} component={R.InfFilesRegistry} roles={INF_FILES_ACCESS} agenda={Agenda.INF} />
                          <SecureRoute path={`${EvidenceBrowserUrl.INF_FILES_SHREDDED}/:id?`} component={R.InfFilesShredded} roles={INF_FILES_ACCESS} agenda={Agenda.INF} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SPR_FILES_ALL}/:id?`} component={R.SprFilesAll} roles={SPR_FILES_ACCESS} agenda={Agenda.SPR} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SPR_FILES_NEW}/:id?`} component={R.SprFilesNew} roles={[Role.INDEX, Role.LUSTRUM, Role.MAILROOM, Role.HEAD_OSV]} agenda={Agenda.SPR} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SPR_FILES_IN_PROGRESS}/:id?`} component={R.SprFilesInProgress} roles={SPR_FILES_ACCESS} agenda={Agenda.SPR} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SPR_FILES_CLOSED}/:id?`} component={R.SprFilesClosed} roles={SPR_FILES_ACCESS} agenda={Agenda.SPR} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SPR_FILES_REGISTRY}/:id?`} component={R.SprFilesRegistry} roles={SPR_FILES_ACCESS} agenda={Agenda.SPR} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SPR_FILES_SHREDDED}/:id?`} component={R.SprFilesShredded} roles={SPR_FILES_ACCESS} agenda={Agenda.SPR} />
                          <SecureRoute path={`${EvidenceBrowserUrl.PLENARY_OPINION_FILES}/:id?`} component={R.PlenaryOpinionFiles} roles={PLENARY_OPINION_FILES_ACCESS} agenda={Agenda.PLENARY_OPINION} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DECISIONS_ALL}/:id?`} component={R.AllDecisions} roles={DECISIONS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DECISIONS_INDEXING}/:id?`} component={R.IndexingDecisions} roles={DECISIONS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DECISIONS_ACCESSIBLE}/:id?`} component={R.AccessibleDecisions} roles={DECISIONS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DECISIONS_SEARCH}/:id?`} component={R.DecisionSearch} roles={DECISIONS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DECISIONS_IN_PROGRESS}/:id?`} component={R.InProgressDecisions} roles={DECISIONS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DECISIONS_TO_ANONYMIZE}/:id?`} component={R.ToAnonymizeDecisions} roles={DECISIONS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DECISIONS_TO_MAKE_ACCESSIBLE}/:id?`} component={R.ToMakeAccessibleDecisions} roles={DECISIONS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_ACT_TYPE}/:id?`} component={R.DictActTypes} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_CONTACT_TYPES}/:id?`} component={R.DictContactTypes} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ASSIGNING_RULES}/:id?`} component={R.AssigningRules} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_BRIEF_SUMMARY}/:id?`} component={R.DictBriefSummaries} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_BARCODE_PRINTERS}/:id?`} component={R.DictBarcodePrinters} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_COUNTRIES}/:id?`} component={R.DictCountries} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_CONCERNED_AUTHORITY_TYPES}/:id?`} component={R.DictConcernedAuthorityTypes} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_DISPATCH_TYPES}/:id?`} component={R.DictDispatchTypes} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_ENVELOPE_CONTENTS}/:id?`} component={R.DictEnvelopeContents} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_HOLIDAYS}/:id?`} component={R.DictHolidays} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_PROCEEDINGS_SUBJECT}/:id?`} component={R.DictProceedingsSubjects} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_NOTES}/:id?`} component={R.DictNotes} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_OPPONENTS}/:id?`} component={R.DictOpponents} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_PROPOSER_DISTINCTION}/:id?`} component={R.DictProposerDistinctions} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_PROPOSER_TYPE}/:id?`} component={R.DictProposerTypes} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_PROCESSING_TYPE}/:id?`} component={R.DictProcessingTypes} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_REGULATION_ORDER}/:id?`} component={R.DictRegulationOrders} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_SENATES}/:id?`} component={R.DictSenates} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_SETTLEMENT_METHODS}/:id?`} component={R.DictSettlementMethods} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_SUBMISSION_CONTENT}/:id?`} component={R.DictSubmissionContents} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_SUBJECT_INDEX}/:id?`} component={R.DictSubjectIndex} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_TASK_NAME}/:id?`} component={R.DictTaskNames} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DICT_ZIP_CODES}/:id?`} component={R.DictZipCodes} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED}/:id?`} component={R.ProcessedDocuments} roles={PROCESSED_DOCUMENTS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DOCUMENTS_DELIVERED_UNPROCESSED}/:id?`} component={R.UnprocessedDocuments} roles={UNPROCESSED_DELIVERED_DOCUMENTS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DOCUMENTS_INTERNAL}/:id?`} component={R.InternalDocuments} roles={DOCUMENTS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DOCUMENTS_OUTGOING}/:id?`} component={R.OutgoingDocuments} roles={OUTGOING_DOCUMENTS_ACCESS} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ELECTRONIC}/:id?`} component={R.ElectronicDocumentDispatches} roles={[Role.INDEX, Role.ASSISTANT, Role.SECRETARY, Role.LUSTRUM, Role.JUDGE, Role.FILE_ROOM, Role.HEAD_OSV, Role.MAILROOM, Role.SOLVER]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ALL}/:id?`} component={R.DocumentDispatches} roles={[Role.INDEX, Role.ASSISTANT, Role.SECRETARY, Role.LUSTRUM, Role.JUDGE, Role.FILE_ROOM, Role.HEAD_OSV, Role.MAILROOM, Role.SOLVER]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ESS_INCOMING_BATCHES}/:id?`} component={R.EssIncomingBatches} roles={[Role.SUPERADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ESS_OUTGOING_BATCHES}/:id?`} component={R.EssOutgoingBatches} roles={[Role.SUPERADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.EXPORT_REQUESTS}/:id?`} component={R.ExportRequests} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.EXPORT_TEMPLATES}/:id?`} component={R.ExportTemplates} roles={[Role.ADMIN]} />
                          <SecureRoute path={EvidenceBrowserUrl.GLOBAL_SETTINGS} component={R.GlobalSettings} roles={[Role.ADMIN]} />
                          <SecureRoute path={EvidenceBrowserUrl.HELP} component={R.Help} />
                          <SecureRoute path={`${EvidenceBrowserUrl.JUDGES}/:id?`} component={R.Judges} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.JOBS}/:id?`} component={R.Jobs} roles={[Role.SUPERADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.NASPIS_LOG_RECORDS}/:id?`} component={R.NaspisLogRecords} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.NOTIFICATIONS}/:id?`} component={R.Notifications} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.NOTIFICATION_TEMPLATES}/:id?`} component={R.NotificationTemplates} roles={[Role.ADMIN]} />
                          <SecureRoute path={EvidenceBrowserUrl.REGISTERS} component={R.RegistersAndFiles} roles={[Role.HEAD_OSV, Role.FILE_ROOM, Role.ADVISER]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.REST_MESSAGES}/:id?`} component={R.RestMessages} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.ROLES}/:id?`} component={R.Roles} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SCHEDULE_JOBS}/:id?`} component={R.ScheduleJobs} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SCHEDULE_RUNS}/:id?`} component={R.ScheduleRuns} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SCREENING}/:id?`} component={R.Screening} roles={[Role.LUSTRUM, Role.SOLVER, Role.HEAD_OSV, Role.INDEX]} />
                          <SecureRoute path={EvidenceBrowserUrl.STATISTICS} component={R.Statistics} roles={[Role.HEAD_OSV]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SUBJECTS}/:id?`} component={R.Subjects} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.SEQUENCES}/:id?`} component={R.Sequences} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.USERS}/:id?`} component={R.Users} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.USER_SUBSTITUTIONS}/:id?`} component={R.UserSubstitutions} roles={[Role.ADMIN]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.TASKS}/:id?`} component={R.Tasks} />
                          <SecureRoute path={`${EvidenceBrowserUrl.VIEWING_REQUEST_ALL}/:id?`} component={R.ViewingRequestsAll} roles={[Role.ADMIN, Role.VIEWING_REQUEST_MANAGER]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.VIEWING_REQUEST_NEW}/:id?`} component={R.ViewingRequestsNew} roles={[Role.ADMIN, Role.VIEWING_REQUEST_MANAGER]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.VIEWING_REQUEST_PROCESSING}/:id?`} component={R.ViewingRequestsProcessing} roles={[Role.ADMIN, Role.VIEWING_REQUEST_MANAGER]} />
                          <SecureRoute path={`${EvidenceBrowserUrl.VIEWING_REQUEST_RESOLVED}/:id?`} component={R.ViewingRequestsResolved} roles={[Role.ADMIN, Role.VIEWING_REQUEST_MANAGER]} />
                        </Switch>
                      </StaticDataProvider>
                    </Menubar>
                  </EvidenceDefaultsProvider>
                </ScheduleProvider>
              </InternalSettingsProvider>
            </NamedSettingsProvider>
          </NotificationProvider>
        </UserSettingsProvider>
      </ExportProvider>
    </InactivityProvider>
  );
}

const MenubarChanger = ({ devTools }: { devTools: MenuItem[] }) => {
  const { modifyItems } = useContext(MenubarContext);

  useEffect(() => {
    modifyItems((items) => {
      const easDevToolsIndex = items?.findIndex((item: any) => item?.label?.props?.id === 'EAS_MENU_ITEM_DEVTOOLS');
      const easDevTools = items[easDevToolsIndex];

      let filteredItems = items;

      if (easDevTools) {
        filteredItems = [...items.slice(0, easDevToolsIndex),
        { ...easDevTools, items: [...(easDevTools?.items ?? []), ...devTools] }
        ]
      }

      return filteredItems;
    })
  }, [])


  return <></>
}