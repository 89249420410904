import { getErrorMessage } from '@utils/error-message';
import { useContext, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  AbortableFetch,
  DetailContext,
  SnackbarContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { ErrorObject, UserReplacement } from '@models';
import { EvidenceApiUrl, Messages } from '@enums';

export function useReplaceUserDialog() {
  const { source } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const [testedFormData, setTestedFormData] = useState<UserReplacement>({});

  const callApi = useEventCallback(
    (id: string, formData: UserReplacement, test = true) => {
      setTestedFormData(test ? formData : {});

      let url = `${EvidenceApiUrl.USERS}/${id}/replace-with/${formData.replacingUser?.user?.id}?role=${formData?.role?.role}`;
      url = formData.agenda ? `${url}&agenda=${formData.agenda}` : url;
      url = `${url}&test=${test}`;

      return abortableFetch(url, {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      });
    }
  );

  const fetch = useRef<AbortableFetch | null>(null);

  const handleConfirm = useEventCallback(async () => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(source.data.id, testedFormData, false);
      await fetch.current.raw();

      unstable_batchedUpdates(() => {
        showSnackbar(...Messages.User.REPLACE_USER.SUCCESS);
        source.setLoading(false);
      });

      await source.refresh();
    } catch (e) {
      const err = e as ErrorObject<string>;
      const message = getErrorMessage(err, Messages.User.REPLACE_USER);

      if (err.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  const showButton = source?.data?.active; // TODO add permimssions

  return {
    callApi: callApi,
    handleConfirm,
    showButton,
  };
}
