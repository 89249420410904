import clsx from 'clsx';
import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TableChartIcon from '@material-ui/icons/TableChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import { Tooltip } from '@eas/common-web';
import { ChartView } from '@enums';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  activeButton: {
    backgroundColor: `${theme.palette.primary.light}40`,
  },
  button: {
    borderRadius: 0,
  },
}));

interface ChartSwitchProps {
  availableViews?: { view: ChartView; tooltip?: string }[];
  currentView: ChartView;
  setCurrentView: (view: ChartView) => void;
}

const ICONS = {
  [ChartView.TABLE]: ViewHeadlineIcon,
  [ChartView.TABLE_2]: TableChartIcon,
  [ChartView.PIE_CHART]: DonutSmallIcon,
  [ChartView.BAR_CHART]: EqualizerIcon,
  [ChartView.LINE_CHART]: ShowChartIcon,
  [ChartView.LINE_CHART_2]: TimelineIcon,
};

export function ChartSwitch({
  availableViews,
  currentView,
  setCurrentView,
}: ChartSwitchProps) {
  const classes = useStyles();

  return (
    <ButtonGroup
      variant="outlined"
      size="small"
      className={classes.buttonGroup}
    >
      {availableViews?.map(({ view, tooltip }) => {
        const Icon = ICONS[view];

        return (
          <IconButton
            key={view}
            size="small"
            onClick={() => setCurrentView(view)}
            className={clsx(classes.button, {
              [classes.activeButton]: view === currentView,
            })}
          >
            {tooltip ? (
              <Tooltip title={tooltip}>
                <Icon fontSize="small" />
              </Tooltip>
            ) : (
              <Icon fontSize="small" />
            )}
          </IconButton>
        );
      })}
    </ButtonGroup>
  );
}
