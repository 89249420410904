import { groupBy } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import SearchIcon from '@material-ui/icons/Search';
import {
  ApiFilterOperation,
  DetailToolbarButton2,
  DetailToolbarButtonType,
  EvidenceContext,
  useEventCallback,
} from '@eas/common-web';
import { FilterActionbar } from './filter-actionbar';
import { FilterColumn } from './filter-column';
import { FilterContext } from './filter-context';
import { FilterExtenders } from './filter-extenders';
import { FilterReducers } from './filter-reducers';
import { FilterState } from './filter-types';
import { deriveFiltersState } from './filter-utils';

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: 100,
  },
  wrapper: {
    margin: '10px 10px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  statusBar: {
    width: '100%',
    display: 'inline-block',
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px #ccc',
    paddingLeft: 10,
    paddingBottom: 10,
    position: 'sticky',
    top: 0,
    zIndex: 98,
  },
  title: {
    margin: '10px 0',
    fontSize: 25,
    fontWeight: 500,
  },
  subTitle: {
    color: theme.palette.error.main,
    marginLeft: 30,
    fontSize: 16,
  },
  filters: {
    margin: 15,
  },
  button: {
    marginTop: 15,
    marginRight: 10,
    marginBottom: 7,
  },
  actionbarWrapper: {
    display: 'flex',
    flex: '0 0 auto',
    width: '100%',
    overflowX: 'auto',
  },
}));

export function Filter({
  defaults,
  initVisibility,
  groupLabels,
  title,
  Before,
}: {
  defaults: FilterState[];
  initVisibility?: string[];
  groupLabels: Record<string | number, string>;
  title?: React.ReactNode;
  Before?: React.ComponentType;
}) {
  /**
   * Styles.
   */
  const classes = useStyles();

  /**
   * Context stuff.
   */
  const { tableRef, tableSource } = useContext(EvidenceContext);
  const {
    extenders,
    appendExtender,
    confirmExtender,
    setActiveExtender,
    activateAllExtenders,
    activeExtender,
    removeExtender,
    filters,
    setFilters,
  } = useContext(FilterContext);

  // (value AND not IS_NULL, NOT_NULL operation) OR (filters) OR (not value AND IS_NULL, NOT_NULL operation)
  const isFilterValid = (filter: FilterState) =>
    (!!filter.value &&
      ![ApiFilterOperation.IS_NULL, ApiFilterOperation.NOT_NULL].includes(
        filter.operation
      )) ||
    !!filter.filters?.length ||
    ([ApiFilterOperation.IS_NULL, ApiFilterOperation.NOT_NULL].includes(
      filter.operation
    ) &&
      !filter.value);

  const validFilters = useMemo(
    () =>
      filters.filter(isFilterValid).map((f) => ({
        id: f.id,
        field: f.field,
        operation: f.operation,
        path: f.path,
        visible: f.visible,
        value: f.value,
        object: f.object,
        filters: f.filters,
      })),
    [filters]
  );

  /**
   * Submit filters.
   */
  const handleSave = useEventCallback(() => {
    const index = appendExtender(validFilters);

    setActiveExtender(index);
  });

  /**
   * Cancel active extender.
   */
  const handleCancel = useEventCallback(() => {
    const filters = deriveFiltersState(
      tableRef.current?.filters ?? [],
      defaults
    );
    setFilters(filters);
    removeExtender(activeExtender!);
    setActiveExtender(undefined);
  });

  /**
   * Flag filter as confirmed = will be added into result filter list.
   */
  const handleAddToResult = useEventCallback(() => {
    handleSave();

    requestAnimationFrame(() => {
      const filters = deriveFiltersState(
        tableRef.current?.filters ?? [],
        defaults
      );

      setFilters(filters);
      confirmExtender();
      setActiveExtender(undefined);
    });

    requestAnimationFrame(() => {
      activateAllExtenders();
    });
  });

  const groupedFilters = groupBy(tableRef.current?.filters, 'filterGroup');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}
      className={classes.form}
    >
      <div>
        <div className={classes.statusBar}>
          <Typography variant="h1" className={classes.title}>
            {title}
            <span className={classes.subTitle}>
              <FormattedMessage
                id="ES__FILTER__TOOLBAR__FOUND_ITEMS"
                defaultMessage="Nalezeno záznamů"
              />
              : {tableSource.count}
            </span>
          </Typography>

          <div className={classes.actionbarWrapper}>
            {!!Before && <Before />}
            <DetailToolbarButton2
              buttonType="submit"
              type={DetailToolbarButtonType.PRIMARY}
              startIcon={<SearchIcon />}
              onClick={handleSave}
              disabled={validFilters.length === 0}
              label={
                <FormattedMessage
                  id="ES__FILTER__TOOLBAR__BTN_SUBMIT"
                  defaultMessage="Hledat"
                />
              }
            />

            {activeExtender !== undefined && (
              <DetailToolbarButton2
                type={DetailToolbarButtonType.SECONDARY}
                onClick={handleCancel}
                startIcon={<DeleteForeverIcon />}
                label={
                  <FormattedMessage
                    id="ES__FILTER__TOOLBAR__BTN_CANCEL"
                    defaultMessage="Zrušit"
                  />
                }
              />
            )}

            {activeExtender !== undefined && (
              <DetailToolbarButton2
                type={DetailToolbarButtonType.PRIMARY}
                onClick={handleAddToResult}
                startIcon={<DoneOutlineIcon />}
                label={
                  <>
                    {extenders[activeExtender].confirmed && (
                      <FormattedMessage
                        id="ES__FILTER__TOOLBAR__BTN_OK"
                        defaultMessage="OK"
                      />
                    )}
                    {!extenders[activeExtender].confirmed && (
                      <FormattedMessage
                        id="ES__FILTER__TOOLBAR__BTN_ADD_TO_RESULT"
                        defaultMessage="Výsledek"
                      />
                    )}
                  </>
                }
              />
            )}
          </div>
        </div>
        <div className={classes.wrapper}>
          {Object.keys(groupedFilters).map((key) => (
            <FilterColumn
              key={key}
              initVisibility={initVisibility}
              filterGroup={groupedFilters[key]}
              filters={filters}
              setFilters={setFilters}
              label={groupLabels[key]}
            />
          ))}
        </div>
      </div>

      <div className={classes.filters}>
        <FilterActionbar />
        <FilterExtenders setFilters={setFilters} />
        <FilterReducers />
      </div>
    </form>
  );
}
