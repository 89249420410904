import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useUsers } from '@modules/user/user-api';
import { CreatedByCell } from '@components/evidence/authored-evidence/created-by-cell';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
} from '@components/form/misc/label-mappers';
import { Task } from '@models';

export function useColumns(): TableColumn<Task>[] {
  const intl = useIntl();
  const { taskStates, taskTypes } = useContext(StaticDataContext);

  const useTypes = () => useStaticListSource(taskTypes);
  const useStates = () => useStaticListSource(taskStates);

  return [
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ES__TASKS__TABLE__COLUMN__NAME',
        defaultMessage: 'Název',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'type',
      filterkey: 'type.id',
      sortkey: 'type.name',
      name: intl.formatMessage({
        id: 'ES__TASKS__TABLE__COLUMN__TYPE',
        defaultMessage: 'Typ úlohy',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      sortable: true,
      filterable: true,
    },
    {
      /**
       * warning: when changing this column, also fix user-overview.tsx#64 onClick funtion
       */
      datakey: 'state',
      filterkey: 'state.id',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ES__TASKS__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav úlohy',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'esFile.number.value',
      sortkey: 'esFile.number.valueSort',
      name: intl.formatMessage({
        id: 'ES__TASKS__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'document.referenceNumber',
      filterkey: 'document.referenceNumber',
      sortkey: 'document.referenceNumberSort',
      name: intl.formatMessage({
        id: 'ES__TASKS__TABLE__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'solver',
      sortkey: 'solver.label',
      filterkey: 'solver.user.id',
      name: intl.formatMessage({
        id: 'ES__TASKS__TABLE__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Pro uživatele',
      }),
      width: 200,
      valueMapper: autocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useUsers,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'createdBy',
      displaykey: 'createdBy.name',
      sortkey: 'createdBy.name',
      filterkey: 'createdBy.name',
      name: intl.formatMessage({
        id: 'ES__TASKS__TABLE__COLUMN__CREATED_BY',
        defaultMessage: 'Vytvořil',
      }),
      width: 150,
      CellComponent: CreatedByCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'ES__TASKS__TABLE__COLUMN__CREATED',
        defaultMessage: 'Datum vytvoření',
      }),
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
    },
  ];
}
