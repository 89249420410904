import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HistoryField } from '@composite/history/history-field';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { useHistoryDescriptors } from './decision-history-descriptors';
import { AbstractFields } from './fields/abstract/abstract';
import { AttachmentFields } from './fields/attachment/attachment';
import { DecisionFields } from './fields/decision/decision';
import { DifferentOpinionFields } from './fields/different-opinion/different-opinion';
import { GeneralFields } from './fields/general/general';
import { JustificationFields } from './fields/justification/justification';
import { LegalSentenceFields } from './fields/legal-sentence/legal-sentence';

export function Fields() {
  const historyDescriptors = useHistoryDescriptors();

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ES__DECISIONS__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Obecné"
        />
      ),
      key: 'GENERAL',
      content: <GeneralFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DECISIONS__DETAIL__PANEL_TITLE__DECISION"
          defaultMessage="Rozhodnutí"
        />
      ),
      key: 'DECISION',
      content: <DecisionFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DECISIONS__DETAIL__PANEL_TITLE__JUSTIFICATION"
          defaultMessage="Odůvodnění"
        />
      ),
      key: 'JUSTIFICATION',
      content: <JustificationFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DECISIONS__DETAIL__PANEL_TITLE__LEGAL_SENTENCE"
          defaultMessage="Právní věta"
        />
      ),
      key: 'LEGAL_SENTENCE',
      content: <LegalSentenceFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DECISIONS__DETAIL__PANEL_TITLE__ABSTRACT"
          defaultMessage="Abstrakt"
        />
      ),
      key: 'ABSTRACT',
      content: <AbstractFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DECISIONS__DETAIL__PANEL_TITLE__DIFFERENT_OPINION"
          defaultMessage="Odlišné stanovisko"
        />
      ),
      key: 'DIFFERENT_OPINION',
      content: <DifferentOpinionFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DECISIONS__DETAIL__PANEL_TITLE__ATTACHMENTS"
          defaultMessage="Přílohy"
        />
      ),
      key: 'ATTACHMENTS',
      content: <AttachmentFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DECISION__DETAIL__PANEL_TITLE__HISTORY"
          defaultMessage="Historie"
        />
      ),
      key: 'HISTORY',
      content: <HistoryField descriptors={historyDescriptors} />,
    },
  ];

  return <Tabs config={config} />;
}
