import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  ApiFilterOperation,
  AutocompleteSource,
  DomainObject,
  FilterComponentProps,
  useEventCallback,
} from '@eas/common-web';
import { FilterAutocompleteCell } from '@composite/filter/filter-cells/filter-autocomplete-cell';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { useNullableAutocompleteOperations } from '../filter-api';
import { useStyles } from '../filter-styles';
import { NullableFilterCell } from './nullable-filter-cell';

export function NullableAutocompleteFilterCell<TYPE extends DomainObject>(
  dataHook: () => AutocompleteSource<TYPE>,
  labelMapper?: (option: TYPE) => string,
  multiple = false
) {
  return useMemo(
    () =>
      function Cell({
        state,
        onChangeState,
        disabled,
        ...rest
      }: FilterComponentProps) {
        const classes = useStyles();

        const operations = useNullableAutocompleteOperations();

        const handleOperationChange = useEventCallback(
          (operation: ApiFilterOperation) => {
            if (
              [
                ApiFilterOperation.IS_NULL,
                ApiFilterOperation.NOT_NULL,
              ].includes(operation)
            ) {
              onChangeState({
                ...state,
                operation: operation,
                value: null,
                filters: undefined,
                object: undefined,
              });
            } else {
              onChangeState({
                ...state,
                operation: operation,
              });
            }
          }
        );

        const AutocompleteCell = FilterAutocompleteCell(
          dataHook,
          autocompleteLabelMapper,
          multiple
        );

        return (
          <div
            className={clsx(classes.root, classes.columnDirection, {
              [classes.disabled]: disabled,
            })}
          >
            <NullableFilterCell
              source={operations}
              value={state.operation}
              onChange={handleOperationChange as any}
              addMargin={
                ![
                  ApiFilterOperation.NOT_NULL,
                  ApiFilterOperation.IS_NULL,
                ].includes(state.operation)
              }
            />
            {state.operation === ApiFilterOperation.OR && (
              <AutocompleteCell
                {...rest}
                state={state}
                onChangeState={onChangeState}
                disabled={disabled}
              />
            )}
          </div>
        );
      },
    [dataHook]
  );
}
