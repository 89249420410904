export const PLENARY_OPINIONS = {
  title: 'Stanovisko pléna',
  description:
    'Stanovisko pléna Ústavního soudu je rozhodnutí, kterým se mění právní názor Ústavního soudu přijetý dříve. Nálezy jsou totiž závazné pro všechny orgány, včetně Ústavního soudu samotného a jeho senátů. Změní-li se právní, společenské nebo sociální podmínky, může se změnit i právní názor Ústavního soudu a formou změny tohoto dříve judikovaného názoru je přijetí stanoviska. Je to upraveno v § 23 zákona o ÚS.',
  actions: [
    {
      name: 'Vytvoření nového spisu stanoviska pléna',
      description: 'Vytvoření nového spisu s agendou <b>Stanovisko pléna</b>.',
    },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
    {
      name: 'Vyvést',
      description:
        'Při vyvedení z rejstříku je změněn <b>Způsob vyřízení</b> na <i>Vyvedeno z rejstříku</i>, jsou stornovány všechny aktivní úlohy a běžící lhůty spisu, spis je uzavřen. Akce je dostupná u spisů, které nejsou uzavřené a mají přidělenu spisovou značku.',
    },
    {
      name: 'Upravení spisu',
      description:
        'U spisů, které jsou ve stavu <b>Navrženo</b> má uživatel možnost upravovat jednotlivé atributy spisu.',
    },
  ],
};
