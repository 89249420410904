import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DictionaryEvidence,
  FormAutocomplete,
  FormTextArea,
  FormTextField,
  eqFilterParams,
} from '@eas/common-web';
import { useUserRoles } from '@modules/role/role-api';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryField } from '@composite/history/history-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Role } from '@enums';
import { useJudges } from '../judge/judge-api';

export function Fields() {
  const historyDescriptors = useCommonHistoryDescriptors();

  const judges = useJudges({
    filters: [{ field: 'function.id', operation: ApiFilterOperation.IS_NULL }],
  });

  const analystUsers = useUserRoles(
    eqFilterParams({ field: 'role.id', value: Role.ANALYST })
  );

  return (
    <>
      <FormTextField
        name="number"
        type="number"
        required
        label={
          <FormattedMessage
            id="ES__DICT_SENATES__DETAIL__FIELD_LABEL__NUMBER"
            defaultMessage="Číslo senátu"
          />
        }
        disabled={true}
      />
      <FormTextArea
        name="numberRoman"
        label={
          <FormattedMessage
            id="ES__DICT_SENATES__DETAIL__FIELD_LABEL__NUMBER_ROMAN"
            defaultMessage="Označení senátu"
          />
        }
        disabled={true}
      />
      <FormTextArea
        name="name"
        required
        label={
          <FormattedMessage
            id="ES__DICT_SENATES__DETAIL__FIELD_LABEL__NAME"
            defaultMessage="Název"
          />
        }
      />
      <FormAutocomplete
        name="chairman"
        label={
          <FormattedMessage
            id="ES__DICT_SENATES__DETAIL__FIELD_LABEL__CHAIRMAN"
            defaultMessage="Předseda senátu"
          />
        }
        source={judges}
        labelMapper={autocompleteLabelMapper}
      />
      <FormAutocomplete
        name="analystUserRole"
        label={
          <FormattedMessage
            id="ES__JUDGES__DETAIL__FIELD_LABEL__ANALYST"
            defaultMessage="Analytik - indexace (senátní spisy)"
          />
        }
        required
        source={analystUsers}
        labelMapper={autocompleteLabelMapper}
      />
      <DictionaryEvidence.FieldActive />
      <HistoryField
        descriptors={[
          ...historyDescriptors,
          { key: 'chairman', label: 'Předseda senátu' },
          { key: 'numberRoman', label: 'Označení senátu' },
        ]}
      />
    </>
  );
}
