import { useContext, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import {
  AbortableFetch,
  FormHandle,
  SnackbarContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { AuthContext } from '@components/auth/auth-context';
import { AuthType } from '@components/auth/auth-types';
import { LoginData } from '@models';
import { Messages } from '@enums';
import { useFormError } from './form-error-hook';
import { loginUser } from './login-api';

export function useLogin() {
  const { type, loginUrl } = useContext(AuthContext);

  const fetch = useRef<AbortableFetch | null>(null);
  const ref = useRef<FormHandle<LoginData>>(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const { setErrors, getFieldError } = useFormError();

  const validationSchema = Yup.object<LoginData>().shape({
    username: Yup.string()
      .nullable()
      .required('Uživatelské jméno musí být vyplněné'),
    password: Yup.string().nullable().required('Heslo musí být vyplněné'),
  });

  const handleSubmit = useEventCallback(async (data: LoginData) => {
    if (fetch.current !== null) {
      fetch.current.abort();
    }

    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      setErrors(errors);

      if (errors.length) {
        return;
      }

      fetch.current = loginUser(data.username, data.password);

      const response = await fetch.current.response;

      if (
        response.redirected &&
        response.ok &&
        !response.url.includes('public?error')
      ) {
        window.location.href = response.url;
      } else {
        const error =
          response.status !== 502 && response.status !== 503
            ? 'ERROR'
            : response.status;
        showSnackbar(...Messages.User.LOGIN[error]);
      }
    }
  });

  useEffect(() => {
    const login = async () => {
      const apiCall = abortableFetch(loginUrl!, { method: 'POST' });
      const response = await apiCall.raw();

      if (response.ok && response.redirected) {
        window.location.href = response.url;
      }
    };

    if (type === AuthType.KERBEROS && loginUrl) {
      login();
    }
  }, [type]);

  return { ref, handleSubmit, validationSchema, getFieldError };
}
