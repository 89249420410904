import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const commonDescriptors = useCommonHistoryDescriptors();

  return [
    ...commonDescriptors,
    {
      key: 'type',
      label: 'Typ subjektu',
    },
    {
      key: 'lastName',
      label: 'Příjmení',
    },
    {
      key: 'maidenName',
      label: 'Rodné příjmení',
    },
    {
      key: 'firstName',
      label: 'Jméno',
    },
    {
      key: 'titleBefore',
      label: 'Titul před',
    },
    {
      key: 'titleAfter',
      label: 'Titul za',
    },
    {
      key: 'birthDate',
      label: 'Datum narození',
    },
    {
      key: 'birthPlace',
      label: 'Místo narození',
    },
    {
      key: 'companyName',
      label: 'Název firmy/organizace',
    },
    {
      key: 'ico',
      label: 'IČO',
    },
    {
      key: 'otherIdentification',
      label: 'Jiná identifikace',
    },
    {
      key: 'function',
      label: 'Funkce',
    },
    {
      key: 'dataBoxes',
      label: 'Datové schránky',
    },
    {
      key: 'emails',
      label: 'Email',
    },

    {
      key: 'address.street',
      label: 'Adresa - ulice',
    },
    {
      key: 'address.descriptiveNumber',
      label: 'Adresa - číslo popisné',
    },
    {
      key: 'address.orientationNumber',
      label: 'Adresa - číslo orientační',
    },
    {
      key: 'address.city',
      label: 'Adresa - město',
    },
    {
      key: 'address.zipCode',
      label: 'Adresa - PSČ',
    },
    {
      key: 'address.country',
      label: 'Adresa - země',
    },
    {
      key: 'address.label',
      label: 'Adresa',
    },

    {
      key: 'deliveryAddress.street',
      label: 'Doručovací adresa - ulice',
    },
    {
      key: 'deliveryAddress.descriptiveNumber',
      label: 'Doručovací adresa - číslo popisné',
    },
    {
      key: 'deliveryAddress.orientationNumber',
      label: 'Doručovací adresa - číslo orientační',
    },
    {
      key: 'deliveryAddress.city',
      label: 'Doručovací adresa - město',
    },
    {
      key: 'deliveryAddress.zipCode',
      label: 'Doručovací adresa - PSČ',
    },
    {
      key: 'deliveryAddress.country',
      label: 'Doručovací adresa - země',
    },
    {
      key: 'deliveryAddress.label',
      label: 'Doručovací adresa',
    },
  ];
}
