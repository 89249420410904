import { noop, stubFalse } from 'lodash';
import React from 'react';
import { TableField } from '@eas/common-web';
import { useStyles } from '@modules/dashboard/search/result-table';
import { AvgProceedingDuration } from '@models';
import { ChartView } from '@enums';
import { useAvgProceedingDurationColumns } from './avg-proceeding-duration-columns';

export function useAvgProceedingDurationVisualisation({
  data = {},
}: {
  data?: AvgProceedingDuration;
}) {
  const Table = () => {
    const classes = useStyles();

    const columns = useAvgProceedingDurationColumns();
    const parsedData = parseData(data);

    return (
      <TableField
        value={parsedData}
        onChange={noop}
        columns={columns}
        showRadioCond={stubFalse}
        classes={classes}
        maxRows={4}
        showToolbar={false}
      />
    );
  };

  return {
    Visualisations: { [ChartView.TABLE]: Table },
  };
}

const parseData = (data: AvgProceedingDuration) => {
  const parsedData = [
    { proceeding: 'Senátní', count: data.avgSenate },
    { proceeding: 'Plenární', count: data.avgPlenary },
    { proceeding: 'Spisy vyřízené nálezem', count: data.avgFinding },
    {
      proceeding: 'Všechny soudní spisy (senátní + plenární)',
      count: data.avgAll,
    },
  ];

  return parsedData;
};
