import { format, parseISO } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  LocaleContext,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { useContactColumns } from '@modules/subject/subject-columns';
import { AccessoryParticipant, LegalEntity, NaturalPerson } from '@models';
import { SubjectType } from '@enums';

export function useAccessoryParticipantColumns(): TableFieldColumn<AccessoryParticipant>[] {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const { columnEmail, columnAddress, columnDataBoxId } = useContactColumns({
    datakeyPrefix: 'contact',
  });

  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: 'ES__ACCESSORY_PARTICIPANTS__COLUMN__NAME',
          defaultMessage: 'Příjmení a jméno / Název',
        }),
        datakey: 'contact.subject.name',
        width: 200,
      },
      {
        name: intl.formatMessage({
          id: 'ES__ACCESSORY_PARTICIPANTS__COLUMN__OTHER_IDENTIFICATION',
          defaultMessage: 'Jiná identifikace',
        }),
        datakey: 'contact.subject.otherIdentification',
        width: 200,
      },
      columnAddress,
      {
        name: intl.formatMessage({
          id: 'ES__ACCESSORY_PARTICIPANTS__COLUMN__YEAR_OR_ICO',
          defaultMessage: 'Rok narození / IČO',
        }),
        datakey: 'contact.subject.ico',
        width: 300,
        CellComponent: function Cell(props) {
          let value = '';
          const subject = props.rowValue.contact.subject;

          if (subject?.type !== SubjectType.NATURAL_PERSON) {
            value = (subject as LegalEntity).ico ?? '';
          } else if (subject?.type === SubjectType.NATURAL_PERSON) {
            const person = subject as NaturalPerson;
            value = person?.birthDate
              ? format(parseISO(person?.birthDate), locale.dateFormat)
              : '';
          }

          return <TableFieldCells.TextCell {...props} value={value} />;
        },
      },
      columnDataBoxId,
      columnEmail,
      {
        name: intl.formatMessage({
          id: 'ES__ACCESSORY_PARTICIPANTS__COLUMN__CONCERNED_AUTHORITY_TYPE',
          defaultMessage: 'Typ dotčeného orgánu',
        }),
        datakey: 'concernedAuthorityType.name',
        width: 200,
      },
    ],
    [intl, locale.dateFormat, columnDataBoxId, columnAddress, columnEmail]
  );
}
