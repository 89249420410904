import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useRevalidateContactDialog() {
  const callApi = useEventCallback((id: string, contactId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.SUBJECTS}/${id}/contact/${contactId}/active`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
      }
    )
  );

  const { source } = useContext(DetailContext);

  // TODO
  const showButton = source.data?.id;

  return {
    callApi,
    showButton,
  };
}
