import { findIndex, orderBy } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  EvidenceContext,
  FormPanel,
  TableFilter,
  useEventCallback,
} from '@eas/common-web';
import { FilterComponent } from './filter-component';
import { FilterState } from './filter-types';

const useStyles = makeStyles((theme) => ({
  filterColumn: {
    flex: '1 1 250px',
    backgroundColor: '#f1f3f4',
    paddingBottom: 10,
  },
  addButton: {
    margin: 10,
    boxShadow: '0 0 0',
    // width: 32,
    height: '32px !important',
    minHeight: '32px !important',
    padding: '0 15px !important',

    '&:active': {
      boxShadow: '0 0 0',
    },
  },
  addButtonIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
}));

export function FilterColumn({
  filterGroup,
  filters,
  setFilters,
  label,
  initVisibility,
}: {
  filterGroup: TableFilter[];
  filters: FilterState[];
  setFilters: Dispatch<SetStateAction<FilterState[]>>;
  label: string;
  initVisibility?: string[];
}) {
  const classes = useStyles();
  const { tableRef } = useContext(EvidenceContext);

  const [visibleFilters, setVisibleFilters] = useState<{
    [key: string]: boolean;
  }>({});

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShow = (filter: TableFilter) => {
    setVisibleFilters((rest) => ({
      ...rest,
      [filter.datakey]: !visibleFilters[filter.datakey],
    }));
  };

  const showVisibleFilters = useEventCallback((initVisibility?: string[]) => {
    if (initVisibility && initVisibility?.length > 0) {
      const init = initVisibility.reduce((a, v) => ({ ...a, [v]: true }), {});

      setVisibleFilters(init);
    } else {
      const appliedFilters = filters.filter((f) => !!f.value);
      const init = appliedFilters.reduce(
        (a, v) => ({ ...a, [v.id!]: true }),
        visibleFilters
      );

      setVisibleFilters(init);
    }
  });

  /**
   * Changes the value of the filter.
   */
  const handleFilterValueChange = useEventCallback(
    (index: number, value: any) => {
      setFilters((filters) => [
        ...filters.slice(0, index),
        { ...filters[index], visible: true, value },
        ...filters.slice(index + 1),
      ]);
    }
  );

  /**
   * Changes the state of the filter.
   */
  const handleFilterStateChange = useEventCallback(
    (index: number, state: FilterState) => {
      setFilters((filters) => [
        ...filters.slice(0, index),
        state,
        ...filters.slice(index + 1),
      ]);
    }
  );

  useEffect(() => {
    showVisibleFilters();
  }, [filters]);

  useEffect(() => {
    showVisibleFilters(initVisibility);
  }, []);

  return (
    <div className={classes.filterColumn}>
      <FormPanel label={label} bottomBorder={false}>
        <div style={{ width: '100%' }}>
          <Fab
            size="small"
            color="default"
            variant="extended"
            onClick={handleClick}
            className={classes.addButton}
          >
            <FilterListIcon className={classes.addButtonIcon} />
            filtry
          </Fab>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          elevation={3}
          variant={'menu'}
        >
          {orderBy(filterGroup, 'filterOrder').map((filter) => (
            <MenuItem
              key={filter.datakey}
              onClick={() => handleShow(filter)}
              selected={visibleFilters[filter.datakey]}
            >
              {filter.label}
            </MenuItem>
          ))}
        </Menu>
        {orderBy(filterGroup, 'filterOrder').map((filter) => {
          const index = findIndex(
            tableRef.current?.filters,
            (f) => f.datakey === filter.datakey
          );
          const state = filters[index];

          return (
            <FilterComponent
              key={index}
              filter={filter}
              state={state ?? {}}
              onChangeValue={(value) => handleFilterValueChange(index, value)}
              onChangeFilterState={(state) =>
                handleFilterStateChange(index, state)
              }
              visible={visibleFilters[filter.datakey]}
            />
          );
        })}
      </FormPanel>
    </div>
  );
}
