import { useContext } from 'react';
import { UserContext, useEventCallback } from '@eas/common-web';
import { DeliveredDocument, Me } from '@models';
import { DeliveredDocumentState, Role } from '@enums';
import { deliveredDocumentAccessMap } from './../document-delivered/delivered-document-access-map';
import { internalDocumentAccessMap } from './../document-internal/internal-document-access-map';
import { outgoingDocumentAccessMap } from './../document-outgoing/outgoing-document-access-map';

export const DOCUMENTS_ACCESS = [
  Role.MAILROOM, // Podatelna/výpravna
  Role.LUSTRUM, // Lustrum
  Role.HEAD_OSV, // Vedoucí OSV
  Role.INDEX, // Rejstřík
  Role.LAWYER, // Právník
  Role.SECRETARY, // Tajemnice
  Role.JUDGE, // Soudce
  Role.ASSISTANT, // Asistent
  Role.ANALYST, // Analytik
  Role.FILE_ROOM, // Spisovna
];

export const OUTGOING_DOCUMENTS_ACCESS = [
  ...DOCUMENTS_ACCESS,
  Role.SOLVER, // Řešitel
];

export const PROCESSED_DOCUMENTS_ACCESS = [
  ...DOCUMENTS_ACCESS,
  Role.SOLVER, // Řešitel
];

export const UNPROCESSED_DELIVERED_DOCUMENTS_ACCESS = [
  Role.MAILROOM, // Podatelna/výpravna
  Role.LUSTRUM, // Lustrum
  Role.HEAD_OSV, // Vedoucí OSV
  Role.INDEX, // Rejstřík
];

function usePermission(accessMap: Map<Role, string[]>) {
  const { user } = useContext<UserContext<Me>>(UserContext);

  const hasPermission = useEventCallback((element: string) => {
    const permittedElements = new Set<string>();

    // flat permitted elements into Set
    user?.userRoles.forEach(({ role }) => {
      const permittedForRole = accessMap.get(role!) ?? [];
      permittedForRole?.forEach((element) => permittedElements.add(element));
    });

    return permittedElements.has(element);
  });

  return { hasPermission };
}

export function useDeliveredDocumentPermission(
  document: DeliveredDocument | null
) {
  return usePermission(
    deliveredDocumentAccessMap.get(
      (document as DeliveredDocument)?.processed
        ? DeliveredDocumentState.PROCESSED
        : DeliveredDocumentState.UNPROCESSED
    ) as Map<Role, string[]>
  );
}

export const useInternalDocumentPermission = () =>
  usePermission(internalDocumentAccessMap);

export const useOutgoingDocumentPermission = () =>
  usePermission(outgoingDocumentAccessMap);
