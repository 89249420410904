import {
  ApiFilterOperation,
  HookedSource,
  useScrollableSource,
} from '@eas/common-web';
import { Role } from '@models';
import { EvidenceApiUrl } from '@enums';

export const USER_ROLE_SOURCE = 'USER_ROLE_SOURCE';

function useUserRoleSource(): HookedSource<Role> {
  const source = useScrollableSource({
    url: `${EvidenceApiUrl.USERS}/role/list`,
  });

  return {
    [USER_ROLE_SOURCE]: {
      source,
      shouldLoad: () => true,
      modifyBeforeLoad: ({ id }) => {
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'role.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useRoleHookedSources(): HookedSource<Role> {
  const userRoles = useUserRoleSource();

  return Object.assign(userRoles);
}
