/**
 * Způsob vypravení
 */
export enum DispatchMethod {
  /**
   * Listovní zásilka
   */
  LETTER = 'LETTER',

  /**
   * E-mail
   */
  EMAIL = 'EMAIL',

  /**
   * Datová zpráva
   */
  DATA_BOX = 'DATA_BOX',
}

/**
 * Způsob doručení vypravení
 */
export enum DispatchDeliveryMethod {
  /**
   * Doručeno listině
   */
  LETTER = 'LETTER',

  /**
   * Doručeno emailem
   */
  EMAIL = 'EMAIL',

  /**
   * Doručeno do DS
   */
  DATA_BOX = 'DATA_BOX',

  /**
   * Doručeno úřední deskou
   */
  NOTICE_BOARD = 'NOTICE_BOARD',
}

/**
 * Stav vypravení
 */
export enum DispatchState {
  /**
   * Připraveno (= NULL)
   *
   * Client describes this state as NULL state in their terminology
   */
  PREPARED = 'PREPARED',

  /**
   * Neodesláno
   */
  NOT_SENT = 'NOT_SENT',

  /**
   * Odesláno
   */
  SENT = 'SENT',

  /**
   * Vypraveno
   */
  DISPATCHED = 'DISPATCHED',

  /**
   * Doručeno
   */
  DELIVERED = 'DELIVERED',

  /**
   * Nedoručeno
   */
  NOT_DELIVERED = 'NOT_DELIVERED',
}

/**
 * Typ obálky
 */
export enum DispatchEnvelopeType {
  /**
   * Obálka - úzká
   */
  SMALL = 'SMALL',

  /**
   * Obálka - zelený pruh
   */
  GREEN_LINE = 'GREEN_LINE',
}

/**
 * Zdroj adresáta
 */
export enum RecipientSource {
  /**
   * Adresář
   */
  DIRECTORY = 'DIRECTORY',

  /**
   * Účastníci
   */
  PARTICIPANTS = 'PARTICIPANTS',
}
