import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import HistoryIcon from '@material-ui/icons/History';
import SendIcon from '@material-ui/icons/Send';
import {
  EvidenceContext,
  SnackbarContext,
  TableSelectedContext,
} from '@eas/common-web';
import { TableActionButton } from '@components/action-button/table-action-button';
import { ErrorObject } from '@models';
import { Messages } from '@enums';
import { dispatchDocumentsApiCall } from './dialog-actions/dispatch-document-hook';
import { returnDocumentsApiCall } from './dialog-actions/return-document-hook';

export function ElectronicDocumentDispatchToolbar() {
  const { detailRef, tableRef } = useContext(EvidenceContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const intl = useIntl();
  const { selected } = useContext(TableSelectedContext);

  return (
    <>
      <TableActionButton
        promptKey="DISPATCH_DOCUMENTS"
        apiCall={(_, selected) =>
          dispatchDocumentsApiCall(selected.length ? selected : undefined)
        }
        buttonDisabled={selected.length === 0}
        buttonLabel={<SendIcon />}
        buttonTooltip={intl.formatMessage({
          id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TOOLBAR__DISPATCH_DOCUMENTS',
          defaultMessage: 'Vypravit elektronické dokumenty',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DIALOG_DISPATCH_DOCUMENTS__TITLE',
          defaultMessage: 'Vypravení elektronických dokumentů',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DIALOG_DISPATCH_DOCUMENTS__TEXT',
          defaultMessage:
            'Opravdu chcete vypravit vybrané elektronické dokumenty?',
        })}
        onError={async (err: ErrorObject<'WRONG_DISPATCH_STATE'>) => {
          if (err.code) {
            const error =
              Messages.Dispatch.DISPATCH?.[err.code] ??
              Messages.Dispatch.DISPATCH.ERROR;
            showSnackbar(...error);
          }
        }}
        onSuccess={async () => {
          detailRef.current?.refreshAll();
          tableRef.current?.refresh();
          tableRef.current?.resetSelection();
        }}
      />
      <TableActionButton
        promptKey="RETURN_DOCUMENTS"
        apiCall={(_, selected) =>
          returnDocumentsApiCall(selected.length ? selected : undefined)
        }
        buttonDisabled={selected.length === 0}
        buttonLabel={<HistoryIcon />}
        buttonTooltip={intl.formatMessage({
          id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TOOLBAR__RETURN_DOCUMENTS',
          defaultMessage: 'Vrátit vypravení ke zpracování',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DIALOG_RETURN_DOCUMENTS__TITLE',
          defaultMessage: 'Vrácení vypravení ke zpracování',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__DIALOG_RETURN_DOCUMENTS__TEXT',
          defaultMessage:
            'Opravdu chcete vrátit vybraná vypravení ke zpracování?',
        })}
        onError={async (err: ErrorObject<'WRONG_DISPATCH_STATE'>) => {
          if (err.code) {
            const error =
              Messages.Dispatch.RETURN?.[err.code] ??
              Messages.Dispatch.RETURN.ERROR;
            showSnackbar(...error);
          }
        }}
      />
    </>
  );
}
