import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  EvidenceContext,
  PrimaryDetailActionbarButton,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';

export function useMakeDefaultDialog() {
  const { apiUrl } = useContext(EvidenceContext);

  const callApi = useEventCallback((id: string) => {
    return abortableFetch(`${apiUrl}/${id}/make-default`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  });

  return {
    callApi,
  };
}

export function MakeDefaultButton() {
  const intl = useIntl();
  const { callApi } = useMakeDefaultDialog();

  return (
    <DetailActionButton
      promptKey="MAKE_DEFAULT"
      apiCall={callApi}
      buttonLabel={intl.formatMessage({
        id: 'ES__DICTIONARY_EVIDENCE__MAKE_DEFAULT',
        defaultMessage: 'Nastavit jako výchozí',
      })}
      dialogTitle={intl.formatMessage({
        id: 'ES__DICTIONARY_EVIDENCE__MAKE_DEFAULT__TITLE',
        defaultMessage: 'Změna výchozí hodnoty',
      })}
      dialogText={intl.formatMessage({
        id: 'ES__DICTIONARY_EVIDENCE__MAKE_DEFAULT__TEXT',
        defaultMessage: 'Opravdu si přejete změnit výchozí hodnotu číselníku?',
      })}
      ButtonComponent={PrimaryDetailActionbarButton}
    />
  );
}
