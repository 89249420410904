import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DictBriefSummary } from '@models';

export function useColumns(): TableColumn<DictBriefSummary>[] {
  const intl = useIntl();
  const { agendas } = useContext(StaticDataContext);
  const {
    columnOrder,
    columnActive,
    columnName,
  } = DictionaryEvidence.useDictionaryColumns<DictBriefSummary>();
  const useAgendas = () => useStaticListSource(agendas);

  return [
    columnName,
    {
      datakey: 'agenda',
      sortkey: 'agenda.name',
      filterkey: 'agenda.id',
      name: intl.formatMessage({
        id: 'ES__DICT_BRIEF_SUMMARIES__TABLE__COLUMN__AGENDA',
        defaultMessage: 'Agenda',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useAgendas),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useAgendas),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    columnOrder,
    columnActive,
  ];
}
