import { getErrorMessage } from '@utils/error-message';
import { isEqual, isNil, omitBy, pick } from 'lodash';
import { useContext, useRef } from 'react';
import {
  AbortableFetch,
  DetailContext,
  RemoteTableFieldContext,
  SnackbarContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { callApi as editContactApiCall } from '@composite/contact/dialog-actions/edit-contact-hook';
import { Dispatch, ErrorObject } from '@models';
import { DataBoxType, EvidenceApiUrl, Messages, SubjectType } from '@enums';
import { MessageType } from '../../../enums/messages/message-type';

export function useAddDispatchDialog() {
  const { source: detailSource } = useContext(DetailContext);

  const callApi = useEventCallback((formData: Dispatch) => {
    return abortableFetch(EvidenceApiUrl.DOCUMENTS_DISPATCHES, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        document: { id: detailSource?.data.id },
        ...formData,
        recipient: formData.recipient,
      }),
    });
  });

  const { handleSubmit } = useSubmitDispatchChange(
    callApi,
    Messages.Dispatch.CREATE
  );

  return {
    handleSubmit,
  };
}

export function useSubmitDispatchChange(
  callApi: (formData: Dispatch) => AbortableFetch,
  Message: MessageType<'SUCCESS' | 'ERROR' | 'CONTACT_CREATED'>
) {
  const fetch = useRef<AbortableFetch | null>(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const { refreshAll } = useContext(DetailContext);

  const handleSubmit = useEventCallback(async (dispatch: Dispatch) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      const originalContact = extractContactProperties(
        dispatch.recipient.originalContact
      );
      const newContact = extractContactProperties(dispatch.recipient);
      const wasContactModified = !isEqual(originalContact, newContact);

      if (wasContactModified) {
        const { email, dataBoxId, ...address } = newContact;

        fetch.current = editContactApiCall(
          dispatch.recipient.contact!.subject!.id,
          {
            ...dispatch.recipient.contact!,
            dataBox: dataBoxId
              ? {
                  identifier: dataBoxId,
                  type: deriveDataBoxType(dispatch.recipient.subjectType!),
                }
              : undefined,
            email,
            address,
          }
        );

        await fetch.current.raw();
      }

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(dispatch);
      await fetch?.current?.json();

      tableSource?.reset();
      tableSource?.loadMore();
      showSnackbar(
        ...(wasContactModified ? Message.CONTACT_CREATED : Message.SUCCESS)
      );
      refreshAll();
    } catch (e) {
      const err = e as ErrorObject<string>;

      const message = getErrorMessage(err);

      if (err.name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  return { handleSubmit };
}

export const extractContactProperties = (object: any) =>
  pick(omitBy(object, isNil), [
    'email',
    'dataBoxId',
    'street',
    'country',
    'city',
    'orientationNumber',
    'descriptiveNumber',
    'zipCode',
  ]);

const deriveDataBoxType = (subjectType: SubjectType) => {
  switch (subjectType) {
    case SubjectType.BUSINESS_NATURAL_PERSON:
      return DataBoxType.BUSINESS_NATURAL_PERSON;
    case SubjectType.LEGAL_ENTITY:
      return DataBoxType.LEGAL_ENTITY;
    case SubjectType.NATURAL_PERSON:
      return DataBoxType.NATURAL_PERSON;
  }
};
