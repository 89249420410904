import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  FormEditor,
  FormPanel,
  FormSelect,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';

export function Fields() {
  const { restServices } = useContext(StaticDataContext);

  const restServicesSource = useStaticListSource(restServices);

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__REST_MESSAGES__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Obecné"
        />
      }
      expandable={false}
    >
      <FormSelect
        name="service"
        label={
          <FormattedMessage
            id="ES__REST_MESSAGES__DETAIL__FIELD_LABEL__SERVICE"
            defaultMessage="Název služby"
          />
        }
        source={restServicesSource}
        valueIsId
      />
      <FormTextField
        name="method"
        label={
          <FormattedMessage
            id="ES__REST_MESSAGES__DETAIL__FIELD_LABEL__METHOD"
            defaultMessage="HTTP metoda"
          />
        }
      />
      <FormTextField
        name="path"
        label={
          <FormattedMessage
            id="ES__REST_MESSAGES__DETAIL__FIELD_LABEL__PATH"
            defaultMessage="URL"
          />
        }
      />
      <FormTextField
        name="duration"
        label={
          <FormattedMessage
            id="ES__REST_MESSAGES__DETAIL__FIELD_LABEL__DURATION"
            defaultMessage="Délka trvání operace"
          />
        }
      />
      <FormTextField
        name="httpStatus"
        label={
          <FormattedMessage
            id="ES__REST_MESSAGES__DETAIL__FIELD_LABEL__HTTP_STATUS"
            defaultMessage="HTTP status"
          />
        }
      />
      <FormEditor
        name="request"
        label={
          <FormattedMessage
            id="ES__REST_MESSAGES__DETAIL__FIELD_LABEL__REQUEST"
            defaultMessage="Požadavek"
          />
        }
        language="json"
      />
      <FormEditor
        name="response"
        label={
          <FormattedMessage
            id="ES__REST_MESSAGES__DETAIL__FIELD_LABEL__RESPONSE"
            defaultMessage="Odpověď"
          />
        }
        language="json"
      />
      <FormTextField
        name="error"
        label={
          <FormattedMessage
            id="ES__REST_MESSAGES__DETAIL__FIELD_LABEL__ERROR"
            defaultMessage="Chyba"
          />
        }
      />
      <AuthoredEvidence.AuthoredFields />
    </FormPanel>
  );
}
