enum Element {
  /**
   * Spisová značka
   */
  FILE_NUMBER = 'FILE_NUMBER',

  /**
   * Čárový kód
   */
  BAR_CODE = 'BAR_CODE',

  /**
   * Datum vytvoření
   */
  CREATED = 'CREATED',

  /**
   * Datum doručení
   */
  DELIVERY_DATE = 'DELIVERY_DATE',

  /**
   * Došlé číslo
   */
  INCOMING_NUMBER = 'INCOMING_NUMBER',

  /**
   * Agenda
   */
  AGENDA = 'AGENDA',

  /**
   * Stav
   */
  STATE = 'STATE',

  /**
   * Obsah podání
   */
  SUBMISSION_CONTENT = 'SUBMISSION_CONTENT',

  /**
   * Vedoucí rejstříku
   */
  INDEX_USER_ROLE = 'INDEX_USER_ROLE',

  /**
   * Řešitelé
   */
  SOLVER_USER_ROLES = 'SOLVER_USER_ROLES',

  /**
   * Způsob vyřízení
   */
  SETTLEMENT_METHODS = 'SETTLEMENT_METHODS',

  /**
   * Datum vyřízení
   */
  SETTLEMENT_DATE = 'SETTLEMENT_DATE',

  /**
   * Datum uzavření
   */
  CLOSING_DATE = 'CLOSING_DATE',

  /**
   * Poznámky
   */
  NOTES = 'NOTES',

  /**
   * Pisatelé
   */
  WRITERS = 'WRITERS',

  /**
   * Úlohy
   */
  TASKS_AND_DEADLINES = 'TASKS_AND_DEADLINES',

  /**
   * Běžící lhůty
   */
  RUNNING_DEADLINES = 'RUNNING_DEADLINES',

  /**
   * Koncepty
   */
  DRAFTS = 'DRAFTS',

  /**
   * AKCE: Nový spis
   */
  CREATE_SPR_FILE_BUTTON = 'CREATE_SPR_FILE_BUTTON',

  /**
   * AKCE: Upravit spis
   */
  UPDATE_SPR_FILE_BUTTON = 'UPDATE_SPR_FILE_BUTTON',

  /**
   * AKCE: Smazat spis
   */
  DELETE_SPR_FILE_BUTTON = 'DELETE_SPR_FILE_BUTTON',

  /**
   * AKCE: Změnit agendu
   */
  UPDATE_SPR_FILE_AGENDA_BUTTON = 'UPDATE_SPR_FILE_AGENDA_BUTTON',

  /**
   * AKCE: Změnit stav
   */
  UPDATE_SPR_FILE_STATE_BUTTON = 'UPDATE_SPR_FILE_STATE_BUTTON',

  /**
   * AKCE: Obal spisu
   */
  CREATE_FILE_COVER_BUTTON = 'CREATE_FILE_COVER_BUTTON',

  /**
   * AKCE: Znovu otevřít
   */
  REOPEN_SPR_FILE_BUTTON = 'REOPEN_SPR_FILE_BUTTON',
}

export default Element;
