import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { green, red } from '@material-ui/core/colors';
import {
  ApiFilterOperation,
  EmptyComponent,
  Evidence,
  UserContext,
} from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import { Task } from '@models';
import {
  EntityName,
  EsFileState,
  EvidenceApiUrl,
  Role,
  TaskState,
} from '@enums';
import { ActionBar } from './task-actionbar';
import { useColumns } from './task-columns';
import { Fields } from './task-fields';
import { useValidationSchema } from './task-schema';

export const createRowStyle = (deadline: Task) => {
  switch (deadline.state) {
    case TaskState.PROCESSED:
      return {
        color: green[500],
        backgroundColor: green[50],
      };
    case TaskState.CANCELED:
      return {
        color: red[500],
        backgroundColor: red[50],
      };
    default:
      return {};
  }
};

export const TASK_IDENTIFIER = 'TASKS';
export const TASK_VERSION = 13;

export function Tasks() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const { user, hasPermission } = useContext(UserContext);

  const evidence = useAuthoredEvidence<Task>({
    version: TASK_VERSION,
    identifier: TASK_IDENTIFIER,
    apiProps: {
      url: EvidenceApiUrl.TASKS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__TASKS__TABLE__TITLE',
        defaultMessage: 'Úlohy',
      }),
      defaultSorts: [],
      createRowStyle: createRowStyle,
      showReportButton: false,
      defaultPreFilters: hasPermission(Role.ADMIN)
        ? []
        : [
            {
              field: 'solver.user.id',
              value: user.id,
              operation: ApiFilterOperation.EQ,
            },
          ],
    },
    detailProps: {
      entityName: EntityName.TASK,
      validationSchema,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      lock: (item) =>
        item?.esFile?.state === EsFileState.CLOSED ||
        item?.esFile?.state === EsFileState.REGISTRY ||
        item?.state == TaskState.PROCESSED ||
        item?.state == TaskState.CANCELED,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__TASKS__DETAIL__TITLE',
          defaultMessage: 'Úloha',
        }),
        showBtn: (btn) => !['NEW', 'REMOVE'].includes(btn),
        disableBtn: (button) => button === 'EDIT' && !hasPermission(Role.ADMIN),
        subtitle: (task) => task?.name ?? '',
        ActionBar,
      },
    },
  });
  return <Evidence {...evidence} />;
}
