import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  EvidenceStateAction,
  NavigationContext,
  Tooltip,
} from '@eas/common-web';

export function Redirect({
  url,
  id,
  showRedirectInCurrent = true,
  showRedirectInNew = true,
}: {
  url: string | ((id: string) => string);
  id: string;
  showRedirectInCurrent?: boolean;
  showRedirectInNew?: boolean;
}) {
  const { navigate } = useContext(NavigationContext);

  const to = typeof url === 'function' ? url(id) : `${url}/${id}`;

  return (
    <>
      {showRedirectInCurrent && (
        <Tooltip
          title={
            <FormattedMessage
              id="ES__REDIRECT__OPEN_IN_CURRENT"
              defaultMessage="Otevře v aktuální kartě"
            />
          }
        >
          <OpenInBrowserIcon
            fontSize="small"
            color="primary"
            style={{ cursor: 'pointer', marginRight: 10 }}
            onClick={() =>
              navigate(to, false, {
                action: EvidenceStateAction.SHOW_ITEM,
                data: id,
              })
            }
          />
        </Tooltip>
      )}

      {showRedirectInNew && (
        <Tooltip
          title={
            <FormattedMessage
              id="ES__REDIRECT__OPEN_IN_NEW"
              defaultMessage="Otevře na nové kartě"
            />
          }
        >
          <OpenInNewIcon
            fontSize="small"
            color="primary"
            style={{ cursor: 'pointer', marginRight: 10 }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(to);
            }}
          />
        </Tooltip>
      )}
    </>
  );
}
