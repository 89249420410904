import React, { ChangeEvent, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MuiMenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Tooltip, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { useDownloadReport } from '../actions/download-report-hook';
import { deriveQueryParams } from '../statistics-utils';

const useStyles = makeStyles({
  select: {
    height: 30,
  },
  actions: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: 24,
    gap: '10px',

    '& svg': {
      height: 22,
    },
  },
});

export function Export<PARAMS>({
  url,
  params,
  supportedExportTypes = [],
}: {
  url: EvidenceApiUrl;
  params?: PARAMS;
  supportedExportTypes?: { id: string; name: string }[];
}) {
  const classes = useStyles();

  const [exportType, setExportType] = useState<string>('PDF');

  const { callApi } = useDownloadReport();

  const handleChange = useEventCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) => {
      setExportType(e.target.value as string);
    }
  );

  return (
    <div className={classes.actions}>
      <FormControl size="small">
        <Select
          className={classes.select}
          onChange={handleChange}
          value={exportType}
          variant="outlined"
        >
          {supportedExportTypes.map((option) => (
            <MuiMenuItem key={option.id} value={option.id}>
              {option.name}
            </MuiMenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip title="Stáhnout report">
        <IconButton
          size="small"
          onClick={() =>
            callApi(
              `${url}/report${deriveQueryParams({ ...params, exportType })}`
            )
          }
        >
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
