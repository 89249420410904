import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistoryDescriptors } from '@modules/es-file/es-file-history-descriptors';
import { HistoryField } from '@composite/history/history-field';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { DocumentFields } from './fields/documents/documents';
import { DraftsFields } from './fields/drafts/drafts';
import { GeneralFields } from './fields/general/general';
import { SimilarFilesFields } from './fields/similar-files/similar-files';
import { TasksAndDeadlinesFields } from './fields/tasks-and-deadlines/tasks-and-deadlines';
import { WritersFields } from './fields/writers/writers';

export function Fields() {
  const historyDescriptors = useHistoryDescriptors();

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ES__INF_FILES__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Obecné"
        />
      ),
      key: 'GENERAL',
      validationKeys: ['agenda', 'deliveryDate', 'incomingNumber'],
      content: <GeneralFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__INF_FILES__DETAIL__PANEL_TITLE__DOCUMENTS"
          defaultMessage="Dokumenty"
        />
      ),
      key: 'DOCUMENTS',
      content: <DocumentFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__INF_FILES__DETAIL__PANEL_TITLE__WRITERS"
          defaultMessage="Pisatelé"
        />
      ),
      key: 'WRITERS',
      content: <WritersFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__INF_FILES__DETAIL__PANEL_TITLE__SIMILAR_FILES"
          defaultMessage="Obdobné spisy"
        />
      ),
      key: 'SIMILAR_FILES',
      content: <SimilarFilesFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__INF_FILES__DETAIL__PANEL_TITLE__TASKS_AND_DEADLINES"
          defaultMessage="Úlohy a lhůty"
        />
      ),
      key: 'TASKS_AND_DEADLINES',
      content: <TasksAndDeadlinesFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__INF_FILES__DETAIL__PANEL_TITLE__DRAFTS"
          defaultMessage="Koncepty"
        />
      ),
      key: 'Drafts',
      content: <DraftsFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__INF_FILES__DETAIL__PANEL_TITLE__HISTORY"
          defaultMessage="Historie"
        />
      ),
      key: 'HISTORY',
      content: <HistoryField descriptors={historyDescriptors} />,
    },
  ];

  return <Tabs config={config} />;
}
