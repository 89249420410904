import clsx from 'clsx';
import React from 'react';
import { ApiFilterOperation, ListSource, Select } from '@eas/common-web';
import { Operation } from '../filter-api';
import { useStyles } from '../filter-styles';

export function NullableFilterCell({
  source,
  onChange,
  value,
  addMargin = false,
}: {
  source: ListSource<Operation>;
  onChange: (value: string | string[] | Operation | Operation[] | null) => void;
  value?: ApiFilterOperation | 'DEFAULT';
  addMargin?: boolean;
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.filledCellWrapper, {
        [classes.marginBottom]: addMargin,
      })}
    >
      <Select
        value={value}
        valueIsId={true}
        source={source}
        onChange={onChange}
        clearable={false}
      />
    </div>
  );
}
