import { isBefore } from 'date-fns';
import * as Yup from 'yup';
import {
  CourtFile,
  DictSenate,
  DictSettlementMethodAutocomplete,
  EsFile,
  Judge,
} from '@models';
import { Agenda, CourtFileType, DisciplinaryProceedingsProposer } from '@enums';

export function useValidationSchema() {
  return Yup.object<EsFile>().shape({
    settlementMethods: validateSettlementMethods,
    deliveryDate: validateDeliveryDate,
    statsDate: validateStatsDate,
    type: validateType,
    incomingNumber: validateIncomingNumber,
    proceedingDuration: validateProceedingDuration,
    submissionContent: validateSubmissionContent,
    participant: validateParticipant,
    proposer: validateProposer,
    sourceFiles: validateSourceFiles,
    senate: validateSenate,
    decisionDate: validateDecisionDate,
  });
}

const validateSettlementMethods =
  Yup.array<DictSettlementMethodAutocomplete>().when('agenda', {
    is: Agenda.COURT,
    then: Yup.array<DictSettlementMethodAutocomplete>()
      .nullable()
      .min(1, 'Povinné pole')
      .required(),
  });

const validateDeliveryDate = Yup.date().when('agenda', {
  is: (agenda) => [Agenda.COURT, Agenda.INF, Agenda.SPR].includes(agenda),
  then: Yup.date()
    .test('', '', function (dateValue) {
      const { createError, path } = this;
      const maxDate = new Date();

      if (dateValue && isBefore(maxDate, dateValue)) {
        return createError({
          path,
          message: 'Datum nesmí být v budoucnosti',
        });
      }
      return true;
    })
    .nullable()
    .required('Povinné pole'),
});

const validateStatsDate = Yup.date().when('agenda', {
  is: Agenda.COURT,
  then: Yup.date().nullable().required('Povinné pole'),
});

const validateType = Yup.mixed<CourtFileType>().when('agenda', {
  is: Agenda.COURT,
  then: Yup.mixed<CourtFileType>().nullable().required('Povinné pole'),
});

const validateIncomingNumber = Yup.string().when('agenda', {
  is: (agenda) => [Agenda.COURT, Agenda.INF, Agenda.SPR].includes(agenda),
  then: Yup.string().nullable().required('Povinné pole'),
});

const validateProceedingDuration = Yup.number().when('agenda', {
  is: Agenda.COURT,
  then: Yup.number()
    .test('', '', function (proceedingDuration) {
      const { createError, path } = this;

      if (proceedingDuration && proceedingDuration < 0) {
        return createError({
          path,
          message: 'Počet dnů nesmí být záporné číslo.',
        });
      }
      return true;
    })
    .nullable(),
});

const validateSubmissionContent = Yup.string().when('agenda', {
  is: Agenda.SPR,
  then: Yup.string().nullable().required('Povinné pole'),
});

const validateParticipant = Yup.mixed<Judge>().when('agenda', {
  is: Agenda.DISCIPLINARY_PROCEEDINGS,
  then: Yup.mixed<Judge>().nullable().required('Povinné pole'),
});

const validateProposer = Yup.mixed<DisciplinaryProceedingsProposer>().when(
  'agenda',
  {
    is: Agenda.DISCIPLINARY_PROCEEDINGS,
    then: Yup.mixed<DisciplinaryProceedingsProposer>()
      .nullable()
      .required('Povinné pole'),
  }
);

const validateSourceFiles = Yup.array<CourtFile>().when('agenda', {
  is: Agenda.PLENARY_OPINION,
  then: Yup.array<CourtFile>()
    .test('', '', function (value) {
      const { createError, path } = this;

      const sourceFiles: CourtFile[] = value ?? [];
      const challengedFiles: CourtFile[] = this?.parent?.challengedFiles ?? [];

      const intersection = sourceFiles.filter((sourceFile) => {
        return challengedFiles.some(
          (challengedFile) => challengedFile.id === sourceFile.id
        );
      });

      if (intersection.length) {
        return createError({
          path,
          message:
            'Žádná ze zdrojových spisových značek nesmí být mezi napadenými spisovými značkami',
        });
      }
      return true;
    })
    .nullable(),
});

const validateSenate = Yup.mixed<DictSenate>().when('agenda', {
  is: Agenda.PLENARY_OPINION,
  then: Yup.mixed<DictSenate>().nullable().required('Povinné pole'),
});

const validateDecisionDate = Yup.string().test('', '', function (value) {
  const { path, createError } = this;

  if (value && new Date(value).getDate() > new Date().getDate()) {
    return createError({ path, message: 'Nesmí být v budoucnosti' });
  }

  return true;
});
