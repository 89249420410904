import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  FormAutocomplete,
  FormCheckbox,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  eqFilterParams,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Document } from '@models';
import { Agenda, UIElement } from '@enums';
import { useBriefSummaries } from '../../../dict-brief-summary/dict-brief-summary-api';
import { useInternalDocumentPermission } from '../../../document/document-permission';
import { EsFileFields } from '../../../document/fields/es-file/es-file';

export function InternalDocumentFields() {
  const detailCtx = useContext(DetailContext);
  const { agendas } = useContext(StaticDataContext);

  const agendaSource = useStaticListSource(agendas);
  const { agenda } = useFormSelector((data: Document) => ({
    agenda: data.agenda,
  }));

  const briefSummaries = useBriefSummaries(
    agenda ? eqFilterParams({ field: 'agenda.id', value: agenda }) : {}
  );

  /**
   * Permissions
   */
  const { hasPermission } = useInternalDocumentPermission();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_INTERNAL__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Obecné"
          />
        }
      >
        <FormTextField
          name="referenceNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__REFERENCE_NUMBER"
              defaultMessage="Číslo jednací"
            />
          }
          disabled={!hasPermission(UIElement.InternalDocument.REFERENCE_NUMBER)}
        />

        <FormSelect
          name="agenda"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__AGENDA"
              defaultMessage="Agenda"
            />
          }
          valueIsId={true}
          source={agendaSource}
          disabled={!hasPermission(UIElement.InternalDocument.AGENDA)}
        />
        <FormAutocomplete
          name="summary"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__SUMMARY"
              defaultMessage="Stručný obsah"
            />
          }
          required
          freeSolo={true}
          autoFocus={true}
          labelMapper={autocompleteLabelMapper}
          source={briefSummaries}
          disabled={!hasPermission(UIElement.InternalDocument.SUMMARY)}
        />
        <FormLocalDateTimeField
          name="createdDate"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__CREATED_DATE"
              defaultMessage="Datum vytvoření"
            />
          }
          disabled={
            detailCtx?.isExisting ||
            !hasPermission(UIElement.InternalDocument.CREATED_DATE)
          }
        />
        {agenda === Agenda.PLENARY_OPINION && (
          <FormCheckbox
            name="decision"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__DECISION"
                defaultMessage="Rozhodnutí"
              />
            }
            disabled={!hasPermission(UIElement.InternalDocument.DECISION)}
          />
        )}
        <FormTextArea
          name="note"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__NOTE"
              defaultMessage="Poznámka"
            />
          }
          disabled={!hasPermission(UIElement.InternalDocument.NOTE)}
        />
        <FormTextField
          type="number"
          name="pageCount"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__PAGE_COUNT"
              defaultMessage="Počet listů"
            />
          }
          disabled={!hasPermission(UIElement.InternalDocument.PAGE_COUNT)}
        />
        <FormTextField
          type="number"
          name="order"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__ORDER"
              defaultMessage="Pořadí"
            />
          }
          disabled={!hasPermission(UIElement.InternalDocument.ORDER)}
        />
        <FormTextField
          name="barCode"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_INTERNAL__DETAIL__FIELD_LABEL__BAR_CODE"
              defaultMessage="Čárový kód"
            />
          }
          disabled={!hasPermission(UIElement.InternalDocument.BAR_CODE)}
        />
      </FormPanel>
    </>
  );
}

export function General() {
  return (
    <>
      <EsFileFields />
      <InternalDocumentFields />
    </>
  );
}
