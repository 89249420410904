import clsx from 'clsx';
import { get } from 'lodash';
import React, { ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  DetailContext,
  EmptyComponent,
  FormPanel,
  FormTextArea,
  TableFieldToolbarButton,
  useFormFieldHighlighting,
} from '@eas/common-web';
import { useDecisionPermission } from '@modules/decision/decision-permission';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { UIElement } from '@enums';

const usePanelStyles = makeStyles(() => ({
  anonymized: { backgroundColor: green[100] },
}));

export function DecisionText({
  label,
  name,
  Before = <EmptyComponent />,
  After = <EmptyComponent />,
  enableHighlighting,
}: {
  label: ReactNode;
  name: string;
  Before?: ReactNode;
  After?: ReactNode;
  enableHighlighting: boolean;
}) {
  const classes = useStyles();
  const panelClasses = usePanelStyles();
  const intl = useIntl();

  const { source } = useContext(DetailContext);

  const { hasPermission } = useDecisionPermission(source.data);

  const [fontSize, setFontSize] = useState(14);

  const { showHighlightedComponent, HighlightedContent } =
    useFormFieldHighlighting({
      enableHighlighting,
      fieldName: name,
      value: get(source.data, name),
    });

  return (
    <FormPanel
      label={label}
      className={clsx({
        [panelClasses.anonymized]: name.includes('anonymized'),
      })}
    >
      <div className={classes.tableActions}>
        <ButtonGroup className={classes.buttonGroup}>
          {Before}
          <div>
            <TableFieldToolbarButton
              IconComponent={RemoveIcon}
              show={true}
              disabled={false}
              title={intl.formatMessage({
                id: 'ES__DECISION_TEXT_TOOLBAR__LARGER__BUTTON',
                defaultMessage: 'Zmenšit písmo',
              })}
              onClick={() =>
                setFontSize((size) => (size === 8 ? size : size - 2))
              }
              color="default"
              variant="text"
            >
              <Typography variant="body2" />
            </TableFieldToolbarButton>
            <Typography variant="body2" display="inline">
              &nbsp;{fontSize}&nbsp;
            </Typography>
            <TableFieldToolbarButton
              IconComponent={AddIcon}
              show={true}
              disabled={false}
              title={intl.formatMessage({
                id: 'ES__DECISION_TEXT_TOOLBAR__SMALLER__BUTTON',
                defaultMessage: 'Zvětšit písmo',
              })}
              onClick={() =>
                setFontSize((size) => (size === 32 ? size : size + 2))
              }
              color="default"
              variant="text"
            >
              <Typography variant="body2" />
            </TableFieldToolbarButton>
          </div>
        </ButtonGroup>
      </div>
      {After}
      <FormTextArea
        name={name}
        labelOptions={{
          hide: true,
        }}
        maxRows={30}
        fontSize={fontSize}
        disabled={
          !hasPermission(
            name.includes('anonymized')
              ? UIElement.Decision.ANONYMIZED_TEXT
              : UIElement.Decision.TEXT
          )
        }
        showHighlightedComponent={showHighlightedComponent}
        HighlightedContent={HighlightedContent}
      />
    </FormPanel>
  );
}
