import { getErrorMessage } from '@utils/error-message';
import React, { useContext, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {
  AbortableFetch,
  DetailContext,
  SnackbarContext,
  SnackbarVariant,
  TableFieldToolbarButton,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl, Messages } from '@enums';

export function useRevalidateAttachmentDialog() {
  const { source } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const fetch = useRef<AbortableFetch | null>(null);
  const intl = useIntl();

  const callApi = useEventCallback((attachmentId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${source?.data?.id}/attachment/${attachmentId}/revalidate`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  const wrappedCallApi = useEventCallback(async (attachmentId: string) => {
    try {
      setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(attachmentId);

      const message = intl.formatMessage({
        id: 'DOCUMENTS__REQUEST_SUCCESSFULLY_SENT',
        defaultMessage: 'Dokument se generuje, čekejte prosím.',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);

      const response = await fetch.current.raw();

      const blob = await response.blob();

      const fileUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = 'revalidace.pdf';

      document.body.appendChild(a); // Required for FF
      a.click();
      a.remove();

      setLoading(false);
      showSnackbar(...Messages.Attachment.DOWNLOAD_VALIDATION_REPORT.SUCCESS);
    } catch (err) {
      setLoading(false);
      const message = getErrorMessage(err as Error);

      if ((err as Error).name !== 'AbortError') {
        showSnackbar(...message);
      }
    }
  });

  const RevalidateButton = ({
    disabled,
    apiCall,
  }: {
    disabled: boolean;
    apiCall: () => Promise<void>;
  }) => (
    <TableFieldToolbarButton
      title={intl.formatMessage({
        id: 'ES__ATTACHMENTS__REVALIDATE_ATTACHMENT__TITLE',
        defaultMessage: 'Revalidace',
      })}
      IconComponent={() => (
        <>
          {loading && <CircularProgress size="20px" color="inherit" />}
          {!loading && <AutorenewIcon />}
        </>
      )}
      show={true}
      disabled={disabled || loading}
      onClick={apiCall}
      color="default"
    >
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'ES__ATTACHMENTS__REVALIDATE_ATTACHMENT__BUTTON',
          defaultMessage: 'Revalidovat',
        })}
      </Typography>
    </TableFieldToolbarButton>
  );

  return {
    callApi: wrappedCallApi,
    RevalidateButton,
  };
}
