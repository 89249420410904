import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FilesContext,
  FormCheckbox,
  FormFileField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextField,
  TableFieldContext,
  useFormSelector,
} from '@eas/common-web';
import { DictionaryAutocomplete, DocumentAttachment } from '@models';
import { DocumentType } from '@enums';
import { useAttachmentTypes } from './attachment-api';
import { useFiles } from './attachment-file-hook';

export function AttachmentDialog({
  documentType,
  disabled = false,
}: {
  documentType?: DocumentType;
  disabled?: boolean;
}) {
  const context = useContext(TableFieldContext);

  const { context: filesContext } = useFiles('/api/evidence-spisu/files');

  const attachmentTypes = useAttachmentTypes(documentType, context?.disabled);

  const { file, validation } = useFormSelector((data: DocumentAttachment) => ({
    file: data.file,
    validation: data.validation,
  }));

  const AttachmentFields = () => (
    <>
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ES__DOCUMENT__DETAIL__ATTACHMENT_FIELD_LABEL__NAME"
            defaultMessage="Název přílohy"
          />
        }
        autoFocus={true}
        disabled={disabled}
      />
      <FormSelect<DictionaryAutocomplete & { allowedFor: DocumentType[] }>
        name="type"
        label={
          <FormattedMessage
            id="ES__DOCUMENT__DETAIL__ATTACHMENT_FIELD_LABEL__TYPE"
            defaultMessage="Typ přílohy"
          />
        }
        source={attachmentTypes}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
        disabled={disabled}
      />
      <FilesContext.Provider value={filesContext}>
        <FormFileField
          name="file"
          label={
            <FormattedMessage
              id="ES__DOCUMENT__DETAIL__ATTACHMENT_FIELD_LABEL__FILE"
              defaultMessage="Soubor"
            />
          }
          disabled={disabled || filesContext.loading}
          before={filesContext.loading ? 'Nahrávání...' : undefined}
        />
      </FilesContext.Provider>
      {documentType &&
        file &&
        [DocumentType.OUTGOING, DocumentType.INTERNAL].includes(
          documentType
        ) && (
          <>
            <FormCheckbox
              name="convertToPdf"
              label={
                <FormattedMessage
                  id="ES__DOCUMENT__DETAIL__FIELD_LABEL__CONVERT_TO_PDF"
                  defaultMessage="Převést do PDF"
                />
              }
            />
            <FormCheckbox
              name="sign"
              label={
                <FormattedMessage
                  id="ES__DOCUMENT__DETAIL__FIELD_LABEL__SIGN"
                  defaultMessage="Opatřit el. pečetí"
                />
              }
            />
          </>
        )}
    </>
  );

  return (
    <>
      {(documentType !== DocumentType.DELIVERED || !validation) && (
        <AttachmentFields />
      )}
      {documentType === DocumentType.DELIVERED && validation && (
        <>
          <FormPanel label="Příloha">
            <AttachmentFields />
          </FormPanel>
          <FormPanel label="Validační report">
            <FormTextField name="validation.name" label="Název" disabled />
            <FormLocalDateTimeField
              name="validation.validationTime"
              label="Datum a čas validace"
              disabled
            />
          </FormPanel>
        </>
      )}
    </>
  );
}

export function AttachmentBulkCreateDialog() {
  return (
    <>
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ES__DELIVERED_DOCUMENT__DETAIL__ATTACHMENT_FIELD_LABEL__NAME"
            defaultMessage="Název přílohy"
          />
        }
        autoFocus={true}
      />
      <FormTextField
        name="count"
        type="number"
        label={
          <FormattedMessage
            id="ES__DELIVERED_DOCUMENT__DETAIL__ATTACHMENT_FIELD_LABEL__COUNT"
            defaultMessage="Počet příloh"
          />
        }
      />
    </>
  );
}
