import React from 'react';
import { FormattedMessage } from 'react-intl';
import Divider from '@material-ui/core/Divider/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { DecisionText } from '@composite/decision-text/decision-text';
import { AnonymizedDecisionActions } from '../anonymized-decision-actions';

const useStyles = makeStyles(() => ({
  divider: {
    width: '100%',
    height: 3,
    margin: '0px 0px 10px',
  },
}));

export function DecisionFields() {
  const classes = useStyles();

  return (
    <>
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__TEXT_INTRODUCTION"
            defaultMessage="Návětí - plné znění"
          />
        }
        name="text.introduction"
        enableHighlighting={true}
      />
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__ANONYMIZED_TEXT_INTRODUCTION"
            defaultMessage="Návětí - anonymizováno"
          />
        }
        name="anonymizedText.introduction"
        Before={<AnonymizedDecisionActions fieldName="introduction" />}
        enableHighlighting={true}
      />

      <Divider className={classes.divider} />
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__TEXT_STATEMENT"
            defaultMessage="Výrok - plné znění"
          />
        }
        name="text.statement"
        enableHighlighting={true}
      />
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__ANONYMIZED_TEXT_STATEMENT"
            defaultMessage="Výrok - anonymizováno"
          />
        }
        name="anonymizedText.statement"
        Before={<AnonymizedDecisionActions fieldName="statement" />}
        enableHighlighting={true}
      />
    </>
  );
}
