import * as Yup from 'yup';
import { Deadline } from '@models';

export function useValidationSchema() {
  return Yup.object<Deadline>().shape({
    duration: Yup.number()
      .nullable()
      .required()
      .positive('Musí být kladné číslo'),
  });
}
