import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormContext,
  FormHandle,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextField,
  useEventCallback,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { CityField } from '@components/form/address/city-field';
import { ZipCodeField } from '@components/form/address/zip-code-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  IsdsSubjectRequestDto,
  LegalEntity,
  NaturalPerson,
  Subject,
} from '@models';
import { IsdsSubjectType, SubjectType } from '@enums';

export function SearchDataBoxDialog({
  initData = false,
  formRef,
}: {
  initData?: boolean;
  formRef?: FormHandle<Subject> | null;
}) {
  const { setFieldValues, setFieldValue } = useContext(FormContext);
  const { isdsSubjectTypes } = useContext(StaticDataContext);

  const typeSource = useStaticListSource(isdsSubjectTypes);

  const { subjectType, dataBoxId } = useFormSelector(
    (data: IsdsSubjectRequestDto) => ({
      subjectType: data.subjectType,
      dataBoxId: data.dataBoxId,
    })
  );

  const countries = useCountries();

  useEffect(() => {
    if (formRef && initData) {
      const values = formRef.getFieldValues();

      const {
        city,
        street,
        orientationNumber,
        descriptiveNumber,
        zipCode,
        country,
      } = values.selectedContact?.address || {};

      setFieldValues({
        originalContact: values?.selectedContact,
        dataBoxId: values.selectedContact?.dataBox?.identifier,
        ico: (values as LegalEntity)?.ico,
        subjectType: (values as LegalEntity)?.isPublicAuthority
          ? IsdsSubjectType.PUBLIC_AUTHORITY
          : deriveIsdsSubjectType(values.type),
        birthDate: (values as NaturalPerson).birthDate,
        firmName: (values as LegalEntity).companyName,
        givenName: (values as NaturalPerson).firstName,
        lastName: (values as NaturalPerson).lastName,
        address: values.selectedContact?.address
          ? {
              city,
              street,
              orientationNumber,
              descriptiveNumber,
              zipCode,
              country,
            }
          : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeEmptyValue = useEventCallback((field: string, value?: string) => {
    const trimmedValue = value?.trim() ?? '';

    setFieldValue(field, trimmedValue?.length > 0 ? value : undefined);
  });

  const resetCompanyFields = () => {
    setFieldValue('firmName', undefined);
    setFieldValue('ico', undefined);
  };

  const resetPersonalFields = () => {
    setFieldValue('givenName', undefined);
    setFieldValue('lastName', undefined);
  };

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__SUBJECT__PANEL_TITLE"
            defaultMessage="Datová schránka"
          />
        }
      >
        <FormTextField
          name="dataBoxId"
          label={
            <FormattedMessage
              id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__DATABOX_ID"
              defaultMessage="Id datové schránky"
            />
          }
        />
      </FormPanel>
      {!dataBoxId && (
        <>
          <FormPanel
            label={
              <FormattedMessage
                id="ES__SUBJECT__PANEL_TITLE"
                defaultMessage="Subjekt"
              />
            }
          >
            <FormSelect
              name="subjectType"
              source={typeSource}
              label={
                <FormattedMessage
                  id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__TYPE"
                  defaultMessage="Typ"
                />
              }
              valueIsId={true}
              autoFocus={true}
              required={true}
              notifyChange={(value) => {
                if (value === IsdsSubjectType.NATURAL_PERSON) {
                  resetCompanyFields();
                } else if (
                  value !== IsdsSubjectType.NATURAL_PERSON &&
                  value !== IsdsSubjectType.PUBLIC_AUTHORITY
                ) {
                  resetPersonalFields();
                }
              }}
              disabled={!!dataBoxId}
            />
            {subjectType &&
              [
                IsdsSubjectType.NATURAL_PERSON,
                IsdsSubjectType.PUBLIC_AUTHORITY,
                IsdsSubjectType.BUSINESS_NATURAL_PERSON,
              ].includes(subjectType) && (
                <>
                  <FormTextField
                    name="givenName"
                    label={
                      <FormattedMessage
                        id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__GIVEN_NAME"
                        defaultMessage="Jméno subjektu"
                      />
                    }
                    disabled={!!dataBoxId}
                  />
                  <FormTextField
                    name="lastName"
                    label={
                      <FormattedMessage
                        id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__LAST_NAME"
                        defaultMessage="Přijmení subjektu"
                      />
                    }
                    required
                    disabled={!!dataBoxId}
                  />
                </>
              )}
            {subjectType && subjectType !== IsdsSubjectType.NATURAL_PERSON && (
              <>
                <FormTextField
                  name="ico"
                  label={
                    <FormattedMessage
                      id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__ICO"
                      defaultMessage="IČO"
                    />
                  }
                  required
                  disabled={!!dataBoxId}
                />
                <FormTextField
                  name="firmName"
                  label={
                    <FormattedMessage
                      id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__FIRM_NAME"
                      defaultMessage="Název firmy subjektu"
                    />
                  }
                  required
                  disabled={!!dataBoxId}
                />
              </>
            )}
            {(subjectType === IsdsSubjectType.NATURAL_PERSON ||
              subjectType === IsdsSubjectType.BUSINESS_NATURAL_PERSON) && (
              <FormLocalDateField
                name="birthDate"
                label={
                  <FormattedMessage
                    id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__BIRTH_DATE"
                    defaultMessage="Datum narození"
                  />
                }
                disabled={!!dataBoxId}
              />
            )}
          </FormPanel>
          <FormPanel
            label={
              <FormattedMessage
                id="ES__ADDRESS__PANEL_TITLE"
                defaultMessage="Adresa"
              />
            }
          >
            <FormTextField
              name="address.street"
              label={
                <FormattedMessage
                  id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__STREET"
                  defaultMessage="Ulice"
                />
              }
              notifyChange={(value) =>
                removeEmptyValue('address.street', value as string)
              }
              disabled={!!dataBoxId}
            />
            <FormTextField
              name="address.descriptiveNumber"
              label={
                <FormattedMessage
                  id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__DESCRIPTIVE_NUMBER"
                  defaultMessage="Číslo popisné"
                />
              }
              notifyChange={(value) =>
                removeEmptyValue('address.descriptiveNumber', value as string)
              }
              disabled={!!dataBoxId}
            />
            <FormTextField
              name="address.orientationNumber"
              label={
                <FormattedMessage
                  id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__ORIENTATION_NUMBER"
                  defaultMessage="Číslo orientační"
                />
              }
              notifyChange={(value) =>
                removeEmptyValue('address.orientationNumber', value as string)
              }
              disabled={!!dataBoxId}
            />
            <ZipCodeField prefix="address" disabled={!!dataBoxId} />
            <CityField
              prefix="address"
              notifyChange={(value) =>
                removeEmptyValue('address.city', value as string)
              }
              disabled={!!dataBoxId}
            />
            <FormAutocomplete
              source={countries}
              name="address.country"
              label={
                <FormattedMessage
                  id="ES__SEACH_DATA_BOX_DIALOG__FIELD_LABEL__ZIP_CODE"
                  defaultMessage="Stát"
                />
              }
              labelMapper={autocompleteLabelMapper}
              disabled={!!dataBoxId}
            />
          </FormPanel>
        </>
      )}
    </>
  );
}

const deriveIsdsSubjectType = (type?: SubjectType) => {
  switch (type) {
    case SubjectType.NATURAL_PERSON:
      return IsdsSubjectType.NATURAL_PERSON;

    case SubjectType.LEGAL_ENTITY:
      return IsdsSubjectType.LEGAL_ENTITY;

    case SubjectType.BUSINESS_NATURAL_PERSON:
      return IsdsSubjectType.BUSINESS_NATURAL_PERSON;

    default:
      return undefined;
  }
};
