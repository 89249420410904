import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { DocumentAttachment } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useEditAttachmentDialog() {
  const callApi = useEventCallback((id: string, values: DocumentAttachment) => {
    const convertToPdf = (!!values?.file && values.convertToPdf) ?? false;
    const sign = (!!values?.file && values?.sign) ?? false;

    return abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${id}/attachment/${values.id}?convertToPdf=${convertToPdf}&sign=${sign}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
        body: JSON.stringify(values),
      }
    );
  });

  const { source } = useContext(DetailContext);

  // TODO
  const showButton = source.data?.id;

  return {
    callApi,
    showButton,
  };
}
