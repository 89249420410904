import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { DeadlineField } from '@composite/deadlines/deadline-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { EsFile } from '@models';
import { UIElement } from '@enums';
import { DEADLINE_SOURCE } from '../../../es-file/es-file-hooked-sources';
import { useCourtFilePermission } from '../../../es-file/es-file-permission';

export function DeadlineFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } =
    useContext<DetailHandle<EsFile>>(DetailContext);
  const { deadlineStates } = useContext(StaticDataContext);

  const { hasPermission } = useCourtFilePermission(source.data);

  // eslint-disable-next-line prettier/prettier
  const disabledDeadlines =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.DisciplinaryProceedingsFile.DEADLINES);
  const deadlineSource = hookedSources[DEADLINE_SOURCE]?.source;

  return (
    <DeadlineField
      display="IN_ES_FILE"
      deadlineStates={deadlineStates}
      disabled={disabledDeadlines}
      source={deadlineSource}
    />
  );
}
