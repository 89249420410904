import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  columnDirection: {
    flexDirection: 'column',
  },
  disabled: {
    '& input': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  selectWrapper: {
    width: 100,
    marginRight: 10,
  },
  textWrapper: {
    width: 'calc(100% - 100px)',
  },
  textWithoutSelectWrapper: {
    width: '100%',
  },
  filledCellWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  marginBottom: {
    marginBottom: 5,
  },
  filterCellWrapper: { display: 'flex', flexDirection: 'row' },
}));
