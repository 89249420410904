import { Params, useAutocompleteSource } from '@eas/common-web';
import { DictBriefSummaryAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useBriefSummaries(params?: Params) {
  return useAutocompleteSource<DictBriefSummaryAutocomplete>({
    url: `${EvidenceApiUrl.DICT_BRIEF_SUMMARIES}/autocomplete`,
    params,
  });
}
