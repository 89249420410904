import * as Yup from 'yup';
import { Judge, UserRole } from '@models';

export function useValidationSchema() {
  return Yup.object<Judge>().shape({
    judgeUserRole: Yup.mixed<UserRole>().nullable().required(),
    indexUserRole: Yup.mixed<UserRole>().nullable().required(),
    secretaryUserRole: Yup.mixed<UserRole>().nullable().required(),
  });
}
