import { abortableFetch, useEventCallback } from '@eas/common-web';
import { ViewingRequest } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useAddViewingRequest() {
  const callApi = useEventCallback((_, formData: ViewingRequest) => {
    return abortableFetch(`${EvidenceApiUrl.VIEWING_REQUEST}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  return { callApi };
}
