import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  FormAutocomplete,
  FormPanel,
} from '@eas/common-web';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Agenda, Role, UIElement } from '@enums';
import { useSenates } from '../../../dict-senate/dict-senate-api';
import { usePlenaryOpinionPermission } from '../../../es-file/es-file-permission';
import { useJudges } from '../../../judge/judge-api';
import { useUserRoles } from '../../../role/role-api';

export function JudgesAndProcessorsFields() {
  const { source } = useContext(DetailContext);

  const indexUsers = useUserRoles({
    filters: [
      {
        field: 'role.id',
        operation: ApiFilterOperation.EQ,
        value: Role.INDEX,
      },
      {
        field: 'agendas.id',
        operation: ApiFilterOperation.EQ,
        value: Agenda.PLENARY_OPINION,
      },
    ],
  });

  const analystUsers = useUserRoles({
    filters: [
      {
        field: 'role.id',
        operation: ApiFilterOperation.EQ,
        value: Role.ANALYST,
      },
    ],
  });

  const senates = useSenates();
  const judges = useJudges();

  const { hasPermission } = usePlenaryOpinionPermission(source.data);

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__PLENARY_OPINION_FILES__DETAIL__PROCESSORS_PANEL_TITLE"
          defaultMessage="Zpracovatelé spisu"
        />
      }
    >
      <FormAutocomplete
        name="senate"
        label={
          <FormattedMessage
            id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__SENATE"
            defaultMessage="Navrhující senát"
          />
        }
        required={true}
        labelMapper={autocompleteLabelMapper}
        source={senates}
        disabled={!hasPermission(UIElement.PlenaryOpinionFile.SENATE)}
      />
      <FormAutocomplete
        name="judge"
        label={
          <FormattedMessage
            id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__JUDGE"
            defaultMessage="Soudce zpravodaj"
          />
        }
        source={judges}
        labelMapper={autocompleteLabelMapper}
        disabled={!hasPermission(UIElement.PlenaryOpinionFile.JUDGE)}
      />
      <FormAutocomplete
        name="indexUserRole"
        label={
          <FormattedMessage
            id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__INDEX_USER_ROLE"
            defaultMessage="Vedoucí rejstříku"
          />
        }
        source={indexUsers}
        labelMapper={autocompleteLabelMapper}
        disabled={!hasPermission(UIElement.PlenaryOpinionFile.INDEX_USER_ROLE)}
      />
      <FormAutocomplete
        name="analystUserRole"
        label={
          <FormattedMessage
            id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__ANALYST_USER_ROLE"
            defaultMessage="Analytik - indexace"
          />
        }
        source={analystUsers}
        labelMapper={autocompleteLabelMapper}
        disabled={
          !hasPermission(UIElement.PlenaryOpinionFile.ANALYST_USER_ROLE)
        }
      />
    </FormPanel>
  );
}
