import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TableFieldCells, TableFieldColumn } from '@eas/common-web';
import { CreatedByCell } from '@components/evidence/authored-evidence/created-by-cell';
import { TextNote } from '@models';

export function useNoteColumns(): TableFieldColumn<TextNote>[] {
  const intl = useIntl();

  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: 'ES__NOTES__COLUMN__TEXT',
          defaultMessage: 'Text',
        }),
        datakey: 'text',
        sortkey: 'text',
        width: 300,
      },
      {
        name: intl.formatMessage({
          id: 'ES__NOTES__COLUMN__CREATED',
          defaultMessage: 'Datum vložení',
        }),
        datakey: 'created',
        width: 120,
        CellComponent: TableFieldCells.DateCell,
      },
      {
        name: intl.formatMessage({
          id: 'ES__NOTES__COLUMN__CREATED_BY',
          defaultMessage: 'Vložil',
        }),
        datakey: 'createdBy.name',
        CellComponent: CreatedByCell,
        width: 200,
      },
    ],
    []
  );
}
