import clsx from 'clsx';
import React from 'react';
import {
  FilterComponentProps,
  Select,
  useStaticListSource,
} from '@eas/common-web';
import { useStyles } from '../filter-styles';

export function FilterCheckboxCell({
  disabled,
  value,
  onChange,
}: FilterComponentProps) {
  const classes = useStyles();
  const source = useStaticListSource([
    {
      id: 'true',
      name: 'Ano',
    },
    {
      id: 'false',
      name: 'Ne',
    },
  ]);

  return (
    <div
      className={clsx(classes.textWithoutSelectWrapper, {
        [classes.disabled]: disabled,
      })}
    >
      <Select
        source={source}
        value={value}
        onChange={onChange}
        disabled={disabled}
        valueIsId
      />
    </div>
  );
}
