import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { DeliveredDocument } from '@models';
import { Agenda, EsFileState, EvidenceApiUrl } from '@enums';

export function useRemoveDialog() {
  const { source } = useContext<DetailHandle<DeliveredDocument>>(DetailContext);

  const callApi = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/remove`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  const showInCourtFile =
    source?.data?.esFile?.agenda === Agenda.COURT &&
    source?.data?.esFile?.state &&
    [
      EsFileState.REGISTER_NEW,
      EsFileState.INDEX,
      EsFileState.REGISTER,
      EsFileState.JUDGE_OFFICE,
    ].includes(source?.data.esFile.state);

  const showInOtherFiles =
    source?.data?.esFile?.agenda !== Agenda.COURT &&
    source?.data?.esFile?.state &&
    [EsFileState.NEW, EsFileState.IN_PROGRESS].includes(
      source?.data.esFile.state
    );

  const showButton = source?.data?.id && (showInCourtFile || showInOtherFiles);

  return {
    callApi,
    showButton,
  };
}
