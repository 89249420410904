const NEW = {
  title: 'Nové spisy',
  actions: [
    {
      name: 'Vytvoření SPR spisu',
      description:
        'SPR spis lze vytvořit dvěma způsoby: <i>1. v pohledu Dokumenty - Nezpracované → akce Nové SPR; 2. v pohledu Spisy - SPR spisy - Nové INF → akce Nové SPR</i>',
    },
    {
      name: 'Odstranění spisu',
      description: 'Odstranit je možné pouze ve stavu <b>Nový</b>. ',
    },
    {
      name: 'Změnit stav',
      description:
        'Po změně stavu na <b>V řešení</b> se přidělí spisová značka.',
    },
    { name: 'Změnit agendu', description: 'Změna agendy.' },
    {
      name: 'Upravení spisu',
      description: 'Uživatel má možnost upravovat jednotlivé atributy spisu.',
    },
  ],
};

const IN_PROGRESS = {
  title: 'V řešení',
  actions: [
    {
      name: 'Vyvést',
      description:
        'Při vyvedení z rejstříku je změněn <b>Způsob vyřízení</b> na <i>Vyvedeno z rejstříku</i>, jsou stornovány všechny aktivní úlohy a běžící lhůty spisu, spis je uzavřen. Akce je dostupná u spisů, které nejsou uzavřené a mají přidělenu spisovou značku.',
    },
    { name: 'Změnit stav', description: 'Změnit stav na Uzavřeno.' },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
    {
      name: 'Upravení spisu',
      description: 'Uživatel může upravovat jednotlivé atributy spisu.',
    },
  ],
};

const CLOSED = {
  title: 'Uzavřené',
  actions: [
    { name: 'Znovu otevřít', description: 'Otevření spisu.' },
    { name: 'Obal spisu', description: 'Vygeneruje se obal spisu.' },
  ],
};

export const SPR_FILES = {
  title: 'Spisy SPR',
  subsections: [NEW, IN_PROGRESS, CLOSED],
};
