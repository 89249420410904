import { abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

/**
 * Fetches data for logged user.
 *
 * @param id User ID
 */
export function fetchProfile() {
  return abortableFetch(`${EvidenceApiUrl.USERS}/me`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}
