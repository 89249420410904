import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useDeleteAttachmentDialog() {
  const callApi = useEventCallback((id: string, attachmentId: string) =>
    abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${id}/attachment/${attachmentId}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'DELETE',
      }
    )
  );

  const { source } = useContext(DetailContext);

  // TODO
  const showButton = source.data?.id;

  return {
    callApi,
    showButton,
  };
}
