import clsx from 'clsx';
import React, { useContext } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { FilterContext } from './filter-context';

const useStyles = makeStyles(() => ({
  middlePositionSwitch: {
    marginLeft: 25,
    marginRight: 15,
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(35%)',
    },
  },
  switch: {
    marginLeft: 25,
    marginRight: 15,
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(80%)',
    },
  },
  actionbar: {
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export function FilterActionbar() {
  const {
    extenders,
    activeExtender,
    reducers,
    deactivateAll,
    activateAll,
  } = useContext(FilterContext);

  const classes = useStyles();
  const areAllExtendersVisible = extenders.every(
    (extender) => extender.visible
  );

  const areAllReducersVisible = reducers.every((reducer) => reducer.visible);
  const label =
    extenders.length > 0 ? 'Použité filtry:' : 'Odstraněné položky:';

  if (extenders.length === 0 && reducers.length === 0) {
    return null;
  }

  return (
    <div className={classes.actionbar}>
      <Typography variant="body1">
        <b>{label}</b>
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={
              extenders.some((extender) => extender.visible) ||
              reducers.some((reducer) => reducer.visible)
            }
            className={clsx(classes.middlePositionSwitch, {
              [classes.switch]: areAllExtendersVisible && areAllReducersVisible,
            })}
            disabled={activeExtender !== undefined}
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (areAllExtendersVisible && areAllReducersVisible) {
                deactivateAll();
              } else {
                activateAll();
              }
            }}
          />
        }
        label="VŠECHNY FILTRY"
        labelPlacement="end"
      />
    </div>
  );
}
