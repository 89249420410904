import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
} from '@components/form/misc/label-mappers';
import { Dispatch } from '@models';
import { EvidenceBrowserUrl, Role } from '@enums';
import { useUserRoles } from '../role/role-api';

export function useColumns(): TableColumn<Dispatch>[] {
  const intl = useIntl();

  const { dispatchMethods, documentDispatchStates } =
    useContext(StaticDataContext);
  const useMethods = () => useStaticListSource(dispatchMethods);
  const useStates = () => useStaticListSource(documentDispatchStates);
  const useIndexes = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.INDEX }));

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ALL}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    {
      datakey: 'jid',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__NUMBER',
        defaultMessage: 'ID vypravení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'document.esFile.number.value',
      sortkey: 'document.esFile.number.valueSort',
      filterkey: 'document.esFile.number.value',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__DOCUMENT_ES_FILE',
        defaultMessage: 'Spisová značka',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'document.referenceNumber',
      sortkey: 'document.referenceNumberSort',
      filterkey: 'document.referenceNumber',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__DOCUMENT_REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.name',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__RECIPIENT_NAME',
        defaultMessage: 'Adresát - Jméno a příjmení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.companyName',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__RECIPIENT_COMPANY_NAME',
        defaultMessage: 'Adresát - Název firmy/organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.dataBoxId',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__RECIPIENT_DATA_BOX_ID',
        defaultMessage: 'Adresát - ID datové schránky',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.email',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__RECIPIENT_EMAIL',
        defaultMessage: 'Adresát - Email',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.address',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__RECIPIENT_ADDRESS',
        defaultMessage: 'Adresát - Adresa',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'method',
      sortkey: 'method.name',
      filterkey: 'method.id',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__TYPE',
        defaultMessage: 'Způsob vypravení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useMethods),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useMethods),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav vypravení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'dispatchTime',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__DISPATCH_TIME',
        defaultMessage: 'Datum vypravení',
      }),
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'deliveryTime',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__DELIVERY_TIME',
        defaultMessage: 'Datum doručení',
      }),
      width: 200,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'document.esFile.indexUserRole',
      filterkey: 'document.esFile.indexUserRole.id',
      sortkey: 'document.esFile.indexUserRole.name',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__INDEX_USER_ROLE',
        defaultMessage: 'Rejstřík',
      }),
      width: 200,
      valueMapper: autocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useIndexes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}
