import { useListSource, useStaticListSource } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useMonths() {
  return useStaticListSource([
    { id: '1', name: 'Leden' },
    { id: '2', name: 'Únor' },
    { id: '3', name: 'Březen' },
    { id: '4', name: 'Duben' },
    { id: '5', name: 'Květen' },
    { id: '6', name: 'Červen' },
    { id: '7', name: 'Červenec' },
    { id: '8', name: 'Srpen' },
    { id: '9', name: 'Září' },
    { id: '10', name: 'Říjen' },
    { id: '11', name: 'Listopad' },
    { id: '12', name: 'Prosinec' },
  ]);
}

export function useIntervalTypes() {
  return useStaticListSource([
    { id: 'RANGE', name: 'Rozsah (od-do)' },
    { id: 'YEAR', name: 'Konkrétní rok' },
    { id: 'ALL', name: 'Vše od prvního podání' },
  ]);
}

export function useSupportedExportTypes(apiUrl: EvidenceApiUrl) {
  return useListSource<{ id: string; name: string }>({
    url: `${apiUrl}/report/supported-types`,
    method: 'GET',
  });
}
