import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '@eas/common-web';
import { User } from '@models';
import { fetchProfile } from './profile-api';
import { TextProfileField } from './profile-field';
import { Notifications } from './tables/notifications';
import { UserRoles } from './tables/user-roles';

const useStyles = makeStyles({
  page: {
    height: '100%',
    overflowY: 'auto',
  },
  container: {
    marginTop: 15,
    justifyContent: 'center',
    width: '100%',
  },
  titleWrapper: {
    textAlign: 'left',
    borderBottom: '1px solid #cdcdcd',
  },
  title: {
    display: 'inline-block',
    margin: '10px 0',
    fontSize: 25,
    fontWeight: 500,
  },
  header: {
    textAlign: 'left',
    marginBottom: 30,
    fontSize: 16,
    fontWeight: 500,
  },
  card: {
    width: '100%',
    borderRadius: 16,
    background:
      'linear-gradient(0deg, rgb(245 246 247) 0%, rgb(255 255 255) 100%)',
    margin: '0 0 24px',
    padding: 32,
  },
});

export function Profile() {
  const { user } = useContext(UserContext);

  const [profile, setProfile] = useState<User | undefined>(undefined);

  const classes = useStyles();

  useEffect(() => {
    const loadProfile = async () => {
      const profile = await fetchProfile().json();
      setProfile(profile);
    };

    if (user) {
      loadProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ProfileFields = {
    Username: TextProfileField,
    TitlesBefore: TextProfileField,
    FirstName: TextProfileField,
    LastName: TextProfileField,
    TitlesAfter: TextProfileField,
    Email: TextProfileField,
    Phone: TextProfileField,
  };

  return (
    <div className={classes.page}>
      <Container maxWidth="lg">
        <Grid container spacing={5} className={classes.container}>
          <Grid item xs={12}>
            <div className={classes.titleWrapper}>
              <Typography variant="h1" className={classes.title}>
                <FormattedMessage
                  id="ES__PROFILE__TITLE"
                  defaultMessage="Profil uživatele"
                />
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Typography variant="h5" className={classes.header}>
              <FormattedMessage
                id="ES__PROFILE__CONTACT_INFO"
                defaultMessage="Kontaktní údaje"
              />
            </Typography>

            <Card className={classes.card} elevation={2}>
              <ProfileFields.Username
                value={profile?.username}
                label={
                  <FormattedMessage
                    id="ES__PROFILE__USERNAME"
                    defaultMessage="Uživatelské jméno"
                  />
                }
              />
              <ProfileFields.FirstName
                value={profile?.firstName}
                label={
                  <FormattedMessage
                    id="ES__PROFILE__FIRST_NAME"
                    defaultMessage="Jméno"
                  />
                }
              />
              <ProfileFields.LastName
                value={profile?.lastName}
                label={
                  <FormattedMessage
                    id="ES__PROFILE__LAST_NAME"
                    defaultMessage="Příjmení"
                  />
                }
              />
              <ProfileFields.TitlesAfter
                value={profile?.titlesAfter}
                label={
                  <FormattedMessage
                    id="ES__PROFILE__TITLES_AFTER"
                    defaultMessage="Tituly za"
                  />
                }
              />
              <ProfileFields.Email
                value={profile?.email}
                label={
                  <FormattedMessage
                    id="ES__PROFILE__EMAIL"
                    defaultMessage="E-mail"
                  />
                }
              />
              <ProfileFields.Phone
                value={profile?.phone}
                label={
                  <FormattedMessage
                    id="ES__PROFILE__PHONE"
                    defaultMessage="Telefon"
                  />
                }
              />
            </Card>
          </Grid>

          <Grid item xs={12} lg={8}>
            <UserRoles />
            <Notifications />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
