const REGISTERS = {
  title: 'Rejstříky - soudní spisy',
};

const STATISTICS = {
  title: 'Statistiky',
  description:
    'Aplikace umožňuje vytvářet statistiky, které je možné uživatelsky exportovat do formátu MS WORD a PDF s podporou české diakritiky. Jednotlivým statistikám je možné nastavovat povolené parametry.',
};

export const STATISTICS_AND_REGISTERS = {
  title: 'Statistiky a rejstříky',
  sections: [REGISTERS, STATISTICS],
};
