/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

/**
 * Attachment messages
 */
const DOWNLOAD_VALIDATION_REPORT: MessageType = {
  SUCCESS: [
    <FormattedMessage
      id="ES__MESSAGE__ATTACHMENT__DOWNLOAD_VALIDATION_REPORT__SUCCESS"
      defaultMessage="Akce byla úspěšně vykonána."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      id="ES__MESSAGE__ATTACHMENT__DOWNLOAD_VALIDATION_REPORT__ERROR"
      defaultMessage="Chyba při generování validačního reportu."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default { DOWNLOAD_VALIDATION_REPORT };
