import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AuthoredObject } from '@models';

const useStyles = makeStyles({
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const CreatedByCell = ({ rowValue }: { rowValue: AuthoredObject }) => {
  const classes = useStyles();
  const { createdByDelegate, createdBy } = rowValue;

  const name = createdByDelegate?.substitutedBy
    ? `${createdByDelegate?.substitutedBy?.name} (zástup za ${createdByDelegate?.name})`
    : createdByDelegate?.name !== 'system'
    ? createdByDelegate?.name
    : createdBy?.name;

  return <Typography className={classes.text}>{name}</Typography>;
};
