import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { green, red } from '@material-ui/core/colors';
import {
  DetailContext,
  DetailHandle,
  FormTableField,
  TableFieldCells,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { OutgoingDocument, SendDispatchDto } from '@models';
import { EvidenceApiUrl, OutgoingDocumentDispatchState } from '@enums';

export function useForwardToDispatch() {
  const { source } = useContext<DetailHandle<OutgoingDocument>>(DetailContext);
  const intl = useIntl();

  const callApi = useEventCallback(() =>
    abortableFetch(
      `${EvidenceApiUrl.DOCUMENTS}/${source.data?.id}/dispatch/send/all`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  const showButton =
    source.data?.id &&
    source?.data?.dispatchState === OutgoingDocumentDispatchState.FOR_SENDING;

  const ResultFields = () => (
    <>
      <FormTableField
        name="dispatches"
        createRowStyle={(dispatch: SendDispatchDto) =>
          dispatch.success
            ? {
                color: green[500],
                backgroundColor: green[50],
              }
            : {
                color: red[500],
                backgroundColor: red[50],
              }
        }
        columns={[
          {
            name: intl.formatMessage({
              id: 'ES__FORWARD_TO_DISPATCH__COLUMN__DISPATCH_NUMBER',
              defaultMessage: 'ID vypravení',
            }),
            datakey: 'dispatch.id',
            width: 200,
          },
          {
            name: intl.formatMessage({
              id: 'ES__FORWARD_TO_DISPATCH__COLUMN__SUCCESS',
              defaultMessage: 'Odesláno',
            }),
            datakey: 'success',
            CellComponent: TableFieldCells.BooleanCell,
            width: 150,
          },
          {
            name: intl.formatMessage({
              id: 'ES__FORWARD_TO_DISPATCH__COLUMN__INVALID_ADDRESS',
              defaultMessage: 'Neúplná adresa',
            }),
            datakey: 'validationResult.invalidAddress',
            CellComponent: TableFieldCells.BooleanCell,
            width: 150,
          },
          {
            name: intl.formatMessage({
              id: 'ES__FORWARD_TO_DISPATCH__COLUMN__EMPTY_ATTACHMENTS',
              defaultMessage: 'Neexistence příloh',
            }),
            datakey: 'validationResult.emptyAttachments',
            CellComponent: TableFieldCells.BooleanCell,
            width: 150,
          },
          {
            name: intl.formatMessage({
              id: 'ES__FORWARD_TO_DISPATCH__COLUMN__ATTACHMENTS_SIZE_THRESHOLD_EXCEEDED',
              defaultMessage: 'Velikost příloh přesahuje limit',
            }),
            datakey: 'validationResult.attachmentsSizeLimitExceeded',
            CellComponent: TableFieldCells.BooleanCell,
            width: 200,
          },
        ]}
        showRadioCond={() => false}
        showDetailBtnCond={() => false}
        showToolbar={false}
        maxRows={12}
        labelOptions={{
          hide: true,
        }}
      />
    </>
  );

  return {
    callApi: callApi,
    showButton,
    ResultFields,
  };
}
