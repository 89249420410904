import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useReturnDialog() {
  const callApi = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/return`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  const { source } = useContext(DetailContext);

  // TODO
  const showButton = source.data?.id && !source.data?.processed;

  return {
    callApi,
    showButton,
  };
}
