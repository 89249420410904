import React from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  Source,
  abortableFetch,
  listItemsFactory,
} from '@eas/common-web';
import { useDatedEvidence } from '@composite/evidence/dated-evidence';
import { Role } from '@models';
import { EvidenceApiUrl, ExportTags } from '@enums';
import { Fields } from './role-fields';
import { useRoleHookedSources } from './role-hooked-sources';

type EasListPostProcess = (data: Source<Role>) => Promise<Source<Role>>;

export function Roles() {
  const intl = useIntl();
  const { columnName } = DictionaryEvidence.useDictionaryColumns<Role>();

  const evidence = useDatedEvidence<Role>({
    version: 3,
    identifier: 'ROLES',
    apiProps: {
      url: EvidenceApiUrl.ROLES,
      listItems: listItemsFactory<Role>({
        listItemsMethod: (url) =>
          abortableFetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }),
        // ts hack: list post process is not implemented to handle other type then `ResultDto` extended with `Source`
        postProcess: ((items: Role[]) => ({
          items: items,
          count: items.length,
        })) as unknown as EasListPostProcess,
      }),
      hookedSources: useRoleHookedSources,
    },
    tableProps: {
      columns: [{ ...columnName, sortable: false }],
      tableName: intl.formatMessage({
        id: 'ES__ROLES__TABLE__TITLE',
        defaultMessage: 'Uživatelské role',
      }),
      defaultSorts: [],
      reportTag: ExportTags.USER_ROLES,
      showBulkActionButton: false,
      showFilterButton: false,
      showReportButton: false,
      showNamedSettingsButton: false,
      showResetSortsButton: false,
      showColumnButton: false,
      SearchbarComponent: EmptyComponent,
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__ROLES__DETAIL__TITLE',
          defaultMessage: 'Uživatelská role',
        }),
        showBtn: (btn) => ['REFRESH', 'SHARE'].includes(btn),
        subtitle: (value) => value?.name ?? '',
      },
    },
  });

  return <Evidence {...evidence} />;
}
