import { getErrorMessage } from '@utils/error-message';
import { stubTrue } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  AbortableFetch,
  ActionButtonHandle,
  DetailContext,
  DetailMode,
  EvidenceContext,
  PagedTableFieldContext,
  SnackbarContext,
  TableFieldToolbarButton,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { OutgoingDocumentFields } from '@modules/document-outgoing/fields/general/general';
import { useValidationSchema } from '@modules/document-outgoing/outgoing-document-schema';
import {
  DECISION_SOURCE,
  DRAFT_SOURCE,
} from '@modules/es-file/es-file-hooked-sources';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { Document, ErrorObject, OutgoingDocument } from '@models';
import { AttachmentType, EvidenceApiUrl } from '@enums';
import { AttachmentField } from '../../attachments/attachment-field';
import { DocumentDialogDataProvider } from '../document-context';

export function useAddOutgoingDocumentDialog(
  setResult: (document: OutgoingDocument | null) => void,
  refreshSources: () => void
) {
  const fetch = useRef<AbortableFetch | null>(null);
  const { showSnackbar } = useContext(SnackbarContext);

  const callCreateApi = useEventCallback(
    (id: string, formData: OutgoingDocument) => {
      return abortableFetch(`${EvidenceApiUrl.DOCUMENTS}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(formData),
      });
    }
  );

  const callEditApi = useEventCallback((formData: OutgoingDocument) => {
    return abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${formData.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  const callCreateDecisionApi = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.DOCUMENTS}/${id}/decision`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
  );

  const handleEditDocument = useEventCallback(
    async (values: OutgoingDocument) => {
      try {
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callEditApi(values);
        const document: OutgoingDocument = await fetch.current.json();

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        if (document.decision) {
          fetch.current = callCreateDecisionApi(document.id);
          await fetch.current.raw();
        }

        refreshSources();
        setResult(null);
      } catch (e) {
        const err = e as ErrorObject<string>;

        const message = getErrorMessage(err);

        if (err.name !== 'AbortError') {
          showSnackbar(...message);
        }
      }
    }
  );

  return {
    callCreateApi,
    handleEditDocument,
  };
}

export function AddOutgoingDocumentButton({
  initialValues,
  disabled,
  refreshAfterConfirm = false,
}: {
  initialValues: Document;
  disabled: boolean;
  refreshAfterConfirm?: boolean;
}) {
  const intl = useIntl();

  const { hookedSources } = useContext(EvidenceContext);
  const { refreshAll } = useContext(DetailContext);
  const { source: documentSource } = useContext(PagedTableFieldContext);
  const decisionsSource = hookedSources[DECISION_SOURCE]?.source;
  const draftSources = hookedSources[DRAFT_SOURCE]?.source;

  const [result, setResult] = useState<OutgoingDocument | null>(null);
  const attachmentRef = useRef<ActionButtonHandle>(null);

  const validationSchema = useValidationSchema();

  const refreshSources = () => {
    documentSource.reset();
    documentSource.loadMore();

    decisionsSource.reset();
    decisionsSource.loadMore();

    draftSources.reset();
    draftSources.loadMore();
  };

  const { callCreateApi, handleEditDocument } = useAddOutgoingDocumentDialog(
    setResult,
    () => {
      if (refreshAfterConfirm) {
        refreshAll();
      }
      refreshSources();
    }
  );

  return (
    <>
      <DetailActionButton
        promptKey="ADD_OUTGOING_DOCUMENT"
        apiCall={callCreateApi}
        buttonLabel={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_OUTGOING_DOCUMENT',
          defaultMessage: 'Přidat odchozí dokument',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_OUTGOING_DOCUMENT__TITLE',
          defaultMessage: 'Odchozí dokument',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_OUTGOING_DOCUMENT__TEXT',
          defaultMessage: 'Vyplňte formulář.',
        })}
        dialogWidth={700}
        modes={[DetailMode.VIEW, DetailMode.EDIT]}
        ButtonComponent={function Button({ label, onClick }) {
          return (
            <TableFieldToolbarButton
              IconComponent={AddCircleOutlineIcon}
              show={true}
              disabled={disabled ?? false}
              title={label}
              onClick={onClick}
              color="primary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'ES__DOCUMENTS_TOOLBAR__ADD_OUTGOING_DOCUMENT__BUTTON',
                  defaultMessage: 'Přidat odchozí',
                })}
              </Typography>
            </TableFieldToolbarButton>
          );
        }}
        FormFields={function Fields() {
          return (
            <DocumentDialogDataProvider document={result!}>
              {() => <OutgoingDocumentFields />}
            </DocumentDialogDataProvider>
          );
        }}
        formInitialValues={initialValues}
        formValidationSchema={validationSchema}
        refreshAfterConfirm={false}
        onResult={async (result: OutgoingDocument) => {
          setResult(result);

          requestAnimationFrame(() => {
            attachmentRef.current?.executeAction();
          });
        }}
      />
      <DetailActionButton
        ref={attachmentRef}
        promptKey="ADD_OUTGOING_DOCUMENT_ATTACHMENTS"
        closeCallback={refreshSources}
        buttonLabel={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_OUTGOING_DOCUMENT_ATTACHMENTS',
          defaultMessage: 'Přidat přílohy',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_OUTGOING_DOCUMENT_ATTACHMENTS__TITLE',
          defaultMessage: 'Přílohy odchozího dokumentu',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__DOCUMENTS_TOOLBAR__ADD_OUTGOING_DOCUMENT_ATTACHMENTS__TEXT',
          defaultMessage: 'Vyplňte formulář.',
        })}
        dialogWidth={800}
        dialogShowClose={false}
        modes={[]}
        submitCallback={handleEditDocument}
        refreshAfterConfirm={false}
        formInitialValues={result}
        dialogDisableBackdrop={true}
        FormFields={function Fields() {
          return (
            <DocumentDialogDataProvider document={result!}>
              {({ source }) => (
                <>
                  <OutgoingDocumentFields />
                  <AttachmentField
                    label={
                      <FormattedMessage
                        id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__INTERNAL"
                        defaultMessage="Přílohy odchozího dokumentu"
                      />
                    }
                    defaultName={result?.summary ?? ''}
                    attachments={source.data?.attachments ?? []}
                    attachmentType={AttachmentType.OUTGOING}
                    defaultExpanded={true}
                    hasPermission={stubTrue}
                  />
                </>
              )}
            </DocumentDialogDataProvider>
          );
        }}
      />
    </>
  );
}
