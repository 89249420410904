import React, { useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ActionButtonHandle,
  ApiFilterOperation,
  DetailActionButton,
  DetailContext,
  DetailHandle,
  DetailMode,
  FieldFilter,
  FormContext,
  FormDictionaryField,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
  useCrudSource,
  useEventCallback,
  useScrollableSource,
} from '@eas/common-web';
import { useZipCodes } from '@modules/dict-zip-code/dict-zip-code-api';
import { Fields } from '@modules/dict-zip-code/dict-zip-code-fields';
import { useValidationSchema } from '@modules/dict-zip-code/dict-zip-code-schema';
import { DictZipCode, DictZipCodeAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { dictionaryFieldLabelMapper } from '../misc/label-mappers';

export function ZipCodeField({
  prefix,
  prefill = true,
  disabled,
  required,
  loadDetail,
}: {
  prefix?: string;
  prefill?: boolean;
  disabled?: boolean;
  required?: boolean;
  loadDetail?: boolean;
}) {
  const intl = useIntl();
  const createRef = useRef<ActionButtonHandle>(null);

  const { setFieldValue } = useContext(FormContext);

  const cityName = prefix ? `${prefix}.city` : 'city';
  const name = prefix ? `${prefix}.zipCode` : 'zipCode';

  const zipCodes = useZipCodes(
    loadDetail ? { apiUrl: EvidenceApiUrl.DICT_ZIP_CODES } : undefined
  );
  const zipCodeSource = useScrollableSource({
    url: `${EvidenceApiUrl.DICT_ZIP_CODES}/list`,
  });

  return (
    <>
      <ZipCodeToolbar
        createRef={createRef}
        fieldName={name}
        onResultValue={(value) => {
          const zip = value as DictZipCodeAutocomplete;
          setFieldValue(name, zip);

          if (prefill && zip) {
            setFieldValue(cityName, zip.city);
          }
        }}
      />
      <FormDictionaryField
        name={name}
        autocompleteSource={zipCodes}
        dialogSource={zipCodeSource}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ZIP_CODE"
            defaultMessage="PSČ"
          />
        }
        filters={[
          {
            label: intl.formatMessage({
              id: 'ES__ZIP_CODES__FILTER__CODE',
              defaultMessage: 'Kód',
            }),
            datakey: 'code',
            filterkey: 'code',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterTextCell,
          },
          {
            label: intl.formatMessage({
              id: 'ES__ZIP_CODES__FILTER__NAME',
              defaultMessage: 'Název',
            }),
            datakey: 'name',
            filterkey: 'name',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterTextCell,
          },
          {
            label: intl.formatMessage({
              id: 'ES__ZIP_CODES__FILTER__CITY',
              defaultMessage: 'Město',
            }),
            datakey: 'city',
            filterkey: 'city',
            defaultOperation: ApiFilterOperation.CONTAINS,
            FilterComponent: FieldFilter.FilterTextCell,
          },
        ]}
        columns={[
          {
            name: intl.formatMessage({
              id: 'ES__ZIP_CODES__COLUMN__CODE',
              defaultMessage: 'Kód',
            }),
            datakey: 'code',
            sortkey: 'code',
            width: 200,
          },
          {
            name: intl.formatMessage({
              id: 'ES__ZIP_CODES__COLUMN__NAME',
              defaultMessage: 'Název',
            }),
            datakey: 'name',
            sortkey: 'name',
            width: 300,
          },
          {
            name: intl.formatMessage({
              id: 'ES__ZIP_CODES__COLUMN__NAME',
              defaultMessage: 'Město',
            }),
            datakey: 'city',
            sortkey: 'city',
            width: 300,
          },
        ]}
        labelMapper={dictionaryFieldLabelMapper}
        notifyChange={(value) => {
          const zip = value as DictZipCodeAutocomplete;

          if (prefill && zip) {
            setFieldValue(cityName, zip.city);
          }
        }}
        onCreate={() => createRef.current?.executeAction()}
        disabled={disabled}
        required={required}
        dialogWidth={1200}
      />
    </>
  );
}

export function ZipCodeToolbar({
  createRef,
  fieldName = 'zipCode',
  onResultValue,
}: {
  createRef: React.RefObject<ActionButtonHandle>;
  fieldName?: string;
  onResultValue?: (result: DictZipCode) => void;
}) {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useContext(FormContext);

  const addZipCodeApiCall = useEventCallback(
    (id: string, formData: DictZipCode) => {
      return abortableFetch(`${EvidenceApiUrl.DICT_ZIP_CODES}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(formData),
      });
    }
  );

  const source = useCrudSource<DictZipCode>({
    url: EvidenceApiUrl.DICT_ZIP_CODES,
  });

  const handleProcessResult = useEventCallback(async (value) => {
    onResultValue ? onResultValue(value) : setFieldValue(fieldName, value);

    const message = intl.formatMessage({
      id: 'EAS_ACTION_BUTTON_MSG_SUCCESS',
      defaultMessage: 'Akce byla úspěšně vykonána.',
    });

    showSnackbar(message, SnackbarVariant.SUCCESS);
  });

  return (
    <>
      <DetailActionButton
        ref={createRef}
        promptKey="ADD_ZIP_CODE"
        apiCall={addZipCodeApiCall}
        buttonLabel={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__ADD_ZIP_CODE',
          defaultMessage: 'Přidat PSČ',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__ADD_ZIP_CODE__TITLE',
          defaultMessage: 'PSČ',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__SUBJECTS_TOOLBAR__ADD_ZIP_CODE__TEXT',
          defaultMessage: 'Vyplňte informace o PSČ.',
        })}
        FormFields={() => (
          <DetailContext.Provider
            value={
              {
                source,
                mode: DetailMode.NEW,
                isExisting: !!source?.data?.id,
              } as DetailHandle<DictZipCode>
            }
          >
            <Fields showHistoryField={false} />
          </DetailContext.Provider>
        )}
        modes={[]}
        refreshAfterConfirm={false}
        dialogWidth={1200}
        formValidationSchema={validationSchema}
        onResult={handleProcessResult}
      />
    </>
  );
}
