import { abortableFetch, useEventCallback } from '@eas/common-web';
import { Subject } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useAddSubjectDialog() {
  const callApi = useEventCallback((id: string, formData: Subject) => {
    return abortableFetch(`${EvidenceApiUrl.SUBJECTS}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  return {
    callApi,
  };
}
