import { useContext } from 'react';
import {
  DetailContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useMakeRoleDefaultDialog() {
  const { source } = useContext(DetailContext);

  const callApi = useEventCallback(
    (id: string, formData?: { agenda?: string }) => {
      const agendaUrl = `${EvidenceApiUrl.USER_ROLE}/default?forAgenda=${formData?.agenda}`;
      const roleUrl = `${EvidenceApiUrl.USER_ROLE}/default?forRole=${source?.data.id}`;

      return abortableFetch(formData?.agenda ? agendaUrl : roleUrl, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: id,
      });
    }
  );

  return {
    callApi,
  };
}
