import React from 'react';
import { FormattedMessage } from 'react-intl';
import { red } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import {
  AbortableFetch,
  ActionButton,
  ActionButtonComponentProps,
  Callback,
  EmptyComponent,
  Tooltip,
} from '@eas/common-web';

function RemoveButton(props: ActionButtonComponentProps) {
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="ES__REDIRECT__REMOVE"
          defaultMessage="Odstranit"
        />
      }
    >
      <ClearIcon
        style={{ color: red['A700'], cursor: 'pointer', marginRight: 10 }}
        fontSize="small"
        {...props}
      />
    </Tooltip>
  );
}

export function Remove({
  id,
  removeDialogTitle = '',
  removeDialogText = '',
  removeOnSuccess = async () => {},
  removeApiCall,
  removeCallback,
}: {
  id: string;
  removeDialogTitle?: string;
  removeDialogText?: string;
  removeOnSuccess?: () => Promise<void>;
  removeApiCall?: (data: string) => AbortableFetch;
  removeCallback?: Callback;
}) {
  return (
    <ActionButton
      promptKey={`ES__REMOVE_${id}`}
      buttonLabel=""
      dialogTitle={removeDialogTitle}
      dialogText={removeDialogText}
      onSuccess={removeOnSuccess}
      formInitialValues={id}
      FormFields={EmptyComponent}
      ButtonComponent={RemoveButton}
      apiCall={removeApiCall!}
      callback={removeCallback!}
      onBeforeDialog={(e) => {
        e?.stopPropagation();
        return true;
      }}
    />
  );
}
