import { EsFileState, Role, UIElement } from '@enums';

export const infFileAccessMap = new Map([
  [
    EsFileState.NEW,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.InfFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.InfFile.CREATE_INF_FILE_BUTTON,
          UIElement.InfFile.DELETE_INF_FILE_BUTTON,
          UIElement.InfFile.UPDATE_INF_FILE_AGENDA_BUTTON,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.InfFile.DELIVERY_DATE,
          UIElement.InfFile.INCOMING_NUMBER,
          UIElement.InfFile.NOTES,
          UIElement.InfFile.CREATE_INF_FILE_BUTTON,
          UIElement.InfFile.DELETE_INF_FILE_BUTTON,
          UIElement.InfFile.UPDATE_INF_FILE_AGENDA_BUTTON,
          UIElement.InfFile.INDEX_USER_ROLE,
          UIElement.InfFile.SOLVER_USER_ROLES,
          UIElement.InfFile.UPDATE_INF_FILE_BUTTON,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.InfFile.INDEX_USER_ROLE,
          UIElement.InfFile.SOLVER_USER_ROLES,
          UIElement.InfFile.UPDATE_INF_FILE_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.InfFile.DELIVERY_DATE,
          UIElement.InfFile.INCOMING_NUMBER,
          UIElement.InfFile.SOLVER_USER_ROLES,
          UIElement.Document.SET_DECISION_BUTTON,
          UIElement.Document.SET_COMPLAINT_BUTTON,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.InfFile.NOTES,
          UIElement.InfFile.WRITERS,
          UIElement.InfFile.CREATE_INF_FILE_BUTTON,
          UIElement.InfFile.UPDATE_INF_FILE_BUTTON,
          UIElement.InfFile.DELETE_INF_FILE_BUTTON,
          UIElement.InfFile.UPDATE_INF_FILE_STATE_BUTTON,
          UIElement.InfFile.UPDATE_INF_FILE_AGENDA_BUTTON,
          UIElement.InfFile.INDEX_USER_ROLE,
        ],
      ],
    ]),
  ],
  [
    EsFileState.IN_PROGRESS,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.InfFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.InfFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.InfFile.NOTES,
          UIElement.InfFile.CREATE_FILE_COVER_BUTTON,
          UIElement.InfFile.INDEX_USER_ROLE,
          UIElement.InfFile.UPDATE_INF_FILE_BUTTON,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.InfFile.INDEX_USER_ROLE,
          UIElement.InfFile.SOLVER_USER_ROLES,
          UIElement.InfFile.UPDATE_INF_FILE_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.InfFile.SOLVER_USER_ROLES,
          UIElement.Document.SET_DECISION_BUTTON,
          UIElement.Document.SET_COMPLAINT_BUTTON,
          UIElement.InfFile.SETTLEMENT_METHODS,
          UIElement.InfFile.SETTLEMENT_DATE,
          UIElement.InfFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.InfFile.WRITERS,
          UIElement.InfFile.TASKS_AND_DEADLINES,
          UIElement.InfFile.DRAFTS,
          UIElement.InfFile.CREATE_FILE_COVER_BUTTON,
          UIElement.InfFile.UPDATE_INF_FILE_BUTTON,
          UIElement.InfFile.UPDATE_INF_FILE_STATE_BUTTON,
          UIElement.InfFile.INDEX_USER_ROLE,
        ],
      ],
      [
        Role.SOLVER,
        [
          UIElement.InfFile.SOLVER_USER_ROLES,
          UIElement.Document.SET_DECISION_BUTTON,
          UIElement.Document.SET_COMPLAINT_BUTTON,
          UIElement.InfFile.SETTLEMENT_METHODS,
          UIElement.InfFile.SETTLEMENT_DATE,
          UIElement.InfFile.NOTES,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.InfFile.TASKS_AND_DEADLINES,
          UIElement.InfFile.DRAFTS,
          UIElement.InfFile.CREATE_FILE_COVER_BUTTON,
          UIElement.InfFile.UPDATE_INF_FILE_BUTTON,
        ],
      ],
    ]),
  ],
  [
    EsFileState.CLOSED,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.InfFile.CREATE_FILE_COVER_BUTTON,
          UIElement.InfFile.REOPEN_INF_FILE_BUTTON,
        ],
      ],
      [Role.HEAD_OSV, [UIElement.InfFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.INDEX,
        [
          UIElement.InfFile.CREATE_FILE_COVER_BUTTON,
          UIElement.InfFile.REOPEN_INF_FILE_BUTTON,
        ],
      ],
      [Role.SOLVER, [UIElement.InfFile.CREATE_FILE_COVER_BUTTON]],
    ]),
  ],
]);
