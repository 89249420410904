import { useContext } from 'react';
import { DetailContext, abortableFetch } from '@eas/common-web';
import { Contact } from '@models';
import { EvidenceApiUrl } from '@enums';

export function callApi(id: string, values: Omit<Contact, 'id'>) {
  return abortableFetch(`${EvidenceApiUrl.SUBJECTS}/${id}/contact`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(values),
  });
}

export function useAddContactDialog() {
  const { source } = useContext(DetailContext);

  // TODO
  const showButton = source.data?.id;

  return {
    callApi,
    showButton,
  };
}
