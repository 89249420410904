import React, { useContext, useEffect, useState } from 'react';
import { FilesToShred } from 'src/models/register';
import Grid from '@material-ui/core/Grid';
import {
  TableFieldCellProps,
  TableFieldCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Agenda, CourtFileType, EvidenceApiUrl } from '@enums';
import { DownloadDocx, DownloadPdf, ForwardToEssl } from './card/actions';
import { ReportCard, useStyles } from './card/card';

export function ToShredFiles() {
  const classes = useStyles();

  const { agendas } = useContext(StaticDataContext);
  const useAgendas = () => useStaticListSource(agendas);

  const [data, setData] = useState<
    FilesToShred & { agenda: Agenda; courtFileType?: CourtFileType }[]
  >([]);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetch(
        `${EvidenceApiUrl.REPORTS}/register/to-shred`
      );

      if (response.ok) {
        const json = await response.json();

        const senateCourtFiles = {
          agenda: Agenda.COURT,
          courtFileType: CourtFileType.SENATE,
          ...json.senateCourtFiles,
        };

        const plenaryCourtFiles = {
          agenda: Agenda.COURT,
          courtFileType: CourtFileType.PLENARY,
          ...json.plenaryCourtFiles,
        };

        const sprFiles = {
          agenda: Agenda.INF,
          ...json.sprFiles,
        };

        const infFiles = {
          agenda: Agenda.INF,
          ...json.infFiles,
        };

        const disciplinaryProceedingsFiles = {
          agenda: Agenda.DISCIPLINARY_PROCEEDINGS,
          ...json.disciplinaryProceedings,
        };

        const plenaryOpinionsFiles = {
          agenda: Agenda.PLENARY_OPINION,
          ...json.plenaryOpinions,
        };

        setData([
          senateCourtFiles,
          plenaryCourtFiles,
          sprFiles,
          infFiles,
          disciplinaryProceedingsFiles,
          plenaryOpinionsFiles,
        ]);
      }
    };

    loadData();
  }, []);

  return (
    <Grid item xs={12}>
      <ReportCard
        title="Spisy ke skartaci"
        data={data}
        columns={[
          {
            datakey: 'agenda',
            name: 'Agenda',
            width: 250,
            CellComponent: (props: TableFieldCellProps<any>) => {
              const Component =
                TableFieldCells.useSelectCellFactory(useAgendas);

              const courtFileType =
                props?.rowValue?.courtFileType === CourtFileType.SENATE
                  ? 'Soudní - Senátní spisy'
                  : props?.rowValue?.courtFileType === CourtFileType.PLENARY
                  ? 'Soudní - Plenární spisy'
                  : undefined;

              return courtFileType === undefined ? (
                <Component {...props} />
              ) : (
                <TableFieldCells.TextCell {...props} value={courtFileType} />
              );
            },
          },
          {
            datakey: 'resolvedYear',
            name: 'Rok vyřízení',
            width: 200,
          },
          {
            datakey: 'count',
            name: 'Počet spisů',
            width: 200,
          },
          {
            datakey: 'actions',
            name: 'Akce',
            width: 250,
            CellComponent: ({ rowValue }) => {
              const queryParams = `?agenda=${rowValue?.agenda}${
                rowValue?.courtFileType
                  ? `&courtFileType=${rowValue.courtFileType}`
                  : ''
              }`;

              return (
                <div className={classes.actions}>
                  <DownloadPdf
                    url={`${EvidenceApiUrl.REPORTS}/register/to-shred/generate${queryParams}`}
                  />
                  <DownloadDocx
                    url={`${EvidenceApiUrl.REPORTS}/register/to-shred/generate${queryParams}`}
                  />
                  <ForwardToEssl
                    url={`${EvidenceApiUrl.REPORTS}/register/forward-to-essl${queryParams}`}
                  />
                </div>
              );
            },
          },
        ]}
      />
    </Grid>
  );
}
