import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  FormAutocomplete,
  FormPanel,
  UserContext,
  eqFilterParams,
  useFormSelector,
} from '@eas/common-web';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { CourtFile, EsFile } from '@models';
import { EvidenceApiUrl, Role, UIElement } from '@enums';
import { useCourtFilePermission } from '../../../es-file/es-file-permission';
import { useJudges } from '../../../judge/judge-api';
import { useUserRoles } from '../../../role/role-api';

export function JudgesAndProcessorsFields() {
  const { source } = useContext<DetailHandle<EsFile>>(DetailContext);

  const { hasPermission: hasCourtFilePermission } = useCourtFilePermission(
    source.data
  );
  const { hasPermission } = useContext(UserContext);
  const isAdmin = hasPermission(Role.ADMIN);

  const { judge } = useFormSelector((data: CourtFile) => ({
    judge: data.judge,
  }));

  const judges = useJudges(undefined, EvidenceApiUrl.JUDGES);
  const secretaries = useUserRoles(
    isAdmin
      ? eqFilterParams({ field: 'role.id', value: Role.SECRETARY })
      : eqFilterParams({ field: 'id', value: judge?.secretaryUserRole?.id })
  );
  const indexes = useUserRoles(
    isAdmin
      ? eqFilterParams({ field: 'role.id', value: Role.INDEX })
      : eqFilterParams({ field: 'id', value: judge?.indexUserRole?.id })
  );
  const assistants = useUserRoles({
    filters: [
      {
        field: 'id',
        operation: ApiFilterOperation.IDS,
        ids: (judge?.assistantUserRoles ?? []).map((role) => role?.id),
      },
    ],
  });
  const analysts = useUserRoles(
    isAdmin
      ? eqFilterParams({ field: 'role.id', value: Role.ANALYST })
      : {
          filters: [
            {
              field: 'id',
              operation: ApiFilterOperation.IDS,
              ids:
                judge?.senates
                  ?.filter((s) => !!s.analystUserRole?.id)
                  .map((s) => s.analystUserRole!.id) ?? [],
            },
          ],
        }
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__COURT_FILES__DETAIL__PROCESSORS_PANEL_TITLE"
            defaultMessage="Zpracovatelé spisu"
          />
        }
      >
        <FormAutocomplete
          name="judge"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__JUDGE"
              defaultMessage="Soudce zpravodaj"
            />
          }
          source={judges}
          labelMapper={autocompleteLabelMapper}
          disabled={!hasCourtFilePermission(UIElement.CourtFile.JUDGE)}
        />
        <FormAutocomplete
          name="indexUserRole"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__INDEX"
              defaultMessage="Rejstřík"
            />
          }
          source={indexes}
          labelMapper={autocompleteLabelMapper}
          disabled={!hasCourtFilePermission(UIElement.CourtFile.INDEX)}
        />
        <FormAutocomplete
          name="secretaryUserRole"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__SECRETARY"
              defaultMessage="Tajemnice"
            />
          }
          source={secretaries}
          labelMapper={autocompleteLabelMapper}
          disabled={!hasCourtFilePermission(UIElement.CourtFile.SECRETARY)}
        />
        <FormAutocomplete
          name="assistantUserRole"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__ASSISTANT"
              defaultMessage="Asistent"
            />
          }
          source={assistants}
          labelMapper={autocompleteLabelMapper}
          disabled={!hasCourtFilePermission(UIElement.CourtFile.ASSISTANT)}
        />
        <FormAutocomplete
          name="analystUserRole"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__ANALYST"
              defaultMessage="Analytik - indexace"
            />
          }
          source={analysts}
          labelMapper={autocompleteLabelMapper}
          disabled={!hasCourtFilePermission(UIElement.CourtFile.ANALYST)}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__COURT_FILES__DETAIL__DECIDING_AUTHORITY_MEMBERS_PANEL_TITLE"
            defaultMessage="Rozhodující orgán"
          />
        }
      >
        <FormAutocomplete
          name="decidingAuthorityMembers"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__DECIDING_AUTHORITY_MEMBERS"
              defaultMessage="Členové rozhodujícího orgánu"
            />
          }
          multiple
          source={judges}
          labelMapper={autocompleteLabelMapper}
          disabled={
            !hasCourtFilePermission(
              UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS
            )
          }
        />
      </FormPanel>
    </>
  );
}
