import { HistoryFieldDescriptor } from '@composite/history/history-types';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  return [
    { key: 'daysRemaining', label: 'Zbývá' },
    { key: 'document', label: 'Dokument' },
    { key: 'duration', label: 'Počet dnů' },
    { key: 'endDate', label: 'Konečné datum' },
    { key: 'startingFrom', label: 'Počáteční datum' },
    { key: 'state', label: 'Stav lhůty' },
  ];
}
