import { useState } from 'react';
import {
  ApiFilterOperation,
  eqFilterParams,
  useEventCallback,
  useScrollableSource,
} from '@eas/common-web';
import { Decision, Document, EsFile } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useSearch() {
  const [searchText, setSearchText] = useState('');

  const defaultParams = {
    size: 0,
    ...eqFilterParams({ field: 'id', value: '-' }),
  };

  const esFileSource = useScrollableSource<EsFile>({
    url: `${EvidenceApiUrl.ES_FILES}/list`,
    params: defaultParams,
  });
  const documentSource = useScrollableSource<Document>({
    url: `${EvidenceApiUrl.DOCUMENTS}/list`,
    params: defaultParams,
  });
  const decisionSource = useScrollableSource<Decision>({
    url: `${EvidenceApiUrl.DECISIONS}/list`,
    params: defaultParams,
  });

  const searchInEsFiles = () => {
    esFileSource.reset();
    esFileSource.setParams({
      filters: [
        {
          operation: ApiFilterOperation.OR,
          filters: ['number.value', 'barCode'].map((field) => ({
            field,
            operation: ApiFilterOperation.CONTAINS,
            value: searchText,
          })),
        },
      ],
      size: 30,
    });
    esFileSource.loadMore();
  };

  const searchInDocuments = () => {
    documentSource.reset();
    documentSource.setParams({
      filters: [
        {
          operation: ApiFilterOperation.OR,
          filters: ['esFile.number.value', 'barCode', 'referenceNumber'].map(
            (field) => ({
              field,
              operation: ApiFilterOperation.CONTAINS,
              value: searchText,
            })
          ),
        },
      ],
      size: 30,
    });
    documentSource.loadMore();
  };

  const searchInDecisions = () => {
    decisionSource.reset();
    decisionSource.setParams({
      filters: [
        {
          operation: ApiFilterOperation.OR,
          filters: ['esFile.number.value', 'document.referenceNumber'].map(
            (field) => ({
              field,
              operation: ApiFilterOperation.CONTAINS,
              value: searchText,
            })
          ),
        },
      ],
      size: 30,
    });
    decisionSource.loadMore();
  };

  const handleSearch = useEventCallback(() => {
    searchInEsFiles();
    searchInDocuments();
    searchInDecisions();
  });

  return {
    esFileSource,
    documentSource,
    decisionSource,
    handleSearch,
    searchText,
    setSearchText,
  };
}
