import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictRegulationOrder } from '@models';
import { EntityName, EvidenceApiUrl, HistoryEntityType } from '@enums';
import { useColumns } from './dict-regulation-order-columns';
import { Fields } from './dict-regulation-order-fields';
import { useValidationSchema } from './dict-regulation-order-schema';

export function DictRegulationOrders() {
  const intl = useIntl();

  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictRegulationOrder>({
    version: 3,
    identifier: 'REGULATION_ORDERS',
    apiProps: {
      url: EvidenceApiUrl.DICT_REGULATION_ORDER,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.REGULATION_ORDER,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ES__DICT_REGULATION_ORDERS__TABLE__TITLE',
        defaultMessage: 'Právní předpisy',
      }),
      showReportButton: false,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      entityName: EntityName.REGULATION_ORDER,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_REGULATION_ORDERS__DETAIL__TITLE',
          defaultMessage: 'Předpis',
        }),
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
